export interface Location {
  lat: number
  lng: number
}

export interface Address {
  street?: string
  postalCode?: string
  countryCode?: string
  city?: string
  // country?: {
  //   name: string
  //   code: string
  // }
  stateProvince?: {
    name: string
    code: string
  }
  location: Location
}

export const getLocation = (address: Address): Location => {
  return address && address.location
}

export const getLat = (address: Address): number => {
  const location = getLocation(address)
  return location && location.lat
}

export const getLng = (address: Address): number => {
  const location = getLocation(address)
  return location && location.lng
}

export const getCountryCode = (address: Address): string => {
  return address && address.countryCode
}

export const getCity = (address: Address): string => {
  return address && address.city
}
