import { Action } from '@ngrx/store'
import { NormalizedResult, ShopProductCategory } from '@models'
import { LanguageType } from '@shared/constants'

export enum ShopProductCategoryActionTypes {
  Create = '[ShopProductCategory] Create',
  CreateSuccess = '[ShopProductCategory] Create Success',
  CreateFail = '[ShopProductCategory] Create Fail',

  Load = '[ShopProductCategory] Load',
  LoadSuccess = '[ShopProductCategory] Load Success',
  LoadFail = '[ShopProductCategory] Load Fail',

  Update = '[ShopProductCategory] Update',
  UpdateSuccess = '[ShopProductCategory] Update Success',
  UpdateFail = '[ShopProductCategory] Update Fail',

  Delete = '[ShopProductCategory] Delete',
  DeleteSuccess = '[ShopProductCategory] Delete Success',
  DeleteFail = '[ShopProductCategory] Delete Fail',

  Toggle = '[ShopProductCategory] Toggle',
  ToggleSuccess = '[ShopProductCategory] Toggle Success',
  ToggleFail = '[ShopProductCategory] Toggle Fail',
}

/**
 * Create ShopProductCategory actions
 */

export interface CreatePayload {
  propertyId: string
  shopId: string
  parentShopProductCategoryId: string
  shopProductCategory: ShopProductCategory
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
}

export class Create implements Action {
  readonly type = ShopProductCategoryActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = ShopProductCategoryActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = ShopProductCategoryActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load ShopProductCategory actions
 */

// export interface LoadPayload {
//   propertyId?: string
//   shopId?: string
// }

// export class Load implements Action {
//   readonly type = ShopProductCategoryActionTypes.Load
//   constructor(public payload: LoadPayload = {}) {}
// }

// export class LoadSuccess implements Action {
//   readonly type = ShopProductCategoryActionTypes.LoadSuccess
//   constructor(public payload, public meta: LoadPayload) {}
// }

// export class LoadFail implements Action {
//   readonly type = ShopProductCategoryActionTypes.LoadFail
//   constructor(public error: any, public meta: LoadPayload) {}
// }

/**
 * Update ShopProductCategory actions
 */
export interface UpdatePayload {
  propertyId: string
  shopId: string
  shopProductCategoryId: string
  shopProductCategory: ShopProductCategory
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
  forceTranslate: boolean
}

export class Update implements Action {
  readonly type = ShopProductCategoryActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = ShopProductCategoryActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = ShopProductCategoryActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete ShopProductCategory actions
 */
export interface DeletePayload {
  propertyId: string
  shopId: string
  shopProductCategoryId: string
}

export class Delete implements Action {
  readonly type = ShopProductCategoryActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = ShopProductCategoryActionTypes.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = ShopProductCategoryActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

/**
 * Toggle ShopProductCategory actions
 */
export interface TogglePayload {
  propertyId: string
  shopId: string
  shopProductCategoryId: string
  paused: boolean
}

export class Toggle implements Action {
  readonly type = ShopProductCategoryActionTypes.Toggle
  constructor(public payload: TogglePayload) {}
}

export class ToggleSuccess implements Action {
  readonly type = ShopProductCategoryActionTypes.ToggleSuccess
  constructor(public payload: NormalizedResult, public meta: TogglePayload) {}
}

export class ToggleFail implements Action {
  readonly type = ShopProductCategoryActionTypes.ToggleFail
  constructor(public error: any, public meta: TogglePayload) {}
}

export type ShopProductCategoryActions =
  | Create
  | CreateSuccess
  | CreateFail
  // | Load
  // | LoadSuccess
  // | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Toggle
  | ToggleSuccess
  | ToggleFail
