import { prop, propEq, partition, uniqBy } from 'ramda'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import {
  OnInit,
  forwardRef,
  OnChanges,
  Directive,
  Input,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core'

export type TranslatableString = Array<{ lang: string; value: string }>

export const TRANSLATABLE_TEXT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-use-before-declare
  useExisting: forwardRef(() => TranslatableTextDirective),
  multi: true,
}

/**
 * @description
 *
 * Allows to edit translatable fields via reactive forms.
 * Use this directive with one `formControl` or `formControlName`
 *
 * @example
 *
 * In class:
 * ```ts
 * title = new FormControl({ default: 'text', en: 'EN text' });
 * ```
 *
 * In template:
 * ```html
 * <input hotTranslatableText
 *   #titleField="hotTranslatableText"
 *   [defaultLanguage]="defaultLanguage"
 *   [language]="language"
 *   [formControl]="title">
 * <button
 *   *ngIf="titleField.translated"
 *   (click)="titleField.clearAll()">
 *     <span>Clear all translations</span>
 * </button>
 * ```
 */
@Directive({
  selector: '[hotTranslatableText]',
  exportAs: 'hotTranslatableText',
  providers: [TRANSLATABLE_TEXT_VALUE_ACCESSOR],
})
export class TranslatableTextDirective implements OnInit, OnChanges, ControlValueAccessor {
  @Input() defaultLanguage = 'en'
  @Input() language = 'en'

  private _value: TranslatableString = []
  private _translationsBackup: TranslatableString = []

  set value(value: TranslatableString) {
    this._value = value.filter(x => Boolean(x.value))
    this.notifyValueChange()
  }

  get value(): TranslatableString {
    return this._value
  }

  onChange = (_: any) => {}

  @HostListener('blur', [])
  onTouched = () => {}

  @HostListener('input', ['$event.target.value'])
  _handleInput(value: any): void {
    if (!value) {
      const withoutLanguage = (this._value || []).filter(x => x.lang !== this.language)
      this.value = withoutLanguage
      return
    }

    let exists = false
    const newValueForLang = { lang: this.language, value }
    const data = (this._value || []).map(item => {
      if (item.lang === this.language) {
        exists = true
        return newValueForLang
      }
      return item
    })
    if (!exists) {
      data.push(newValueForLang)
    }
    this.value = data
  }

  // Current-language value or default
  get normalizedValue() {
    if (this._value) {
      return Array.isArray(this._value)
        ? prop('value', this._value.find(propEq('lang', this.language)))
        : this._value[this.language] || null
    }
    return null
  }

  get currentValue() {
    return this.valueForLang(this.language)
  }

  get defaultValue() {
    if (this._value) {
      return Array.isArray(this._value)
        ? prop('value', this._value.find(propEq('lang', this.defaultLanguage)) || this._value[0])
        : this._value[this.defaultLanguage]
    }
    return null
  }

  get translated() {
    return this.currentValue && this.currentValue !== this.defaultValue
  }

  public isTranslated(lang: string) {
    return Boolean(this.valueForLang(lang))
  }

  valueForLang(lang: string) {
    if (this._value) {
      return Array.isArray(this._value)
        ? prop('value', this._value.find(propEq('lang', lang)))
        : this._value[lang] || null
    }
    return null
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value)
    }
  }

  ngOnInit(): void {}
  ngOnChanges(changes) {
    if (changes.language) {
      this.writeValue(this._value)
    }
  }

  toggleLanguages(languages: string[]) {
    return this.isTranslated(languages[0])
      ? this.clearLanguages(languages)
      : this.restoreLanguages(languages)
  }

  clearLanguages(languages: string[]) {
    const [translationsToBackup, translationsToKeep] = partition(
      x => languages.includes(x.lang),
      this._value
    )
    const newBackup = uniqBy(x => x.lang, this._translationsBackup.concat(translationsToBackup))
    this._translationsBackup = newBackup
    this.writeValue(translationsToKeep)
    this.value = translationsToKeep
  }

  restoreLanguages(languages: string[]) {
    const [translationsToRestore, backups] = partition(
      x => languages.includes(x.lang),
      this._translationsBackup
    )
    const newLanguages = uniqBy(x => x.lang, this.value.concat(translationsToRestore))
    this._translationsBackup = backups
    this.writeValue(newLanguages)
    this.value = newLanguages
  }

  clearAll(): void {
    this.writeValue([])
    this.value = []
  }

  writeValue(obj: any): void {
    this._value = obj
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', this.normalizedValue || '')
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled)
  }

  copyFromDefault() {
    this._handleInput(this.defaultValue)
    this.writeValue(this._value)
  }
}
