import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { PropertyFacade } from '@redux/property/property.facade'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { UserProfileFacade } from '@shared/facades/user-profile.facade'

@Injectable()
export class OnboardingGuard implements CanActivate {
  constructor(
    private router: Router,
    private propertyFacade: PropertyFacade,
    private userProfileFacade: UserProfileFacade
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.propertyFacade.property$,
      this.propertyFacade.getOnboardingGoals(),
      this.userProfileFacade.can('property.stats'),
    ]).pipe(
      map(([property, goals, canViewStats]) => {
        if (!canViewStats) {
          return this.router.parseUrl(`properties/${property._id}/requests`)
        }

        const incompleteGoals = goals.filter(x => !(x.completedAt || x.skippedAt))

        if (Boolean(incompleteGoals.length)) {
          return this.router.parseUrl(`properties/${property._id}/hi`)
        } else {
          return this.router.parseUrl(`properties/${property._id}/stats`)
        }
      })
    )
  }
}
