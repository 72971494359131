import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Actions } from '@ngrx/effects'

import { Subject, combineLatest, Observable, BehaviorSubject } from 'rxjs'
import { map, take, switchMap, shareReplay, startWith, exhaustMap, tap } from 'rxjs/operators'

import { Poll, Builder, Form } from '@models'
import * as fromRoot from '@redux'
import * as fromBuilder from '@redux/builder'
import * as fromForms from '@redux/form'
import { PollService } from '@redux/poll/poll.service'
import { PropertyFacade } from '@redux/property/property.facade'

import { successOrFail } from './helpers/success-or-fail'

export interface MenuBuilderOpts {
  modules: string[]
  builders: Builder[]
  forms: Form[]
  polls: Poll[]
}

@Injectable({ providedIn: 'root' })
export class MenuBuilderFacade {
  private loadBuilders$ = this.propertyFacade.propertyId$.pipe(
    exhaustMap(() => this.reloadBuilders()),
    shareReplay(1)
  )
  private loadForms$ = this.propertyFacade.propertyId$.pipe(
    switchMap(() => this.reloadForms()),
    shareReplay(1)
  )

  public modules$: Observable<string[]> = this.loadBuilders$.pipe(
    switchMap(() => this.store$.pipe(select(fromRoot.selectBuilderModules))),
    startWith([])
  )
  public builders$: Observable<Builder[]> = this.loadBuilders$.pipe(
    switchMap(() => this.store$.pipe(select(fromRoot.selectCurrentBuilderEntities))),
    startWith([])
  )
  public forms$: Observable<Form[]> = this.loadForms$.pipe(
    switchMap(() => this.store$.pipe(select(fromRoot.selectCurrentForms))),
    startWith([])
  )

  private pollsReload = new Subject()
  private pollsLoadedState = new BehaviorSubject(false)
  public polls$: Observable<Poll[]> = combineLatest([
    this.propertyFacade.propertyId$,
    this.pollsReload.pipe(startWith('initial loading')),
  ]).pipe(
    tap(() => this.pollsLoadedState.next(false)),
    exhaustMap(([propertyId]) => this.pollService.getList(propertyId)),
    map((x: any) => x.polls),
    tap(() => this.pollsLoadedState.next(true)),
    startWith([]),
    shareReplay(1)
  )

  /**
   * You can use it with `hot-create-button` to simplify loading of its stuff
   *
   * @example
   *
   *    // in component controller:
   *    public menuBuilderOpts$ = this.menuBuilderFacade.menuBuilderOpts$
   *
   *    // in template:
   *    <hot-create-button
   *      *ngIf="(menuBuilderOpts$ | async) as menuBuilderOpts"
   *      [language]="currentLanguage"
   *      [builders]="menuBuilderOpts.builders"
   *      [modules]="menuBuilderOpts.modules"
   *      [forms]="menuBuilderOpts.forms"
   *      [polls]="menuBuilderOpts.polls"
   *      (update)="onButtonUpdate($event)"
   *    ></hot-create-button>
   */
  public menuBuilderOpts$: Observable<MenuBuilderOpts> = combineLatest([
    this.modules$,
    this.builders$,
    this.forms$,
    this.polls$,
  ]).pipe(map(([modules, builders, forms, polls]) => ({ modules, builders, forms, polls })))

  public pollsLoaded$ = this.pollsLoadedState.asObservable()

  public formsLoaded$ = this.store$.pipe(select(fromRoot.selectFormsLoaded), startWith(false))
  public builderLoaded$ = this.store$.pipe(select(fromRoot.selectBuilderLoaded), startWith(false))

  public loaded$ = combineLatest([this.builderLoaded$, this.formsLoaded$, this.pollsLoaded$]).pipe(
    map(([builderLoader, formsLoaded, pollsLoaded]) => builderLoader && formsLoaded && pollsLoaded)
  )

  constructor(
    private store$: Store<fromRoot.State>,
    private actions$: Actions,
    private propertyFacade: PropertyFacade,
    private pollService: PollService
  ) {}

  reloadPolls() {
    this.pollsReload.next()
  }

  reloadBuilders() {
    this.propertyFacade.propertyId$.pipe(take(1)).subscribe(propertyId => {
      this.store$.dispatch(new fromBuilder.LoadAll({ propertyId }))
    })
    return successOrFail<fromBuilder.LoadAllSuccess>(
      this.actions$,
      fromBuilder.BuilderActionTypes.LoadAllSuccess,
      fromBuilder.BuilderActionTypes.LoadAllFail
    )
  }

  reloadForms() {
    this.propertyFacade.propertyId$.pipe(take(1)).subscribe(propertyId => {
      this.store$.dispatch(new fromForms.Load({ propertyId }))
    })
    return successOrFail<fromForms.LoadSuccess>(
      this.actions$,
      fromForms.ActionTypes.LoadSuccess,
      fromForms.ActionTypes.LoadFail
    )
  }
}
