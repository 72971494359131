import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core'

import { Store, select } from '@ngrx/store'

import { FormResponseStatus, Property, User } from '@models'
import * as fromRoot from '@redux/reducers'
import * as fromUser from '@redux/user'
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import * as fromFormResponse from '@redux/form-response'
import * as fromReview from '@redux/review'
import * as fromChats from '@redux/chat'
import * as fromCart from '@redux/cart'
import * as fromShopCart from '@redux/shop-cart'
import { WindowRefService } from '@shared/services'
import { ReviewStatus } from '@models/review.model'
import { CartStatus } from '@models/cart.model'
import { ShopCartStatus } from '@models/shop.model'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { UserProfileFacade } from '@shared/facades/user-profile.facade'

@Component({
  selector: 'hot-app-header',
  templateUrl: './app-header.component.html',
  // encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  public avatarUrl: string = null
  public openedRequests$ = this.store$.pipe(
    select(fromRoot.selectCurrentFormResponsesOpenedRecords)
  )
  public openedCarts$ = this.store$.pipe(select(fromRoot.selectCurrentCartsOpenedRecords))
  public openedShopCarts$ = this.store$.pipe(select(fromRoot.selectCurrentShopCartsOpenedRecords))
  public totalReviews$ = this.store$.pipe(select(fromRoot.selectCurrentReviewsOpenedRecords))
  public inboxChatsCounter$ = this.store$.pipe(select(fromRoot.selectChatsInboxCounter))
  private _user: User = null
  private destroyed = new Subject()
  public hasTouch = this.windowService.hasTouch

  public showAdminSection$ = this.userProfileFacade.canAccessAdminSection$
  public showPropertySettings$ = this.userProfileFacade.canAny([
    'property.update',
    'property.integrations.read',
    'property.webhooks.update',
    'property.channels.update',
    'property.reports',
    'property.shop.update',
    'property.forms.update',
    'property.menu.update',
    'sales-service-settings.create',
  ])
  public showPropertyStats$ = this.userProfileFacade.can('property.stats')
  public showPropertyMarketing$ = this.userProfileFacade.can('property.messages.read')
  public showPropertyChats$ = this.userProfileFacade.can('property.chats.read')
  public showWifi$ = this.userProfileFacade.can('property.wifi.sessions.read')

  @Input() currentProperty: Property | null
  @Input() properties: Property[]

  @Input()
  set user(user: User) {
    const email = (user && user.email) || 'hotbot'
    this._user = user
    this.avatarUrl = `https://image.devidentify.com/${email}`
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private store$: Store<fromRoot.State>,
    public readonly userProfileFacade: UserProfileFacade,
    public readonly windowService: WindowRefService
  ) {}

  public helpIsVisible = false
  public get logoBackgroundImage() {
    const logo = this.currentProperty && this.currentProperty.logoUrl
    return logo ? `url("${logo}")` : ''
  }

  ngOnInit() {
    this.store$
      .pipe(
        select(fromRoot.selectCurrentPropertyId),
        filter(propertyId => propertyId !== null),
        distinctUntilChanged(),
        takeUntil(this.destroyed)
      )
      .subscribe(propertyId => {
        this.loadBadgesInfo(propertyId)
      })

    this.helpIsVisible = typeof window.showSupportChat === 'function' && window.innerWidth < 960
  }

  public loadBadgesInfo(propertyId) {
    this.store$.dispatch(
      new fromFormResponse.Load({
        propertyId,
        status: FormResponseStatus.Open,
        page: 0,
        limit: 0,
      })
    )
    this.store$.dispatch(
      new fromReview.Load({
        propertyId,
        status: ReviewStatus.Open,
        page: 0,
        limit: 0,
      })
    )
    this.store$.dispatch(
      new fromCart.Load({
        propertyId,
        status: CartStatus.Open,
        page: 0,
        limit: 0,
      })
    )
    this.store$.dispatch(
      new fromShopCart.Load({
        propertyId,
        status: ShopCartStatus.Open,
        page: 0,
        limit: 0,
      })
    )
    this.store$.dispatch(
      new fromChats.Load({
        propertyId,
        params: { tab: 'inbox' },
      })
    )
  }

  ngOnDestroy() {
    this.destroyed.next()
  }

  get user() {
    return this._user
  }

  showHelp() {
    window.showSupportChat()
  }

  public logout() {
    this.store$.dispatch(new fromUser.Logout())
  }
}
