<div [formGroup]="form">
  <div class="pb3">
    <mat-checkbox formControlName="Enabled" color="primary">
      <span>{{ 'SalesService.settings.fields.enabled' | translate: locale.language }}</span>
    </mat-checkbox>
  </div>

  <mat-form-field class="w-100" appearance="fill" [formGroup]="legalEntityForm">
    <mat-label>{{ 'LegalEntities.title' | translate: locale.language }} *</mat-label>
    <input type="text" formControlName="LegalEntity" matInput [matAutocomplete]="autoLegalEntity" />
    <mat-autocomplete #autoLegalEntity="matAutocomplete" [displayWith]="displayLegalEntityItemFn">
      <mat-option *ngFor="let item of (filteredLegalEntities$ | async)" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="flex flex-row w-100">
  <mat-form-field appearance="fill" class="w-100">
      <mat-label>Default VAT type</mat-label>
      <mat-select formControlName="DefaultVatType">
        <mat-option *ngFor="let vat of vatTypes" [value]="vat">
          {{ vat }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex flex-row w-100">
    <mat-form-field appearance="fill" class="w-60 pr2">
      <mat-label
        >{{
          'SalesService.settings.fields.contractNumber' | translate: locale.language
        }}
        *</mat-label
      >
      <input
        #contractNumber
        formControlName="ContractNumber"
        matInput
        [maxLength]="200"
        autocapitalize="on"
      />
      <mat-hint align="end"
        >{{ contractNumber.value.length }} / {{ contractNumber.maxLength }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-60 pr2">
      <mat-label
        >{{ 'SalesService.settings.fields.contractDate' | translate: locale.language }} *</mat-label
      >
      <input
        formControlName="ContractDate"
        matInput
        [matDatepicker]="contractDatePicker"
        autocomplete="off"
        autocapitalize="off"
        type="text"
        readonly
        (dateChange)="onContractDateChange($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="contractDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #contractDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="flex flex-row w-100">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label
        >{{ 'SalesService.settings.fields.agentFee' | translate: locale.language }} *</mat-label
      >
      <input #agentFeeValue formControlName="AgentFeeValue" matInput type="number" [min]="0" />
    </mat-form-field>
    <div class="self-baseline">
      <mat-button-toggle-group
        #agentFeeType
        formControlName="AgentFeeType"
        aria-label="Font Style"
        class="flex justify-center"
      >
        <mat-button-toggle value="fixed"><mat-icon>attach_money</mat-icon></mat-button-toggle>
        <mat-button-toggle value="percent" class="ph1">%</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="flex flex-row w-100">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label
        >{{
          'SalesService.settings.fields.agentReturnFee' | translate: locale.language
        }}
        *</mat-label
      >
      <input
        #agentReturnFeeValue
        formControlName="AgentReturnFeeValue"
        matInput
        type="number"
        [min]="0"
      />
    </mat-form-field>
    <div class="self-baseline">
      <mat-button-toggle-group
        #agentReturnFeeType
        formControlName="AgentReturnFeeType"
        aria-label="Font Style"
        class="flex justify-center"
      >
        <mat-button-toggle value="fixed"><mat-icon>attach_money</mat-icon></mat-button-toggle>
        <mat-button-toggle value="percent" class="ph1">%</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="flex flex-row w-100">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label
        >{{
          'SalesService.settings.fields.customerReturnFee' | translate: locale.language
        }}
        *</mat-label
      >
      <input
        #customerReturnFeeValue
        formControlName="CustomerReturnFeeValue"
        matInput
        type="number"
        [min]="0"
      />
    </mat-form-field>
    <div class="self-baseline">
      <mat-button-toggle-group
        #customerReturnFeeType
        formControlName="CustomerReturnFeeType"
        aria-label="Font Style"
        class="flex justify-center"
      >
        <mat-button-toggle value="fixed"><mat-icon>attach_money</mat-icon></mat-button-toggle>
        <mat-button-toggle value="percent" class="ph1">%</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-form-field appearance="fill" class="w-100">
    <mat-label>Merchant Provider *</mat-label>
    <mat-select formControlName="MerchantProvider" (valueChange)="onMerchantProviderChange($event)">
      <mat-option *ngFor="let m of merchantProviders" [value]="m">
        {{ m }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="fill"
    class="w-100"
    *ngIf="selectedMerchantProvider === 'HotbotPaymentService'"
  >
    <mat-label
      >{{ 'SalesService.settings.fields.merchant' | translate: locale.language }} *</mat-label
    >
    <mat-select formControlName="MerchantAlias">
      <mat-option *ngFor="let m of merchants" [value]="m.alias">
        {{ m.name }} ({{ m.alias }})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="selectedMerchantProvider === 'MercadoPago'">
    <mat-checkbox formControlName="SandboxMode" color="primary">
      <span>Sandbox mode</span>
    </mat-checkbox>
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Public Key</mat-label>
      <input formControlName="MerchantPublicKey" matInput type="email" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Private Key</mat-label>
      <input formControlName="MerchantPrivateKey" matInput type="email" />
    </mat-form-field>
  </div>

  <mat-form-field appearance="fill" class="w-100">
    <mat-label
      >{{ 'SalesService.settings.fields.currency' | translate: locale.language }} *</mat-label
    >
    <mat-select formControlName="Currency">
      <mat-option *ngFor="let c of currencies" [value]="c.value">
        {{ c.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="w-100">
    <mat-label
      >{{ 'SalesService.settings.fields.offerLink' | translate: locale.language }} *</mat-label
    >
    <input
      [maxlength]="'255'"
      #offerLink
      formControlName="OfferLink"
      hotTranslatableText
      [language]="locale.language"
      matInput
      required
    />
  </mat-form-field>

  <div class="mt3 mb2 fw5 f6 black-70">
    {{ 'SalesService.settings.fields.invoiceIncoming' | translate: locale.language }}
  </div>
  <div class="flex justify-between">
    <mat-form-field appearance="fill" class="w-40 pr2">
      <mat-label>{{
        'SalesService.settings.fields.invoicePrefix' | translate: locale.language
      }}</mat-label>
      <input
        #incomingInvoicePrefix
        formControlName="IncomingInvoicePrefix"
        matInput
        [maxLength]="6"
        autocapitalize="on"
      />
      <mat-hint align="end"
        >{{ incomingInvoicePrefix.value.length }} / {{ incomingInvoicePrefix.maxLength }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-60 pl2">
      <mat-label
        >{{
          'SalesService.settings.fields.invoiceCounter' | translate: locale.language
        }}
        *</mat-label
      >
      <input formControlName="IncomingInvoiceCounter" matInput type="number" [min]="1" />
    </mat-form-field>
  </div>

  <div class="mt3 mb2 fw5 f6 black-70">
    {{ 'SalesService.settings.fields.invoiceOutgoing' | translate: locale.language }}
  </div>
  <div class="flex justify-between">
    <mat-form-field appearance="fill" class="w-40 pr2">
      <mat-label>{{
        'SalesService.settings.fields.invoicePrefix' | translate: locale.language
      }}</mat-label>
      <input
        #outgoingInvoicePrefix
        formControlName="OutgoingInvoicePrefix"
        matInput
        [maxLength]="6"
        autocapitalize="on"
      />
      <mat-hint align="end"
        >{{ outgoingInvoicePrefix.value.length }} / {{ outgoingInvoicePrefix.maxLength }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-60 pl2">
      <mat-label
        >{{
          'SalesService.settings.fields.invoiceCounter' | translate: locale.language
        }}
        *</mat-label
      >
      <input formControlName="OutgoingInvoiceCounter" matInput type="number" [min]="1" />
    </mat-form-field>
  </div>

  <div class="mt3 mb2 fw5 f6 black-70">
    Авто отчеты
  </div>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>email *</mat-label>
    <input formControlName="Email" matInput type="email" />
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>{{
      'SalesService.settings.fields.reportInterval' | translate: locale.language
    }}</mat-label>
    <mat-select formControlName="ReportInterval">
      <mat-option *ngFor="let interval of reportIntervals" [value]="interval.value">
        {{ interval.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="mt3 mb2 fw5 f6 black-70 w-100">
    {{ 'SalesService.settings.fields.reportNextTimestamp' | translate: locale.language }}
  </div>
  <div class="flex justify-between w-100">
    <mat-form-field appearance="fill" class="w-60 pr2">
      <mat-label>Дата</mat-label>
      <input
        formControlName="NextReportDate"
        matInput
        [matDatepicker]="reportDatePicker"
        autocomplete="off"
        autocapitalize="off"
        type="text"
        readonly
        (dateChange)="onDateChange($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="reportDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #reportDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-40 pl2">
      <mat-label>Время</mat-label>
      <input
        formControlName="NextReportTime"
        matInput
        autocomplete="off"
        autocapitalize="off"
        type="text"
        pattern="^(?:2[0-3]|1\d|0?\d):[0-5]\d$"
      />
    </mat-form-field>
  </div>
  <div class="flex flex-column flex-row-ns flex-wrap justify-end ma2">
    <button
      class="mb2 mr3-ns order-3 order-1-ns"
      mat-button
      color="primary"
      (click)="onFormCancel()"
    >
      {{ 'forms.action.cancel' | translate: locale.language }}
    </button>
    <button
      class="mb2 order-1"
      mat-raised-button
      color="primary"
      [disabled]="!enableSubmit"
      (click)="onFormSubmit()"
    >
      {{ 'forms.action.save' | translate: locale.language }}
    </button>
  </div>
</div>
