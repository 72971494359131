import { AbstractControl } from '@angular/forms'

const decimalRegexp = /^[0-9]{1,8}([\.][0-9]{1,2})?$/
export const decimalValidator = (control: AbstractControl) => {
  if (control && control.value && !control.value.match(decimalRegexp)) {
    return {
      decimal: true,
    }
  }
  return null
}
