<div *ngIf="user" class="flex-ns pa3 mb4 w-100 bg-white br2">
  <div class="flex-auto-ns">
    <b>{{ 'Requests.messengerNotificationsHint.title' | translate: locale.language }}</b>
    <br />
    {{ 'Requests.messengerNotificationsHint.description' | translate: locale.language }}
  </div>
  <div class="channels">
    <button
      *ngFor="let channel of channels"
      class="mr2 channel-button"
      (click)="onChannelClick(channel)"
    >
      <mat-icon
        [svgIcon]="'hot:' + channel.type + '-messenger-sq'"
        class="hotbot-small-logo"
      ></mat-icon>
    </button>
  </div>
</div>
