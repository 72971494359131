<div class="form" [formGroup]="form">
  <mat-form-field appearance="fill" class="mr3 mb1 w-100" floatLabel="always">
    <mat-label>{{
      'FormQuestion.optionalComment.labelEnterText' | translate: locale.language
    }}</mat-label>
    <input
      type="text"
      matInput
      formControlName="labelEnterText"
      [placeholder]="labelEnterTextPlaceholder"
      hotTranslatableText
      [language]="language"
      [defaultLanguage]="defaultLanguage"
    />
  </mat-form-field>
</div>
