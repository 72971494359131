import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import {
  OnInit,
  forwardRef,
  OnChanges,
  Directive,
  Input,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core'

export const HOT_PERCENTS_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-use-before-declare
  useExisting: forwardRef(() => HotPercentsDirective),
  multi: true,
}

@Directive({
  selector: '[hotPercents]',
  exportAs: 'hotPercents',
  providers: [HOT_PERCENTS_VALUE_ACCESSOR],
})
export class HotPercentsDirective implements OnInit, OnChanges, ControlValueAccessor {
  @Input() defaultLanguage = 'en'

  private _value: number

  private percentsToVariableUnit(value: string): number {
    // 4.2 -> 0.042
    if (value === '') {
      return null
    }
    const str = (Number(value) / 100).toFixed(4)
    return str.endsWith('.0') ? Number(str.replace('.0', '')) : Number(str)
  }

  private percentsToBaseUnit(value: number): string {
    // 0.042 -> 4.2
    let str = (value * 100).toFixed(2)
    str = str.endsWith('.00') ? String(Number(str)) : str
    return str
  }

  set value(value: number) {
    this._value = value
    this.notifyValueChange()
  }

  get value(): number {
    return this._value
  }

  onChange = (_: any) => {}

  @HostListener('blur', [])
  onTouched = () => {}

  @HostListener('input', ['$event.target.value'])
  _handleInput(value: string): void {
    if (!value) {
      this.value = null
      return
    }

    this.value = this.percentsToVariableUnit(value)
  }

  get normalizedValue() {
    return this.percentsToBaseUnit(this._value)
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value)
    }
  }

  ngOnInit(): void {}
  ngOnChanges(changes) {
    if (changes.language) {
      this.writeValue(this._value)
    }
  }

  clearAll(): void {
    this.writeValue(null)
    this.value = null
  }

  writeValue(value: number): void {
    this._value = value
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', this.normalizedValue || '')
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled)
  }
}
