import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'declensionOfNum',
})
export class DeclensionOfNum implements PipeTransform {
  transform(value: any, titles: string[]): any {
    const cases = [2, 0, 1, 1, 1, 2]
    const title =
      titles[value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5]]
    return `${value} ${title}`
  }
}
