<div class="flex flex-column items-center justify-center w-100 relative">
  <input
    #fileInput
    name="file"
    type="file"
    accept="image/png,image/x-png,image/gif,image/jpeg"
    hidden
    (change)="fileChangeEvent($event)"
  />
  <span *ngIf="_showPlaceholder" class="flex justify-center w-100">{{
    translatedPlaceholder
  }}</span>
  <span *ngIf="uploadFailed" class="flex red mt1 w-100">{{ uploadImageError }}</span>
  <image-cropper
    [class.dn]="!loaded"
    [class.hot-image-cropper-uploading]="uploadingToServer"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="aspectRatio"
    [resizeToWidth]="resizeToWidth"
    [onlyScaleDown]="onlyScaleDown"
    format="imageExtension"
    (imageCroppedFile)="imageCroppedFile($event)"
    (imageLoaded)="imageLoaded()"
  >
  </image-cropper>
  <div *ngIf="loaded" class="flex w-100 justify-end ma2">
    <button mat-button color="primary" (click)="onCancel($event)" [disabled]="isUploading">
      {{ 'forms.action.cancel' | translate: locale.language }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="ml3"
      [disabled]="!enableSubmit || isUploading"
      (click)="onSave($event)"
    >
      {{ 'forms.action.save' | translate: locale.language }}
    </button>
  </div>
  <mat-spinner
    *ngIf="isUploading"
    class="hot-image-cropper-spinner"
    [diameter]="20"
    color="primary"
  ></mat-spinner>
</div>
