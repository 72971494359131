<div class="flex justify-center">
  <mat-card class="w-60">
    <div class="w-100 flex flex-column" [formGroup]="form">
      <mat-form-field class="ma2">
        <input
          #name
          formControlName="Name"
          matInput
          placeholder="Название ресторана *"
          [maxlength]="maxNameLength"
          hotTranslatableField
          [language]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
        />
        <mat-hint align="end">{{ name.value.length }} / {{ maxNameLength }}</mat-hint>
        <mat-error *ngIf="form.touched && form.get('Name').hasError('empty')">
          Поле не должно быть пустым
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('Name').hasError('emptyDefault')">
          Язык по умолчанию должен быть заполнен
        </mat-error>
        <mat-error *ngIf="form && form.touched && form.get('Name').hasError('blank')">
          Поле не должно состоять из одних пробелов
        </mat-error>
      </mat-form-field>

      <div class="ma2">
        <mat-form-field
          class="w-100"
          [ngClass]="{ 'mat-form-field-invalid': categoryCtrl.touched && !categories.length }"
        >
          <mat-chip-list #categoriesList>
            <mat-chip
              *ngFor="let category of categories"
              [selectable]="true"
              [removable]="true"
              (removed)="removeCategory(category)"
            >
              {{ category }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Категория *"
              #categoryInput
              [formControl]="categoryCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="categoriesList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="false"
              (matChipInputTokenEnd)="addCategory($event)"
            />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCategory($event)">
            <mat-option *ngFor="let category of (filteredCategories | async)" [value]="category">
              {{ category }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error
          style="font-size: 75%;margin-top: -1.25em"
          *ngIf="categoryCtrl.touched && !categories.length"
        >
          Выберите категорию из списка
        </mat-error>
      </div>

      <hot-restaurant-cuisine-autocomplete
        class="ma2"
        [language]="currentLanguage"
        [selectedCuisines]="(restaurant && restaurant.__cuisines) || []"
        (change)="onRestaurantCuisinesChange($event)"
      >
      </hot-restaurant-cuisine-autocomplete>

      <mat-form-field class="ma2">
        <input
          #schedule
          formControlName="Schedule"
          matInput
          placeholder="Время работы *"
          [maxlength]="maxCommonLength"
          hotTranslatableField
          [language]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
        />
        <mat-hint align="end">{{ schedule.value.length }} / {{ maxNameLength }}</mat-hint>
        <mat-error *ngIf="form.touched && form.get('Schedule').hasError('required')">
          Поле не должно быть пустым
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('Schedule').hasError('emptyDefault')">
          Язык по умолчанию должен быть заполнен
        </mat-error>
        <mat-error *ngIf="form && form.touched && form.get('Schedule').hasError('blank')">
          Поле не должно состоять из одних пробелов
        </mat-error>
      </mat-form-field>

      <mat-form-field class="ma2">
        <input
          #address
          formControlName="Address"
          matInput
          placeholder="Адрес *"
          [maxlength]="maxAddressLength"
          hotTranslatableField
          [language]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
        />
        <mat-hint align="end">{{ address.value.length }} / {{ maxAddressLength }}</mat-hint>
        <mat-error *ngIf="form.touched && form.get('Address').hasError('required')">
          Поле не должно быть пустым
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('Address').hasError('emptyDefault')">
          Язык по умолчанию должен быть заполнен
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('Address').hasError('blank')">
          Поле не должно состоять из одних пробелов
        </mat-error>
      </mat-form-field>

      <mat-form-field class="ma2">
        <input
          #city
          formControlName="City"
          matInput
          placeholder="Город *"
          [maxlength]="maxCommonLength"
          hotTranslatableField
          [language]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
        />
        <mat-hint align="end">{{ city.value.length }} / {{ maxNameLength }}</mat-hint>
        <mat-error *ngIf="form.touched && form.get('City').hasError('required')">
          Поле не должно быть пустым
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('City').hasError('emptyDefault')">
          Язык по умолчанию должен быть заполнен
        </mat-error>
        <mat-error *ngIf="form && form.touched && form.get('City').hasError('blank')">
          Поле не должно состоять из одних пробелов
        </mat-error>
      </mat-form-field>

      <div class="flex ma2">
        <div class="flex w-50">
          <mat-form-field class="w-90">
            <input
              #lat
              formControlName="Lat"
              matInput
              placeholder="Широта *"
              [maxlength]="maxLatLngLength"
            />
            <mat-hint align="end">{{ lat.value.length }} / {{ maxLatLngLength }}</mat-hint>
            <mat-error *ngIf="form.touched && form.get('Lat').hasError('required')">
              Поле не должно быть пустым
            </mat-error>
            <mat-error *ngIf="form.touched && form.get('Lat').hasError('number')">
              Неверный формат числа
            </mat-error>
            <mat-error
              *ngIf="
                form.touched &&
                !form.get('Lat').hasError('number') &&
                form.get('Lat').hasError('range')
              "
            >
              Значение широты должно находится в диапазоне [{{ minLatValue }}, {{ maxLatValue }}]
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex justify-end w-50">
          <mat-form-field class="w-90">
            <input
              #lng
              formControlName="Lng"
              matInput
              placeholder="Долгота *"
              [maxlength]="maxLatLngLength"
            />
            <mat-hint align="end">{{ lng.value.length }} / {{ maxLatLngLength }}</mat-hint>
            <mat-error *ngIf="form.touched && form.get('Lng').hasError('required')">
              Поле не должно быть пустым
            </mat-error>
            <mat-error *ngIf="form.touched && form.get('Lng').hasError('number')">
              Неверный формат числа
            </mat-error>
            <mat-error
              *ngIf="
                form.touched &&
                !form.get('Lng').hasError('number') &&
                form.get('Lng').hasError('range')
              "
            >
              Значение долготы должно находится в диапазоне [{{ minLngValue }}, {{ maxLngValue }}]
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field class="ma2">
        <input
          #averageBill
          formControlName="AverageBill"
          matInput
          placeholder="Средний чек *"
          [maxlength]="maxCommonLength"
          hotTranslatableField
          [language]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
        />
        <mat-hint align="end">{{ averageBill.value.length }} / {{ maxNameLength }}</mat-hint>
        <mat-error *ngIf="form.touched && form.get('AverageBill').hasError('required')">
          Поле не должно быть пустым
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('City').hasError('AverageBill')">
          Язык по умолчанию должен быть заполнен
        </mat-error>
        <mat-error *ngIf="form && form.touched && form.get('AverageBill').hasError('blank')">
          Поле не должно состоять из одних пробелов
        </mat-error>
      </mat-form-field>
      <mat-form-field class="ma2">
        <input #rating formControlName="Rating" matInput placeholder="Рейтинг" maxlength="3" />
        <mat-error *ngIf="form.touched && form.get('Rating').hasError('range')">
          Значение рейтинга должно быть от 0 до 10
        </mat-error>
        <mat-error
          *ngIf="
            form.touched &&
            form.get('Rating').hasError('pattern') &&
            !form.get('Rating').hasError('range')
          "
        >
          Некорректный формат
        </mat-error>
      </mat-form-field>

      <mat-form-field class="ma2">
        <input
          #link
          formControlName="Link"
          matInput
          placeholder="Сайт"
          [maxlength]="maxWebsiteLength"
        />
        <mat-hint align="end">{{ link.value.length }} / {{ maxWebsiteLength }}</mat-hint>
        <mat-error *ngIf="form && form.touched && form.get('Link').hasError('url')">
          Некорректный формат ссылки
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mh2">
        <textarea
          #description
          matInput
          formControlName="Description"
          placeholder="Описание *"
          [maxlength]="maxDescriptionLength"
          hotTranslatableField
          [language]="currentLanguage"
          [defaultLanguage]="defaultLanguage"
        ></textarea>
        <mat-hint align="end">{{ description.value.length }} / {{ maxDescriptionLength }}</mat-hint>
        <mat-error *ngIf="form.touched && form.get('Description').hasError('required')">
          Поле не должно быть пустым
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('Description').hasError('AverageBill')">
          Язык по умолчанию должен быть заполнен
        </mat-error>
        <mat-error *ngIf="form.touched && form.get('Description').hasError('blank')">
          Поле не должно состоять из одних пробелов
        </mat-error>
      </mat-form-field>

      <div class="ma2 flex flex-column">
        <span class="hot-property-form-text mb1 mt3">Фотография *</span>
        <hot-upload-file
          [fileUrl]="restaurant ? restaurant.imageUrl : ''"
          (change)="onUploadLogo($event)"
        >
        </hot-upload-file>
      </div>
      <div class="flex justify-end ma2">
        <button class="mr3" mat-button color="primary" (click)="onCancel()">
          {{ 'forms.action.cancel' | translate: locale.language }}
        </button>
        <button mat-raised-button color="primary" [disabled]="!enableSubmit" (click)="onSubmit()">
          {{ 'forms.action.save' | translate: locale.language }}
        </button>
        <button
          *ngIf="enableTranslate"
          class="ml3"
          mat-raised-button
          color="primary"
          [disabled]="!enableSubmit"
          (click)="onSubmit(true)"
        >
          {{ 'forms.action.saveAndTranslate' | translate: locale.language }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
