import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'

import * as fromRoot from '@redux'
import * as propertyActions from './property.actions'
import { PropertyGoalType, Integration } from '@models'
import { map, take, mergeMap, shareReplay, switchMap, distinctUntilChanged } from 'rxjs/operators'
import { Observable, race, throwError, of, NEVER } from 'rxjs'
import * as R from 'ramda'

const onboardingGoalTypes: PropertyGoalType[] = [
  'add_members',
  'connect_messenger',
  'verify_faq',
  'verify_services',
]

let prevProperty: any = null
const distinctPropertyUntilChanged = <T>(x: T) => {
  const isEqual = R.equals(prevProperty, x)
  prevProperty = x
  if (isEqual) {
    return NEVER
  }
  return of(x)
}

@Injectable({
  providedIn: 'root',
})
export class PropertyFacade {
  public actions = propertyActions
  public properties$ = this.store$.pipe(select(fromRoot.selectProperties))
  public property$ = this.store$.pipe(
    select(fromRoot.selectCurrentProperty),
    mergeMap(distinctPropertyUntilChanged),
    shareReplay(1)
  )

  public integrations$ = this.store$.pipe(select(fromRoot.selectCurrentPropertyIntegrations))
  public availableIntegrations$ = this.store$.pipe(
    select(fromRoot.selectCurrentPropertyAvailableIntegrations)
  )

  public propertyId$ = this.property$.pipe(
    map(p => p._id),
    distinctUntilChanged()
  )

  constructor(private store$: Store<fromRoot.State>, private actions$: Actions) {}

  getPropertyGoals(goals: PropertyGoalType[]) {
    return this.property$.pipe(
      map(property => {
        if (!property || !property.goals) {
          return []
        }
        const onboardingGoals = property.goals.filter(x => goals.includes(x.name))
        return onboardingGoals
      })
    )
  }

  getOnboardingGoals() {
    return this.getPropertyGoals(onboardingGoalTypes)
  }

  loadProperty(propertyId: string) {
    this.store$.dispatch(new propertyActions.LoadItem({ propertyId }))
    return this.successOrFail<propertyActions.LoadItemSuccess>(
      propertyActions.PropertyActionTypes.LoadItemSuccess,
      propertyActions.PropertyActionTypes.LoadItemFail
    )
  }

  reloadProperty() {
    const result$ = this.property$.pipe(
      take(1),
      switchMap(property => {
        if (property) {
          return this.loadProperty(property._id)
        }
        return throwError('No property')
      }),
      shareReplay(1)
    )
    result$.subscribe()
    return result$
  }

  loadPropertyIntegrations() {
    this.property$.pipe(take(1)).subscribe(property => {
      this.store$.dispatch(new propertyActions.LoadIntegrations({ propertyId: property._id }))
    })
    return this.successOrFail<propertyActions.LoadIntegrationsSuccess>(
      propertyActions.PropertyActionTypes.LoadIntegrationsSuccess,
      propertyActions.PropertyActionTypes.LoadIntegrationsFail
    )
  }

  updatePropertyIntegrations(integrations: Integration[]) {
    this.property$.pipe(take(1)).subscribe(property => {
      this.store$.dispatch(
        new propertyActions.UpdateIntegrations({ propertyId: property._id, integrations })
      )
    })
    return this.successOrFail<propertyActions.UpdateIntegrationsSuccess>(
      propertyActions.PropertyActionTypes.UpdateIntegrationsSuccess,
      propertyActions.PropertyActionTypes.UpdateIntegrationsFail
    )
  }

  validateIntegration(integration: Integration) {
    this.property$.pipe(take(1)).subscribe(property => {
      this.store$.dispatch(
        new propertyActions.ValidateIntegration({ propertyId: property._id, integration })
      )
    })
    return this.successOrFail<propertyActions.UpdateIntegrationsSuccess>(
      propertyActions.PropertyActionTypes.ValidateIntegrationSuccess,
      propertyActions.PropertyActionTypes.ValidateIntegrationFail
    )
  }

  removeIntegration({ index }) {
    return this.integrations$.pipe(
      take(1),
      switchMap(integrations => {
        return this.updatePropertyIntegrations(R.remove(index, 1, integrations))
      })
    )
  }

  completeGoals(goalTypes: PropertyGoalType[]) {
    this.property$.pipe(take(1)).subscribe(property => {
      this.store$.dispatch(
        new propertyActions.CompleteGoal({ propertyId: property._id, goalTypes })
      )
    })

    return this.successOrFail<propertyActions.CompleteGoalSuccess>(
      propertyActions.PropertyActionTypes.CompleteGoalSuccess,
      propertyActions.PropertyActionTypes.CompleteGoalFail
    )
  }

  skipGoals(goalTypes: PropertyGoalType[]) {
    this.property$.pipe(take(1)).subscribe(property => {
      this.store$.dispatch(new propertyActions.SkipGoal({ propertyId: property._id, goalTypes }))
    })

    return this.successOrFail<propertyActions.SkipGoalSuccess>(
      propertyActions.PropertyActionTypes.SkipGoalSuccess,
      propertyActions.PropertyActionTypes.SkipGoalFail
    )
  }

  private successOrFail<T>(successType: string, failType: string) {
    return race(
      this.actions$.pipe(
        ofType(successType),
        take(1)
      ),
      this.actions$.pipe(
        ofType(failType),
        take(1),
        mergeMap(x => throwError(x))
      )
    ) as Observable<T>
  }
}
