import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { AuthService, ConfigService } from '@shared/services'
import { Component, OnInit, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { Store, select } from '@ngrx/store'

import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

import * as fromRoot from '@redux/reducers'
import * as fromUser from '@redux/user'
import { blankValidator } from '@shared/validators'

const PASSWORD_MIN_LENGTH = 6
const PASSWORD_MAX_LENGTH = 300

@Component({
  selector: 'hot-auth-reset',
  templateUrl: './reset.container.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
        align-items: flex-start;
      }
      .auth-form-container {
        width: 400px;
      }
    `,
  ],
})
export class ResetPasswordContainer implements OnInit {
  public userId$: Observable<string>
  public setPasswordForm: FormGroup
  public token$ = this.store$.pipe(select(fromRoot.selectResetPasswordToken))
  public tokenValidationStatus$ = this.store$.pipe(
    select(fromRoot.selectResetPasswordTokenValidationStatus)
  )
  public tokenValidationStatus = null
  public token = null
  public statuses = fromUser.ValidateTokenStatus

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private store$: Store<fromRoot.State>,
    private config: ConfigService,
    public authService: AuthService
  ) {}

  public ngOnInit() {
    this.setPasswordForm = new FormGroup({
      password: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          blankValidator,
          Validators.minLength(PASSWORD_MIN_LENGTH),
          Validators.maxLength(PASSWORD_MAX_LENGTH),
        ])
      ),
      passwordConfirmation: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          blankValidator,
          Validators.minLength(PASSWORD_MIN_LENGTH),
          Validators.maxLength(PASSWORD_MAX_LENGTH),
        ])
      ),
    })

    this.token$.pipe(filter(Boolean)).subscribe((x: string) => {
      this.token = x
      this.store$.dispatch(new fromUser.ValidateResetPasswordToken({ token: x }))
    })

    this.tokenValidationStatus$.subscribe(x => {
      this.tokenValidationStatus = x
    })
    this.formControlValueChanges()
  }

  public formControlValueChanges() {
    const p1 = this.setPasswordForm.get('password')
    const p2 = this.setPasswordForm.get('passwordConfirmation')
    p1.valueChanges.subscribe(value => {
      p2.setErrors(value !== p2.value ? { passwordsMismatch: true } : null)
    })
    p2.valueChanges.subscribe(value => {
      p2.setErrors(value !== p1.value ? { passwordsMismatch: true } : null)
    })
  }

  public submit() {
    const formData = this.setPasswordForm.value
    this.store$.dispatch(new fromUser.SetPassword({ ...formData, token: this.token }))
  }

  public get enableSubmit(): boolean {
    return (
      this.setPasswordForm &&
      this.setPasswordForm.dirty &&
      this.setPasswordForm.valid &&
      this.setPasswordForm.get('password').value ===
        this.setPasswordForm.get('passwordConfirmation').value
    )
  }

  public get passwordMinLength() {
    return PASSWORD_MIN_LENGTH
  }

  public get passwordMaxLength() {
    return PASSWORD_MAX_LENGTH
  }

  public get isBookingSuiteEnabled() {
    return this.config.BOOKING_SUITE_ENABLED
  }
}
