import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import * as R from 'ramda'

import { distinctUntilChanged, takeUntil, filter, startWith } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { DialogFlowService } from '@shared/services/dialog-flow.service'
import { Subject } from 'rxjs'
import { BuilderTracking, intentFallbackCodes } from '@models'

@Component({
  selector: 'hot-builder-tracking',
  template: `
    <div class="flex flex-column w-100">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Tracking</mat-label>
        <mat-chip-list #trackingList>
          <ng-container *ngFor="let code of pageTrackingCodes">
            <mat-chip *ngIf="code && code.value"
                      [class.hot-user-tag]="code && code.manuallyAdded"
                      [class.hot-user-faq]="code && isFaqItem(code)"
                      [selectable]="selectable"
                      [removable]="removable"
                      [disabled]="isEditMode"
                      (removed)="remove(code.value)">
              {{ code.viewValue || code.value }}
              <mat-icon [class.hot-user-remove-icon]="code && code.manuallyAdded" matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input
            #trackingInput
            [formControl]="trackingCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="trackingList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let code of filteredCodes" [value]="code">
            {{ code }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
      }

      .hot-user-tag {
        background-color: #2278cf;
        color: white;
      }

      .hot-user-faq {
        background-color: orange;
      }

      mat-chip {
        margin-bottom: 0.2rem !important;
      }

      .hot-user-remove-icon {
        color: white !important;
      }

      .hot-no-tags-container {
        font-size: 0.7rem;
        margin-top: -0.8rem;
      }

      .no-hot-tags-text {
        transition: all 0.5s ease-in 0.2s
      }

      .hide-no-hot-tags {
        opacity: 0;
      }

      .show-no-hot-tags {
        opactity: 1;
      }
    `,
  ],
})
export class BuilderTrackingComponent implements OnInit, OnChanges {
  @Input()
  public set trackings(val: BuilderTracking[]) {
    this.pageTrackingCodes = val || []
    this.autoTagsDetected = R.any(t => !t.manuallyAdded, this.pageTrackingCodes)
  }

  @Input() public isEditMode: boolean
  @Output() public update = new EventEmitter<BuilderTracking[]>()
  @ViewChild('trackingInput', { static: true }) trackingInput: ElementRef

  public pageTrackingCodes: BuilderTracking[] = []
  public visible = true
  public selectable = true
  public removable = true
  public addOnBlur = false
  public separatorKeysCodes = [ENTER, COMMA]
  public trackingCtrl = new FormControl()
  public filteredCodes: string[] = []
  public trackingCodes: string[] = []

  private destroyed = new Subject()
  private autoTagsDetected = false

  public constructor(private dialogFlowService: DialogFlowService) {}

  public ngOnChanges(changes) {
    if (changes.isEditMode) {
      if (changes.isEditMode.currentValue) {
        this.trackingCtrl.disable()
      } else {
        this.trackingCtrl.enable()
      }
    }
  }

  public ngOnInit(): void {
    this.dialogFlowService
      .getTagsList()
      .pipe(filter(Boolean), distinctUntilChanged(), takeUntil(this.destroyed))
      .subscribe(({ results }) => {
        this.trackingCodes = results.filter(code => !intentFallbackCodes.includes(code)) || []
        this.subscribeTrackingsChange()
      })
  }

  public isFaqItem(code) {
    return code.viewValue ? code.viewValue.includes('faq item') : code.value.includes('faq item')
  }

  public get tagsDetected(): boolean {
    return this.autoTagsDetected
  }

  private subscribeTrackingsChange(): void {
    this.trackingCtrl.valueChanges.pipe(startWith('')).subscribe(code => {
      this.filteredCodes = code ? this.filter(code) : this.trackingCodes.slice()
    })
  }

  public viewValueToSaveValue(value) {
    return value.includes(':') ? R.drop(1, value.trim().split(':')).join('') : value.trim()
  }

  add(event: MatChipInputEvent): void {
    const value = (this.trackingCtrl.value ? this.trackingCtrl.value.trim() : '') as string
    if (
      R.contains(value, this.trackingCodes) &&
      !R.contains(value, this.pageTrackingCodes.map(c => c.viewValue))
    ) {
      this.pageTrackingCodes.push({
        viewValue: value.trim(),
        value: this.viewValueToSaveValue(value),
        manuallyAdded: true,
      })
      this.update.next(this.pageTrackingCodes)
    }
    if (event.input) {
      event.input.value = ''
    }
    this.trackingCtrl.setValue(null)
  }

  remove(code: string): void {
    if (this.isEditMode) {
      return
    }
    const index = this.pageTrackingCodes.map(c => c.value).indexOf(code)
    if (index >= 0) {
      this.removeTrackingCodeByIndex(index)
    }
  }

  private removeTrackingCodeByIndex(index: number): void {
    this.pageTrackingCodes.splice(index, 1)
    this.update.next(this.pageTrackingCodes)
  }

  filter(name: string) {
    return this.trackingCodes.filter(code => code.toLowerCase().includes(name.toLowerCase()))
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!R.contains(event.option.viewValue, this.pageTrackingCodes.map(c => c.viewValue))) {
      this.pageTrackingCodes.push({
        viewValue: event.option.viewValue,
        value: this.viewValueToSaveValue(event.option.viewValue),
        manuallyAdded: true,
      })
      this.update.next(this.pageTrackingCodes)
    }
    this.trackingInput.nativeElement.value = ''
    this.trackingCtrl.setValue(null)
  }
}
