import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResults, NormalizedResult, ProductCategory } from '@models'
import { LanguageType } from '@shared/constants'

@Injectable()
export class ProductCategoryService {
  constructor(public readonly apiService: ApiService) {}

  public create(
    propertyId: string,
    roomServiceId: string,
    productCategory: ProductCategory,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[]
  ): Observable<NormalizedResult> {
    return this.apiService.post(
      `properties/${propertyId}/roomService/${roomServiceId}/categories`,
      {
        productCategory,
        sourceLanguage,
        targetLanguages,
      }
    )
  }

  public get(payload): Observable<NormalizedResults> {
    const { propertyId, roomServiceId } = payload
    return this.apiService.get(`properties/${propertyId}/roomService/${roomServiceId}/categories/`)
  }

  public update(
    propertyId: string,
    roomServiceId: string,
    productCategoryId: string,
    productCategory: ProductCategory,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[],
    forceTranslate: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(
      `properties/${propertyId}/roomService/${roomServiceId}/categories/${productCategoryId}`,
      {
        productCategory,
        sourceLanguage,
        targetLanguages,
        forceTranslate,
      }
    )
  }

  public delete(
    propertyId: string,
    roomServiceId: string,
    productCategoryId: string
  ): Observable<NormalizedResult> {
    return this.apiService.delete(
      `properties/${propertyId}/roomService/${roomServiceId}/categories/${productCategoryId}`
    )
  }
}
