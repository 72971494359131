import { Component, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { MenuContent, UploadFileStatus } from '@models'
import { MenuContentType } from '@models/builder.model'
import { ImageCropperComponent } from '@shared/components/image-cropper/image-cropper.component'

@Component({
  selector: 'hot-builder-images',
  templateUrl: './builder-images.component.html',
  styleUrls: ['./builder-images.component.scss'],
})
export class BuilderImagesComponent {
  @Input() images: MenuContent[]
  @Input() availableLanguages: string[]
  @Input() public currentLanguage: string
  @Input() public isEditMode: boolean
  @Output() public update = new EventEmitter()
  @Output() public remove = new EventEmitter()
  @Output() public editModeChange = new EventEmitter<boolean>()
  @ViewChild('imageLoader', { static: true }) public imageLoader: ImageCropperComponent

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  handleAddImage() {
    if (!this.imageLoader.loaded && !this.isEditMode) {
      this.imageLoader.openFileDialog()
    }
  }

  handleRemove(index, type) {
    this.remove.emit({ index, type })
  }

  onImageChange($event) {
    const status = $event.status
    const data = $event.data
    this.editModeChange.emit(true)

    if (status === UploadFileStatus.COMPLETED || status === UploadFileStatus.FAILED || !status) {
      this.editModeChange.emit(false)
    }

    if (status === UploadFileStatus.COMPLETED) {
      const newImage = {
        type: MenuContentType.IMAGE,
        translations: {
          default: encodeURI(data.url),
        },
      }

      this.images.push(newImage as MenuContent)
      this.imageLoader.cancelUploadImage()
      this.update.emit()
    }
  }
}
