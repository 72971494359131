import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import * as shopProductActions from './shop-product.actions'
import { ShopProductService } from './shop-product.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class ShopProductEffects {
  // Create

  @Effect()
  public create$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.Create),
    switchMap((action: shopProductActions.Create) => {
      const meta = action.payload
      return this.shopProductService
        .create(
          action.payload.propertyId,
          action.payload.shopId,
          action.payload.shopProduct,
          action.payload.sourceLanguage,
          action.payload.targetLanguages
        )
        .pipe(
          map(x => {
            return new shopProductActions.CreateSuccess(x, meta)
          }),
          catchError(x => of(new shopProductActions.CreateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.CreateSuccess),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.createProductSuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.CreateFail),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.createProductFail')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  // Update
  @Effect()
  update$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.Update),
    switchMap((action: shopProductActions.Update) => {
      const meta = action.payload
      return this.shopProductService
        .update(
          action.payload.propertyId,
          action.payload.shopId,
          action.payload.shopProductId,
          action.payload.shopProduct,
          action.payload.sourceLanguage,
          action.payload.targetLanguages,
          action.payload.forceTranslate
        )
        .pipe(
          map(x => new shopProductActions.UpdateSuccess(x, meta)),
          catchError(x => of(new shopProductActions.UpdateFail(x, meta)))
        )
    })
    // tap(() => {
    //   const message = this.translationService.translate(
    //     'RoomService.notification.updateShopProductSuccess'
    //   )
    //   this.matSnackBar.open(message, '', {
    //     panelClass: ['bg-green'],
    //     duration: 2000,
    //   })
    // })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.UpdateSuccess),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.updateProductSuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.UpdateFail),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.updateProductFail')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  // Delete
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.Delete),
    switchMap((action: shopProductActions.Delete) => {
      const meta = action.payload
      return this.shopProductService
        .delete(action.payload.propertyId, action.payload.shopId, action.payload.shopProductId)
        .pipe(
          map(x => {
            return new shopProductActions.DeleteSuccess(x, meta)
          }),
          catchError(x => of(new shopProductActions.DeleteFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.DeleteSuccess),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.deleteProductSuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(shopProductActions.ShopProductActionTypes.DeleteFail),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.deleteProductFail')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private shopProductService: ShopProductService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
