import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

import { Store } from '@ngrx/store'

import { of } from 'rxjs'

import * as fromRoot from '@redux'
import * as fromChats from '@redux/chat'

@Injectable()
export class SelectChatGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}
  canActivate(router: ActivatedRouteSnapshot) {
    const chatId = router.params.chatId
    this.store$.dispatch(new fromChats.Select({ chatId: chatId }))
    return of(true)
  }
}
