import { Component, OnInit, OnDestroy, Inject } from '@angular/core'

import { filter, takeUntil, distinctUntilChanged, take, map } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'

import * as fromRoot from '@redux'
import * as fromRouter from '@redux/router'
import { Property, filterPropertyByQuery } from '@models'
import { AuthService } from '@shared/services'
import { Subject } from 'rxjs'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'hot-home-page',
  template: `
    <ng-template #emptyHeader>
      <hot-page-heading>
        <span page-title></span>
      </hot-page-heading>
    </ng-template>
    <ng-container *ngIf="(propertiesList$ | async); else emptyHeader">
      <hot-page-heading>
        <span page-title>{{ 'Home.title' | translate: locale.language }}</span>
        <!--<span page-subtitle>Отелей: {{ (properties$ | async).length }}</span>-->
      </hot-page-heading>

      <hot-page-content>
        <div *ngIf="isAdmin" class="mb3 flex justify-end">
          <hot-search-input (queryChange)="onQueryChange($event)"></hot-search-input>
        </div>
        <div class="links" [class.links--small]="properties.length > 5">
          <div
            *ngFor="let property of filteredProperties"
            class="hot-property-panel mb2 hover-bg-washed-blue"
          >
            <a
              class="flex items-center hot-property-link link flex-auto"
              [routerLink]="['/properties', property._id]"
            >
              <hot-property-logo [showTooltip]="false" [property]="property"></hot-property-logo>
              <span class="flex-ns items-center">
                <div>
                  <span class="hot-property-name db ml3">{{ property.name }}</span>
                  <span class="f6 db ml3 T-text-muted">{{
                    property.description | selectLanguage: locale.language
                  }}</span>
                </div>

                <span
                  *ngIf="
                    property.badges?.openedRequests ||
                    property.badges?.openedReviews ||
                    property.badges?.openedChats
                  "
                  class="f6 dib ml3 hot-property-info"
                >
                  <span *ngIf="property.badges.openedRequests"
                    >{{ 'Home.openedRequests' | translate: locale.language }}&nbsp;-&nbsp;{{
                      property.badges.openedRequests
                    }}</span
                  >
                  <span *ngIf="property.badges.openedReviews"
                    >{{ 'Home.openedReviews' | translate: locale.language }}&nbsp;-&nbsp;{{
                      property.badges.openedReviews
                    }}</span
                  >
                  <span *ngIf="property.badges.openedChats"
                    >{{ 'Home.openedChats' | translate: locale.language }}&nbsp;-&nbsp;{{
                      property.badges.openedChats
                    }}</span
                  >
                </span>
              </span>
            </a>
            <ng-container *ngIf="isBookingHotel(property)">
              <mat-icon
                svgIcon="hot:booking"
                class="booking-logo"
                title="BookingSuite hotel"
              ></mat-icon>
            </ng-container>
            <ng-container *ngIf="isAdmin">
              <a
                matTooltip="Menu builder"
                class="dn db-ns hot-property-link link black-40 hover-blue hover-bg-lightest-blue br2 mh1"
                [routerLink]="['/properties', property._id, 'settings', 'builder']"
              >
                <mat-icon>chat</mat-icon>
              </a>
              <a
                matTooltip="Edit property"
                class="dn db-ns hot-property-link link black-40 hover-blue hover-bg-lightest-blue br2 mh1"
                [routerLink]="['/admin/objects/properties', property._id]"
              >
                <mat-icon>edit</mat-icon>
              </a>
            </ng-container>
          </div>
        </div>
      </hot-page-content>
      <!--
    <h2 class="pa3">Dashboard</h2>
    <div class="bg-white pa3">
      <pre>Current property: {{ property$ | async | json }}</pre>
    </div>
    -->
    </ng-container>
  `,
  styleUrls: [`./home-page.component.scss`],
})
export class HomePageComponent implements OnInit, OnDestroy {
  private destroyed = new Subject()

  public properties$ = this.store$.pipe(select(fromRoot.selectProperties))
  public propertiesList$ = this.properties$.pipe(map(xs => (xs.length > 1 ? xs : null)))
  public property$ = this.store$.pipe(select(fromRoot.selectCurrentProperty))
  public isAdmin = false
  public properties: Property[] = []
  public filteredProperties: Property[] = []

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private readonly store$: Store<fromRoot.State>,
    public readonly authService: AuthService
  ) {}

  public ngOnInit() {
    this.properties$.subscribe(properties => {
      this.properties = properties
      this.filteredProperties = properties.filter(filterPropertyByQuery(null))
    })
    this.properties$
      .pipe(
        filter(xs => xs.length > 0),
        take(1),
        takeUntil(this.destroyed)
      )
      .subscribe(properties => {
        if (properties.length === 1) {
          this.store$.dispatch(new fromRouter.Go({ path: ['/properties', properties[0]._id] }))
        }
      })

    this.authService.isAdmin$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed)
      )
      .subscribe(isAdmin => {
        this.isAdmin = isAdmin
      })
  }

  public ngOnDestroy() {
    this.destroyed.next()
  }

  public isBookingHotel(property) {
    return property.source === 'bookingsuite'
  }

  public onQueryChange(query: string) {
    this.filteredProperties = this.properties.filter(filterPropertyByQuery(query))
  }
}
