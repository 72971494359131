export enum ChartType {
  Line = 'line',
  Bar = 'bar',
  HorizontalBar = 'horizontalBar',
  Pie = 'pie',
  Donut = 'doughnut',
}

enum AxisPosition {
  Left = 'left',
  Right = 'right',
}

export const defaultChartOptions = {
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.2,
      borderWidth: 5,
      spanGaps: true,
      offset: false,
    },
    rectangle: {
      borderWidth: 0,
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          color: 'rgba(144,146,149,0.1)',
        },
        ticks: {
          autoSkip: true,
          beginAtZero: true,
          suggestedMax: 10,
        },
        position: AxisPosition.Left,
      },
    ],
    xAxes: [
      {
        gridLines: {
          color: 'rgba(144,146,149,0.1)',
        },
        ticks: {
          display: false,
          autoSkip: false,
          beginAtZero: true,
        },
      },
    ],
  },
  crosshair: {},
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    display: false,
  },
}

export const defaultOptionsByType = {
  [ChartType.Pie]: {
    scales: {},
  },
  [ChartType.Donut]: {
    scales: {},
    circumference: Math.PI,
    rotation: -Math.PI,
    animation: {
      animateRotate: true,
      animateScale: false,
    },
  },
  [ChartType.Line]: {
    hover: { intersect: false, mode: 'index' },
    tooltips: { intersect: false, mode: 'index' },
  },
  [ChartType.HorizontalBar]: {
    hover: { intersect: false, mode: 'index' },
    tooltips: { enabled: false },
    layout: {
      padding: {
        top: 0,
        right: 30,
        bottom: 0,
        left: 0,
      },
    },
    plugins: {
      datalabels: {
        color: ctx => ctx.dataset.hoverBackgroundColor,
        clamp: true,
        display: true,
        borderRadius: 4,
        font: {
          weight: 'bold',
        },
        align: 'end',
        anchor: 'end',
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: 'rgba(144,146,149,0.1)',
          },
          ticks: {
            autoSkip: false,
            beginAtZero: true,
          },
          position: AxisPosition.Left,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: 'rgba(144,146,149,0.1)',
          },
          ticks: {
            display: false,
            // autoSkip: false,
            beginAtZero: true,
          },
        },
      ],
    },
  },
  [ChartType.Bar]: {
    layout: {
      padding: {
        top: 20,
        right: 8,
        bottom: 8,
        left: 0,
      },
    },
    hover: { intersect: false, mode: 'index' },
    tooltips: { intersect: false, mode: 'index' },
  },
}

export const getOverridesForType = (type: ChartType) => {
  return defaultOptionsByType[type] || null
}
