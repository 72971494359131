import { without, indexBy } from 'ramda'
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core'
import { FacilityGroup } from '@models'
import { FormControl } from '@angular/forms'
import { startWith, map } from 'rxjs/operators'
import { selectLanguage } from '@shared/pipes'
import { LanguageType } from '@shared/constants'
import { Subject, combineLatest } from 'rxjs'

@Component({
  selector: 'hot-builder-facilities-groups',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>Facilities</mat-label>
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let item of value"
          [selectable]="true"
          [removable]="true"
          (removed)="remove(item)">
          {{ getLabel(item) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input type="text"
          #groupInput
          [formControl]="inputControl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let group of (filteredGroups$ | async)" [value]="group._id">
          {{ group.name | selectLanguage: 'en' }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: [``],
})
export class BuilderFacilitiesGroupsComponent implements OnInit, OnChanges {
  @Input() facilitiesGroups: FacilityGroup[] = []
  @Input() value: string[] = []

  @Output() update = new EventEmitter<string[]>()

  @ViewChild('groupInput', { static: true }) groupInput: ElementRef

  changed = new Subject()
  inputControl = new FormControl()
  filteredGroups$ = combineLatest(
    this.changed.pipe(startWith(null)),
    this.inputControl.valueChanges.pipe(startWith(null))
  ).pipe(
    map(([_, term]) => term),
    map(term => {
      const lcTerm = term && term.toLowerCase()
      const ids = new Set(this._value)
      return lcTerm
        ? this.facilitiesGroups.filter(x => {
            if (ids.has(x._id)) {
              return false
            }
            const name = selectLanguage(x.name, LanguageType.EN) || ''
            return name.toLowerCase().includes(lcTerm)
          })
        : this.facilitiesGroups.filter(x => !ids.has(x._id))
    })
  )

  private _value: string[] = []
  private groupsByIndex: { [key: string]: FacilityGroup } = {}

  getLabel(id) {
    const group = this.groupsByIndex[id]
    return (group && group.name && selectLanguage(group.name, LanguageType.EN)) || '<deleted>'
  }

  constructor() {}

  updateValue(value) {
    this.update.next(value)
  }

  public remove(item) {
    this.updateValue(without(item, this._value))
  }

  public selected($event) {
    const item = $event.option.value
    this.updateValue(without(item, this._value).concat([item]))
    this.groupInput.nativeElement.value = ''
    this.inputControl.setValue(null)
  }

  ngOnInit(): void {}

  ngOnChanges(changes) {
    if (changes.value) {
      this._value = this.value || []
    }
    if (changes.facilitiesGroups) {
      this.groupsByIndex = indexBy(x => x._id, this.facilitiesGroups)
    }
    this.changed.next()
  }
}
