import { Action } from '@ngrx/store'
import { NormalizedResult, ShopProduct } from '@models'
import { LanguageType } from '@shared/constants'

export enum ShopProductActionTypes {
  Create = '[ShopProduct] Create',
  CreateSuccess = '[ShopProduct] Create Success',
  CreateFail = '[ShopProduct] Create Fail',

  Load = '[ShopProduct] Load',
  LoadSuccess = '[ShopProduct] Load Success',
  LoadFail = '[ShopProduct] Load Fail',

  Update = '[ShopProduct] Update',
  UpdateSuccess = '[ShopProduct] Update Success',
  UpdateFail = '[ShopProduct] Update Fail',

  Delete = '[ShopProduct] Delete',
  DeleteSuccess = '[ShopProduct] Delete Success',
  DeleteFail = '[ShopProduct] Delete Fail',
}

/**
 * Create ShopProduct actions
 */

export interface CreatePayload {
  propertyId: string
  shopId: string
  shopProduct: ShopProduct
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
}

export class Create implements Action {
  readonly type = ShopProductActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = ShopProductActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = ShopProductActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load ShopProduct actions
 */

// export interface LoadPayload {
//   propertyId?: string
//   shopId?: string
// }

// export class Load implements Action {
//   readonly type = ShopProductActionTypes.Load
//   constructor(public payload: LoadPayload = {}) {}
// }

// export class LoadSuccess implements Action {
//   readonly type = ShopProductActionTypes.LoadSuccess
//   constructor(public payload, public meta: LoadPayload) {}
// }

// export class LoadFail implements Action {
//   readonly type = ShopProductActionTypes.LoadFail
//   constructor(public error: any, public meta: LoadPayload) {}
// }

/**
 * Update ShopProduct actions
 */
export interface UpdatePayload {
  propertyId: string
  shopId: string
  shopProductId: string
  shopProduct: ShopProduct
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
  forceTranslate: boolean
}

export class Update implements Action {
  readonly type = ShopProductActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = ShopProductActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = ShopProductActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete ShopProduct actions
 */
export interface DeletePayload {
  propertyId: string
  shopId: string
  shopProductId: string
}

export class Delete implements Action {
  readonly type = ShopProductActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = ShopProductActionTypes.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = ShopProductActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type ShopProductActions =
  | Create
  | CreateSuccess
  | CreateFail
  // | Load
  // | LoadSuccess
  // | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
