const DEFAULT_CANVAS_WIDTH = 300
const DEFAULT_CANVAS_HEIGHT = 300

let canvasContext: CanvasRenderingContext2D = null
export const getContext = () => {
  if (!canvasContext) {
    canvasContext = document.createElement('canvas').getContext('2d')
  }
  return canvasContext
}

export const createLinearGradient = (x0, y0, x1, y1) => {
  return getContext().createLinearGradient(x0, y0, x1, y1)
}

export const createTopToBottomGradient = (
  color1,
  color2,
  { height = DEFAULT_CANVAS_HEIGHT } = {}
) => {
  const g = createLinearGradient(0, 0, 0, height)
  g.addColorStop(0, color1)
  g.addColorStop(1, color2)
  return g
}

export const createLeftToRightGradient = (
  color1,
  color2,
  { width = DEFAULT_CANVAS_WIDTH } = {}
) => {
  const g = createLinearGradient(0, 0, width, 0)
  g.addColorStop(0, color1)
  g.addColorStop(1, color2)
  return g
}
