import { Pipe, PipeTransform } from '@angular/core'
import { Chat } from '@models'
import { fullName } from './full-name.pipe'

const MAX_LENGTH = 18

export const chatName = (chat: Chat, mode?: 'trim' | null): string | null => {
  if (!chat) {
    return ''
  }
  const name =
    (chat.name ? chat.name : chat.user ? fullName(chat.user) : null) || chat.fallbackName || ''
  return mode === 'trim' && name.length > MAX_LENGTH
    ? name.slice(0, MAX_LENGTH).trim() + '...'
    : name
}

@Pipe({
  name: 'chatName',
})
export class ChatNamePipe implements PipeTransform {
  transform = chatName
}
