import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResults, NormalizedResult } from '@models'

@Injectable()
export class ShopCartService {
  constructor(public readonly apiService: ApiService) {}

  public getList(propertyId: string, params?: any): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/shop-carts`, {
      params: {
        status: params.status || '',
        page: params.page,
        limit: params.limit,
      },
    })
  }

  public get(propertyId: string, shopCartId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/shop-carts/${shopCartId}`)
  }

  public updateStatus(
    propertyId: string,
    shopCartId: string,
    { status, comment }
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/shop-carts/${shopCartId}`, {
      status: status,
      comment: comment,
    })
  }

  public cancellationReject(propertyId: string, shopCartId: string): Observable<NormalizedResult> {
    return this.apiService.delete(
      `properties/${propertyId}/shop-carts/${shopCartId}/cancellation_reject`
    )
  }

  public cancellationAccept(
    propertyId: string,
    shopCartId: string,
    comment?: string
  ): Observable<NormalizedResult> {
    return this.apiService.post(
      `properties/${propertyId}/shop-carts/${shopCartId}/cancellation_accept`,
      { comment }
    )
  }
}
