import { AbstractControl } from '@angular/forms'
import * as R from 'ramda'

interface Translate {
  lang: string
  value: string
}

interface TranslatableAbstractControl extends AbstractControl {
  value: Translate[]
}

export const translatableTextValidator = (control: TranslatableAbstractControl) => {
  if (
    control &&
    control.value &&
    control.value.length
  ) {
    if (R.all(v => v.value && v.value.trim().length === 0, control.value)) {
      return {
        blank: true,
      }
    }
    return null
  }
  return {
    blank: true,
  }
}
