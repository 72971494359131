import { Pipe, PipeTransform } from '@angular/core'
import { I18nService } from '@shared/services'

@Pipe({
  name: 'segmentTypeLabel',
})
export class SegmentTypeLabelPipe implements PipeTransform {
  constructor(private translation: I18nService) {}
  transform(segmentType: any, _lang = 'ru'): string {
    const key = 'Push.targetSegment.' + segmentType
    return this.translation.translate(key)
  }
}
