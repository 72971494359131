import { Component, OnDestroy, OnInit, Inject } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { PropertyService } from '@redux/property/property.service'
import { Store, select } from '@ngrx/store'
import { User } from '@models'
import { distinctUntilChanged, filter, takeUntil, switchMap } from 'rxjs/operators'
import * as fromRoot from '@redux/reducers'
import { Subject } from 'rxjs'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { QRCodeDialogComponent } from './qrcode-dialog/qrcode-dialog.component'

@Component({
  selector: 'hot-messenger-notification-hint',
  templateUrl: './messenger-notification-hint.component.html',
  styleUrls: ['./messenger-notification-hint.component.scss'],
})
export class MessengerNotificationHintComponent implements OnInit, OnDestroy {
  private destroyed = new Subject()
  public user: User = null
  public channels: any[] = []
  public propertyId: string = null

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private store$: Store<fromRoot.State>,
    private propertyService: PropertyService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store$
      .pipe(
        select(fromRoot.selectAuthorizedUser),
        filter(x => x !== null),
        distinctUntilChanged(),
        takeUntil(this.destroyed)
      )
      .subscribe(user => {
        this.user = user
      })
    this.store$
      .pipe(
        select(fromRoot.selectCurrentPropertyId),
        filter(x => x !== null),
        distinctUntilChanged(),
        takeUntil(this.destroyed),
        switchMap(id => {
          this.propertyId = id
          return this.propertyService.getChannels(id)
        })
      )
      .subscribe(({ channels }) => {
        this.channels = channels.filter(({ type }) =>
          ['viber', 'facebook', 'telegram'].includes(type)
        )
      })
  }

  ngOnDestroy() {
    this.destroyed.next()
  }

  public onChannelClick(channel): MatDialogRef<QRCodeDialogComponent> {
    return this.dialog.open(QRCodeDialogComponent, {
      width: '420px',
      data: {
        channel,
        token: this.user.managerModeToken,
        propertyId: this.propertyId,
      },
    })
  }
}
