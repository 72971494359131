import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router'

import { Store } from '@ngrx/store'

import { Observable, of } from 'rxjs'
import { filter, mapTo, catchError, exhaustMap, tap, take } from 'rxjs/operators'

import * as fromRoot from '@redux'
import * as fromProperty from '@redux/property'
import { PropertyFacade } from '@redux/property/property.facade'
import { Property } from '@models'

@Injectable()
export class PropertyGuard implements CanActivate {
  constructor(
    private store$: Store<fromRoot.State>,
    private propertyFacade: PropertyFacade,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const id = route.params['propertyId']
    const isPropertyReady = (x: Property) => x && x._id === id

    const loadAndSelectProperty = (): Observable<Property> => {
      return this.propertyFacade.loadProperty(id).pipe(
        tap(() => this.store$.dispatch(new fromProperty.SelectProperty({ propertyId: id }))),
        exhaustMap(() =>
          this.propertyFacade.property$.pipe(
            filter(isPropertyReady),
            take(1)
          )
        )
      )
    }

    return this.propertyFacade.property$.pipe(
      take(1),
      exhaustMap(property => {
        if (isPropertyReady(property)) {
          return of(property)
        }
        return loadAndSelectProperty()
      }),
      mapTo(true),
      catchError(() => of(this.router.parseUrl('/'))),
      take(1)
    )
  }
}
