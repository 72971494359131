<div
  class="carts hot-flex-table"
  [class.carts--list]="theme !== 'table'"
  [class.carts--user-list]="theme === 'user-list'"
  [class.carts--table]="theme === 'table'"
>
  <div class="row row--header" *ngIf="theme === 'table'">
    <div *ngIf="showColumn('orderNumber')" class="column column--number">
      {{ 'Requests.tableColumn.number' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('date')" class="column column--date">
      {{ 'Requests.tableColumn.date' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('guest')" class="column">
      {{ 'Requests.tableColumn.guest' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('roomNumber')" class="column">
      {{ 'Requests.tableColumn.roomNumber' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('status')" class="column">
      {{ 'Requests.tableColumn.status' | translate: locale.language }}
    </div>
  </div>
  <a
    *ngFor="let cart of carts"
    [routerLink]="['/properties', propertyId, cartType, cart._id]"
    [class]="'row row--link bg-animate cart cart--' + cart.status"
  >
    <div *ngIf="showColumn('orderNumber')" class="column column--number">
      {{ cart.orderNumber }}
    </div>
    <div *ngIf="showColumn('date')" class="column column--date">
      {{ cart.createdAt | date: 'medium':'':locale.language }}
    </div>
    <div *ngIf="showColumn('guest')" class="column column--name">
      <span *ngIf="theme === 'list'">👤</span>
      <span>{{ (cart.__chat | chatName) || '&mdash;' }}</span>
    </div>
    <div *ngIf="showColumn('roomNumber')" class="column column--room">
      <span *ngIf="theme === 'list'">🚪</span>
      <span>{{ getRoomNumber(cart) || '&mdash;' }}</span>
    </div>
    <div *ngIf="showColumn('status')" class="column column--status">
      {{ cart.status | cartStatus: locale.language }}
    </div>
  </a>
</div>
