import { NavigationExtras } from '@angular/router'

import { Action } from '@ngrx/store'

export enum RouterActionTypes {
  Go = '[Router] Go',
  // Replace = '[Router] Replace',
  // Search = '[Router] Search',
  Back = '[Router] Back',
  Forward = '[Router] Forward',
}

export interface RouterPayload {
  path: any[]
  query?: object
  extras?: NavigationExtras
}

export class Go implements Action {
  readonly type = RouterActionTypes.Go
  constructor(public payload: RouterPayload) {}
}

export class Back implements Action {
  readonly type = RouterActionTypes.Back
}

export class Forward implements Action {
  readonly type = RouterActionTypes.Forward
}

export type RouterActions = Go | Back | Forward
