import * as R from 'ramda'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResults, NormalizedResult, Restaurant } from '@models'
import { LanguageType } from '@shared/constants'

@Injectable()
export class RestaurantService {
  constructor(public readonly apiService: ApiService) {}

  public create(
    restaurant: Restaurant,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[]
  ): Observable<NormalizedResult> {
    return this.apiService.post(`restaurants/`, { restaurant, sourceLanguage, targetLanguages })
  }

  public get(query): Observable<NormalizedResults> {
    // const url = restaurantId ? `restaurants/${restaurantId}` : `restaurants`
    const params = {
      page: query.page,
      limit: query.limit,
    }
    return this.apiService.get(`restaurants`, {
      params: R.pickBy(value => Boolean(value), params),
    })
  }

  public getItem(restaurantId: string): Observable<NormalizedResult> {
    return this.apiService.get(`restaurants/${restaurantId}`)
  }

  public update(
    restaurantId: string,
    restaurant: Restaurant,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[]
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`restaurants/${restaurantId}`, {
      restaurant,
      sourceLanguage,
      targetLanguages,
    })
  }

  public delete(restaurantId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`restaurants/${restaurantId}`)
  }
}
