<div class="flex flex-column">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <!-- forms section -->
    <div class="mb4" *ngIf="hasPagesOrFaqs">
      <p class="mb2" *ngIf="data.pagesList && data.pagesList.length">
        <span
          [innerHTML]="
            'Forms.label.usedIn'
              | translate
                : locale.language
                : {
                    count: data.pagesList.length,
                    type: 'Forms.label.form' | translate: locale.language
                  }
          "
        ></span>
        <ng-container *ngFor="let page of data.pagesList; last as isLast">
          <a class="link" href="{{ getMenuLink(page) }}" target="_blank">
            {{ page.name | selectLanguage: locale.language }} </a
          ><span *ngIf="!isLast">,</span>
        </ng-container>
      </p>
      <p *ngIf="data.faqsList && data.faqsList.length">
        <span
          [innerHTML]="
            'Forms.label.faqCounts' | translate: locale.language:{ count: data.faqsList.length }
          "
        ></span>
      </p>
    </div>

    <ng-container *ngIf="allowReassign">
      <p
        class="mb2"
        [innerHTML]="
          'Forms.label.youCanReassign'
            | translate: locale.language:{ type: data.isTemplate ? 'template' : 'form' }
        "
      ></p>
      <div class="flex items-center">
        <mat-slide-toggle (change)="handleToggleChange($event)" class="mr4">
          {{
            (data.isTemplate ? 'Forms.action.reassignTemplate' : 'Forms.action.reassign')
              | translate: locale.language
          }}
        </mat-slide-toggle>
        <mat-form-field>
          <mat-label>
            {{
              (data.isTemplate ? 'Forms.action.selectTemplate' : 'Forms.action.select')
                | translate: locale.language
            }}
          </mat-label>
          <mat-select [(value)]="formToAssign" [disabled]="!isAssignActive">
            <mat-option *ngFor="let form of forms" [value]="form._id">
              {{ form.title | selectLanguage: locale.language }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <p class="mb2" *ngIf="!allowReassign">
      {{ warningText }}
    </p>
  </div>
  <div class="flex justify-end ma2" mat-dialog-actions>
    <button class="mr3" color="primary" mat-button (click)="onCancel()">
      {{ 'common.cancel' | translate: locale.language }}
    </button>
    <button mat-raised-button color="primary" (click)="onConfirm()">
      {{ confirmationButtonText }}
    </button>
  </div>
</div>
