import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { switchMap, map, catchError, tap } from 'rxjs/operators'

import * as reservationActions from './reservation.actions'
import { ReservationService } from './reservation.service'
import { MatSnackBar } from '@angular/material/snack-bar'

const LOAD_ERROR = 'При загрузке информации о порталах возникла ошибка'

@Injectable()
export class ReservationEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(reservationActions.ActionTypes.Load),
    switchMap((action: reservationActions.Load) => {
      const meta = action.payload
      return this.reservationService
        .getList(action.payload.propertyId, meta.query)
        .pipe(
          map(xs => new reservationActions.LoadSuccess(xs, meta)),
          catchError(x => of(new reservationActions.LoadFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  loadFail$ = this.actions$.pipe(
    ofType(reservationActions.ActionTypes.LoadFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private reservationService: ReservationService,
    private matSnackBar: MatSnackBar
  ) {}
}
