<div [formGroup]="form">
  <mat-tab-group
    *ngIf="withKpi"
    mat-stretch-tabs
    [class.small-tabs]="smallTabs"
    [dynamicHeight]="true"
  >
    <!-- NOTIFICATIONS -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div>{{ 'Form.sectionTitle.notificationsSettings' | translate: locale.language }}</div>
      </ng-template>
      <div class="pt3">
        <div
          formArrayName="notifications"
          *ngFor="
            let item of notificationsSettings.controls;
            let i = index;
            first as isFirst;
            last as isLast
          "
        >
          <div [formGroupName]="i" class="flex items-center" [class.mb3]="!isLast">
            <mat-button-toggle-group
              class="mr3 flex-none notifications"
              multiple
              formControlName="types"
            >
              <ng-container *ngFor="let type of types">
                <mat-button-toggle
                  [value]="type.type"
                  [matTooltip]="
                    type.tooltipKey
                      ? ('Form.notification.' + type.tooltipKey | translate: locale.language)
                      : null
                  "
                >
                  <mat-icon *ngIf="type.icon">{{ type.icon }}</mat-icon>
                  <span *ngIf="!type.icon && type.label">{{ type.label }}</span>
                </mat-button-toggle>
              </ng-container>
            </mat-button-toggle-group>
            <div>
              <div class="f6 fw5 lh-solid">
                {{ (notificationsSettings?.controls)[i]?.value?.title || '😑' }}
              </div>
              <div class="f7 black-50">
                {{ (notificationsSettings?.controls)[i]?.value?.subtitle || '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <!-- KPI_NOTIFICATIONS -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div>{{ 'Form.sectionTitle.kpiSettings' | translate: locale.language }}</div>
      </ng-template>
      <div class="pt3">
        <div
          formArrayName="kpiNotifications"
          *ngFor="
            let item of kpiNotificationsSettings.controls;
            let i = index;
            first as isFirst;
            last as isLast
          "
        >
          <div [formGroupName]="i" class="flex items-start" [class.mb3]="!isLast">
            <mat-button-toggle-group
              class="mr3 flex-none notifications"
              multiple
              formControlName="types"
            >
              <ng-container *ngFor="let type of types">
                <mat-button-toggle
                  [value]="type.type"
                  [matTooltip]="
                    type.tooltipKey
                      ? ('Form.notification.' + type.tooltipKey | translate: locale.language)
                      : null
                  "
                >
                  <mat-icon *ngIf="type.icon">{{ type.icon }}</mat-icon>
                  <span *ngIf="!type.icon && type.label">{{ type.label }}</span>
                </mat-button-toggle>
              </ng-container>
            </mat-button-toggle-group>
            <div class="flex-auto flex items-center flex-wrap">
              <div class="flex items-center mr2 flex-auto" style="height: 47px; min-width: 140px;">
                <div>
                  <div class="f6 fw5 lh-solid">
                    {{ getKpiNotificationsSettingsForm(i)?.value?.title || '😑' }}
                  </div>
                  <div class="f7 black-50">
                    {{ getKpiNotificationsSettingsForm(i)?.value?.subtitle || '' }}
                  </div>
                </div>
              </div>
              <div *ngIf="getKpiNotificationsSettingsForm(i)?.value?.types?.length">
                <div class="kpi-time-input-wrapper">
                  <!-- [required]="getKpiNotificationsSettingsForm(i)?.value?.types?.length" -->
                  <input
                    type="number"
                    formControlName="time"
                    class="input kpi-time-input"
                    matInput
                  />
                  <div class="kpi-time-input-postfix">
                    {{ 'RoomService.label.min' | translate: locale.language }}
                  </div>
                  <mat-icon
                    class="ml1 o-30 kpi-time-input-info-badge"
                    [matTooltip]="
                      ('Form.field.kpiTime' | translate: locale.language) +
                      '. ' +
                      ('Form.field.kpiTimeDefault'
                        | translate: locale.language:{ value: defaultKpiTime }) +
                      '.'
                    "
                    >help_outline</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <!-- NOTIFICATIONS (NO KPI) -->
  <ng-container *ngIf="!withKpi">
    <div
      formArrayName="notifications"
      *ngFor="
        let item of notificationsSettings.controls;
        let i = index;
        first as isFirst;
        last as isLast
      "
    >
      <div [formGroupName]="i" class="flex items-center" [class.mb3]="!isLast">
        <mat-button-toggle-group
          class="mr3 flex-none notifications"
          multiple
          formControlName="types"
        >
          <ng-container *ngFor="let type of types">
            <mat-button-toggle
              [value]="type.type"
              [matTooltip]="
                type.tooltipKey
                  ? ('Form.notification.' + type.tooltipKey | translate: locale.language)
                  : null
              "
            >
              <mat-icon *ngIf="type.icon">{{ type.icon }}</mat-icon>
              <span *ngIf="!type.icon && type.label">{{ type.label }}</span>
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
        <div>
          <div class="f6 fw5 lh-solid">
            {{ (notificationsSettings?.controls)[i]?.value?.title || '😑' }}
          </div>
          <div class="f7 black-50">
            {{ (notificationsSettings?.controls)[i]?.value?.subtitle || '' }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
