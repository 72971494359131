import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@redux'
import * as fromForms from '@redux/form'
import { Form } from '@models'
import { I18nService } from '@shared/services'

@Component({
  selector: 'hot-form-confirmation-dialog',
  templateUrl: './form-confirmation-dialog.component.html',
  styles: [
    `
      .link {
        white-space: nowrap;
      }
    `,
  ],
})
export class FormConfirmationDialogComponent implements OnInit {
  public forms$ = this.store$.pipe(select(fromRoot.selectCurrentForms))
  public templates$ = this.store$.pipe(select(fromRoot.selectFormTemplates))
  public forms: Form[]
  public formToAssign = ''
  public isAssignActive: boolean

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private store$: Store<fromRoot.State>,
    public dialogRef: MatDialogRef<FormConfirmationDialogComponent>,
    public translation: I18nService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isTemplate: boolean
      title: string
      text: string
      confirmButtonText: string
      propertyId: string
      formId: string
      pagesList: []
      faqsList: []
    }
  ) {}

  ngOnInit() {
    if (this.data.isTemplate) {
      this.store$.dispatch(new fromForms.LoadTemplates())
      this.templates$.subscribe(
        forms => (this.forms = forms.filter(form => form._id !== this.data.formId))
      )
    } else {
      this.store$.dispatch(new fromForms.Load({ propertyId: this.data.propertyId }))
      this.forms$.subscribe(
        forms => (this.forms = forms.filter(form => form._id !== this.data.formId))
      )
    }
  }

  public handleToggleChange($event) {
    this.isAssignActive = $event.checked
  }

  public getMenuLink(page) {
    return this.data.isTemplate
      ? `admin/chat_bot/templates/${page._id}`
      : `properties/${this.data.propertyId}/settings/builder/${page.pageId}`
  }

  public onConfirm(): void {
    this.dialogRef.close({
      isConfirmed: true,
      needAssign: Boolean(this.isAssignActive),
      formIdToAssign: this.formToAssign,
    })
  }

  public onCancel(): void {
    this.dialogRef.close({ isConfirmed: false })
  }

  public get title() {
    return this.data.title || this.translation.translate('common.youSure')
  }

  public get warningText() {
    return this.translation.translate('common.actionCannotUndone')
  }

  public get confirmationButtonText() {
    return this.data.confirmButtonText || this.translation.translate('common.ok')
  }

  public get allowReassign() {
    return this.forms && this.forms.length && this.hasPagesOrFaqs
  }

  public get hasPagesOrFaqs() {
    return (
      (this.data.pagesList && this.data.pagesList.length) ||
      (this.data.faqsList && this.data.faqsList.length)
    )
  }
}
