import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { isNil } from 'ramda'

export const rangeValidator = (value: Array<number>): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (isNil(value)) {
      return null
    }
    if (!isNil(Validators.required(control))) {
      return null
    }

    const v: number = +control.value
    return v >= value[0] && v <= value[1] ? null : { range: { value: value } }
  }
}
