<div class="hot-request-timeline" *ngFor="let event of events">
  <div [ngSwitch]="event.event">
    <div class="hot-request-timeline-item" *ngSwitchCase="'user_notified'">
      <div class="hot-request-timeline-item-header">
        <div class="hot-request-timeline-icon-container bg-light-yellow">
          <mat-icon class="hot-request-timeline-icon black-50">notification_important</mat-icon>
        </div>
        {{ 'components.Timeline.notificationSent' | translate: locale.language }}
        <span class="b mr1" *ngIf="event.__user">{{
          event.__user.name || event.__user.email
        }}</span>
        <span class="b mr1" *ngIf="!event.__user">{{
          'components.Timeline.deletedUser' | translate: locale.language
        }}</span>
        <span *ngIf="event.eventMeta?.email">✉️</span>
        <span *ngIf="event.eventMeta?.phone || event.eventMeta?.sms">📱</span>
        <span *ngIf="event.eventMeta?.chat">💬</span>
        <div class="mt1 f7 o-60">
          <span>
            {{ event.createdAt | date: 'medium':'':locale.language }}
          </span>
        </div>
      </div>
    </div>

    <div class="hot-request-timeline-item" *ngSwitchCase="'user_notify_error'">
      <div class="hot-request-timeline-item-header">
        <div class="hot-request-timeline-icon-container bg-light-red">
          <mat-icon class="hot-request-timeline-icon black-50">error</mat-icon>
        </div>
        <ng-container *ngIf="event.isManager">
          {{ 'components.Timeline.managerNotificationError' | translate: locale.language }}
        </ng-container>
        <ng-container *ngIf="!event.isManager">
          {{ 'components.Timeline.guestNotificationError' | translate: locale.language }}
        </ng-container>
        <div class="mt1 f7 o-60">
          <span>
            {{ event.createdAt | date: 'medium':'':locale.language }}
          </span>
        </div>
      </div>
    </div>

    <div class="hot-request-timeline-item" *ngSwitchCase="'kpi_time_expired'">
      <div class="hot-request-timeline-item-header">
        <div class="hot-request-timeline-icon-container bg-red">
          <mat-icon class="hot-request-timeline-icon black-50">notification_important</mat-icon>
        </div>
        {{ 'components.Timeline.kpiTimeExpired' | translate: locale.language }}
        <div class="mt1 f7 o-60">
          <span>
            {{ event.createdAt | date: 'medium':'':locale.language }}
          </span>
        </div>
      </div>
    </div>

    <div class="hot-request-timeline-item" *ngSwitchDefault>
      <div class="hot-request-timeline-item-header">
        <ng-container [ngSwitch]="event.event">
          <div class="hot-request-timeline-icon-container bg-blue" *ngSwitchCase="'inprogress'">
            <mat-icon class="hot-request-timeline-icon white">work</mat-icon>
          </div>
          <div class="hot-request-timeline-icon-container bg-blue" *ngSwitchCase="'ready'">
            <mat-icon class="hot-request-timeline-icon white">work</mat-icon>
          </div>
          <div class="hot-request-timeline-icon-container bg-green" *ngSwitchCase="'closed'">
            <mat-icon class="hot-request-timeline-icon white">done</mat-icon>
          </div>
          <div class="hot-request-timeline-icon-container bg-green" *ngSwitchCase="'done'">
            <mat-icon class="hot-request-timeline-icon white">done</mat-icon>
          </div>
          <div class="hot-request-timeline-icon-container bg-red" *ngSwitchCase="'canceled'">
            <mat-icon class="hot-request-timeline-icon white">close</mat-icon>
          </div>
          <div class="hot-request-timeline-icon-container" *ngSwitchDefault>
            <mat-icon class="hot-request-timeline-icon">comment</mat-icon>
          </div>
        </ng-container>
        <span
          class="hot-request-timeline-item-status"
          [innerHTML]="
            'components.Timeline.statusChanged' | translate: locale.language:getStatusContext(event)
          "
        >
        </span>
        <div class="mt1 f7 o-60">{{ event.createdAt | date: 'medium':'':locale.language }}</div>
      </div>
      <div class="hot-request-timeline-item-content" *ngIf="showComment(event)">
        <div *ngIf="event.photos">
          <ng-container *ngFor="let photo of event.photos">
            <img
              *ngIf="photo && photo.url"
              (click)="openPhotoDialog(photo)"
              class="hot-request-timeline-photo"
              [hotProxySrc]="photo.url"
            />
          </ng-container>
        </div>
        <span [innerHTML]="event.body"></span>
      </div>
    </div>
  </div>
</div>
