import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import { Statistics } from '@models'

import * as fromDashboard from './dashboard.actions'
import { DashboardService } from './dashboard.service'
import { I18nService } from '@shared/services'

@Injectable()
export class DashboardEffects {
  @Effect()
  public loadStats$ = this.actions$.pipe(
    ofType(fromDashboard.DashboardActionTypes.LoadStatistics),
    switchMap((action: fromDashboard.LoadStatistics) => {
      const meta = action.payload
      return this.dashboardService.loadStatistics(action.payload.propertyId, meta).pipe(
        map(x => new fromDashboard.LoadStatisticsSuccess(Statistics.fromJSON(x), meta)),
        catchError(x => of(new fromDashboard.LoadStatisticsFail(x, meta)))
      )
    })
  )

  @Effect()
  public loadFormResponses$ = this.actions$.pipe(
    ofType(fromDashboard.DashboardActionTypes.LoadFormResponses),
    switchMap((action: fromDashboard.LoadFormResponses) => {
      const meta = action.payload
      return this.dashboardService
        .loadFormResponses(action.payload.propertyId, { statuses: action.payload.statuses })
        .pipe(
          map(x => new fromDashboard.LoadFormResponsesSuccess(x, meta)),
          catchError(x => of(new fromDashboard.LoadFormResponsesFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public loadStatsFail$ = this.actions$.pipe(
    ofType(fromDashboard.DashboardActionTypes.LoadStatisticsFail),
    tap(() => {
      const loadStatsError = this.translationService.translate('Stats.error.errorLoadStats')
      this.matSnackBar.open(loadStatsError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public loadFormResponsesFail$ = this.actions$.pipe(
    ofType(fromDashboard.DashboardActionTypes.LoadFormResponsesFail),
    tap(() => {
      const loadFormresponsesError = this.translationService.translate(
        'Stats.error.errorLoadFormresponses'
      )
      this.matSnackBar.open(loadFormresponsesError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  public constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
