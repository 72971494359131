import { Pipe, PipeTransform } from '@angular/core'
import { I18nService } from '@shared/services'

@Pipe({
  name: 'requestStatus',
})
export class RequestStatusPipe implements PipeTransform {
  constructor(private translation: I18nService) {}
  transform(status: any): any {
    const key = 'Request.status.' + status
    return this.translation.translate(key)
  }
}
