import { Directive, AfterViewInit, ElementRef, Input, OnDestroy, OnChanges } from '@angular/core'

@Directive({
  selector: '[hotAutofocus]',
})
export class AutofocusDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  set hotAutofocus(value: boolean) {
    this._autofocus = value != null && value !== false
  }

  private _autofocus = false
  private _timeout = null

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this._autofocus) {
      this.focusNativeElement()
    }
  }

  ngOnChanges(changes) {
    if (changes.hotAutofocus && changes.hotAutofocus.currentValue) {
      this.focusNativeElement()
    }
  }

  ngOnDestroy() {
    this.clearTimer()
  }

  clearTimer() {
    if (this._timeout) {
      window.clearTimeout(this._timeout)
      this._timeout = null
    }
  }

  focusNativeElement() {
    this.clearTimer()

    this._timeout = window.setTimeout(() => {
      this.el.nativeElement.focus()

      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded#Browser_compatibility
      // Only Chrome and Webkit supported. But no smooth though :-(
      if (typeof this.el.nativeElement.scrollIntoViewIfNeeded === 'function') {
        this.el.nativeElement.scrollIntoViewIfNeeded()
        return
      }

      if (typeof this.el.nativeElement.scrollIntoView === 'function') {
        // Smooth is not supported in every browser
        // https://caniuse.com/#feat=scrollintoview
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#Browser_compatibility
        try {
          this.el.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          })
        } catch (e) {
          this.el.nativeElement.scrollIntoView({
            block: 'start',
            inline: 'start',
          })
        }
      }
    }, 50)
  }
}
