import { Pipe, PipeTransform } from '@angular/core'

export const fullName = (value: any): string | null => {
  return value ? ((value.firstName || '') + ' ' + (value.lastName || '')).trim() : null
}

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform = fullName
}
