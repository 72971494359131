import { Action } from '@ngrx/store'
import { NormalizedResult, RoomService } from '@models'
import { LanguageType } from '@shared/constants'

export enum RoomServiceActionTypes {
  Create = '[RoomService] Create',
  CreateSuccess = '[RoomService] Create Success',
  CreateFail = '[RoomService] Create Fail',
  Load = '[RoomService] Load',
  LoadSuccess = '[RoomService] Load Success',
  LoadFail = '[RoomService] Load Fail',
  Update = '[RoomService] Update',
  UpdateSuccess = '[RoomService] Update Success',
  UpdateFail = '[RoomService] Update Fail',
  Toggle = '[RoomService] Toggle',
  ToggleSuccess = '[RoomService] Toggle Success',
  ToggleFail = '[RoomService] Toggle Fail',
  Delete = '[RoomService] Delete',
  DeleteSuccess = '[RoomService] Delete Success',
  DeleteFail = '[RoomService] Delete Fail',
}

/**
 * Create room service actions
 */

export interface CreatePayload {
  propertyId: string
  roomService: RoomService
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
}

export class Create implements Action {
  readonly type = RoomServiceActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = RoomServiceActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = RoomServiceActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load room service actions
 */

export interface LoadPayload {
  propertyId?: string
}

export class Load implements Action {
  readonly type = RoomServiceActionTypes.Load
  constructor(public payload: LoadPayload = {}) {}
}

export class LoadSuccess implements Action {
  readonly type = RoomServiceActionTypes.LoadSuccess
  // constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
  constructor(public payload, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = RoomServiceActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Update room service actions
 */
export interface UpdatePayload {
  propertyId: string
  roomServiceId: string
  roomService: RoomService
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
  forceTranslate: boolean
}

export class Update implements Action {
  readonly type = RoomServiceActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = RoomServiceActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = RoomServiceActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Room service toggle availability actions
 */
export interface TogglePayload {
  propertyId: string
  roomServiceId: string
  pause: boolean
}

export class Toggle implements Action {
  readonly type = RoomServiceActionTypes.Toggle
  constructor(public payload: TogglePayload) {}
}

export class ToggleSuccess implements Action {
  readonly type = RoomServiceActionTypes.ToggleSuccess
  constructor(public payload: NormalizedResult, public meta: TogglePayload) {}
}

export class ToggleFail implements Action {
  readonly type = RoomServiceActionTypes.ToggleFail
  constructor(public error: any, public meta: TogglePayload) {}
}

/**
 * Delete room service actions
 */
export interface DeletePayload {
  propertyId: string
  roomServiceId: string
}

export class Delete implements Action {
  readonly type = RoomServiceActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = RoomServiceActionTypes.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = RoomServiceActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type RoomServiceActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Toggle
  | ToggleSuccess
  | ToggleFail
  | Delete
  | DeleteSuccess
  | DeleteFail
