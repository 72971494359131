<div [formGroup]="fieldForm" class="flex flex-column min-h-100">
  <!-- Type selector -->
  <div class="flex flex-column sticky">
    <mat-form-field class="type-selector br2 br--top" floatLabel="always">
      <mat-select formControlName="type" disableRipple panelClass="mh-300">
        <mat-select-trigger>
          <div class="flex items-center">
            <mat-icon color="primary" class="mt1 mr3">{{ currentTypeConfig.icon }}</mat-icon>
            <span class="fw5">{{
              'FormQuestion.type.' + currentTypeConfig.type | translate: locale.language
            }}</span>
            <!--<span>{{ fieldForm.get('type').value }}</span>-->
          </div>
        </mat-select-trigger>
        <ng-container *ngFor="let fieldType of fieldTypes">
          <mat-option [value]="fieldType.type">
            <mat-icon>{{ fieldType.icon }}</mat-icon>
            <span>{{ 'FormQuestion.type.' + fieldType.type | translate: locale.language }}</span>
          </mat-option>
          <mat-divider class="mv2" *ngIf="fieldType.showDivider"></mat-divider>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Common fields: Title and Label -->
  <div class="pt2 ph3 pb3 flex flex-column" *ngIf="currentTypeConfig.interactive">
    <div class="measure-narrow">
      <mat-form-field appearance="standard" floatLabel="always" class="w-100 mb3">
        <mat-label>{{ 'FormQuestion.field.title' | translate: locale.language }}</mat-label>
        <input
          matInput
          formControlName="title"
          hotTranslatableText
          [language]="language"
          [defaultLanguage]="defaultLanguage"
          [placeholder]="'FormQuestion.field.titlePlaceholder' | translate: locale.language"
        />
        <mat-hint>{{ 'FormQuestion.field.titleHint' | translate: locale.language }}</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
        <mat-label>{{ 'FormQuestion.field.label' | translate: locale.language }}</mat-label>
        <textarea
          matInput
          formControlName="label"
          hotTranslatableText
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="1"
          [language]="language"
          [defaultLanguage]="defaultLanguage"
          [placeholder]="labelPlaceholder"
        ></textarea>
        <mat-hint>{{ 'FormQuestion.field.labelHint' | translate: locale.language }}</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <ng-container [ngSwitch]="currentType">
    <!-- Action -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'action'">
      <hot-form-field-action
        [config]="formFieldConfig"
        [language]="language"
        [defaultLanguage]="defaultLanguage"
        [showIdInputs]="showIdInputs"
        (changed)="patchConfig($event)"
      >
      </hot-form-field-action>
    </div>

    <!-- Message -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'message'">
      <mat-form-field appearance="standard" floatLabel="always" class="w-100">
        <mat-label>{{ 'FormQuestion.message.text' | translate: locale.language }}</mat-label>
        <textarea
          matInput
          formControlName="label"
          hotTranslatableText
          cdkTextareaAutosize
          [cdkAutosizeMinRows]="1"
          [language]="language"
          [defaultLanguage]="defaultLanguage"
          [placeholder]="labelPlaceholder"
        ></textarea>
        <mat-hint>{{ 'FormQuestion.message.textHint' | translate: locale.language }}</mat-hint>
      </mat-form-field>
    </div>

    <!-- Options -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'options'">
      <div class="fw5 f7 ttu tracked mb3">
        {{ 'FormQuestion.options.answersSectionLabel' | translate: locale.language }}:
      </div>

      <hot-form-field-options
        [config]="formFieldConfig"
        [language]="language"
        [defaultLanguage]="defaultLanguage"
        [showIdInputs]="showIdInputs"
        (changed)="patchConfig($event)"
      >
      </hot-form-field-options>

      <div
        class="error--config pa3 f7 mt3 br2 br--bottom nl3 nr3 nb3"
        *ngIf="formFieldConfig.touched && fieldForm.hasError('emptyOptions')"
      >
        <span>{{ 'FormQuestion.options.error.noAnswers' | translate: locale.language }}</span>
      </div>
    </div>

    <!-- Date -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'date'">
      <!-- <div class="fw5 f7 ttu tracked mb3">Настройки даты:</div> -->
      <hot-form-field-date
        [config]="formFieldConfig"
        [language]="language"
        [defaultLanguage]="defaultLanguage"
        (changed)="patchConfig($event)"
      >
      </hot-form-field-date>
    </div>

    <!-- Comment -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'comment'">
      <!-- <div class="fw5 f7 ttu tracked mb3">Настройки ком:</div> -->
      <hot-form-field-comment
        [config]="formFieldConfig"
        [language]="language"
        [defaultLanguage]="defaultLanguage"
        (changed)="patchConfig($event)"
      >
      </hot-form-field-comment>
    </div>

    <!-- Text -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'text'">
      <hot-form-field-text
        [config]="formFieldConfig"
        [language]="language"
        [defaultLanguage]="defaultLanguage"
        (changed)="patchConfig($event)"
      >
      </hot-form-field-text>
    </div>

    <!-- Number -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'number'">
      <hot-form-field-number
        [config]="formFieldConfig"
        [language]="language"
        [defaultLanguage]="defaultLanguage"
        (changed)="patchConfig($event)"
      >
      </hot-form-field-number>
    </div>

    <!-- Address -->
    <div class="config-section pa3 br2 br--bottom" *ngSwitchCase="'address'">
      <hot-form-field-address
        [config]="formFieldConfig"
        [language]="language"
        [defaultLanguage]="defaultLanguage"
        (changed)="patchConfig($event)"
      >
      </hot-form-field-address>
    </div>
  </ng-container>
</div>
