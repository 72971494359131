import * as R from 'ramda'

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  HostBinding,
  Inject,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'

import { Actions, ofType } from '@ngrx/effects'

import { Subject } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import { selectLanguage } from '@shared/pipes/select-language.pipe'
import {
  Builder,
  Form,
  MenuButton,
  MenuButtonType,
  MenuContentType,
  MenuModules,
  MenuPagesTemplates,
  UploadFile,
  UploadFileResult,
  UploadFileStatus,
  Poll,
  ShopProductCategory,
  ShopProduct,
} from '@models'
import * as fromBuilderActions from '@redux/builder/builder.actions'
import { FILE_EXTENSIONS, IMAGE_EXTENSIONS } from '@shared/constants'
import { builderUrlValidator } from '@shared/validators'
import { AuthService, I18nService } from '@shared/services'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

const DEFAULT = 'en'

const emptyButtonContent = {
  type: null,
  title: '',
  config: {},
}

const emptyButton: MenuButton = {
  type: MenuContentType.BUTTON,
  translations: {
    en: R.clone(emptyButtonContent),
  },
  isEdit: false,
}

interface MenuModule {
  module: MenuModules
  i18nKey: string
  place?: string
  pollId?: string
  pollResults?: boolean
}

const modulesArray: MenuModule[] = [
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.atm', place: 'ATM' },
  {
    module: MenuModules.MAP,
    i18nKey: 'MenuBuilder.module.currencyExchange',
    place: 'Currency exchange',
  },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.airports', place: 'Airports' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.groceryStore', place: 'Grocery store' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.pharmacy', place: 'Pharmacy' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.simCards', place: 'Phone store' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.parks', place: 'Parks' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.landmarks', place: 'Landmarks' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.museums', place: 'Museums' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.theatres', place: 'Theatres' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.cinemas', place: 'Cinemas' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.shoppingMalls', place: 'Shopping malls' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.clubs', place: 'Clubs' },
  { module: MenuModules.MAP, i18nKey: 'MenuBuilder.module.fitnessClubs', place: 'Fitness clubs' },

  { module: MenuModules.MY_ORDERS, i18nKey: 'MenuBuilder.module.myOrders' },
  { module: MenuModules.WEATHER, i18nKey: 'MenuBuilder.module.weather' },
  { module: MenuModules.RESTAURANT, i18nKey: 'MenuBuilder.module.restaurants' },
  { module: MenuModules.BOOKING, i18nKey: 'MenuBuilder.module.booking' },
  { module: MenuModules.ROOMS, i18nKey: 'MenuBuilder.module.rooms' },
  { module: MenuModules.ROOM_SERVICE, i18nKey: 'MenuBuilder.module.roomService' },
  { module: MenuModules.MY_RESERVATION, i18nKey: 'MenuBuilder.module.myReservation' },
  { module: MenuModules.CONTACT_ADMINISTRATOR, i18nKey: 'MenuBuilder.module.contactAdministrator' },
]

const MAX_BUTTON_LENGTH = 20

@Component({
  selector: 'hot-builder-button-editor',
  templateUrl: './builder-button-editor.component.html',
  styles: [
    `
      .hot-builder-button-edit-content {
        overflow: auto;
        max-height: 500px;
        max-height: calc(100vh - 300px);
      }

      :host.is-tracking-code-visible .hot-builder-button-edit-content,
      :host.is-period-visible .hot-builder-button-edit-content {
        max-height: calc(100vh - 300px - 72px);
      }

      :host.is-tracking-code-visible.is-period-visible .hot-builder-button-edit-content {
        max-height: calc(100vh - 300px - 72px - 72px);
      }

      .mat-flat-button:not(.mat-primary):not(.mat-accent) {
        background: #eee;
      }

      .mat-flat-button {
        box-shadow: none !important;
      }

      .hot-builder__emoji-picker {
        position: absolute;
        right: 10px;
        top: 15px;
        z-index: 10;
      }

      .title-input {
        line-height: 19px;
      }

      .tracking-button {
        margin-top: -1rem;
      }

      .title-field.ng-touched.ng-invalid ~ .tracking-button {
        margin-top: 0;
      }
    `,
  ],
})
export class BuilderButtonEditorComponent implements OnInit, OnChanges {
  private _modules = modulesArray
  @Input() public currentLanguage = 'en'
  @Input() public addPageEnabled = true
  @Input() public canSetVisibilityPeriod = false

  @Input()
  public set cache(value: MenuButton) {
    this._cache = value || R.clone(emptyButton)
  }

  public get cache() {
    return this._cache
  }

  private _cache: MenuButton = R.clone(emptyButton)

  @Input()
  set builders(value: Builder[]) {
    if (value && value.length) {
      this._builders = this.setSortableLabel(value)
      this._builders = R.sort(
        (a, b) => a._sortableLabel.localeCompare(b._sortableLabel),
        this._builders
      )
    }
  }

  get builders(): Builder[] {
    return this._builders
  }

  @Input() public forms: Form[] = []
  @Input() public polls: Poll[] = []
  @Input() public templates: MenuPagesTemplates[] = []
  @Input() public shopCategories: ShopProductCategory[] = []
  @Input() public shopProducts: ShopProduct[] = []

  @Input() public availableModules: string[] | null = []
  @Input() public availableLanguages: string[] = []
  @Input() public enableManuallyTranslate = true
  @Input() public buttonAdding = false
  @Input() public buttons: MenuButton[] = []
  @Input() public buttonIndex: number

  @Output() public update = new EventEmitter()
  @Output() public navigate = new EventEmitter()
  @Output() public createNewPage = new EventEmitter()
  @Output() public save = new EventEmitter()
  @Output() public cancel = new EventEmitter()

  @ViewChild('urlInput') public urlInput: ElementRef

  public lastEditIndex: number
  public urlForm: FormGroup
  public titleForm: FormGroup

  @HostBinding('class.is-tracking-code-visible')
  public showTrackingCode = false

  @HostBinding('class.is-period-visible')
  public showPeriodOfVisibility = false

  public isAdmin = false

  public extensions = R.merge(IMAGE_EXTENSIONS, FILE_EXTENSIONS)
  public debugJson = false

  private destroyed = new Subject()
  private _tabIndexMap = {}
  private _builders: Builder[] = []
  private regexp = /^[^a-zа-я0-9ёЁ]*/i

  @HostListener('keydown', ['$event'])
  handleEnter($event) {
    if ($event.key === 'Enter' && this.isSaveEnabled) {
      this.buttonSave(this.buttonIndex, this.enableManuallyTranslate && this.buttonAdding)
    }
  }

  public get modules() {
    return this._modules
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private actions$: Actions,
    private fb: FormBuilder,
    private authService: AuthService,
    private translationService: I18nService
  ) {
    this.initializeTitleForm()
    this.initializeUrlForm()
    this.authService.isAdmin$.pipe(take(1)).subscribe(isAdmin => {
      this.isAdmin = isAdmin
    })
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cache) {
      const title = this.cache.translations[this.currentLanguage].title
      const trackingCode = this.cache.trackingCode
      const visibilityPeriod = this.cache.visibilityPeriod || { start: null, end: null }
      const url = this.cache.translations[this.currentLanguage].config.url
      const targetSelf = this.cache.translations[this.currentLanguage].config.targetSelf || false
      this.titleForm.patchValue({
        Title: title,
        TrackingCode: trackingCode,
        VisibilityPeriod: visibilityPeriod,
      })
      this.urlForm.patchValue({ Url: url, TargetSelf: targetSelf })
      if (trackingCode) {
        this.showTrackingCode = true
      }
      if (visibilityPeriod.start || visibilityPeriod.end) {
        this.showPeriodOfVisibility = this.canSetVisibilityPeriod && true
      }
    }

    if (changes.availableModules) {
      if (Array.isArray(this.availableModules)) {
        this._modules = modulesArray.filter(x => this.availableModules.includes(x.module))
      } else if (this.availableModules === null) {
        // Template-mode: All modules should be visible
        this._modules = modulesArray
      } else {
        this._modules = []
      }
    }

    const tabs = [
      MenuButtonType.PAGE,
      MenuButtonType.MODULE,
      MenuButtonType.URL,
      this.forms && this.forms.length > 0 && MenuButtonType.FORM,
      this.shopCategories && this.shopCategories.length > 0 && MenuButtonType.SHOP_LINK,
      this.polls && this.polls.length > 0 && MenuButtonType.POLL,
      this.templates && this.templates.length > 0 && MenuButtonType.TEMPLATE,
    ].filter(Boolean)

    this._tabIndexMap = R.invertObj(tabs)
  }

  private setSortableLabel(pages: Builder[]): Builder[] {
    return pages.map(p => {
      const name = p.name[this.currentLanguage] || p.name.default
      const sortableLabel = name && name.toLowerCase().replace(this.regexp, '')
      return {
        ...p,
        _sortableLabel: sortableLabel || '<nottranslated>',
      }
    })
  }

  public selectedButtonIndex() {
    const buttonConfig = this.cache.translations[this.currentLanguage].config
    const buttonModule = buttonConfig && buttonConfig.module
    const buttonType =
      buttonModule === 'poll'
        ? MenuButtonType.POLL
        : buttonModule === 'shop_link'
        ? MenuButtonType.SHOP_LINK
        : this.cache.translations[this.currentLanguage].type
    return (this._tabIndexMap && this._tabIndexMap[buttonType]) || 0
  }

  public get fileUrl(): string {
    const type = this.cache.translations[this.currentLanguage].type
    const url = this.cache.translations[this.currentLanguage].config.url
    const fileExtension =
      url && type === MenuButtonType.URL ? this.extensions[R.toUpper(url.split('.').pop())] : null
    return fileExtension ? this.cache.translations[this.currentLanguage].config.url : null
  }

  private initializeTitleForm(): void {
    const visibilityPeriod = this.cache.visibilityPeriod || {}
    this.titleForm = this.fb.group({
      Title: new FormControl(
        this.cache.translations[this.currentLanguage].title,
        Validators.compose([Validators.required, Validators.maxLength(MAX_BUTTON_LENGTH)])
      ),
      TrackingCode: new FormControl(this.cache.trackingCode),
      VisibilityPeriod: this.fb.group({
        start: visibilityPeriod.start || null,
        end: visibilityPeriod.end || null,
      }),
    })
  }

  private initializeUrlForm(): void {
    this.urlForm = this.fb.group({
      Url: new FormControl(
        this.cache.translations[this.currentLanguage].config.url,
        Validators.compose([Validators.required, builderUrlValidator])
      ),
      TargetSelf: new FormControl(false),
    })
  }

  public onUploadFile(result: UploadFileResult): void {
    if (result.status === UploadFileStatus.COMPLETED) {
      this.urlForm.patchValue({
        Url: (result.data as UploadFile).url,
      })
      this.urlInput.nativeElement.focus()
    } else if (
      result.status === UploadFileStatus.CANCELLED_BY_USER ||
      result.status === UploadFileStatus.FAILED
    ) {
      this.urlForm.patchValue({
        Url: null,
      })
    }
  }

  public changeButtonTitle(title: string) {
    this.titleForm.patchValue({ Title: title.slice(0, 20) })
  }

  public changeButtonType($event) {
    const type = $event.tab.ariaLabel
    this.cache.translations[this.currentLanguage].config = {}
    this.cache.translations[this.currentLanguage].type = type
    this.titleForm.markAsUntouched()
    this.urlForm.markAsUntouched()

    if (type === 'shop_link') {
      this.cache.translations[this.currentLanguage].config = {
        rootCategory: null,
        category: null,
        product: null,
      }
    }
  }

  public setConfig(config, type = null) {
    this.cache.translations[this.currentLanguage].config = config
    if (type) {
      this.cache.translations[this.currentLanguage].type = type
    }
  }

  public selectPage(page: Builder) {
    const title = page.name[this.currentLanguage] || page.name.default
    this.setConfig({ pageId: page.pageId })
    this.changeButtonTitle(title)
  }

  public selectModule(module: MenuModule) {
    this.setConfig({ module: module.module, place: module.place || '' })
    this.changeButtonTitle(this.translationService.translate(module.i18nKey))
  }

  public selectForm(form: Form) {
    const title = selectLanguage(form.title, this.currentLanguage, 'en')
    this.setConfig({ formId: form._id })
    this.changeButtonTitle(title)
  }

  public selectPoll(poll: Poll) {
    const title = poll.title[this.currentLanguage] || poll.title.default
    this.setConfig({ module: MenuModules.POLL, pollId: poll._id }, 'module')
    this.changeButtonTitle(title)
  }

  public selectTemplate(template: Builder & { _id: any }) {
    const title = selectLanguage(template.name, this.currentLanguage, 'en')
    this.setConfig({ templateId: template._id, name: template.name })
    this.changeButtonTitle(title)
  }

  public handleShopLinkChange($event: {
    rootCategory: string
    category?: string
    product?: string
  }) {
    this.setConfig($event, MenuButtonType.SHOP_LINK)
  }

  public isActiveModuleButton(currentModule, currentPlace) {
    const module = this.cache.translations[this.currentLanguage].config.module || ''
    const place = this.cache.translations[this.currentLanguage].config.place || ''

    if (currentPlace) {
      return module &&
        module.toLowerCase() === currentModule.toLowerCase() &&
        place.toLowerCase() === currentPlace.toLowerCase()
        ? 'primary'
        : ''
    } else {
      return module.toLowerCase() === currentModule.toLowerCase() ? 'primary' : ''
    }
  }

  setTypeIfNotDefined() {
    if (!this.cache.translations[this.currentLanguage].type) {
      const firstTabKey = Object.keys(this._tabIndexMap)[0] as MenuButtonType
      this.cache.translations[this.currentLanguage].type = firstTabKey
    }
  }

  public cancelButtonSave(): void {
    this.cancel.next()
  }

  buttonSave(index, translate = false) {
    this.setTypeIfNotDefined()
    const title = this.titleForm && this.titleForm.valid && this.titleForm.value.Title
    const type = this.cache.translations[this.currentLanguage].type

    if (type === MenuButtonType.URL) {
      this.setUrl({
        url: this.urlForm && this.urlForm.value.Url,
        targetSelf: this.urlForm && this.urlForm.value.TargetSelf,
      })
    }

    const config = this.cache.translations[this.currentLanguage].config

    if (type === MenuButtonType.TEMPLATE) {
      this.createPageFromTemplate(title, index, { templateId: config.templateId })
      return false
    }

    this.fillEmptyFields(type, title, config)
    this.lastEditIndex = null

    const button = R.clone(this.cache as MenuButton)
    button.translations[this.currentLanguage].title = title
    button.isEdit = false
    button.needTranslate = translate
    button.trackingCode = this.titleForm.value.TrackingCode
    if (this.canSetVisibilityPeriod) {
      button.visibilityPeriod = this.titleForm.value.VisibilityPeriod
    }
    this.buttons[index] = button

    this.update.emit(button)
    this.cache = R.clone(emptyButton)
    this.save.next()
  }

  updateConfig($event) {
    try {
      const text = $event.target.value
      const json = JSON.parse(text)
      this.cache = json
      this.titleForm.patchValue(
        { Title: json.translations[this.currentLanguage].title },
        { emitEvent: false }
      )
    } catch (e) {}
  }

  public createPageFromTemplate(name, index, config) {
    this.handleNewPage(name, index, config)
    this.cache.translations[this.currentLanguage].type = MenuButtonType.PAGE
  }

  public setUrl({ url, targetSelf = false }) {
    const urlHasProtocol =
      url.indexOf('http://') === 0 ||
      url.indexOf('https://') === 0 ||
      url.indexOf('payload://') === 0

    if (!urlHasProtocol) {
      url = `http://${url}`
    }

    this.cache.translations[this.currentLanguage].config = { url, targetSelf }
  }

  public fillEmptyFields(type, title, config) {
    const languages = this.availableLanguages.length
      ? this.availableLanguages
      : Object.keys(this.cache.translations)
    languages.forEach(lang => {
      if (!this.cache.translations[lang]) {
        this.cache.translations[lang] = R.clone(emptyButtonContent)
      }

      this.cache.translations[lang].type = type
      this.cache.translations[lang].config = config

      if (!this.cache.translations[lang].title) {
        this.cache.translations[lang].title = title
      }
    })
  }

  public canAddTypeButton(buttonType): boolean {
    return this._tabIndexMap && R.has(MenuButtonType[buttonType] as string, this._tabIndexMap)
  }

  public get isSaveEnabled() {
    // Super Hot-Fix error during saving button
    if (!this.cache.translations || !this.cache.translations[this.currentLanguage]) {
      return false
    }
    const type = this.cache.translations[this.currentLanguage].type
    const config = this.cache.translations[this.currentLanguage].config
    const hasTitle = this.titleForm && this.titleForm.valid

    if (type === MenuButtonType.URL) {
      return hasTitle && this.urlForm && this.urlForm.valid
    }

    if (type === MenuButtonType.SHOP_LINK) {
      return hasTitle
    }

    return hasTitle && Object.keys(config).length
  }

  public get maxButtonLength() {
    return MAX_BUTTON_LENGTH
  }

  public handleNewPage(name, index, meta = {}) {
    const newPage = {
      content: [],
      name: {
        default: name,
        [DEFAULT]: name,
      },
    }

    this.availableLanguages.map(v => {
      newPage.name[v] = name
    })

    this.createNewPage.emit({
      menuPage: newPage,
      translateName: this.enableManuallyTranslate,
      meta,
    })

    this.actions$
      .pipe(
        ofType(fromBuilderActions.BuilderActionTypes.CreateSuccess),
        take(1),
        takeUntil(this.destroyed),
        takeUntil(this.actions$.pipe(ofType(fromBuilderActions.BuilderActionTypes.CreateFail)))
      )
      .subscribe((createSuccessAction: fromBuilderActions.CreateSuccess) => {
        const pageId = createSuccessAction.payload.result
        this.cache.translations[this.currentLanguage].config = { pageId }
        this.buttonSave(index, this.enableManuallyTranslate)
      })
  }

  public addEmoji({ emoji }) {
    const updatedTitle = this.titleForm.get('Title').value + emoji
    this.changeButtonTitle(updatedTitle)
  }
}
