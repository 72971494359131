import { Directive, AfterViewInit, ElementRef } from '@angular/core'

@Directive({
  // tslint:disable-next-line
  selector: 'mat-icon',
})
export class MatIconDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.classList.add('notranslate')
    this.el.nativeElement.setAttribute('translate', 'no')
  }
}
