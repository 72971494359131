import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from './api.service'

@Injectable()
export class DialogFlowService {
  constructor(private apiService: ApiService) {}

  public getTagsList(): Observable<{ results: string[] }> {
    return this.apiService.get(`tags`)
  }
}
