import { Injectable } from '@angular/core'
import { I18nService } from '@shared/services'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import * as fromRouter from '@redux/router'
import * as roomServiceActions from './room-service.actions'
import { RoomServiceService } from './room-service.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'

const LOAD_ERROR = 'При загрузке информации возникла ошибка'

@Injectable()
export class RoomServiceEffects {
  /**
   * Load room services effects
   */
  @Effect()
  effect$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.Load),
    switchMap((action: roomServiceActions.Load) => {
      const query = {
        propertyId: action.payload.propertyId,
      }
      return this.roomServiceService.get(query).pipe(
        map(x => new roomServiceActions.LoadSuccess(x, action.payload)),
        catchError(x => of(new roomServiceActions.LoadFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.LoadFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Update room service effects
   */

  @Effect()
  public update$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.Update),
    switchMap((action: roomServiceActions.Update) => {
      const meta = action.payload
      return this.roomServiceService
        .update(
          action.payload.propertyId,
          action.payload.roomServiceId,
          action.payload.roomService,
          action.payload.sourceLanguage,
          action.payload.targetLanguages,
          action.payload.forceTranslate
        )
        .pipe(
          map(x => new roomServiceActions.UpdateSuccess(x, meta)),
          catchError(x => of(new roomServiceActions.UpdateFail(x, meta)))
        )
    })
  )

  @Effect()
  public updateSuccess$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.UpdateSuccess),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.updateRoomServiceSuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    }),
    map((action: roomServiceActions.UpdateSuccess) => {
      return new fromRouter.Go({
        path: ['properties', action.meta.propertyId, 'settings', 'room-service'],
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.UpdateFail),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.updateRoomServiceFail'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Toggle room service effects
   */

  @Effect()
  public toggle$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.Toggle),
    switchMap((action: roomServiceActions.Toggle) => {
      const meta = action.payload
      return this.roomServiceService
        .toggle(action.payload.propertyId, action.payload.roomServiceId, action.payload.pause)
        .pipe(
          map(x => new roomServiceActions.ToggleSuccess(x, meta)),
          catchError(x => of(new roomServiceActions.ToggleFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public toggleSuccess$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.ToggleSuccess),
    tap((action: roomServiceActions.ToggleSuccess) => {
      const message = action.meta.pause
        ? this.translationService.translate('RoomService.notification.turnOffRoomServiceSuccess')
        : this.translationService.translate('RoomService.notification.turnOnRoomServiceSuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public toggleFail$ = this.actions$.pipe(
    ofType(roomServiceActions.RoomServiceActionTypes.ToggleFail),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.toggleRoomServiceFail'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private roomServiceService: RoomServiceService,
    private matSnackBar: MatSnackBar,
    public translationService: I18nService
  ) {}
}
