import { AbstractControl } from '@angular/forms'
import * as R from 'ramda'

export const translatableFieldValidator = (control: AbstractControl) => {
  if (
    control &&
    control.value &&
    R.has('default', control.value) &&
    Boolean(control.value.default)
  ) {
    if (R.any(v => v && v.trim().length === 0, R.values(control.value))) {
      return {
        blank: true,
      }
    }
    return null
  }
  return {
    emptyDefault: true,
  }
}
