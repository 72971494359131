import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { Builder, BuilderResponse } from '@models/builder.model'
import { ActivatedRoute } from '@angular/router'

@Injectable()
export class BuilderService {
  public get(propertyId: string, pageId: string): Observable<BuilderResponse> {
    return pageId
      ? this.apiService.get(`properties/${propertyId}/menu/${pageId}`, this.getCommonOpts())
      : this.apiService.get(`properties/${propertyId}/menu`, this.getCommonOpts())
  }

  public getAll(propertyId: string): Observable<BuilderResponse> {
    return this.apiService.get(`properties/${propertyId}/menu/builder`, this.getCommonOpts())
  }

  public create(
    propertyId: string,
    menuPage: Builder,
    translateName = false,
    meta?: {}
  ): Observable<Builder> {
    return this.apiService.post(
      `properties/${propertyId}/menu`,
      {
        menuPage: menuPage,
        translateName: translateName,
        ...meta,
      },
      this.getCommonOpts()
    )
  }

  public update(propertyId: string, pageId: string, params: any): Observable<Builder> {
    return this.apiService.patch(
      `properties/${propertyId}/menu/${pageId}`,
      {
        menuPage: (params && params.menuPage) || null,
        lang: (params && params.lang) || null,
        translateName: (params && params.translateName) || false,
      },
      this.getCommonOpts()
    )
  }

  public delete(propertyId: string, pageId: string): Observable<{ result: string }> {
    return this.apiService.delete(`properties/${propertyId}/menu/${pageId}`, this.getCommonOpts())
  }

  constructor(private apiService: ApiService, private route: ActivatedRoute) {}

  private getCommonOpts() {
    return { params: this.getCommonParams() }
  }

  private getCommonParams() {
    return this.route.snapshot.queryParams
  }
}
