import { Component, EventEmitter, Input, OnInit, Output, OnChanges, Inject } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { I18nService } from '@shared/services'
import { Property, User, Form, RoomService } from '@models'
import { blankValidator } from '@shared/validators'
import { selectLanguage } from '@shared/pipes/select-language.pipe'
import { without, propEq, prop } from 'ramda'
import { UserProfileFacade } from '@shared/facades/user-profile.facade'
import { ShopDeliveryFacade } from '@redux/shop-delivery/shop-delivery.facade'

const MAX_FIELD_LENGTH = 100

const ROLES = [
  {
    key: 'owner',
    name: 'StaffUserForm.role.name.owner',
    description: 'StaffUserForm.role.description.owner',
  },
  {
    key: 'manager',
    name: 'StaffUserForm.role.name.manager',
    description: 'StaffUserForm.role.description.manager',
  },
  {
    key: 'concierge',
    name: 'StaffUserForm.role.name.concierge',
    description: 'StaffUserForm.role.description.concierge',
  },
  {
    key: 'external',
    name: 'StaffUserForm.role.name.external',
    description: 'StaffUserForm.role.description.external',
  },
]

@Component({
  selector: 'hot-staff-user-form',
  templateUrl: './staff-user-form.component.html',
  styles: [
    `
      .hot-staff-interval-icon {
        color: #999;
        cursor: pointer;
        border-radius: 4px;
        transform: scale(0.9);
        transition: color 0.2s ease;
      }
      .hot-staff-interval-add-OLD {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        margin: auto;
        transform: translateX(7px);
      }
      .hot-staff-interval-add {
        position: absolute;
        right: -34px;
        top: 24px;
        color: #1976d2;
        background: #deecff;
      }
      .hot-staff-interval-add:hover {
        background: #e0edfe;
      }
      .hot-staff-interval-remove {
        position: absolute;
        top: 0;
        right: -24px;
        background: transparent;
      }
      .hot-staff-interval-remove:hover {
        color: #ff4136;
      }
      .hot-staff-user-tooltip {
        vertical-align: bottom;
        transform: scale(0.75);
      }
    `,
  ],
})
export class StaffUserFormComponent implements OnInit, OnChanges {
  @Input() public pending = false
  @Input() public property: Property
  @Input() public forms: Form[]
  @Input() public roomService: RoomService
  @Input() public user: User = null
  @Output() public submit = new EventEmitter<any>()
  @Output() public cancel = new EventEmitter()
  public timeSteps
  public workingHoursIsEnabled = false
  public workingHours: any[] = []
  public workingHoursChanged: any[] = []
  public workingHoursIsValid = true
  public notificationsSettings = []
  public notificationTypes = [
    { type: 'email', icon: 'email', tooltipKey: 'email' },
    // { type: 'sms', icon: 'phone_iphone', tooltip: 'sms' },
    { type: 'chat', icon: 'chat', tooltipKey: 'messenger' },
  ]

  public form: FormGroup

  public canEditRoles = this.userProfileFacade.can('property.members.update_roles')

  public pickupPoints$ = this.shopDeliveryFacade.pickupPoints$

  public get formNotificationsField() {
    return this.form.get('FormNotifications') as FormArray
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private fb: FormBuilder,
    private translationService: I18nService,
    private userProfileFacade: UserProfileFacade,
    private shopDeliveryFacade: ShopDeliveryFacade
  ) {
    this.initializeForm()
    this.updateFormWithUser(this.user)
  }

  ngOnInit() {
    this.updateFormWithUser(this.user)
  }

  ngOnChanges(change) {
    if (change.property && this.property) {
      this.shopDeliveryFacade.loadDeliveryInfo({
        property: this.property._id,
        shop: 'all',
      })
    }
    if (change.forms || change.roomService) {
      this.clearNotificationControls()
      this.updateWithFormsAndRoomService({
        forms: (change.forms && change.forms.currentValue) || this.forms,
        roomService: (change.roomService && change.roomService.currentValue) || this.roomService,
      })
    }
  }

  clearNotificationControls() {
    const control = this.formNotificationsField
    while (control.controls.length > 0) {
      control.removeAt(0)
    }
    this.notificationsSettings = []
  }

  updateWithFormsAndRoomService({ forms, roomService }) {
    const control = this.formNotificationsField
    if (roomService && this.property.config.ordersEnabled) {
      control.push(
        this.createFormNotificationsControl({
          _id: 'roomService',
          notifications: roomService.notifications,
        })
      )
    }
    if (forms && forms.length) {
      forms.forEach(form => {
        control.push(this.createFormNotificationsControl(form))
      })
    }
  }

  updateFormWithUser(user) {
    if (!user) {
      return
    }
    const formData = {
      Name: user.name,
      RoleDescription: user.roleDescription,
      Email: user.email,
      Phone: user.phone,
      Role: user.role,
      PickupPoint: user.pickupPoint,
      IntensiveShopOrderNotification: user.intensiveShopOrderNotification,
    }
    if (user.workingHours) {
      this.workingHoursIsEnabled = user.workingHours.length ? true : false
      this.workingHours = user.workingHours
      this.workingHoursChanged = user.workingHours
    }
    this.form.patchValue(formData)
  }

  createFormNotificationsControl(form) {
    let types = []
    if (this.user) {
      const notifications = form.notifications.find(n => n.user === this.user._id)
      if (notifications && notifications.types && notifications.types) {
        types = [...notifications.types]
      }
    }
    this.notificationsSettings.push({
      id: form._id,
      types,
      title:
        prop('value', (form.title || []).find(propEq('lang', this.locale.language))) ||
        prop('value', (form.title || []).find(propEq('lang', 'en'))),
    })
    return this.fb.group({
      FormId: form._id,
      Types: new FormControl(types),
    })
  }

  public onNotificationChange({ notifications }) {
    if (notifications) {
      this.form.patchValue({
        FormNotifications: notifications.map(x => ({ FormId: x.id, Types: x.types })),
      })
    }
    this.formNotificationsField.markAsTouched()
    this.form.markAsDirty()
  }

  public toggleWorkingHours() {
    this.workingHoursIsEnabled = !this.workingHoursIsEnabled
    this.form.markAsDirty()
  }

  public get maxFieldLenght(): number {
    return MAX_FIELD_LENGTH
  }

  public get enableSubmit(): boolean {
    return (
      this.form && this.form.dirty && this.form.valid && !this.pending && this.workingHoursIsValid
    )
  }

  public get userRoles(): Array<{ key: string; name: string }> {
    return ROLES
  }

  public get selectedRole(): typeof ROLES[number] {
    const selectedKey = this.form.get('Role').value || 'external'
    return ROLES.find(x => x.key === selectedKey)
  }

  public onSubmit(): void {
    if (this.enableSubmit) {
      this.updateData()
    } else {
      this.form.markAllAsTouched()
      this.form.updateValueAndValidity()
    }
  }

  public onCancel(): void {
    this.cancel.next()
  }

  public isShopServiceEnabled(): boolean {
    return Boolean(this.property && this.property.config.shopServiceEnabled)
  }

  private updateData(): void {
    if (!this.form.value.Phone || !this.form.value.Phone.trim()) {
      this.formNotificationsField.controls.forEach(form => {
        if (form.value.Types.includes('sms')) {
          form.patchValue({ Types: without(['sms'], form.value.Types) })
          form.markAsTouched()
        }
      })
    }
    const data = this.form.value
    const user: Partial<User> = {
      name: data.Name.trim(),
      roleDescription: data.RoleDescription.trim(),
      phone: data.Phone.trim(),
      email: data.Email.trim(),
      pickupPoint: data.PickupPoint,
      role: data.Role,
      workingHours: this.workingHoursIsEnabled ? this.workingHoursChanged : [],
    }

    if (this.isShopServiceEnabled) {
      user['intensiveShopOrderNotification'] = data.IntensiveShopOrderNotification
    }

    let notifications = []
    let roomServiceNotifications = null
    if (this.formNotificationsField.touched) {
      const control = this.formNotificationsField
      const forms = control.controls
      const roomServiceNotificationsControl = forms.find(x => x.value.FormId === 'roomService')
      roomServiceNotifications = roomServiceNotificationsControl
        ? {
            roomServiceId: this.roomService._id,
            types: roomServiceNotificationsControl.value.Types,
          }
        : null
      notifications = forms
        .filter(x => x.value.FormId !== 'roomService')
        .map(form => ({ formId: form.value.FormId, types: form.value.Types }))
    }
    this.submit.next({
      propertyId: this.property._id,
      userId: this.user ? this.user._id : undefined,
      user,
      notifications,
      roomServiceNotifications,
    })
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      Name: new FormControl('', this.getCommonValidators()),
      RoleDescription: new FormControl('', this.getCommonValidators()),
      Phone: new FormControl('', Validators.maxLength(MAX_FIELD_LENGTH)),
      Email: new FormControl(
        '',
        Validators.compose([this.getCommonValidators(), Validators.email])
      ),
      FormNotifications: this.fb.array([]),
      Role: new FormControl('concierge'),
      PickupPoint: new FormControl(''),
      IntensiveShopOrderNotification: new FormControl(false),
    })
  }

  private getCommonValidators(): ValidatorFn {
    return Validators.compose([
      Validators.required,
      Validators.maxLength(MAX_FIELD_LENGTH),
      blankValidator,
    ])
  }

  getFormTitle(formId) {
    if (formId === 'roomService') {
      return this.translationService.translate('Settings.linkLabel.roomService')
    }
    const f = this.forms.find(({ _id }) => _id === formId)
    return selectLanguage(f.title, this.locale.language)
  }

  get isPhoneValid() {
    const c = this.form.get('Phone')
    return c && c.value && c.valid
  }

  public onWorkingHoursChange(data) {
    this.workingHoursChanged = data.value
    this.workingHoursIsValid = data.isValid
    this.form.markAsDirty()
  }
}
