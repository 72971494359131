import { Component, EventEmitter, Input, OnChanges, Output, OnDestroy } from '@angular/core'
import {
  Builder,
  BuilderTracking,
  MenuContent,
  MenuPagesTemplates,
  Form,
  Property,
  FacilityGroup,
  Poll,
  ShopProductCategory,
  ShopProduct,
} from '@models'
import { MenuButton, MenuContentType } from '@models/builder.model'
import { AuthService } from '@shared/services'
import * as R from 'ramda'

const DEFAULT = 'default'

@Component({
  selector: 'hot-builder-page',
  templateUrl: './builder-page.component.html',
  styleUrls: ['./builder-page.component.scss'],
})
export class BuilderPageComponent implements OnChanges, OnDestroy {
  @Input() public pageIsLoading = false
  @Input() public languages: string[] = []
  @Input() public facilitiesGroups: FacilityGroup[] = []
  @Input() public builders: Builder[]
  @Input() public forms: Form[]
  @Input() public shopCategories: ShopProductCategory[] = []
  @Input() public shopProducts: ShopProduct[] = []
  @Input() public polls: Poll[]
  @Input() public templates: MenuPagesTemplates[]
  @Input() public property: Property
  @Input() public builder: Builder
  @Input() public modules: string[]
  @Input() public isUpdated: string
  @Input() public rootId: string
  @Input() public pageId: string
  @Input() public isShowTree = true

  @Output() public update = new EventEmitter()
  @Output() public delete = new EventEmitter()
  @Output() public createNewPage = new EventEmitter()
  @Output() public navigate = new EventEmitter()

  @Input()
  public set defaultLanguage(value) {
    this._defaultLanguage = value || this.languages[0]
  }

  public get defaultLanguage() {
    if (!this._defaultLanguage) {
      this.defaultLanguage = null
    }
    return this._defaultLanguage
  }

  public get enableManuallyTranslate(): boolean {
    return this.languages.length > 1
  }

  public _defaultLanguage: string
  public isEditMode: boolean
  public texts: MenuContent[] = []
  public images: MenuContent[] = []
  public buttons: MenuButton[] = []
  public currentLanguage: string

  constructor(public authService: AuthService) {}

  ngOnDestroy() {
    // HACK: Fixes scroll when text is too long, and chrome cause scroll jumps
    document.documentElement.classList.remove('hard-scroll')
  }

  ngOnChanges() {
    const content = this.builder.content || []

    this.texts = R.filter(v => v.type === MenuContentType.TEXT, content) as MenuContent[]
    this.images = R.filter(v => v.type === MenuContentType.IMAGE, content) as MenuContent[]
    this.buttons = R.filter(v => v.type === MenuContentType.BUTTON, content) as MenuButton[]
  }

  public changeLanguage(language) {
    this.currentLanguage = language
    this.copyDefaults(language)
    this.handleEditModeChange(false)
  }

  public handleShiftAction({ firstIndex, secondIndex, type }): void {
    const targetArray = this[type]
    if (targetArray[firstIndex] && targetArray[secondIndex]) {
      const tmpBtn = targetArray[firstIndex]
      targetArray[firstIndex] = targetArray[secondIndex]
      targetArray[secondIndex] = tmpBtn
      this.updateBuilder()
    }
  }

  handleRemoveAction({ index, type, noUpdate }) {
    const targetArray = this[type]
    targetArray.splice(index, 1)
    if (!noUpdate) {
      this.updateBuilder()
    }
  }

  get isHasTexts() {
    return (
      this.texts.length &&
      this.texts.some(text => {
        const translatedText = text.translations[this.currentLanguage] as string
        return translatedText && Boolean(translatedText.length)
      })
    )
  }

  get builderValid() {
    const hasImages = this.images.length
    const hasText = this.texts.length
    const hasName = this.builder.name[this.currentLanguage]
    return hasName || hasImages || hasText
  }

  public updateFacilitiesGroups(groups: string[]) {
    if (this.builderValid) {
      this.builder.facilitiesGroups = groups
      this.update.emit({ menuPage: this.builder, language: this.currentLanguage })
    }
  }

  public updateTrackingCode(trackingCodes: BuilderTracking[]) {
    if (this.builderValid) {
      this.builder.tracking = trackingCodes
      this.update.emit({ menuPage: this.builder, language: this.currentLanguage })
    }
  }

  public updateBuilder(traslateName = false) {
    if (this.builderValid) {
      this.builder.content = [...this.texts, ...this.images, ...this.buttons]
      this.saveCurrentToDefault()
      this.update.emit({
        menuPage: this.builder,
        language: this.currentLanguage,
        translateName: traslateName,
      })
    }
  }

  public saveCurrentToDefault() {
    const langToSave = this.languages.includes(this.defaultLanguage)
      ? this.defaultLanguage
      : this.currentLanguage

    this.builder.content.forEach((content, index) => {
      if (content.type === MenuContentType.IMAGE) {
        return
      }
      this.builder.content[index].translations.default = R.clone(content.translations[langToSave])
    })

    this.builder.name.default = this.builder.name[langToSave]
  }

  public copyDefaults(language) {
    this.texts = this.texts.map(text => {
      text.translations[language] = text.translations[language] || text.translations[DEFAULT]
      text.isEdit = false
      return text
    })

    this.buttons = this.buttons.map(button => {
      button.translations[language] =
        button.translations[language] || R.clone(button.translations[DEFAULT])
      button.isEdit = false
      return button
    })

    if (!this.builder.name.hasOwnProperty(language)) {
      this.builder.name[language] = this.builder.name.default
    }
  }

  public handleEditModeChange(editModeState) {
    this.isEditMode = editModeState
    // HACK: Fixes scroll when text is too long, and chrome cause scroll jumps
    if (this.isEditMode) {
      document.documentElement.classList.add('hard-scroll')
    } else {
      document.documentElement.classList.remove('hard-scroll')
    }
  }
}
