import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Inject,
  OnDestroy,
} from '@angular/core'
import { Subject } from 'rxjs'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { LanguageType } from '../../constants'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { FormBuilder, FormGroup, FormArray } from '@angular/forms'

interface Notification {
  id: string
  title: string
  subtitle?: string
  types: string[]
  kpiTime?: number
  kpiTypes?: string[]
}
// type KpiNotification = Notification & { kpiTime: number }
interface NotificationType {
  type: string
  icon?: string
  label?: string
  tooltip?: string
}

@Component({
  selector: 'hot-manager-notifications',
  templateUrl: './manager-notifications.component.html',
  // encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./manager-notifications.component.scss'],
})
export class ManagerNotificationsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lang: LanguageType
  @Input() withKpi = false
  @Input() smallTabs = false
  @Input() notifications: Notification[] = null
  @Input() types: NotificationType[] = []
  // @Input() kpiNotifications: KpiNotification[] = null

  @Output() update = new EventEmitter<{
    notifications: Array<{ id: string; types: string[] }>
    kpiNotifications: Array<{ id: string; types: string[]; time: number }> | null
  }>()

  public defaultKpiTime = 5

  public form = new FormGroup({})
  public ignoreChanges = false

  private destroyed = new Subject()

  public constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeForm()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notifications && changes.notifications.currentValue) {
      this.updateFormWithData(changes.notifications.currentValue)
    }
  }

  ngOnDestroy() {
    this.destroyed.next()
  }

  initializeForm() {
    this.form = this.fb.group({
      notifications: this.fb.array([]),
      kpiNotifications: this.fb.array([]),
    })
    if (this.notifications) {
      this.updateFormWithData(this.notifications)
    }
    this.form.valueChanges
      .pipe(
        // debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.destroyed)
      )
      .subscribe(this.onChange.bind(this))
  }

  updateFormWithData(notifications: Notification[]) {
    this.ignoreChanges = true
    this.form.setControl(
      'notifications',
      this.fb.array(
        notifications.map((x: Notification) => {
          return this.fb.group({
            id: x.id,
            types: [x.types],
            title: x.title,
            subtitle: x.subtitle || null,
          })
        })
      )
    )
    this.form.setControl(
      'kpiNotifications',
      this.fb.array(
        notifications.map((x: Notification) => {
          return this.fb.group({
            id: x.id,
            types: [x.kpiTypes],
            time: x.kpiTime,
            title: x.title,
            subtitle: x.subtitle || null,
            kpiTime: x.kpiTime || this.defaultKpiTime,
          })
        })
      )
    )
    this.ignoreChanges = false
  }

  public onChange(formData) {
    if (!this.update || this.ignoreChanges) {
      return
    }
    const notifications = formData.notifications.map(x => ({
      id: x.id,
      types: x.types,
    }))
    const kpiNotifications = this.withKpi
      ? formData.kpiNotifications.map(x => ({
          id: x.id,
          types: x.types,
          time: Math.abs(x.time || this.defaultKpiTime),
        }))
      : null
    this.update.emit({ notifications, kpiNotifications })
  }

  public get notificationsSettings(): FormArray {
    return this.form.get('notifications') as FormArray
  }
  public getNotificationsSettingsForm(index: number) {
    return (this.form.get('notifications') as FormArray).controls[index]
  }

  public get kpiNotificationsSettings(): FormArray {
    return this.form.get('kpiNotifications') as FormArray
  }
  public getKpiNotificationsSettingsForm(index: number) {
    return (this.form.get('kpiNotifications') as FormArray).controls[index]
  }
}
