import { ComponentPortal, ComponentType, Portal } from '@angular/cdk/portal'
import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { HotCalendarCellCssClasses } from './calendar-body'
import { createMissingDateImplError } from './datepicker-errors'
import {
  MatDatepickerIntl,
  MatMultiYearView,
  MatYearView,
  MatCalendarView,
  yearsPerPage,
} from '@angular/material/datepicker'

import { HotDatepickerRangeValue } from './datepicker-input'
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core'
import { HotMonthViewComponent } from '@shared/components/date-range-picker/month-view'
export type HotRangeCalendarView = 'month' | 'year' | 'multi-year'

@Component({
  moduleId: module.id,
  selector: 'hot-calendar',
  templateUrl: 'calendar.html',
  styleUrls: ['calendar.css'],
  exportAs: 'hotCalendar',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotCalendarComponent<D>
  implements AfterContentInit, AfterViewChecked, OnDestroy, OnChanges {
  // @HostBinding('class.mat-calendar')
  @Input()
  get beginDate(): D | null {
    return this._beginDate
  }
  set beginDate(value: D | null) {
    this._beginDate = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  private _beginDate: D | null

  @Input()
  get endDate(): D | null {
    return this._endDate
  }
  set endDate(value: D | null) {
    this._endDate = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  private _endDate: D | null

  @Input() rangeMode = false

  /** Enables datepicker closing after selection */
  @Input() closeAfterSelection = true

  @Input() maxIntervalInDays: number
  @Output() dateRangesChange = new EventEmitter<HotDatepickerRangeValue<D>>()

  beginDateSelected: D | boolean = false
  @Output() beginDateSelectedChange = new EventEmitter<D>()

  /** An input indicating the type of the header component, if set. */
  @Input() headerComponent: ComponentType<any>

  /** A portal containing the header component type for this calendar. */
  _calendarHeaderPortal: Portal<any>

  private _intlChanges: Subscription

  /**
   * Used for scheduling that focus should be moved to the active cell on the next tick.
   * We need to schedule it, rather than do it immediately, because we have to wait
   * for Angular to re-evaluate the view children.
   */
  private _moveFocusOnNextTick = false

  /** A date representing the period (month or year) to start the calendar in. */
  @Input()
  get startAt(): D | null {
    return this._startAt
  }
  set startAt(value: D | null) {
    this._startAt = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  private _startAt: D | null

  /** Whether the calendar should be started in month or year view. */
  @Input() startView: HotRangeCalendarView = 'month'

  /** The currently selected date. */
  @Input()
  get selected(): D | null {
    return this._selected
  }
  set selected(value: D | null) {
    this._selected = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  private _selected: D | null

  /** The minimum selectable date. */
  @Input()
  get minDate(): D | null {
    return this._minDate
  }
  set minDate(value: D | null) {
    this._minDate = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  private _minDate: D | null

  /** The maximum selectable date. */
  @Input()
  get maxDate(): D | null {
    return this._maxDate
  }
  set maxDate(value: D | null) {
    this._maxDate = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  private _maxDate: D | null

  /** Function used to filter which dates are selectable. */
  @Input() dateFilter: (date: D) => boolean

  /** Function that can be used to add custom CSS classes to dates. */
  @Input() dateClass: (date: D) => HotCalendarCellCssClasses

  /** Order the views when clicking on period label button */
  @Input() orderPeriodLabel: 'multi-year' | 'month' = 'multi-year'

  /** Emits when the currently selected date changes. */
  @Output() readonly selectedChange: EventEmitter<D> = new EventEmitter<D>()

  /**
   * Emits the year chosen in multiyear view.
   * This doesn't imply a change on the selected date.
   */
  @Output() readonly yearSelected: EventEmitter<D> = new EventEmitter<D>()

  /**
   * Emits the month chosen in year view.
   * This doesn't imply a change on the selected date.
   */
  @Output() readonly monthSelected: EventEmitter<D> = new EventEmitter<D>()

  /** Emits when any date is selected. */
  @Output() readonly _userSelection: EventEmitter<void> = new EventEmitter<void>()

  /** Reference to the current month view component. */
  @ViewChild(HotMonthViewComponent) monthView: HotMonthViewComponent<D>

  /** Reference to the current year view component. */
  @ViewChild(MatYearView) yearView: MatYearView<D>

  /** Reference to the current multi-year view component. */
  @ViewChild(MatMultiYearView) multiYearView: MatMultiYearView<D>

  /**
   * The current active date. This determines which time period is shown and which date is
   * highlighted when using keyboard navigation.
   */
  get activeDate(): D {
    return this._clampedActiveDate
  }
  set activeDate(value: D) {
    this._clampedActiveDate = this._dateAdapter.clampDate(value, this.minDate, this.maxDate)
    this.stateChanges.next()
    this._changeDetectorRef.markForCheck()
  }
  private _clampedActiveDate: D

  /** Whether the calendar is in month view. */
  get currentView(): MatCalendarView {
    return this._currentView
  }
  set currentView(value: MatCalendarView) {
    this._currentView = value
    this._moveFocusOnNextTick = true
    this._changeDetectorRef.markForCheck()
  }
  private _currentView: MatCalendarView

  /**
   * Emits whenever there is a state change that the header may need to respond to.
   */
  stateChanges = new Subject<void>()

  constructor(
    _intl: MatDatepickerIntl,
    @Optional() private _dateAdapter: DateAdapter<D>,
    @Optional() @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    if (!this._dateAdapter) {
      throw createMissingDateImplError('DateAdapter')
    }

    if (!this._dateFormats) {
      throw createMissingDateImplError('MAT_DATE_FORMATS')
    }

    this._intlChanges = _intl.changes.subscribe(() => {
      _changeDetectorRef.markForCheck()
      this.stateChanges.next()
    })
  }

  ngAfterContentInit() {
    this._calendarHeaderPortal = new ComponentPortal(
      // tslint:disable-next-line: no-use-before-declare
      this.headerComponent || HotCalendarHeaderComponent
    )
    this.activeDate = this.startAt || this._dateAdapter.today()

    // Assign to the private property since we don't want to move focus on init.
    this._currentView = this.startView
  }

  ngAfterViewChecked() {
    if (this._moveFocusOnNextTick) {
      this._moveFocusOnNextTick = false
      this.focusActiveCell()
    }
  }

  ngOnDestroy() {
    this._intlChanges.unsubscribe()
    this.stateChanges.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    const change =
      changes['minDate'] ||
      changes['maxDate'] ||
      changes['dateFilter'] ||
      changes['maxIntervalInDays']

    if (change && !change.firstChange) {
      const view = this._getCurrentViewComponent()

      if (view) {
        // We need to `detectChanges` manually here, because the `minDate`, `maxDate` etc. are
        // passed down to the view via data bindings which won't be up-to-date when we call `_init`.
        this._changeDetectorRef.detectChanges()
        view._init()
      }
    }

    this.stateChanges.next()
  }

  focusActiveCell() {
    this._getCurrentViewComponent()._focusActiveCell()
  }

  /** Handles date selection in the month view. */
  _dateSelected(date: D): void {
    if (this.rangeMode) {
      if (!this.beginDateSelected) {
        this.beginDateSelected = date
        this.beginDate = date
        this.endDate = date
        this.beginDateSelectedChange.emit(date)
      } else {
        this.beginDateSelected = false
        if (this._dateAdapter.compareDate(<D>this.beginDate, date) <= 0) {
          this.endDate = date
        } else {
          this.endDate = this._dateAdapter.clone(this.beginDate)
          this.beginDate = date
        }
        this.dateRangesChange.emit({ begin: <D>this.beginDate, end: this.endDate })
      }
    } else if (!this._dateAdapter.sameDate(date, this.selected)) {
      this.selectedChange.emit(date)
    }
  }

  _yearSelectedInMultiYearView(normalizedYear: D) {
    this.yearSelected.emit(normalizedYear)
  }

  _monthSelectedInYearView(normalizedMonth: D) {
    this.monthSelected.emit(normalizedMonth)
  }

  _userSelected(): void {
    this._userSelection.emit()
  }

  _goToDateInView(date: D, view: 'month' | 'year' | 'multi-year'): void {
    this.activeDate = date
    this.currentView = view
  }

  /**
   * @param obj The object to check.
   * @returns The given object if it is both a date instance and valid, otherwise null.
   */
  private _getValidDateOrNull(obj: any): D | null {
    return this._dateAdapter.isDateInstance(obj) && this._dateAdapter.isValid(obj) ? obj : null
  }

  /** Returns the component instance that corresponds to the current calendar view. */
  private _getCurrentViewComponent() {
    return this.monthView || this.yearView || this.multiYearView
  }
}

@Component({
  moduleId: module.id,
  selector: 'hot-calendar-header',
  templateUrl: 'calendar-header.html',
  exportAs: 'hotCalendarHeader',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotCalendarHeaderComponent<D> {
  constructor(
    private _intl: MatDatepickerIntl,
    @Inject(forwardRef(() => HotCalendarComponent)) public calendar: HotCalendarComponent<D>,
    @Optional() private _dateAdapter: DateAdapter<D>,
    @Optional() @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this.calendar.stateChanges.subscribe(() => changeDetectorRef.markForCheck())
  }

  /** The label for the current calendar view. */
  get periodButtonText(): string {
    if (this.calendar.currentView === 'month') {
      return this._dateAdapter
        .format(this.calendar.activeDate, this._dateFormats.display.monthYearLabel)
        .toLocaleUpperCase()
    }
    if (this.calendar.currentView === 'year') {
      return this._dateAdapter.getYearName(this.calendar.activeDate)
    }

    // The offset from the active year to the "slot" for the starting year is the
    // *actual* first rendered year in the multi-year view, and the last year is
    // just yearsPerPage - 1 away.
    const activeYear = this._dateAdapter.getYear(this.calendar.activeDate)
    const minYearOfPage =
      activeYear -
      getActiveOffset(
        this._dateAdapter,
        this.calendar.activeDate,
        this.calendar.minDate,
        this.calendar.maxDate
      )
    const maxYearOfPage = minYearOfPage + yearsPerPage - 1
    return `${minYearOfPage} \u2013 ${maxYearOfPage}`
  }

  get periodButtonLabel(): string {
    return this.calendar.currentView === 'month'
      ? this._intl.switchToMultiYearViewLabel
      : this._intl.switchToMonthViewLabel
  }

  /** The label for the previous button. */
  get prevButtonLabel(): string {
    return {
      month: this._intl.prevMonthLabel,
      year: this._intl.prevYearLabel,
      'multi-year': this._intl.prevMultiYearLabel,
    }[this.calendar.currentView]
  }

  /** The label for the next button. */
  get nextButtonLabel(): string {
    return {
      month: this._intl.nextMonthLabel,
      year: this._intl.nextYearLabel,
      'multi-year': this._intl.nextMultiYearLabel,
    }[this.calendar.currentView]
  }

  /** Handles user clicks on the period label.
   * Option`calendar.orderPeriodLabel` sort the label period views.
   * - Default [multi-year]: multi-year then back to month
   * - Month [month]: month > year > multi-year
   */
  currentPeriodClicked(): void {
    const mouthFirstOrder: MatCalendarView[] = ['month', 'year', 'multi-year']
    const defaultOrder: MatCalendarView[] = ['month', 'multi-year', 'month']
    const orderPeriod = this.calendar.orderPeriodLabel === 'month' ? mouthFirstOrder : defaultOrder
    switch (this.calendar.currentView) {
      case 'month':
        this.calendar.currentView = orderPeriod[1]
        break
      case 'year':
        this.calendar.currentView = orderPeriod[2]
        break
      default:
        this.calendar.currentView = orderPeriod[0]
        break
    }
  }

  /** Handles user clicks on the previous button. */
  previousClicked(): void {
    this.calendar.activeDate =
      this.calendar.currentView === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(
            this.calendar.activeDate,
            this.calendar.currentView === 'year' ? -1 : -yearsPerPage
          )
  }

  /** Handles user clicks on the next button. */
  nextClicked(): void {
    this.calendar.activeDate =
      this.calendar.currentView === 'month'
        ? this._dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(
            this.calendar.activeDate,
            this.calendar.currentView === 'year' ? 1 : yearsPerPage
          )
  }

  /** Whether the previous period button is enabled. */
  previousEnabled(): boolean {
    if (!this.calendar.minDate) {
      return true
    }
    return (
      !this.calendar.minDate || !this._isSameView(this.calendar.activeDate, this.calendar.minDate)
    )
  }

  /** Whether the next period button is enabled. */
  nextEnabled(): boolean {
    return (
      !this.calendar.maxDate || !this._isSameView(this.calendar.activeDate, this.calendar.maxDate)
    )
  }

  /** Whether the two dates represent the same view in the current view mode (month or year). */
  private _isSameView(date1: D, date2: D): boolean {
    if (this.calendar.currentView === 'month') {
      return (
        this._dateAdapter.getYear(date1) === this._dateAdapter.getYear(date2) &&
        this._dateAdapter.getMonth(date1) === this._dateAdapter.getMonth(date2)
      )
    }
    if (this.calendar.currentView === 'year') {
      return this._dateAdapter.getYear(date1) === this._dateAdapter.getYear(date2)
    }
    // Otherwise we are in 'multi-year' view.
    return isSameMultiYearView(
      this._dateAdapter,
      date1,
      date2,
      this.calendar.minDate,
      this.calendar.maxDate
    )
  }
}

export function isSameMultiYearView<D>(
  dateAdapter: DateAdapter<D>,
  date1: D,
  date2: D,
  minDate: D | null,
  maxDate: D | null
): boolean {
  const year1 = dateAdapter.getYear(date1)
  const year2 = dateAdapter.getYear(date2)
  const startingYear = getStartingYear(dateAdapter, minDate, maxDate)
  return (
    Math.floor((year1 - startingYear) / yearsPerPage) ===
    Math.floor((year2 - startingYear) / yearsPerPage)
  )
}

export function getActiveOffset<D>(
  dateAdapter: DateAdapter<D>,
  activeDate: D,
  minDate: D | null,
  maxDate: D | null
): number {
  const activeYear = dateAdapter.getYear(activeDate)
  return euclideanModulo(activeYear - getStartingYear(dateAdapter, minDate, maxDate), yearsPerPage)
}

function getStartingYear<D>(
  dateAdapter: DateAdapter<D>,
  minDate: D | null,
  maxDate: D | null
): number {
  let startingYear = 0
  if (maxDate) {
    const maxYear = dateAdapter.getYear(maxDate)
    startingYear = maxYear - yearsPerPage + 1
  } else if (minDate) {
    startingYear = dateAdapter.getYear(minDate)
  }
  return startingYear
}

function euclideanModulo(a: number, b: number): number {
  return ((a % b) + b) % b
}
