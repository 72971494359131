import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { CURRENCY_SIGN } from '@shared/constants'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

enum IFeeType {
  Fixed = 'fixed',
  Percent = 'percent',
}

interface IFee {
  type: IFeeType
  value: number
}

const positiveFeeValidator = control => {
  if (control) {
    if (!String(control.value)) {
      return { number: true }
    }
    const value = Number(control.value)
    if (value < 0) {
      return { number: true }
    }
  }
  return null
}

@Component({
  selector: 'hot-fee-input',
  templateUrl: './fee-input.component.html',
  styles: [
    `
      .input {
        -moz-appearance: textfield;
      }
      .input::-webkit-outer-spin-button,
      .input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .buttons {
        position: absolute;
        top: 10px;
        right: 8px;
        display: flex;
      }
      .button {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        border: none;
        outline: none;
        border-radius: 4px;
        background: #e9e9e9;
        color: rgba(0, 0, 0, 0.4);
        cursor: pointer;
        transition: background-color 0.15s ease, color 0.15s ease;
      }
      .button.active {
        background: #357edd;
        color: #fff;
      }
      .button:focus {
        box-shadow: 0 0 0 3px #c2dcfd;
      }
    `,
  ],
})
export class FeeInputComponent implements OnInit, OnDestroy {
  @Input() value: IFee = null
  @Input() label: string = null
  @Input() lang: string
  @Input() currency: string = null
  @Output() change = new EventEmitter<IFee>()

  form: FormGroup

  private destroyed = new Subject()

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      type: new FormControl(IFeeType.Fixed),
      value: new FormControl('0', [Validators.required, positiveFeeValidator]),
    })
  }

  ngOnInit() {
    if (this.value) {
      this.updateFormWithData(this.value)
    }
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(formValue => {
      this.change.emit(formValue)
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  updateFormWithData(value) {
    this.form.patchValue(value, { emitEvent: false, onlySelf: true })
  }

  get currencySign() {
    return CURRENCY_SIGN[this.currency || 'usd']
  }

  onSetType(type: IFeeType) {
    this.form.patchValue({
      type,
      value: 0,
    })
  }

  onSetFixedType() {
    return this.onSetType(IFeeType.Fixed)
  }

  onSetPercentType() {
    return this.onSetType(IFeeType.Percent)
  }
}
