<div [formGroup]="form" class="flex">
  <div class="w-100 flex flex-row relative">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <input
        *ngIf="form.get('type').value === 'fixed'"
        min="0"
        class="input"
        formControlName="value"
        matInput
        type="number"
        [min]="0"
        hotCurrency
      />
      <input
        *ngIf="form.get('type').value === 'percent'"
        min="0"
        class="input"
        formControlName="value"
        matInput
        type="number"
        [min]="0"
        hotPercents
      />
      <mat-error class="red" *ngIf="form && form.touched && form.get('value').hasError('number')">
        {{ 'Shop.label.feeValueValidationError' | translate: lang }}
      </mat-error>
      <mat-error class="red" *ngIf="form && form.touched && form.get('value').hasError('required')">
        {{ 'Shop.field.fieldRequired' | translate: lang }}
      </mat-error>
    </mat-form-field>
    <div class="buttons">
      <button
        (click)="onSetFixedType()"
        class="button mr2"
        [class.active]="form.get('type').value === 'fixed'"
      >
        {{ currencySign }}
      </button>
      <button
        (click)="onSetPercentType()"
        class="button"
        [class.active]="form.get('type').value === 'percent'"
      >
        %
      </button>
    </div>
  </div>
</div>
