import { Component, Input, Output, Inject, EventEmitter, OnInit } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { ShopProduct, ShopProductQuantityTrackingSource, ShopProductFieldKind } from '@models'
import { CURRENCY_SIGN } from '@shared/constants'

const fieldKindsWithOptions = [ShopProductFieldKind.Options, ShopProductFieldKind.Select]

@Component({
  selector: 'hot-shop-product-card',
  templateUrl: './shop-product-card.component.html',
  styleUrls: ['./shop-product-card.component.scss'],
})
export class ShopProductCardComponent implements OnInit {
  @Input() product: ShopProduct
  @Input() lang: string
  @Input() currency: string
  @Input() selected = false
  @Input() clickable = false
  @Input() draggable = false
  @Output() click = new EventEmitter()

  public quantity = null

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit() {
    if (!this.product) {
      return
    }
    if (!this.product.quantityTracking) {
      return
    }
    if (this.product.quantityTrackingSource === ShopProductQuantityTrackingSource.Product) {
      this.quantity = {
        value: this.product.quantity.value > 0 ? this.product.quantity.value : 0,
        prevent: this.product.quantity.preventSellingWhenOutOfStock,
      }
      return
    }
    if (this.product.quantityTrackingSource === ShopProductQuantityTrackingSource.Option) {
      this.quantity = (this.product.fields || [])
        .filter(x => fieldKindsWithOptions.includes(x.kind))
        .reduce(
          (fieldAcc, field) => {
            const optionQuantity = (field.options || []).reduce(
              (optionAcc, option) => {
                if (option.quantity && option.quantity.value && option.quantity.value > 0) {
                  optionAcc.value += option.quantity.value
                }
                if (!option.quantity.preventSellingWhenOutOfStock) {
                  optionAcc.prevent = false
                }
                return optionAcc
              },
              { value: 0, prevent: true }
            )
            if (optionQuantity.value) {
              fieldAcc.value += optionQuantity.value
            }
            fieldAcc.prevent = optionQuantity.prevent
            if (!field.required) {
              fieldAcc.prevent = false
            }
            return fieldAcc
          },
          { value: 0, prevent: true }
        )
    }
  }

  get currencySign() {
    return CURRENCY_SIGN[this.currency]
  }

  public onClick(event) {
    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
    this.click.next(event)
  }

  public get quantityValue() {
    return this.product && this.product.quantity && String(this.product.quantity.value)
  }
}
