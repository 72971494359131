<div class="flex-l flex-m">
  <div class="flex-auto flex-none-l flex-none-m mb3 mr4-l mr3-m">
    <div class="tabs-wrapper sticky">
      <div class="tabs bg-white">
        <div (click)="selectTab(formTabs.General)" class="tab-item">
          {{ 'PropertyForm.tab.' + formTabs.General | translate: locale.language }}
        </div>
        <div (click)="selectTab(formTabs.Contact)" class="tab-item">
          {{ 'PropertyForm.tab.' + formTabs.Contact | translate: locale.language }}
        </div>
        <div (click)="selectTab(formTabs.Booking)" class="tab-item">
          {{ 'PropertyForm.tab.' + formTabs.Booking | translate: locale.language }}
        </div>
        <div (click)="selectTab(formTabs.Content)" class="tab-item">
          {{ 'PropertyForm.tab.' + formTabs.Content | translate: locale.language }}
        </div>
        <div
          *ngIf="appleWalletIsEnabled"
          (click)="selectTab(formTabs.AppleWallet)"
          class="tab-item"
        >
          {{ 'PropertyForm.tab.' + formTabs.AppleWallet | translate: locale.language }}
        </div>
        <div *ngIf="canEditConfig" (click)="selectTab(formTabs.Admin)" class="tab-item">
          {{ 'PropertyForm.tab.' + formTabs.Admin | translate: locale.language }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex-auto">
    <div class="content-wrapper w-100" [formGroup]="form">
      <!-- GENERAL -->
      <ng-container>
        <div class="content" [attr.data-to]="formTabs.General">
          <div class="content-title">
            🏨 {{ 'PropertyForm.tab.' + formTabs.General | translate: locale.language }}
          </div>

          <section>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'PropertyForm.field.name' | translate: locale.language }}</mat-label>
              <input #name formControlName="Name" matInput [maxlength]="maxLength" required />
              <mat-hint align="end">{{ name.value.length }} / {{ maxLength }}</mat-hint>
              <mat-error *ngIf="form.touched && form.get('Name').hasError('required')">
                {{ 'PropertyForm.error.nameRequired' | translate: locale.language }}
              </mat-error>
              <mat-error *ngIf="form && form.touched && form.get('Name').hasError('blank')">
                {{ 'PropertyForm.error.nameInvalid' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="canEditConfig" appearance="fill">
              <mat-label>{{ 'PropertyForm.field.alias' | translate: locale.language }}</mat-label>
              <input #alias formControlName="Alias" matInput [maxlength]="maxLength" />
              <mat-hint align="end">{{ alias.value.length }} / {{ maxLength }}</mat-hint>
              <mat-error *ngIf="form && form.touched && form.get('Alias').hasError('hasSpace')">
                {{ 'PropertyForm.error.aliasInvalid' | translate: locale.language }}
              </mat-error>
            </mat-form-field>
          </section>

          <section>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'PropertyForm.field.country' | translate: locale.language }}</mat-label>
              <input
                matInput
                #countryInput
                formControlName="Country"
                [matAutocomplete]="autoCountry"
                required
              />
              <mat-autocomplete #autoCountry="matAutocomplete">
                <mat-option *ngFor="let country of filteredCountries$ | async" [value]="country">
                  {{ country }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="form.touched && form.get('Country').hasError('oneOfArray')">
                {{ 'PropertyForm.error.countryOneOfArrayError' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'PropertyForm.field.city' | translate: locale.language }}</mat-label>
              <input
                matInput
                #countryInput
                formControlName="City"
                required
                [matAutocomplete]="autoCity"
              />
              <div *ngIf="searchCityPending" class="hot-search-city-loader">
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
              <mat-autocomplete
                #autoCity="matAutocomplete"
                (optionSelected)="citySelected$.next($event)"
              >
                <mat-option *ngFor="let option of searchCityResults" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'PropertyForm.field.address' | translate: locale.language }}</mat-label>
              <input
                #address
                formControlName="Address"
                matInput
                [maxlength]="maxAddressLength"
                hotTranslatableField
                [language]="language"
                [defaultLanguage]="defaultLanguage"
              />
              <mat-hint align="end">{{ address.value.length }} / {{ maxAddressLength }}</mat-hint>
              <mat-error *ngIf="form.touched && form.get('Address').hasError('blank')">
                {{ 'PropertyForm.error.addressInvalid' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <div class="flex">
              <div class="flex w-50 pr2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>{{
                    'PropertyForm.field.latitude' | translate: locale.language
                  }}</mat-label>
                  <input #lat formControlName="Lat" matInput [maxlength]="maxLength" required />
                  <mat-hint align="end">{{ lat.value.length }} / {{ maxLength }}</mat-hint>
                  <mat-error *ngIf="form.touched && form.get('Lat').hasError('required')">
                    {{ 'forms.error.fieldRequired' | translate: locale.language }}
                  </mat-error>
                  <mat-error *ngIf="form.touched && form.get('Lat').hasError('number')">
                    {{ 'forms.error.invalidNumber' | translate: locale.language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      form.touched &&
                      !form.get('Lat').hasError('number') &&
                      form.get('Lat').hasError('range')
                    "
                  >
                    {{ 'forms.error.invalidLatitude' | translate: locale.language }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex justify-end w-50 pl2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>{{
                    'PropertyForm.field.longitude' | translate: locale.language
                  }}</mat-label>
                  <input #lng formControlName="Lng" matInput [maxlength]="maxLength" required />
                  <mat-hint align="end">{{ lng.value.length }} / {{ maxLength }}</mat-hint>
                  <mat-error *ngIf="form.touched && form.get('Lng').hasError('required')">
                    {{ 'forms.error.fieldRequired' | translate: locale.language }}
                  </mat-error>
                  <mat-error *ngIf="form.touched && form.get('Lng').hasError('number')">
                    {{ 'forms.error.invalidNumber' | translate: locale.language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      form.touched &&
                      !form.get('Lng').hasError('number') &&
                      form.get('Lng').hasError('range')
                    "
                  >
                    {{ 'forms.error.invalidLongitude' | translate: locale.language }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'forms.title.timeZone' | translate: locale.language }}</mat-label>
              <mat-select [formControl]="form.get('TimeZone')" required>
                <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.value">
                  {{ timeZone.name }}
                </mat-option>
              </mat-select>
              <mat-error
                class="red"
                *ngIf="form && form.touched && form.get('TimeZone').hasError('required')"
              >
                {{ 'forms.error.fieldRequired' | translate: locale.language }}
              </mat-error>
            </mat-form-field>
          </section>

          <div class="flex">
            <div class="flex w-50 pr2">
              <mat-form-field class="w-100" appearance="fill">
                <mat-label>{{
                  'PropertyForm.field.checkInTime' | translate: locale.language
                }}</mat-label>
                <mat-select [formControl]="form.get('CheckInTime')">
                  <mat-option *ngFor="let timeStep of timeSteps" [value]="timeStep">
                    {{ timeStep }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex justify-end w-50 pl2">
              <mat-form-field class="w-100" appearance="fill">
                <mat-label>{{
                  'PropertyForm.field.checkOutTime' | translate: locale.language
                }}</mat-label>
                <mat-select [formControl]="form.get('CheckOutTime')">
                  <mat-option *ngFor="let timeStep of timeSteps" [value]="timeStep">
                    {{ timeStep }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <section>
            <mat-form-field appearance="fill">
              <mat-label>{{
                'PropertyForm.field.legalAgreement' | translate: locale.language
              }}</mat-label>
              <textarea
                #legalAgreement
                matInput
                formControlName="LegalAgreement"
                [maxLength]="maxDescriptionLength"
                hotTranslatableField
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="1"
                [language]="language"
                [defaultLanguage]="defaultLanguage"
              ></textarea>
              <mat-hint align="end"
                >{{ legalAgreement.value.length }} / {{ maxDescriptionLength }}</mat-hint
              >
            </mat-form-field>
          </section>

          <section *ngIf="showAdminSection$ | async">
            <mat-label class="black-50 mt3 mb1 db">
              {{ 'PropertyForm.field.propertyKind.title' | translate: locale.language }}
            </mat-label>
            <mat-radio-group formControlName="Kind" class="flex mv1">
              <mat-radio-button
                *ngFor="let kind of propertyKinds"
                color="primary"
                class="mr3"
                [value]="kind"
                >{{
                  'PropertyForm.field.propertyKind.' + kind | translate: locale.language
                }}</mat-radio-button
              >
            </mat-radio-group>
          </section>
        </div>
      </ng-container>

      <!-- CONTACT -->
      <ng-container>
        <div class="content" [attr.data-to]="formTabs.Contact">
          <div class="content-title">
            🔗 {{ 'PropertyForm.tab.' + formTabs.Contact | translate: locale.language }}
          </div>

          <section>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'PropertyForm.field.email' | translate: locale.language }}</mat-label>
              <input #email formControlName="Email" matInput [maxlength]="maxLength" />
              <mat-hint align="end">{{ email.value.length }} / {{ maxLength }}</mat-hint>
              <mat-error *ngIf="form.touched && form.get('Email').hasError('email')">
                {{ 'forms.error.invalidEmail' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <div class="mb4" [class.mv4]="phoneControls && phoneControls.length > 0">
              <div
                *ngIf="phoneControls && phoneControls.length > 0"
                class="hot-property-form-text mb3 fw5 black-70"
              >
                {{ 'PropertyForm.field.phones' | translate: locale.language }}
              </div>
              <div formArrayName="Phones" *ngFor="let phone of phoneControls; let i = index">
                <div class="flex items-center" [formGroupName]="i">
                  <div class="flex w-50 pr2">
                    <mat-form-field class="w-100" appearance="fill">
                      <mat-label>{{
                        'PropertyForm.field.phoneLabel' | translate: locale.language
                      }}</mat-label>
                      <input
                        #phoneLabel
                        formControlName="Label"
                        matInput
                        [maxlength]="maxLength"
                        hotTranslatableField
                        [language]="language"
                        [defaultLanguage]="defaultLanguage"
                      />
                      <mat-hint align="end"
                        >{{ phoneLabel.value.length }} / {{ maxLength }}</mat-hint
                      >
                      <mat-error *ngIf="phone.get('Label').hasError('blank')">
                        {{ 'PropertyForm.error.phoneLabelInvalid' | translate: locale.language }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="flex items-center w-50 pl2">
                    <mat-form-field class="flex-auto" appearance="fill">
                      <mat-label>{{
                        'PropertyForm.field.phoneNumber' | translate: locale.language
                      }}</mat-label>
                      <input
                        #phoneValue
                        formControlName="Value"
                        matInput
                        [maxlength]="maxLength"
                        required
                      />
                      <mat-hint align="end"
                        >{{ phoneValue.value.length }} / {{ maxLength }}</mat-hint
                      >
                      <mat-error *ngIf="phone.get('Value').hasError('required')">
                        {{ 'forms.error.fieldRequired' | translate: locale.language }}
                      </mat-error>
                      <mat-error *ngIf="phone.get('Value').hasError('blank')">
                        {{ 'PropertyForm.error.phoneNumberInvalid' | translate: locale.language }}
                      </mat-error>
                    </mat-form-field>
                    <button mat-icon-button (click)="removePhone(i)" class="nt2 black-70">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex justify-start w-100">
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addPhone()"
                  [disabled]="!enableAddPhone"
                >
                  {{ 'PropertyForm.action.addPhone' | translate: locale.language }}
                </button>
              </div>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>{{
                'PropertyForm.field.websiteUrl' | translate: locale.language
              }}</mat-label>
              <input #website formControlName="Website" matInput [maxlength]="maxLength" />
              <mat-hint align="end">{{ website.value.length }} / {{ maxLength }}</mat-hint>
              <mat-error *ngIf="form && form.touched && form.get('Website').hasError('url')">
                {{ 'forms.error.invalidUrl' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{
                'PropertyForm.field.shopWebsiteUrl' | translate: locale.language
              }}</mat-label>
              <input #website formControlName="ShopWebsite" matInput [maxlength]="maxLength" />
              <mat-hint align="end">{{ website.value.length }} / {{ maxLength }}</mat-hint>
              <mat-error *ngIf="form && form.touched && form.get('ShopWebsite').hasError('url')">
                {{ 'forms.error.invalidUrl' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{
                'PropertyForm.field.tripAdvisorUrl' | translate: locale.language
              }}</mat-label>
              <input #tripadvisor formControlName="TripAdvisor" matInput />
              <mat-error *ngIf="form && form.touched && form.get('TripAdvisor').hasError('url')">
                {{ 'forms.error.invalidUrl' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{
                'PropertyForm.field.sharingText' | translate: locale.language
              }}</mat-label>
              <input
                #vksharingtext
                formControlName="VkSharingText"
                matInput
                hotTranslatableField
                [language]="language"
                [defaultLanguage]="defaultLanguage"
              />
            </mat-form-field>
          </section>
        </div>
      </ng-container>

      <!-- BOOKING -->
      <ng-container>
        <div class="content" [attr.data-to]="formTabs.Booking">
          <div class="content-title">
            🗓 {{ 'PropertyForm.tab.' + formTabs.Booking | translate: locale.language }}
          </div>

          <section>
            <div *ngIf="bookingEngineConfig">
              <mat-form-field class="mv2 db" appearance="fill">
                <mat-label>{{
                  'PropertyForm.field.bookingEngineType' | translate: locale.language
                }}</mat-label>
                <mat-select [formControl]="form.get('BookingEngineType')">
                  <mat-option *ngFor="let bookingEngine of bookingEngines" [value]="bookingEngine">
                    {{ getBookingEngineLabel(bookingEngine) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="isCustomBookingEngine" class="mv2 db" appearance="fill">
                <mat-label>{{
                  'PropertyForm.field.bookingEngineForm' | translate: locale.language
                }}</mat-label>
                <mat-select [formControl]="form.get('BookingEngineForm')">
                  <mat-option>&mdash;</mat-option>
                  <mat-option *ngFor="let engineForm of customEngineForms" [value]="engineForm._id">
                    {{ engineForm.title | selectLanguage: locale.language }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                *ngIf="engineRequiresExternalHotelId"
                class="mv2 db"
                appearance="fill"
              >
                <mat-label>{{
                  'PropertyForm.field.externalHotelId' | translate: locale.language
                }}</mat-label>
                <input formControlName="BookingEngineExternalHotelId" matInput />
                <mat-error
                  *ngIf="
                    form &&
                    form.touched &&
                    form.get('BookingEngineExternalHotelId').hasError('blank')
                  "
                >
                  {{ 'forms.error.fieldRequired' | translate: locale.language }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="mv2 db" appearance="fill">
                <mat-label>{{
                  'PropertyForm.field.bookingEngineUrl' | translate: locale.language
                }}</mat-label>
                <input #bookingEngineUrl formControlName="BookingEngineUrl" matInput />
                <mat-error
                  *ngIf="form && form.touched && form.get('BookingEngineUrl').hasError('url')"
                >
                  {{ 'forms.error.invalidUrl' | translate: locale.language }}
                </mat-error>
              </mat-form-field>
              <div class="mv2" *ngIf="engineAllowsDepartureOnDayOfArrival">
                <mat-checkbox color="primary" formControlName="AllowDepartureOnDayOfArrival">
                  {{
                    'PropertyForm.field.bookingAllowDepartureOnDayOfArrival'
                      | translate: locale.language
                  }}
                </mat-checkbox>
              </div>
              <div class="mv2" *ngIf="engineAllowsChildren">
                <mat-checkbox color="primary" formControlName="AllowChildren">
                  {{ 'PropertyForm.field.bookingAllowChildren' | translate: locale.language }}
                </mat-checkbox>
              </div>
              <div class="mv2" *ngIf="engineAllowChildrenAge">
                <mat-checkbox
                  color="primary"
                  formControlName="ChildrenAge"
                  [disabled]="childrenAgeDisabled"
                >
                  {{ 'PropertyForm.field.bookingChildrenAge' | translate: locale.language }}
                </mat-checkbox>
              </div>
              <div class="mv2" *ngIf="engineAllowsHourlyBooking">
                <mat-checkbox color="primary" formControlName="AllowHourlyBooking">
                  {{ 'PropertyForm.field.bookingAllowHourlyBooking' | translate: locale.language }}
                </mat-checkbox>
              </div>
            </div>
          </section>
        </div>
      </ng-container>

      <!-- CONTENT -->
      <ng-container>
        <div class="content" [attr.data-to]="formTabs.Content">
          <div class="content-title">
            🖋 {{ 'PropertyForm.tab.' + formTabs.Content | translate: locale.language }}
          </div>

          <section>
            <div class="flex flex-column">
              <span class="hot-property-form-text mb1"
                >{{ 'PropertyForm.field.logo' | translate: locale.language }} *</span
              >
              <hot-upload-file
                #logoUploader
                class="self-start"
                [preventClick]="true"
                [class.dn]="logoCrop.loaded"
                [fileUrl]="property ? property.logoUrl : ''"
                [placeholder]="'components.UploadFile.uploadImage'"
                [forceUseProxy]="form.get('UseProxy').value || null"
                (change)="onUploadLogo($event)"
                (click)="logoUploaderClick($event)"
              >
              </hot-upload-file>

              <hot-image-cropper
                #logoCrop
                class="hot-builder-image-loader"
                [class.hot-builder-image-loader-crop]="logoCrop.loaded"
                [aspectRatio]="4 / 4"
                [showPlaceholder]="false"
                (change)="onUploadLogo($event)"
              >
              </hot-image-cropper>

              <span class="hot-property-form-text mb1 mt3">{{
                'PropertyForm.field.photo' | translate: locale.language
              }}</span>
              <hot-upload-file
                #photoUploader
                class="self-start"
                [class.dn]="photoCrop.loaded"
                [preventClick]="true"
                [previewImageWidth]="previewPhotoWidth"
                [placeholder]="'components.UploadFile.uploadImage'"
                [forceUseProxy]="form.get('UseProxy').value || null"
                [fileUrl]="propertyPhotoUrl"
                (change)="onUploadPhoto($event)"
                (click)="photoUploaderClick($event)"
              >
              </hot-upload-file>

              <hot-image-cropper
                #photoCrop
                class="hot-builder-image-loader"
                [class.hot-builder-image-loader-crop]="photoCrop.loaded"
                [showPlaceholder]="false"
                [resizeToWidth]="1280"
                (change)="onUploadPhoto($event)"
              >
              </hot-image-cropper>
            </div>
          </section>

          <section>
            <mat-form-field appearance="fill">
              <mat-label>{{
                'PropertyForm.field.description' | translate: locale.language
              }}</mat-label>
              <textarea
                #description
                matInput
                formControlName="Description"
                [maxlength]="maxDescriptionLength"
                hotTranslatableField
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="2"
                [language]="language"
                [defaultLanguage]="defaultLanguage"
              ></textarea>
              <mat-hint align="end"
                >{{ description.value.length }} / {{ maxDescriptionLength }}</mat-hint
              >
              <mat-error *ngIf="form.touched && form.get('Description').hasError('blank')">
                {{ 'PropertyForm.error.descriptionInvalid' | translate: locale.language }}
              </mat-error>
            </mat-form-field>

            <div class="flex flex-column">
              <span class="hot-property-form-text mb1">{{
                'PropertyForm.field.languages' | translate: locale.language
              }}</span>
              <ng-container formArrayName="Languages">
                <div
                  *ngFor="let language of languagesControls; let i = index"
                  [formGroupName]="i"
                  class="mb1 flex items-center hide-child"
                >
                  <mat-checkbox color="primary" formControlName="IsSelected">
                    {{ getLanguageLabel(i) }}
                  </mat-checkbox>
                  <span
                    class="dib ml1 f7 black-50"
                    *ngIf="
                      defaultLanguage === language.get('Language').value.value;
                      else makeDefaultLangButton
                    "
                  >
                    &mdash;
                    {{ 'components.LangSelector.defaultLanguage' | translate: locale.language }}
                  </span>
                  <ng-template #makeDefaultLangButton>
                    <span
                      class="child dib ml2 f7 blue underline-hover pointer"
                      *ngIf="language.get('IsSelected').value"
                      (click)="updateDefaultLanguage(language.get('Language').value.value)"
                    >
                      {{
                        'components.LangSelector.makeDefaultLanguage' | translate: locale.language
                      }}
                    </span>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </section>

          <section *ngIf="!property && canSelectMenuTemplate">
            <mat-form-field appearance="fill">
              <mat-label>{{
                'PropertyForm.field.menuPageTemplate' | translate: locale.language
              }}</mat-label>
              <mat-select [formControl]="form.get('MenuPageTemplate')">
                <mat-option [value]="null" class="gray">
                  {{ 'forms.common.notSelected' | translate: locale.language }}
                </mat-option>
                <mat-option *ngFor="let template of mainMenuPageTemplates" [value]="template._id">
                  {{ template.name | selectLanguage: currentLanguage }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </section>
        </div>
      </ng-container>

      <!-- APPLE WALLET -->
      <ng-container *ngIf="appleWalletIsEnabled">
        <div class="content" [attr.data-to]="formTabs.AppleWallet">
          <div class="content-title">
            🍏 {{ 'PropertyForm.field.wallet.title' | translate: locale.language }}
          </div>
          <section formGroupName="Wallet">
            <div class="mb3" *ngIf="canEditConfig">
              <mat-checkbox formControlName="enabled" color="primary">
                <span>{{
                  'PropertyForm.field.wallet.fields.enabled' | translate: locale.language
                }}</span>
              </mat-checkbox>
            </div>

            <div formGroupName="options" *ngIf="form.get('Wallet.enabled').value">
              <div class="mb2 fw5 f6 black-70">
                {{ 'PropertyForm.field.wallet.colorsTitle' | translate: locale.language }}
              </div>
              <mat-form-field class="w-100" floatLabel="always">
                <mat-label>{{
                  'PropertyForm.field.wallet.fields.backgroundColor' | translate: locale.language
                }}</mat-label>
                <input
                  type="text"
                  placeholder="#FFFFFF"
                  matInput
                  formControlName="backgroundColor"
                  class="w-90"
                />
                <input
                  type="color"
                  class="w-10 pa0"
                  [value]="form.get('Wallet.options.backgroundColor').value"
                  (change)="updateWalletColor('backgroundColor', $event)"
                />
              </mat-form-field>
              <mat-form-field class="w-100" floatLabel="always">
                <mat-label>{{
                  'PropertyForm.field.wallet.fields.labelColor' | translate: locale.language
                }}</mat-label>
                <input
                  type="text"
                  placeholder="#000000"
                  matInput
                  formControlName="labelColor"
                  class="w-90"
                />
                <input
                  type="color"
                  class="w-10 pa0"
                  [value]="form.get('Wallet.options.labelColor').value"
                  (change)="updateWalletColor('labelColor', $event)"
                />
              </mat-form-field>
              <mat-form-field class="w-100" floatLabel="always">
                <mat-label>{{
                  'PropertyForm.field.wallet.fields.foregroundColor' | translate: locale.language
                }}</mat-label>
                <input
                  type="text"
                  placeholder="#000000"
                  matInput
                  formControlName="foregroundColor"
                  class="w-90"
                />
                <input
                  type="color"
                  class="w-10 pa0"
                  [value]="form.get('Wallet.options.foregroundColor').value"
                  (change)="updateWalletColor('foregroundColor', $event)"
                />
              </mat-form-field>

              <div class="mt3 mb2 fw5 f6 black-70">
                {{ 'PropertyForm.field.wallet.previewTitle' | translate: locale.language }}
              </div>
              <div
                [style.backgroundColor]="form.get('Wallet.options.backgroundColor').value || '#fff'"
                [style.color]="form.get('Wallet.options.foregroundColor').value || '#000'"
                class="pa3 ba b--black-10 br3"
              >
                <div class="flex items-center mb3">
                  <img
                    class="w2 br2 mr2"
                    *ngIf="form.get('Logo').value"
                    [src]="form.get('Logo').value"
                  />
                  <span>{{ form.get('Name').value }}</span>
                </div>
                <div class="flex items-center mb3">
                  <div>
                    <div
                      class="f7 fw6 ttu"
                      [style.color]="form.get('Wallet.options.labelColor').value || '#000'"
                    >
                      Guest name
                    </div>
                    <div class="f3 fw4">John Appleseed</div>
                  </div>
                  <img
                    style="object-fit: cover; width: 80px; height: 80px;"
                    class="br3 ml-auto"
                    *ngIf="form.get('Photo').value"
                    [src]="form.get('Photo').value"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </ng-container>

      <!-- ADMIN -->
      <ng-container *ngIf="canEditConfig">
        <div class="content" [attr.data-to]="formTabs.Admin">
          <div class="content-title">
            👑 {{ 'PropertyForm.tab.' + formTabs.Admin | translate: locale.language }}
          </div>

          <section>
            <mat-checkbox
              color="primary"
              formControlName="UseProxy"
              (change)="onProxyChage($event)"
            >
              {{ 'PropertyForm.field.proxy' | translate: locale.language }}
            </mat-checkbox>
          </section>

          <section>
            <div class="hot-property-form-text mb1">
              {{ 'PropertyForm.field.backofficeMap' | translate: locale.language }}
            </div>
            <mat-form-field class="mv2 db" appearance="fill">
              <mat-label>{{
                'PropertyForm.field.backofficeMapService' | translate: locale.language
              }}</mat-label>
              <mat-select [formControl]="form.get('MapService')">
                <mat-option *ngFor="let service of mapServices" [value]="service">
                  {{ getMapServiceLabel(service) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </section>

          <section>
            <div class="hot-property-form-text mb1">
              {{ 'PropertyForm.field.backofficeIntegration' | translate: locale.language }}
            </div>
            <mat-form-field class="mv2 db" appearance="fill">
              <mat-label>{{
                'PropertyForm.field.backofficeIntegrationProvider' | translate: locale.language
              }}</mat-label>
              <mat-select [formControl]="form.get('BackofficeIntegration')">
                <mat-option [value]="null">
                  {{ 'forms.common.notSelected' | translate: locale.language }}
                </mat-option>
                <mat-option
                  *ngFor="let integration of backofficeIntegrations"
                  [value]="integration"
                >
                  {{ getBackofficeIntegrationLabel(integration) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="form.get('BackofficeIntegration').value" class="flex">
              <div class="flex w-50 pr2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>{{
                    'PropertyForm.field.backofficeIntegrationUrl' | translate: locale.language
                  }}</mat-label>
                  <input
                    formControlName="BackofficeIntegrationUrl"
                    matInput
                    [placeholder]="backofficeIntegrationUrlPlaceholder"
                  />
                  <mat-error
                    *ngIf="
                      form && form.touched && form.get('BackofficeIntegrationUrl').hasError('url')
                    "
                  >
                    {{ 'forms.error.invalidUrl' | translate: locale.language }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      form &&
                      form.touched &&
                      form.get('BackofficeIntegrationUrl').hasError('required')
                    "
                  >
                    {{ 'forms.error.fieldRequired' | translate: locale.language }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex justify-end w-50 pl2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>{{
                    'PropertyForm.field.backofficeIntegrationToken' | translate: locale.language
                  }}</mat-label>
                  <input
                    formControlName="BackofficeIntegrationToken"
                    matInput
                    [maxlength]="maxLength"
                  />
                  <mat-error
                    *ngIf="
                      form &&
                      form.touched &&
                      form.get('BackofficeIntegrationToken').hasError('required')
                    "
                  >
                    {{ 'forms.error.fieldRequired' | translate: locale.language }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </section>

          <section *ngIf="property?.config?.isDevicesEnabled" formGroupName="DevicesIntegration">
            <div class="hot-property-form-text mb1">
              {{ 'PropertyForm.field.devicesIntegration' | translate: locale.language }}
            </div>
            <mat-form-field class="mv2 db" appearance="fill">
              <mat-label>{{
                'PropertyForm.field.devicesIntegrationProvider' | translate: locale.language
              }}</mat-label>
              <mat-select formControlName="provider">
                <mat-option [value]="null">
                  {{ 'forms.common.notSelected' | translate: locale.language }}
                </mat-option>
                <mat-option *ngFor="let integration of devicesIntegration" [value]="integration">
                  {{ getDevicesIntegrationLabel(integration) }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div
              class="flex"
              *ngIf="form.get('DevicesIntegration.provider').value === 'sberdevices'"
              formGroupName="config"
            >
              <div class="flex w-30 pr2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>{{
                    'PropertyForm.field.deviceIntegrationKeyId' | translate: locale.language
                  }}</mat-label>
                  <input formControlName="keyId" matInput />
                  <mat-error
                    *ngIf="
                      form &&
                      form.touched &&
                      form.get('DevicesIntegration.config.keyId').hasError('required')
                    "
                  >
                    {{ 'forms.error.fieldRequired' | translate: locale.language }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex w-70 pr2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>{{
                    'PropertyForm.field.deviceIntegrationKey' | translate: locale.language
                  }}</mat-label>
                  <input formControlName="key" matInput />
                  <mat-error
                    *ngIf="
                      form &&
                      form.touched &&
                      form.get('DevicesIntegration.config.key').hasError('required')
                    "
                  >
                    {{ 'forms.error.fieldRequired' | translate: locale.language }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </section>

          <section>
            <mat-accordion [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ 'PropertyForm.field.config.title' | translate: locale.language }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let config of propertyConfigs">
                  <ng-container *ngIf="config.type === 'checkbox'">
                    <mat-checkbox
                      class="db"
                      color="primary"
                      [formControl]="form.get('Config.' + config.key)"
                    >
                      {{
                        'PropertyForm.field.config.' + config.key + '.title'
                          | translate: locale.language
                      }}
                      <mat-icon
                        *ngIf="config.tooltip"
                        class="ml1 o-30 hot-property-config-tooltip"
                        [matTooltip]="
                          'PropertyForm.field.config.' + config.key + '.tooltip'
                            | translate: locale.language
                        "
                        >help_outline</mat-icon
                      >
                    </mat-checkbox>
                  </ng-container>
                  <ng-container *ngIf="config.type === 'select'">
                    <mat-form-field class="w-100" appearance="fill">
                      <mat-label>{{
                        'PropertyForm.field.config.' + config.key + '.title'
                          | translate: locale.language
                      }}</mat-label>
                      <mat-select [formControl]="form.get('Config.' + config.key)" required>
                        <mat-option *ngFor="let option of config.options" [value]="option">
                          {{ option }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  <ng-container
                    *ngIf="config.type === 'constant' && getConstantConfigValue(config.key)"
                  >
                    <div class="hot-property-config-constant">
                      <div class="hot-property-config-constant-title">
                        {{
                          'PropertyForm.field.config.' + config.key + '.title'
                            | translate: locale.language
                        }}
                      </div>
                      <div class="hot-property-config-constant-value">
                        {{ getConstantConfigValue(config.key) }}
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </section>

          <section>
            <mat-accordion [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Правила возврата бронирования
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="hot-warn-text">
                  При обновлении текста правил возврата обязательно следует поменять схему правил
                  JSON!
                </div>
                <mat-form-field class="db" appearance="fill">
                  <mat-label>Правила возврата</mat-label>
                  <textarea
                    matInput
                    formControlName="BookingRefundPolicy"
                    hotTranslatableField
                    cdkTextareaAutosize
                    [cdkAutosizeMinRows]="2"
                    [language]="language"
                    [defaultLanguage]="defaultLanguage"
                    [placeholder]="'ПРАВИЛА ВОЗВРАТА В ЗАВИСИМОСТИ ОТ ДАТЫ ***'"
                  ></textarea>
                </mat-form-field>
                <mat-form-field class="db" appearance="fill">
                  <mat-label>Схема правил возврата (JSON)</mat-label>
                  <textarea
                    style="font-family: monospace;"
                    matInput
                    formControlName="BookingRefundPolicySchema"
                    cdkTextareaAutosize
                    [cdkAutosizeMinRows]="2"
                    [placeholder]="'ПРАВИЛА ВОЗВРАТА В ФОРМАТЕ JSON'"
                  ></textarea>
                  <mat-error
                    *ngIf="form.touched && form.get('BookingRefundPolicySchema').hasError('json')"
                  >
                    JSON PARSE ERROR: {{ form.get('BookingRefundPolicySchema').errors.message }}
                  </mat-error>
                </mat-form-field>
              </mat-expansion-panel>
            </mat-accordion>
          </section>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<hot-app-footer>
  <button
    class="mr3"
    mat-raised-button
    color="primary"
    [class.o-50]="!enableSubmit"
    (click)="onSubmit()"
  >
    {{ 'forms.action.save' | translate: locale.language }}
  </button>
  <button
    *ngIf="enableTranslate"
    class="mr3"
    mat-raised-button
    color="primary"
    [class.o-50]="!enableSubmit"
    (click)="onSubmit(true)"
  >
    {{ 'forms.action.saveAndTranslate' | translate: locale.language }}
  </button>
  <button mat-button color="primary" (click)="onCancel()">
    {{ 'forms.action.cancel' | translate: locale.language }}
  </button>
</hot-app-footer>
