import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from '@shared/services'

import { Statistics, NormalizedResults } from '@models'
import { HttpResponse } from '@angular/common/http'

@Injectable()
export class DashboardService {
  constructor(private apiService: ApiService) {}

  public loadStatistics(propertyId: string, params?: any): Observable<Statistics> {
    return this.apiService.get(`properties/${propertyId}/stats`, {
      params: params,
    })
  }

  public loadExcelFile(propertyId: string, params?: any): Observable<HttpResponse<Blob>> {
    return this.apiService.get(`properties/${propertyId}/stats`, {
      params: {format: 'xlsx', ...params},
      observe: 'response',
      responseType: 'blob',
    })
  }

  public loadFormResponses(propertyId: string, params?: any): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/formResponses`, {
      params: {
        status: params.statuses || [],
      },
    })
  }
}
