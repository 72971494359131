import { Action } from '@ngrx/store'
import {
  NormalizedResult,
  Property,
  PropertyGoalType,
  Integration,
  IntegrationProvider,
} from '@models'

export enum PropertyActionTypes {
  SelectProperty = '[Property] Select property',
  Create = '[Property] Create',
  CreateSuccess = '[Property] Create Success',
  CreateFail = '[Property] Create Fail',
  Load = '[Property] Load',
  LoadSuccess = '[Property] Load Success',
  LoadFail = '[Property] Load Fail',
  LoadItem = '[Property] Load item',
  LoadItemSuccess = '[Property] Load item Success',
  LoadItemFail = '[Property] Load item Fail',
  Update = '[Property] Update',
  UpdateSuccess = '[Property] Update Success',
  UpdateFail = '[Property] Update Fail',
  Delete = '[Property] Delete',
  DeleteSuccess = '[Property] Delete Success',
  DeleteFail = '[Property] Delete Fail',
  UpdateBooking = '[Property] Update Booking',
  UpdateBookingSuccess = '[Property] Update Booking Success',
  UpdateBookingFail = '[Property] Update Booking Fail',
  UpdateTravelLine = '[Property] Update TravelLine',
  UpdateTravelLineSuccess = '[Property] Update TravelLine Success',
  UpdateTravelLineFail = '[Property] Update TravelLine Fail',
  UpdateAYS = '[Property] Update AYS',
  UpdateAYSSuccess = '[Property] Update AYS Success',
  UpdateAYSFail = '[Property] Update AYS Fail',

  LoadIntegrations = '[Property] Load Integrations',
  LoadIntegrationsSuccess = '[Property] Load Integrations Success',
  LoadIntegrationsFail = '[Property] Load Integrations Fail',

  UpdateIntegrations = '[Property] Update Integrations',
  UpdateIntegrationsSuccess = '[Property] Update Integrations Success',
  UpdateIntegrationsFail = '[Property] Update Integrations Fail',

  ValidateIntegration = '[Property] Validate Integration',
  ValidateIntegrationSuccess = '[Property] Validate Integration Success',
  ValidateIntegrationFail = '[Property] Validate Integration Fail',

  CompleteGoal = '[Property] CompleteGoal',
  CompleteGoalSuccess = '[Property] CompleteGoal Success',
  CompleteGoalFail = '[Property] CompleteGoal Fail',

  SkipGoal = '[Property] SkipGoal',
  SkipGoalSuccess = '[Property] SkipGoal Success',
  SkipGoalFail = '[Property] SkipGoal Fail',

  SearchCity = '[Property] SearchCity',
  SearchCitySuccess = '[Property] SearchCitySuccess',
}

export class SelectProperty implements Action {
  readonly type = PropertyActionTypes.SelectProperty
  constructor(public payload: { propertyId: string }) {}
}

/**
 * Create properties actions
 */
export interface CreatePayload {
  property: Property
  sourceLanguage?: string
  targetLanguages?: string[]
  forceTranslate?: boolean
  menuPageTemplate?: string | null
}

export class Create implements Action {
  readonly type = PropertyActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = PropertyActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = PropertyActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}
/**
 * Load properties actions
 */
export class Load implements Action {
  readonly type = PropertyActionTypes.Load
}

export class LoadSuccess implements Action {
  readonly type = PropertyActionTypes.LoadSuccess
  constructor(public payload: { results: string[] }) {}
}

export class LoadFail implements Action {
  readonly type = PropertyActionTypes.LoadFail
  constructor(public error: any) {}
}

/**
 * Load property actions
 */

export interface LoadItemPayload {
  propertyId: string
}

export class LoadItem implements Action {
  readonly type = PropertyActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}
export class LoadItemSuccess implements Action {
  readonly type = PropertyActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}
export class LoadItemFail implements Action {
  readonly type = PropertyActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Update property actions
 */
export interface UpdatePayload {
  propertyId: string
  property: Property
  sourceLanguage: string
  targetLanguages: string[]
  forceTranslate: boolean
}

export class Update implements Action {
  readonly type = PropertyActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}
export class UpdateSuccess implements Action {
  readonly type = PropertyActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}
export class UpdateFail implements Action {
  readonly type = PropertyActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete property actions
 */

export class Delete implements Action {
  readonly type = PropertyActionTypes.Delete
  constructor(public payload: string) {}
}
export class DeleteSuccess implements Action {
  readonly type = PropertyActionTypes.DeleteSuccess
  constructor(public payload: any, public propertyId: string) {}
}
export class DeleteFail implements Action {
  readonly type = PropertyActionTypes.DeleteFail
  constructor(public error: any) {}
}

/**
 * Update property from Booking actions
 */

export interface UpdateBookingPayload {
  propertyId: string
}
export class UpdateBooking implements Action {
  readonly type = PropertyActionTypes.UpdateBooking
  constructor(public payload: UpdateBookingPayload) {}
}
export class UpdateBookingSuccess implements Action {
  readonly type = PropertyActionTypes.UpdateBookingSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateBookingPayload) {}
}
export class UpdateBookingFail implements Action {
  readonly type = PropertyActionTypes.UpdateBookingFail
  constructor(public error: any, public meta: UpdateBookingPayload) {}
}

/**
 * Update property from TravelLine actions
 */

export interface UpdateTravelLinePayload {
  propertyId: string
}
export class UpdateTravelLine implements Action {
  readonly type = PropertyActionTypes.UpdateTravelLine
  constructor(public payload: UpdateTravelLinePayload) {}
}
export class UpdateTravelLineSuccess implements Action {
  readonly type = PropertyActionTypes.UpdateTravelLineSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateTravelLinePayload) {}
}
export class UpdateTravelLineFail implements Action {
  readonly type = PropertyActionTypes.UpdateTravelLineFail
  constructor(public error: any, public meta: UpdateTravelLinePayload) {}
}

/**
 * Update property from AYS actions
 */

export interface UpdateAYSPayload {
  propertyId: string
}
export class UpdateAYS implements Action {
  readonly type = PropertyActionTypes.UpdateAYS
  constructor(public payload: UpdateAYSPayload) {}
}
export class UpdateAYSSuccess implements Action {
  readonly type = PropertyActionTypes.UpdateAYSSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateAYSPayload) {}
}
export class UpdateAYSFail implements Action {
  readonly type = PropertyActionTypes.UpdateAYSFail
  constructor(public error: any, public meta: UpdateAYSPayload) {}
}

/**
 * Load integrations actions
 */
export interface LoadIntegrationsPayload {
  propertyId: string
}
export class LoadIntegrations implements Action {
  readonly type = PropertyActionTypes.LoadIntegrations
  constructor(public payload: LoadIntegrationsPayload) {}
}
export class LoadIntegrationsSuccess implements Action {
  readonly type = PropertyActionTypes.LoadIntegrationsSuccess
  constructor(
    public payload: { integrations: Integration[]; availableIntegrations: IntegrationProvider[] },
    public meta: LoadIntegrationsPayload
  ) {}
}
export class LoadIntegrationsFail implements Action {
  readonly type = PropertyActionTypes.LoadIntegrationsFail
  constructor(public error: any, public meta: LoadIntegrationsPayload) {}
}

/**
 * Update integrations actions
 */
export interface UpdateIntegrationsPayload {
  propertyId: string
  integrations: Integration[]
}
export class UpdateIntegrations implements Action {
  readonly type = PropertyActionTypes.UpdateIntegrations
  constructor(public payload: UpdateIntegrationsPayload) {}
}
export class UpdateIntegrationsSuccess implements Action {
  readonly type = PropertyActionTypes.UpdateIntegrationsSuccess
  constructor(
    public payload: { integrations: Integration[]; availableIntegrations: IntegrationProvider[] },
    public meta: UpdateIntegrationsPayload
  ) {}
}
export class UpdateIntegrationsFail implements Action {
  readonly type = PropertyActionTypes.UpdateIntegrationsFail
  constructor(public error: any, public meta: UpdateIntegrationsPayload) {}
}

/**
 * Update integrations actions
 */
export interface ValidateIntegrationPayload {
  propertyId: string
  integration: Integration
}
export class ValidateIntegration implements Action {
  readonly type = PropertyActionTypes.ValidateIntegration
  constructor(public payload: ValidateIntegrationPayload) {}
}
export class ValidateIntegrationSuccess implements Action {
  readonly type = PropertyActionTypes.ValidateIntegrationSuccess
  constructor(
    public payload: { message: any; error?: any; errorMessage?: string },
    public meta: ValidateIntegrationPayload
  ) {}
}
export class ValidateIntegrationFail implements Action {
  readonly type = PropertyActionTypes.ValidateIntegrationFail
  constructor(public error: any, public meta: ValidateIntegrationPayload) {}
}

/**
 * Update property goal
 */
export interface UpdateGoalPayload {
  propertyId: string
  goalTypes: PropertyGoalType[]
}

export class CompleteGoal implements Action {
  readonly type = PropertyActionTypes.CompleteGoal
  constructor(public payload: UpdateGoalPayload) {}
}
export class CompleteGoalSuccess implements Action {
  readonly type = PropertyActionTypes.CompleteGoalSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateGoalPayload) {}
}
export class CompleteGoalFail implements Action {
  readonly type = PropertyActionTypes.CompleteGoalFail
  constructor(public error: any, public meta: UpdateGoalPayload) {}
}
export class SkipGoal implements Action {
  readonly type = PropertyActionTypes.SkipGoal
  constructor(public payload: UpdateGoalPayload) {}
}
export class SkipGoalSuccess implements Action {
  readonly type = PropertyActionTypes.SkipGoalSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateGoalPayload) {}
}
export class SkipGoalFail implements Action {
  readonly type = PropertyActionTypes.SkipGoalFail
  constructor(public error: any, public meta: UpdateGoalPayload) {}
}

/**
 * Change city search query
 */
export interface SearchCityPayload {
  query: string
  countryCode?: string
}
export class SearchCity implements Action {
  readonly type = PropertyActionTypes.SearchCity
  constructor(public payload: SearchCityPayload) {}
}
export class SearchCitySuccess implements Action {
  readonly type = PropertyActionTypes.SearchCitySuccess
  constructor(public payload: any, public meta: SearchCityPayload) {}
}

export type PropertyActions =
  | SelectProperty
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | UpdateBooking
  | UpdateBookingSuccess
  | UpdateBookingFail
  | UpdateTravelLine
  | UpdateTravelLineSuccess
  | UpdateTravelLineFail
  | UpdateAYS
  | UpdateAYSSuccess
  | UpdateAYSFail
  | LoadIntegrations
  | LoadIntegrationsSuccess
  | LoadIntegrationsFail
  | UpdateIntegrations
  | UpdateIntegrationsSuccess
  | UpdateIntegrationsFail
  | CompleteGoal
  | CompleteGoalSuccess
  | CompleteGoalFail
  | SkipGoal
  | SkipGoalSuccess
  | SkipGoalFail
  | SearchCity
  | SearchCitySuccess
