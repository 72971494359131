import { StaffUser } from './staff-user.model'
import { User } from './user.model'
import { FormResponseStatus } from './form-response.model'
import { ReviewStatus } from './review.model'

export const TimelineEventStatus = { ...FormResponseStatus, ...ReviewStatus }

export enum TimelineEventType {
  Created = 'created',
  Opened = 'opened',
  Closed = 'closed',
  InProgress = 'inprogress',
  Updated = 'updated',
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  Canceled = 'canceled',
  Commented = 'commented',
}
export enum TimelineType {
  FormResponse = 'formResponse',
  Review = 'review',
  Cart = 'cart',
}

export interface TimelineEventPhoto {
  url: string
  thumb: string
  size: number
  contentType: string
}

export interface TimelineEvent {
  event: TimelineEventType
  user?: string
  app?: string
  appName?: string
  staffUser?: string
  oldValue?: string
  newValue?: string
  body?: string
  photos?: TimelineEventPhoto[]
  createdAt?: string
  eventMeta: {
    email?: boolean
    sms?: boolean
    chat?: boolean
    // @deprecated
    phone?: boolean
  }
  __staffUser: StaffUser
  __user: User
}
