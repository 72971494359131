import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'hot-timeline-event-photo-dialog',
  template: `
    <mat-dialog-content *ngIf="data && data.url">
      <img [hotProxySrc]="data.url" />
    </mat-dialog-content>
  `,
  styles: [],
})
export class TimelineEventPhotoDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}
}
