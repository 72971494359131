import * as R from 'ramda'
import { Pipe, PipeTransform } from '@angular/core'
import { LanguageType } from '@shared/constants'

// TODO: lang will be here but once it will be moved to special service
const CURRENT_LANGUAGE = LanguageType.RU

export const selectLanguage = (
  value: any,
  lang: LanguageType | string | 'default' = CURRENT_LANGUAGE,
  defaultLang: LanguageType | string = null
): any => {
  if (Array.isArray(value)) {
    return (
      R.prop(
        'value',
        value.find(R.propEq('lang', lang)) || value.find(R.propEq('lang', defaultLang || 'en'))
      ) || null
    )
  }
  return value ? value[lang] || value.default || value.en : null
}

@Pipe({
  name: 'selectLanguage',
})
export class SelectLanguagePipe implements PipeTransform {
  transform = selectLanguage
}
