import { Pipe, PipeTransform } from '@angular/core'
import { PushStatus } from '@models'
import { LanguageType } from '@shared/constants'

const translations = {
  [LanguageType.RU]: {
    [PushStatus.SENT]: 'Отправлена',
    [PushStatus.PENDING]: 'Ожидает отправки...',
  },
  [LanguageType.EN]: {
    [PushStatus.SENT]: 'Sent',
    [PushStatus.PENDING]: 'Scheduled...',
  },
  [LanguageType.ES]: {
    [PushStatus.SENT]: 'Enviadas',
    [PushStatus.PENDING]: 'Programado...',
  },
}

const translationsEmoji = {
  [PushStatus.SENT]: '✅',
  [PushStatus.PENDING]: '🕑',
}

@Pipe({
  name: 'pushMessageStatus',
})
export class PushMessageStatusPipe implements PipeTransform {
  transform(value: any, lang = 'ru', format = 'full'): any {
    const emoji = translationsEmoji[value] || null
    if (!emoji) {
      // Status is unsupported for some reason. Quit early
      return null
    }

    if (format === 'emoji') {
      return emoji
    }

    const translation = translations[lang][value]
    if (format === 'text') {
      return translation
    }

    return emoji + ' ' + translation
  }
}
