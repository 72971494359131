import { Component, Output, EventEmitter, ContentChildren, QueryList, Input } from '@angular/core'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { DndBlockDirective } from '@shared/directives/dnd-block.directive'

@Component({
  selector: 'hot-dnd-list',
  templateUrl: './dnd-list.component.html',
  styleUrls: ['./dnd-list.component.scss'],
})
export class DndListComponent {
  @ContentChildren(DndBlockDirective) blocks: QueryList<DndBlockDirective>
  @Output() public change = new EventEmitter()
  @Input() public extraClasses: {
    listClass?: string
    blockClass?: string
  } = {}

  constructor() {}

  drop(event: CdkDragDrop<string[]>) {
    this.change.emit({
      prevIndex: event.previousIndex,
      nextIndex: event.currentIndex,
    })
  }

  public get listClass() {
    return this.extraClasses.listClass || ''
  }

  public get blockClass() {
    return { drag: true, [this.extraClasses.blockClass]: Boolean(this.extraClasses.blockClass) }
  }
}
