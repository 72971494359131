<ng-container *ngIf="field">
  <ng-container [ngSwitch]="field.type">
    <div class="chat-message" *ngSwitchCase="'action'">
      <div [ngSwitch]="field.config.nextQuestion" class="pa2 f6 black-50 flex items-center">
        <mat-icon class="f5 h1 w1 mr2">work_outline</mat-icon>
        <span *ngSwitchCase="'~finish~'">
          {{ 'FormQuestion.action.label.finish' | translate: locale.language }}
        </span>
        <span *ngSwitchCase="'~finish_silently~'">
          {{ 'FormQuestion.action.label.finishSilently' | translate: locale.language }}
        </span>
        <span *ngSwitchCase="'~cancel~'">
          {{ 'FormQuestion.action.label.cancel' | translate: locale.language }}
        </span>
        <span *ngSwitchDefault>
          {{ 'FormQuestion.action.label.gotoSomewhere' | translate: locale.language }}
        </span>
      </div>
    </div>

    <div class="chat-message chat-message--preview" *ngSwitchCase="'message'">
      <div
        class="chat-message__text"
        *ngIf="getTranslation(field.label) as fieldLabel; else noLabelText"
      >
        <span>{{ fieldLabel }}</span>
      </div>
      <ng-template #noLabelText>
        <div class="chat-message__text red">
          <span class="i">{{
            'FormQuestion.error.enterMessageText' | translate: locale.language
          }}</span>
        </div>
      </ng-template>
    </div>

    <div *ngSwitchCase="'options'" class="chat-message chat-message--preview">
      <div
        class="chat-message__text"
        *ngIf="getTranslation(field.label) as fieldLabel; else noLabelText"
      >
        <span>{{ fieldLabel }}</span>
      </div>
      <ng-template #noLabelText>
        <div class="chat-message__text red">
          <span class="i">{{
            'FormQuestion.error.enterQuestion' | translate: locale.language
          }}</span>
        </div>
      </ng-template>

      <div class="chat-message__buttons">
        <div class="chat-message__button" *ngFor="let button of field.config.options">
          <span *ngIf="getTranslation(button.label) as buttonLabel; else noButtonText">{{
            buttonLabel
          }}</span>
          <ng-template #noButtonText>
            <span class="i red">{{
              'FormQuestion.error.enterText' | translate: locale.language
            }}</span>
          </ng-template>
        </div>
        <div class="chat-message__button" *ngIf="field.config?.acceptUserText">
          {{ otherButtonLabel }}
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'date'" class="chat-message chat-message--preview">
      <div
        class="chat-message__text"
        *ngIf="getTranslation(field.label) as fieldLabel; else noLabelText"
      >
        <span>{{ fieldLabel }}</span>
        <div class="chat-message__buttons">
          <div class="chat-message__button" *ngFor="let buttonLabel of getDateButtons()">
            <span>{{ buttonLabel }}</span>
          </div>
        </div>
      </div>
      <ng-template #noLabelText>
        <div class="chat-message__text red">
          <span class="i">{{
            'FormQuestion.error.enterQuestion' | translate: locale.language
          }}</span>
        </div>
      </ng-template>
    </div>

    <div class="flex flex-column items-start" *ngSwitchCase="'comment'">
      <div class="chat-message chat-message--preview">
        <div
          class="chat-message__text"
          *ngIf="getTranslation(field.label) as fieldLabel; else noLabelText"
        >
          <span>{{ fieldLabel }}</span>
          <div class="chat-message__buttons">
            <div class="chat-message__button" *ngFor="let buttonLabel of commentButtons">
              <span>{{ buttonLabel | selectLanguage: language }}</span>
            </div>
          </div>
        </div>
        <ng-template #noLabelText>
          <div class="chat-message__text red">
            <span class="i">{{
              'FormQuestion.error.enterQuestion' | translate: locale.language
            }}</span>
          </div>
        </ng-template>
      </div>
      <div class="chat-message chat-message--preview mt2">
        <div class="chat-message__text">
          <span>{{ getConfigLabel('labelEnterText') }}</span>
        </div>
      </div>
    </div>

    <div *ngSwitchDefault class="chat-message chat-message--preview">
      <div
        class="chat-message__text"
        *ngIf="getTranslation(field.label) as fieldLabel; else noLabelText"
      >
        <span>{{ fieldLabel }}</span>
      </div>
      <ng-template #noLabelText>
        <div class="chat-message__text red">
          <span class="i">{{
            'FormQuestion.error.enterQuestion' | translate: locale.language
          }}</span>
        </div>
      </ng-template>
      <!-- <div class="chat-message__text">{{ field.label | selectLanguage: language }}</div> -->
      <!-- <div class="black-50">😰 not supported yet: {{ field.type }}</div> -->
    </div>
  </ng-container>
</ng-container>
