import * as R from 'ramda'

import { Action } from '@ngrx/store'

import {
  Builder,
  CaptivePortal,
  Chat,
  Cuisine,
  Form,
  FormResponse,
  Hotspot,
  MenuPagesTemplates,
  Property,
  Push,
  Reservation,
  Restaurant,
  Review,
  StaffUser,
  RoomService,
  ProductCategory,
  Product,
  Cart,
  ShopCart,
  User,
  WifiVoucher,
  LegalEntity,
  SalesServiceSettings,
  Merchant,
  Shop,
  ShopProduct,
  ShopProductCategory,
} from '@models'
import { FAQCategory } from '@models/faq.model'

import { NormalizedEntities } from '@models/api-normalized-responses'
import { LoginSuccess, UserActions, UserActionTypes } from '../user'

export interface State {
  captivePortals: R.Dictionary<CaptivePortal>
  hotspots: R.Dictionary<Hotspot>
  chats: R.Dictionary<Chat>
  formResponses: R.Dictionary<FormResponse>
  menuPages: R.Dictionary<Builder>
  properties: R.Dictionary<Property>
  staffUsers: R.Dictionary<StaffUser>
  users: R.Dictionary<User>
  pushes: R.Dictionary<Push>
  forms: R.Dictionary<Form>
  formTemplates: R.Dictionary<Form>
  reviews: R.Dictionary<Review>
  reservations: R.Dictionary<Reservation>
  restaurants: R.Dictionary<Restaurant>
  shops: R.Dictionary<Shop>
  shopProducts: R.Dictionary<ShopProduct>
  shopProductCategories: R.Dictionary<ShopProductCategory>
  roomServices: R.Dictionary<RoomService>
  productCategories: R.Dictionary<ProductCategory>
  products: R.Dictionary<Product>
  carts: R.Dictionary<Cart>
  shopCarts: R.Dictionary<ShopCart>
  menuPagesTemplates: R.Dictionary<MenuPagesTemplates>
  FAQCategories: R.Dictionary<FAQCategory>
  cuisines: R.Dictionary<Cuisine>
  wifiVouchers: R.Dictionary<WifiVoucher>
  legalEntities: R.Dictionary<LegalEntity>
  salesServiceSettings: R.Dictionary<SalesServiceSettings>
  merchants: R.Dictionary<Merchant>
}

export const initialState: State = {
  captivePortals: {},
  hotspots: {},
  chats: {},
  formResponses: {},
  menuPages: {},
  properties: {},
  staffUsers: {},
  users: {},
  pushes: {},
  forms: {},
  formTemplates: {},
  reviews: {},
  reservations: {},
  restaurants: {},
  shops: {},
  shopProducts: {},
  shopProductCategories: {},
  roomServices: {},
  productCategories: {},
  products: {},
  carts: {},
  shopCarts: {},
  menuPagesTemplates: {},

  FAQCategories: {},

  cuisines: {},
  wifiVouchers: {},
  legalEntities: {},
  salesServiceSettings: {},
  merchants: {},
}

interface HttpActionPayload extends NormalizedEntities {
  result?: string
  results?: string[]
}

interface HttpAction extends Action {
  payload: HttpActionPayload
}

// MenuPages users pageId instead of _id
const getEntityId = x => x.pageId || x._id

const mergeEntity = (a, b) => {
  // HACK for chats
  if (a.propertyParameters || b.propertyParameters) {
    return {
      ...R.merge(a, b),
      propertyParameters: R.merge(a.propertyParameters, b.propertyParameters),
    }
  }
  // HACK for shopCarts
  if (a.__feesCost || b.__feesCost) {
    return R.mergeDeepRight(a, b)
  }
  return R.merge(a, b)
}

// Creates new object only if new data exists
const safeMerge = (state, newState) => {
  if (Array.isArray(newState)) {
    return R.mergeWith(mergeEntity, state, R.indexBy(getEntityId, newState))
  }
  return newState ? R.mergeWith(mergeEntity, state, newState) : state
}

export function mergeEntities(state = initialState, payload: HttpActionPayload): State {
  if (
    payload &&
    (payload.properties ||
      payload.captivePortals ||
      payload.hotspots ||
      payload.users ||
      payload.chats ||
      payload.menuPages ||
      payload.formResponses ||
      payload.staffUsers ||
      payload.forms ||
      payload.formTemplates ||
      payload.reviews ||
      payload.reservations ||
      payload.restaurants ||
      payload.shops ||
      payload.shopProducts ||
      payload.shopProductCategories ||
      payload.roomServices ||
      payload.productCategories ||
      payload.products ||
      payload.carts ||
      payload.shopCarts ||
      payload.pushes ||
      payload.menuPagesTemplates ||
      payload.cuisines ||
      payload.wifiVouchers ||
      payload.FAQCategories ||
      payload.legalEntities ||
      payload.salesServiceSettings ||
      payload.merchants)
  ) {
    return {
      captivePortals: safeMerge(state.captivePortals, payload.captivePortals),
      hotspots: safeMerge(state.hotspots, payload.hotspots),
      chats: safeMerge(state.chats, payload.chats),
      formResponses: safeMerge(state.formResponses, payload.formResponses),
      menuPages: safeMerge(state.menuPages, payload.menuPages),
      properties: safeMerge(state.properties, payload.properties),
      staffUsers: safeMerge(state.staffUsers, payload.staffUsers),
      users: safeMerge(state.users, payload.users),
      pushes: safeMerge(state.pushes, payload.pushes),
      forms: safeMerge(state.forms, payload.forms),
      formTemplates: safeMerge(state.formTemplates, payload.formTemplates),
      reviews: safeMerge(state.reviews, payload.reviews),
      reservations: safeMerge(state.reservations, payload.reservations),
      restaurants: safeMerge(state.restaurants, payload.restaurants),
      shops: safeMerge(state.shops, payload.shops),
      shopProducts: safeMerge(state.shopProducts, payload.shopProducts),
      shopProductCategories: safeMerge(state.shopProductCategories, payload.shopProductCategories),
      roomServices: safeMerge(state.roomServices, payload.roomServices),
      productCategories: safeMerge(state.productCategories, payload.productCategories),
      products: safeMerge(state.products, payload.products),
      carts: safeMerge(state.carts, payload.carts),
      shopCarts: safeMerge(state.shopCarts, payload.shopCarts),
      menuPagesTemplates: safeMerge(state.menuPagesTemplates, payload.menuPagesTemplates),

      FAQCategories: safeMerge(state.FAQCategories, payload.FAQCategories),

      cuisines: safeMerge(state.cuisines, payload.cuisines),
      wifiVouchers: safeMerge(state.wifiVouchers, payload.wifiVouchers),
      legalEntities: safeMerge(state.legalEntities, payload.legalEntities),
      salesServiceSettings: safeMerge(state.salesServiceSettings, payload.salesServiceSettings),
      merchants: safeMerge(state.merchants, payload.merchants),
    }
  }
  return state
}

export function reducer(state = initialState, action: HttpAction | UserActions): State {
  switch (action.type) {
    case UserActionTypes.LoginSuccess: {
      const user = (action as LoginSuccess).payload.user
      return mergeEntities(state, {
        users: { [user._id]: user },
      })
    }

    default:
      return mergeEntities(state, (action as any).payload)
  }
}

export const selectCaptivePortals = (state: State) => state.captivePortals
export const selectHotspots = (state: State) => state.hotspots
export const selectChats = (state: State) => state.chats
export const selectFormResponses = (state: State) => state.formResponses
export const selectForms = (state: State) => state.forms
export const selectMenus = (state: State) => state.menuPages
export const selectProperties = (state: State) => state.properties
export const selectPushes = (state: State) => state.pushes
export const selectStaffUsers = (state: State) => state.staffUsers
export const selectUsers = (state: State) => state.users
export const selectReservations = (state: State) => state.reservations
export const selectReviews = (state: State) => state.reviews
export const selectRestaurants = (state: State) => state.restaurants
export const selectShops = (state: State) => state.shops
export const selectShopProducts = (state: State) => state.shopProducts
export const selectShopProductCategories = (state: State) => state.shopProductCategories
export const selectRoomServices = (state: State) => state.roomServices
export const selectProductCategories = (state: State) => state.productCategories
export const selectProducts = (state: State) => state.products
export const selectCarts = (state: State) => state.carts
export const selectShopCarts = (state: State) => state.shopCarts
export const selectMenuPagesTemplates = (state: State) => state.menuPagesTemplates
export const selectFAQCategoriesEntities = (state: State) => state.FAQCategories
export const selectFormTemplates = (state: State) => state.formTemplates
export const selectCuisines = (state: State) => state.cuisines
export const selectLegalEntities = (state: State) => state.legalEntities
export const selectSalesServiceSettings = (state: State) => state.salesServiceSettings
export const selectMerchants = (state: State) => state.merchants
