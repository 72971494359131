import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  ViewChild,
  AfterViewInit,
  QueryList,
} from '@angular/core'
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop'
import { DndBlockDirective } from '@shared/directives/dnd-block.directive'

@Component({
  selector: 'hot-dnd-area',
  templateUrl: './dnd-area.component.html',
  styleUrls: ['./dnd-area.component.scss'],
})
export class DndAreaComponent implements AfterViewInit {
  @ContentChildren(DndBlockDirective) blocks: QueryList<DndBlockDirective>
  @ViewChild(CdkDropList, { static: true }) placeholder: CdkDropList
  @Output() public change = new EventEmitter()
  @Input() public extraClasses: {
    areaClass?: string
    blockClass?: string
  } = {}

  public target: CdkDropList
  public targetIndex: number
  public source: any
  public sourceIndex: number

  constructor() {
    this.target = null
    this.source = null
  }

  ngAfterViewInit() {
    const phElement = this.placeholder.element.nativeElement
    phElement.style.display = 'none'
    phElement.parentNode.removeChild(phElement)
  }

  drop() {
    if (!this.target) {
      return
    }

    const phElement = this.placeholder.element.nativeElement
    const parent = phElement.parentNode

    phElement.style.display = 'none'

    parent.removeChild(phElement)
    parent.appendChild(phElement)
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex])

    this.target = null
    this.source = null

    if (this.sourceIndex !== this.targetIndex) {
      this.change.emit({
        prevIndex: this.sourceIndex,
        nextIndex: this.targetIndex,
      })
    }
  }

  enter = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop === this.placeholder) {
      return true
    }

    const phElement = this.placeholder.element.nativeElement
    const dropElement = drop.element.nativeElement

    let dragIndex = indexOfNode(
      dropElement.parentNode.children,
      drag.dropContainer.element.nativeElement
    )
    const dropIndex = indexOfNode(dropElement.parentNode.children, dropElement)

    if (!this.source) {
      this.sourceIndex = dragIndex
      this.source = drag.dropContainer
      const sourceElement = this.source.element.nativeElement
      phElement.style.width = sourceElement.clientWidth + 'px'
      phElement.style.height = sourceElement.clientHeight + 'px'
      sourceElement.parentNode.removeChild(sourceElement)
    } else {
      // 🤷‍♂️
      if (dragIndex === -1) {
        dragIndex = this.sourceIndex
      }
    }

    this.targetIndex = dropIndex
    this.target = drop

    phElement.style.display = ''
    dropElement.parentNode.insertBefore(
      phElement,
      dragIndex < dropIndex ? dropElement.nextSibling : dropElement
    )

    // this.source.start()
    this.placeholder.enter(
      drag,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop
    )

    return false
  }

  public get areaClass() {
    return this.extraClasses.areaClass || ''
  }

  public get blockClass() {
    return { drag: true, [this.extraClasses.blockClass]: Boolean(this.extraClasses.blockClass) }
  }
}

function indexOfNode(collection, node) {
  return Array.prototype.indexOf.call(collection, node)
}
