import { FormResponseStatus, Statistics } from '@models'

import { DashboardActions, DashboardActionTypes } from './dashboard.actions'
import * as moment from 'moment'

export interface StatsSubstate {
  data?: Statistics
  days?: number // days for stats collecting
  daterange: {
    begin: moment.Moment
    end: moment.Moment
  }
  segmentsNumber?: number // number of top segments to display
  loading: boolean
  loaded: boolean
}

export interface FormResponsesSubstate {
  ids: string[]
  loaded: boolean
  loading: boolean
  statuses: FormResponseStatus[]
}
export interface Substate {
  statsSubstate?: StatsSubstate
  formResponsesSubstate?: FormResponsesSubstate
}

export interface State {
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialStatsSubstate: StatsSubstate = {
  data: null,
  days: 0,
  daterange: {
    begin: null,
    end: null,
  },
  loaded: false,
  loading: false,
}

export const initialFormResponsesSubstate: FormResponsesSubstate = {
  ids: [],
  loaded: false,
  loading: false,
  statuses: [],
}

export const initialSubstate: Substate = {
  statsSubstate: initialStatsSubstate,
  formResponsesSubstate: initialFormResponsesSubstate,
}

export const initialState: State = {
  byPropertyId: {},
}

export function formResponsesSubstateReducer(
  state = initialFormResponsesSubstate,
  action: DashboardActions
): FormResponsesSubstate {
  switch (action.type) {
    case DashboardActionTypes.LoadFormResponses: {
      return {
        ...state,
        statuses: [...action.payload.statuses],
        loaded: false,
        loading: true,
      }
    }
    case DashboardActionTypes.LoadFormResponsesSuccess: {
      return {
        ...state,
        ids: action.payload.results,
        loaded: true,
        loading: false,
      }
    }
    case DashboardActionTypes.LoadFormResponsesFail: {
      return {
        //   ...state,
        ids: [],
        statuses: [],
        loaded: false,
        loading: false,
      }
    }
  }
}

export function statsSubstateReducer(
  state = initialStatsSubstate,
  action: DashboardActions
): StatsSubstate {
  switch (action.type) {
    case DashboardActionTypes.LoadStatistics: {
      const begin = moment(action.payload.begin)
      const end = moment(action.payload.end)
      return {
        ...state,
        daterange: {
          begin,
          end,
        },
        days: Math.abs(end.diff(begin, 'days')) + 1,
        segmentsNumber: action.payload.segmentsNumber,
        loaded: false,
        loading: true,
      }
    }
    case DashboardActionTypes.LoadStatisticsSuccess: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false,
      }
    }
    case DashboardActionTypes.LoadStatisticsFail: {
      return {
        daterange: null,
        data: null,
        loaded: false,
        loading: false,
        segmentsNumber: 0,
      }
    }
    default:
      return state
  }
}

export function substateReducer(state = initialSubstate, action: DashboardActions): Substate {
  switch (action.type) {
    case DashboardActionTypes.LoadStatistics:
    case DashboardActionTypes.LoadStatisticsSuccess:
    case DashboardActionTypes.LoadStatisticsFail: {
      return {
        ...state,
        statsSubstate: statsSubstateReducer(state.statsSubstate, action),
      }
    }
    case DashboardActionTypes.LoadFormResponses:
    case DashboardActionTypes.LoadFormResponsesSuccess:
    case DashboardActionTypes.LoadFormResponsesFail: {
      return {
        ...state,
        formResponsesSubstate: formResponsesSubstateReducer(state.formResponsesSubstate, action),
      }
    }
    default:
      return state
  }
}

export function reducer(state = initialState, action: DashboardActions): State {
  switch (action.type) {
    case DashboardActionTypes.LoadStatistics:
    case DashboardActionTypes.LoadStatisticsSuccess:
    case DashboardActionTypes.LoadStatisticsFail:
    case DashboardActionTypes.LoadFormResponses:
    case DashboardActionTypes.LoadFormResponsesSuccess:
    case DashboardActionTypes.LoadFormResponsesFail: {
      const query =
        action.type === DashboardActionTypes.LoadStatistics ||
        action.type === DashboardActionTypes.LoadFormResponses
          ? action.payload
          : action.meta
      return {
        byPropertyId: {
          ...state.byPropertyId,
          [query.propertyId]: substateReducer(state.byPropertyId[query.propertyId], action),
        },
      }
    }
    default:
      return state
  }
}

export const selectSubstates = (state: State) => state.byPropertyId
export const selectSubstateStats = (state: Substate) =>
  state && state.statsSubstate && state.statsSubstate.data
export const selectSubstateStatsDays = (state: Substate) =>
  state && state.statsSubstate && state.statsSubstate.days
export const selectSubstateStatRange = (state: Substate) =>
  state && state.statsSubstate && state.statsSubstate.daterange
export const selectSubstateStatsSegmentsNumber = (state: Substate) =>
  state && state.statsSubstate && state.statsSubstate.segmentsNumber
export const selectSubstateStatsLoading = (state: Substate) =>
  state && state.statsSubstate && state.statsSubstate.loading
export const selectSubstateStatsLoaded = (state: Substate) =>
  state && state.statsSubstate && state.statsSubstate.loaded
export const selectSubstateFormResponsesIds = (state: Substate) =>
  state && state.formResponsesSubstate && state.formResponsesSubstate.ids
export const selectSubstateFormResponsesLoaded = (state: Substate) =>
  state && state.formResponsesSubstate && state.formResponsesSubstate.loaded
export const selectSubstateFormResponsesLoading = (state: Substate) =>
  state && state.formResponsesSubstate && state.formResponsesSubstate.loading
