import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { NormalizedResult } from '@models/api-normalized-responses'
import { LegalEntity } from '@models/legal-entity.model'

@Injectable()
export class LegalEntityService {
  constructor(public readonly apiService: ApiService) {}

  public create(legalEntity: LegalEntity): Observable<NormalizedResult> {
    return this.apiService.post(`legal_entity/`, legalEntity)
  }

  public get(): Observable<NormalizedResult> {
    return this.apiService.get(`legal_entity`)
  }

  public getItem(legalEntityId: string): Observable<NormalizedResult> {
    return this.apiService.get(`legal_entity/${legalEntityId}`)
  }

  public update(
    legalEntityId: string,
    legalEntity: LegalEntity
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`legal_entity/${legalEntityId}`, legalEntity)
  }

  public delete(legalEntityId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`legal_entity/${legalEntityId}`)
  }
}
