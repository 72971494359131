import { Component, EventEmitter, Output, OnInit, Inject, Input } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { FormControl, FormBuilder, FormGroup } from '@angular/forms'

@Component({
  selector: 'hot-search-input',
  templateUrl: './search-input.component.html',
  styles: [
    `
      .hot-search {
        position: relative;
        min-width: 180px;
      }
      .hot-search-input {
        width: 100%;
        border: 0;
        padding: 6px 28px 6px 10px;
        border-radius: 4px;
        outline: none;
      }
      .hot-search-input-icon {
        position: absolute;
        top: 2px;
        right: 4px;
        color: #999;
        transform: scale(0.9);
        pointer-events: none;
      }
      .hot-search-input-icon-reset {
        pointer-events: auto;
        cursor: pointer;
        transition: color 0.2s ease;
      }
      .hot-search-input-icon-reset:hover {
        color: #1976d2;
      }
    `,
  ],
})
export class SearchInputComponent implements OnInit {
  @Input() public background?: string
  @Output() public queryChange = new EventEmitter<any>()
  public form: FormGroup

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      Query: new FormControl(''),
    })

    this.form.get('Query').valueChanges.subscribe((value: string) => {
      this.queryChange.emit(value.trim().toLowerCase())
    })
  }

  public reset() {
    this.form.get('Query').patchValue('')
  }
}
