import { ApiService } from '@shared/services'
import { Injectable } from '@angular/core'
import { NormalizedResults } from '@models'
import { Observable } from 'rxjs'

export interface ReservationsQueryParams {
  limit?: number
  page?: number
}

@Injectable()
export class ReservationService {
  constructor(public readonly apiService: ApiService) {}

  public getList(
    propertyId: string,
    params: ReservationsQueryParams = {}
  ): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/reservations`, { params })
  }
}
