import * as R from 'ramda'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
} from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@redux'

import {
  Form,
  FormField,
  FormType,
  User,
  sortUserByName,
  FormFieldType,
  BackofficeIntegraion,
} from '@models'
import { LanguageType } from '../../constants'
import { UidService } from '../../services/index'
import { Subscription } from 'rxjs'
import { decimalValidator, translatableTextValidator } from '@shared/validators'
import { selectLanguage } from '@shared/pipes/select-language.pipe'
import { map, distinctUntilChanged, startWith, filter } from 'rxjs/operators'

@Component({
  selector: 'hot-form-editor',
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.scss'],
})
export class FormEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public users: User[] = []
  @Input() public defaultLanguage = 'en'
  @Input() public language: LanguageType | 'en' = 'en'
  @Input() public form: Form = null
  @Input() public showNotification = true
  @Output() public changed = new EventEmitter<Form>()
  @Output() public statusChanged = new EventEmitter<boolean>()
  public formForm: FormGroup

  public usersById: R.Dictionary<User> = {}
  private _subscriptions: Subscription[] = []

  public backofficeIntegration: BackofficeIntegraion = null

  public formTypes = [FormType.Request, FormType.Review, FormType.Link]

  public notificationSettings: any[] = []
  public notificationTypes = [
    { type: 'email', icon: 'email', tooltipKey: 'email' },
    // { type: 'sms', icon: 'phone_iphone', tooltip: 'sms' },
    { type: 'chat', icon: 'chat', tooltipKey: 'messenger' },
  ]
  public onNotificationChange({ notifications, kpiNotifications }) {
    if (notifications) {
      this.formForm.patchValue({
        notifications: notifications.map(x => ({ user: x.id, types: x.types })),
      })
    }
    if (kpiNotifications) {
      this.formForm.patchValue({
        kpiNotifications: kpiNotifications.map(x => ({ user: x.id, types: x.types, time: x.time })),
      })
    }
  }

  public getUser(userId) {
    return this.usersById[userId]
  }

  public hasPhone(user: User) {
    return user.phone && user.phone !== '-'
  }

  public get formType() {
    return this.formForm && this.formForm.get('formType').value
  }

  public get showKpiSettings() {
    const formTypeControl = this.formType
    return (
      this.form &&
      (formTypeControl
        ? formTypeControl === FormType.Request
        : this.form.formType === FormType.Request)
    )
  }
  public get notificationsSettings(): FormArray {
    return this.formForm.get('notifications') as FormArray
  }

  public get kpiNotificationsSettings(): FormArray {
    return this.formForm.get('kpiNotifications') as FormArray
  }

  public get formFields(): FormArray {
    return this.formForm.get('fields') as FormArray
  }

  public get isLinkForm(): boolean {
    return this.formType === FormType.Link
  }

  public get showIdInputs(): boolean {
    return this.isLinkForm
  }

  public hasTranslatableValue = (value: any) => {
    return Boolean(selectLanguage(value, this.language))
  }

  public validateTranslatableField = (control: FormControl) => {
    if (!this.hasTranslatableValue(control.value)) {
      return { required: true }
    }
    return null
  }

  public validateField = (control: FormControl) => {
    const { type, config } = control.value
    if (type === FormFieldType.Options) {
      const options =
        config &&
        config.options &&
        config.options.filter(option => this.hasTranslatableValue(option.label))
      const hasOptions = options && options.length >= 1
      if (!hasOptions) {
        return { emptyOptions: true }
      }
    }
    return null
  }

  public validateFields = (control: FormControl) => {
    const fieldControl = control.get('fields') as FormArray

    if (!(fieldControl && fieldControl.controls.length)) {
      return { required: true }
    }

    return null
  }

  addField(idx = this.formFields.length) {
    const field = this.createField()
    this.formFields.insert(idx, field)
    this.scrollToElement(field.value.id)
  }

  getNextId() {
    return this.uid.nanoId(this.formFields.value)
  }

  createForm() {
    return this.fb.group(
      {
        title: this.fb.control([], translatableTextValidator),
        cancelConfirmation: this.fb.control([]),
        requiresRoomNumber: this.fb.control(false),
        requiresEmailNotification: this.fb.control(false),
        requiresSmsNotification: this.fb.control(false),
        instantCancellation: this.fb.control(false),
        ignoreBackofficeIntegration: this.fb.control(false),
        formType: this.fb.control(FormType.Request),
        formLink: this.fb.group({
          url: this.fb.control(''),
          label: this.fb.control([]),
          description: this.fb.control([]),
        }),
        fields: this.fb.array([]),
        notifications: this.fb.array([]),
        kpiNotifications: this.fb.array([]),
        kpiTime: this.fb.control('', [decimalValidator]),
      },
      { validator: [this.validateFields] }
    )
  }

  createNotification(data: any = {}) {
    return this.fb.group({
      user: this.fb.control(data.user || ''),
      types: this.fb.control(data.types || []),
      time: this.fb.control(data.time || null),
    })
  }

  createField(data: any = {}) {
    const field = this.fb.group(
      {
        id: data.id || this.getNextId(),
        type: this.fb.control(data.type || FormFieldType.Text),
        title: this.fb.control(data.title || []),
        label: this.fb.control(data.label || []),
        config: this.fb.control(data.config || {}),
      },
      { validator: [this.validateField] }
    )
    // NOTE: Subscrptions will be deleted and recreated on changes
    this._subscriptions.push(
      field.valueChanges
        .pipe(
          map(x => x.type),
          distinctUntilChanged(),
          startWith(data.type || FormFieldType.Text)
        )
        .subscribe(val => {
          if (val === FormFieldType.Action) {
            field.get('title').setValidators(null)
            field.get('label').setValidators(null)
          } else if (val === FormFieldType.Message) {
            field.get('title').setValidators(null)
            field.get('label').setValidators(translatableTextValidator)
          } else {
            field.get('title').setValidators(translatableTextValidator)
            field.get('label').setValidators(translatableTextValidator)
          }
          field.get('title').updateValueAndValidity()
          field.get('label').updateValueAndValidity()
        })
    )
    return field
  }

  updateField(idx: number, field: FormField) {
    this.formFields.controls[idx].setValue(field)
  }

  deleteField(idx) {
    this.formFields.removeAt(idx)
  }

  moveField(idx: number, position) {
    const control = this.formFields.controls[idx]
    const nextIndex = idx + position
    this.formFields.removeAt(idx)
    this.formFields.insert(nextIndex, control)
    this.scrollToElement(control.value.id)
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private fb: FormBuilder,
    private uid: UidService,
    private store$: Store<fromRoot.State>
  ) {
    this.formForm = this.createForm()
  }

  unsubscribe() {
    this._subscriptions.forEach(s => s.unsubscribe())
    this._subscriptions = []
  }

  subscribeToChanges() {
    this._subscriptions.push(
      this.formForm.valueChanges.subscribe(this.changed),
      this.formForm.statusChanges.subscribe(this.statusChanged)
    )
  }

  ngOnInit() {
    this.subscribeToChanges()
    this.store$
      .pipe(
        select(fromRoot.selectCurrentProperty),
        distinctUntilChanged(),
        filter(x => Boolean(x && x.backofficeIntegration && x.backofficeIntegration.provider)),
        map(x => x.backofficeIntegration.provider)
      )
      .subscribe((provider: BackofficeIntegraion) => {
        this.backofficeIntegration = provider
      })
  }

  ngOnDestroy() {
    this.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.users) {
      this.usersById = R.indexBy(R.prop('_id'), this.users)
    }

    if (changes.form) {
      const form: Form = this.form
      const users = this.users
      const fields = form.fields || []
      const notifications = form.notifications || []
      const kpiNotifications = form.kpiNotifications || []
      const notificationsByUserId: R.Dictionary<any> = R.indexBy(R.prop('user'), notifications)
      const kpiNotificationsByUserId: R.Dictionary<any> = R.indexBy(
        R.prop('user'),
        kpiNotifications
      )

      // Since we can't silence FormArray in angular
      // we need to unsubscribe and re-subscribe to change-events
      this.unsubscribe()

      this.formForm.patchValue(
        {
          title: form.title || [],
          cancelConfirmation: form.cancelConfirmation || [],
          formType: form.formType || FormType.Request,
          requiresRoomNumber: form.requiresRoomNumber || false,
          requiresEmailNotification: form.requiresEmailNotification || false,
          requiresSmsNotification: form.requiresSmsNotification || false,
          instantCancellation: form.instantCancellation || false,
          ignoreBackofficeIntegration: form.ignoreBackofficeIntegration || false,
          kpiTime: form.kpiTime || '',
          formLink: form.formLink || { url: '', description: [], label: [] },
        },
        { emitEvent: false, onlySelf: true }
      )

      this.formForm.setControl(
        'fields',
        this.fb.array(
          fields.map(x => {
            return this.createField(x)
          })
        )
      )

      this.notificationSettings = users.sort(sortUserByName).map(x => {
        const notification = notificationsByUserId[x._id]
        const kpiNotification = kpiNotificationsByUserId[x._id]
        return {
          id: x._id,
          types: (notification && notification.types) || [],
          kpiTypes: (kpiNotification && kpiNotification.types) || [],
          title: x.name,
          subtitle: x.roleDescription,
          kpiTime: (kpiNotification && kpiNotification.time) || null,
        }
      })
      this.formForm.setControl(
        'notifications',
        this.fb.array(
          users.sort(sortUserByName).map(x => {
            const notification = notificationsByUserId[x._id]
            return this.createNotification({
              user: x._id,
              types: (notification && notification.types) || [],
            })
          })
        )
      )

      this.formForm.setControl(
        'kpiNotifications',
        this.fb.array(
          users.map(x => {
            const notification = kpiNotificationsByUserId[x._id]
            return this.createNotification({
              user: x._id,
              types: (notification && notification.types) || [],
              time: (notification && notification.time) || null,
            })
          })
        )
      )

      if (this.formFields.length === 0) {
        this.addField()
      }

      this.formForm.markAsPristine()
      this.formForm.markAsUntouched()
      this.subscribeToChanges()
    }
  }

  private scrollToElement(id: string) {
    // Scroll to moved element
    setTimeout(() => {
      const hbl = document.querySelector('.hot-builder-languages') as HTMLElement
      if (hbl) {
        const fixedHeaderHeight = hbl.offsetTop + hbl.offsetHeight + 24
        const el = document.querySelector(`[data-form-field="${id}"]`) as HTMLElement
        const offsetTop = el ? el.offsetTop - fixedHeaderHeight : 0
        window.scrollTo(0, offsetTop)
      }
    }, 10)
  }
}
