import { Action } from '@ngrx/store'
import { NormalizedResults, NormalizedResult, Form } from '@models'

export enum ActionTypes {
  Select = '[Form] Select',
  Load = '[Form] Load',
  LoadSuccess = '[Form] Load Success',
  LoadFail = '[Form] Load Fail',
  LoadItem = '[Form] Load Item',
  LoadItemSuccess = '[Form] Load Item Success',
  LoadItemFail = '[Form] Load Item Fail',
  Create = '[Form] Create',
  CreateSuccess = '[Form] Create Success',
  CreateFail = '[Form] Create Fail',
  Update = '[Form] Update',
  UpdateSuccess = '[Form] Update Success',
  UpdateFail = '[Form] Update Fail',
  ForceDelete = '[Form] Force Delete',
  Delete = '[Form] Delete',
  DeleteSuccess = '[Form] Delete Success',
  DeleteFail = '[Form] Delete Fail',
  LoadTemplates = '[Form] Load Templates',
  LoadTemplatesSuccess = '[Form] Load Templates Success',
  LoadTemplatesFail = '[Form] Load Templates Fail',
  LoadTemplate = '[Form] Load Template',
  LoadTemplateSuccess = '[Form] Load Template Success',
  LoadTemplateFail = '[Form] Load Template Fail',
  CreateTemplate = '[Form] Create Template',
  CreateTemplateSuccess = '[Form] Create Template Success',
  CreateTemplateFail = '[Form] Create Template Fail',
  ForceDeleteTemplate = '[Form] Force Delete Template',
  DeleteTemplate = '[Form] Delete Template',
  DeleteTemplateSuccess = '[Form] Delete Template Success',
  DeleteTemplateFail = '[Form] Delete Template Fail',
  UpdateTemplate = '[Form] Update Templates',
  UpdateTemplateSuccess = '[Form] Update Templates Success',
  UpdateTemplateFail = '[Form] Update Templates Fail',
}

/**
 * Select action
 */
export class Select implements Action {
  readonly type = ActionTypes.Select
  constructor(public payload: { formId: string }) {}
}

/**
 * Load Forms actions
 */
export interface LoadPayload {
  propertyId: string
}

export class Load implements Action {
  readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load Form actions
 */
export interface LoadItemPayload {
  propertyId: string
  formId: string
}

export class LoadItem implements Action {
  readonly type = ActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}

export class LoadItemSuccess implements Action {
  readonly type = ActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}

export class LoadItemFail implements Action {
  readonly type = ActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Load Form templates actions
 */

export class LoadTemplates implements Action {
  readonly type = ActionTypes.LoadTemplates
  constructor() {}
}

export class LoadTemplatesSuccess implements Action {
  readonly type = ActionTypes.LoadTemplatesSuccess
  constructor(public payload: NormalizedResults) {}
}

export class LoadTemplatesFail implements Action {
  readonly type = ActionTypes.LoadTemplatesFail
  constructor(public error: any) {}
}

export class LoadTemplate implements Action {
  readonly type = ActionTypes.LoadTemplate
  constructor(public payload: { formId: string }) {}
}

export class LoadTemplateSuccess implements Action {
  readonly type = ActionTypes.LoadTemplateSuccess
  constructor(public payload: NormalizedResults) {}
}

export class LoadTemplateFail implements Action {
  readonly type = ActionTypes.LoadTemplateFail
  constructor(public error: any) {}
}

/**
 * Create Form actions
 */
export interface CreatePayload {
  propertyId: string
  form: Partial<Form>
}

export class Create implements Action {
  readonly type = ActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = ActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = ActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

export interface CreateTemplatePayload {
  form: Partial<Form>
}

export class CreateTemplate implements Action {
  readonly type = ActionTypes.CreateTemplate
  constructor(public payload: CreateTemplatePayload) {}
}

export class CreateTemplateSuccess implements Action {
  readonly type = ActionTypes.CreateTemplateSuccess
  constructor(public payload: NormalizedResult, public meta: CreateTemplatePayload) {}
}

export class CreateTemplateFail implements Action {
  readonly type = ActionTypes.CreateTemplateFail
  constructor(public error: any, public meta: CreateTemplatePayload) {}
}

/**
 * Update Form actions
 */
export interface UpdatePayload {
  propertyId?: string
  formId: string
  form: Partial<Form>
  forceTranslate?: boolean
  sourceLanguage?: string
}

export class Update implements Action {
  readonly type = ActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = ActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = ActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

export class UpdateTemplate implements Action {
  readonly type = ActionTypes.UpdateTemplate
  constructor(public payload: UpdatePayload) {}
}

export class UpdateTemplateSuccess implements Action {
  readonly type = ActionTypes.UpdateTemplateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateTemplateFail implements Action {
  readonly type = ActionTypes.UpdateTemplateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete actions
 */
export interface DeletePayload {
  propertyId?: string
  formId: string
}
export interface DeleteMeta {
  formIdToAssign: string
  needAssign: boolean
}

export class ForceDelete implements Action {
  public readonly type = ActionTypes.ForceDelete
  constructor(public payload: DeletePayload, public meta: DeleteMeta) {}
}
export class Delete implements Action {
  public readonly type = ActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}
export class DeleteSuccess implements Action {
  public readonly type = ActionTypes.DeleteSuccess
  constructor(public payload: DeletePayload) {}
}
export class DeleteFail implements Action {
  public readonly type = ActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export class ForceDeleteTemplate implements Action {
  public readonly type = ActionTypes.ForceDeleteTemplate
  constructor(public payload: DeletePayload, public meta: DeleteMeta) {}
}
export class DeleteTemplate implements Action {
  public readonly type = ActionTypes.DeleteTemplate
  constructor(public payload: { formId: string }) {}
}
export class DeleteTemplateSuccess implements Action {
  public readonly type = ActionTypes.DeleteTemplateSuccess
  constructor(public payload: any) {}
}
export class DeleteTemplateFail implements Action {
  public readonly type = ActionTypes.DeleteTemplateFail
  constructor(public error: any, public meta: any) {}
}

export type Actions =
  | Select
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | ForceDelete
  | Delete
  | DeleteSuccess
  | DeleteFail
  | LoadTemplate
  | LoadTemplateSuccess
  | LoadTemplateFail
  | CreateTemplate
  | CreateTemplateSuccess
  | CreateTemplateFail
  | ForceDeleteTemplate
  | DeleteTemplate
  | DeleteTemplateSuccess
  | DeleteTemplateFail
  | LoadTemplates
  | LoadTemplatesSuccess
  | LoadTemplatesFail
