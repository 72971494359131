import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { ApiService } from './api.service'
import { saveAs } from 'file-saver'
import * as moment from 'moment'
import { switchMap, tap } from 'rxjs/operators'

@Injectable()
export class ReportsService {
  public constructor(private apiService: ApiService) {}
  public generateReportForProperty(
    reportType: string,
    reportFormat: string,
    lang: string,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    options: any
  ): Observable<any> {
    return of(options.property).pipe(
      tap(a => console.log(a)),
      switchMap(property =>
        this.apiService.get(`properties/${property}/reports/${reportType}`, {
          params: {
            format: reportFormat,
            lang: lang,
            begin: startDate ? startDate.format('YYYY-MM-DD') : '',
            end: endDate ? endDate.format('YYYY-MM-DD') : '',
            options: JSON.stringify(options),
          },
          observe: 'response',
          responseType: 'blob',
        })
      )
    )
  }
  public saveToFileWrapper(
    reportType: string,
    reportFormat: string,
    lang: string,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    options: any
  ): Observable<{ filename: string }> {
    const fileName = `${
      options.filename ? options.filename.replace(/\s+/g, '_') : 'hotbot_report'
    }_${reportType}${
      startDate
        ? '_' + startDate.format('YYYY-MM-DD') + (endDate ? '-' + endDate.format('YYYY-MM-DD') : '')
        : ''
    }.${reportFormat.toLowerCase()}`
    return this.generateReportForProperty(
      reportType,
      reportFormat,
      lang,
      startDate,
      endDate,
      options
    ).pipe(
      switchMap(response => {
        saveAs(response.body, fileName)
        return of({ filename: fileName })
      })
    )
  }
}
