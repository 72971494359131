import { Chat } from './chat.model'
import { Form } from './form.model'
import { TimelineEvent } from './timeline-event.model'
import { PaginationOptions } from './pagination-options'
import { TranslatableString } from '@shared/directives/translatable-text.directive'

export enum FormResponseStatus {
  // New = 'new',
  Open = 'open',
  InProgress = 'inprogress',
  Closed = 'closed',
  CanceledByGuest = 'canceled_guest',
  CanceledByHotel = 'canceled_hotel',
}

export interface FormResponseFilters {
  status?: FormResponseStatus
  paginationOptions?: PaginationOptions
}

export interface FormResponseTitle {
  default: string
  en: string
}

export interface FormResponse {
  _id: string
  createdAt: string
  closedAt: string
  responseNumber: number
  status: FormResponseStatus
  chat: string
  formCode: string
  events: TimelineEvent[]
  requestTitle: TranslatableString
  title: FormResponseTitle
  pageTitle: FormResponseTitle
  answers: {
    [key: string]: string
  }

  // Custom fields to populate
  __chat?: Chat
  __events?: TimelineEvent
  __form?: Form
}
