<hot-lang-selector
  [stickTo]="'hot-page-heading .wrap'"
  [defaultLanguage]="defaultLanguage"
  [languages]="languages"
  (languageChange)="changeLanguage($event)"
></hot-lang-selector>

<hot-page-content class="mt4-l">
  <ng-content></ng-content>

  <main class="flex">
    <div *ngIf="isShowTree && pageId && rootId" class="menu-pages notranslate">
      <hot-builder-tree
        (deletePage)="delete.next($event)"
        [showOrphans]="authService.isAdmin$ | async"
        [currentLanguage]="currentLanguage"
        [builders]="builders"
        [rootId]="rootId"
        [pageId]="pageId"
      ></hot-builder-tree>
    </div>

    <div class="menu-page-content">
      <mat-spinner *ngIf="pageIsLoading; else menuBuilderCard" [diameter]="32"></mat-spinner>
      <ng-template #menuBuilderCard>
        <hot-builder-facilities-groups
          *ngIf="facilitiesGroups?.length && (authService.isAdmin$ | async)"
          [value]="builder.facilitiesGroups"
          [facilitiesGroups]="facilitiesGroups"
          (update)="updateFacilitiesGroups($event)"
        ></hot-builder-facilities-groups>

        <mat-card>
          <hot-builder-tracking
            class="mb2"
            *ngIf="(authService.isAdmin$ | async)"
            [trackings]="builder.tracking"
            [isEditMode]="isEditMode"
            (update)="updateTrackingCode($event)"
            (editModeChange)="handleEditModeChange($event)"
          >
          </hot-builder-tracking>

          <hot-builder-name
            class="notranslate"
            [name]="builder.name"
            [currentLanguage]="currentLanguage"
            [enableManuallyTranslate]="enableManuallyTranslate"
            [isEditMode]="isEditMode"
            (update)="updateBuilder($event)"
            (editModeChange)="handleEditModeChange($event)"
          >
          </hot-builder-name>

          <hot-builder-images
            [images]="images"
            [availableLanguages]="languages"
            [currentLanguage]="currentLanguage"
            [isEditMode]="isEditMode"
            (update)="updateBuilder()"
            (remove)="handleRemoveAction($event)"
            (editModeChange)="handleEditModeChange($event)"
          >
          </hot-builder-images>

          <hot-builder-texts
            [texts]="texts"
            [availableLanguages]="languages"
            [currentLanguage]="currentLanguage"
            [enableManuallyTranslate]="enableManuallyTranslate"
            [isEditMode]="isEditMode"
            (remove)="handleRemoveAction($event)"
            (update)="updateBuilder()"
            (editModeChange)="handleEditModeChange($event)"
          >
          </hot-builder-texts>

          <hot-builder-buttons
            *ngIf="isHasTexts"
            [canSetVisibilityPeriod]="true"
            [availableLanguages]="languages"
            [property]="property"
            [currentLanguage]="currentLanguage"
            [buttons]="buttons"
            [builders]="builders"
            [forms]="forms"
            [shopCategories]="shopCategories"
            [shopProducts]="shopProducts"
            [polls]="polls"
            [templates]="templates"
            [modules]="modules"
            [enableManuallyTranslate]="enableManuallyTranslate"
            [enableWifiButtons]="property && property.config && property.config.wifiEnabled"
            [isEditMode]="isEditMode"
            (remove)="handleRemoveAction($event)"
            (shift)="handleShiftAction($event)"
            (update)="updateBuilder()"
            (navigate)="navigate.emit($event)"
            (createNewPage)="createNewPage.emit($event)"
          >
          </hot-builder-buttons>
        </mat-card>
      </ng-template>
    </div>
  </main>

  <footer></footer>
</hot-page-content>
