import { createTopToBottomGradient, createLeftToRightGradient } from './create-gradient'
import { Size } from './types'
import { ChartType } from './chart-types'
import { ThemeKind } from '@shared/directives/theme.directive'

export interface GetColorsFnParams {
  theme: ThemeKind
  chartType: ChartType
  canvasSize: Size
  colorsCount: number
  userColors: any[]
}

export type GetColorsFn = (params: GetColorsFnParams) => any

const adjustLightnessForShadesOfViolet = hue => {
  return (Math.max(0, (hue % 360) - 180) / (240 - 180)) * 15
}

const fadeOutHexColor = (
  color: any,
  canvasSize: Size,
  direction: 'bottom' | 'right' | 'left' | 'top' = 'bottom',
  fromAlpha = 'ff',
  toAlpha = '30'
) => {
  if (typeof color === 'string' && color[0] === '#') {
    switch (direction) {
      case 'bottom':
        return createTopToBottomGradient(color + fromAlpha, color + toAlpha, canvasSize)
      case 'top':
        return createTopToBottomGradient(color + toAlpha, color + fromAlpha, canvasSize)
      case 'right':
        return createLeftToRightGradient(color + fromAlpha, color + toAlpha, canvasSize)
      case 'left':
        return createLeftToRightGradient(color + toAlpha, color + fromAlpha, canvasSize)
    }
  }
  return color
}

export const getColorsForLineChart: GetColorsFn = ({ canvasSize, userColors = [] }) => {
  const startHue = 210

  const colors = userColors.concat([]).map(c => fadeOutHexColor(c, canvasSize, 'bottom'))
  const hoverColors = userColors.concat([])

  // TODO: Support multiple lines on chart
  const hue = startHue
  const color1 = `hsla(${hue}, 100%, 50%, 1)`
  const color2 = `hsla(${hue - 10}, 100%, 50%, 0)`
  colors.push(createTopToBottomGradient(color1, color2, canvasSize))
  hoverColors.push(color1)
  const borderColor = createTopToBottomGradient(
    `hsla(210, 100%, 55%, 1)`,
    `hsla(200, 100%, 50%, 1)`,
    canvasSize
  )

  return colors.map((color, idx) => {
    const hoverColor = hoverColors[idx]
    return {
      backgroundColor: color,
      hoverBackgroundColor: hoverColor,
      borderColor: userColors[idx] || borderColor,

      pointBorderWidth: 3,
      pointRadius: 6,
      pointHoverRadius: 6,
      pointHitRadius: 10,
      pointStyle: 'circle',

      pointBackgroundColor: 'rgba(0,0,0,0)',
      pointBorderColor: 'rgba(0,0,0,0)',
      pointHoverBackgroundColor: userColors[idx] || '#fff',
      pointHoverBorderColor: userColors[idx] || '#357edd',
    }
  })
}

export const getColorsForBarChart: GetColorsFn = ({
  theme,
  colorsCount = 1,
  chartType = ChartType.Bar,
  userColors = [],
  canvasSize,
}) => {
  const centerHue = theme === ThemeKind.Dark ? 200 : 240
  const desiredStep = 10
  const maxHueRange = theme === ThemeKind.Dark ? 100 : 50
  const hueRange = Math.min(colorsCount * desiredStep, maxHueRange)
  const startHue = centerHue - hueRange / 2
  const colorStep = hueRange / colorsCount

  const fadeOutDirection = chartType === ChartType.HorizontalBar ? 'left' : 'bottom'
  const colors = userColors.concat([]).map(c => fadeOutHexColor(c, canvasSize, fadeOutDirection))
  const hoverColors = userColors.concat([])
  for (let i = 0; i < colorsCount; i++) {
    const hue = (startHue + Math.round(i * colorStep)) % 360
    const lightness = 50 + adjustLightnessForShadesOfViolet(hue)
    const color1 = `hsla(${hue}, 100%, ${lightness}%, 1)`
    const color2 = `hsla(${hue}, 100%, ${lightness}%, 0)`
    const gradient =
      chartType === ChartType.HorizontalBar
        ? createLeftToRightGradient(color2, color1, canvasSize)
        : createTopToBottomGradient(color1, color2, canvasSize)
    colors.push(gradient)
    hoverColors.push(`hsla(${hue}, 100%, ${lightness}%, 1)`)
  }

  const borderColor = 'rgba(0,0,0,0)'

  return [
    {
      borderColor,
      backgroundColor: colors,
      hoverBackgroundColor: hoverColors,

      pointBorderWidth: 3,
      pointRadius: 6,
      pointHoverRadius: 6,
      pointHitRadius: 10,
      pointStyle: 'circle',

      pointBackgroundColor: 'rgba(0,0,0,0)',
      pointBorderColor: 'rgba(0,0,0,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#357edd',
    },
  ]
}

export const getColorsForPieChart: GetColorsFn = ({
  theme,
  canvasSize,
  colorsCount = 1,
  userColors = [],
}) => {
  const centerHue = 190
  const hueRange = -40
  const startHue = centerHue - hueRange / 2
  const colorStep = hueRange / colorsCount

  const colors = userColors.concat([]).map(c => fadeOutHexColor(c, canvasSize, 'bottom'))
  const hoverColors = userColors.concat([])
  for (let i = 0; i < colorsCount; i++) {
    const hue = (startHue + Math.round(i * colorStep)) % 360
    const lightness = 50 + adjustLightnessForShadesOfViolet(hue)
    hoverColors.push(`hsla(${hue}, 100%, ${lightness}%, 1)`)
    colors.push(
      createTopToBottomGradient(
        `hsla(${hue}, 100%, ${lightness}%, 1)`,
        `hsla(${hue}, 100%, ${lightness}%, 0.5)`,
        canvasSize
      )
    )
  }

  const borderColor = theme === ThemeKind.Dark ? `hsla(218,14%,15%,0.5)` : `hsla(200,100%,100%,1)`

  return [
    {
      borderColor,
      borderWidth: 1,
      backgroundColor: colors,
      hoverBackgroundColor: hoverColors,
    },
  ]
}

/*
class Color {
  constructor(private hue: number, private saturation: number, private lightness: number, private alpha = 1) {}

  addHue(amount: number) {
    return new Color(this.hue + amount, this.saturation, this.lightness, this.alpha)
  }

  toString() {
    return this.valueOf()
  }
  valueOf() {
    return `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, ${this.alpha})`
  }
}

const color1 = new Color(200, 100, 50)
const color2 = color1.addHue(10)

const text = `color: (${color1}) (${color2})` // ?
*/
