import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from '@shared/services'

import { NormalizedResult, NormalizedResults, Form } from '@models'
import { DeleteMeta, DeletePayload } from '@redux/form/form.actions'

@Injectable()
export class FormService {
  public getList(propertyId: string): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/forms`)
  }

  public getParentPages(propertyId: string, formId: string): Observable<{ pages: []; faqs: [] }> {
    return this.apiService.get(`properties/${propertyId}/forms/${formId}/parentPages`)
  }

  public get(propertyId: string, formId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/forms/${formId}`)
  }

  public create(propertyId: string, formData: Partial<Form>): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/forms/`, formData)
  }

  public update(
    propertyId: string,
    formId: string,
    formData: Partial<Form>,
    sourceLanguage: string,
    forceTranslate: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/forms/${formId}`, {
      data: formData,
      forceTranslate,
      sourceLanguage,
    })
  }

  public delete(
    { propertyId, formId }: DeletePayload,
    meta: DeleteMeta
  ): Observable<NormalizedResult> {
    return this.apiService.delete(`properties/${propertyId}/forms/${formId}`, { params: meta })
  }

  public createTemplate(formData: Partial<Form>): Observable<NormalizedResult> {
    return this.apiService.post(`templates/forms/`, formData)
  }

  public getUsedInForms(formId: string): Observable<{ pages: [] }> {
    return this.apiService.get(`templates/forms/${formId}/usedInForms`)
  }

  public getTemplatesList(): Observable<NormalizedResults> {
    return this.apiService.get(`templates/forms`)
  }

  public getTemplate(formId: string): Observable<NormalizedResults> {
    return this.apiService.get(`templates/forms/${formId}`)
  }

  public updateTemplate(
    formId: string,
    formData: Partial<Form>,
    forceTranslate: boolean,
    sourceLanguage: string
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`templates/forms/${formId}`, {
      data: formData,
      forceTranslate,
      sourceLanguage,
    })
  }

  public deleteTemplate({ formId }: DeletePayload, meta: DeleteMeta): Observable<NormalizedResult> {
    return this.apiService.delete(`templates/forms/${formId}`, { params: meta })
  }

  // public patch(propertyId: string, data: Partial<Form>): Observable<Form> {
  //   return this.apiService.patch(`properties/${propertyId}/faq`, data)
  // }

  constructor(private apiService: ApiService) {}
}
