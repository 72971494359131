import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from '@shared/services'
import { filter } from 'ramda'
import {
  NormalizedResults,
  NormalizedResult,
  PropertyGoalType,
  Integration,
  IntegrationProvider,
} from '@models'
import { ChatType } from '@shared/constants'
import * as fromProperty from '@redux/property'

@Injectable()
export class PropertyService {
  constructor(public readonly apiService: ApiService) {}

  public getList(): Observable<NormalizedResults> {
    return this.apiService.get(`properties`)
  }

  public get(propertyId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}`)
  }

  public create(data: fromProperty.CreatePayload): Observable<NormalizedResult> {
    return this.apiService.post(`properties/`, data)
  }

  public update(data: fromProperty.UpdatePayload): Observable<NormalizedResult> {
    return this.apiService.put(`properties/${data.propertyId}`, data)
  }

  public delete(propertyId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`properties/${propertyId}`)
  }

  public getChannels(
    propertyId: string,
    channelType?: ChatType | null
  ): Observable<NormalizedResults & { channelsIds: string[] }> {
    return this.apiService.get(`properties/${propertyId}/channels`, {
      params: {
        type: channelType || '',
      },
    })
  }

  public createChannel(propertyId: string, channelConfig: any): Observable<any> {
    return this.apiService.post(`properties/${propertyId}/channels/`, channelConfig)
  }

  public removeChannel(propertyId: string, channelId: string): Observable<any> {
    return this.apiService.delete(`properties/${propertyId}/channels/${channelId}`, {})
  }

  public updateInfoFromBooking(propertyId: string): Observable<any> {
    return this.apiService.post(`properties/${propertyId}/pull/booking`, {})
  }

  public updateInfoFromTravelLine(propertyId: string): Observable<any> {
    return this.apiService.post(`properties/${propertyId}/pull/travelline`, {})
  }

  public updateInfoFromAYS(propertyId: string): Observable<any> {
    return this.apiService.post(`properties/${propertyId}/pull/ays`, {})
  }

  public getIntegrations(
    propertyId: string
  ): Observable<{ integrations: Integration[]; availableIntegrations: IntegrationProvider[] }> {
    return this.apiService.get(`properties/${propertyId}/integrations`, {})
  }

  public updateIntegrations(
    propertyId: string,
    integrations: Integration[]
  ): Observable<{ integrations: Integration[]; availableIntegrations: IntegrationProvider[] }> {
    return this.apiService.put(`properties/${propertyId}/integrations`, { integrations })
  }

  public validateIntegration(
    propertyId: string,
    integration: Integration
  ): Observable<{ message: string; error?: any; errorMessage?: string }> {
    return this.apiService.post(
      `properties/${propertyId}/integrations/${integration.provider}/validate`,
      { config: integration.config }
    )
  }

  public completeGoals(propertyId: string, goals: PropertyGoalType[]): Observable<any> {
    return this.apiService.post(`properties/${propertyId}/goals/complete`, { goals })
  }

  public skipGoals(propertyId: string, goals: PropertyGoalType[]): Observable<any> {
    return this.apiService.post(`properties/${propertyId}/goals/skip`, { goals })
  }

  public searchCity(query: string, countryCode?: string): Observable<any> {
    return this.apiService.get(`cities`, {
      params: filter(Boolean, { query, country: countryCode }),
    })
  }
}
