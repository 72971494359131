import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import * as pushSubscriptionActions from './push-subscription.actions'
import { switchMap, catchError, map, tap } from 'rxjs/operators'
import { PushSubscriptionService } from './push-subscription.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'

const SAVE_ERROR = 'При сохранении push subscription возникла ошибка'

@Injectable()
export class PushSubscriptionEffects {
  public constructor(
    private actions$: Actions,
    private pushSubscriptionService: PushSubscriptionService,
    private matSnackBar: MatSnackBar
  ) {}

  @Effect()
  public saveSubscription$ = this.actions$.pipe(
    ofType(pushSubscriptionActions.PushSubscriptionActionType.Save),
    switchMap((action: pushSubscriptionActions.Save) => {
      return this.pushSubscriptionService
        .saveSubscription(action.payload)
        .pipe(
          map(x => new pushSubscriptionActions.SaveSuccess(x, action.payload)),
          catchError(x => of(new pushSubscriptionActions.SaveFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public requestSubscriptionFail$ = this.actions$.pipe(
    ofType(pushSubscriptionActions.PushSubscriptionActionType.SaveFail),
    tap(() => {
      this.matSnackBar.open(SAVE_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )
}
