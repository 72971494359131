import { Action } from '@ngrx/store'
import { NormalizedResult, Product } from '@models'
import { LanguageType } from '@shared/constants'

export enum ProductActionTypes {
  Create = '[Product] Create',
  CreateSuccess = '[Product] Create Success',
  CreateFail = '[Product] Create Fail',

  Load = '[Product] Load',
  LoadSuccess = '[Product] Load Success',
  LoadFail = '[Product] Load Fail',

  Update = '[Product] Update',
  UpdateSuccess = '[Product] Update Success',
  UpdateFail = '[Product] Update Fail',

  Delete = '[Product] Delete',
  DeleteSuccess = '[Product] Delete Success',
  DeleteFail = '[Product] Delete Fail',
}

/**
 * Create Product actions
 */

export interface CreatePayload {
  propertyId: string
  roomServiceId: string
  product: Product
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
}

export class Create implements Action {
  readonly type = ProductActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = ProductActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = ProductActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load Product actions
 */

export interface LoadPayload {
  propertyId?: string
  roomServiceId?: string
}

export class Load implements Action {
  readonly type = ProductActionTypes.Load
  constructor(public payload: LoadPayload = {}) {}
}

export class LoadSuccess implements Action {
  readonly type = ProductActionTypes.LoadSuccess
  constructor(public payload, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ProductActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Update Product actions
 */
export interface UpdatePayload {
  propertyId: string
  roomServiceId: string
  productId: string
  product: Product
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
  forceTranslate: boolean
}

export class Update implements Action {
  readonly type = ProductActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = ProductActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = ProductActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete Product actions
 */
export interface DeletePayload {
  propertyId: string
  roomServiceId: string
  productId: string
}

export class Delete implements Action {
  readonly type = ProductActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = ProductActionTypes.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = ProductActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type ProductActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
