import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResult, Product } from '@models'
import { LanguageType } from '@shared/constants'

@Injectable()
export class ProductService {
  constructor(public readonly apiService: ApiService) {}

  public create(
    propertyId: string,
    roomServiceId: string,
    product: Product,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[]
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/roomService/${roomServiceId}/products`, {
      product,
      sourceLanguage,
      targetLanguages,
    })
  }

  public update(
    propertyId: string,
    roomServiceId: string,
    productId: string,
    product: Product,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[],
    forceTranslate: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(
      `properties/${propertyId}/roomService/${roomServiceId}/products/${productId}`,
      {
        product,
        sourceLanguage,
        targetLanguages,
        forceTranslate,
      }
    )
  }

  public delete(
    propertyId: string,
    roomServiceId: string,
    productId: string
  ): Observable<NormalizedResult> {
    return this.apiService.delete(
      `properties/${propertyId}/roomService/${roomServiceId}/products/${productId}`
    )
  }
}
