import * as R from 'ramda'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import { restoreSettings, updateSettings } from '@shared/utils/settingsUtils'
import { I18nService, WindowRefService } from '@shared/services'
import { map } from 'rxjs/operators'

const LANG_SELECTOR_SETTINGS_KEY = 'language'

@Component({
  selector: 'hot-lang-selector',
  template: `
    <div
      #langSelector
      class="hot-builder-languages flex"
      [style.top]="offset$ | async"
      [ngClass]="{ 'is-fixed': stickTo }"
    >
      <span
        *ngFor="let lang of languages"
        class="hot-builder-languages-item"
        [matTooltip]="tooltipText"
        [matTooltipDisabled]="lang !== defaultLanguage"
        [ngClass]="{
          'hot-builder-languages-item-active': isActiveLanguage(lang),
          'hot-builder-languages-item-default': lang === defaultLanguage
        }"
        (click)="changeLanguage(lang)"
      >
        {{ lang.toUpperCase() }}
      </span>
    </div>
  `,
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent implements OnInit, OnChanges {
  @Input() public defaultLanguage: string
  @Input() public languages: string[]
  @Input() public stickTo: string
  @Output() public languageChange = new EventEmitter<string>()

  @ViewChild('langSelector', { static: true }) public langSelector: ElementRef

  public currentLanguage: string
  public langSelectorSettings

  public offset$ = this.windowService.media$.pipe(
    map(({ isSmall }) => {
      if (this.stickTo) {
        if (isSmall) {
          return '0px'
        }
        return this.fixedOffset()
      }
      return ''
    })
  )

  public constructor(
    public translationService: I18nService,
    private windowService: WindowRefService
  ) {}

  public ngOnChanges(changes) {
    const isLanguagesChange =
      changes.languages &&
      !changes.languages.firstChange &&
      !R.equals(changes.languages.currentValue, changes.languages.previousValue)

    const isDefaultLanguageChange =
      changes.defaultLanguage &&
      !changes.defaultLanguage.firstChange &&
      !changes.defaultLanguage.currentValue !== changes.defaultLanguage.previousValue

    if (isLanguagesChange || isDefaultLanguageChange) {
      this.configureLanguages()
    }
  }

  public ngOnInit() {
    this.langSelectorSettings = restoreSettings(LANG_SELECTOR_SETTINGS_KEY)
    this.configureLanguages()
    // if (this.stickTo) {
    //   this.languageSelectorPositioning()
    // }
  }

  public get tooltipText() {
    const translatePath = 'components.LangSelector.defaultLanguage'
    return this.translationService.translate(`${translatePath}`)
  }

  fixedOffset() {
    const offsetEl = document.querySelector(this.stickTo) as HTMLElement
    const offsetElHeight = offsetEl && offsetEl.offsetHeight
    return offsetElHeight ? offsetElHeight + 'px' : ''
  }

  // languageSelectorPositioning() {
  //   const pageHeader = document.querySelector(this.stickTo) as HTMLElement
  //   if (pageHeader) {
  //     setTimeout(() => {
  //       const pageHeaderHeight = pageHeader.offsetHeight
  //       this.langSelector.nativeElement.style.top = `${pageHeaderHeight}px`
  //     }, 0)
  //   }
  // }

  changeLanguage(language) {
    updateSettings('currentLanguage', language, LANG_SELECTOR_SETTINGS_KEY)
    if (language !== this.currentLanguage) {
      setTimeout(() => {
        this.currentLanguage = language
        this.languageChange.emit(language)
      }, 10)
    }
  }

  public isActiveLanguage(language) {
    return this.currentLanguage === language
  }

  private configureLanguages() {
    const savedLanguage = this.langSelectorSettings['currentLanguage']
    if (this.languages.includes(this.defaultLanguage)) {
      this.languages = [this.defaultLanguage, ...R.without([this.defaultLanguage], this.languages)]
    }
    this.languages.includes(savedLanguage)
      ? this.changeLanguage(savedLanguage)
      : this.languages.includes(this.defaultLanguage)
      ? this.changeLanguage(this.defaultLanguage)
      : this.changeLanguage(this.languages[0])
  }
}
