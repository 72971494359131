import {
  MenuPagesTemplatesActions,
  MenuPagesTemplatesActionTypes,
} from './menu-pages-templates.actions'
import * as R from 'ramda'
import { BuilderActionTypes, BuilderActions } from '@redux/builder'

// States

export interface State {
  loaded: boolean
  loading: boolean
  updated: boolean
  ids: string[]
}

export const initialState: State = {
  loaded: false,
  loading: false,
  updated: false,
  ids: [],
}

export function reducer(
  state = initialState,
  action: MenuPagesTemplatesActions | BuilderActions
): State {
  switch (action.type) {
    case MenuPagesTemplatesActionTypes.Load: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }

    case BuilderActionTypes.LoadAllSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        ids: R.pluck<string, any>('pageId', action.payload.menuPagesTemplates),
      }
    }

    case MenuPagesTemplatesActionTypes.LoadSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        ids: action.payload.results || [action.payload.result],
      }
    }

    case MenuPagesTemplatesActionTypes.CreateSuccess: {
      return {
        ...state,
        ids: [...state.ids, action.payload.result],
      }
    }

    case MenuPagesTemplatesActionTypes.LoadFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }

    case MenuPagesTemplatesActionTypes.Update: {
      return {
        ...state,
        updated: false,
      }
    }

    case MenuPagesTemplatesActionTypes.UpdateSuccess: {
      return {
        ...state,
        updated: true,
      }
    }

    case MenuPagesTemplatesActionTypes.DeleteSuccess: {
      const templateId = action.payload
      return {
        ...state,
        ids: R.without(templateId, state.ids),
      }
    }

    default:
      return state
  }
}

export const selectIds = (state: State) => state.ids
export const selectIsLoaded = (state: State) => state.loaded
