const EMPTY_TITLE = ''

export abstract class StatsItem {
  count: number
  getValue(): number {
    return this.count
  }
  abstract getLabel(language?: string): string
}

export interface UsersAmountStats {
  [key: string]: number
  total: number
}

export interface ChatsStatsEntry {
  aiResponseFailure: number
  managerConnections: number
  noFailureWithActionsAfterOnboarding: number
}

export interface ChatsStats {
  perDay: Array<ChatsStatsEntry & { date: string }>
  total: ChatsStatsEntry
}

export class SegmentStatsItem extends StatsItem {
  trackingCode: string
  title: string

  public static fromJSON(jsonObject: any): SegmentStatsItem {
    const item = new SegmentStatsItem()
    item.trackingCode = jsonObject.trackingCode
    item.title = jsonObject.title
    item.count = jsonObject.count
    return item
  }

  public getLabel(): string {
    return this.title || EMPTY_TITLE
  }
}
export class AmountPerDayStatsItem extends StatsItem {
  day: number
  count: number
  year: number
  month: number

  public static fromJSON(jsonObject: any): AmountPerDayStatsItem {
    const item = new AmountPerDayStatsItem()
    item.day = jsonObject.day
    item.month = jsonObject.month
    item.year = jsonObject.year
    item.count = jsonObject.count
    return item
  }

  public getLabel(language?: string): string {
    return new Date(this.year, this.month - 1, this.day).toLocaleDateString(language)
  }
}

class PageVisitStatsItem extends StatsItem {
  constructor(public url: string, public count: number) {
    super()
  }
  public static fromJSON(jsonObject: any): PageVisitStatsItem {
    return new PageVisitStatsItem(jsonObject.url, jsonObject.count)
  }

  public getLabel() {
    return this.url
  }
}

export class Statistics {
  // key is name, for example 'formResponses' or 'chatUsers'
  formResponsesStats: AmountPerDayStatsItem[]
  chatsStats: AmountPerDayStatsItem[]
  propertySegmentsStats: SegmentStatsItem[]
  usersAmountStats: UsersAmountStats
  chatsData: ChatsStats

  dau: Array<{ date: string; count: number }>
  wau: Array<{ date: string; week: string; count: number }>
  mau: Array<{ date: string; count: number }>
  aiResponseSuccess: {
    perDay: Array<{
      date: string
      fails: number
      managerConnections: number
      managerConnectionsRatio: number
      success: number
      successRatio: number
      total: number
    }>
    total: {
      fails: number
      managerConnections: number
      managerConnectionsRatio: number
      success: number
      successRatio: number
      total: number
    }
  }

  topPages: PageVisitStatsItem[]
  requestResponseTime: {
    last30DaysAvg: number
  }

  bookings: {
    total: {
      started: number
      finished: number
      clicks: number
    }
  }

  requests: {
    total: number
  }

  public static fromJSON(jsonObject: any): Statistics {
    const statistics = new Statistics()
    statistics.chatsStats = jsonObject.chatsStats
      ? jsonObject.chatsStats.map(AmountPerDayStatsItem.fromJSON)
      : null
    statistics.formResponsesStats = jsonObject.formResponsesStats
      ? jsonObject.formResponsesStats.map(AmountPerDayStatsItem.fromJSON)
      : null
    statistics.propertySegmentsStats = jsonObject.propertySegmentsStats
      ? jsonObject.propertySegmentsStats.map(SegmentStatsItem.fromJSON)
      : null
    statistics.usersAmountStats = jsonObject.usersAmountStats || {}
    statistics.chatsData = jsonObject.chatsData || {}
    statistics.dau = jsonObject.dau || []
    statistics.wau = jsonObject.wau || []
    statistics.mau = jsonObject.mau || []
    statistics.aiResponseSuccess = jsonObject.aiResponseSuccess || {}
    statistics.requestResponseTime = jsonObject.requestResponseTime || {}
    statistics.bookings = jsonObject.bookings || { total: { finished: 0 } }
    statistics.requests = jsonObject.requests || { total: 0 }
    statistics.topPages = jsonObject.topPages
      ? jsonObject.topPages.map(PageVisitStatsItem.fromJSON)
      : []
    // checkBookingAvialabilityFlowRatio.total.finished
    return statistics
  }
}
