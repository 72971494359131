import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import * as fromRoot from '@redux'
import * as fromRestaurant from '@redux/restaurant'

@Injectable({
  providedIn: 'root',
})
export class SelectRestaurantGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}
  canActivate(router: ActivatedRouteSnapshot) {
    const restaurantId = router.params.restaurantId
    this.store$.dispatch(new fromRestaurant.Select({ restaurantId: restaurantId }))
    return of(true)
  }
}
