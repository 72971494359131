<div class="mt3-ns ml4-ns">
  <div *ngFor="let day of workingHoursControls; let dayIndex = index" class="flex-ns mb3">
    <div class="mr3 w3" style="margin-top: 1.5rem;">
      <mat-checkbox color="primary" [formControl]="day.get('Include')">
        {{ getWeekdayName(dayIndex) }}
      </mat-checkbox>
    </div>
    <div *ngIf="day.get('Include').value" class="mr3" style="margin-top: 1.5rem;">
      <mat-checkbox color="primary" [formControl]="day.get('Is24Hours')">
        {{ 'Shop.field.is24Hours' | translate: lang }}
      </mat-checkbox>
    </div>
    <div *ngIf="day.get('Include').value" style="position: relative;" class="intervals">
      <div
        *ngFor="
          let dayInterval of day.controls.Intervals.controls;
          let intervalIndex = index;
          let firstInterval = first
        "
        class="flex items-start mb1"
        style="position: relative;"
      >
        <ng-container *ngIf="firstInterval || !day.get('Is24Hours').value">
          <div
            class="flex justify-end justify-start-ns items-center mr3-ns"
            [class.black-50]="day.get('Is24Hours').value"
          >
            <span class="mr2">{{ 'Shop.field.from' | translate: lang }}</span>
            <mat-form-field class="w-90 w4-ns" appearance="fill">
              <mat-label>{{ 'Shop.label.startHour' | translate: lang }}</mat-label>
              <mat-select
                [formControl]="dayInterval.get('StartHour')"
                required
                [disabled]="day.get('Is24Hours').value"
              >
                <mat-option
                  *ngFor="let timeStep of timeSteps"
                  [value]="timeStep"
                  [disabled]="timeStep === dayInterval.get('EndHour').value"
                >
                  {{ timeStep }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="flex justify-end items-center justify-end-ns"
            [class.black-50]="day.get('Is24Hours').value"
          >
            <span class="mr2">{{ 'Shop.field.to' | translate: lang }}</span>
            <mat-form-field class="w-90 w4-ns" appearance="fill">
              <mat-label>{{ 'Shop.label.endHour' | translate: lang }}</mat-label>
              <mat-select
                [formControl]="dayInterval.get('EndHour')"
                required
                [disabled]="day.get('Is24Hours').value"
              >
                <mat-option
                  *ngFor="let timeStep of timeSteps"
                  [value]="timeStep"
                  [disabled]="timeStep === dayInterval.get('StartHour').value"
                >
                  {{ timeStep }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-icon
            *ngIf="!firstInterval"
            class="interval-icon interval-remove"
            (click)="removeDayInterval(dayIndex, intervalIndex)"
          >
            close
          </mat-icon>
        </ng-container>
      </div>
      <mat-icon
        *ngIf="!day.get('Is24Hours').value"
        (click)="addDayInterval(dayIndex)"
        class="interval-icon interval-add"
        >add</mat-icon
      >
    </div>
  </div>
</div>
