<div class="w-100 flex flex-column" [formGroup]="form">
  <!-- ENTITY -->
  <div class="flex justify-center">
    <mat-button-toggle-group formControlName="Entity" (change)="onChangeEntity($event)">
      <mat-button-toggle *ngFor="let entity of entities" [value]="entity">
        {{ 'Shop.field.' + entity | translate: lang }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- ROOT CATEGORY -->
  <mat-form-field class="mv2 db" appearance="fill">
    <mat-label>{{ 'Shop.field.rootCategory' | translate: lang }}</mat-label>
    <mat-select
      (selectionChange)="onSelectRootCategory($event.value)"
      formControlName="RootCategoryId"
      [required]="!allowHomePage"
    >
      <mat-option [value]="null" *ngIf="allowHomePage">
        No category (main page)
      </mat-option>

      <mat-option *ngFor="let category of sortedRootCategories" [value]="category._id">
        {{ category.title | selectLanguage: currentLanguage }}
      </mat-option>
    </mat-select>
    <mat-error
      class="red mv1"
      *ngIf="form && form.touched && form.get('RootCategoryId').hasError('required')"
      >{{ 'Shop.field.fieldRequired' | translate: lang }}</mat-error
    >
  </mat-form-field>

  <!-- NESTED CATEGORY -->
  <mat-form-field *ngIf="showNestedCategoryControl" class="mv2 db" appearance="fill">
    <mat-label>{{ 'Shop.field.nestedCategory' | translate: lang }}</mat-label>
    <mat-select
      formControlName="NestedCategoryId"
      (selectionChange)="onSelectNestedCategory($event.value)"
    >
      <!-- <mat-option value="none">
        {{ 'Shop.label.noSelectedNestedCategory' | translate: lang }}
      </mat-option> -->
      <mat-option *ngFor="let category of subcategories" [value]="category._id">
        {{ category.title | selectLanguage: currentLanguage }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- PRODUCT -->
  <mat-radio-group *ngIf="showProductControl" formControlName="ProductId" class="mv1">
    <mat-label class="black-50 mb1 db">
      {{ 'Shop.field.product' | translate: lang }}
    </mat-label>
    <!-- <mat-radio-button color="primary" class="db pa2" value="none">
      <div class="ml1">
        {{ 'Shop.label.noSelectedProduct' | translate: lang }}
      </div>
    </mat-radio-button> -->
    <mat-radio-button
      *ngFor="let product of products"
      color="primary"
      class="db pa2"
      [value]="product._id"
    >
      <div class="ml1 flex items-center">
        <div
          [style.backgroundImage]="
            product.pictures.length ? 'url(' + product.pictures[0] + ')' : 'none'
          "
          style="width: 54px; height: 54px; background-position: center; overflow: hidden;"
          class="mr2 br2 cover bg-light-gray"
        ></div>
        <div class="flex-auto">
          {{ product.title | selectLanguage: currentLanguage }}
        </div>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>
