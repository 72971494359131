// tslint:disable: no-bitwise

import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core'
import { User } from '@models'

function hashCode(str) {
  // java String#hashCode
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function intToRGB(i) {
  const color = (i & 0x00ffffff).toString(16)
  return '#' + '000000'.substring(color.length) + color
}

function isLight(i) {
  const color = i & 0x00ffffff
  const r = color >> 16
  const g = (color >> 8) & 255
  const b = color & 255
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp > 160
}

@Component({
  selector: 'hot-user-avatar',
  template: `
    <div class="user-name" [ngStyle]="style">
      {{ initials }}
    </div>
  `,
  styles: [
    `
      .user-name {
        width: 2em;
        height: 2em;
        border-radius: 50%;
        display: inline-block;
        line-height: 2em;
        text-align: center;
        font-weight: bold;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() user: User = null
  public initials = ''

  public style = {}

  constructor() {}

  ngOnInit() {}
  ngOnChanges() {
    const user = { _id: '', name: '', ...this.user }
    this.initials = user.name
      .split(/\s/g)
      .map(x => x[0])
      .slice(0, 2)
      .join('')
    const color: number = hashCode(user._id)
    const textColor = isLight(color) ? 'rgba(0,0,0,0.5)' : '#fff'
    this.style = {
      color: textColor,
      backgroundColor: intToRGB(color),
    }
  }
}
