import { Action } from '@ngrx/store'
import { Builder, BuilderResponse } from '@models/builder.model'

export enum BuilderActionTypes {
  Create = '[Builder] Create',
  CreateSuccess = '[Builder] Create Success',
  CreateFail = '[Builder] Create Fail',
  Load = '[Builder] Load',
  LoadSuccess = '[Builder] Load Success',
  LoadFail = '[Builder] Load Fail',
  LoadAll = '[Builder] LoadAll',
  LoadAllSuccess = '[Builder] LoadAll Success',
  LoadAllFail = '[Builder] LoadAll Fail',
  Update = '[Builder] Update',
  UpdateSuccess = '[Builder] Update Success',
  UpdateFail = '[Builder] Update Fail',
  UpdateTrackings = '[Builder] Update Trackings',

  Delete = '[Builder] Delete',
  DeleteSuccess = '[Builder] Delete Success',
  DeleteFail = '[Builder] Delete Fail',
}

/**
 * Create Builders actions
 */

export interface CreatePayload {
  propertyId: string
  pageId?: string
  menuPage: Partial<Builder>
  translateName?: boolean
  meta?: {}
}

export class Create implements Action {
  public readonly type = BuilderActionTypes.Create
  constructor(public payload: CreatePayload) {}
}
export class CreateSuccess implements Action {
  public readonly type = BuilderActionTypes.CreateSuccess
  constructor(public payload, public meta: CreatePayload) {}
}
export class CreateFail implements Action {
  public readonly type = BuilderActionTypes.CreateFail

  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load Builders actions
 */
export interface LoadPayload {
  propertyId: string
  pageId?: string
}
export class Load implements Action {
  public readonly type = BuilderActionTypes.Load
  constructor(public payload: LoadPayload) {}
}
export class LoadSuccess implements Action {
  public readonly type = BuilderActionTypes.LoadSuccess
  constructor(public payload, public meta: LoadPayload) {}
}
export class LoadFail implements Action {
  public readonly type = BuilderActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * LoadAll Builders actions
 */
export interface LoadAllPayload {
  propertyId: string
}
export class LoadAll implements Action {
  public readonly type = BuilderActionTypes.LoadAll
  constructor(public payload: LoadAllPayload) {}
}
export class LoadAllSuccess implements Action {
  public readonly type = BuilderActionTypes.LoadAllSuccess
  constructor(public payload: BuilderResponse, public meta: LoadAllPayload) {}
}
export class LoadAllFail implements Action {
  public readonly type = BuilderActionTypes.LoadAllFail
  constructor(public error: any, public meta: LoadAllPayload) {}
}

/**
 * Update Builder actions
 */

export interface UpdatePayload {
  propertyId: string
  pageId?: string
  lang: string
  menuPage: Partial<Builder>
  translateName?: boolean
}

export class Update implements Action {
  public readonly type = BuilderActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}
export class UpdateSuccess implements Action {
  public readonly type = BuilderActionTypes.UpdateSuccess
  constructor(public payload: Builder, public meta: UpdatePayload) {}
}
export class UpdateFail implements Action {
  public readonly type = BuilderActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete Builder actions
 */

export interface DeletePayload {
  propertyId: string
  pageId: string
}

export class Delete implements Action {
  public readonly type = BuilderActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}
export class DeleteSuccess implements Action {
  public readonly type = BuilderActionTypes.DeleteSuccess
  constructor(public payload: { result: string }, public meta: DeletePayload) {}
}
export class DeleteFail implements Action {
  public readonly type = BuilderActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type BuilderActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
