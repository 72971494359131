import { NgModule, Component } from '@angular/core'
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router'

import {
  AuthGuard,
  LoadPropertiesGuard,
  PropertyGuard,
  SelectFormResponseGuard,
  SelectCartGuard,
  SelectShopCartGuard,
  SelectPushMessageGuard,
  OnboardingGuard,
} from '@shared/guards'

import { AuthContainer } from './auth/auth.container'
import { ResetPasswordContainer } from './auth/reset/reset.container'
import { RecoverPasswordContainer } from './auth/recover/recover.container'
import { HomePageComponent } from './home/home-page/home-page.component'
import { NoContentComponent } from './no-content/no-content.component'
import { SelectReviewGuard } from '@shared/guards/select-review.guard'
import { SelectChatGuard } from '@shared/guards/select-chat.guard'
import { IsAdminGuard } from '@shared/guards/is-admin.guard'

export function propertyIdMatcher(url: UrlSegment[]): UrlMatchResult {
  return url.length >= 2 && url[0].path === 'properties'
    ? {
        consumed: url.slice(0, 2),
        posParams: {
          propertyId: url[1],
        },
      }
    : null
}

// Component used to call `canActivate` guard,
// because it's not triggered when using `redirectTo`
// @see https://github.com/angular/angular/issues/18605
@Component({
  selector: 'hot-dummy',
  template: ``,
})
export class DummyComponent {}

const routes: Routes = [
  {
    matcher: propertyIdMatcher,
    canActivate: [AuthGuard, PropertyGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DummyComponent,
        canActivate: [OnboardingGuard],
      },
      {
        path: 'hi',
        loadChildren: () => import('./hello/hello.module').then(m => m.HelloModule),
      },
      {
        path: 'stats',
        loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule),
      },
      {
        path: 'chats',
        loadChildren: () => import('./chats/chats.module').then(m => m.ChatsModule),
        canActivate: [SelectChatGuard],
      },
      {
        path: 'chats/:chatId',
        loadChildren: () => import('./chats/chats.module').then(m => m.ChatsModule),
        canActivate: [SelectChatGuard],
      },
      {
        path: 'requests/:formResponseId',
        loadChildren: () => import('./request/request.module').then(m => m.RequestModule),
        canActivate: [SelectFormResponseGuard],
      },
      {
        path: 'requests',
        pathMatch: 'full',
        loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule),
        canActivate: [],
      },
      {
        path: 'orders/:orderId',
        loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
        canActivate: [SelectCartGuard],
      },
      {
        path: 'orders',
        pathMatch: 'full',
        loadChildren: () => import('./carts/carts.module').then(m => m.CartsModule),
        canActivate: [],
      },
      {
        path: 'shop-orders/:orderId',
        loadChildren: () => import('./shop-cart/shop-cart.module').then(m => m.ShopCartModule),
        canActivate: [SelectShopCartGuard],
      },
      {
        path: 'shop-orders',
        pathMatch: 'full',
        loadChildren: () => import('./shop-carts/shop-carts.module').then(m => m.ShopCartsModule),
        canActivate: [],
      },
      {
        path: 'reviews',
        pathMatch: 'full',
        loadChildren: () => import('./reviews/reviews.module').then(m => m.ReviewsModule),
        canActivate: [],
      },
      {
        path: 'reviews/:reviewId',
        loadChildren: () => import('./review/review.module').then(m => m.ReviewModule),
        canActivate: [SelectReviewGuard],
      },
      {
        path: 'polls',
        loadChildren: () => import('./polls/polls.module').then(m => m.PollsModule),
        canActivate: [],
      },
      {
        path: 'pushes',
        loadChildren: () => import('./pushes/pushes.module').then(m => m.PushesModule),
        canActivate: [SelectPushMessageGuard],
      },
      {
        path: 'pushes/:pushMessageId',
        loadChildren: () => import('./push/push.module').then(m => m.PushModule),
        canActivate: [SelectPushMessageGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [],
      },
    ],
  },
  {
    path: 'login',
    component: AuthContainer,
    canActivate: [AuthGuard],
    data: {
      publicRoute: true,
    },
  },
  {
    path: 'recover',
    component: RecoverPasswordContainer,
    canActivate: [AuthGuard],
    data: {
      publicRoute: true,
    },
  },
  {
    path: 'reset/:resetPasswordToken',
    component: ResetPasswordContainer,
    canActivate: [AuthGuard],
    data: {
      publicRoute: true,
    },
  },
  {
    path: '',
    canActivate: [AuthGuard, LoadPropertiesGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'admin',
      },
      {
        path: 'properties',
        component: HomePageComponent,
        children: [],
      },
      {
        path: 'admin',
        canActivate: [IsAdminGuard],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
    ],
  },
  {
    path: '**',
    component: NoContentComponent,
    data: { publicRoute: true },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  declarations: [DummyComponent],
})
export class AppRoutingModule {}
