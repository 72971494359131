import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { defer, NEVER, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import { AuthService, WebChatWidgetService, I18nService } from '@shared/services'

import * as userActions from './user.actions'
import { UserService } from './user.service'
import * as fromRouter from '@redux/router'
import { environment } from 'environments/environment'
import { PostMessageType } from '@shared/constants'

const ROOT_APP_URL = '/'
const HTTP_CONFLICT = 409
const HTTP_FORBIDDEN = 403
const HTTP_UNAUTHORIZED = 401

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly webChatWidgetService: WebChatWidgetService,
    public i18n: I18nService,
    private matSnackBar: MatSnackBar
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Login),
    map((action: userActions.Login) => action.payload),
    switchMap(({ email, password, accessToken }) => {
      const tokenObservable = accessToken
        ? of(accessToken)
        : this.userService.login(email, password)
      return tokenObservable.pipe(
        switchMap((token: string) => {
          this.authService.accessToken = token
          return this.userService.getUser()
        }),
        map(user => new userActions.LoginSuccess({ user })),
        catchError(error => of(new userActions.LoginFail(error)))
      )
    })
  )

  @Effect()
  public onAuthorized$ = defer(() => {
    return this.authService.isAuthorized()
      ? this.userService.getUser().pipe(
          switchMap(user => of(new userActions.LoginSuccess({ user }))),
          catchError(error => of(new userActions.LoginFail(error)))
        )
      : NEVER
  })

  @Effect({ dispatch: false })
  public userLoginFail$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginFail),
    tap((action: userActions.LoginFail) => {
      const message =
        action.error.statusCode === HTTP_UNAUTHORIZED
          ? this.i18n.translate('Auth.error.loginOrPasswordError')
          : action.error.message
      if (message) {
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )

  @Effect()
  public recover$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.ResetPassword),
    switchMap((action: userActions.ResetPassword) => {
      const meta = action.payload
      return this.userService.reset(meta.email).pipe(
        map(() => {
          const message = this.i18n.translate('Auth.action.recoverSuccess', {
            email: meta.email,
          })
          this.matSnackBar.open(message, '', {
            panelClass: ['bg-green'],
            duration: 3000,
          })
          return new fromRouter.Go({ path: ['login'] })
        }),
        catchError(x => {
          const message = this.i18n.translate('Auth.error.sendEmail')
          this.matSnackBar.open(message, '', {
            panelClass: ['bg-red'],
            duration: 3000,
          })
          return of(new userActions.ResetPasswordFail(x))
        })
      )
    })
  )

  @Effect()
  public validateToken$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.ValidateResetPasswordToken),
    switchMap((action: userActions.ValidateResetPasswordToken) => {
      const meta = action.payload
      return this.userService.validateResetPasswordToken(meta.token).pipe(
        map(x => new userActions.ValidateResetPasswordTokenSuccess(x, meta)),
        catchError(() => {
          const message = this.i18n.translate('Auth.error.expiredLink')
          this.matSnackBar.open(message, '', {
            panelClass: ['bg-red'],
            duration: 3000,
          })
          return of(new fromRouter.Go({ path: ['login'] }))
        })
      )
    })
  )

  @Effect()
  public setPassword$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.SetPassword),
    switchMap((action: userActions.SetPassword) => {
      const meta = action.payload
      return this.userService
        .setPassword(meta.token, meta.password, meta.passwordConfirmation)
        .pipe(
          map(() => {
            const message = this.i18n.translate('Auth.action.resetSuccess')
            this.matSnackBar.open(message, '', {
              panelClass: ['bg-green'],
              duration: 3000,
            })
            return new fromRouter.Go({ path: ['login'] })
          }),
          catchError(x => {
            const err = x.description.message
            const message =
              err === 'passwords_mismatch'
                ? this.i18n.translate('Auth.error.passwordsMismatch')
                : this.i18n.translate('Auth.error.expiredLink')
            this.matSnackBar.open(message, '', {
              panelClass: ['bg-red'],
              duration: 3000,
            })
            return of(new userActions.ResetPasswordFail(x))
          })
        )
    })
  )

  @Effect({ dispatch: false })
  public subsscribePushNotifications$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginSuccess),
    tap(() => {
      window.postMessage(
        { data: environment.PUSH_NOTIF_PUBLIC_KEY, type: PostMessageType.USER_LOGIN_SUCCESS },
        ROOT_APP_URL
      )
    })
  )

  @Effect({ dispatch: false })
  public initWebChat$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginSuccess),
    tap(() => {
      this.webChatWidgetService.loadWebChatScript()
    })
  )

  @Effect({ dispatch: false })
  public onLogout$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Logout),
    tap(() => this.authService.logout())
  )

  /**
   * Create User effects
   */
  @Effect()
  public create$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Create),
    switchMap((action: userActions.Create) => {
      const meta = action.payload
      return this.userService
        .createUser(
          action.payload.propertyId,
          action.payload.user,
          action.payload.notifications,
          action.payload.roomServiceNotifications
        )
        .pipe(
          map(x => new userActions.CreateSuccess(x, meta)),
          catchError(x => of(new userActions.CreateFail(x, meta)))
        )
    })
  )

  @Effect()
  public createSuccess$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.CreateSuccess),
    tap(() => {
      const message = this.i18n.translate('StaffUsers.action.createSuccess')
      if (message) {
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-green'],
          duration: 2000,
        })
      }
    }),
    map((action: userActions.CreateSuccess) => {
      return new fromRouter.Go({
        path: ['properties', action.meta.propertyId, 'settings', 'staff'],
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.CreateFail),
    tap((action: userActions.CreateFail) => {
      const message =
        action.error.statusCode === HTTP_CONFLICT
          ? this.i18n.translate('Auth.error.userExists', { email: action.meta.user.email })
          : action.error.description.message
      if (message) {
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )

  /**
   * Update User effects
   */
  @Effect()
  public update$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Update),
    switchMap((action: userActions.Update) => {
      const meta = action.payload
      return this.userService
        .updateUser(
          action.payload.propertyId,
          action.payload.userId,
          action.payload.user,
          action.payload.notifications,
          action.payload.roomServiceNotifications
        )
        .pipe(
          map(x => new userActions.UpdateSuccess(x, meta)),
          catchError(x => of(new userActions.UpdateFail(x, meta)))
        )
    })
  )

  @Effect()
  public updateSuccess$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.UpdateSuccess),
    tap(() => {
      const message = this.i18n.translate('StaffUsers.action.updateSuccess')
      if (message) {
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-green'],
          duration: 2000,
        })
      }
    }),
    map((action: userActions.UpdateSuccess) => {
      return new fromRouter.Go({
        path: ['properties', action.meta.propertyId, 'settings', 'staff'],
        // path: ['properties', action.meta.propertyId, 'settings', 'staff', 'create', 'success'],
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.UpdateFail),
    tap((action: userActions.UpdateFail) => {
      const message =
        action.error.statusCode === HTTP_CONFLICT
          ? this.i18n.translate('Auth.error.userExists', { email: action.meta.user.email })
          : action.error.statusCode === HTTP_FORBIDDEN
          ? this.i18n.translate('StaffUsers.action.updateForbidden')
          : action.error.description.message
      if (message) {
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )

  /**
   * Update User preferences effects
   */
  @Effect()
  public updatePreferences$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.UpdatePreferences),
    switchMap((action: userActions.UpdatePreferences) => {
      const meta = action.payload
      return this.userService
        .updateUser(
          action.payload.propertyId,
          action.payload.userId,
          action.payload.user,
          action.payload.notifications,
          action.payload.roomServiceNotifications
        )
        .pipe(
          map(x => new userActions.UpdatePreferencesSuccess(x, meta)),
          catchError(x => of(new userActions.UpdatePreferencesFail(x, meta)))
        )
    })
  )

  /**
   * Delete User effects
   */
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.Delete),
    switchMap((action: userActions.Delete) => {
      const meta = action.payload
      return this.userService.deleteUser(action.payload.propertyId, action.payload.userId).pipe(
        map(x => new userActions.DeleteSuccess(x, meta)),
        catchError(x => of(new userActions.DeleteFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.DeleteSuccess),
    tap(() => {
      const message = this.i18n.translate('StaffUsers.action.deleteSuccess')
      if (message) {
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-green'],
          duration: 2000,
        })
      }
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(userActions.UserActionTypes.DeleteFail),
    tap((action: userActions.DeleteFail) => {
      const message = this.i18n.translate(
        action.error.statusCode === HTTP_FORBIDDEN
          ? 'StaffUsers.action.deleteForbidden'
          : 'StaffUsers.action.deleteFail'
      )
      if (message) {
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )
}
