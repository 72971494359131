import { Inject, Injectable } from '@angular/core'

import { WindowRefService } from './window-ref.service'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { DOCUMENT } from '@angular/common'

const STORAGE_PREFIX = 'hot-auth'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _storage: Storage
  private _authOptions: { ssoMode?: boolean }
  private _authToken: string
  private _authTokenPayload: { _id: string; role: string; email: string }

  public accessToken$ = new BehaviorSubject<string | null>(null)
  public user$ = new BehaviorSubject<{ _id: string; role: string; email: string } | null>(null)
  public isAdmin$ = this.user$.pipe(map(x => x && x.role === 'oleg'))

  constructor($window: WindowRefService, @Inject(DOCUMENT) private document: Document) {
    this.user$.subscribe(user => {
      if (user && window['_fs_namespace'] && window[window['_fs_namespace']]) {
        window['FS'].identify(user._id, { email: user.email })
      }
    })
    this._storage = $window.localStorage
    this._authOptions = {}
    const urlParams = new URLSearchParams(this.document.location.search)
    const ssoToken = urlParams.get('authToken') ? urlParams.get('authToken') : null
    const accessToken = ssoToken || this._storage.getItem(`${STORAGE_PREFIX}.access_token`)
    this.login(accessToken, {
      ssoMode: !!ssoToken,
    })
  }

  public login(accessToken: string, opt?: { ssoMode?: boolean }) {
    this._authOptions = opt ? opt : {}
    this.accessToken = accessToken
    this.payload = this.parseToken(accessToken)
    this.accessToken$.next(this.accessToken)
    this.user$.next(this.payload)
  }

  public logout(): void {
    this.accessToken = null
    this.accessToken$.next(null)
    this.user$.next(null)
    this._storage.removeItem(`${STORAGE_PREFIX}.access_token`)
  }

  public isAuthorized(): boolean {
    return Boolean(this.accessToken)
  }

  public get accessToken(): string | null {
    return this._authToken
  }
  public set accessToken(accessToken: string) {
    this._authToken = accessToken
    const tokenPayload = this.parseToken(this._authToken)
    this.accessToken$.next(accessToken)
    if (!this._authOptions.ssoMode) {
      this._storage.setItem(`${STORAGE_PREFIX}.access_token`, this.accessToken)
    }
    if (tokenPayload) {
      this.payload = tokenPayload
    }
  }

  public get payload(): { _id: string; role: string; email: string } {
    return this._authTokenPayload
  }

  public set payload(payload) {
    this._authTokenPayload = payload
    this.user$.next(this._authTokenPayload)
  }

  public get ssoMode() {
    return this._authOptions && this._authOptions.ssoMode
  }

  public parseToken(
    token: string
  ): { _id: string; role: string; email: string; iat: number } | null {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
      return null
    }
  }
}
