import { MenuButtonItem } from './builder.model'
import { PaginationOptions } from './pagination-options'
import { TranslatableField } from './translatable-field'

export interface FAQLanguageSubItem {
  imageUrl?: string
  text?: string
  buttons: MenuButtonItem[]
}

export interface FAQCategory {
  _id: string
  code: string
  viewCode: string
  title: TranslatableField
}

export const getImage = (faq: FAQLanguageSubItem): string => {
  return faq && faq.imageUrl
}

export const getText = (faq: FAQLanguageSubItem): string => {
  return (faq && faq.text) || ''
}

export const getButtons = (faq: FAQLanguageSubItem): MenuButtonItem[] => {
  return faq && faq.buttons
}

export const getFAQForLanguage = (item: FAQItem, lang = null): FAQLanguageSubItem => {
  return item ? item[lang] || item.default : null
}

export const getImageForLanguage = (item: FAQItem, lang = null): string => {
  const faq = getFAQForLanguage(item, lang)
  return getImage(faq)
}

export const getTextForLanguage = (item: FAQItem, lang = null): string => {
  const faq = item[lang]
  return getText(faq)
}

export const getButtonsForLanguage = (item: FAQItem, lang = null): MenuButtonItem[] => {
  const faq = item[lang]
  return getButtons(faq)
}

export const intentFallbackCodes = ['Default Welcome Intent', 'Default Fallback Intent']

export interface FAQItem {
  default: FAQLanguageSubItem
  en: FAQLanguageSubItem
  ru: FAQLanguageSubItem
}

export interface PropertyFAQ {
  _id?: string
  faq: string
  property: string
  content: FAQItem
  needTranslate?: boolean
}

export interface FAQCategoriesFilters {
  paginationOptions?: PaginationOptions
}
