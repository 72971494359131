import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Inject } from '@angular/core'
import { FilterChipsStatus } from '@shared/components/filter-chips/filter-chips-status'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'hot-filter-chips',
  template: `
    <!--
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select [placeholder]="statuses[0].label | translate: locale.language">
        <mat-option *ngFor="let status of statuses" [value]="status.status">
          {{ status.label | translate: locale.language }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <pre>{{ statuses | json }}</pre>
    -->
    <div class="filter-chips br3 pa1 bn-ns">
      <div
        *ngFor="let status of statuses"
        class="filter-chips__chip br2 br-pill-ns"
        [class.filter-chips__chip--active]="status.status == activeStatus"
        (click)="changeFilterStatus(status)"
      >
        {{ status.label | translate: locale.language }}
      </div>
    </div>
  `,
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent implements OnInit, OnDestroy {
  @Input() public activeStatus = ''
  @Input() public statuses: FilterChipsStatus[] = []
  @Output() public change = new EventEmitter<FilterChipsStatus>()

  public constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  public ngOnInit() {}
  public ngOnDestroy() {}

  public changeFilterStatus(status: FilterChipsStatus): void {
    this.change.next(status)
  }
}
