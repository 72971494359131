import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators'

import * as fromRouter from '../router'
import * as formActions from './form.actions'
import { FormService } from './form.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
// import * as fromForm from '@redux/form/index'
import { I18nService } from '@shared/services'
import { FormConfirmationDialogComponent } from '@shared/components/form-confirmation-dialog/form-confirmation-dialog.component'

const DELETE_SUCCESS = 'Шаблон успешно удален'
const DELETE_ERROR = 'При удалении шаблона возникла ошибка'
const UPDATE_SUCCESS = 'Данные были успешно обновлены'
const UPDATE_FAIL = 'При обновлении шаблона возникла ошибка'

@Injectable()
export class FormEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.Load),
    exhaustMap((action: formActions.Load) => {
      const meta = action.payload
      return this.formService.getList(meta.propertyId).pipe(
        map(x => new formActions.LoadSuccess(x, meta)),
        catchError(x => of(new formActions.LoadFail(x, meta)))
      )
    })
  )

  @Effect()
  loadItem$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.LoadItem),
    exhaustMap((action: formActions.LoadItem) => {
      const meta = action.payload
      return this.formService.get(meta.propertyId, meta.formId).pipe(
        map(x => new formActions.LoadItemSuccess(x, meta)),
        catchError(x => of(new formActions.LoadItemFail(x, meta)))
      )
    })
  )

  @Effect()
  create$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.Create),
    exhaustMap((action: formActions.Create) => {
      const meta = action.payload
      return this.formService.create(meta.propertyId, meta.form).pipe(
        map(x => new formActions.CreateSuccess(x, meta)),
        catchError(x => of(new formActions.CreateFail(x, meta)))
      )
    })
  )

  @Effect()
  redirectToFormAfterCreate$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.CreateSuccess),
    map((action: formActions.CreateSuccess) => {
      const { payload, meta } = action
      return new fromRouter.Go({
        path: ['properties', meta.propertyId, 'settings', 'forms', payload.result],
      })
    })
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.Update),
    exhaustMap((action: formActions.Update) => {
      const meta = action.payload
      return this.formService
        .update(meta.propertyId, meta.formId, meta.form, meta.sourceLanguage, meta.forceTranslate)
        .pipe(
          map(x => new formActions.UpdateSuccess(x, meta)),
          catchError(x => of(new formActions.UpdateFail(x, meta)))
        )
    })
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.Delete),
    exhaustMap((action: formActions.Delete) => {
      const payload = action.payload
      const propertyId = payload.propertyId
      const formId = payload.formId
      return this.formService.getParentPages(propertyId, formId).pipe(
        switchMap(parentPages => {
          const dialogRef = this.dialog.open(FormConfirmationDialogComponent, {
            width: '500px',
            data: {
              propertyId,
              formId,
              pagesList: parentPages.pages,
              faqsList: parentPages.faqs,
              confirmButtonText: this.translation.translate('Forms.action.remove'),
            },
          })

          return dialogRef.afterClosed().pipe(
            switchMap(result => {
              const isConfirmed = result && result.isConfirmed
              if (isConfirmed) {
                return of(new formActions.ForceDelete(action.payload, result))
              } else {
                return of(null)
              }
            })
          )
        })
      )
    }),
    filter(Boolean)
  )

  @Effect()
  forceDelete$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.ForceDelete),
    exhaustMap((action: formActions.ForceDelete) => {
      const { payload, meta } = action
      return this.formService.delete(payload, meta).pipe(
        map(
          () => new fromRouter.Go({ path: ['properties', payload.propertyId, 'settings', 'forms'] })
        ),
        catchError(x => of(new formActions.DeleteFail(x, action.payload)))
      )
    })
  )

  @Effect()
  loadTemplates$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.LoadTemplates),
    exhaustMap(() => {
      return this.formService.getTemplatesList().pipe(
        map(x => new formActions.LoadTemplatesSuccess(x)),
        catchError(x => of(new formActions.LoadTemplatesFail(x)))
      )
    })
  )

  @Effect()
  loadTemplate$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.LoadTemplate),
    exhaustMap((action: formActions.LoadTemplate) => {
      const formId = action.payload.formId
      return this.formService.getTemplate(formId).pipe(
        map(x => new formActions.LoadTemplatesSuccess(x)),
        catchError(x => of(new formActions.LoadTemplatesFail(x)))
      )
    })
  )

  @Effect()
  createTemplate$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.CreateTemplate),
    exhaustMap((action: formActions.CreateTemplate) => {
      const meta = action.payload
      return this.formService.createTemplate(meta.form).pipe(
        map(x => new formActions.CreateTemplateSuccess(x, meta)),
        catchError(x => of(new formActions.CreateTemplateFail(x, meta)))
      )
    })
  )

  @Effect()
  updateTemplate$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.UpdateTemplate),
    exhaustMap((action: formActions.UpdateTemplate) => {
      const meta = action.payload
      return this.formService
        .updateTemplate(meta.formId, meta.form, meta.forceTranslate, meta.sourceLanguage)
        .pipe(
          map(x => new formActions.UpdateTemplateSuccess(x, meta)),
          catchError(x => of(new formActions.UpdateTemplateFail(x, meta)))
        )
    })
  )

  @Effect()
  deleteTemplate$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.DeleteTemplate),
    exhaustMap((action: formActions.DeleteTemplate) => {
      const formId = action.payload.formId

      return this.formService.getUsedInForms(formId).pipe(
        switchMap(parentPages => {
          const dialogRef = this.dialog.open(FormConfirmationDialogComponent, {
            width: '500px',
            data: {
              formId,
              isTemplate: true,
              pagesList: parentPages.pages,
              confirmButtonText: this.translation.translate('Forms.action.removeTemplate'),
            },
          })

          return dialogRef.afterClosed().pipe(
            switchMap(result => {
              const isConfirmed = result && result.isConfirmed
              if (isConfirmed) {
                return of(new formActions.ForceDeleteTemplate(action.payload, result))
              } else {
                return of(null)
              }
            })
          )
        })
      )
    }),
    filter(Boolean)
  )

  @Effect()
  forceDeleteTemplate$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.ForceDeleteTemplate),
    exhaustMap((action: formActions.ForceDeleteTemplate) => {
      const { payload, meta } = action
      return this.formService.deleteTemplate(payload, meta).pipe(
        map(() => new fromRouter.Go({ path: ['admin', 'chat_bot', 'forms'] })),
        catchError(x => of(new formActions.DeleteTemplateFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.UpdateTemplateSuccess, formActions.ActionTypes.UpdateSuccess),
    tap(_ => {
      this.matSnackBar.open(UPDATE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.UpdateTemplateFail),
    tap(() => {
      this.matSnackBar.open(UPDATE_FAIL, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteTemplateSuccess$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.DeleteTemplateSuccess),
    tap(() => {
      this.matSnackBar.open(DELETE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteTemplateFail$ = this.actions$.pipe(
    ofType(formActions.ActionTypes.DeleteTemplateFail),
    tap(() => {
      this.matSnackBar.open(DELETE_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    public translation: I18nService,
    public dialog: MatDialog,
    private actions$: Actions,
    private formService: FormService,
    private matSnackBar: MatSnackBar
  ) {}
}
