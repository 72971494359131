import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core'
import { FormResponse, LanguageItem } from '@models'
import * as chatUtils from '@models/chat.model'
import { FormResponsesHeaders } from '@shared/constants'
import * as R from 'ramda'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { langLabel } from '@shared/utils/langLabel'
import { ConfigService, I18nService } from '@shared/services'

@Component({
  selector: 'hot-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
})
export class RequestsListComponent implements OnInit, OnDestroy {
  @Input() public theme: 'table' | 'list' | 'user-list' = 'table'
  @Input() public requests: FormResponse[] = []
  @Input() public propertyId: string
  @Input() public availableLanguages: LanguageItem[]
  public columnHeaders = [
    FormResponsesHeaders.Number,
    FormResponsesHeaders.Date,
    FormResponsesHeaders.Guest,
    FormResponsesHeaders.Language,
    FormResponsesHeaders.RoomNumber,
    FormResponsesHeaders.Type,
    FormResponsesHeaders.Status,
  ]

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public readonly configService: ConfigService,
    public readonly translation: I18nService
  ) {
    this.availableLanguages = this.configService.languages
  }

  public getLanguageLabel(langCode): string {
    return langLabel(this.translation, langCode, this.availableLanguages)
  }

  public ngOnInit() {}
  public ngOnDestroy() {}

  public getRoomNumber(request: FormResponse): string {
    return request.__chat && chatUtils.getFormattedRoomNumbers(request.__chat)
  }

  public showColumn(columnName): boolean {
    return this.columnHeaders && R.contains(columnName, this.columnHeaders)
  }
}
