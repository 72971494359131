import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { Analytics, AnalyticsFilters, PropertyKind, ShopServiceStatistics } from '@models'

@Injectable()
export class AnalyticsService {
  public constructor(public apiService: ApiService) {}

  public getAnalytics(filters?: AnalyticsFilters): Observable<Analytics> {
    return this.apiService.get(`analytics`, { params: filters })
  }

  public getShopStatistics({
    propertyId = null,
    from,
    to,
    isAdmin = false,
    propertyKind = null,
  }: {
    from: string
    to: string
    propertyId?: string
    isAdmin?: boolean
    propertyKind?: PropertyKind
  }): Observable<ShopServiceStatistics> {
    return this.apiService.post(`analytics/shop`, {
      propertyId,
      from,
      to,
      isAdmin,
      propertyKind,
    })
  }
}
