import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import * as fromSalesServiceActions from './sales-service.actions'
import { SalesServiceService } from './sales-service.service'

const CREATE_SUCCESS = 'CREATE_SUCCESS'
const DUPLICATE_ERROR = 'DUPLICATE_ERROR'
const CREATE_ERROR = 'CREATE_ERROR'
const LOAD_ERROR = 'LOAD_ERROR'
const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
const UPDATE_FAIL = 'UPDATE_FAIL'

@Injectable()
export class SalesServiceEffects {
  @Effect()
  public createSettings$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.CreateSettings),
    switchMap((action: fromSalesServiceActions.CreateSettings) => {
      const meta = action.payload
      return this.saleServiceService
        .createSettings(action.payload.propertyId, action.payload.salesServiceSettings)
        .pipe(
          map(x => {
            return new fromSalesServiceActions.CreateSettingsSuccess(x, meta)
          }),
          catchError(x => of(new fromSalesServiceActions.CreateSettingsFail(x, meta)))
        )
    })
  )

  /**
   * Create Settings effects
   */
  @Effect({ dispatch: false })
  public createSettingsSuccess$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.CreateSettingsSuccess),
    tap(() => {
      this.matSnackBar.open(CREATE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createSettingsFail$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.CreateSettingsFail),
    tap((action: fromSalesServiceActions.CreateSettingsFail) => {
      const message = action.error
      const error =
        message.description && message.description.errors && message.description.errors.duplicated
          ? DUPLICATE_ERROR
          : CREATE_ERROR
      if (error) {
        this.matSnackBar.open(error, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )

  /**
   * Load Settings effects
   */
  @Effect()
  public loadSettings$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.LoadSettings),
    switchMap((action: fromSalesServiceActions.LoadSettings) => {
      return this.saleServiceService.getSettings(action.payload.propertyId).pipe(
        map(x => new fromSalesServiceActions.LoadSettingsSuccess(x, action.payload)),
        catchError(x => of(new fromSalesServiceActions.LoadSettingsFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadSettingsFail$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.LoadSettingsFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  @Effect()
  public loadSettingsItem$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.LoadSettingsItem),
    switchMap((action: fromSalesServiceActions.LoadSettingsItem) => {
      return this.saleServiceService
        .getSettingsItem(action.payload.propertyId, action.payload.salesServiceSettingsId || '')
        .pipe(
          map(x => new fromSalesServiceActions.LoadSettingsItemSuccess(x, action.payload)),
          catchError(x => of(new fromSalesServiceActions.LoadSettingsItemFail(x, action.payload)))
        )
    })
  )

  @Effect()
  public loadSettingsItemForProperty$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.LoadSettingsItemForProperty),
    switchMap((action: fromSalesServiceActions.LoadSettingsItemForProperty) => {
      return this.saleServiceService.getSettingsItem(action.payload.propertyId, '').pipe(
        map(x => new fromSalesServiceActions.LoadSettingsItemSuccess(x, action.payload)),
        catchError(x => of(new fromSalesServiceActions.LoadSettingsItemFail(x, action.payload)))
      )
    })
  )

  /**
   * Update Settings effects
   */
  @Effect()
  public updateSettings$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.UpdateSettings),
    switchMap((action: fromSalesServiceActions.UpdateSettings) => {
      return this.saleServiceService
        .updateSettings(
          action.payload.propertyId,
          action.payload.salesServiceSettingsId,
          action.payload.saleServiceSettings
        )
        .pipe(
          map(x => new fromSalesServiceActions.UpdateSettingsSuccess(x, action.payload)),
          catchError(x => of(new fromSalesServiceActions.UpdateSettingsFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateSettingsSuccess$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.UpdateSettingsSuccess),
    tap(() => {
      this.matSnackBar.open(UPDATE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateSettingsFail$ = this.actions$.pipe(
    ofType(fromSalesServiceActions.SalesServiceActionTypes.UpdateSettingsFail),
    tap(() => {
      this.matSnackBar.open(UPDATE_FAIL, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private saleServiceService: SalesServiceService,
    private matSnackBar: MatSnackBar
  ) {}
}
