<div class="flex justify-center">
  <mat-card class="w-100 mw7">
    <div class="w-100 flex flex-column" [formGroup]="form">
      <mat-form-field class="ma2" appearance="fill">
        <mat-label>{{ 'StaffUserForm.field.name' | translate: locale.language }}</mat-label>
        <input [maxlength]="maxFieldLenght" #name formControlName="Name" matInput required />
        <mat-hint align="end">{{ name.value.length }} / {{ maxFieldLenght }}</mat-hint>
        <mat-error *ngIf="form && form.touched && form.get('Name').hasError('required')">
          {{ 'StaffUserForm.error.nameRequired' | translate: locale.language }}
        </mat-error>
        <mat-error *ngIf="form && form.get('Name').hasError('blank')">
          {{ 'StaffUserForm.error.nameInvalid' | translate: locale.language }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="ma2" appearance="fill">
        <mat-label>{{ 'StaffUserForm.field.position' | translate: locale.language }}</mat-label>
        <input
          [maxlength]="maxFieldLenght"
          #roleDescription
          formControlName="RoleDescription"
          matInput
          required
        />
        <mat-hint align="end">{{ roleDescription.value.length }} / {{ maxFieldLenght }}</mat-hint>
        <mat-error *ngIf="form && form.touched && form.get('RoleDescription').hasError('required')">
          {{ 'StaffUserForm.error.positionRequired' | translate: locale.language }}
        </mat-error>
        <mat-error *ngIf="form && form.touched && form.get('RoleDescription').hasError('blank')">
          {{ 'StaffUserForm.error.positionInvalid' | translate: locale.language }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="ma2" appearance="fill">
        <mat-label>{{ 'StaffUserForm.field.phone' | translate: locale.language }}</mat-label>
        <input [maxlength]="maxFieldLenght" #phone formControlName="Phone" matInput />
        <mat-hint align="end">{{ phone.value.length }} / {{ maxFieldLenght }}</mat-hint>
        <mat-error *ngIf="form && form.touched && form.get('Phone').hasError('blank')">
          {{ 'StaffUserForm.error.phoneInvalid' | translate: locale.language }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="ma2" appearance="fill">
        <mat-label>{{ 'StaffUserForm.field.email' | translate: locale.language }}</mat-label>
        <input [maxlength]="maxFieldLenght" #email formControlName="Email" matInput required />
        <mat-hint align="end">{{ email.value.length }} / {{ maxFieldLenght }}</mat-hint>
        <mat-error *ngIf="form && form.touched && form.get('Email').hasError('required')">
          {{ 'StaffUserForm.error.emailRequired' | translate: locale.language }}
        </mat-error>
        <mat-error *ngIf="form && form.touched && form.get('Email').hasError('blank')">
          {{ 'StaffUserForm.error.emailInvalid' | translate: locale.language }}
        </mat-error>
        <mat-error *ngIf="form && form.touched && form.get('Email').hasError('email')">
          {{ 'forms.error.invalidEmail' | translate: locale.language }}
        </mat-error>
      </mat-form-field>

      <ng-container *ngIf="pickupPoints$ | async as pickupPoints">
        <mat-form-field class="ma2" appearance="fill" *ngIf="pickupPoints.length">
          <mat-label>{{
            'StaffUserForm.field.pickupPoint' | translate: locale.language
          }}</mat-label>
          <mat-select formControlName="PickupPoint">
            <mat-option>-</mat-option>
            <mat-option *ngFor="let pickupPoint of pickupPoints" [value]="pickupPoint._id">
              {{ pickupPoint.title | selectLanguage: locale.language }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <div
        class="ma2"
        *ngIf="
          formNotificationsField &&
          formNotificationsField.controls &&
          formNotificationsField.controls.length
        "
      >
        <mat-label class="db mb3">{{
          'StaffUserForm.field.notifications' | translate: locale.language
        }}</mat-label>
        <div>
          <hot-manager-notifications
            [withKpi]="false"
            [notifications]="notificationsSettings"
            [types]="notificationTypes"
            (update)="onNotificationChange($event)"
          ></hot-manager-notifications>
        </div>

        <div *ngIf="isShopServiceEnabled" class="mt3">
          <mat-checkbox color="primary" formControlName="IntensiveShopOrderNotification">
            {{ 'StaffUserForm.field.intensiveShopOrderNotification' | translate: locale.language }}
            <mat-icon
              class="ml1 o-30 hot-staff-user-tooltip"
              [matTooltip]="
                'StaffUserForm.label.intensiveShopOrderNotificationTooltip'
                  | translate: locale.language
              "
              >help_outline</mat-icon
            >
          </mat-checkbox>
        </div>
      </div>

      <div class="ma2 mt4">
        <mat-label class="db mb3">{{
          'StaffUserForm.field.workingHours' | translate: locale.language
        }}</mat-label>

        <ng-container *ngIf="workingHoursIsEnabled">
          <hot-working-hours
            [value]="workingHours"
            (change)="onWorkingHoursChange($event)"
          ></hot-working-hours>
        </ng-container>

        <div class="flex items-center flex-wrap">
          <button mat-stroked-button color="primary" (click)="toggleWorkingHours()" class="mr3 mv2">
            {{
              (workingHoursIsEnabled
                ? 'StaffUserForm.action.disableWorkingHours'
                : 'StaffUserForm.action.specifyWorkingHours'
              ) | translate: locale.language
            }}
          </button>
          <mat-label *ngIf="!workingHoursIsEnabled" class="gray mv2">
            {{ 'StaffUserForm.label.userWorkingHoursNotifications' | translate: locale.language }}
          </mat-label>
        </div>
      </div>

      <div *ngIf="canEditRoles | async" class="ma2 mt4" appearance="fill">
        <mat-label class="db mb2">{{
          'StaffUserForm.field.role' | translate: locale.language
        }}</mat-label>
        <mat-button-toggle-group formControlName="Role">
          <mat-button-toggle *ngFor="let role of userRoles" [value]="role.key">
            {{ role.name | translate: locale.language }}
          </mat-button-toggle>
        </mat-button-toggle-group>
        <p class="pre-wrap mt2 black-70">
          {{ selectedRole.description | translate: locale.language }}
        </p>
      </div>
    </div>
  </mat-card>
</div>

<hot-app-footer>
  <button
    class="mr3-ns"
    mat-raised-button
    color="primary"
    [class.o-50]="!enableSubmit"
    (click)="onSubmit()"
  >
    <span class="flex items-center">
      <mat-spinner *ngIf="pending" class="mr3" [diameter]="20"></mat-spinner>
      <ng-container *ngIf="user">
        {{ 'forms.action.save' | translate: locale.language }}
      </ng-container>
      <ng-container *ngIf="!user">
        {{ 'forms.action.create' | translate: locale.language }}
      </ng-container>
    </span>
  </button>
  <button mat-button color="primary" (click)="onCancel()">
    {{ 'forms.action.cancel' | translate: locale.language }}
  </button>
</hot-app-footer>
