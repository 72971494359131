import { Action } from '@ngrx/store'
import { ReviewStatus, NormalizedResults, NormalizedResult } from '@models'
import { ReviewMeta } from '@redux/review/review.reducer'

export enum ActionTypes {
  Load = '[Review] Load',
  LoadSuccess = '[Review] Load success',
  LoadFail = '[Review] Load fail',
  LoadItem = '[Review] Load item',
  LoadItemSuccess = '[Review] Load item success',
  LoadItemFail = '[Review] Load item fail',
  Select = '[Review] Select',
  UpdateStatus = '[Review] Update status',
  UpdateStatusSuccess = '[Review] Update status success',
  UpdateStatusFail = '[Review] Update status fail',
  UpdateMeta = '[Review] Update metadata',
}

/**
 * Select action
 */
export interface SelectPayload {
  reviewId: string
}

export class Select implements Action {
  public readonly type = ActionTypes.Select
  constructor(public payload: SelectPayload) {}
}

/**
 * Load actions
 */
export interface LoadPayload {
  propertyId: string
  status?: ReviewStatus
  page?: number
  limit?: number
}

export class Load implements Action {
  public readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  public readonly type = ActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  public readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load item actions
 */
export interface LoadItemPayload {
  propertyId: string
  reviewId: string
}
export class LoadItem implements Action {
  public readonly type = ActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}
export class LoadItemSuccess implements Action {
  public readonly type = ActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}
export class LoadItemFail implements Action {
  public readonly type = ActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Update status actions
 */
export interface UpdateStatusPayload {
  propertyId: string
  reviewId: string
  status: ReviewStatus
  share: boolean
}
export class UpdateStatus implements Action {
  public readonly type = ActionTypes.UpdateStatus
  constructor(public payload: UpdateStatusPayload) {}
}
export class UpdateStatusSuccess implements Action {
  public readonly type = ActionTypes.UpdateStatusSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateStatusPayload) {}
}
export class UpdateStatusFail implements Action {
  public readonly type = ActionTypes.UpdateStatusFail
  constructor(public error: any, public meta: UpdateStatusPayload) {}
}

export interface UpdateMetaPayload {
  propertyId: string
}
export class UpdateMeta implements Action {
  public readonly type = ActionTypes.UpdateMeta
  constructor(public payload: UpdateMetaPayload, public meta: ReviewMeta) {}
}

export type Actions =
  | Load
  | LoadSuccess
  | LoadFail
  | Select
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | UpdateStatus
  | UpdateStatusSuccess
  | UpdateStatusFail
  | UpdateMeta
