import { AbstractControl } from '@angular/forms'

export const oneOfArrayValidator = (array: any[]) => (control: AbstractControl) => {
  const value = control.value
  if (value && array.indexOf(value) === -1) {
    return {
      oneOfArray: true,
    }
  }
  return null
}
