import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import { ReviewService } from '@redux/review/review.service'

import * as reviewActions from './review.actions'
import { I18nService } from '@shared/services'

@Injectable()
export class ReviewEffects {
  /**
   * Load reviews effects
   */
  @Effect()
  load$ = this.actions$.pipe(
    ofType(reviewActions.ActionTypes.Load),
    switchMap((action: reviewActions.Load) => {
      const meta = action.payload
      return this.reviewService
        .getList(action.payload.propertyId, {
          status: action.payload.status,
          page: action.payload.page,
          limit: action.payload.limit,
        })
        .pipe(
          map(x => new reviewActions.LoadSuccess(x, meta)),
          catchError(x => of(new reviewActions.LoadFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  loadFail$ = this.actions$.pipe(
    ofType(reviewActions.ActionTypes.LoadFail),
    tap(() => {
      const loadError = this.translationService.translate('Review.error.loadError')
      this.matSnackBar.open(loadError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load review item effects
   */
  @Effect()
  loadItem$ = this.actions$.pipe(
    ofType(reviewActions.ActionTypes.LoadItem),
    switchMap((action: reviewActions.LoadItem) => {
      const meta = action.payload
      return this.reviewService.get(action.payload.propertyId, action.payload.reviewId).pipe(
        map(x => new reviewActions.LoadItemSuccess(x, meta)),
        catchError(x => of(new reviewActions.LoadItemFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  loadItemFail$ = this.actions$.pipe(
    ofType(reviewActions.ActionTypes.LoadItemFail),
    tap(() => {
      const loadItemError = this.translationService.translate('Review.error.loadItemError')
      this.matSnackBar.open(loadItemError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Update status effects
   */
  @Effect()
  updateStatus$ = this.actions$.pipe(
    ofType(reviewActions.ActionTypes.UpdateStatus),
    switchMap((action: reviewActions.UpdateStatus) => {
      const meta = action.payload
      return this.reviewService
        .updateStatus(action.payload.propertyId, action.payload.reviewId, {
          status: action.payload.status || null,
          share: action.payload.share || false,
        })
        .pipe(
          map(x => new reviewActions.UpdateStatusSuccess(x, meta)),
          catchError(x => of(new reviewActions.UpdateStatusFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  updateStatusFail$ = this.actions$.pipe(
    ofType(reviewActions.ActionTypes.UpdateStatusFail),
    tap(() => {
      const updateReviewStatusError = this.translationService.translate(
        'Review.error.updateStatusError'
      )
      this.matSnackBar.open(updateReviewStatusError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  public constructor(
    private actions$: Actions,
    private reviewService: ReviewService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
