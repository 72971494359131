import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import * as fromRouter from '@redux/router'
import * as productCategoryActions from './product-category.actions'
import { ProductCategoryService } from './product-category.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class ProductCategoryEffects {
  constructor(
    private actions$: Actions,
    private productCategoryService: ProductCategoryService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}

  // Create

  @Effect()
  create$ = this.actions$.pipe(
    ofType(productCategoryActions.ProductCategoryActionTypes.Create),
    switchMap((action: productCategoryActions.Create) => {
      const meta = action.payload
      return this.productCategoryService
        .create(
          action.payload.propertyId,
          action.payload.roomServiceId,
          action.payload.productCategory,
          action.payload.sourceLanguage,
          action.payload.targetLanguages
        )
        .pipe(
          map(x => {
            return new productCategoryActions.CreateSuccess(x, meta)
          }),
          catchError(x => of(new productCategoryActions.CreateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(productCategoryActions.ProductCategoryActionTypes.CreateSuccess),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.createCategorySuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  createFail$ = this.actions$.pipe(
    ofType(productCategoryActions.ProductCategoryActionTypes.CreateFail),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.createCategoryFail'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  // Update
  @Effect()
  update$ = this.actions$.pipe(
    ofType(productCategoryActions.ProductCategoryActionTypes.Update),
    switchMap((action: productCategoryActions.Update) => {
      const meta = action.payload
      return this.productCategoryService
        .update(
          action.payload.propertyId,
          action.payload.roomServiceId,
          action.payload.productCategoryId,
          action.payload.productCategory,
          action.payload.sourceLanguage,
          action.payload.targetLanguages,
          action.payload.forceTranslate
        )
        .pipe(
          map(x => new productCategoryActions.UpdateSuccess(x, meta)),
          catchError(x => of(new productCategoryActions.UpdateFail(x, meta)))
        )
    }),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.updateCategorySuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  // Delete
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(productCategoryActions.ProductCategoryActionTypes.Delete),
    switchMap((action: productCategoryActions.Delete) => {
      const meta = action.payload
      return this.productCategoryService
        .delete(
          action.payload.propertyId,
          action.payload.roomServiceId,
          action.payload.productCategoryId
        )
        .pipe(
          map(x => new productCategoryActions.DeleteSuccess(x, meta)),
          catchError(x => of(new productCategoryActions.DeleteFail(x, meta)))
        )
    })
  )

  @Effect()
  public deleteSuccess$ = this.actions$.pipe(
    ofType(productCategoryActions.ProductCategoryActionTypes.DeleteSuccess),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.deleteCategorySuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    }),
    map((action: productCategoryActions.DeleteSuccess) => {
      return new fromRouter.Go({
        path: ['properties', action.meta.propertyId, 'settings', 'room-service'],
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(productCategoryActions.ProductCategoryActionTypes.DeleteFail),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.deleteCategoryFail'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )
}
