import { Directive, Input, forwardRef, ElementRef, OnChanges, Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { distinctUntilChanged, shareReplay, map } from 'rxjs/operators'

export enum ThemeKind {
  Light = 'light',
  Dark = 'dark',
}

interface ThemeContextInterface {
  readonly theme: ThemeKind
  readonly theme$: Observable<ThemeKind>
  readonly colors$: Observable<any>
}

@Injectable()
export class ThemeContext implements ThemeContextInterface {
  theme = ThemeKind.Light
  readonly theme$ = of(ThemeKind.Light).pipe(shareReplay(1))
  readonly colors$ = of({ text: '#08f' }).pipe(shareReplay(1))
}

@Directive({
  selector: '[hotTheme]',
  exportAs: 'hotTheme',
  providers: [{ provide: ThemeContext, useExisting: forwardRef(() => ThemeDirective) }],
})
export class ThemeDirective implements ThemeContextInterface, OnChanges {
  @Input() set hotTheme(value: string) {
    this._theme.next(value === 'dark' ? ThemeKind.Dark : ThemeKind.Light)
  }
  private _theme = new BehaviorSubject(ThemeKind.Light)
  public theme$ = this._theme.pipe(
    distinctUntilChanged(),
    shareReplay(1)
  )

  public colors$ = this.theme$.pipe(
    map(x => {
      return x === ThemeKind.Light ? { text: '#000' } : { text: '#fff' }
    })
  )

  public get theme() {
    return this._theme.value
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {}
  ngOnChanges(): void {
    if (this.theme === ThemeKind.Dark) {
      this.elementRef.nativeElement.classList.add('T--dark')
      this.elementRef.nativeElement.classList.remove(`T--light`)
    } else {
      this.elementRef.nativeElement.classList.add('T--light')
      this.elementRef.nativeElement.classList.remove(`T--dark`)
    }
  }
}
