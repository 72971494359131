import { AnalyticsService } from './analytics/analytics.service'
import { BuilderService } from './builder/builder.service'
import { ChatService } from './chat/chat.service'
import { CuisineService } from './cuisine/cuisine.service'
import { DashboardService } from './dashboard/dashboard.service'
import { FaqService } from './faq/faq.service'
import { FormResponseService } from './form-response/form-response.service'
import { FormService } from './form/form.service'
import { MenuPagesTemplatesService } from './menu-pages-templates/menu-pages-templates.service'
import { PollService } from './poll/poll.service'
import { PropertyService } from './property/property.service'
import { PushSubscriptionService } from './push-subscription/push-subscription.service'
import { PushService } from './push/push.service'
import { ReservationService } from './reservation/reservation.service'
import { RestaurantService } from './restaurant/restaurant.service'
import { ReviewService } from './review/review.service'
import { ShopService } from './shop/shop.service'
import { ShopProductService } from './shop-product/shop-product.service'
import { ShopProductCategoryService } from './shop-product-category/shop-product-category.service'
import { RoomServiceService } from './room-service/room-service.service'
import { ProductCategoryService } from './product-category/product-category.service'
import { ProductService } from './product/product.service'
import { CartService } from './cart/cart.service'
import { ShopCartService } from './shop-cart/shop-cart.service'
import { UserService } from './user/user.service'
import { WifiService } from './wifi/wifi.service'
import { MerchantService } from '@redux/merchant/merchant.service'
import { LegalEntityService } from '@redux/legal-entity/legal-entity.service'
import { SalesServiceService } from '@redux/sales-service/sales-service.service'
import { InvoiceService } from '@redux/invoice/invoice.service'

export const SERVICES = [
  AnalyticsService,
  BuilderService,
  ChatService,
  FaqService,
  FormService,
  FormResponseService,
  PropertyService,
  UserService,
  PushService,
  MenuPagesTemplatesService,
  DashboardService,
  WifiService,
  PollService,
  PushSubscriptionService,
  ReservationService,
  RestaurantService,
  ReviewService,
  ShopService,
  ShopProductService,
  ShopProductCategoryService,
  RoomServiceService,
  ProductCategoryService,
  ProductService,
  CartService,
  ShopCartService,
  CuisineService,
  MerchantService,
  LegalEntityService,
  SalesServiceService,
  InvoiceService,
]
