import { Action } from '@ngrx/store'

import { ShopCartStatus, NormalizedResults, NormalizedResult, ShopServiceStatistics } from '@models'
import { ShopCartMeta } from '@redux/shop-cart/shop-cart.reducer'

export enum ActionTypes {
  Select = '[ShopCart] Select cart',
  Load = '[ShopCart] Load',
  LoadSuccess = '[ShopCart] Load Success',
  LoadFail = '[ShopCart] Load Fail',
  LoadItem = '[ShopCart] Load Item',
  LoadItemSuccess = '[ShopCart] Load Item Success',
  LoadItemFail = '[ShopCart] Load Item Fail',
  UpdateStatus = '[ShopCart] Update Status Status',
  UpdateStatusSuccess = '[ShopCart] Update Status Success',
  UpdateStatusFail = '[ShopCart] Update Status Fail',
  UpdateMeta = '[ShopCart] Update meta',
  LoadStatistics = '[ShopCart] Load Statistics',
  LoadStatisticsSuccess = '[ShopCart] Load Statistics Success',
  LoadStatisticsFail = '[ShopCart] Load Statistics Fail',
  CancellationReject = '[ShopCart] Cancellation Reject',
  CancellationRejectSuccess = '[ShopCart] Cancellation Reject Success',
  CancellationRejectFail = '[ShopCart] Cancellation Reject Fail',
  CancellationAccept = '[ShopCart] Cancellation Accept',
  CancellationAcceptSuccess = '[ShopCart] Cancellation Accept Success',
  CancellationAcceptFail = '[ShopCart] Cancellation Accept Fail',
}

/**
 * Select action
 */
export class Select implements Action {
  readonly type = ActionTypes.Select
  constructor(public payload: { shopCartId: string }) {}
}

/**
 * Load actions
 */
export interface LoadPayload {
  propertyId: string
  // status?: string
  status?: ShopCartStatus
  page?: number
  limit?: number
}

export class Load implements Action {
  readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load statistisc actions
 */
export interface LoadStatisticsPayload {
  propertyId: string
  from: string
  to: string
}

export class LoadStatistics implements Action {
  readonly type = ActionTypes.LoadStatistics
  constructor(public payload: LoadStatisticsPayload) {}
}

export class LoadStatisticsSuccess implements Action {
  readonly type = ActionTypes.LoadStatisticsSuccess
  constructor(public payload: ShopServiceStatistics, public meta: LoadStatisticsPayload) {}
}

export class LoadStatisticsFail implements Action {
  readonly type = ActionTypes.LoadStatisticsFail
  constructor(public error: any, public meta: LoadStatisticsPayload) {}
}

/**
 * Load item actions
 */
export interface LoadItemPayload {
  propertyId?: string
  shopCartId?: string
}

export class LoadItem implements Action {
  readonly type = ActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}

export class LoadItemSuccess implements Action {
  readonly type = ActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}

export class LoadItemFail implements Action {
  readonly type = ActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Update status actions
 */
export interface UpdateStatusPayload {
  propertyId: string
  shopCartId: string
  status: ShopCartStatus
  comment: string
}

export class UpdateStatus implements Action {
  readonly type = ActionTypes.UpdateStatus
  constructor(public payload: UpdateStatusPayload) {}
}

export class UpdateStatusSuccess implements Action {
  readonly type = ActionTypes.UpdateStatusSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateStatusPayload) {}
}

export class UpdateStatusFail implements Action {
  readonly type = ActionTypes.UpdateStatusFail
  constructor(public error: any, public meta: UpdateStatusPayload) {}
}

export type CancellationPayload = Pick<UpdateStatusPayload, 'propertyId' | 'shopCartId' | 'comment'>
export class CancellationReject implements Action {
  readonly type = ActionTypes.CancellationReject
  constructor(public payload: CancellationPayload) {}
}

export class CancellationRejectSuccess implements Action {
  readonly type = ActionTypes.CancellationRejectSuccess
  constructor(public payload: NormalizedResult, public meta: CancellationPayload) {}
}

export class CancellationRejectFail implements Action {
  readonly type = ActionTypes.CancellationRejectFail
  constructor(public error: any, public meta: CancellationPayload) {}
}

export class CancellationAccept implements Action {
  readonly type = ActionTypes.CancellationAccept
  constructor(public payload: CancellationPayload) {}
}

export class CancellationAcceptSuccess implements Action {
  readonly type = ActionTypes.CancellationAcceptSuccess
  constructor(public payload: NormalizedResult, public meta: CancellationPayload) {}
}

export class CancellationAcceptFail implements Action {
  readonly type = ActionTypes.CancellationAcceptFail
  constructor(public error: any, public meta: CancellationPayload) {}
}

export interface UpdateMetaPayload {
  propertyId: string
}
export class UpdateMeta implements Action {
  public readonly type = ActionTypes.UpdateMeta
  constructor(public payload: UpdateMetaPayload, public meta: ShopCartMeta) {}
}

export type Actions =
  | Select
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | LoadStatistics
  | LoadStatisticsSuccess
  | LoadStatisticsFail
  | UpdateStatus
  | UpdateStatusSuccess
  | UpdateStatusFail
  | UpdateMeta
