// import { Update } from '@ngrx/entity'
import { Action } from '@ngrx/store'

import { User, NormalizedResult, UserProfile } from '@models'

export enum UserActionTypes {
  Login = '[User] Login',
  LoginSuccess = '[User] Login success',
  LoginFail = '[User] Login fail',

  ResetPassword = '[User] ResetPassword',
  ResetPasswordSuccess = '[User] ResetPassword success',
  ResetPasswordFail = '[User] ResetPassword fail',

  ValidateResetPasswordToken = '[User] ValidateResetPasswordToken',
  ValidateResetPasswordTokenSuccess = '[User] ValidateResetPasswordToken success',
  ValidateResetPasswordTokenFail = '[User] ValidateResetPasswordToken fail',

  SetPassword = '[User] SetPassword',
  SetPasswordSuccess = '[User] SetPassword success',
  SetPasswordFail = '[User] SetPassword fail',

  Logout = '[User] Logout',

  Create = '[User] Create',
  CreateSuccess = '[User] Create Success',
  CreateFail = '[User] Create Fail',

  UpdatePreferences = '[User] Update Preferences',
  UpdatePreferencesSuccess = '[User] Update Preferences Success',
  UpdatePreferencesFail = '[User] Update Preferences Fail',

  Update = '[User] Update',
  UpdateSuccess = '[User] Update Success',
  UpdateFail = '[User] Update Fail',

  Delete = '[User] Delete',
  DeleteSuccess = '[User] Delete Success',
  DeleteFail = '[User] Delete Fail',

  Select = '[User] Select',
}

type OneOfTwo<T1, T2> = (T1 & Partial<T2>) | (T2 & Partial<T1>)
interface UserNotification {
  formId: string
  types: string[]
}
type UserNotifications = UserNotification[]

export class Login implements Action {
  readonly type = UserActionTypes.Login
  constructor(
    public payload: OneOfTwo<{ email: string; password: string }, { accessToken: string }>
  ) {}
}

export class LoginSuccess implements Action {
  readonly type = UserActionTypes.LoginSuccess
  constructor(public payload: { user: UserProfile }) {}
}

export class LoginFail implements Action {
  readonly type = UserActionTypes.LoginFail
  constructor(public error: any) {}
}

export class Logout implements Action {
  readonly type = UserActionTypes.Logout
  constructor() {}
}

export class ResetPassword implements Action {
  readonly type = UserActionTypes.ResetPassword
  constructor(public payload: { email: string }) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = UserActionTypes.ResetPasswordSuccess
  constructor(public payload: any, public meta: any) {}
}

export class ResetPasswordFail implements Action {
  readonly type = UserActionTypes.ResetPasswordFail
  constructor(public error: any) {}
}

export class SetPassword implements Action {
  readonly type = UserActionTypes.SetPassword
  constructor(public payload: { password: string; passwordConfirmation: string; token: string }) {}
}

export class SetPasswordSuccess implements Action {
  readonly type = UserActionTypes.SetPasswordSuccess
  constructor(public payload: any, public meta: any) {}
}

export class SetPasswordFail implements Action {
  readonly type = UserActionTypes.SetPasswordFail
  constructor(public error: any) {}
}

export class ValidateResetPasswordToken implements Action {
  readonly type = UserActionTypes.ValidateResetPasswordToken
  constructor(public payload: { token: string }) {}
}
export class ValidateResetPasswordTokenSuccess implements Action {
  readonly type = UserActionTypes.ValidateResetPasswordTokenSuccess
  constructor(public payload: any, public meta: any) {}
}
export class ValidateResetPasswordTokenFail implements Action {
  readonly type = UserActionTypes.ValidateResetPasswordTokenFail
  constructor(public payload: any, public meta: any) {}
}

/**
 * Create User
 */

export interface CreatePayload {
  propertyId: string
  user: User
  notifications: UserNotifications
  roomServiceNotifications: UserNotification
}

export class Create implements Action {
  readonly type = UserActionTypes.Create
  constructor(public payload: CreatePayload) {}
}
export class CreateSuccess implements Action {
  readonly type = UserActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}
export class CreateFail implements Action {
  readonly type = UserActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Select User
 */

export interface SelectPayload {
  userId: string
}
export class Select implements Action {
  readonly type = UserActionTypes.Select
  constructor(public payload: SelectPayload) {}
}

/**
 * Update User
 */

export interface UpdatePayload {
  propertyId: string
  userId: string
  user: Partial<User>
  notifications?: UserNotifications
  roomServiceNotifications?: UserNotification
}
export class Update implements Action {
  readonly type = UserActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}
export class UpdateSuccess implements Action {
  readonly type = UserActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}
export class UpdateFail implements Action {
  readonly type = UserActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

export class UpdatePreferences implements Action {
  readonly type = UserActionTypes.UpdatePreferences
  constructor(public payload: UpdatePayload) {}
}
export class UpdatePreferencesSuccess implements Action {
  readonly type = UserActionTypes.UpdatePreferencesSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}
export class UpdatePreferencesFail implements Action {
  readonly type = UserActionTypes.UpdatePreferencesFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete User
 */

export interface DeletePayload {
  propertyId: string
  userId: string
}

export class Delete implements Action {
  readonly type = UserActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}
export class DeleteSuccess implements Action {
  readonly type = UserActionTypes.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}
export class DeleteFail implements Action {
  readonly type = UserActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type UserActions =
  | Login
  | LoginSuccess
  | LoginFail
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdatePreferences
  | UpdateSuccess
  | UpdateFail
  | Select
  | Delete
  | DeleteSuccess
  | DeleteFail
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFail
  | SetPassword
  | SetPasswordSuccess
  | SetPasswordFail
  | ValidateResetPasswordToken
  | ValidateResetPasswordTokenSuccess
  | ValidateResetPasswordTokenFail
