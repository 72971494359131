<ng-template [cdkPortalOutlet]="_calendarHeaderPortal"></ng-template>

<div class="mat-calendar-content" [ngSwitch]="currentView" cdkMonitorSubtreeFocus tabindex="-1">
  <hot-month-view
    *ngSwitchCase="'month'"
    [(activeDate)]="activeDate"
    [rangeMode]="rangeMode"
    [selected]="selected"
    [beginDate]="beginDate"
    [endDate]="endDate"
    [closeAfterSelection]="closeAfterSelection"
    [dateFilter]="dateFilter"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [dateClass]="dateClass"
    [beginDateSelected]="beginDateSelected"
    [maxIntervalInDays]="maxIntervalInDays"
    (selectedChange)="_dateSelected($event)"
    (_userSelection)="_userSelected()">
  >
  </hot-month-view>

  <mat-year-view
    *ngSwitchCase="'year'"
    [(activeDate)]="activeDate"
    [selected]="selected"
    [dateFilter]="dateFilter"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (monthSelected)="_monthSelectedInYearView($event)"
    (selectedChange)="_goToDateInView($event, 'month')"
  >
  </mat-year-view>

  <mat-multi-year-view
    *ngSwitchCase="'multi-year'"
    [(activeDate)]="activeDate"
    [selected]="selected"
    [dateFilter]="dateFilter"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (yearSelected)="_yearSelectedInMultiYearView($event)"
    (selectedChange)="_goToDateInView($event, 'year')"
  >
  </mat-multi-year-view>
</div>
