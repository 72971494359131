import { AbstractControl } from '@angular/forms'

export const blankValidator = (control: AbstractControl) => {
  if (control.value && control.value.length !== 0 && control.value.trim() === '') {
    return {
      blank: true,
    }
  }
  return null
}
