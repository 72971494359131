import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  Inject,
} from '@angular/core'
import { MenuContent } from '@models'
import { MenuContentType } from '@models/builder.model'
import { ESCAPE } from '@angular/cdk/keycodes'

const MAX_BODY_LENGTH = 600

@Component({
  selector: 'hot-builder-texts',
  templateUrl: './builder-texts.component.html',
  styleUrls: ['./builder-texts.component.scss'],
})
export class BuilderTextsComponent implements OnChanges {
  @Input() texts: MenuContent[]
  @Input() public currentLanguage: string
  @Input() public availableLanguages: string[]
  @Input() public enableManuallyTranslate = true
  @Input() public isEditMode = false
  @Output() public update = new EventEmitter()
  @Output() public remove = new EventEmitter()
  @Output() public editModeChange = new EventEmitter<boolean>()

  public cache = ''
  public lastEditedIndex = null
  public textAdding = false

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  @HostListener('keydown', ['$event'])
  handleEscape($event) {
    if ($event.keyCode === ESCAPE) {
      if (this.textAdding) {
        this.handleRemove(this.lastEditedIndex)
      } else {
        this.handleCancel(this.lastEditedIndex)
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.currentLanguage && this.lastEditedIndex !== null) {
      const lastEditedText = this.texts[this.lastEditedIndex].translations[this.currentLanguage]
      const lastEditedHasValue = Boolean(lastEditedText)
      if (!lastEditedHasValue) {
        this.handleRemove(this.lastEditedIndex, undefined, true)
        this.lastEditedIndex = null
      }
    }
  }

  handleRemove(index, type = 'texts', noUpdate = false) {
    this.editModeChange.emit(false)
    this.remove.emit({ index, type, noUpdate })
  }

  handleCancel(index) {
    this.texts[index].isEdit = false
    this.editModeChange.emit(false)
  }

  public handleEnter($event, i) {
    if ($event.key === 'Enter' && $event.shiftKey) {
      $event.preventDefault()
      this.textSave(i, this.enableManuallyTranslate && this.textAdding)
    }
  }

  textEdit(index, isNewText = false) {
    if (this.isEditMode) {
      return false
    }

    this.lastEditedIndex = index
    this.texts.map(text => {
      text.isEdit = false
    })
    this.texts[index].isEdit = true
    this.cache = this.texts[index].translations[this.currentLanguage]

    if (!isNewText) {
      this.textAdding = false
    }

    // check if empty text exists and remove it when edit other one
    for (let i = 0; i < this.texts.length; i++) {
      if (!Boolean(this.texts[i].translations[this.currentLanguage]) && i !== index) {
        this.texts.splice(i, 1)
      }
    }
    this.editModeChange.emit(true)
  }

  textSave(index, translate = false) {
    if (!this.isTextValid) {
      return false
    }
    this.lastEditedIndex = null
    this.texts[index].isEdit = false
    this.texts[index].translations[this.currentLanguage] = this.cache
    this.availableLanguages.map(lang => {
      if (!this.texts[index].translations[lang]) {
        this.texts[index].translations[lang] = this.cache
      }
    })
    this.texts[index].needTranslate = translate
    this.cache = ''
    this.editModeChange.emit(false)
    this.update.emit()
  }

  handleAddText(addedText = '') {
    const newText = {
      type: MenuContentType.TEXT,
      translations: {},
    }

    this.availableLanguages.map(lang => {
      newText.translations[lang] = addedText
    })

    const lastAddedIndex = this.texts.push(newText as MenuContent)
    this.cache = addedText
    this.textAdding = true
    this.textEdit(lastAddedIndex - 1, true)
  }

  public get maxBodyLength() {
    return MAX_BODY_LENGTH
  }

  get isTextEdit() {
    return this.texts.some(text => text.isEdit)
  }

  get isTextValid() {
    return this.cache && this.cache.length
  }

  public showRemoveBtn(index) {
    if (this.texts[index].isEdit) {
      return true
    } else {
      return !this.isEditMode
    }
  }

  public addEmoji({ emoji }) {
    this.cache += emoji
  }
}
