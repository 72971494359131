import {
  Component,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Inject,
} from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { UploadFileService, I18nService } from '@shared/services'
import { Store, select } from '@ngrx/store'
import * as fromRoot from '@redux'
import { filter, take } from 'rxjs/operators'
import { UploadFile, UploadFileStatus, UploadFileResult, UploadFilePreset } from '@models'
import * as R from 'ramda'

const DEFAULT_PATH_TYPE = 'images'
const DEFAULT_IMAGE_EXTENSION = 'png'
const DEFAUL_RESIZE_WIDTH = 400
const DEFAULT_ASPECT_RATIO = 4 / 3
const IMAGE_EXTENSIONS = {
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg',
  GIF: 'gif',
}

@Component({
  selector: 'hot-image-cropper',
  templateUrl: './image-cropper.component.html',
  styles: [
    `
      .hot-image-cropper-spinner {
        position: absolute;
        top: 42%;
      }
      .hot-image-cropper-uploading {
        opacity: 0.6;
      }
    `,
  ],
})
export class ImageCropperComponent implements OnInit {
  @Input() public showPlaceholder = true
  @Input() public placeholder = null
  @Input() public resizeToWidth = DEFAUL_RESIZE_WIDTH
  @Input() public aspectRatio = DEFAULT_ASPECT_RATIO
  @Input() public maintainAspectRatio = true
  @Input() public onlyScaleDown = false
  @Input() public preset: UploadFilePreset = null

  @ViewChild('fileInput', { static: true }) public fileInput: ElementRef
  @Output() public change = new EventEmitter<UploadFileResult>()

  public extensions = IMAGE_EXTENSIONS
  public imageChangedEvent = null
  public loaded = false
  public loading = false
  public uploadingToServer = false
  public uploadStatus = null

  private selectedFileName = null
  private selectedImageType = null
  private croppedImage = null
  private propertyId = null
  public uploadImageError: string

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private i18nService: I18nService,
    private uploadFileService: UploadFileService,
    private $store: Store<fromRoot.State>
  ) {}

  ngOnInit() {
    this.$store
      .pipe(
        select(fromRoot.selectCurrentPropertyId),
        filter(Boolean),
        take(1)
      )
      .subscribe(propertyId => (this.propertyId = propertyId))
  }

  public get translatedPlaceholder(): string {
    return this.placeholder || this.i18nService.translate('components.UploadFile.uploadImage')
  }

  public get type(): string {
    return DEFAULT_PATH_TYPE
  }

  public openFileDialog(): void {
    this.uploadStatus = null
    this.fileInput.nativeElement.click()
  }

  public get imageExtension(): string {
    return (
      (this.selectedFileName && this.selectedFileName.split('.').pop()) || DEFAULT_IMAGE_EXTENSION
    )
  }

  public get _showPlaceholder(): boolean {
    return (
      this.showPlaceholder &&
      !this.loaded &&
      !this.loading &&
      !this.uploadingToServer &&
      !this.uploadFailed
    )
  }

  public get enableSubmit(): boolean {
    return this.loaded
  }

  public get isUploading(): boolean {
    return this.loading || this.uploadingToServer
  }

  public get uploadFailed(): boolean {
    return this.uploadStatus === UploadFileStatus.FAILED
  }

  public onCancel($event): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.cancelUploadImage()
  }

  public cancelUploadImage(): void {
    this.imageChangedEvent = null
    this.selectedFileName = null
    this.selectedImageType = null
    this.croppedImage = null
    this.fileInput.nativeElement.value = ''
    this.loaded = false
    this.loading = false
    this.uploadingToServer = false
  }

  public onSave($event): void {
    $event.preventDefault()
    $event.stopPropagation()
    const uploadUrl = this.propertyId ? `${this.propertyId}/${this.type}` : `${this.type}`
    const file = new File([this.croppedImage], this.selectedFileName, {
      type: this.selectedImageType,
    })
    this.uploadingToServer = true
    this.uploadFileService.upload(file, uploadUrl, this.preset).subscribe(
      (x: UploadFile) => {
        this.uploadingToServer = false
        this.uploadStatus = UploadFileStatus.COMPLETED
        this.change.next({ status: UploadFileStatus.COMPLETED, data: x })
      },
      _ => {
        this.cancelUploadImage()
        this.uploadStatus = UploadFileStatus.FAILED
        this.uploadImageError = this.i18nService.translate(
          'components.UploadFile.uploadingImageError'
        )
        this.change.next({ status: UploadFileStatus.FAILED })
      }
    )
  }

  private allowedExtension(fileExtension) {
    if (fileExtension && !this.extensions[R.toUpper(fileExtension)]) {
      this.uploadStatus = UploadFileStatus.FAILED
      this.uploadImageError = this.i18nService.translate(
        'components.UploadFile.imageExtensionError'
      )
      return false
    }
    return true
  }

  public fileChangeEvent(event: any): void {
    const file = (event && event.target.files && event && event.target.files[0]) || null
    const fileExtension = file.name.split('.').pop()

    if (!this.allowedExtension(fileExtension)) {
      return
    }

    this.loading = true
    this.imageChangedEvent = event
    this.selectedFileName = file && file.name
    this.selectedImageType = file && file.type
  }

  public imageCroppedFile($event): void {
    this.croppedImage = $event
  }

  public imageLoaded() {
    this.loaded = true
    this.loading = false
  }
}
