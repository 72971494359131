import { LanguageType, SegmentType } from '@shared/constants'

import { PaginationOptions } from './pagination-options'
import { TranslatableField } from './translatable-field'
import { User } from './user.model'
import { MenuButton } from './builder.model'

export enum PushStatus {
  DRAFT = 'Draft',
  SENT = 'Sent',
  PENDING = 'Pending',
}

export enum PushSendType {
  IMMEDIATELY = 'immediately',
  DEFERRED = 'deferred',
}

// Old format
export class PushButton {
  title: TranslatableField
  type: 'web_url'
  body?: string
}
const isPushButton = (button: any): button is PushButton => {
  return button && button.title
}

export const getButtonTitleForLang = (button: MenuButton | PushButton, lang = LanguageType.EN) => {
  if (isPushButton(button)) {
    // Old PUSH button
    return button.title[lang] || button.title.default
  }

  const translation =
    button && button.translations && (button.translations[lang] || button.translations.en)
  return (translation && translation.title) || null
}

type ISODate = string

export interface PushFilters {
  roomNumber?: string
  activeFrom?: ISODate
  activeTo?: ISODate
}

export interface PushListFilters {
  status?: PushStatus
  paginationOptions?: PaginationOptions
}

export interface PushConfig {
  filters?: PushFilters
}

export interface Push {
  _id?: string
  title: string
  lang: string
  text?: string
  target: SegmentType
  user?: string
  photo?: string
  pushMessageNumber?: number
  sentAt?: Date
  status?: PushStatus
  // array of relative chats ids
  chats?: string[]
  sendType?: PushSendType
  sendDate?: ISODate
  buttons?: MenuButton[]
  config?: PushConfig
  // fields to populate
  __user?: User
}
