<hot-builder-button-editor
  [buttonAdding]="data.buttonAdding"
  [enableManuallyTranslate]="data.enableManuallyTranslate"
  [currentLanguage]="data.currentLanguage"
  [canSetVisibilityPeriod]="data.canSetVisibilityPeriod"
  [addPageEnabled]="data.addPageEnabled"
  [cache]="data.cache"
  [builders]="data.builders"
  [forms]="data.forms"
  [shopCategories]="data.shopCategories"
  [shopProducts]="data.shopProducts"
  [polls]="data.polls"
  [templates]="data.templates"
  [availableModules]="data.availableModules"
  [availableLanguages]="data.availableLanguages"
  [buttons]="data.buttons"
  [buttonIndex]="data.buttonIndex"
  (save)="onSave()"
  (cancel)="onCancel()"
  (update)="onUpdate($event)"
  (navigate)="data.navigate.emit($event)"
  (createNewPage)="onCreateNewPage($event)"
>
</hot-builder-button-editor>
