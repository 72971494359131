import { Injectable } from '@angular/core'
import { fromEvent, merge } from 'rxjs'
import {
  map,
  startWith,
  throttleTime,
  share,
  shareReplay,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators'

function getWindow(): any {
  return window
}

const em = num => num * 16

@Injectable()
export class WindowRefService {
  public hasTouch = 'ontouch' in document

  public resize$ = fromEvent(window, 'resize').pipe(share())
  public media$ = merge(
    this.resize$.pipe(throttleTime(500)),
    this.resize$.pipe(debounceTime(100))
  ).pipe(
    startWith({}),
    map(() => {
      const w = window.innerWidth
      const h = window.innerHeight
      return {
        width: w,
        height: h,
        isSmall: w < em(40),
        isNotSmall: w >= em(40),
        isMedium: w >= em(40) && w < em(60),
        isLarge: w >= em(60),
      }
    }),
    shareReplay()
  )

  public isSmall$ = this.media$.pipe(
    map(x => x.isSmall),
    distinctUntilChanged(),
    shareReplay()
  )
  public isNotSmall$ = this.media$.pipe(
    map(x => x.isNotSmall),
    distinctUntilChanged(),
    shareReplay()
  )
  public isMedium$ = this.media$.pipe(
    map(x => x.isMedium),
    distinctUntilChanged(),
    shareReplay()
  )
  public isLarge$ = this.media$.pipe(
    map(x => x.isLarge),
    distinctUntilChanged(),
    shareReplay()
  )

  // public size$ = fromEvent()
  public get windowRef(): any {
    return getWindow()
  }

  public get localStorage(): Storage {
    return this.windowRef.localStorage
  }
}
