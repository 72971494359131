import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'

import { Store, select } from '@ngrx/store'

import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

import * as fromRoot from '@redux'
import * as fromMenuPagesTemplates from '@redux/menu-pages-templates'

@Injectable()
export class LoadMenuPagesTemplatesGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}

  public canActivate(): Observable<boolean> {
    this.store$.dispatch(new fromMenuPagesTemplates.Load())

    return this.store$.pipe(select(fromRoot.selectMenuPagesTemplatesLoaded), filter(Boolean))
  }
}
