import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { NormalizedResults, NormalizedResult } from '@models'
import { ApiService } from '@shared/services'

@Injectable()
export class ReviewService {
  public getList(propertyId: string, params?: any): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/reviews`, {
      params: {
        status: params.status || '',
        page: params.page,
        limit: params.limit,
      },
    })
  }

  public get(propertyId: string, reviewId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/reviews/${reviewId}`)
  }

  public updateStatus(
    propertyId: string,
    reviewId: string,
    { status, share = false }
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/reviews/${reviewId}`, {
      status: status,
      share: share,
    })
  }

  public constructor(private apiService: ApiService) {}
}
