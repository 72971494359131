import { Actions, ActionTypes } from './analytics.actions'
import { Analytics, ShopServiceStatistics } from '@models'

export interface State {
  loaded: boolean
  loading: boolean
  filters: {
    propertyId?: string
  }
  analytics?: Analytics | {}
  shopStatistics?: ShopServiceStatistics
}

export const initialState: State = {
  loaded: false,
  loading: false,
  filters: {},
  analytics: {},
}

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.Load: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }
    case ActionTypes.LoadSuccess: {
      const payload = action.payload
      return {
        ...state,
        analytics: payload,
        loaded: true,
        loading: false,
      }
    }
    case ActionTypes.LoadShop: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }
    case ActionTypes.LoadShopSuccess: {
      const payload = action.payload
      return {
        ...state,
        shopStatistics: payload,
        loaded: true,
        loading: false,
      }
    }

    default: {
      return state
    }
  }
}

export const selectAnalytics = (state: State) => state && state.analytics
export const selectShopStatistics = (state: State) => state && state.shopStatistics
export const selectAnalyticsLoaded = (state: State) => state && state.loaded
export const selectAnalyticsLoading = (state: State) => state && state.loading
