import { Component, OnInit, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'hot-page-subheading',
  template: `
  <div class="wrap"
    [style.top]="top"
    [style.height]="height"
    [style.align-items]="alignItems">
    <ng-content></ng-content>
  </div>
  `,
  styleUrls: ['./page-subheading.component.scss'],
})
export class PageSubheadingComponent implements OnInit {
  @HostBinding('class.withoutOffset')
  @Input()
  withoutOffset = false

  @HostBinding('style.height')
  @Input()
  height = '40px'

  @Input() top = '80px'
  @Input() alignItems = 'center'

  constructor() {}

  ngOnInit() {}
}
