<div class="hot-builder-title pr4">
  <div class="hot-builder-title-text w-100" *ngIf="!isNameEdit" (click)="nameEdit()">
    {{ name[currentLanguage] }}
  </div>
  <div class="w-100 relative" *ngIf="isNameEdit">
    <hot-emoji-picker
      class="hot-builder__emoji-picker"
      (emojiSelect)="addEmoji($event)"
    ></hot-emoji-picker>
    <mat-form-field class="w-100 hot-emoji-input-container">
      <mat-label>{{ 'MenuBuilder.field.pageTitle' | translate: locale.language }}</mat-label>
      <input
        class="pr4"
        matInput
        name="menuTitle"
        required
        hotAutofocus
        [maxlength]="maxNameLength"
        ngModel
        #menuTitle="ngModel"
        [(ngModel)]="cache"
        (input)="cache = $event.target.value"
        [value]="cache"
        (keyup.enter)="nameSave(false)"
      />
      <mat-hint align="end">{{ cache.length }} / {{ maxNameLength }}</mat-hint>
      <mat-error *ngIf="menuTitle?.errors && menuTitle?.errors.required">
        {{ 'MenuBuilder.error.pageTitleRequired' | translate: locale.language }}
      </mat-error>
    </mat-form-field>
    <div class="tr mt3">
      <button
        mat-raised-button
        color="primary"
        [disabled]="menuTitle?.errors"
        (click)="nameSave(false)"
      >
        {{ 'forms.action.save' | translate: locale.language }}
      </button>
      <button
        *ngIf="enableManuallyTranslate"
        mat-raised-button
        class="ml3"
        color="primary"
        [disabled]="menuTitle?.errors"
        (click)="nameSave(true)"
      >
        {{ 'forms.action.saveAndTranslate' | translate: locale.language }}
      </button>
    </div>
  </div>

  <div class="overlay-button--edit" *ngIf="!isEditMode">
    <mat-icon
      class="hot-bulder-item-edit notranslate"
      (click)="nameEdit()"
      [matTooltip]="'forms.action.edit' | translate: locale.language"
      matTooltipPosition="right"
      >edit</mat-icon
    >
  </div>
  <mat-icon
    *ngIf="isNameEdit"
    class="hot-bulder-item-success notranslate"
    (click)="cancelEdit()"
    [matTooltip]="'forms.action.cancel' | translate: locale.language"
    matTooltipPosition="right"
    >close</mat-icon
  >
</div>
