import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { isNil } from 'ramda'

export const emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (!isNil(Validators.required(control))) {
    return null
  }

  const v: string = control.value
  // tslint:disable-next-line: max-line-length
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v
  )
    ? null
    : { email: true }
}
