import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Inject } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import { LegalEntity } from '@models/legal-entity.model'
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators'
import * as R from 'ramda'
import { SalesServiceSettings } from '@models/sales-service-settings.model'
import * as moment from 'moment'
import { IMerchantShort } from '@redux/sales-service/sales-service.service'
import { translatableTextValidator } from '@shared/validators'

const REPORT_INTERVALS = [
  { title: '1 day', value: '1d' },
  { title: '2 days', value: '2d' },
  { title: '3 days', value: '3d' },
  { title: '4 days', value: '4d' },
  { title: '5 days', value: '5d' },
  { title: '6 days', value: '6d' },
  { title: '7 days', value: '7d' },
  { title: '10 days', value: '10d' },
  { title: '14 days', value: '14d' },
  { title: '21 days', value: '21d' },
  { title: '30 days', value: '30d' },
]
enum PaymentProvider {
  HOTBOT = 'HotbotPaymentService',
  MERCADO_PAGO = 'MercadoPago',
}

export enum VatType {
  none = 'none',
  ru_vat20 = 'ru_vat20',
  ru_vat10 = 'ru_vat10',
  ru_vat110 = 'ru_vat110',
  ru_vat120 = 'ru_vat120',
  ru_vat0 = 'ru_vat0',
}

@Component({
  selector: 'hot-sales-service-settings-form',
  templateUrl: './sales-service-settings.component.html',
})
export class SalesServiceSettingsComponent implements OnInit, OnDestroy {
  @Input() legalEntities: LegalEntity[]
  @Input() salesServiceSettings: SalesServiceSettings
  @Input() currencies: { title: string; value: string }[]
  @Input() merchants: IMerchantShort[]

  @Output() submit = new EventEmitter()
  @Output() cancel = new EventEmitter()
  form = new FormGroup({})
  legalEntityForm = new FormGroup({})
  filteredLegalEntities$: Observable<LegalEntity[]>
  destroyed$ = new Subject()

  public merchantProviders = R.values(PaymentProvider)
  public vatTypes = R.values(VatType)
  public selectedMerchantProvider: string
  private reportDate: moment.Moment
  private contractDate: moment.Moment

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm()
    if (this.salesServiceSettings) {
      this.updateForm()
    } else {
      this.reportDate = moment().startOf('day')
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  displayLegalEntityItemFn(item: LegalEntity): string {
    return R.propOr('', 'name', item)
  }

  get reportIntervals(): Array<{ title: string; value: string }> {
    return REPORT_INTERVALS
  }

  onFormCancel() {
    this.cancel.next()
  }

  onFormSubmit() {
    if (this.enableSubmit) {
      this.updateData()
    }
  }

  public get enableSubmit(): boolean {
    return this.form && this.form.valid && this.form.dirty && !R.isEmpty(this.reportDate)
  }

  public onDateChange({ value }) {
    this.reportDate = value
  }

  public onContractDateChange({ value }) {
    this.contractDate = value
  }

  public onMerchantProviderChange(value) {
    this.selectedMerchantProvider = value
  }

  private initializeForm() {
    this.legalEntityForm = this.fb.group({
      LegalEntity: new FormControl('', Validators.compose([Validators.required])),
    })
    this.form = this.fb.group({
      Enabled: false,
      LegalEntity: this.legalEntityForm,
      AgentFeeValue: new FormControl(
        5,
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      AgentFeeType: new FormControl('percent', Validators.compose([Validators.required])),

      AgentReturnFeeValue: new FormControl(
        2.5,
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      AgentReturnFeeType: new FormControl('percent', Validators.compose([Validators.required])),

      CustomerReturnFeeValue: new FormControl(
        0,
        Validators.compose([Validators.required, Validators.min(0)])
      ),
      CustomerReturnFeeType: new FormControl('percent', Validators.compose([Validators.required])),

      MerchantProvider: new FormControl('', Validators.compose([Validators.required])),
      MerchantAlias: new FormControl(''),

      Email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      ReportInterval: new FormControl('', Validators.compose([Validators.required])),
      NextReportDate: new FormControl(moment(), Validators.compose([Validators.required])),
      NextReportTime: new FormControl(
        moment().format('hh:mm'),
        Validators.compose([Validators.required, Validators.pattern(/^\d{1,2}:\d{2}$/)])
      ),

      IncomingInvoiceCounter: new FormControl(
        1,
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      IncomingInvoicePrefix: '',

      OutgoingInvoiceCounter: new FormControl(
        1,
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      OutgoingInvoicePrefix: '',

      Currency: new FormControl('rub', Validators.compose([Validators.required])),
      ContractNumber: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      ContractDate: new FormControl(null, Validators.compose([Validators.required])),
      OfferLink: new FormControl([], translatableTextValidator),
      SandboxMode: new FormControl(false),
      MerchantPublicKey: new FormControl(''),
      MerchantPrivateKey: new FormControl(''),
      DefaultVatType: new FormControl(null),
    })

    this.filteredLegalEntities$ = this.legalEntityForm.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      distinctUntilChanged(),
      filter(Boolean),
      map(value => {
        return this._filter(value.LegalEntity)
      })
    )
  }

  private updateForm() {
    let agentFeeValue = Number(R.propOr('0', 'value', this.salesServiceSettings.agentFee))
    const agentFeeType = R.propOr('percent', 'type', this.salesServiceSettings.agentFee)
    if (agentFeeType === 'percent') {
      agentFeeValue = Number((agentFeeValue * 100).toFixed(2))
    }

    let agentReturnFeeValue = Number(
      R.propOr('0', 'value', this.salesServiceSettings.agentReturnFee)
    )
    const agentReturnFeeType = R.propOr('percent', 'type', this.salesServiceSettings.agentReturnFee)
    if (agentReturnFeeType === 'percent') {
      agentReturnFeeValue = Number((agentReturnFeeValue * 100).toFixed(2))
    }

    let customerReturnFeeValue = Number(
      R.propOr('0', 'value', this.salesServiceSettings.customerReturnFee)
    )
    const customerReturnFeeType = R.propOr(
      'percent',
      'type',
      this.salesServiceSettings.customerReturnFee
    )
    if (customerReturnFeeType === 'percent') {
      customerReturnFeeValue = Number((customerReturnFeeValue * 100).toFixed(2))
    }
    this.selectedMerchantProvider = R.propOr(
      PaymentProvider.HOTBOT,
      'merchantProvider',
      this.salesServiceSettings
    )
    const formData = {
      Enabled: R.propOr(false, 'enabled', this.salesServiceSettings),
      AgentFeeType: agentFeeType,
      AgentFeeValue: agentFeeValue,
      AgentReturnFeeType: agentReturnFeeType,
      AgentReturnFeeValue: agentReturnFeeValue,
      CustomerReturnFeeType: customerReturnFeeType,
      CustomerReturnFeeValue: customerReturnFeeValue,
      MerchantProvider: this.selectedMerchantProvider,
      MerchantAlias: R.propOr('', 'merchant', this.salesServiceSettings),
      Email: R.path(['reportOptions', 'email'], this.salesServiceSettings),
      ReportInterval: R.path(['reportOptions', 'interval'], this.salesServiceSettings),
      NextReportDate: moment(
        R.path(['reportOptions', 'nextReportTime'], this.salesServiceSettings)
      ).startOf('day'),
      NextReportTime: moment(
        R.path(['reportOptions', 'nextReportTime'], this.salesServiceSettings)
      ).format('hh:mm'),

      IncomingInvoiceCounter: Number(
        R.pathOr('0', ['incomingInvoice', 'counter'], this.salesServiceSettings)
      ),
      IncomingInvoicePrefix: R.pathOr(
        '0',
        ['incomingInvoice', 'prefix'],
        this.salesServiceSettings
      ),

      OutgoingInvoiceCounter: Number(
        R.pathOr('0', ['outgoingInvoice', 'counter'], this.salesServiceSettings)
      ),
      OutgoingInvoicePrefix: R.pathOr(
        '0',
        ['outgoingInvoice', 'prefix'],
        this.salesServiceSettings
      ),

      Currency: R.propOr('rub', 'currency', this.salesServiceSettings),
      ContractNumber: R.prop('contractNumber', this.salesServiceSettings),
      ContractDate: moment(R.prop('contractDate', this.salesServiceSettings)).startOf('day'),
      OfferLink: this.salesServiceSettings.offerLink,
      SandboxMode: R.path(['merchantConfig', 'sandboxEnabled'], this.salesServiceSettings),
      MerchantPublicKey: R.path(['merchantConfig', 'publicKey'], this.salesServiceSettings),
      MerchantPrivateKey: R.path(['merchantConfig', 'privateKey'], this.salesServiceSettings),
      DefaultVatType: R.path(['defaultVatType'], this.salesServiceSettings),
    }
    const legalFormData = {
      LegalEntity: this.legalEntities.filter(
        x => x._id === this.salesServiceSettings.legalEntity
      )[0],
    }
    this.reportDate = moment(this.salesServiceSettings.reportOptions.nextReportTime).startOf('day')
    this.contractDate = moment(this.salesServiceSettings.contractDate).startOf('day')

    this.form.patchValue(formData)
    this.legalEntityForm.patchValue(legalFormData)
  }

  private updateData() {
    const data = this.form.value
    const legalEntityData = this.legalEntityForm.value
    const [reportHours, reportMinutes] = data.NextReportTime.split(':').map(x => Number(x))

    const salesSettings: SalesServiceSettings = {
      _id: R.propOr(undefined, '_id', this.salesServiceSettings),
      reportOptions: {
        email: data.Email,
        interval: data.ReportInterval,
        nextReportTime: this.reportDate
          .hours(reportHours)
          .minutes(reportMinutes)
          .toISOString(true),
      },
      legalEntity: legalEntityData.LegalEntity._id,
      agentFee: {
        type: data.AgentFeeType,
        value: data.AgentFeeType === 'fixed' ? data.AgentFeeValue : data.AgentFeeValue / 100,
      },
      agentReturnFee: {
        type: data.AgentReturnFeeType,
        value:
          data.AgentReturnFeeType === 'fixed'
            ? data.AgentReturnFeeValue
            : data.AgentReturnFeeValue / 100,
      },
      customerReturnFee: {
        type: data.CustomerReturnFeeType,
        value:
          data.CustomerReturnFeeType === 'fixed'
            ? data.CustomerReturnFeeValue
            : data.CustomerReturnFeeValue / 100,
      },
      enabled: data.Enabled,
      currency: data.Currency,
      merchant: data.MerchantAlias,
      merchantProvider: data.MerchantProvider,
      incomingInvoice: {
        counter: data.IncomingInvoiceCounter,
        prefix: data.IncomingInvoicePrefix,
      },
      outgoingInvoice: {
        counter: data.OutgoingInvoiceCounter,
        prefix: data.OutgoingInvoicePrefix,
      },
      contractNumber: data.ContractNumber,
      contractDate: this.contractDate.format('YYYY-MM-DD'),
      offerLink: data.OfferLink,
      merchantConfig: {
        privateKey: data.MerchantPrivateKey,
        publicKey: data.MerchantPublicKey,
        sandboxEnabled: data.SandboxMode,
      },
      defaultVatType: data.DefaultVatType,
    }
    this.submit.next({ salesServiceSettings: salesSettings })
    this.form.markAsPristine()
    this.form.markAsTouched()
  }

  private _filter(value: string | LegalEntity): LegalEntity[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase()
    return this.legalEntities.filter(x => x.name.toLowerCase().includes(filterValue))
  }
}
