export enum MERCHANT_PAYMENT_WAY {
  CARD = 'card',
  APPLEPAY = 'applepay',
  GPAY = 'gpay',
}

export type MERCHANT_CAPABILITIES_APPLEPAY = 'supports3DS'
export enum MERCHANT_PAYMENT_SYSTEM {
  VISA = 'visa',
  MASTERCARD = 'masterCard',
  MIR = 'mir',
}

interface IMerchantPaymentWayCommon {
  validation: boolean
  enabled: boolean
}

export interface IMerchantPaymentWays {
  [MERCHANT_PAYMENT_WAY.APPLEPAY]?: IMerchantPaymentWayCommon & {
    merchant: {
      id: string
      domain: string
      certificate: {
        key: string
        cert: string
      }
    }
    options: {
      version: 3
      countryCode: 'RU'
      supportedNetworks: MERCHANT_PAYMENT_SYSTEM[]
      merchantCapabilities?: MERCHANT_CAPABILITIES_APPLEPAY[]
    }
  }
  [MERCHANT_PAYMENT_WAY.CARD]?: IMerchantPaymentWayCommon
  [MERCHANT_PAYMENT_WAY.GPAY]?: IMerchantPaymentWayCommon
}

export interface IMerchantProtoConnection {
  merchant?: string
  endpoint: string
  credentials: {
    login: string
    password: string
  }
}

export interface Merchant {
  _id?: string
  account: string
  alias: string
  name: string
  merchantProto: 'RBS'
  merchantSettings: IMerchantProtoConnection
  paymentWays: IMerchantPaymentWays
}
