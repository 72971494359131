import { AbstractControl, ValidationErrors } from '@angular/forms'

export const jsonValidator = (control: AbstractControl) => {
  if (control && control.value) {
    const error: ValidationErrors = { json: true }
    try {
      JSON.parse(control.value)
    } catch (e) {
      error.message = e.message
      return error
    }
  }
  return null
}
