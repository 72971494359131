import { Injectable } from '@angular/core'
import { NormalizedResults, NormalizedResult } from '@models'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'

@Injectable()
export class PollService {
  public getList(propertyId: string): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/polls`)
  }

  public get(propertyId: string, pollId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/polls/${pollId}`)
  }

  public create(propertyId: string, data: any): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/polls`, data)
  }

  public save(propertyId: string, pollId: string, data: any): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/polls/${pollId}`, data)
  }

  public delete(propertyId: string, pollId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`properties/${propertyId}/polls/${pollId}`)
  }

  public stop(propertyId: string, pollId: string): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/polls/${pollId}/stop`, {})
  }

  public resume(propertyId: string, pollId: string): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/polls/${pollId}/resume`, {})
  }

  constructor(private apiService: ApiService) {}
}
