import { Component, OnInit, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'hot-page-content',
  template: `
    <ng-content></ng-content>
  `,
  // template: `<div class="flex flex-column flex-auto pa4"></div>`,
  styleUrls: [`./page-content.component.scss`],
})
export class PageContentComponent implements OnInit {
  @HostBinding('class.is-padded')
  @Input()
  public padded = true

  @HostBinding('class.is-fixed')
  @Input()
  public fixed = false

  @HostBinding('class.flex')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-auto')
  @Input()
  public flex = true

  @HostBinding('class.page-has-heading')
  @Input()
  public hasHeading = true

  constructor() {}

  ngOnInit() {}
}
