<!--
<hot-form-editor-field
  class="flex-auto"
  [formField]="item.value"
  [language]="language"
  (changed)="updateField(i, $event)"
  (deleted)=deleteField(i)>
</hot-form-editor-field>
    -->

<div class="relative">
  <button
    *ngIf="!showEntities"
    mat-button
    color="primary"
    (click)="showEntities = true"
    class="absolute top--2 nt2 right-0 pa0 h2 lh-solid"
  >
    <mat-icon class="small">add</mat-icon>
    <mat-icon>psychology</mat-icon>
    <!-- <mat-icon svgIcon="hot:ai" class="h1 w1"></mat-icon> -->
  </button>
  <div
    class="form"
    [formGroup]="form"
    [sortablejs]="answers"
    [sortablejsOptions]="{ handle: '.drag-handle' }"
  >
    <div
      formArrayName="options"
      class="mb3 draggable"
      *ngFor="let item of answers.controls; let i = index; first as isFirst; last as isLast"
    >
      <div [formGroupName]="i" class="mb1">
        <div class="answer-field flex br2 items-stretch">
          <div class="drag-handle pv2 ph1 black-50 flex items-center">
            <mat-icon>drag_indicator</mat-icon>
          </div>
          <div class="pv1 flex items-stretch flex-auto">
            <input
              type="text"
              #answerInput
              class="label-input w-100 br2"
              formControlName="label"
              [placeholder]="'FormQuestion.error.enterText' | translate: locale.language"
              hotTranslatableText
              [language]="language"
              [defaultLanguage]="defaultLanguage"
              (keyup.enter)="addAnswer()"
            />
            <input
              *ngIf="showIdInputs"
              [placeholder]="'FormQuestion.field.idPlaceholder' | translate: locale.language"
              type="text"
              #answerIdInput
              class="label-input w-50 br2 ml1"
              formControlName="value"
            />
            <input
              *ngIf="showEntities"
              placeholder="Entities"
              type="text"
              class="label-input w-100 br2 ml1"
              formControlName="entities"
            />
          </div>
          <div
            class="button--remove flex items-stretch pa1 pointer"
            (click)="deleteAnswer(i)"
            [matTooltip]="'FormQuestion.options.action.deleteOption' | translate: locale.language"
            matTooltipPosition="right"
          >
            <div class="button__bg flex-auto flex items-center pa1 br2">
              <mat-icon class="delete-icon">cancel</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="ph2 black-40 flex items-center" *ngIf="!isLastQuestion">
        <span class="f4 mr2 nt1">↳</span>

        <mat-menu #appMenu="matMenu">
          <button
            type="button"
            mat-menu-item
            *ngFor="let field of otherFormFields"
            (click)="setNextPage(i, field.id)"
          >
            <span class="mr2 black-50">#{{ field.idx + 1 }} </span>
            <span class="fw5">{{ field.title | selectLanguage: language }}</span>
          </button>
          <mat-divider *ngIf="otherFormFields.length > 0"></mat-divider>
          <button
            type="button"
            mat-menu-item
            class="f6 fw5 black-70"
            (click)="setNextPage(i, END_FORM_ACTION.id)"
          >
            <mat-icon class="mr2">fast_forward</mat-icon>
            <span>{{ END_FORM_ACTION.title | translate: locale.language }}</span>
          </button>
          <button
            type="button"
            mat-menu-item
            class="f6 fw5 black-70"
            (click)="setNextPage(i, END_SILENTLY_FORM_ACTION.id)"
          >
            <mat-icon class="mr2">fast_forward</mat-icon>
            <span>{{ END_SILENTLY_FORM_ACTION.title | translate: locale.language }}</span>
          </button>
          <button
            type="button"
            mat-menu-item
            class="f6 fw5 black-70"
            (click)="setNextPage(i, CANCEL_FORM_ACTION.id)"
          >
            <mat-icon class="mr2">cancel</mat-icon>
            <span>{{ CANCEL_FORM_ACTION.title | translate: locale.language }}</span>
          </button>
        </mat-menu>

        <ng-container *ngIf="nextQuestionFor(item) as nq; else noNextQuestion">
          <span class="f7 skip-question">
            <ng-container [ngSwitch]="nq.id">
              <span class="mr1" *ngSwitchCase="END_FORM_ACTION.id">
                <span class="bb bw1 b--gold link pointer" [matMenuTriggerFor]="appMenu">
                  <span class="fw5 black">{{
                    END_FORM_ACTION.title | translate: locale.language
                  }}</span>
                </span>
              </span>

              <span class="mr1" *ngSwitchCase="END_SILENTLY_FORM_ACTION.id">
                <span class="bb bw1 b--gold link pointer" [matMenuTriggerFor]="appMenu">
                  <span class="fw5 black">{{
                    END_SILENTLY_FORM_ACTION.title | translate: locale.language
                  }}</span>
                </span>
              </span>

              <span class="mr1" *ngSwitchCase="CANCEL_FORM_ACTION.id">
                <span class="bb bw1 b--red link pointer" [matMenuTriggerFor]="appMenu">
                  <span class="fw5 black">{{
                    CANCEL_FORM_ACTION.title | translate: locale.language
                  }}</span>
                </span>
              </span>

              <span class="mr1" *ngSwitchDefault>
                <span class="mr1">{{
                  'FormQuestion.options.label.gotoQuestion' | translate: locale.language
                }}</span>
                <span class="bb bw1 b--green link pointer" [matMenuTriggerFor]="appMenu">
                  <span class="black-50">#{{ nq.idx + 1 }} </span>
                  <span class="fw5 black">{{ nq.title | selectLanguage: language }}</span>
                </span>
              </span>
            </ng-container>

            <span class="dib br-100 bg-white pointer" (click)="setNextPage(i, null)">
              <mat-icon class="delete-icon">cancel</mat-icon>
            </span>
          </span>
        </ng-container>

        <ng-template #noNextQuestion>
          <span class="f7" [matMenuTriggerFor]="appMenu">
            <span class="link pointer skip-question skip-question--link">{{
              'FormQuestion.options.action.gotoQuestion' | translate: locale.language
            }}</span>
          </span>
        </ng-template>
      </div>
    </div>

    <div class="mb3" *ngIf="form.get('acceptUserText').value">
      <div class="answer-field flex br2 items-stretch">
        <div class="pv2 ph3 black-50 flex items-center">
          <div class="i mr2">
            {{ 'FormQuestion.options.otherOptionLabel' | translate: locale.language }}
          </div>
        </div>
        <div class="pv1 flex items-stretch flex-auto">
          <input
            type="text"
            disabled
            class="label-input w-100 br2"
            [placeholder]="
              'FormQuestion.options.otherOptionPlaceholder' | translate: locale.language
            "
          />
        </div>
        <div
          class="button--remove flex items-stretch pa1 pointer"
          (click)="deleteOtherAnswer()"
          [matTooltip]="'FormQuestion.options.action.deleteOption' | translate: locale.language"
          matTooltipPosition="right"
        >
          <div class="button__bg flex-auto flex items-center pa1 br2">
            <mat-icon class="delete-icon">cancel</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap">
    <button
      type="button"
      class="template-button flex-auto mr2-ns w-100 w-auto-ns mb1 mb0-ns"
      mat-button
      color="primary"
      (click)="addAnswer()"
    >
      <mat-icon>add</mat-icon>
      <span>{{ 'FormQuestion.options.action.addOption' | translate: locale.language }}</span>
    </button>

    <button
      type="button"
      *ngIf="!form.get('acceptUserText').value"
      class="template-button w-100 w-auto-ns"
      mat-button
      color="primary"
      (click)="addOtherAnswer()"
    >
      <mat-icon>add</mat-icon>
      <span>{{ 'FormQuestion.options.action.addOther' | translate: locale.language }}</span>
    </button>
  </div>

  <!-- <pre>{{ config.value | json }}</pre> -->
</div>
