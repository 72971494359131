import { Actions, ofType } from '@ngrx/effects'
import { race, throwError, Observable } from 'rxjs'
import { take, mergeMap } from 'rxjs/operators'

export const successOrFail = <SuccessType>(
  actions: Actions,
  successType: string,
  failType: string
) => {
  return race(
    actions.pipe(
      ofType(successType),
      take(1)
    ),
    actions.pipe(
      ofType(failType),
      take(1),
      mergeMap(x => throwError(x))
    )
  ) as Observable<SuccessType>
}
