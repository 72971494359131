import { Action } from '@ngrx/store'

export enum GlobalActionTypes {
  ResetState = '[Global] ResetState',
}

export class ResetState implements Action {
  readonly type = GlobalActionTypes.ResetState
}

export type GlobalActions = ResetState
