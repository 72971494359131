<div
  class="card"
  [class.is-selected]="selected"
  [class.is-clickable]="clickable"
  [class.is-disabled]="!product.display"
  (click)="onClick($event)"
>
  <div class="image">
    <div
      *ngIf="product.pictures && product.pictures[0]"
      class="image-bg"
      [style.backgroundImage]="'url(' + product.pictures[0] + ')'"
    ></div>
    <span *ngIf="product.price" class="price"
      >{{ product.price | baseCurrencyUnit }} {{ currencySign }}</span
    >
    <div class="actions" [class.withDraggerSpace]="draggable">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="info">
    <div class="title">{{ product.title | selectLanguage: lang }}</div>
    <div class="quantity" *ngIf="quantity">
      <span
        *ngIf="quantity.prevent"
        [matTooltip]="'Shop.label.productWithSailPrevetion' | translate: locale.language"
        >⚠️</span
      >
      {{ 'Shop.label.items' | translate: locale.language:{ value: quantity.value } }}
    </div>
  </div>
</div>
