import { RouterStateSnapshot, Params } from '@angular/router'
import { RouterStateSerializer } from '@ngrx/router-store'
import { Injectable } from '@angular/core'

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  url: string
  queryParams: Params
  params: Params
  data: { [key: string]: any }
}

@Injectable()
export class RouterStateUrlSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState
    const queryParams = routerState.root.queryParams
    const { data, params } = this.getRecursiveRouteData(routerState.root)

    return { url, queryParams, data, params }
  }

  public getRecursiveRouteData(currentRoute) {
    let recursiveRouteData = {}
    let params = {}

    while (currentRoute) {
      recursiveRouteData = {
        ...recursiveRouteData,
        ...currentRoute.data,
      }
      params = {
        ...params,
        ...currentRoute.params,
      }
      currentRoute = currentRoute.children.find(route => route.outlet === 'primary')
    }

    return { data: recursiveRouteData, params }
  }
}
