type TranslatableString = Array<{ lang: string; value: string }>

export enum FormFieldType {
  Action = 'action',
  Address = 'address',
  Comment = 'comment',
  Date = 'date',
  Message = 'message',
  Number = 'number',
  Options = 'options',
  Promocode = 'promocode',
  Review = 'review',
  Text = 'text',
  Time = 'time',
}

export interface FieldOption {
  id: number
  label: TranslatableString
  value?: string
}

export interface FormFieldConfig {
  acceptAnyText?: boolean
  acceptUserText?: boolean
  required?: boolean
  autofill?: boolean
  autofillFrom?: string
  validateEntities?: boolean
  entities?: string
  options?: FieldOption[]

  // Number
  min?: string
  max?: string

  // Action-specific
  nextQuestion?: string

  // Date-specific
  columns?: number
  startingDayOffset?: number
  days?: number
}
export interface FormField {
  id: string
  type: FormFieldType
  title: TranslatableString
  label: TranslatableString
  config: FormFieldConfig
}
