import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

import { Store } from '@ngrx/store'

import { Observable, of } from 'rxjs'

import * as fromRoot from '@redux'
import * as fromReview from '@redux/review'

@Injectable()
export class SelectReviewGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}
  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const reviewId = router.params.reviewId
    this.store$.dispatch(new fromReview.Select({ reviewId: reviewId }))
    return of(true)
  }
}
