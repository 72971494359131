import * as R from 'ramda'

export interface TranslatableField {
  default: string
  en?: string
  ru?: string
}

export const trimValue = (value: TranslatableField): TranslatableField => {
  const result = { default: null }
  R.keys(value).forEach(lang => {
    result[lang] = value[lang] && value[lang].trim()
  })
  return result
}
