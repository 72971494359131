import { Action } from '@ngrx/store'
import { PropertyFAQ } from '@models/faq.model'
import { FAQCategory, FAQItem, NormalizedResult, NormalizedResults } from '@models'

export enum FAQActionTypes {
  Create = '[FAQ] Create',
  CreateSuccess = '[FAQ] Create Success',
  CreateFail = '[FAQ] Create Fail',
  Load = '[FAQ] Load',
  LoadSuccess = '[FAQ] Load Success',
  LoadFail = '[FAQ] Load Fail',
  Update = '[FAQ] Update',
  UpdateSuccess = '[FAQ] Update Success',
  UpdateFail = '[FAQ] Update Fail',
  Delete = '[FAQ] Delete',
  DeleteSuccess = '[FAQ] Delete Success',
  DeleteFail = '[FAQ] Delete Fail',
  CreateCategories = '[FAQ] Create Categories',
  CreateCategoriesSuccess = '[FAQ] Create Categories Success',
  CreateCategoriesFail = '[FAQ] Create Categories Fail',
  LoadCategories = '[FAQ] Load Categories',
  LoadCategoriesSuccess = '[FAQ] Load Categories Success',
  LoadCategoriesFail = '[FAQ] Load Categories Fail',
  UpdateCategories = '[FAQ] Update Categories',
  UpdateCategoriesSuccess = '[FAQ] Update Categories Success',
  UpdateCategoriesFail = '[FAQ] Update Categories Fail',
  DeleteCategories = '[FAQ] Delete Categories',
  DeleteCategoriesSuccess = '[FAQ] Delete Categories Success',
  DeleteCategoriesFail = '[FAQ] Delete Categories Fail',
}

export interface LoadPayload {
  propertyId: string
  needTranslate?: boolean
}

/**
 * Create FAQs actions
 */
export class Create implements Action {
  public readonly type = FAQActionTypes.Create
  constructor(public payload: LoadPayload, public meta: PropertyFAQ) {}
}

export class CreateSuccess implements Action {
  public readonly type = FAQActionTypes.CreateSuccess
  constructor(public payload: PropertyFAQ, public meta: LoadPayload) {}
}

export class CreateFail implements Action {
  public readonly type = FAQActionTypes.CreateFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load FAQs actions
 */

export class Load implements Action {
  public readonly type = FAQActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  public readonly type = FAQActionTypes.LoadSuccess
  constructor(public payload: PropertyFAQ[], public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  public readonly type = FAQActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Update FAQ actions
 */
export interface UpdatePayload {
  propertyId: string
  faq: string
  content: Partial<FAQItem>
  sourceLanguage: string
  options?: {
    needTranslate?: boolean
  }
}

export class Update implements Action {
  public readonly type = FAQActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  public readonly type = FAQActionTypes.UpdateSuccess
  constructor(public payload: PropertyFAQ, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  public readonly type = FAQActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete FAQ actions
 */
export interface DeletePayload {
  propertyId: string
  faqId: string
}

export class Delete implements Action {
  public readonly type = FAQActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  public readonly type = FAQActionTypes.DeleteSuccess
  constructor(public payload: any, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  public readonly type = FAQActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

/**
 * Create FAQ Categories actions
 */
export interface CreateCategoriesPayload {
  category: FAQCategory
  sourceLanguage: string
  targetLanguages: string[]
  forceTranslate: boolean
}
export class CreateCategories implements Action {
  public readonly type = FAQActionTypes.CreateCategories
  constructor(public payload: CreateCategoriesPayload) {}
}

export class CreateCategoriesSuccess implements Action {
  public readonly type = FAQActionTypes.CreateCategoriesSuccess
  constructor(public payload: NormalizedResult, public meta: CreateCategoriesPayload) {}
}

export class CreateCategoriesFail implements Action {
  public readonly type = FAQActionTypes.CreateCategoriesFail
  constructor(public error: any, public meta: CreateCategoriesPayload) {}
}

/**
 * Load FAQ Categories actions
 */

export interface LoadCategoriesPayload {
  page?: number
  limit?: number
  meta?: {}
}

export class LoadCategories implements Action {
  public readonly type = FAQActionTypes.LoadCategories
  constructor(public payload: LoadCategoriesPayload) {}
}

export class LoadCategoriesSuccess implements Action {
  public readonly type = FAQActionTypes.LoadCategoriesSuccess
  constructor(public payload: NormalizedResults, public meta: LoadCategoriesPayload) {}
}

export class LoadCategoriesFail implements Action {
  public readonly type = FAQActionTypes.LoadCategoriesFail
  constructor(public error: any) {}
}

/**
 * Update FAQ Categories actions
 */

export class UpdateCategories implements Action {
  public readonly type = FAQActionTypes.UpdateCategories
  constructor(public payload: CreateCategoriesPayload) {}
}

export class UpdateCategoriesSuccess implements Action {
  public readonly type = FAQActionTypes.UpdateCategoriesSuccess
  constructor(public payload: NormalizedResult, public meta: any) {}
}

export class UpdateCategoriesFail implements Action {
  public readonly type = FAQActionTypes.UpdateCategoriesFail
  constructor(public error: any, public meta: any) {}
}

/**
 * Delete FAQ Categories actions
 */

export class DeleteCategories implements Action {
  public readonly type = FAQActionTypes.DeleteCategories
  constructor(public payload: any) {}
}

export class DeleteCategoriesSuccess implements Action {
  public readonly type = FAQActionTypes.DeleteCategoriesSuccess
  constructor(public payload: any, public meta: any) {}
}

export class DeleteCategoriesFail implements Action {
  public readonly type = FAQActionTypes.DeleteCategoriesFail
  constructor(public error: any, public meta: any) {}
}

export type FAQActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | CreateCategories
  | CreateCategoriesSuccess
  | CreateCategoriesFail
  | LoadCategories
  | LoadCategoriesSuccess
  | LoadCategoriesFail
  | UpdateCategories
  | UpdateCategoriesSuccess
  | UpdateCategoriesFail
  | DeleteCategories
  | DeleteCategoriesSuccess
  | DeleteCategoriesFail
