import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap, exhaustMap } from 'rxjs/operators'

import * as propertyActions from './property.actions'
import { PropertyService } from './property.service'
import { of, empty } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class PropertyEffects {
  /**
   * Create property effects
   */

  @Effect()
  public create$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.Create),
    switchMap((action: propertyActions.Create) => {
      const payload = action.payload
      return this.propertyService.create(payload).pipe(
        map(x => {
          return new propertyActions.CreateSuccess(x, payload)
        }),
        catchError(x => of(new propertyActions.CreateFail(x, payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.CreateSuccess),
    tap(() => {
      const createPropertySuccess = this.translationService.translate(
        'PropertyForm.notification.createPropertySuccess'
      )
      this.matSnackBar.open(createPropertySuccess, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.CreateFail),
    tap((action: propertyActions.UpdateFail) => {
      const message = action.error
      const error =
        message.description && message.description.errors && message.description.errors.alias
          ? this.translationService.translate('PropertyForm.error.sameAliasError')
          : this.translationService.translate('PropertyForm.error.createPropertyError')
      if (error) {
        this.matSnackBar.open(error, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )

  /**
   * Load properties effects
   */
  @Effect()
  load$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.Load),
    exhaustMap(() =>
      this.propertyService.getList().pipe(
        map(x => new propertyActions.LoadSuccess(x)),
        catchError(error => of(new propertyActions.LoadFail(error)))
      )
    )
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.LoadFail),
    tap(() => {
      const loadPropertiesError = this.translationService.translate(
        'PropertyForm.error.loadPropertiesError'
      )
      this.matSnackBar.open(loadPropertiesError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Load item effects
   */

  @Effect()
  public loadItem$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.LoadItem),
    exhaustMap((action: propertyActions.LoadItem) => {
      const meta = action.payload
      return this.propertyService.get(action.payload.propertyId).pipe(
        map(x => new propertyActions.LoadItemSuccess(x, meta)),
        catchError(x => of(new propertyActions.LoadItemFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadItemFail$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.LoadItemFail),
    tap(() => {
      const loadPropertyError = this.translationService.translate(
        'PropertyForm.error.loadPropertyError'
      )
      this.matSnackBar.open(loadPropertyError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Update property effects
   */

  @Effect()
  public update$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.Update),
    switchMap((action: propertyActions.Update) => {
      const payload = action.payload
      return this.propertyService.update(payload).pipe(
        map(x => new propertyActions.UpdateSuccess(x, payload)),
        catchError(x => of(new propertyActions.UpdateFail(x, payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.UpdateSuccess),
    tap((_: propertyActions.UpdateFail) => {
      const updatePropertySuccess = this.translationService.translate(
        'PropertyForm.notification.updatePropertySuccess'
      )
      this.matSnackBar.open(updatePropertySuccess, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.UpdateFail),
    tap(() => {
      const updatePropertyError = this.translationService.translate(
        'PropertyForm.error.updatePropertyError'
      )
      this.matSnackBar.open(updatePropertyError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect()
  public updateBooking$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.UpdateBooking),
    switchMap((action: propertyActions.UpdateBooking) => {
      const meta = action.payload
      return this.propertyService.updateInfoFromBooking(meta.propertyId).pipe(
        map(x => new propertyActions.UpdateBookingSuccess(x, meta)),
        catchError(x => of(new propertyActions.UpdateBookingFail(x, meta)))
      )
    })
  )

  @Effect()
  public updateTravelLine$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.UpdateTravelLine),
    switchMap((action: propertyActions.UpdateTravelLine) => {
      const meta = action.payload
      return this.propertyService.updateInfoFromTravelLine(meta.propertyId).pipe(
        map(x => new propertyActions.UpdateTravelLineSuccess(x, meta)),
        catchError(x => of(new propertyActions.UpdateTravelLineFail(x, meta)))
      )
    })
  )

  @Effect()
  public updateAYS$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.UpdateAYS),
    switchMap((action: propertyActions.UpdateAYS) => {
      const meta = action.payload
      return this.propertyService.updateInfoFromAYS(meta.propertyId).pipe(
        map(x => new propertyActions.UpdateAYSSuccess(x, meta)),
        catchError(x => of(new propertyActions.UpdateAYSFail(x, meta)))
      )
    })
  )

  @Effect()
  public loadIntegrations$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.LoadIntegrations),
    switchMap((action: propertyActions.LoadIntegrations) => {
      const meta = action.payload
      return this.propertyService.getIntegrations(meta.propertyId).pipe(
        map(x => new propertyActions.LoadIntegrationsSuccess(x, meta)),
        catchError(x => of(new propertyActions.LoadIntegrationsFail(x, meta)))
      )
    })
  )

  @Effect()
  public updateIntegrations$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.UpdateIntegrations),
    switchMap((action: propertyActions.UpdateIntegrations) => {
      const meta = action.payload
      return this.propertyService.updateIntegrations(meta.propertyId, meta.integrations).pipe(
        map(x => new propertyActions.UpdateIntegrationsSuccess(x, meta)),
        catchError(x => of(new propertyActions.LoadIntegrationsFail(x, meta)))
      )
    })
  )

  @Effect()
  public validateIntegration$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.ValidateIntegration),
    switchMap((action: propertyActions.ValidateIntegration) => {
      const meta = action.payload
      return this.propertyService.validateIntegration(meta.propertyId, meta.integration).pipe(
        map(x => new propertyActions.ValidateIntegrationSuccess(x, meta)),
        catchError(x => of(new propertyActions.ValidateIntegrationFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  public updateTravelLineSuccess$ = this.actions$.pipe(
    ofType(
      propertyActions.PropertyActionTypes.UpdateTravelLineSuccess,
      propertyActions.PropertyActionTypes.UpdateAYSSuccess
    ),
    tap(() => {
      const message = this.translationService.translate(
        'PropertyForm.notification.updatePropertyRoomsSuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateTravelLineFail$ = this.actions$.pipe(
    ofType(
      propertyActions.PropertyActionTypes.UpdateTravelLineFail,
      propertyActions.PropertyActionTypes.UpdateAYSFail
    ),
    tap(() => {
      const message = this.translationService.translate(
        'PropertyForm.error.updatePropertyRoomsError'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect()
  public completeGoal$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.CompleteGoal),
    switchMap((action: propertyActions.CompleteGoal) => {
      const meta = action.payload
      return this.propertyService.completeGoals(meta.propertyId, meta.goalTypes).pipe(
        map(x => new propertyActions.CompleteGoalSuccess(x, meta)),
        catchError(x => of(new propertyActions.CompleteGoalFail(x, meta)))
      )
    })
  )

  @Effect()
  public skipGoal$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.SkipGoal),
    switchMap((action: propertyActions.SkipGoal) => {
      const meta = action.payload
      console.log('SKIP', meta)
      return this.propertyService.skipGoals(meta.propertyId, meta.goalTypes).pipe(
        map(x => new propertyActions.SkipGoalSuccess(x, meta)),
        catchError(x => of(new propertyActions.SkipGoalFail(x, meta)))
      )
    })
  )

  @Effect()
  public searchCity$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.SearchCity),
    switchMap((action: propertyActions.SearchCity) => {
      const meta = action.payload
      return meta.query && (meta.query.length > 2 || meta.countryCode)
        ? this.propertyService
            .searchCity(meta.query, meta.countryCode)
            .pipe(map(x => new propertyActions.SearchCitySuccess(x, meta)))
        : empty()
    })
  )

  /**
   * Delete property effects
   */

  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.Delete),
    switchMap((action: propertyActions.Delete) => {
      const propertyId = action.payload
      return this.propertyService.delete(propertyId).pipe(
        map(x => new propertyActions.DeleteSuccess(x, propertyId)),
        catchError(x => of(new propertyActions.DeleteFail(x)))
      )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.DeleteSuccess),
    tap((_: propertyActions.DeleteFail) => {
      const deletePropertySuccess = this.translationService.translate(
        'PropertyForm.notification.deletePropertySuccess'
      )
      this.matSnackBar.open(deletePropertySuccess, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(propertyActions.PropertyActionTypes.DeleteFail),
    tap(() => {
      const deletePropertyError = this.translationService.translate(
        'PropertyForm.error.deletePropertyError'
      )
      this.matSnackBar.open(deletePropertyError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private propertyService: PropertyService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
