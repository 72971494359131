import { Injectable } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { I18nService } from '@shared/services'

@Injectable({ providedIn: 'root' })
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel = 'Items per page:'
  nextPageLabel = 'Next page'
  previousPageLabel = 'Previous page'
  firstPageLabel = 'First page'
  lastPageLabel = 'Last page'

  constructor(public translation: I18nService) {
    super()
    this.translation.translationChanged().subscribe(() => {
      this.itemsPerPageLabel = this.translation.translate('components.Paginator.itemsPerPage')
      this.nextPageLabel = this.translation.translate('components.Paginator.nextPage')
      this.previousPageLabel = this.translation.translate('components.Paginator.previousPage')
      this.firstPageLabel = this.translation.translate('components.Paginator.firstPage')
      this.lastPageLabel = this.translation.translate('components.Paginator.lastPage')
      this.changes.next()
    })
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.translation.translate('components.Paginator.rangeZero', { total: length })
    }
    length = Math.max(length, 0)
    const startIndex = page * pageSize
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
    return this.translation.translate('components.Paginator.range', {
      startIndex: startIndex + 1,
      endIndex,
      total: length,
    })
  }
}
