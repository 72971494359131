<a
  class="flex items-center page-link"
  [ngClass]="{ active: pageId === tree[rootId].id }"
  [routerLink]="[builderRootUrl, tree[rootId].id]"
  queryParamsHandling="preserve"
>
  <!-- (click)="navigateToLink(tree[rootId].id)" -->
  <mat-icon class="mr2 o-50">menu</mat-icon>
  {{ tree[rootId].title }}
</a>
<ul class="mt2">
  <li class="mb1" *ngFor="let page of tree[rootId].children">
    <ng-container *ngTemplateOutlet="innerNode; context: { subpage: page }"></ng-container>
  </li>
</ul>

<ng-container *ngIf="showOrphans && orphans.length">
  <div class="mt4 ttu f6 fw5 tracked T-text-muted">Orphan pages</div>
  <ul class="T-text">
    <li class="mb1" *ngFor="let page of orphans">
      <ng-container *ngTemplateOutlet="innerNode; context: { subpage: page }"></ng-container>
    </li>
  </ul>
</ng-container>

<ng-template #innerNode let-subpage="subpage">
  <ng-container [ngSwitch]="subpage.type">
    <div class="flex items-center" *ngSwitchCase="'page'">
      <div class="nl4 mr2" *ngIf="subpage.orphan">
        <div
          class="pa1 flex pointer hover-bg-red hover-white br-pill nl2 mr2"
          (click)="handleDeletePage(subpage.id)"
        >
          <mat-icon>delete_forever</mat-icon>
        </div>
      </div>

      <a
        class="flex items-center page-link"
        [ngClass]="{ active: pageId === subpage.config.pageId }"
        [routerLink]="[builderRootUrl, subpage.config.pageId]"
        queryParamsHandling="preserve"
      >
        <!-- (click)="navigateToLink(subpage.config.pageId)" -->
        <mat-icon class="mr2 o-50">art_track</mat-icon>
        <span>{{ subpage.title }}</span>
        <mat-icon
          *ngIf="subpage.isTracking; else noAiPowered"
          svgIcon="hot:ai"
          class="ml2 h1 w1"
          matTooltip="{{ 'MenuBuilder.aiPowered' | translate: locale.language }}"
          [ngStyle]="{ color: '#2ac98f' }"
        ></mat-icon>
        <ng-template #noAiPowered>
          <mat-icon
            svgIcon="hot:ai"
            class="ml2 o-20 h1 w1"
            matTooltip="{{ 'MenuBuilder.noAiPowered' | translate: locale.language }}"
          ></mat-icon>
        </ng-template>
      </a>
    </div>

    <span
      mat-button
      class="flex items-center page-link w-100 hot-module-btn-title pt0 pb0"
      *ngSwitchCase="'module'"
    >
      <mat-icon class="mr2 o-50 page-link-icon">assessment</mat-icon>
      <span>{{ subpage.title }}</span>
    </span>

    <span
      matTooltip="{{ (subpage.config && subpage.config.url) || '&mdash;' }}"
      target="_blank"
      class="flex items-center page-link"
      *ngSwitchCase="'url'"
    >
      <mat-icon class="mr2 o-50">link</mat-icon>
      <span>{{ subpage.title }}</span>
    </span>

    <span
      mat-button
      class="flex items-center page-link w-100 hot-module-btn-title pt0 pb0"
      *ngSwitchCase="'form'"
    >
      <mat-icon class="mr2 o-50 page-link-icon">assignment</mat-icon>
      <span>{{ subpage.title }}</span>
    </span>

    <span
      mat-button
      class="flex items-center page-link w-100 hot-module-btn-title pt0 pb0"
      *ngSwitchCase="'shop_link'"
    >
      <mat-icon class="mr2 o-50 page-link-icon">shopping_cart</mat-icon>
      <span>{{ subpage.title }}</span>
    </span>

    <span *ngSwitchDefault class="flex items-center page-link" pt0 pb0>
      <mat-icon class="mr2 o-50" [title]="subpage.type">new_releases</mat-icon>
      <span>{{ subpage.title }}</span>
    </span>
  </ng-container>

  <ul
    class="mt1"
    [ngClass]="{ active: pageId === subpage.config.pageId }"
    *ngIf="subpage.type === 'page' && subpage.children"
  >
    <li class="mb1" *ngFor="let subpage of subpage.children">
      <ng-container *ngTemplateOutlet="innerNode; context: { subpage: subpage }"></ng-container>
    </li>
  </ul>
</ng-template>
