import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { switchMap, map, catchError, tap } from 'rxjs/operators'

import * as wifiActions from './wifi.actions'
import { WifiService } from './wifi.service'
import { MatSnackBar } from '@angular/material/snack-bar'

const LOAD_ERROR = 'При загрузке информации о порталах возникла ошибка'

@Injectable()
export class WifiEffects {
  @Effect()
  createCaptivePortal$ = this.actions$.pipe(
    ofType(wifiActions.ActionTypes.CreateCaptivePortal),
    switchMap((action: wifiActions.CreateCaptivePortal) => {
      const meta = action.payload
      return this.wifiService
        .createCaptivePortal(action.payload.propertyId, action.payload.captivePortal)
        .pipe(
          map(xs => new wifiActions.CreateCaptivePortalSuccess(xs, meta)),
          catchError(x => of(new wifiActions.CreateCaptivePortalFail(x, meta)))
        )
    })
  )

  @Effect()
  load$ = this.actions$.pipe(
    ofType(wifiActions.ActionTypes.LoadCaptivePortals),
    switchMap((action: wifiActions.LoadCaptivePortals) => {
      const meta = action.payload
      return this.wifiService
        .getCaptivePortals(action.payload.propertyId)
        .pipe(
          map(xs => new wifiActions.LoadCaptivePortalsSuccess(xs, meta)),
          catchError(x => of(new wifiActions.LoadCaptivePortalsFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  loadFail$ = this.actions$.pipe(
    ofType(wifiActions.ActionTypes.LoadCaptivePortalsFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private wifiService: WifiService,
    private matSnackBar: MatSnackBar
  ) {}
}
