import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import * as productActions from './product.actions'
import { ProductService } from './product.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class ProductEffects {
  // Create

  @Effect()
  public create$ = this.actions$.pipe(
    ofType(productActions.ProductActionTypes.Create),
    switchMap((action: productActions.Create) => {
      const meta = action.payload
      return this.productService
        .create(
          action.payload.propertyId,
          action.payload.roomServiceId,
          action.payload.product,
          action.payload.sourceLanguage,
          action.payload.targetLanguages
        )
        .pipe(
          map(x => {
            return new productActions.CreateSuccess(x, meta)
          }),
          catchError(x => of(new productActions.CreateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(productActions.ProductActionTypes.CreateSuccess),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.createProductSuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(productActions.ProductActionTypes.CreateFail),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.createProductFail'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  // Update
  @Effect()
  update$ = this.actions$.pipe(
    ofType(productActions.ProductActionTypes.Update),
    switchMap((action: productActions.Update) => {
      const meta = action.payload
      return this.productService
        .update(
          action.payload.propertyId,
          action.payload.roomServiceId,
          action.payload.productId,
          action.payload.product,
          action.payload.sourceLanguage,
          action.payload.targetLanguages,
          action.payload.forceTranslate
        )
        .pipe(
          map(x => new productActions.UpdateSuccess(x, meta)),
          catchError(x => of(new productActions.UpdateFail(x, meta)))
        )
    }),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.updateProductSuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  // Delete
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(productActions.ProductActionTypes.Delete),
    switchMap((action: productActions.Delete) => {
      const meta = action.payload
      return this.productService
        .delete(action.payload.propertyId, action.payload.roomServiceId, action.payload.productId)
        .pipe(
          map(x => {
            return new productActions.DeleteSuccess(x, meta)
          }),
          catchError(x => of(new productActions.DeleteFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(productActions.ProductActionTypes.DeleteSuccess),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.deleteProductSuccess'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(productActions.ProductActionTypes.DeleteFail),
    tap(() => {
      const message = this.translationService.translate(
        'RoomService.notification.deleteProductFail'
      )
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
