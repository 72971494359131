import { Pipe, PipeTransform } from '@angular/core'
import { ChatType } from '@shared/constants'

const chatTypes = {
  [ChatType.Axon]: '🟢 SberDevices',
  [ChatType.Facebook]: 'Facebook',
  [ChatType.Telegram]: 'Telegram',
  [ChatType.Viber]: 'Viber',
  [ChatType.Webchat]: 'Webchat',
  [ChatType.Whatsapp]: 'WhatsApp',
  [ChatType.Alisa]: 'Yandex Alisa',
}

const UNDEFINED_CHAT_TYPE = '❓ Unknown'

@Pipe({
  name: 'chatType',
})
export class ChatTypePipe implements PipeTransform {
  transform(value: any): any {
    return chatTypes[value] || UNDEFINED_CHAT_TYPE
  }
}
