import { Component } from '@angular/core'

@Component({
  selector: 'hot-no-content',
  template: `
    <div class="flex flex-auto items-center justify-center">
      <div class="bg-white shadow-1 pa3 pv5-l ph6-l flex flex-column items-center justify-center">
        <h2 class="mb3 fw4">404: Страница не найдена</h2>
        <a routerLink="/" mat-raised-button color="accent">Перейти на главную</a>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex: auto;
      }
    `,
  ],
})
export class NoContentComponent {}
