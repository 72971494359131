import { AbstractControl } from '@angular/forms'
import { UrlButtonRegex } from '@shared/constants'

export const builderUrlValidator = (control: AbstractControl) => {
  if (control && control.value && !control.value.match(UrlButtonRegex)) {
    return {
      url: true,
    }
  }
  return null
}
