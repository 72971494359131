import { Property } from './property.model'
import { ChatType, SegmentType } from '@shared/constants'

export enum ChatStatuses {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export interface Chat {
  _id: string
  property: string
  channelType: ChatType
  locale: string
  unsubscribedAt: boolean
  canAssign: boolean
  canJoin: boolean
  canWrite: boolean
  canClose: boolean
  pausedAt: string
  pendingAt: string
  connectedUsers: string[]
  propertyParameters?: any
  responses?: {
    open?: number
    inprogress?: number
  }
  status: ChatStatuses.ONLINE | ChatStatuses.OFFLINE
  createdAt: string
  updatedAt: string
  name: string
  fallbackName?: string
  email: string
  phoneNumber: string
  lastMessage: string
  lastMessageDate: Date

  // FIXME: Add Fields to chat from chatUser
  user?: {
    externalId?: string
    username?: string
    firstName?: string
    lastName?: string
    profilePic?: string
    locale?: string
  }
  // FIXME: Add Fields to chat from chatUser

  linkedChats: Array<
    Pick<Chat, '_id' | 'name' | 'channelType' | 'property' | 'status' | 'locale' | 'updatedAt'>
  >

  source: Array<'wifi' | 'widget' | 'email' | 'widget-autogreeting' | 'cjm'>

  geoip: {
    ip: string
    countryEmoji: string
    countryCode: string
    countryName: string
    city: string
    isEU: string
  }
  utm: {
    source: string
    content: string
    medium: string
    campaign: string
    term: string
  }
  visitedPages: ChatPageVisit[]

  // @deprecated
  parameters?: any

  // Custom fields to populate
  __unreadMessages?: number
  __property?: Property
}

export interface ChatPageVisit {
  url: string
  date: Date
  title?: string
}

export interface ChatFilters {
  tab: 'inbox' | 'all'
  term?: string
  limit?: number
  page?: number
}

export interface ChatCountFilters {
  lang?: string
  segment: SegmentType
  activeFrom?: Date | string
  activeTo?: Date | string
}

export const getRoomNumbers = (chat: Chat): string[] => {
  const params = chat && chat.propertyParameters
  if (!params || !(params.roomNumbers || params.roomNumber)) {
    return []
  }
  if (params.roomNumbers && params.roomNumbers.length) {
    return params.roomNumbers
  }
  return params.roomNumber ? [params.roomNumber] : []
}

export const getFormattedRoomNumbers = (chat: Chat): string => {
  return getRoomNumbers(chat).join(' / ')
}
