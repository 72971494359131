import { Chat } from './chat.model'
import { User } from './user.model'
import { Product } from './product.model'
import { ProductCategory } from './product-category.model'
import { PaginationOptions } from './pagination-options'

export enum CartStatus {
  New = 'new',
  Open = 'open',
  WaitForPayment = 'wait_for_payment',
  InProgress = 'inprogress',
  Done = 'done',
  CanceledByGuest = 'canceled_guest',
  CanceledByHotel = 'canceled_hotel',
}

export enum CartEventType {
  Opened = 'opened',
  Canceled = 'canceled',
  InProgress = 'inprogress',
  Done = 'done',
  PaymentPreauth = 'payment_preauth',
  PaymentRefund = 'payment_refund',
  PaymentCancelled = 'payment_cancelled',
  PaymentComplete = 'payment_complete',
}

export interface CartFilters {
  status?: CartStatus
  paginationOptions?: PaginationOptions
}

export interface CartEventItem {
  event: CartEventType
  body?: string
  oldValue: CartStatus
  newValue: CartStatus
  createdAt: Date
  updatedAt: Date
  isManager: boolean
  user?: string
  __user?: User
}

export interface CartItem {
  product: string
  amount: number
}

export interface Cart {
  _id?: string
  orderNumber: number | string
  createdAt: string
  updatedAt: string
  property: string
  chat: string
  roomService: string
  items: CartItem[]
  status: CartStatus
  events: CartEventItem[]
  details?: {
    payment: {
      method: 'cash' | 'card'
      requiresChange?: boolean
      changeFrom?: number
    }
    personsCount: number
    deliveryCost?: number
    serviceFee?: number
  }
  __chat?: Chat
  __products?: R.Dictionary<Product>
  __productCategories?: R.Dictionary<ProductCategory>
}
