import { Pipe, PipeTransform } from '@angular/core'
import { CURRENCY_SIGN } from '@shared/constants'
import { values } from 'ramda'

const currencySigns = values(CURRENCY_SIGN) as string[]

const signsFollowing = [CURRENCY_SIGN.rub]

export const variableToBaseUnit = (value: number, currency?: string): string => {
  const str = (value / 100).toFixed(2)
  const amount = str.endsWith('.00') ? String(Number(str)) : str
  if (!currency) {
    return amount
  }
  const currencySign = currencySigns.includes(currency) ? currency : CURRENCY_SIGN[currency] || '$'
  return signsFollowing.includes(currencySign)
    ? `${amount} ${currencySign}`
    : `${currencySign} ${amount}`
}

@Pipe({
  name: 'baseCurrencyUnit',
})
export class BaseCurrencyUnit implements PipeTransform {
  transform = variableToBaseUnit
}
