import { Directive, TemplateRef } from '@angular/core'

@Directive({
  selector: '[hotDndBlock]',
  exportAs: 'dndBlock',
})
export class DndBlockDirective {
  public template: TemplateRef<any>
  constructor(private templateRef: TemplateRef<any>) {
    this.template = this.templateRef
  }
}
