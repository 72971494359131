import { Pipe, PipeTransform } from '@angular/core'

const timeOrNull = (x: string) => {
  if (!x) {
    return null
  }
  const date = new Date(x)
  return isNaN(date.getTime()) ? null : date
}

export const visibilityPeriodState = (period: { start?: string; end?: string }): string | null => {
  if (!period) {
    return null
  }
  const start = timeOrNull(period.start) || 0
  const end = timeOrNull(period.end) || Infinity
  const now = Date.now()
  if (start < now && now < end) {
    return 'active'
  }
  if (now < start) {
    return 'future'
  }
  if (end < now) {
    return 'past'
  }
  return 'unknown'
}

@Pipe({
  name: 'visibilityPeriodState',
})
export class VisibilityPeriodStatePipe implements PipeTransform {
  transform = visibilityPeriodState
}
