import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { AuthService } from './auth.service'
import { ConfigService } from './config.service'
import { tap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly configService: ConfigService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isHotbotAPI = req.url.includes(this.configService.API_BASE_URL)
    if (!isHotbotAPI) {
      return next.handle(req)
    }

    const authToken = this.getAuthorizationToken()

    if (authToken) {
      const authReq = req.clone({
        setHeaders: { Authorization: authToken },
      })

      return next.handle(authReq).pipe(
        tap(null, x => {
          if (x.status === 401) {
            // TODO: Refresh token
            this.authService.logout()
          }
        })
      )
    }

    return next.handle(req)
  }

  public getAuthorizationToken(): string | null {
    const token = this.authService.accessToken
    return token ? 'Bearer ' + token : null
  }
}
