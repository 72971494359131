<div *ngIf="logoBackgroundImage"
  [ngStyle]="{'background-image': logoBackgroundImage}"
  class="nav-name overflow-hidden property-logo"
  [matTooltip]="tooltip"
  matTooltipPosition="right">
</div>
<div *ngIf="property && !property.logoUrl"
  class="nav-name overflow-hidden property-name"
  [matTooltip]="tooltip"
  matTooltipPosition="right">
  <span>{{ (property && property.name | abbreviation) || '&mdash;' }}</span>
</div>
