import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

import { Store } from '@ngrx/store'

import { Observable, of } from 'rxjs'

import * as fromRoot from '@redux'
import * as fromPush from '@redux/push'

@Injectable()
export class SelectPushMessageGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}

  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const { pushMessageId } = router.params
    this.store$.dispatch(new fromPush.Select({ pushMessageId: pushMessageId || null }))
    return of(true)
  }
}
