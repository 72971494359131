import { Action } from '@ngrx/store'

import { NormalizedResult, NormalizedResults, CaptivePortal } from '@models'

import { WifiStatsQueryParams, WifiVouchersQueryParams } from './wifi.service'

export enum ActionTypes {
  SelectCaptivePortal = '[Wifi] Select captive portal',

  CreateCaptivePortal = '[Wifi] Create captive portal',
  CreateCaptivePortalSuccess = '[Wifi] Create captive portal Success',
  CreateCaptivePortalFail = '[Wifi] Create captive portal Fail',

  LoadCaptivePortals = '[Wifi] Load Captive Portals',
  LoadCaptivePortalsSuccess = '[Wifi] Load Captive Portals Success',
  LoadCaptivePortalsFail = '[Wifi] Load Captive Portals Fail',

  LoadStats = '[Wifi] Load Stats',
  LoadStatsSuccess = '[Wifi] Load Stats Success',
  LoadStatsFail = '[Wifi] Load Stats Fail',

  LoadVouchers = '[Wifi] Load Vouchers',
  LoadVouchersSuccess = '[Wifi] Load Vouchers Success',
  LoadVouchersFail = '[Wifi] Load Vouchers Fail',

  CreateVoucher = '[Wifi] Create Voucher',
  CreateVoucherSuccess = '[Wifi] Create Voucher Success',
  CreateVoucherFail = '[Wifi] Create Voucher Fail',

  // UpdateStatus = '[Wifi] Update Status',
  // UpdateStatusSuccess = '[Wifi] Update Status Success',
  // UpdateStatusFail = '[Wifi] Update Status Fail',
}

/**
 * Select action
 */
export class SelectCaptivePortal implements Action {
  readonly type = ActionTypes.SelectCaptivePortal
  constructor(public payload: { captivePortalId: string }) {}
}

/**
 * Create action
 */
export interface CreateCaptivePortalPayload {
  propertyId: string
  captivePortal?: Partial<CaptivePortal>
}

export class CreateCaptivePortal implements Action {
  readonly type = ActionTypes.CreateCaptivePortal
  constructor(public payload: CreateCaptivePortalPayload) {}
}
export class CreateCaptivePortalSuccess implements Action {
  readonly type = ActionTypes.CreateCaptivePortalSuccess
  constructor(public payload: NormalizedResults, public meta: CreateCaptivePortalPayload) {}
}
export class CreateCaptivePortalFail implements Action {
  readonly type = ActionTypes.CreateCaptivePortalFail
  constructor(public error: any, public meta: CreateCaptivePortalPayload) {}
}

/**
 * Load actions
 */
export interface LoadCaptivePortalsPayload {
  propertyId: string
  // page?: number
  // limit?: number
}

export class LoadCaptivePortals implements Action {
  readonly type = ActionTypes.LoadCaptivePortals
  constructor(public payload: LoadCaptivePortalsPayload) {}
}

export class LoadCaptivePortalsSuccess implements Action {
  readonly type = ActionTypes.LoadCaptivePortalsSuccess
  constructor(public payload: NormalizedResults, public meta: LoadCaptivePortalsPayload) {}
}

export class LoadCaptivePortalsFail implements Action {
  readonly type = ActionTypes.LoadCaptivePortalsFail
  constructor(public error: any, public meta: LoadCaptivePortalsPayload) {}
}

/**
 * Load item actions
 */
export type LoadStatsPayload = WifiStatsQueryParams

export class LoadStats implements Action {
  readonly type = ActionTypes.LoadStats
  constructor(public payload: LoadStatsPayload) {}
}

export class LoadStatsSuccess implements Action {
  readonly type = ActionTypes.LoadStatsSuccess
  constructor(public payload: NormalizedResult, public meta: LoadStatsPayload) {}
}

export class LoadStatsFail implements Action {
  readonly type = ActionTypes.LoadStatsFail
  constructor(public error: any, public meta: LoadStatsPayload) {}
}

/**
 * Load vouchers actions
 */
export type LoadVouchersPayload = WifiVouchersQueryParams

export class LoadVouchers implements Action {
  readonly type = ActionTypes.LoadVouchers
  constructor(public payload: LoadVouchersPayload) {}
}

export class LoadVouchersSuccess implements Action {
  readonly type = ActionTypes.LoadVouchersSuccess
  constructor(public payload: NormalizedResult, public meta: LoadVouchersPayload) {}
}

export class LoadVouchersFail implements Action {
  readonly type = ActionTypes.LoadVouchersFail
  constructor(public error: any, public meta: LoadVouchersPayload) {}
}

export type Actions =
  | SelectCaptivePortal
  | CreateCaptivePortal
  | LoadCaptivePortals
  | LoadCaptivePortalsSuccess
  | LoadCaptivePortalsFail
  | LoadStats
  | LoadStatsSuccess
  | LoadStatsFail
