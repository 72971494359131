import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from '@shared/services'

import { NormalizedResults, NormalizedResult } from '@models'

@Injectable()
export class FormResponseService {
  constructor(public readonly apiService: ApiService) {}

  public getList(propertyId: string, params?: any): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/formResponses`, {
      params: {
        status: params.status || '',
        page: params.page,
        limit: params.limit,
      },
    })
  }

  public get(propertyId: string, formResponseNumber: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/formResponses/${formResponseNumber}`)
  }

  public updateStatus(
    propertyId: string,
    formResponseId: string,
    { status, comment }
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/formResponses/${formResponseId}`, {
      status: status,
      comment: comment,
    })
  }
}
