import { FormField } from './form-field.model'
type TranslatableString = Array<{ lang: string; value: string }>

export type FormNotificationType = 'email' | 'sms'

export enum FormType {
  Request = 'request',
  Review = 'review',
  Link = 'link',
}

export interface FormLink {
  url: string
  label: TranslatableString
}

export interface FormNotificationSetting {
  user: string
  types: FormNotificationType[]
}
export type FormKpiNotificationSetting = FormNotificationSetting & {
  time: number
}
export interface Form {
  _id?: string
  title: TranslatableString
  cancelConfirmation: TranslatableString
  property: string
  fields: FormField[]
  requiresRoomNumber: boolean
  requiresEmailNotification: boolean
  requiresSmsNotification: boolean
  formType: FormType
  formLink: FormLink
  notifications?: FormNotificationSetting[]
  kpiNotifications?: FormKpiNotificationSetting[]
  kpiTime: string
  template?: boolean
  templateId?: string
  isServiceForm?: boolean
  instantCancellation?: boolean
  ignoreBackofficeIntegration?: boolean
}
