import { Injectable } from '@angular/core'
import { ConfigService } from './config.service'
import { AuthService } from './auth.service'

@Injectable({ providedIn: 'root' })
export class WebChatWidgetService {
  private widget = null
  public webChatScriptLoaded = false
  public widgetInstance = null
  constructor(private configService: ConfigService, private authService: AuthService) {
    this.authService.user$.subscribe(user => {
      if (!user) {
        this.destroyWebChatWidget()
      }
    })
  }

  private getApiBaseUrl(): string {
    const apiBaseUrl = this.configService.API_BASE_URL
    if (!apiBaseUrl) {
      throw new Error('API_BASE_URL is not provided')
    }
    return apiBaseUrl.startsWith('http') ? apiBaseUrl : '//' + apiBaseUrl
  }

  public loadWebChatScript(): void {
    if (!this.webChatScriptLoaded) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.onload = () => this.webChatScriptLoadedCallback()
      script.src = `${this.getApiBaseUrl()}/widget.js`
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }

  public destroyWebChatWidget(): void {
    if (this.widget) {
      this.widget.destroy()
      this.widget = null
    }
  }

  public initWebChatWidget(channelId: string, extraParams = {}): void {
    if (!this.widget && this.webChatScriptLoaded) {
      this.widget = window['HotBot'].init({ appId: channelId, ...extraParams })
    }
  }

  public openChat() {
    if (this.widget) {
      this.widgetInstance.openChat()
    }
  }

  public showWidget() {
    if (this.widget) {
      this.widgetInstance.showWidget()
    }
  }

  public hideWidget() {
    if (this.widgetInstance) {
      this.widgetInstance.hideWidget()
    }
  }

  public passOnboarding() {
    if (this.widgetInstance) {
      this.widgetInstance.passOnboarding()
    }
  }

  public isWidgetReady() {
    return this.widgetInstance && this.widgetInstance.isReady()
  }

  private webChatScriptLoadedCallback(): void {
    this.webChatScriptLoaded = true
    this.widgetInstance = window['HotBot']
    // console.log('Webchat loaded = ', this.webChatScriptLoaded)
  }
}
