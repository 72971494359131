import { Action } from '@ngrx/store'

import { FormResponseStatus, Statistics } from '@models'
import { NormalizedResults } from '@models/api-normalized-responses'

export enum DashboardActionTypes {
  LoadStatistics = '[Dashboard] Load Statistics',
  LoadStatisticsSuccess = '[Dashboard] Load Statistics Success',
  LoadStatisticsFail = '[Dashboard] Load Statistics Fail',
  LoadFormResponses = '[Dashboard] Load Form Responses',
  LoadFormResponsesSuccess = '[Dashboard] Load Form Responses Success',
  LoadFormResponsesFail = '[Dashboard] Load Form Responses Fail',
}

/**
 * Load form responses
 */
export interface LoadFormResponsesPayload {
  propertyId: string
  statuses?: FormResponseStatus[]
}

export class LoadFormResponses implements Action {
  readonly type = DashboardActionTypes.LoadFormResponses
  constructor(public payload: LoadFormResponsesPayload) {}
}

export class LoadFormResponsesSuccess implements Action {
  readonly type = DashboardActionTypes.LoadFormResponsesSuccess
  constructor(public payload: NormalizedResults, public meta: LoadFormResponsesPayload) {}
}

export class LoadFormResponsesFail implements Action {
  readonly type = DashboardActionTypes.LoadFormResponsesFail
  constructor(public error: any, public meta: LoadFormResponsesPayload) {}
}

/**
 * Load stats
 */
export interface LoadStatisticsPayload {
  propertyId: string
  segmentsNumber: number // number of chat bot segments
  language?: string
  begin?: string
  end?: string
}

export class LoadStatistics implements Action {
  readonly type = DashboardActionTypes.LoadStatistics
  constructor(public payload: LoadStatisticsPayload) {}
}

export class LoadStatisticsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadStatisticsSuccess
  constructor(public payload: Statistics, public meta: LoadStatisticsPayload) {}
}

export class LoadStatisticsFail implements Action {
  readonly type = DashboardActionTypes.LoadStatisticsFail
  constructor(public error: any, public meta: LoadStatisticsPayload) {}
}

export type DashboardActions =
  | LoadStatistics
  | LoadStatisticsSuccess
  | LoadStatisticsFail
  | LoadFormResponses
  | LoadFormResponsesSuccess
  | LoadFormResponsesFail
