import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core'
import { Cart, LanguageItem } from '@models'
import * as chatUtils from '@models/chat.model'
import { CartsHeaders } from '@shared/constants'
import * as R from 'ramda'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { langLabel } from '@shared/utils/langLabel'
import { ConfigService, I18nService } from '@shared/services'

@Component({
  selector: 'hot-carts-list',
  templateUrl: './carts-list.component.html',
  styleUrls: ['./carts-list.component.scss'],
})
export class CartsListComponent implements OnInit, OnDestroy {
  @Input() public theme: 'table' | 'list' | 'user-list' = 'table'
  @Input() public carts: Cart[] = []
  @Input() public propertyId: string
  @Input() public availableLanguages: LanguageItem[]
  @Input() public cartType: 'orders' | 'shop-orders'
  public columnHeaders = [
    CartsHeaders.OrderNumber,
    CartsHeaders.Date,
    CartsHeaders.Guest,
    CartsHeaders.RoomNumber,
    CartsHeaders.Status,
  ]

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public readonly configService: ConfigService,
    public readonly translation: I18nService
  ) {
    this.availableLanguages = this.configService.languages
  }

  public getLanguageLabel(langCode): string {
    return langLabel(this.translation, langCode, this.availableLanguages)
  }

  public ngOnInit() {}
  public ngOnDestroy() {}

  public getRoomNumber(cart: Cart): string {
    return cart.__chat && chatUtils.getFormattedRoomNumbers(cart.__chat)
  }

  public showColumn(columnName): boolean {
    return this.columnHeaders && R.contains(columnName, this.columnHeaders)
  }
}
