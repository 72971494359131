import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  AfterViewInit,
  Inject,
} from '@angular/core'
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FormField, FormFieldType } from '@models'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

type FormFieldWithIdx = Partial<FormField> & { idx: number }

const END_FORM_ACTION = {
  id: '~finish~',
  title: 'FormQuestion.action.label.finish',
}

const END_SILENTLY_FORM_ACTION = {
  id: '~finish_silently~',
  title: 'FormQuestion.action.label.finishSilently',
}

const CANCEL_FORM_ACTION = {
  id: '~cancel~',
  title: 'FormQuestion.action.label.cancel',
}

@Component({
  selector: 'hot-form-field-action',
  templateUrl: './form-field-action.component.html',
  styleUrls: ['./form-field-action.component.scss'],
})
export class FormFieldActionComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() public defaultLanguage
  @Input() public language
  @Input() public showIdInputs = false
  @Input() public config: FormControl
  @Output() public changed = new EventEmitter()

  public END_FORM_ACTION = END_FORM_ACTION
  public END_SILENTLY_FORM_ACTION = END_SILENTLY_FORM_ACTION
  public CANCEL_FORM_ACTION = CANCEL_FORM_ACTION
  public form: FormGroup
  public otherFormFields: FormFieldWithIdx[]
  public isLastQuestion = true
  private formFieldsSubscription: Subscription = null
  private destroyed = new Subject()

  public getCurrentAction() {
    const id = this.form.get('nextQuestion').value
    return this.getFieldById(id)
  }

  public getFieldById(id) {
    if (id === END_FORM_ACTION.id) {
      return END_FORM_ACTION
    }

    if (id === CANCEL_FORM_ACTION.id) {
      return CANCEL_FORM_ACTION
    }

    if (id === END_SILENTLY_FORM_ACTION.id) {
      return END_SILENTLY_FORM_ACTION
    }

    if (id) {
      return this.otherFormFields.find(x => x.id === id)
    }

    return null
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    this.form = this.fb.group({
      nextQuestion: this.fb.control(null),
    })
  }

  ngAfterViewInit() {
    // this.answerInputs.changes.pipe(takeUntil(this.destroyed)).subscribe(() => {
    //   if (this.answerInputs.length > 1 && this.focusLastField) {
    //     this.answerInputs.last.nativeElement.focus()
    //   }
    // })
  }

  updateFormFieldsDropdown() {
    const currentFormField = this.config.parent.value as FormField
    const formFields = this.config.parent.parent.value as FormField[]
    const currentFieldId = currentFormField.id
    const currentFieldIdx = formFields.findIndex(x => x.id === currentFieldId)
    this.isLastQuestion = currentFieldIdx + 1 === formFields.length
    this.otherFormFields = formFields
      .map((x, idx) => {
        const title = x.type === FormFieldType.Message ? x.label : x.title
        return { idx, id: x.id, title, type: x.type }
      })
      .slice(currentFieldIdx + 2)
      .filter(x => x.type !== FormFieldType.Action)
    // const currentField = this.getCurrentAction() as FormFieldWithIdx
    // if (!currentField || currentField.type === FormFieldType.Action) {
    //   this.setNextPage(null)
    // }
  }

  setNextPage(pageId) {
    this.form.patchValue({
      nextQuestion: pageId,
    })
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
      this.changed.next({ ...this.config.value, ...val })
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.form.patchValue({ nextQuestion: this.config.value.nextQuestion })
      this.updateFormFieldsDropdown()

      const formFields = this.config.parent.parent as FormArray
      if (this.formFieldsSubscription) {
        this.formFieldsSubscription.unsubscribe()
        this.formFieldsSubscription = null
      }
      this.formFieldsSubscription = formFields.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.updateFormFieldsDropdown()
        })
    }
  }
}
