export const DEFAULT_PAGINATION_SIZE = 10

export interface PaginationOptions {
  page: number
  limit: number
}

export const PageSizeOptions: number[] = [
  DEFAULT_PAGINATION_SIZE,
  DEFAULT_PAGINATION_SIZE * 2,
  DEFAULT_PAGINATION_SIZE * 3,
]
