import { Action } from '@ngrx/store'

import {
  NormalizedEntities,
  NormalizedResult,
  NormalizedResults,
  ChatMessage,
  FormResponseStatus,
  ShopCartStatus,
  ChatAttachment,
  ChatPageVisit,
  ChatFilters,
  Chat,
} from '@models'

export enum ActionTypes {
  Select = '[Chat] Select chat',
  ReceivedMessage = '[Chat] Received message',
  EditedMessages = '[Chat] Edited messages',
  SelectTab = '[Chat] Select chats tab',

  Load = '[Chat] Load Chats',
  LoadSuccess = '[Chat] Load Chats Success',
  LoadFail = '[Chat] Load Chats Fail',

  LoadMessages = '[Chat] Load Messages',
  LoadMessagesSuccess = '[Chat] Load Messages Success',
  LoadMessagesFail = '[Chat] Load Messages Fail',

  LoadVisitedPages = '[Chat] Load Visisted Pages',
  LoadVisitedPagesSuccess = '[Chat] Load Visisted Pages Success',
  LoadVisitedPagesFail = '[Chat] Load Visisted Pages Fail',

  SearchMessages = '[Chat] Search Messages',
  SearchMessagesSuccess = '[Chat] Search Messages Success',
  SearchMessagesFail = '[Chat] Search Messages Fail',

  Join = '[Chat] Join',
  JoinSuccess = '[Chat] Join Success',
  JoinFail = '[Chat] Join Fail',

  Leave = '[Chat] Leave',
  LeaveSuccess = '[Chat] Leave Success',
  LeaveFail = '[Chat] Leave Fail',

  Assign = '[Chat] Assign',
  AssignSuccess = '[Chat] Assign Success',
  AssignFail = '[Chat] Assign Fail',

  UnsubscribePromo = '[Chat] UnsubscribePromo',
  UnsubscribePromoSuccess = '[Chat] UnsubscribePromo Success',
  UnsubscribePromoFail = '[Chat] UnsubscribePromo Fail',

  SendMessage = '[Chat] Send message',
  SendMessageSuccess = '[Chat] Send message success',
  SendMessageFail = '[Chat] Send message fail',

  LoadFormResponses = '[Chat] Load form responses',
  LoadFormResponsesSuccess = '[Chat] Load form responses success',
  LoadFormResponsesFail = '[Chat] Load form responses fail',

  LoadShopCarts = '[Chat] Load shop carts responses',
  LoadShopCartsSuccess = '[Chat] Load shop carts success',
  LoadShopCartsFail = '[Chat] Load shop carts fail',

  Typing = '[Chat] Typing',

  UpdateChat = '[Chat] Update chat',
}

/**
 * Select action
 */
export class Select implements Action {
  readonly type = ActionTypes.Select
  constructor(public payload: { chatId: string }) {}
}

export class ReceivedMessage implements Action {
  readonly type = ActionTypes.ReceivedMessage
  constructor(public payload: { message: ChatMessage }) {}
}

export class EditedMessages implements Action {
  readonly type = ActionTypes.EditedMessages
  constructor(public payload: { messages: ChatMessage[] }) {}
}

export class SelectTab implements Action {
  readonly type = ActionTypes.SelectTab
  constructor(public payload: { tabIndex: number }) {}
}

/**
 * Load actions
 */

export interface LoadPayload {
  propertyId: string
  params?: ChatFilters
}

export class Load implements Action {
  readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load messages actions
 */

export interface LoadMessagesPayload {
  chatId: string
}

export class LoadMessages implements Action {
  readonly type = ActionTypes.LoadMessages
  constructor(public payload: LoadMessagesPayload) {}
}

export class LoadMessagesSuccess implements Action {
  readonly type = ActionTypes.LoadMessagesSuccess
  constructor(public payload: NormalizedEntities, public meta: LoadMessagesPayload) {}
}

export class LoadMessagesFail implements Action {
  readonly type = ActionTypes.LoadMessagesFail
  constructor(public error: any, public meta: LoadMessagesPayload) {}
}

/**
 * Load visited pages actions
 */

export interface LoadVisitedPagesPayload {
  chatId: string
}

export class LoadVisitedPages implements Action {
  readonly type = ActionTypes.LoadVisitedPages
  constructor(public payload: LoadVisitedPagesPayload) {}
}

export class LoadVisitedPagesSuccess implements Action {
  readonly type = ActionTypes.LoadVisitedPagesSuccess
  constructor(
    public payload: { chat: string; visitedPages: ChatPageVisit[] },
    public meta: LoadVisitedPagesPayload
  ) {}
}

export class LoadVisitedPagesFail implements Action {
  readonly type = ActionTypes.LoadVisitedPagesFail
  constructor(public error: any, public meta: LoadVisitedPagesPayload) {}
}

/**
 * Search messages actions
 */

export interface LoadMessagesPayload {
  chatId: string
}

export class SearchMessages implements Action {
  readonly type = ActionTypes.SearchMessages
  constructor(public payload: { propertyId; chatId; query }) {}
}

export class SearchMessagesSuccess implements Action {
  readonly type = ActionTypes.SearchMessagesSuccess
  constructor(public payload: NormalizedEntities, public meta: LoadMessagesPayload) {}
}

export class SearchMessagesFail implements Action {
  readonly type = ActionTypes.SearchMessagesFail
  constructor(public error: any, public meta: LoadMessagesPayload) {}
}

/**
 * Load form responses actions
 */

export interface LoadFormResponsesPayload {
  chatId: string
  propertyId: string
  statuses: FormResponseStatus[]
}

export class LoadFormResponses implements Action {
  readonly type = ActionTypes.LoadFormResponses
  constructor(public payload: LoadFormResponsesPayload) {}
}

export class LoadFormResponsesSuccess implements Action {
  readonly type = ActionTypes.LoadFormResponsesSuccess
  constructor(public payload: NormalizedEntities, public meta: LoadFormResponsesPayload) {}
}

export class LoadFormResponsesFail implements Action {
  readonly type = ActionTypes.LoadFormResponsesFail
  constructor(public error: any, public meta: LoadFormResponsesPayload) {}
}

export interface LoadShopCartPayload {
  chatId: string
  propertyId: string
  statuses: ShopCartStatus[]
}

export class LoadShopCarts implements Action {
  readonly type = ActionTypes.LoadShopCarts
  constructor(public payload: LoadShopCartPayload) {}
}

export class LoadShopCartsSuccess implements Action {
  readonly type = ActionTypes.LoadShopCartsSuccess
  constructor(public payload: NormalizedEntities, public meta: LoadShopCartPayload) {}
}

export class LoadShopCartsFail implements Action {
  readonly type = ActionTypes.LoadShopCartsFail
  constructor(public error: any, public meta: LoadShopCartPayload) {}
}

/**
 * Join to chat actions
 */

export interface JoinPayload {
  chatId: string
}

export class Join implements Action {
  readonly type = ActionTypes.Join
  constructor(public payload: JoinPayload) {}
}

export class JoinSuccess implements Action {
  readonly type = ActionTypes.JoinSuccess
  constructor(public payload: NormalizedResult, public meta: JoinPayload) {}
}

export class JoinFail implements Action {
  readonly type = ActionTypes.JoinFail
  constructor(public error: any, public meta: JoinPayload) {}
}

/**
 * Leave to chat actions
 */

export interface LeavePayload {
  chatId: string
}

export class Leave implements Action {
  readonly type = ActionTypes.Leave
  constructor(public payload: LeavePayload) {}
}

export class LeaveSuccess implements Action {
  readonly type = ActionTypes.LeaveSuccess
  constructor(public payload: NormalizedResult, public meta: LeavePayload) {}
}

export class LeaveFail implements Action {
  readonly type = ActionTypes.LeaveFail
  constructor(public error: any, public meta: LeavePayload) {}
}

/**
 * Assign to chat actions
 */

export interface AssignPayload {
  chatId: string
  formId: string
}

export class Assign implements Action {
  readonly type = ActionTypes.Assign
  constructor(public payload: AssignPayload) {}
}

export class AssignSuccess implements Action {
  readonly type = ActionTypes.AssignSuccess
  constructor(public payload: NormalizedResult, public meta: AssignPayload) {}
}

export class AssignFail implements Action {
  readonly type = ActionTypes.AssignFail
  constructor(public error: any, public meta: AssignPayload) {}
}

/**
 * UnsubscribePromo to chat actions
 */

export interface UnsubscribePromoPayload {
  chatId: string
}

export class UnsubscribePromo implements Action {
  readonly type = ActionTypes.UnsubscribePromo
  constructor(public payload: UnsubscribePromoPayload) {}
}

export class UnsubscribePromoSuccess implements Action {
  readonly type = ActionTypes.UnsubscribePromoSuccess
  constructor(public payload: NormalizedResult, public meta: UnsubscribePromoPayload) {}
}

export class UnsubscribePromoFail implements Action {
  readonly type = ActionTypes.UnsubscribePromoFail
  constructor(public error: any, public meta: UnsubscribePromoPayload) {}
}

/**
 * Send message to chat actions
 */

export interface SendMessagePayload {
  chatId: string
  message: { photo?: string; file?: ChatAttachment; text?: string }
}

export class SendMessage implements Action {
  readonly type = ActionTypes.SendMessage
  constructor(public payload: SendMessagePayload) {}
}

export class SendMessageSuccess implements Action {
  readonly type = ActionTypes.SendMessageSuccess
  constructor(public payload: NormalizedResult, public meta: SendMessagePayload) {}
}

export class SendMessageFail implements Action {
  readonly type = ActionTypes.SendMessageFail
  constructor(public error: any, public meta: SendMessagePayload) {}
}

export class Typing implements Action {
  readonly type = ActionTypes.Typing
  constructor(public payload: { chatId: string }) {}
}

export class UpdateChat implements Action {
  readonly type = ActionTypes.UpdateChat
  constructor(public payload: { chat: Chat; modifiedPaths: string[] }) {}
}

export type Actions =
  | Select
  | SelectTab
  | ReceivedMessage
  | EditedMessages
  | Load
  | LoadSuccess
  | LoadFail
  | LoadMessages
  | LoadMessagesSuccess
  | LoadMessagesFail
  | LoadVisitedPages
  | LoadVisitedPagesSuccess
  | LoadVisitedPagesFail
  | SearchMessages
  | SearchMessagesSuccess
  | SearchMessagesFail
  | Join
  | JoinSuccess
  | JoinFail
  | Leave
  | LeaveSuccess
  | LeaveFail
  | UnsubscribePromo
  | UnsubscribePromoSuccess
  | UnsubscribePromoFail
  | SendMessage
  | SendMessageSuccess
  | SendMessageFail
  | LoadFormResponses
  | LoadFormResponsesSuccess
  | LoadFormResponsesFail
  | Typing
  | LoadShopCarts
  | LoadShopCartsSuccess
  | LoadShopCartsFail
  | UpdateChat
