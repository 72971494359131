import { Component, Input, Output, Inject, EventEmitter } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { ShopProductCategory } from '@models'

@Component({
  selector: 'hot-shop-product-category-card',
  templateUrl: './shop-product-category-card.component.html',
  styleUrls: ['./shop-product-category-card.component.scss'],
})
export class ShopProductCategoryCardComponent {
  @Input() category: ShopProductCategory
  @Input() lang: string
  @Input() selected = false
  @Input() clickable = false
  @Input() draggable = false
  @Output() click = new EventEmitter()

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  public onClick(event) {
    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
    this.click.next(event)
  }
}
