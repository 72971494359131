import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { MenuPagesTemplates } from '@models'

@Injectable()
export class MenuPagesTemplatesService {
  public get(): Observable<any> {
    return this.apiService.get(`templates/menu-pages`)
  }

  public create(languages = []): Observable<MenuPagesTemplates> {
    return this.apiService.post(`templates/menu-pages`, { languages })
  }

  public update(menuPageId: string, params: any): Observable<MenuPagesTemplates> {
    const data = params || {}
    return this.apiService.patch(`templates/menu-pages/${menuPageId}`, {
      ...data,
      name: data.name || null,
      tracking: data.tracking || null,
      content: data.content || [],
      lang: data.lang || null,
      languages: data.languages || [],
      translateName: data.translateName || false,
    })
  }

  public delete(templateId): Observable<MenuPagesTemplates> {
    return this.apiService.delete(`templates/menu-pages/${templateId}`, {})
  }

  constructor(private apiService: ApiService) {}
}
