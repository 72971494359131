export type UserRolePermission =
  | 'apps.read'
  | 'apps.create'
  | 'admin.facilities'
  | 'analytics.read'
  | 'bots.configureAll'
  | 'bots.create'
  | 'bots.delete'
  | 'bots.read'
  | 'bots.update'
  | 'cuisines.create'
  | 'cuisines.delete'
  | 'cuisines.read'
  | 'cuisines.update'
  | 'faq.create'
  | 'faq.delete'
  | 'faq.read'
  | 'faq.update'
  | 'legal-entities.read'
  | 'legal-entities.create'
  | 'legal-entities.update'
  | 'legal-entities.delete'
  | 'properties.create'
  | 'properties.create_with_menu'
  | 'properties.read'
  | 'properties.read_all'
  | 'properties.delete'
  | 'properties.update_config'
  | 'reservations.create'
  | 'reservations.read'
  | 'restaurants.create'
  | 'restaurants.delete'
  | 'restaurants.read'
  | 'restaurants.update'
  | 'sales-service-settings.create'
  | 'sales-service-settings.read'
  | 'sales-service-settings.update'
  | 'sales-service-settings.delete'
  | 'templates.form.create'
  | 'templates.form.delete'
  | 'templates.form.read'
  | 'templates.form.update'
  | 'templates.menu-page.create'
  | 'templates.menu-page.delete'
  | 'templates.menu-page.read'
  | 'templates.menu-page.update'

export type UserPropertyPermission =
  | 'property.stats'
  | 'property.facilities.read'
  | 'property.facilities.create'
  | 'property.facilities.update'
  | 'property.bots.create'
  | 'property.bots.read'
  | 'property.cjm.read'
  | 'property.cjm.create'
  | 'property.cjm.update'
  | 'property.chats.read_all'
  | 'property.chats.read'
  | 'property.chats.close'
  | 'property.chats.join'
  | 'property.chats.assign'
  | 'property.chats.unsubscribe'
  | 'property.faq.update'
  | 'property.channels.read'
  | 'property.channels.create'
  | 'property.channels.update'
  | 'property.integrations.read'
  | 'property.integrations.create'
  | 'property.integrations.create_sftp'
  | 'property.integrations.update'
  | 'property.issues.read'
  | 'property.issues.ai.read'
  | 'property.webhooks.read'
  | 'property.webhooks.create'
  | 'property.webhooks.update'
  | 'property.requests.read'
  | 'property.requests.update'
  | 'property.requests.read_all'
  | 'property.requests.update_all'
  | 'property.orders.read'
  | 'property.orders.update'
  | 'property.orders.read_all'
  | 'property.orders.update_all'
  | 'property.delivery.read'
  | 'property.delivery.create'
  | 'property.delivery.update'
  | 'property.forms.read'
  | 'property.forms.create'
  | 'property.forms.update'
  | 'property.forms.delete'
  | 'property.forms.read_all'
  | 'property.forms.update_all'
  | 'property.members.invite'
  | 'property.members.read'
  | 'property.members.update'
  | 'property.members.update_roles'
  | 'property.members.delete'
  | 'property.menu.read'
  | 'property.menu.update'
  | 'property.menu.delete'
  | 'property.messages.read'
  | 'property.messages.send_direct'
  | 'property.messages.send_to_all'
  | 'property.shop.create'
  | 'property.shop.read'
  | 'property.shop.update'
  | 'property.shop.pause'
  | 'property.shop.fees.update'
  | 'property.shop.notifications.update'
  | 'property.shop.promocodes.read'
  | 'property.shop.promocodes.update'
  | 'property.shop.payments.update'
  | 'property.wifi.captiveportals.read'
  | 'property.wifi.captiveportals.create'
  | 'property.wifi.captiveportals.update'
  | 'property.wifi.captiveportals.delete'
  | 'property.wifi.sessions.read'
  | 'property.wifi.vouchers.read'
  | 'property.wifi.vouchers.create'
  | 'property.wifi.vouchers.delete'
  | 'property.read'
  | 'property.update'
  | 'property.delete'
  | 'property.apps.read'
  | 'property.apps.update'
  | 'property.reports'
  | 'property.devices.read'
  | 'property.devices.create'
  | 'property.devices.update'

export type UserPermission = UserRolePermission | UserPropertyPermission

export interface User {
  _id: string
  email: string
  name?: string
  phone?: string
  role?: string
  roleDescription?: string
  pickupPoint?: string
  createdAt?: Date
  password?: string
  locale?: string
  confirmationEmailSent?: boolean
  workingHours?: Array<{
    day: string
    from: string
    to: string
  }>
  managerModeToken?: string
  intensiveShopOrderNotification?: boolean
}

export type UserProfile = User & { permissions: UserPermission[] }

export const sortUserByName = ({ name: name1 }: User, { name: name2 }: User) =>
  name1.localeCompare(name2)

const includes = (str: string, query: string) => str.toLowerCase().includes(query)

export const filterUserByQuery = (query: string) => (user: User) =>
  query
    ? includes(user.name, query) ||
      includes(user.email, query) ||
      includes(user.roleDescription, query)
    : true
