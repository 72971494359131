import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { switchMap, map, catchError, tap, merge } from 'rxjs/operators'

import * as shopCartActions from './shop-cart.actions'
import { ShopCartService } from './shop-cart.service'
import { AnalyticsService } from '@redux/analytics'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class ShopCartEffects {
  /**
   * Load form responses effects
   */
  @Effect()
  load$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.Load),
    switchMap((action: shopCartActions.Load) => {
      const meta = action.payload
      return this.shopCartService
        .getList(action.payload.propertyId, {
          status: action.payload.status,
          page: action.payload.page,
          limit: action.payload.limit,
        })
        .pipe(
          map(x => new shopCartActions.LoadSuccess(x, meta)),
          catchError(x => of(new shopCartActions.LoadFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  loadFail$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.LoadFail),
    tap(() => {
      const loadError = this.translationService.translate('Request.error.loadError')
      this.matSnackBar.open(loadError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load item effects
   */
  @Effect()
  loadItem$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.LoadItem),
    merge(
      this.actions$.pipe(
        ofType(shopCartActions.ActionTypes.UpdateStatusFail),
        map((action: shopCartActions.UpdateStatusFail) => new shopCartActions.LoadItem(action.meta))
      )
    ),
    switchMap((action: shopCartActions.LoadItem) => {
      const meta = action.payload
      return this.shopCartService.get(action.payload.propertyId, action.payload.shopCartId).pipe(
        map(x => new shopCartActions.LoadItemSuccess(x, meta)),
        catchError(x => of(new shopCartActions.LoadItemFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  loadItemFail$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.LoadItemFail),
    tap(() => {
      const loadItemError = this.translationService.translate('Request.error.loadItemError')
      this.matSnackBar.open(loadItemError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load statistics effects
   */
  @Effect()
  loadStatistics$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.LoadStatistics),
    switchMap((action: shopCartActions.LoadStatistics) => {
      const meta = action.payload
      return this.analyticsService.getShopStatistics(meta).pipe(
        map(x => new shopCartActions.LoadStatisticsSuccess(x, meta)),
        catchError(x => of(new shopCartActions.LoadStatisticsFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  loadStatisticsFail$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.LoadStatisticsFail),
    tap(() => {
      const loadStatisticsError = this.translationService.translate(
        'Request.error.loadStatisticsError'
      )
      this.matSnackBar.open(loadStatisticsError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Update status effects
   */
  @Effect()
  public updateStatus$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.UpdateStatus),
    switchMap((action: shopCartActions.UpdateStatus) => {
      const meta = action.payload
      return this.shopCartService
        .updateStatus(action.payload.propertyId, action.payload.shopCartId, {
          status: action.payload.status,
          comment: action.payload.comment,
        })
        .pipe(
          map(x => new shopCartActions.UpdateStatusSuccess(x, meta)),
          catchError(x => of(new shopCartActions.UpdateStatusFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateStatusFail$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.UpdateStatusFail),
    tap(() => {
      const updateStatusError = this.translationService.translate('Request.error.updateStatusError')
      this.matSnackBar.open(updateStatusError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Cancellation Reject Effects
   */
  @Effect()
  public cancellationReject$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.CancellationReject),
    switchMap((action: shopCartActions.CancellationReject) => {
      const meta = action.payload
      return this.shopCartService
        .cancellationReject(action.payload.propertyId, action.payload.shopCartId)
        .pipe(
          map(x => new shopCartActions.CancellationRejectSuccess(x, meta)),
          catchError(x => of(new shopCartActions.CancellationRejectFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public cancellationRejectFail$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.CancellationRejectFail),
    tap(() => {
      const updateStatusError = this.translationService.translate(
        'Request.error.сancellationRejectError'
      )
      this.matSnackBar.open(updateStatusError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Cancellation Accept Effects
   */
  @Effect()
  public cancellationAccept$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.CancellationAccept),
    switchMap((action: shopCartActions.CancellationAccept) => {
      const meta = action.payload
      return this.shopCartService
        .cancellationAccept(
          action.payload.propertyId,
          action.payload.shopCartId,
          action.payload.comment
        )
        .pipe(
          map(x => new shopCartActions.CancellationAcceptSuccess(x, meta)),
          catchError(x => of(new shopCartActions.CancellationAcceptFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public cancellationAcceptFail$ = this.actions$.pipe(
    ofType(shopCartActions.ActionTypes.CancellationAcceptFail),
    tap(() => {
      const updateStatusError = this.translationService.translate(
        'Request.error.сancellationAcceptError'
      )
      this.matSnackBar.open(updateStatusError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private shopCartService: ShopCartService,
    private analyticsService: AnalyticsService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
