import * as R from 'ramda'
import { ProductCategoryActionTypes } from './product-category.actions'
import { RoomServiceActionTypes } from '../room-service/room-service.actions'
import { ProductCategory } from '@models'

export interface Substate {
  items: ProductCategory[]
  loaded: boolean
  loading: boolean
}

export interface State {
  ids: string[]
  loaded: boolean
  loading: boolean
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialSubstate: Substate = {
  items: [],
  loaded: false,
  loading: false,
}

export const initialState: State = {
  ids: [],
  loaded: false,
  loading: false,
  byPropertyId: {},
}

function substateReducer(state = initialSubstate, action): Substate {
  switch (action.type) {
    case ProductCategoryActionTypes.Load: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }

    case ProductCategoryActionTypes.LoadSuccess: {
      return {
        items: action.payload.results,
        loaded: true,
        loading: false,
      }
    }

    case ProductCategoryActionTypes.LoadFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }

    case RoomServiceActionTypes.LoadSuccess: {
      return {
        items: action.payload.productCategories.map(R.prop('_id')),
        loading: false,
        loaded: true,
      }
    }

    case ProductCategoryActionTypes.CreateSuccess: {
      return {
        items: [...state.items, ...action.payload.productCategories.map(R.prop('_id'))],
        loading: false,
        loaded: true,
      }
    }

    case ProductCategoryActionTypes.UpdateSuccess: {
      return {
        items: R.uniq([...state.items, ...action.payload.productCategories.map(R.prop('_id'))]),
        loading: false,
        loaded: true,
      }
    }

    case ProductCategoryActionTypes.DeleteSuccess: {
      return {
        ...state,
        items: R.without([action.meta.productCategoryId], state.items),
      }
    }

    default:
      return state
  }
}

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case RoomServiceActionTypes.LoadSuccess:
    case ProductCategoryActionTypes.Create:
    case ProductCategoryActionTypes.CreateSuccess:
    case ProductCategoryActionTypes.CreateFail:
    case ProductCategoryActionTypes.Load:
    case ProductCategoryActionTypes.LoadSuccess:
    case ProductCategoryActionTypes.LoadFail:
    case ProductCategoryActionTypes.DeleteSuccess:
    case ProductCategoryActionTypes.Update:
    case ProductCategoryActionTypes.UpdateSuccess:
    case ProductCategoryActionTypes.UpdateFail: {
      const query =
        action.type === ProductCategoryActionTypes.Create ||
        action.type === ProductCategoryActionTypes.Load ||
        action.type === ProductCategoryActionTypes.Update
          ? action.payload
          : action.meta
      return {
        ...state,
        ids: action.payload.productCategories
          ? R.uniq([...state.ids, ...action.payload.productCategories.map(R.prop('_id'))])
          : action.payload.deleted
            ? R.without([action.meta.productCategoryId], state.ids)
            : state.ids,
        byPropertyId: {
          ...state.byPropertyId,
          [query.propertyId]: substateReducer(state.byPropertyId[query.propertyId], action),
        },
      }
    }
    default: {
      return state
    }
  }
}

export const selectSubstates = (state: State) => state && state.byPropertyId
export const selectPropertySubstateProductCategoryIds = (state: Substate) => state && state.items
