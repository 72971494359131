import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

import { Store } from '@ngrx/store'

import { Observable, of } from 'rxjs'

import * as fromRoot from '@redux'
import * as fromFormResponse from '@redux/form-response'

@Injectable()
export class SelectFormResponseGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const id = route.params['formResponseId']

    this.store$.dispatch(new fromFormResponse.Select({ formResponseId: id }))

    return of(true)
  }
}
