import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { DOWN_ARROW } from '@angular/cdk/keycodes'
import {
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core'
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core'
import { MatFormField } from '@angular/material/form-field'
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input'
import { Subscription } from 'rxjs'
import { HotDatepickerComponent } from './datepicker'
import { createMissingDateImplError } from './datepicker-errors'
import * as R from 'ramda'

export const HOT_DATEPICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => HotDatepickerInputDirective),
  multi: true,
}

export const HOT_DATEPICKER_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => HotDatepickerInputDirective),
  multi: true,
}

export interface HotDatepickerRangeValue<D> {
  begin: D | null
  end: D | null
}

export class HotDatepickerInputEvent<D> {
  value: HotDatepickerRangeValue<D> | D | null

  constructor(public target: HotDatepickerInputDirective<D>, public targetElement: HTMLElement) {
    this.value = this.target.value
  }
}

@Directive({
  selector: 'input[hotDatepicker]',
  providers: [
    HOT_DATEPICKER_VALUE_ACCESSOR,
    HOT_DATEPICKER_VALIDATORS,
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: HotDatepickerInputDirective },
  ],
  exportAs: 'hotDatepickerInput',
})
export class HotDatepickerInputDirective<D> implements ControlValueAccessor, OnDestroy, Validator {
  @Output() readonly dateChange: EventEmitter<HotDatepickerInputEvent<D>> = new EventEmitter<
    HotDatepickerInputEvent<D>
  >()

  @Output() readonly dateInput: EventEmitter<HotDatepickerInputEvent<D>> = new EventEmitter<
    HotDatepickerInputEvent<D>
  >()
  private _datepicker: HotDatepickerComponent<D>
  public _dateFilter: (date: HotDatepickerRangeValue<D> | D | null) => boolean
  public _valueChange = new EventEmitter<HotDatepickerRangeValue<D> | D | null>()
  private _value: HotDatepickerRangeValue<D> | D | null
  private _min: D | null
  private _max: D | null
  private _disabled: boolean
  private _disabledChange = new EventEmitter<boolean>()
  private _datepickerSubscription = Subscription.EMPTY
  private _localeSubscription = Subscription.EMPTY
  private _isLastValueValid = false
  @HostBinding('class.hot-datepicker--input') className = 'hot-datepicker--input'
  private _parseValidator: ValidatorFn = (): ValidationErrors | null => {
    return this._isLastValueValid
      ? null
      : { matDatepickerParse: { text: this._elementRef.nativeElement.value } }
  }

  private _minValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (this._datepicker.rangeMode && control.value) {
      const beginDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.begin))
      const endDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.end))
      if (this.min) {
        if (beginDate && this._dateAdapter.compareDate(this.min, beginDate) > 0) {
          return { matDatepickerMin: { min: this.min, actual: beginDate } }
        }
        if (endDate && this._dateAdapter.compareDate(this.min, endDate) > 0) {
          return { matDatepickerMin: { min: this.min, actual: endDate } }
        }
      }
      return null
    }
    const controlValue = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value))
    return !this.min || !controlValue || this._dateAdapter.compareDate(this.min, controlValue) <= 0
      ? null
      : { matDatepickerMin: { min: this.min, actual: controlValue } }
  }

  private _maxValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (this._datepicker.rangeMode && control.value) {
      const beginDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.begin))
      const endDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.end))
      if (this.max) {
        if (beginDate && this._dateAdapter.compareDate(this.max, beginDate) < 0) {
          return { matDatepickerMax: { max: this.max, actual: beginDate } }
        }
        if (endDate && this._dateAdapter.compareDate(this.max, endDate) < 0) {
          return { matDatepickerMax: { max: this.max, actual: endDate } }
        }
      }
      return null
    }
    const controlValue = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value))
    return !this.max || !controlValue || this._dateAdapter.compareDate(this.max, controlValue) >= 0
      ? null
      : { matDatepickerMax: { max: this.max, actual: controlValue } }
  }

  private _filterValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (this._datepicker.rangeMode && control.value) {
      const beginDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.begin))
      const endDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.end))
      return !this._dateFilter ||
        (!beginDate && !endDate) ||
        (this._dateFilter(beginDate) && this._dateFilter(endDate))
        ? null
        : { matDatepickerFilter: true }
    }
    const controlValue = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value))
    return !this._dateFilter || !controlValue || this._dateFilter(controlValue)
      ? null
      : { matDatepickerFilter: true }
  }

  private _rangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (this._datepicker.rangeMode && control.value) {
      const beginDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.begin))
      const endDate = this._getValidDateOrNull(this._dateAdapter.deserialize(control.value.end))
      return !beginDate || !endDate || this._dateAdapter.compareDate(beginDate, endDate) <= 0
        ? null
        : { matDatepickerRange: true }
    }
    return null
  }
  // tslint:disable-next-line:member-ordering
  private _validator: ValidatorFn | null = Validators.compose([
    this._parseValidator,
    this._minValidator,
    this._maxValidator,
    this._filterValidator,
    this._rangeValidator,
  ])

  private _onTouched = () => {}
  private _cvaOnChange: (value: any) => void = () => {}
  private _validatorOnChange = () => {}

  @HostBinding('attr.aria-haspopup') get ariaHasPopup() {
    return this._datepicker ? 'dialog' : null
  }

  @HostBinding('attr.aria-haspopup') get ariaOwns() {
    return (R.prop('opened', this._datepicker) && R.prop('id', this._datepicker)) || null
  }
  @HostBinding('attr.min') get ariaMin() {
    return this.min ? this._dateAdapter.toIso8601(this.min) : null
  }
  @HostBinding('attr.max') get ariaMax() {
    return this.max ? this._dateAdapter.toIso8601(this.max) : null
  }

  @HostBinding('disabled') get isDisabled() {
    return this._disabled
  }

  @Input()
  set hotDatepicker(value: HotDatepickerComponent<D>) {
    if (!value) {
      return
    }

    this._datepicker = value
    this._datepicker._registerInput(this)
    this._datepickerSubscription.unsubscribe()

    this._datepickerSubscription = this._datepicker._selectedChanged.subscribe((selected: D) => {
      this.value = selected
      this._cvaOnChange(selected)
      this._onTouched()
      this.dateInput.emit(new HotDatepickerInputEvent(this, this._elementRef.nativeElement))
      this.dateChange.emit(new HotDatepickerInputEvent(this, this._elementRef.nativeElement))
    })
  }

  @Input()
  set matDatepickerFilter(value: (date: D | null) => boolean) {
    this._dateFilter = value
    this._validatorOnChange()
  }

  @Input()
  get value(): HotDatepickerRangeValue<D> | D | null {
    return this._value
  }
  set value(value: HotDatepickerRangeValue<D> | D | null) {
    if (value && value.hasOwnProperty('begin') && value.hasOwnProperty('end')) {
      const rangeValue = <HotDatepickerRangeValue<D>>value
      rangeValue.begin = this._dateAdapter.deserialize(rangeValue.begin)
      rangeValue.end = this._dateAdapter.deserialize(rangeValue.end)
      this._isLastValueValid =
        !rangeValue.begin ||
        !rangeValue.end ||
        (this._dateAdapter.isValid(rangeValue.begin) && this._dateAdapter.isValid(rangeValue.end))
      rangeValue.begin = this._getValidDateOrNull(rangeValue.begin)
      rangeValue.end = this._getValidDateOrNull(rangeValue.end)
      const oldValue = <HotDatepickerRangeValue<D> | null>this.value
      this._elementRef.nativeElement.value =
        rangeValue && rangeValue.begin && rangeValue.end
          ? this._dateAdapter.format(rangeValue.begin, this._dateFormats.display.dateInput) +
            ' - ' +
            this._dateAdapter.format(rangeValue.end, this._dateFormats.display.dateInput)
          : ''
      if (
        R.isNil(oldValue) ||
        !this._dateAdapter.sameDate(
          (<HotDatepickerRangeValue<D>>oldValue).begin,
          rangeValue.begin
        ) ||
        !this._dateAdapter.sameDate((<HotDatepickerRangeValue<D>>oldValue).end, rangeValue.end)
      ) {
        if (
          rangeValue.end &&
          rangeValue.begin &&
          this._dateAdapter.compareDate(rangeValue.begin, rangeValue.end) > 0
        ) {
          value = null
        }
        this._value = value
        this._valueChange.emit(value)
      }
    } else {
      value = this._dateAdapter.deserialize(value)
      this._isLastValueValid = !value || this._dateAdapter.isValid(value)
      value = this._getValidDateOrNull(value)
      const oldValue = this.value
      this._value = value
      this._elementRef.nativeElement.value = value
        ? this._dateAdapter.format(value, this._dateFormats.display.dateInput)
        : ''
      if (!this._dateAdapter.sameDate(<D>oldValue, value)) {
        this._valueChange.emit(value)
      }
    }
  }

  @Input()
  get min(): D | null {
    return this._min
  }
  set min(value: D | null) {
    this._min = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
    this._validatorOnChange()
  }

  @Input()
  get max(): D | null {
    return this._max
  }
  set max(value: D | null) {
    this._max = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
    this._validatorOnChange()
  }

  @Input()
  get disabled(): boolean {
    return !!this._disabled
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value)
    const element = this._elementRef.nativeElement

    if (this._disabled !== newValue) {
      this._disabled = newValue
      this._disabledChange.emit(newValue)
    }

    if (newValue && element.blur) {
      element.blur()
    }
  }

  constructor(
    private _elementRef: ElementRef<HTMLInputElement>,
    @Optional() public _dateAdapter: DateAdapter<D>,
    @Optional() @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    @Optional() private _formField: MatFormField
  ) {
    if (!this._dateAdapter) {
      throw createMissingDateImplError('DateAdapter')
    }
    if (!this._dateFormats) {
      throw createMissingDateImplError('MAT_DATE_FORMATS')
    }

    // Update the displayed date when the locale changes.
    this._localeSubscription = _dateAdapter.localeChanges.subscribe(() => {
      this.value = this.value
    })
  }

  ngOnDestroy() {
    this._datepickerSubscription.unsubscribe()
    this._localeSubscription.unsubscribe()
    this._valueChange.complete()
    this._disabledChange.complete()
  }

  /** @docs-private */
  registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn
  }

  /** @docs-private */
  validate(c: AbstractControl): ValidationErrors | null {
    return this._validator ? this._validator(c) : null
  }

  /**
   * Gets the element that the datepicker popup should be connected to.
   * @return The element to connect the popup to.
   */
  getConnectedOverlayOrigin(): ElementRef {
    return this._formField ? this._formField.getConnectedOverlayOrigin() : this._elementRef
  }

  // Implemented as part of ControlValueAccessor
  writeValue(value: HotDatepickerRangeValue<D> | D): void {
    this.value = value
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => void): void {
    this._cvaOnChange = fn
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  @HostListener('keydown', ['$event'])
  _onKeydown(event: KeyboardEvent) {
    const isAltDownArrow = event.altKey && event.keyCode === DOWN_ARROW

    if (this._datepicker && isAltDownArrow && !this._elementRef.nativeElement.readOnly) {
      this._datepicker.open()
      event.preventDefault()
    }
  }

  @HostListener('input', ['$event.target.value'])
  _onInput(value: string) {
    let date: HotDatepickerRangeValue<D> | D | null = null
    if (this._datepicker.rangeMode) {
      const parts = value.split('-')
      if (parts.length > 1) {
        const position = Math.floor(parts.length / 2)
        const beginDateString = parts.slice(0, position).join('-')
        const endDateString = parts.slice(position).join('-')
        let beginDate = this._dateAdapter.parse(beginDateString, this._dateFormats.parse.dateInput)
        let endDate = this._dateAdapter.parse(endDateString, this._dateFormats.parse.dateInput)
        this._isLastValueValid =
          !beginDate ||
          !endDate ||
          (this._dateAdapter.isValid(beginDate) && this._dateAdapter.isValid(endDate))
        beginDate = this._getValidDateOrNull(beginDate)
        endDate = this._getValidDateOrNull(endDate)
        if (beginDate && endDate) {
          date = <HotDatepickerRangeValue<D>>{ begin: beginDate, end: endDate }
        }
      }
    } else {
      date = this._dateAdapter.parse(value, this._dateFormats.parse.dateInput)
      this._isLastValueValid = !date || this._dateAdapter.isValid(date)
      date = this._getValidDateOrNull(date)
    }
    this._value = date
    this._cvaOnChange(date)
    this._valueChange.emit(date)
    this.dateInput.emit(new HotDatepickerInputEvent(this, this._elementRef.nativeElement))
  }

  @HostListener('change')
  _onChange() {
    this.dateChange.emit(new HotDatepickerInputEvent(this, this._elementRef.nativeElement))
  }

  /** Returns the palette used by the input's form field, if any. */
  _getThemePalette(): ThemePalette {
    return this._formField ? this._formField.color : undefined
  }

  @HostListener('blur')
  _onBlur() {
    if (this.value) {
      this._formatValue(this.value)
    }

    this._onTouched()
  }

  private _formatValue(value: HotDatepickerRangeValue<D> | D | null) {
    if (value && value.hasOwnProperty('begin') && value.hasOwnProperty('end')) {
      value = value as HotDatepickerRangeValue<D>
      this._elementRef.nativeElement.value =
        value && value.begin && value.end
          ? this._dateAdapter.format(value.begin, this._dateFormats.display.dateInput) +
            ' - ' +
            this._dateAdapter.format(value.end, this._dateFormats.display.dateInput)
          : ''
    } else {
      value = value as D | null
      this._elementRef.nativeElement.value = value
        ? this._dateAdapter.format(value, this._dateFormats.display.dateInput)
        : ''
    }
  }

  /**
   * @param obj The object to check.
   * @returns The given object if it is both a date instance and valid, otherwise null.
   */
  private _getValidDateOrNull(obj: any): D | null {
    return this._dateAdapter.isDateInstance(obj) && this._dateAdapter.isValid(obj) ? obj : null
  }
}
