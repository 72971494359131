import * as R from 'ramda'
import { PaginationOptions } from './pagination-options'
import { Chat } from './chat.model'

type TranslatableString = Array<{ lang: string; value: string }>
interface Notification {
  user: string
  types: ['sms' | 'email' | 'chat' | 'push']
}
type KpiNotification = Notification & {
  time: number
}
interface PriceModifier {
  type: 'fixed' | 'percent'
  value: number
}

interface ShopSectionEntityLink {
  emoji: string
  title: TranslatableString
  rootCategory: string
  category?: string
  product?: string
}
interface ShopSectionUrlLink {
  title?: TranslatableString
  emoji: string
  url: string
}
export enum ShopSectionLinkAction {
  OpenChat = 'open_chat',
}
interface ShopSectionActionLink {
  title?: TranslatableString
  emoji: string
  action: ShopSectionLinkAction
}

export type ShopSectionLink = ShopSectionEntityLink & ShopSectionUrlLink & ShopSectionActionLink

export interface ShopSection {
  title: TranslatableString
  items: ShopSectionLink[]
}

export type ShopDeliveryOption =
  | { type: 'delivery'; enabled: boolean }
  | { type: 'pickup'; enabled: boolean }

export interface Shop {
  _id?: string
  title?: TranslatableString
  currency: 'rub' | 'eur' | 'usd'
  sections: ShopSection[]
  paused?: boolean
  enablePromocodes: boolean
  checkoutComment: TranslatableString
}

export enum ShopProductKind {
  Product = 'product',
  Service = 'service',
}

export enum ShopProductFieldKind {
  Text = 'text',
  Date = 'date',
  Time = 'time',
  Options = 'options',
  Select = 'select',
  Checkbox = 'checkbox',
}

export interface ShopProductFieldOption {
  optionId?: string
  title: TranslatableString
  description?: TranslatableString
  default?: boolean
  additionalCost?: number
  quantity?: {
    value: number
    preventSellingWhenOutOfStock: boolean
  }
}
export interface ShopProductField {
  fieldId?: string
  title: TranslatableString
  description?: TranslatableString
  required: boolean
  kind: ShopProductFieldKind
  options: ShopProductFieldOption[]
  maxOptionsCount?: number
}

export enum ShopProductQuantityTrackingSource {
  Product = 'product',
  Option = 'option',
}

export interface ShopProduct {
  _id?: string
  title: TranslatableString
  description?: TranslatableString
  price: number
  pictures: string[]
  kind: ShopProductKind
  fields: ShopProductField[]
  display: boolean
  quantityTracking: boolean
  quantityTrackingSource: ShopProductQuantityTrackingSource
  quantity: {
    value: number
    preventSellingWhenOutOfStock: boolean
  }
}

export interface ShopProductCategoryFee {
  title: TranslatableString
  fee: PriceModifier
  applyTo?: ShopProductKind
}

export enum PaymentType {
  Cash = 'cash',
  Card = 'card',
  Online = 'online',
  Room = 'room',
}

export interface ShopProductCategory {
  _id?: string
  property: string
  shop: string
  alias: string
  title: TranslatableString
  description?: TranslatableString
  etaDescription?: TranslatableString
  etaValue?: number
  picture?: string
  display: boolean
  subcategories?: string[]
  products?: string[]
  workingHours?: Array<{
    day: string
    from: string
    to: string
  }>
  shopCartStatusDescription?: Array<{
    status: ShopCartStatus
    value: TranslatableString
  }>
  processingTime?: number
  notifications: Notification[]
  kpiNotifications: KpiNotification[]
  kpiTime: number
  instantCheckout?: boolean
  fees: ShopProductCategoryFee[]
  paymentTypes: PaymentType[]
  requiredInfo: {
    room: boolean
    phone: boolean
    email: boolean
  }
  paused?: boolean
}

export enum ShopCartEventType {
  Opened = 'opened',
  Canceled = 'canceled',
  PaymentWait = 'payment_wait',
  PaymentPreauth = 'payment_preauth',
  PaymentRefund = 'payment_refund',
  PaymentCancelled = 'payment_cancelled',
  PaymentComplete = 'payment_complete',
  InProgress = 'inprogress',
  Ready = 'ready',
  Done = 'done',
  UserNotified = 'user_notified',
  UserNotifyError = 'user_notify_error',
  ReceiptSent = 'receipt_sent',
  KpiTimeExpired = 'kpi_time_expired',
}
export interface ShopCartEventItem {
  event: ShopCartEventType
  body?: string
  oldValue?: any
  newValue?: any
  createdAt: Date | number
  updatedAt: Date | number
  isManager?: boolean
  user?: string
  chatUser?: string
  appName?: string
  eventMeta?: {
    sms: boolean
    email: boolean
    chat: boolean
  }
}

export enum ShopCartStatus {
  New = 'new',
  Open = 'open',
  WaitForPayment = 'wait_for_payment',
  InProgress = 'inprogress',
  Ready = 'ready',
  Done = 'done',
  CanceledByGuest = 'canceled_guest',
  CanceledByHotel = 'canceled_hotel',
  CancellationRequested = 'cancellation_requested',
}

export interface ShopCartItemFieldOption {
  optionId: string
  additionalCost?: number
  quantity?: number
  title?: TranslatableString
}
export interface ShopCartItemField {
  fieldId: string
  value?: string
  options?: ShopCartItemFieldOption[]
  title?: TranslatableString
}
export interface ShopCartItem {
  _id?: string
  product: string
  quantity: number
  price: number
  fields: ShopCartItemField[]
  itemPrice: number
  feesCost: number
  total: number
  title: TranslatableString
  category: string
  categoryTitle: TranslatableString
  __product?: ShopProduct
}

export type ShopCartFee = ShopProductCategoryFee & {
  total: number
}
export interface ShopCart {
  _id?: string
  createdAt: Date | number
  updatedAt: Date | number
  submittedAt?: Date | number
  orderNumber: string
  property: string
  chat: string
  shop: string
  shopProductCategory: string
  categoryTitle: TranslatableString
  items: ShopCartItem[]
  status: ShopCartStatus
  events: ShopCartEventItem[]
  details?: {
    payment: {
      paid: boolean
      method: 'cash' | 'card' | 'online'
      requiresChange?: boolean
      changeFrom?: number
    }
    personsCount: number
    delivery?: {
      deliveryType: 'delivery' | 'pickup'
      location?: {
        coordinates?: { lat: number; lng: number }
        address?: string
        addressDetails?: string
      }
      pickupPoint?: string
    }
    contacts?: {
      email?: string
      phone?: string
      room?: string
    }
  }
  readyAt?: Date
  processingTime?: number
  deliveryTime?: number
  fees: ShopCartFee[]
  itemsCost: number
  feesCost: number
  total: number
  discountedCosts?: {
    total: number
    deliveryCost: number
    orderServiceFeesCost: number
    itemsCost: number
  }
  userComment?: string
  currency: string
  cancellationReason?: string
  __chat?: Chat
  __pickupPoint?: ShopPickupPoint
  // __shopProducts?: R.Dictionary<ShopProduct>
  // __shopProductCategories?: R.Dictionary<ShopProductCategory>
}

export enum ShopPickupPointStatus {
  Active = 'active',
  Disabled = 'disabled',
}

export interface ShopPickupPointDeliveryFee {
  title: TranslatableString
  applyTo?: ShopProductKind
  fee?: PriceModifier // FIXME: remove after migration
  rankedFee?: Array<
    PriceModifier & {
      minDistance?: number
      maxDistance?: number
    }
  >
  freeDeliveryMinCost?: number | null
}
export interface ShopPickupPoint {
  _id?: any

  delivery: boolean
  deliveryTime: number
  processingTime: number
  pickup: boolean

  title: TranslatableString
  description: TranslatableString
  status: ShopPickupPointStatus
  coordinates: { lat: number; lng: number }
  radius?: number
  customMerchantSettings?: {
    publicKey?: string
    privateKey?: string
    merchant?: string
  }
  // deliveryFee?: ShopProductCategoryFee
  deliveryFee?: ShopPickupPointDeliveryFee
  minimalAmount?: number // DELETE:
  minimalAmountForDelivery?: number
  minimalAmountForPickup?: number
  workingHours?: Array<{
    day: string
    from: string
    to: string
  }>
  deliveryWorkingHours?: Array<{
    day: string
    from: string
    to: string
  }>
}

export interface ShopCartFilters {
  status?: ShopCartStatus
  paginationOptions?: PaginationOptions
}

export const filterShopProductsByQuery = (query: string) => (product: ShopProduct) =>
  query ? product.title && product.title.some(tr => tr.value.toLowerCase().includes(query)) : true

export const filterShopProductCategoriesByQuery = (query: string) => (
  category: ShopProductCategory
) =>
  query ? category.title && category.title.some(tr => tr.value.toLowerCase().includes(query)) : true

export const sortByTitle = (lang: string) => (
  { title: title1 }: ShopProduct | ShopProductCategory,
  { title: title2 }: ShopProduct | ShopProductCategory
) => {
  // @ts-ignore
  const value1 = R.prop('value', R.find(R.propEq('lang', lang), title1))
  // @ts-ignore
  const value2 = R.prop('value', R.find(R.propEq('lang', lang), title2))
  return value1 ? value1.localeCompare(value2) : 0
}

export const splitRootFromNestedCategories = (
  categories: ShopProductCategory[]
): [ShopProductCategory[], ShopProductCategory[]] =>
  // @ts-ignore
  R.partition(c => {
    return c.products.length || categories.some(x => x.subcategories.includes(c._id))
  }, categories).reverse()

export enum ShopStatisticsSource {
  Unknown = 'unknown',
  Website = 'website',
  Wifi = 'wifi',
  Email = 'email',
  Offline = 'offline',
  Direct = 'direct',
}
export interface ShopEmailStatistics {
  total: number
  opened: number
  clicked: number
}
export interface ShopStatisticsBySource {
  source: ShopStatisticsSource
  visitors: number
  shopVisitors: number
  // shopVisitorsRatio: number
  missedIncome: number
  income: number
  incomeOnline: number
  incomeOffline: number
  orders: number
  ordersRatio: number
}
export type ShopStatistics = ShopStatisticsBySource[]

export interface ShopServiceStatistics {
  shopSource: Array<{
    source: ShopStatisticsSource
    visitors: number
    shopVisitors: number
    missedIncome: Array<{ currency: string; value: number }>
    income: Array<{ currency: string; value: number }>
    orders: Array<{ currency: string; value: number }>
  }>
  emailsOpenRate: {
    total: number
    opened: number
    clicked: number
  }
  guests: {
    contacts: {
      guestsCount: number
      noContacts: number
      withEmail: number
      withPhone: number
      noContactsRatio: number
      withEmailRatio: number
      withPhoneRatio: number
    }
    emailConversion: {
      guestsCount: number
      clickedEmailPerGuest: number
      clickedEmailTotal: number
      openedEmailPerGuest: number
      openedEmailTotal: number
      recievedEmailPerGuest: number
      recievedEmailTotal: number
      clickedEmailPerGuestRatio: number
      openedEmailPerGuestRatio: number
      recievedEmailPerGuestRatio: number
      // guestsWithCjmOrder: number
      // guestsWithCjmOrderRatio: number
    }
  }
  payment: Array<{
    currency: string
    totalCount: number
    totalIncome: number
    averageBill: number
    online: {
      count: number
      total: number
      byStatus: Array<{
        status: ShopCartStatus
        count: number
        total: number
      }>
      byPaymentMethod: Array<{
        method: 'gpay' | 'applepay' | 'card'
        count: number
        total: number
      }>
    }
    offline: {
      count: number
      total: number
      byStatus: Array<{
        status: ShopCartStatus
        count: number
        total: number
      }>
      byPaymentMethod: Array<{
        method: 'card' | 'cash' | 'room'
        count: number
        total: number
      }>
    }
  }>
  retentionRate: {
    count: number
    twoOrMore: number
    threeOrMore: number
    fourOrMore: number
    fiveOrMore: number
    twoOrMoreRatio: number
    threeOrMoreRatio: number
    fourOrMoreRatio: number
    fiveOrMoreRatio: number
  }
  popularProducts: {
    product: Array<
      [
        number,
        { _id: string; title: TranslatableString; pictures: string[]; kind: ShopProductKind }
      ]
    >
    service: Array<
      [
        number,
        { _id: string; title: TranslatableString; pictures: string[]; kind: ShopProductKind }
      ]
    >
  }
  orderProcessing: {
    avgDelayTotal: number
    byUsers: Array<{
      user: {
        name: string
        email: string
        roleDescription: string
      }
      count: number
      avgDelay: number
    }>
  }
  ordersFromCjm: {
    count: number
    ratio: number
  }
}
