import { Pipe, PipeTransform } from '@angular/core'
import { I18nService } from '@shared/services'
import { ReservationStatus } from '@models'

@Pipe({
  name: 'reservationStatus',
})
export class ReservationStatusPipe implements PipeTransform {
  constructor(private translation: I18nService) {}
  transform(status: any): any {
    if (!status) {
      return this.translation.translate('Reservation.status.' + ReservationStatus.Unknown)
    }
    const key = 'Reservation.status.' + status
    return this.translation.translate(key)
  }
}
