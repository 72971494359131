import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from '@shared/services'

import {
  NormalizedResult,
  NormalizedResults,
  ChatFilters,
  ChatPageVisit,
  ChatCountFilters,
} from '@models'

@Injectable()
export class ChatService {
  constructor(public readonly apiService: ApiService) {}

  public getCount(
    propertyId: string,
    params?: ChatCountFilters
  ): Observable<{ chatsCount: number }> {
    return this.apiService.get(`properties/${propertyId}/chatsCount`, { params })
  }

  public getList(propertyId: string, params?: ChatFilters): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/chats`, { params })
  }

  public getMessages(propertyId: string, chatId: string): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/chats/${chatId}/messages`)
  }

  public getVisitedPages(
    propertyId: string,
    chatId: string
  ): Observable<{ chat: string; visitedPages: ChatPageVisit[] }> {
    return this.apiService.get(`properties/${propertyId}/chats/${chatId}/visitedPages`)
  }

  public searchMessages(
    propertyId: string,
    chatId: string,
    queryString: any
  ): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/chats/${chatId}/messages`, {
      params: queryString,
    })
  }

  public getFormResponses(
    propertyId: string,
    chatId: string,
    params?: any
  ): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/chats/${chatId}/formResponses`, {
      params: {
        status: params.statuses || [],
      },
    })
  }

  public getShopCarts(
    propertyId: string,
    chatId: string,
    params?: any
  ): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/chats/${chatId}/carts`, {
      params: {
        status: params.statuses || [],
      },
    })
  }

  public joinChat(propertyId: string, chatId: string): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/chats/${chatId}/pause`, null)
  }

  public leaveChat(propertyId: string, chatId: string): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/chats/${chatId}/unpause`, null)
  }

  public assignChat(
    propertyId: string,
    chatId: string,
    formId: string
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/chats/${chatId}/assign`, { form: formId })
  }

  public unsubscribeChat(propertyId: string, chatId: string): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/chats/${chatId}/unsubscribe`, null)
  }

  public sendMessage(
    propertyId: string,
    chatId: string,
    message: { photo?: string; text?: string }
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/chats/${chatId}/messages`, message)
  }
}
