import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { NormalizedResult } from '@models/api-normalized-responses'
import { Merchant } from '@models/merchant.model'

@Injectable()
export class MerchantService {
  constructor(public readonly apiService: ApiService) {}

  public create(merchantPaymentConfig: Merchant): Observable<NormalizedResult> {
    return this.apiService.post(`payment_service/merchant/`, merchantPaymentConfig)
  }

  public update(
    merchantId: string,
    merchantPaymentConfig: Merchant
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`payment_service/merchant/${merchantId}`, merchantPaymentConfig)
  }

  public delete(merchantId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`payment_service/merchant/${merchantId}`)
  }
}
