import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  Inject,
} from '@angular/core'
import {
  Property,
  PropertyKind,
  Form,
  BookingEngineType,
  UploadFileResult,
  UploadFileStatus,
  UploadFile,
  LanguageItem,
  BookingEngineLabels,
  CountryItem,
  BackofficeIntegraion,
  BackofficeIntegraionLabels,
  MapServices,
  MapServicesLabels,
  DevicesIntegration,
  DevicesIntegrationLabels,
} from '@models'
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms'
import * as R from 'ramda'
import * as moment from 'moment'

import {
  LanguageType,
  TIME_ZONES,
  DEFAULT_CHECK_IN_TIME,
  DEFAULT_CHECK_OUT_TIME,
} from '@shared/constants'
import {
  blankValidator,
  urlValidator,
  oneOfArrayValidator,
  jsonValidator,
  emailValidator,
  numberValidator,
  rangeValidator,
} from '@shared/validators'
import * as propertyUtils from '@models/property.model'
import { MenuPagesTemplates } from '@models/menu-pages-templates.model'
import { hasSpaceValidator } from '@shared/validators/has-space-validator'
import { ImageCropperComponent } from '@shared/components/image-cropper/image-cropper.component'
import { UploadFileComponent } from '@shared/components/upload-file/upload-file.component'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { ConfigService, I18nService, AuthService } from '@shared/services'
import { langLabel } from '../../utils/langLabel'
import * as translatableFieldUtils from '@models/translatable-field'
import { Observable, Subject, NEVER, of } from 'rxjs'
import { takeUntil, map, debounceTime, startWith, switchMap, withLatestFrom } from 'rxjs/operators'
import { UserProfileFacade } from '@shared/facades/user-profile.facade'

interface BookingEngineSettings {
  allowChildren: boolean
  allowChildrenAge?: boolean
  allowHourlyBooking: boolean
  allowDepartureOnDayOfArrival: boolean
}

const MAX_LENGTH = 100
const MAX_ADDRESS_LENGTH = 200
const MAX_DESCRIPTION_LENGTH = 600
const BOOKING_ENGINES_LIST = [
  BookingEngineType.None,
  BookingEngineType.AYS,
  BookingEngineType.FRONTDESK,
  BookingEngineType.TRAVELLINE,
  BookingEngineType.BOOKING,
  BookingEngineType.CUSTOM_FORM,
]
const BACKOFFICE_INTEGRATIONS = [BackofficeIntegraion.HKEEPER, BackofficeIntegraion.TEAMJET]
const DEVICES_INTEGRATIONS = [DevicesIntegration.SBERDEVICES]
const MAP_SERVICES = [MapServices.GOOGLE, MapServices.YANDEX]
const BOOKING_ENGINES_SETTINGS: R.Dictionary<BookingEngineSettings> = {
  default: {
    allowChildren: false,
    allowChildrenAge: false,
    allowHourlyBooking: false,
    allowDepartureOnDayOfArrival: false,
  },
  [BookingEngineType.AYS]: {
    allowChildren: false,
    allowChildrenAge: false,
    allowHourlyBooking: false,
    allowDepartureOnDayOfArrival: true,
  },
  [BookingEngineType.FRONTDESK]: {
    allowChildren: true,
    allowChildrenAge: false,
    allowHourlyBooking: true,
    allowDepartureOnDayOfArrival: true,
  },
  [BookingEngineType.TRAVELLINE]: {
    allowChildren: true,
    allowChildrenAge: true,
    allowHourlyBooking: false,
    allowDepartureOnDayOfArrival: true,
  },
  [BookingEngineType.BOOKING]: {
    allowChildren: true,
    allowChildrenAge: false,
    allowHourlyBooking: false,
    allowDepartureOnDayOfArrival: true,
  },
}
const MIN_LAT_VALUE = -90
const MAX_LAT_VALUE = 90
const MIN_LNG_VALUE = -180
const MAX_LNG_VALUE = 180

const PROPERTY_CONFIG_SETTINGS = [
  {
    type: 'select',
    options: ['strictInHouseWithRoomNumber', 'none'],
    key: 'authFlow',
    default: 'none',
  },
  { type: 'constant', key: 'fidelioOperaEmailSubject' },
  { type: 'checkbox', key: 'demoProperty', default: false, tooltip: true },
  { type: 'checkbox', key: 'aiEnabled', default: true, tooltip: true },
  { type: 'checkbox', key: 'reservationsEnabled', default: false, tooltip: true },
  { type: 'checkbox', key: 'adminConnectionEnabled', default: true },
  { type: 'checkbox', key: 'allowBookRestaurantsRequest', default: false, tooltip: true },
  { type: 'checkbox', key: 'authorizationAfterOnboarding', default: true, tooltip: true },
  { type: 'checkbox', key: 'bookingDuringOnboarding', default: true, tooltip: true },
  { type: 'checkbox', key: 'botEnabled', default: true },
  { type: 'checkbox', key: 'chatModeEnabled', default: false, tooltip: true },
  { type: 'checkbox', key: 'filterRestaurantsByCity', default: true, tooltip: true },
  { type: 'checkbox', key: 'filterRestaurantsByLocation', default: true, tooltip: true },
  { type: 'checkbox', key: 'formsEnabled', default: true },
  { type: 'checkbox', key: 'inviteBySms', default: false, tooltip: true },
  { type: 'checkbox', key: 'isHapiEnabled', default: false },
  { type: 'checkbox', key: 'loadPlacesFromKudaGo', default: true, tooltip: true },
  { type: 'checkbox', key: 'menuBuilderEnabled', default: true },
  { type: 'checkbox', key: 'ordersEnabled', default: true },
  { type: 'checkbox', key: 'pollsEnabled', default: false },
  { type: 'checkbox', key: 'sendInvitationEmails', default: true, tooltip: true },
  { type: 'checkbox', key: 'showMyRequestsButton', default: true, tooltip: true },
  { type: 'checkbox', key: 'wifiEnabled', default: false },
  { type: 'checkbox', key: 'salesServiceEnabled', default: false },
  { type: 'checkbox', key: 'splitCottagesFromRooms', default: false, tooltip: true },
  { type: 'checkbox', key: 'splitSeaViewRooms', default: false, tooltip: true },
  { type: 'checkbox', key: 'allowBookingPayment', default: false, tooltip: true },
  { type: 'checkbox', key: 'shopServiceEnabled', default: false, tooltip: false },
  { type: 'checkbox', key: 'hideSwitchPropertyButton', default: false, tooltip: false },
  { type: 'checkbox', key: 'isPMSIntegrationMonitoringEnabled', default: false, tooltip: true },
  { type: 'checkbox', key: 'isDevicesEnabled', default: false, tooltip: true },
]

const propertyKinds = R.values(PropertyKind)

const PREVIEW_PHOTO_WIDTH = 400

const getCurrentTimezone = () => {
  let result = null
  try {
    const timeZone = TIME_ZONES.find(
      tz => tz.value === Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    if (timeZone) {
      result = timeZone.value
    }
  } catch (e) {
    console.error('Error while processing timezone', e)
  }
  return result
}

enum FormTab {
  General = 'general',
  Contact = 'contact',
  Booking = 'booking',
  Content = 'content',
  AppleWallet = 'appleWallet',
  Admin = 'admin',
}

@Component({
  selector: 'hot-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.scss'],
})
export class PropertyFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public language = LanguageType.RU // Current language for content
  @Input() public property: Property
  @Input() public searchCityPending: boolean
  @Input() public searchCityResults: string[]
  @Input() public mainMenuPageTemplates: MenuPagesTemplates[] = []
  @Input() public customEngineForms: Form[] = []
  @Output() public submit = new EventEmitter()
  @Output() public searchCity = new EventEmitter()
  @Output() public cancel = new EventEmitter()

  public form = new FormGroup({})
  public uploadPhotoStatus = null
  public uploadLogoStatus = null
  public availableLanguages: string[]
  public countries: CountryItem[]
  public countryNames: string[]
  public bookingEngines = BOOKING_ENGINES_LIST
  public backofficeIntegrations = BACKOFFICE_INTEGRATIONS
  public devicesIntegration = DEVICES_INTEGRATIONS
  public mapServices = MAP_SERVICES
  public timeZones = TIME_ZONES
  public timeSteps

  public filteredCountries$: Observable<string[]>
  public filteredCities$: Observable<string[]>

  private logoUploaded = false
  private photoUploadChanged = false
  private logoUploadChanged = false
  private phoneRemoved = false

  private propertyLanguages = []
  public defaultLanguage: string

  public citySelected$ = new Subject()
  private destroyed$ = new Subject()

  public showAdminSection$ = this.userProfileFacade.canAccessAdminSection$

  // NOTE: Updated from observable in constructor
  public canEditConfig = false
  public canSelectMenuTemplate = false

  // private heads: { [key: string]: ElementRef }

  @ViewChild('logoCrop', { static: true }) public logoCrop: ImageCropperComponent
  @ViewChild('logoUploader', { static: true }) public logoUploader: UploadFileComponent
  @ViewChild('photoCrop', { static: true }) public photoCrop: ImageCropperComponent
  @ViewChild('photoUploader', { static: true }) public photoUploader: UploadFileComponent

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private fb: FormBuilder,
    public readonly authService: AuthService,
    public readonly configService: ConfigService,
    public i18n: I18nService,
    public readonly userProfileFacade: UserProfileFacade
  ) {
    this.countries = this.configService.countries
    this.countryNames = this.configService.countries.map(c => c.name)
    this.availableLanguages = this.configService.languages.map(lang => lang.code)
    this.timeSteps = this.generateTimeSteps()

    this.userProfileFacade
      .can('properties.update_config')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(canEditConfig => {
        this.canEditConfig = canEditConfig
      })
    this.userProfileFacade
      .can('properties.create_with_menu')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        this.canSelectMenuTemplate = value
      })
  }

  public ngOnInit() {
    this.initializeForm()
    if (this.property) {
      this.updateForm()
    } else {
      this.propertyLanguages = this.availableLanguages
      this.defaultLanguage = this.availableLanguages[0]
    }
    this.formControlValueChanges()
  }

  public ngOnChanges(changes): void {
    if (changes.property && !changes.property.firstChange) {
      this.updateForm()
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  public selectTab(tab: FormTab) {
    const target = document.querySelector(`[data-to="${tab}"]`)
    if (target) {
      this.scrollTo(target)
    }
  }

  private scrollTo(el, delay = 50) {
    if (!el) {
      return
    }
    setTimeout(() => {
      const topMargin = window.innerWidth >= 960 ? 144 : 15
      const offset = window.pageYOffset + el.getBoundingClientRect().top
      const offsetTop = el ? offset - topMargin : 0
      window.scrollTo(0, offsetTop)
    }, delay)
  }

  public get formTabs() {
    return FormTab
  }

  public get propertyKinds() {
    return propertyKinds
  }

  private formControlValueChanges() {
    const backofficeIntegrationControl = this.form.get('BackofficeIntegration')
    const backofficeIntegrationUrlControl = this.form.get('BackofficeIntegrationUrl')
    const backofficeIntegrationTokenControl = this.form.get('BackofficeIntegrationToken')

    backofficeIntegrationControl.valueChanges.subscribe((value: boolean) => {
      if (!value) {
        backofficeIntegrationUrlControl.patchValue('')
        backofficeIntegrationTokenControl.patchValue('')
      }
    })
  }

  public getBookingEngineLabel(bookingEngineType) {
    if (bookingEngineType === BookingEngineType.CUSTOM_FORM) {
      return this.i18n.translate('PropertyForm.field.bookingEngineCustomFormType')
    }
    return BookingEngineLabels[bookingEngineType]
  }

  public getBackofficeIntegrationLabel(type) {
    return BackofficeIntegraionLabels[type]
  }

  public getDevicesIntegrationLabel(type) {
    return DevicesIntegrationLabels[type]
  }

  public getMapServiceLabel(type) {
    return MapServicesLabels[type]
  }

  public get bookingEngineType() {
    const engineType = this.form.get('BookingEngineType').value
    return `ENGINE_TYPE: ${engineType}`
  }

  public get isCustomBookingEngine() {
    const engine = this.form.get('BookingEngineType').value
    return this.isCustomFormAllowedForEngine(engine)
  }

  isCustomFormAllowedForEngine(engine: BookingEngineType) {
    return engine === BookingEngineType.CUSTOM_FORM || engine === BookingEngineType.TRAVELLINE
  }

  public getFormName(form: Form) {
    return form && form.title && R.prop('value', form.title.find(R.propEq('lang', this.language)))
  }

  public get bookingEngineConfig() {
    const engineType = this.form.get('BookingEngineType').value
    return (
      (engineType && BOOKING_ENGINES_SETTINGS[engineType]) || BOOKING_ENGINES_SETTINGS['default']
    )
  }

  public get childrenAgeDisabled() {
    return !this.form.get('AllowChildren').value
  }

  public get engineRequiresExternalHotelId() {
    return [BookingEngineType.TRAVELLINE].includes(this.form.get('BookingEngineType').value)
  }

  public get engineAllowsChildren() {
    return this.bookingEngineConfig && this.bookingEngineConfig.allowChildren
  }

  public get engineAllowChildrenAge() {
    return this.bookingEngineConfig && this.bookingEngineConfig.allowChildrenAge
  }

  public get engineAllowsDepartureOnDayOfArrival() {
    return this.bookingEngineConfig && this.bookingEngineConfig.allowDepartureOnDayOfArrival
  }
  public get engineAllowsHourlyBooking() {
    return this.bookingEngineConfig && this.bookingEngineConfig.allowHourlyBooking
  }
  public get enableTranslate(): boolean {
    return this.propertyLanguages.length > 1
  }

  public get previewPhotoWidth(): number {
    return PREVIEW_PHOTO_WIDTH
  }

  public get maxLength(): number {
    return MAX_LENGTH
  }

  public get maxAddressLength(): number {
    return MAX_ADDRESS_LENGTH
  }

  public get maxDescriptionLength(): number {
    return MAX_DESCRIPTION_LENGTH
  }

  public onSubmit(translate = false): void {
    if (this.enableSubmit) {
      this.fillAlias()
      this.updateData(translate)
    } else {
      this.form.markAllAsTouched()
      this.form.updateValueAndValidity()
    }
  }

  public fillAlias() {
    if (!this.form.value.Alias) {
      this.form.get('Alias').patchValue((this.form.value && this.form.value.Name) || '')
    }
  }

  public onCancel(): void {
    this.cancel.next()
  }

  public get languagesControls() {
    return this.form && this.form.controls.Languages
      ? (this.form.controls.Languages as FormArray).controls
      : []
  }

  public getLanguageLabel(index: number): string {
    const langCode =
      this.form &&
      this.form.controls.Languages &&
      ((this.form.controls.Languages as FormArray).controls[index] as FormGroup).controls.Language
        .value.value
    return langLabel(this.i18n, langCode, this.availableLanguages)
  }

  public get enableSubmit(): boolean {
    return (
      this.form &&
      this.form.valid &&
      (this.form.dirty || this.photoUploadChanged || this.logoUploadChanged || this.phoneRemoved) &&
      this.logoUploaded &&
      Boolean(this.selectedLanguages.length)
    )
  }

  public get phoneControls(): AbstractControl[] {
    const phones = this.form.controls.Phones as FormArray
    return phones.controls
  }

  public get enableAddPhone(): boolean {
    const phones = this.form.controls.Phones as FormArray
    const phoneControls = phones.controls
    return phoneControls && R.all(c => c.valid, phoneControls)
  }

  private updatePhotoControlValue(value): void {
    this.form.patchValue({
      Photo: value,
    })
  }

  private updateLogoControlValue(value): void {
    this.form.patchValue({
      Logo: value,
    })
  }

  public onUploadLogo(result: UploadFileResult): void {
    if (result.status) {
      this.uploadLogoStatus = result.status
      this.logoUploadChanged = true
    }
    if (result.status === UploadFileStatus.COMPLETED) {
      const imageUrl = encodeURI(result.data.url)
      this.logoUploader.fileUrl = imageUrl
      this.logoUploaded = true
      this.updateLogoControlValue((result.data as UploadFile).url)
      this.logoCrop.cancelUploadImage()
    } else if (
      result.status === UploadFileStatus.CANCELLED_BY_USER ||
      result.status === UploadFileStatus.FAILED
    ) {
      this.logoCrop.cancelUploadImage()
      this.logoUploaded = false
      this.updateLogoControlValue('')
    }
  }

  public onUploadPhoto(result: UploadFileResult): void {
    if (result.status) {
      this.uploadPhotoStatus = result.status
      this.photoUploadChanged = true
    }
    if (result.status === UploadFileStatus.COMPLETED) {
      const imageUrl = encodeURI(result.data.url)
      this.photoUploader.fileUrl = imageUrl
      this.updatePhotoControlValue((result.data as UploadFile).url)
      this.photoCrop.cancelUploadImage()
    } else if (
      result.status === UploadFileStatus.CANCELLED_BY_USER ||
      result.status === UploadFileStatus.FAILED
    ) {
      this.photoCrop.cancelUploadImage()
      this.updatePhotoControlValue('')
    }
  }

  private get selectedLanguages() {
    return this.form.controls.Languages.value
      .filter(langObj => langObj.IsSelected)
      .map(langObj => langObj.Language.value)
  }

  private collectPhonesData(): propertyUtils.PropertyPhone[] {
    const phones = this.form.controls.Phones as FormArray
    return phones.controls.map((fg: FormGroup) => {
      const value = fg.controls.Value.value
      const label = fg.controls.Label.value
      const id = fg.controls.Id.value
      let labelTranslations = {}
      if (id) {
        const phone = R.find((p: propertyUtils.PropertyPhone) => p._id === id, this.property.phone)
        if (phone) {
          labelTranslations = phone.label
        }
      }
      const result = {
        value: value ? value.trim() : '',
        label: R.merge(labelTranslations, translatableFieldUtils.trimValue(label)),
      }
      if (
        result.label[this.defaultLanguage] &&
        result.label.default !== result.label[this.defaultLanguage]
      ) {
        result.label[this.defaultLanguage] = result.label.default
      }
      return result
    })
  }

  private collectPropertyInfo() {
    const data = this.form.value
    const result = {}
    this.availableLanguages.forEach(lang => {
      const address = data.Address && data.Address[lang] ? data.Address[lang] : ''
      result[lang] = {
        ...propertyUtils.getPropertyInfo(this.property, lang),
        address: address.trim(),
        photoUrl: data.Photo,
      }
    })
    return result
  }

  private collectPropertySharingSettings() {
    const data = this.form.value
    const result = {}

    this.availableLanguages.forEach(lang => {
      result[lang] = propertyUtils.generatePropertySharingSettings(data, lang)
    })

    return result
  }

  private getPropertyDescription() {
    const description = this.form.value.Description
    const result = {}

    this.availableLanguages.forEach(lang => {
      if (lang === this.defaultLanguage && description.default) {
        result[this.defaultLanguage] = description.default
      }
      result[lang] = description[lang] || ''
    })

    return result
  }

  private getPropertyLegalAgreement() {
    const legalAgreement = this.form.value.LegalAgreement
    const result = {}

    this.availableLanguages.forEach(lang => {
      if (lang === this.defaultLanguage && legalAgreement.default) {
        result[this.defaultLanguage] = legalAgreement.default
      }
      result[lang] = legalAgreement[lang] || ''
    })

    return result
  }

  private getPropertyTranslatableVkSharingText(): translatableFieldUtils.TranslatableField {
    const result = {
      default: propertyUtils.getPropertyVkSharingText(this.property, this.defaultLanguage) || '',
    }
    this.property.languages.forEach(lang => {
      const value = propertyUtils.getPropertyVkSharingText(this.property, lang)
      if (!result.hasOwnProperty(lang)) {
        result[lang] = {}
      }
      result[lang] = value || ''
    })
    return result
  }

  private getPropertyTranslatableAddress(): translatableFieldUtils.TranslatableField {
    const defaultInfo =
      this.property && this.property.info && this.property.info[this.defaultLanguage]
    const result = { default: (defaultInfo && defaultInfo.address) || '' }
    this.availableLanguages.forEach(lang => {
      if (this.property.info && this.property.info[lang]) {
        result[lang] = this.property.info[lang].address || ''
      }
    })
    return result
  }

  private updateData(translate = false): void {
    const data = this.form.value
    const currentSettings = this.property ? this.property.sharingSettings : {}
    const newSettings = this.collectPropertySharingSettings()
    const sharingSettings = {
      ...currentSettings,
      ...newSettings,
    }
    const property: Property = {
      name: data.Name,
      alias: data.Alias.replace(/\s/g, ''),
      defaultLanguage: this.defaultLanguage,
      phone: data.Phones ? this.collectPhonesData() : [],
      email: data.Email ? data.Email.trim() : '',
      website: data.Website ? data.Website.trim() : '',
      shopWebsite: data.ShopWebsite ? data.ShopWebsite.trim() : '',
      bookingEngine: {
        url: data.BookingEngineUrl || '',
        engineType: data.BookingEngineType || '',
        config: {
          children: data.AllowChildren,
          childrenAge: data.ChildrenAge,
          hourlyBooking: data.AllowHourlyBooking,
          departureOnDayOfArrival: data.AllowDepartureOnDayOfArrival,
          form: this.isCustomFormAllowedForEngine(data.BookingEngineType)
            ? data.BookingEngineForm || null
            : null,
        },
        externalId: data.BookingEngineExternalHotelId || null,
      },
      backofficeIntegration: data.BackofficeIntegration
        ? {
            provider: data.BackofficeIntegration,
            url: data.BackofficeIntegrationUrl,
            token: data.BackofficeIntegrationToken,
          }
        : { provider: null, url: null, token: null },
      mapService: data.MapService,
      sharingSettings,
      address: {
        location: {
          lat: data.Lat,
          lng: data.Lng,
        },
        countryCode: this.getCountryCode(data.Country),
        city: data.City,
      },
      info: this.collectPropertyInfo(),
      description: this.getPropertyDescription(),
      legalAgreement: this.getPropertyLegalAgreement(),
      logoUrl: data.Logo,
      languages: this.selectedLanguages,
      timeZone: data.TimeZone,
      useProxy: data.UseProxy,
      kind: data.Kind,
      checkInTime: data.CheckInTime,
      checkOutTime: data.CheckOutTime,
    }
    if (this.canEditConfig) {
      property.config = data.Config
      // NOTE: Do not update colors, if we're disabling wallet
      property.wallet = data.Wallet.enabled ? data.Wallet : { enabled: false }
      property.devicesIntegration = data.DevicesIntegration.provider ? data.DevicesIntegration : {}
      property.bookingRefundPolicy = {
        text: data.BookingRefundPolicy,
        schema: JSON.parse(data.BookingRefundPolicySchema || 'null'),
      }
    } else if (this.appleWalletIsEnabled) {
      property.wallet = data.Wallet
    }
    const sourceLanguage = this.language
    const targetLanguages = R.difference(this.selectedLanguages, [sourceLanguage])
    const forceTranslate = Boolean(translate)

    const menuPageTemplate =
      !this.property && this.canSelectMenuTemplate ? data.MenuPageTemplate : null

    this.submit.next({
      property,
      sourceLanguage,
      targetLanguages,
      forceTranslate,
      menuPageTemplate,
    })
    this.form.markAsPristine()
    this.form.markAsTouched()
    this.logoUploadChanged = false
    this.photoUploadChanged = false
  }

  private getCommonValidators(maxLengthConstraint: number): ValidatorFn {
    return Validators.compose([Validators.maxLength(maxLengthConstraint), blankValidator])
  }

  private getLocationValidators(minValue, maxValue): ValidatorFn {
    return Validators.compose([
      Validators.required,
      numberValidator,
      rangeValidator([minValue, maxValue]),
      Validators.maxLength(MAX_LENGTH),
    ])
  }

  public addPhone(): void {
    const phones = this.form.controls.Phones as FormArray
    phones.push(this.createPhoneFormGroup())
  }

  public removePhone(index: number): void {
    const phones = this.form.controls.Phones as FormArray
    phones.removeAt(index)
    this.phoneRemoved = true
  }

  private bookingEngineExternalHotelIdValidator(control) {
    const engineType = control.parent && control.parent.get('BookingEngineType').value
    if (engineType && [BookingEngineType.TRAVELLINE].includes(engineType)) {
      if (control.value && control.value.trim()) {
        return null
      }
      return { blank: true }
    }
    return null
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      Name: new FormControl(
        '',
        Validators.compose([Validators.required, this.getCommonValidators(MAX_LENGTH)])
      ),
      Phones: this.fb.array([]),
      Email: new FormControl(
        '',
        Validators.compose([emailValidator, Validators.maxLength(MAX_LENGTH)])
      ),
      AllowChildren: new FormControl(true),
      ChildrenAge: new FormControl(false),
      AllowHourlyBooking: new FormControl(false),
      BookingRefundPolicy: new FormControl(''),
      BookingRefundPolicySchema: new FormControl('', Validators.compose([jsonValidator])),
      BookingEngineUrl: new FormControl('', Validators.compose([urlValidator])),
      BookingEngineType: new FormControl(''),
      BookingEngineForm: new FormControl(''),
      BackofficeIntegration: new FormControl(''),
      BackofficeIntegrationUrl: new FormControl('', Validators.compose([urlValidator])),
      MapService: new FormControl(MapServices.GOOGLE),
      BookingEngineExternalHotelId: new FormControl('', this.bookingEngineExternalHotelIdValidator),
      BackofficeIntegrationToken: new FormControl(''),
      AllowDepartureOnDayOfArrival: new FormControl(true),
      Website: new FormControl(
        '',
        Validators.compose([urlValidator, Validators.maxLength(MAX_LENGTH)])
      ),
      ShopWebsite: new FormControl(
        '',
        Validators.compose([urlValidator, Validators.maxLength(MAX_LENGTH)])
      ),
      TripAdvisor: new FormControl('', Validators.compose([urlValidator])),
      VkSharingText: new FormControl(''),
      Description: new FormControl(
        '',
        Validators.compose([Validators.maxLength(MAX_DESCRIPTION_LENGTH)])
      ),
      LegalAgreement: new FormControl('', Validators.maxLength(MAX_DESCRIPTION_LENGTH)),
      Lat: new FormControl('', this.getLocationValidators(MIN_LAT_VALUE, MAX_LAT_VALUE)),
      Lng: new FormControl('', this.getLocationValidators(MIN_LNG_VALUE, MAX_LNG_VALUE)),
      Address: new FormControl({ default: '' }, Validators.maxLength(MAX_ADDRESS_LENGTH)),
      Country: new FormControl('', oneOfArrayValidator(this.countryNames)),
      City: new FormControl('', Validators.maxLength(MAX_ADDRESS_LENGTH)),
      Photo: new FormControl(''),
      Logo: new FormControl(''),
      UseProxy: new FormControl(false),
      Alias: new FormControl('', [hasSpaceValidator]),
      TimeZone: new FormControl(getCurrentTimezone(), Validators.required),
      Languages: this.fb.array(this.initializeLanguagesArray()),
      Config: this.fb.group(this.initializeConfigFormGroup()),
      Wallet: this.fb.group({
        enabled: false,
        options: this.fb.group({
          labelColor: '',
          foregroundColor: '',
          backgroundColor: '',
        }),
      }),
      DevicesIntegration: this.fb.group({
        provider: '',
        config: this.fb.group({
          key: '',
          keyId: '',
        }),
      }),
      Kind: new FormControl('hotel'),
      MenuPageTemplate: new FormControl(null),
      CheckInTime: new FormControl(DEFAULT_CHECK_IN_TIME),
      CheckOutTime: new FormControl(DEFAULT_CHECK_OUT_TIME),
    })

    this.form.get('DevicesIntegration.provider').valueChanges.subscribe(val => {
        if (val) {
            this.form.get('DevicesIntegration.config.key').setValidators([Validators.required])
            this.form.get('DevicesIntegration.config.keyId').setValidators([Validators.required])
        } else {
            this.form.get('DevicesIntegration.config.key').clearValidators()
            this.form.get('DevicesIntegration.config.keyId').clearValidators()
        }
        this.form.get('DevicesIntegration.config.key').updateValueAndValidity()
        this.form.get('DevicesIntegration.config.keyId').updateValueAndValidity()
    })

    this.form
      .get('BookingEngineType')
      .valueChanges.subscribe(() => this.form.get('BookingEngineExternalHotelId').patchValue(''))

    this.filteredCountries$ = this.form.get('Country').valueChanges.pipe(
      takeUntil(this.destroyed$),
      map(term => this.filterCountries(this.countryNames, term))
    )

    this.form
      .get('City')
      .valueChanges.pipe(
        debounceTime(300),
        withLatestFrom(
          this.citySelected$.pipe(
            // @ts-ignore
            map(x => x.option.value),
            startWith(null)
          )
        ),
        switchMap(([inputValue, selectedValue]) => {
          if (selectedValue === inputValue) {
            return NEVER
          }
          return of(inputValue)
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(query =>
        this.searchCity.next({
          query,
          countryCode: this.getCountryCode(this.form.get('Country').value),
        })
      )
  }

  public onProxyChage() {
    this.form.markAsDirty()
  }

  private getCountryCode(name) {
    if (!name) {
      return null
    }
    const country = this.countries.find(c => c.name === name)
    return country ? country.code : null
  }

  private getCountryName(c) {
    if (!c) {
      return null
    }
    const code = c.toUpperCase()
    const country = this.countries.find(x => x.code === code)
    return country ? country.name : null
  }

  private createPhoneFormGroup(phone?: propertyUtils.PropertyPhone): FormGroup {
    return this.fb.group({
      Value: new FormControl(
        (phone && phone.value) || null,
        Validators.compose([Validators.required, Validators.maxLength(MAX_LENGTH)])
      ),
      Label: new FormControl((phone && phone.label) || null, Validators.maxLength(MAX_LENGTH)),
      Id: (phone && phone._id) || null,
    })
  }

  private updateForm(): void {
    this.propertyLanguages = this.property.languages
    this.defaultLanguage =
      (this.property && this.property.defaultLanguage) ||
      (this.property.languages && this.property.languages[0])

    const bookingEngineSettings = this.property.bookingEngine
    this.logoUploaded = Boolean(this.property && this.property.logoUrl)
    const backofficeIntegration =
      this.property.backofficeIntegration && this.property.backofficeIntegration.provider
        ? this.property.backofficeIntegration
        : null
    const bookingEngineForm =
      bookingEngineSettings && bookingEngineSettings.config
        ? bookingEngineSettings.config.form
        : null
    const bookingRefundPolicy =
      this.property.bookingRefundPolicy && this.property.bookingRefundPolicy.text
    const bookingRefundPolicySchema =
      this.property.bookingRefundPolicy &&
      JSON.stringify(this.property.bookingRefundPolicy.schema, null, 2)
    const formData = {
      Alias: this.property.alias.replace(/\s/g, ''),
      Name: propertyUtils.getPropertyName(this.property),
      Email: propertyUtils.getPropertyEmail(this.property),
      Website: propertyUtils.getPropertyWebsite(this.property),
      ShopWebsite: this.property?.shopWebsite,
      AllowChildren:
        bookingEngineSettings && bookingEngineSettings.config
          ? bookingEngineSettings.config.children
          : true,
      ChildrenAge:
        bookingEngineSettings && bookingEngineSettings.config
          ? bookingEngineSettings.config.childrenAge
          : true,
      AllowHourlyBooking:
        bookingEngineSettings && bookingEngineSettings.config
          ? bookingEngineSettings.config.hourlyBooking
          : false,
      BookingEngineUrl: bookingEngineSettings ? bookingEngineSettings.url : '',
      BookingRefundPolicy: bookingRefundPolicy || '',
      BookingRefundPolicySchema: bookingRefundPolicySchema || '',
      BookingEngineType: bookingEngineSettings ? bookingEngineSettings.engineType : '',
      BookingEngineForm: bookingEngineForm,
      BookingEngineExternalHotelId: bookingEngineSettings ? bookingEngineSettings.externalId : '',
      AllowDepartureOnDayOfArrival:
        bookingEngineSettings && bookingEngineSettings.config
          ? bookingEngineSettings.config.departureOnDayOfArrival
          : false,
      BackofficeIntegration: backofficeIntegration ? backofficeIntegration.provider : null,
      BackofficeIntegrationUrl: backofficeIntegration ? backofficeIntegration.url : null,
      BackofficeIntegrationToken: backofficeIntegration ? backofficeIntegration.token : null,
      MapService: this.property.mapService,
      TripAdvisor: propertyUtils.getPropertyTripAdvisorLink(this.property),
      VkSharingText: this.getPropertyTranslatableVkSharingText(),
      Description: this.property.description,
      LegalAgreement: this.property.legalAgreement,
      Country: this.getCountryName(propertyUtils.getPropertyCountryCode(this.property)) || '',
      City: propertyUtils.getPropertyCity(this.property) || '',
      Lat: propertyUtils.getPropertyLat(this.property),
      Lng: propertyUtils.getPropertyLng(this.property),
      TimeZone: propertyUtils.getPropertyTimeZone(this.property) || getCurrentTimezone(),
      Address: this.getPropertyTranslatableAddress(),
      Photo: this.propertyPhotoUrl,
      Logo: this.property.logoUrl,
      Languages: this.fb.array(this.initializeLanguagesArray()).controls,
      UseProxy: this.property.useProxy,
      Kind: this.property.kind,
      CheckInTime: this.property.checkInTime ? this.property.checkInTime : DEFAULT_CHECK_IN_TIME,
      CheckOutTime: this.property.checkOutTime
        ? this.property.checkOutTime
        : DEFAULT_CHECK_OUT_TIME,
    }
    this.form.patchValue(formData)
    this.form.setControl(
      'Phones',
      this.fb.array(this.property.phone.map(p => this.createPhoneFormGroup(p)) || [])
    )

    if (this.canEditConfig) {
      this.form.setControl('Config', this.fb.group(this.initializeConfigFormGroup() || {}))
    }

    const wallet: Property['wallet'] = (this.property && this.property.wallet) || {}
    const walletOptions = wallet.options || {}
    this.form.get('Wallet').patchValue({
      enabled: wallet.enabled || false,
      options: walletOptions,
    })

    const devicesIntegration: Property['devicesIntegration'] =
      (this.property && this.property.devicesIntegration) || {}
    const devicesIntegrationConfig = this.property.devicesIntegration.config || {}

    this.form.get('DevicesIntegration').patchValue({
      provider: devicesIntegration.provider,
      config: devicesIntegrationConfig,
    })
  }

  public get appleWalletIsEnabled() {
    return (
      this.canEditConfig || (this.property && this.property.wallet && this.property.wallet.enabled)
    )
  }

  public get propertyPhotoUrl(): string {
    return (
      this.property &&
      propertyUtils.getPropertyInfoPhotoUrl(this.property, this.property.defaultLanguage)
    )
  }

  public get backofficeIntegrationUrlPlaceholder(): string {
    return this.form.get('BackofficeIntegration').value === BackofficeIntegraion.HKEEPER
      ? 'your-hotel.hkeeper.us'
      : 'your-hotel.teamjet.pro'
  }

  private initializeLanguagesArray(): FormGroup[] {
    return this.configService.languages.map(langItem => this.getLanguageControl(langItem))
  }

  private getLanguageControl(languageItem: LanguageItem, selected?: boolean): FormGroup {
    const isSelected = selected
      ? selected
      : this.property
      ? R.indexOf(languageItem.code, this.property.languages) !== -1
      : false
    return this.fb.group({
      IsSelected: new FormControl(isSelected),
      Language: new FormControl({ value: languageItem.code, label: languageItem.name }),
    })
  }

  public get propertyConfigs() {
    return PROPERTY_CONFIG_SETTINGS
  }

  private initializeConfigFormGroup() {
    const controlsConfig = {}
    PROPERTY_CONFIG_SETTINGS.forEach(config => {
      if (config.type !== 'constant') {
        const value = this.property ? this.property.config[config.key] : config.default
        controlsConfig[config.key] = new FormControl(value)
      }
    })
    return controlsConfig
  }

  public getConstantConfigValue(key) {
    return (this.property && this.property.config[key]) || null
  }

  public logoUploaderClick($event) {
    const isRemoveClick = $event.target.classList.contains('hot-upload-file-remove')
    if (!this.logoCrop.loaded && !isRemoveClick) {
      this.logoCrop.openFileDialog()
    }
  }

  public photoUploaderClick($event) {
    const isRemoveClick = $event.target.classList.contains('hot-upload-file-remove')
    if (!this.photoCrop.loaded && !isRemoveClick) {
      this.photoCrop.openFileDialog()
    }
  }

  private filterCountries(countries: string[], term: string): string[] {
    const value = term.trim().toLowerCase()
    // @ts-ignore
    return value
      ? R.flatten(
          R.partition(
            str => str.toLowerCase().startsWith(value),
            R.filter(c => c.toLowerCase().includes(value), countries)
          )
        )
      : this.countryNames
  }

  public updateWalletColor(colorName, $event) {
    const field = this.form.get('Wallet.options.' + colorName)
    field.setValue($event.target.value)
    field.markAsTouched()
    field.markAsDirty()
  }

  public updateDefaultLanguage(language) {
    this.defaultLanguage = language
    this.form.markAsDirty()
  }

  public generateTimeSteps() {
    const tick = moment().startOf('day')
    const result = []
    for (let i = 0; i < 48; i++) {
      result.push(tick.format('HH:mm'))
      tick.add(30, 'm')
    }
    return result
  }
}
