import { LegalEntityActions, LegalEntityActionTypes } from '@redux/legal-entity/legal-entity.actions'
import * as R from 'ramda'

export interface State {
  ids: string[]
  selectedId: string | null
  loadedById: { [key: string]: boolean }
  loaded: boolean
  loading: boolean
}

export const initialState: State = {
  ids: [],
  selectedId: null,
  loadedById: {},
  loaded: false,
  loading: false,
}

export function reducer(state = initialState, action: LegalEntityActions): State {
  switch (action.type) {
    case LegalEntityActionTypes.Load:
    case LegalEntityActionTypes.LoadItem: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }
    case LegalEntityActionTypes.LoadSuccess:
    case LegalEntityActionTypes.LoadItemSuccess: {
      const ids = action.payload.results || [action.payload.result]
      const r = {
        ...state,
        loaded: true,
        loading: false,
        ids,
        loadedById: ids.reduce((a, e) => {
          a[e] = true
          return a
        }, state.loadedById),
      }
      if (action.type === LegalEntityActionTypes.LoadItemSuccess) {
        r.selectedId = action.payload.result
      }
      return r
    }
    case LegalEntityActionTypes.LoadFail:
    case LegalEntityActionTypes.LoadItemFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }
    case LegalEntityActionTypes.CreateSuccess: {
      return {
        ...state,
        ids: [...state.ids, action.payload.result],
      }
    }
    case LegalEntityActionTypes.DeleteSuccess: {
      const itemId = action.meta.legalEntityId
      return {
        ...state,
        ids: R.without([itemId], state.ids),
        loadedById: R.omit([itemId], state.loadedById),
      }
    }
    case LegalEntityActionTypes.Select: {
      return {
        ...state,
        selectedId: action.payload.legalEntityId,
      }
    }

    default: {
      return state
    }
  }
}

export const selectIds = (state: State) => state.ids
export const selectLegalEntitiesLoaded = (state: State) => state.loaded
export const selectCurrentlySelectedLegalEntityId = (state: State) => state.selectedId
export const selectIsLoadedById = (state: State, id: string) => state.loadedById[id] || false
