import { Directive, AfterViewInit, ElementRef, Input, OnDestroy } from '@angular/core'
import { PropertyFacade } from '@redux/property/property.facade'
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { proxyUrl } from '@shared/utils/proxy'

@Directive({
  selector: '[hotProxySrc] , [hotProxyHref]',
})
export class ProxySrcDirective implements AfterViewInit, OnDestroy {
  @Input()
  set hotProxySrc(value) {
    this.imageSrc$.next(value)
    this.targetAttribute = 'src'
  }
  @Input()
  set forceUseProxy(value) {
    this.forceUseProxy$.next(value)
  }
  @Input()
  set hotProxyHref(value) {
    this.imageSrc$.next(value)
    this.targetAttribute = 'href'
  }

  constructor(
    private el: ElementRef<HTMLImageElement | HTMLLinkElement>,
    private propertyFacade: PropertyFacade
  ) {}

  private imageSrc$ = new BehaviorSubject(null)
  private forceUseProxy$ = new BehaviorSubject(null)
  private subscription: Subscription = null
  private targetAttribute = 'src'

  private resultImageUrl$ = combineLatest(
    this.imageSrc$.pipe(distinctUntilChanged()),
    this.forceUseProxy$.pipe(distinctUntilChanged()),
    this.propertyFacade.property$.pipe(map(p => p && p.useProxy), distinctUntilChanged())
  ).pipe(
    map(
      ([imageUrl, forceUseProxy, propertyUseProxy]) =>
        forceUseProxy === null
          ? propertyUseProxy ? proxyUrl(imageUrl) : imageUrl
          : forceUseProxy ? proxyUrl(imageUrl) : imageUrl
    )
  )

  ngAfterViewInit() {
    this.subscription = this.resultImageUrl$.subscribe(src => {
      this.el.nativeElement.setAttribute(this.targetAttribute, src)
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
