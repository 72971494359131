import { Component, OnInit, Input, HostBinding } from '@angular/core'
import { Property } from '@models'
import { proxyUrl } from '../../../_shared/utils/proxy'

@Component({
  selector: 'hot-property-logo',
  templateUrl: './property-logo.component.html',
  styleUrls: ['./property-logo.component.scss'],
})
export class PropertyLogoComponent implements OnInit {
  @Input() public property: Property
  @Input() public showTooltip = true

  @HostBinding('style.width')
  @HostBinding('style.height')
  @HostBinding('style.lineHeight')
  @Input()
  public size = ''

  constructor() {}

  ngOnInit() {}

  public get logoBackgroundImage() {
    const logo = this.property && this.property.logoUrl ? proxyUrl(this.property.logoUrl) : null
    return logo ? `url("${logo}")` : ''
  }

  public get tooltip(): string {
    return this.showTooltip ? (this.property && this.property.name) || '&mdash;' : null
  }
}
