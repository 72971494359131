import { environment } from 'environments/environment'

import { NgModule } from '@angular/core'

import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'

import { metaReducers, reducers } from '@redux'

export const APP_STORE_IMPORTS = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    // TODO: Fix BuilderPageComponent, StatsPageComponent, and others to not mutate objects retrieved from state
    runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false,
    },
  }),
  !environment.production ? StoreDevtoolsModule.instrument() : [],
]

export const APP_STORE_PROVIDERS = []

@NgModule({
  imports: APP_STORE_IMPORTS,
  exports: [StoreModule],
})
export class AppStoreModule {}
