import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from '@shared/services'

import { NormalizedResults, WifiVoucher, CaptivePortal } from '@models'

type AtLeastOneOfTwo<A, B> = Partial<A & B> & (A | B)
// type AtLeastOneOfThree<A, B, C> = Partial<A & B & C> & (A | B | C)

// Requires at least one of captivePortal or hotspot to be set
export type WifiStatsQueryParams = {
  dateFrom?: string
  dateTo?: string
  limit?: number
  page?: number
} & AtLeastOneOfTwo<{ captivePortal: string }, { hotspot: string }>

// Requires at least one of captivePortal or hotspot to be set
export interface WifiVouchersQueryParams {
  dateFrom?: string
  dateTo?: string
  limit?: number
  page?: number
}

export interface WifiSearchLogsQueryParams {
  host?: string
  date?: Date
}

@Injectable()
export class WifiService {
  constructor(public readonly apiService: ApiService) {}

  public createCaptivePortal(
    propertyId: string,
    captivePortal: Partial<CaptivePortal> = {}
  ): Observable<NormalizedResults> {
    return this.apiService.post(`properties/${propertyId}/captive-portals`, captivePortal)
  }

  public getCaptivePortals(propertyId: string): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/captive-portals`)
  }

  public getStats(propertyId: string, params: WifiStatsQueryParams): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/sessions`, { params })
  }

  public getVouchers(
    captivePortalId: string,
    params: WifiVouchersQueryParams
  ): Observable<NormalizedResults> {
    return this.apiService.get(`captive-portals/${captivePortalId}/vouchers`, { params })
  }

  public createVoucher(
    captivePortalId: string,
    voucher: Partial<WifiVoucher>
  ): Observable<NormalizedResults> {
    return this.apiService.post(`captive-portals/${captivePortalId}/vouchers`, voucher)
  }

  public searchLogs(
    propertyId: string,
    params: WifiSearchLogsQueryParams
  ): Observable<{ credentials: string[] }> {
    return this.apiService.get(`properties/${propertyId}/sessions/search`, { params })
  }
}
