import { composeReducers, createListLoader } from '../helpers'
import {
  Actions as ReservationActions,
  ActionTypes as ReservationActionTypes,
} from './reservation.actions'

export interface Substate {
  ids: string[]
  loaded: boolean
  loading: boolean
}
export interface State {
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialSubstate: Substate = {
  ids: [],
  loaded: false,
  loading: false,
}
export const initialState: State = {
  byPropertyId: {},
}

// Experimenting with reducing boilerplate...
const ListLoader = createListLoader(ReservationActionTypes, initialSubstate)
const substateReducer = composeReducers<Substate, ReservationActions>([ListLoader.reducer])

// function substateReducer(state = initialSubstate, action: ReservationActions): Substate {
//   switch (action.type) {
//     case ReservationActionTypes.Load: {
//       return { ...state, loading: true }
//     }
//     case ReservationActionTypes.LoadSuccess: {
//       return { ...state, ids: action.payload.results, loading: false, loaded: true }
//     }
//     case ReservationActionTypes.LoadFail: {
//       return { ...state, loading: false, loaded: true }
//     }
//   }
//   return state
// }

export function reducer(state = initialState, action: ReservationActions): State {
  switch (action.type) {
    case ReservationActionTypes.Load:
    case ReservationActionTypes.LoadSuccess:
    case ReservationActionTypes.LoadFail: {
      const propertyId =
        action.type === ReservationActionTypes.Load
          ? action.payload.propertyId
          : action.meta.propertyId

      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [propertyId]: substateReducer(state.byPropertyId[propertyId], action),
        },
      }
    }
  }
  return state
}

export const selectSubstates = (state: State) => state.byPropertyId
export const selectSubstateIds = ListLoader.selectIds
export const selectSubstateLoading = ListLoader.selectLoading
export const selectSubstateLoaded = ListLoader.selectLoaded
export const selectSubstateMeta = ListLoader.selectMeta
