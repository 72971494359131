type ISODate = string
type RoomNumber = string

export interface Guest {
  firstName?: string
  lastName?: string
}

export enum ReservationStatus {
  Requested = 'REQUESTED',
  Reserved = 'RESERVED',
  CheckedIn = 'IN_HOUSE',
  Canceled = 'CANCELLED',
  CheckedOut = 'CHECKED_OUT',
  NoShow = 'NO_SHOW',
  WaitList = 'WAIT_LIST',
  Unknown = 'UNKNOWN',
  // not HAPI status
  TmpOut = 'TMP_OUT',
  Confirmed = 'CONFIRMED',
}

export interface Reservation {
  reservationNumber: string
  roomStays: [RoomNumber]
  arrival?: {
    arrivalDate?: ISODate
    arrivalTime?: string
  }
  departure?: {
    departureDate?: ISODate
    departureTime?: string
  }
  invitationSent: boolean
  smsSent: boolean

  createdAt: ISODate
  updatedAt: ISODate

  status: ReservationStatus

  guests: [Guest]

  bookingRequest?: string
  refundedAt: Date
}
