import { Injectable } from '@angular/core'
import * as nanoid from 'nanoid'

@Injectable()
export class UidService {
  // This one should be safe to use as a global ID
  // that could be searchable by itself
  // See `Nano ID Collision Calculator`:
  // https://alex7kom.github.io/nano-nanoid-cc/
  public longId: () => string = nanoid

  // Use only for IDs that local to one document
  // e.g. ID of a button inside menu page
  // (it could be used in payloads, so we need short size)
  public shortId(): string {
    return nanoid(14)
  }

  // Even shorter than previous one to save size in DB and in payload
  // Probably should be checked for collisions
  public microId(arr: any[] = null, idKey = 'id'): string {
    let id = nanoid(8)
    if (arr && Array.isArray(arr)) {
      while (arr.some(x => x[idKey] === id)) {
        id = nanoid(8)
      }
    }
    return id
  }

  // Even shorter than previous one to save size in DB and in payload
  // Probably should be checked for collisions
  public nanoId(arr: any[] = null, idKey = 'id'): string {
    let id = nanoid(4)
    if (arr && Array.isArray(arr)) {
      while (arr.some(x => x[idKey] === id)) {
        id = nanoid(4)
      }
    }
    return id
  }
}
