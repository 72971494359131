import { environment } from '../../../environments/environment'
import { Injectable } from '@angular/core'
import { LanguageItem, CountryItem } from '@models'

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private readonly API_HOST = environment.API_BASE_URL
  private readonly FACEBOOK_ID = environment.FACEBOOK_APP_ID
  private readonly APP_ID = environment.APP_ID
  private _languages: LanguageItem[]
  private _countries: CountryItem[]

  public get API_BASE_URL(): string {
    return this.API_HOST.startsWith('http') ? this.API_HOST : '//' + this.API_HOST
  }

  public get CHANNEL_ID(): string {
    return this.APP_ID
  }

  public get FACEBOOK_APP_ID(): string {
    return this.FACEBOOK_ID
  }

  public get BOOKING_SUITE_ENABLED(): boolean {
    return environment.BOOKING_OAUTH_ENABLED
  }

  public set languages(value) {
    this._languages = value
  }

  public get languages() {
    return this._languages
  }

  public set countries(value) {
    this._countries = value
  }

  public get countries() {
    return this._countries
  }

  public get languageCodes() {
    return this.languages.map(langItem => langItem.code)
  }

  constructor() {}
}
