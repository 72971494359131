import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { switchMap, map, catchError, tap, merge } from 'rxjs/operators'

import * as cartActions from './cart.actions'
import { CartService } from './cart.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class CartEffects {
  /**
   * Load form responses effects
   */
  @Effect()
  load$ = this.actions$.pipe(
    ofType(cartActions.ActionTypes.Load),
    switchMap((action: cartActions.Load) => {
      const meta = action.payload
      return this.cartService
        .getList(action.payload.propertyId, {
          status: action.payload.status,
          page: action.payload.page,
          limit: action.payload.limit,
        })
        .pipe(
          map(x => new cartActions.LoadSuccess(x, meta)),
          catchError(x => of(new cartActions.LoadFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  loadFail$ = this.actions$.pipe(
    ofType(cartActions.ActionTypes.LoadFail),
    tap(() => {
      const loadError = this.translationService.translate('Request.error.loadError')
      this.matSnackBar.open(loadError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load item effects
   */
  @Effect()
  loadItem$ = this.actions$.pipe(
    ofType(cartActions.ActionTypes.LoadItem),
    merge(
      this.actions$.pipe(
        ofType(cartActions.ActionTypes.UpdateStatusFail),
        map((action: cartActions.UpdateStatusFail) => new cartActions.LoadItem(action.meta))
      )
    ),
    switchMap((action: cartActions.LoadItem) => {
      const meta = action.payload
      return this.cartService.get(action.payload.propertyId, action.payload.cartId).pipe(
        map(x => new cartActions.LoadItemSuccess(x, meta)),
        catchError(x => of(new cartActions.LoadItemFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  loadItemFail$ = this.actions$.pipe(
    ofType(cartActions.ActionTypes.LoadItemFail),
    tap(() => {
      const loadItemError = this.translationService.translate('Request.error.loadItemError')
      this.matSnackBar.open(loadItemError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Update status effects
   */
  @Effect()
  public updateStatus$ = this.actions$.pipe(
    ofType(cartActions.ActionTypes.UpdateStatus),
    switchMap((action: cartActions.UpdateStatus) => {
      const meta = action.payload
      return this.cartService
        .updateStatus(action.payload.propertyId, action.payload.cartId, {
          status: action.payload.status,
          comment: action.payload.comment,
        })
        .pipe(
          map(x => new cartActions.UpdateStatusSuccess(x, meta)),
          catchError(x => of(new cartActions.UpdateStatusFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateStatusFail$ = this.actions$.pipe(
    ofType(cartActions.ActionTypes.UpdateStatusFail),
    tap(() => {
      const updateStatusError = this.translationService.translate('Request.error.updateStatusError')
      this.matSnackBar.open(updateStatusError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private cartService: CartService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
