import * as R from 'ramda'
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { LanguageType } from '../../constants'
import {
  ShopProductCategory,
  ShopProduct,
  sortByTitle,
  splitRootFromNestedCategories,
} from '@models'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'

enum ShopEntity {
  RootCategory = 'rootCategory',
  Category = 'nestedCategory',
  Product = 'product',
  // Promo = 'promo',
}

interface ShopLink {
  rootCategory: string
  category?: string
  product?: string
}

@Component({
  selector: 'hot-shop-entity-selector',
  templateUrl: './shop-entity-selector.component.html',
  // encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./shop-entity-selector.component.scss'],
})
export class ShopEntitySelectorComponent implements OnInit {
  @Input() lang: LanguageType
  @Input() defaultLanguage = 'en'
  @Input() currentLanguage = 'en'
  @Input() categories: ShopProductCategory[] = []
  @Input() products: ShopProduct[] = []
  @Input() link: ShopLink = null

  @Input() allowHomePage = false

  @Output() change = new EventEmitter<ShopLink>()

  public sortedProducts: ShopProduct[] = []
  public sortedRootCategories: ShopProductCategory[] = []
  public sortedNestedCategories: ShopProductCategory[] = []
  public subcategories: ShopProductCategory[] = []

  private ignoreChanges = false

  public form = new FormGroup({})

  public constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeForm()

    this.sortedProducts = this.products.sort(sortByTitle(this.defaultLanguage))
    const [rootCategories, nestedCategories] = splitRootFromNestedCategories(this.categories)
    this.sortedRootCategories = rootCategories.sort(sortByTitle(this.defaultLanguage))
    this.sortedNestedCategories = nestedCategories.sort(sortByTitle(this.defaultLanguage))

    if (this.link) {
      this.updateFormWithData(this.link)
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      Entity: new FormControl(ShopEntity.RootCategory),
      RootCategoryId: new FormControl(null, this.allowHomePage ? [] : Validators.required),
      NestedCategoryId: new FormControl(null),
      ProductId: new FormControl(null),
    })
    this.form.valueChanges.subscribe(this.onChange.bind(this))
  }

  updateFormWithData(link: ShopLink) {
    this.ignoreChanges = true
    const entity = link.product
      ? ShopEntity.Product
      : link.category
      ? ShopEntity.Category
      : ShopEntity.RootCategory
    const formData = {
      Entity: entity,
      RootCategoryId: link.rootCategory || null,
      NestedCategoryId: link.category || null,
      ProductId: link.product || null,
    }
    if (formData.RootCategoryId) {
      this.onSelectRootCategory(formData.RootCategoryId)
    }
    if (formData.NestedCategoryId) {
      this.onSelectNestedCategory(formData.NestedCategoryId)
    }
    this.form.patchValue(formData, { emitEvent: false })
    this.ignoreChanges = false
  }

  public get entities() {
    return R.values(ShopEntity)
  }

  public get showNestedCategoryControl() {
    return (
      [ShopEntity.Category, ShopEntity.Product].includes(this.form.get('Entity').value) &&
      this.form.get('RootCategoryId').value
    )
  }

  public get showProductControl() {
    return (
      this.form.get('Entity').value === ShopEntity.Product &&
      this.form.get('RootCategoryId').value &&
      this.form.get('NestedCategoryId').value
    )
  }

  onChangeEntity({ value }: { value: ShopEntity }) {
    if (value === ShopEntity.RootCategory) {
      this.form.patchValue({ NestedCategoryId: null, ProductId: null })
    }
    if (value === ShopEntity.Category) {
      this.form.patchValue({ ProductId: null })
    }
  }

  public onSelectRootCategory(id: string) {
    const rootCategory = this.sortedRootCategories.find(R.propEq('_id', id))
    if (!rootCategory) {
      this.form.patchValue({
        RootCategoryId: null,
        NestedCategoryId: null,
        ProductId: null,
      })
      return
    }
    this.subcategories = this.sortedNestedCategories.filter(x =>
      rootCategory.subcategories.includes(x._id)
    )
    this.form.patchValue({
      NestedCategoryId: null,
      ProductId: null,
    })
  }

  public onSelectNestedCategory(id: string) {
    const category = this.sortedNestedCategories.find(R.propEq('_id', id))
    if (!category) {
      return
    }
    this.products = category
      ? this.sortedProducts.filter(x => category.products.includes(x._id))
      : []
    this.form.patchValue({
      ProductId: null,
    })
  }

  public onChange(formData) {
    if (!this.change || this.ignoreChanges) {
      return
    }
    const entity = formData.Entity
    const rootCategory = formData.RootCategoryId
    const category = formData.NestedCategoryId
    const product = formData.ProductId

    let data = null
    switch (entity) {
      case ShopEntity.RootCategory: {
        if (rootCategory || this.allowHomePage) {
          data = { rootCategory, category: null, product: null }
        }
        break
      }
      case ShopEntity.Category: {
        if (rootCategory && category) {
          data = { rootCategory, category, product: null }
        }
        break
      }
      case ShopEntity.Product: {
        if (rootCategory && category && product) {
          data = { rootCategory, category, product }
        }
      }
    }

    if (data) {
      this.change.emit(data)
    }
  }
}
