import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store'

import {
  FormResponse,
  TimelineEvent,
  Property,
  Push,
  User,
  Review,
  Restaurant,
  Cuisine,
  Cart,
  CartEventItem,
  ShopCart,
  ShopCartEventItem,
  Chat,
} from '@models'

import * as R from 'ramda'

import { environment } from '../../environments/environment'
import * as globalActions from './actions'
import * as fromAnalytics from './analytics'
import * as fromBuilder from './builder'
import * as fromChat from './chat'
import * as fromDashboard from './dashboard'
import * as fromEntity from './entity'
import * as fromFAQ from './faq'
import * as fromForm from './form'
import * as fromFormResponse from './form-response'
import * as fromProperty from './property'
import * as fromPush from './push'
import * as fromRouter from './router'
import * as fromReservation from './reservation'
import * as fromUser from './user'
import * as fromWifi from './wifi'
import * as fromReview from './review'
import * as fromRestaurant from './restaurant'
import * as fromRoomService from './room-service'
import * as fromProductCategory from './product-category'
import * as fromProduct from './product'
import * as fromCart from './cart'
import * as fromShopCart from './shop-cart'
import * as fromMenuPagesTemplates from './menu-pages-templates'
import * as fromCuisines from './cuisine'
import * as fromLegalEntities from './legal-entity'
import * as fromSalesService from './sales-service'
import * as fromShop from './shop'
import * as fromShopProduct from './shop-product'
import * as fromShopProductCategory from './shop-product-category'

export interface State {
  analytics: fromAnalytics.State
  builder: fromBuilder.State
  entity: fromEntity.State
  chat: fromChat.State
  faq: fromFAQ.State
  form: fromForm.State
  formResponse: fromFormResponse.State
  property: fromProperty.State
  user: fromUser.State
  router: fromRouter.State
  push: fromPush.State
  dashboard: fromDashboard.State
  wifi: fromWifi.State
  review: fromReview.State
  reservation: fromReservation.State
  menuPageTemplates: fromMenuPagesTemplates.State
  restaurant: fromRestaurant.State
  shop: fromShop.State
  shopProduct: fromShopProduct.State
  shopProductCategory: fromShopProductCategory.State
  roomService: fromRoomService.State
  productCategory: fromProductCategory.State
  product: fromProduct.State
  cart: fromCart.State
  shopCart: fromShopCart.State
  cuisines: fromCuisines.State
  legalEntities: fromLegalEntities.State
  salesService: fromSalesService.State
}

export const reducers: ActionReducerMap<State> = {
  analytics: fromAnalytics.reducer,
  builder: fromBuilder.reducer,
  entity: fromEntity.reducer,
  chat: fromChat.reducer,
  faq: fromFAQ.reducer,
  form: fromForm.reducer,
  formResponse: fromFormResponse.reducer,
  property: fromProperty.reducer,
  user: fromUser.reducer,
  router: fromRouter.reducer,
  push: fromPush.reducer,
  dashboard: fromDashboard.reducer,
  wifi: fromWifi.reducer,
  review: fromReview.reducer,
  reservation: fromReservation.reducer,
  menuPageTemplates: fromMenuPagesTemplates.reducer,
  restaurant: fromRestaurant.reducer,
  shop: fromShop.reducer,
  shopProduct: fromShopProduct.reducer,
  shopProductCategory: fromShopProductCategory.reducer,
  roomService: fromRoomService.reducer,
  productCategory: fromProductCategory.reducer,
  product: fromProduct.reducer,
  cart: fromCart.reducer,
  shopCart: fromShopCart.reducer,
  cuisines: fromCuisines.reducer,
  legalEntities: fromLegalEntities.reducer,
  salesService: fromSalesService.reducer,
}

/**
 * Reset State on Action
 */
export function resetStateReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    if (action.type === globalActions.GlobalActionTypes.ResetState) {
      state = undefined
    }
    return reducer(state, action)
  }
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [resetStateReducer]
  : [resetStateReducer]

/**
 * Helpers
 */
// For Immutability-check: do not create new array every time
export const _emptyObject = {}
export const _emptyArray = []
export const safeObject = fn => (...args) => fn(...args) || _emptyObject
export const safeArray = fn => (...args) => fn(...args) || _emptyArray
export const matchEntity = <T>(id: string, entities: { [key: string]: T }): T => {
  return (entities || _emptyObject)[id]
}
export const matchEntities = <T>(ids: string[], entities: { [key: string]: T }): T[] => {
  return ids ? ids.map(id => entities[id]) : _emptyArray
}

export const enrichFormResponsesWithChats = (
  responses,
  chats: Record<string, Chat>
): FormResponse[] => {
  return responses.map(response => {
    const chat = chats[response.chat]
    return {
      ...response,
      __chat: chat,
    }
  })
}

export const populateReviewsWithChat = (reviews, chats: Record<string, Chat>): FormResponse[] => {
  return reviews.map(response => {
    const chat = chats[response.chat]
    return {
      ...response,
      __chat: chat,
    }
  })
}

export const enrichTimelineEvent = <T = TimelineEvent>(event, users, staffUsers): T => {
  return {
    ...event,
    __staffUser: event.staffUser && staffUsers[event.staffUser],
    __user: event.user && users[event.user],
  }
}

/**
 * Router
 */
export const selectRouterModule = createFeatureSelector<fromRouter.State>('router')
export const selectRouterState = createSelector(selectRouterModule, x => x.state)
export const selectRouterParams = createSelector(selectRouterState, x => x.params)
// export const selectRouterQueryParams = createSelector(selectRouterState, x => x.queryParams)
// export const selectRouterData = createSelector(selectRouterState, x => x.data)

/**
 * Entities
 */
export const selectEntitiesState = createFeatureSelector<fromEntity.State>('entity')
export const selectBuilderEntities = createSelector(selectEntitiesState, fromEntity.selectMenus)
export const selectCaptivePortalsEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectCaptivePortals
)
export const selectHotspotsEntities = createSelector(selectEntitiesState, fromEntity.selectHotspots)
export const selectCuisineEntities = createSelector(selectEntitiesState, fromEntity.selectCuisines)
export const selectChatsEntities = createSelector(selectEntitiesState, fromEntity.selectChats)
export const selectFormResponsesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectFormResponses
)
export const selectCartsEntities = createSelector(selectEntitiesState, fromEntity.selectCarts)
export const selectShopCartsEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectShopCarts
)
export const selectPropertiesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectProperties
)

export const selectPushesEntities = createSelector(selectEntitiesState, fromEntity.selectPushes)

export const selectStaffUsersEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectStaffUsers
)

export const selectFormsEntities = createSelector(selectEntitiesState, fromEntity.selectForms)
export const selectUsersEntities = createSelector(selectEntitiesState, fromEntity.selectUsers)
export const selectReservationEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectReservations
)
export const selectReviewsEntities = createSelector(selectEntitiesState, fromEntity.selectReviews)
export const selectRestaurantsEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectRestaurants
)
export const selectShopEntities = createSelector(selectEntitiesState, fromEntity.selectShops)
export const selectShopProductsEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectShopProducts
)
export const selectShopProductCategoriesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectShopProductCategories
)
export const selectRoomServicesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectRoomServices
)
export const selectProductCategoriesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectProductCategories
)
export const selectProductsEntities = createSelector(selectEntitiesState, fromEntity.selectProducts)
export const selectMenuPagesTemplatesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectMenuPagesTemplates
)

export const selectFAQCategoriesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectFAQCategoriesEntities
)

export const selectFormTemplatesEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectFormTemplates
)

export const selectLegalEntityEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectLegalEntities
)

export const selectSalesServiceSettingsEntities = createSelector(
  selectEntitiesState,
  fromEntity.selectSalesServiceSettings
)

/**
 * Property
 */
export const matchUsers = (property: Property, users): User[] => {
  return property && property.members
    ? property.members.map(m => {
        const user = users[m.user]
        if (user) {
          user.role = m.role
          user.workingHours = m.workingHours
          user.pickupPoint = m.pickupPoint
        }
        return user
      })
    : []
}
export const selectPropertyState = createFeatureSelector<fromProperty.State>('property')
export const selectPropertiesIds = createSelector(selectPropertyState, fromProperty.selectIds)
export const selectPropertiesLoaded = createSelector(
  selectPropertyState,
  fromProperty.selectIsLoaded
)
export const selectProperties = createSelector(
  selectPropertiesIds,
  selectPropertiesEntities,
  matchEntities
)
export const selectCurrentPropertyId = createSelector(
  selectPropertyState,
  fromProperty.selectCurrentPropertyId
)
export const selectCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesEntities,
  matchEntity
)

export const selectCurrentPropertyLoaded = createSelector(
  selectPropertyState,
  selectCurrentPropertyId,
  fromProperty.selectIsLoadedById
)
export const selectCurrentPropertyUsers = createSelector(
  selectCurrentProperty,
  selectUsersEntities,
  matchUsers
)
export const selectSearchCityPending = createSelector(
  selectPropertyState,
  fromProperty.selectSearchCityPending
)
export const selectSearchCityResults = createSelector(
  selectPropertyState,
  fromProperty.selectSearchCityResults
)
export const selectCurrentPropertyIntegrations = createSelector(
  selectPropertyState,
  selectCurrentPropertyId,
  fromProperty.selectIntegrationsById
)
export const selectCurrentPropertyAvailableIntegrations = createSelector(
  selectPropertyState,
  selectCurrentPropertyId,
  fromProperty.selectAvailableIntegrationsById
)

/**
 * User
 */
export const selectUserState = createFeatureSelector<fromUser.State>('user')
export const selectAuthorizedUserId = createSelector(
  selectUserState,
  fromUser.selectAuthorizedUserId
)
export const selectAuthorizedUser = createSelector(
  selectAuthorizedUserId,
  selectUsersEntities,
  matchEntity
)
export const selectSelectedUserId = createSelector(selectUserState, fromUser.selectSelectedUserId)
export const selectCurrentUser = createSelector(
  selectCurrentPropertyUsers,
  selectSelectedUserId,
  (users, userId) => users.find(({ _id }) => _id === userId)
)
export const selectResetPasswordToken = createSelector(
  selectRouterParams,
  R.prop('resetPasswordToken')
)
export const selectUserPending = createSelector(selectUserState, fromUser.selectUserPending)
export const selectUserPermissions = createSelector(selectUserState, fromUser.selectUserPermissions)

export const selectResetPasswordTokenValidationStatus = createSelector(
  selectUserState,
  fromUser.selectTokenValidationStatus
)

/**
 * Shop
 */
export const selectShopState = createFeatureSelector<fromShop.State>('shop')
export const selectPropertiesShopSubstates = createSelector(
  selectShopState,
  fromShop.selectSubstates
)
export const selectShopSubstateByCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesShopSubstates,
  safeObject(matchEntity)
)
export const selectCurrentShopIds = createSelector(
  selectShopSubstateByCurrentProperty,
  safeArray(fromShop.selectPropertySubstateShopIds)
)

export const selectCurrentPropertyShop = createSelector(
  selectCurrentShopIds,
  selectShopEntities,
  (ids, entities) => entities[ids[0]] || null
)

/**
 * ShopProduct
 */
export const selectShopProductState = createFeatureSelector<fromShopProduct.State>('shopProduct')
export const selectPropertiesShopProductSubstates = createSelector(
  selectShopProductState,
  fromShopProduct.selectSubstates
)
export const selectShopProductSubstateByCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesShopProductSubstates,
  safeObject(matchEntity)
)
export const selectShopProductEntitiesByCurrentProperty = createSelector(
  selectShopProductSubstateByCurrentProperty,
  selectShopProductsEntities,
  (substate, entities) => (substate.items ? substate.items.map(id => entities[id]) : [])
)

/**
 * ShopProductCategory
 */
export const selectShopProductCategoryState = createFeatureSelector<fromShopProductCategory.State>(
  'shopProductCategory'
)
export const selectPropertiesShopProductCategorySubstates = createSelector(
  selectShopProductCategoryState,
  fromShopProductCategory.selectSubstates
)
export const selectShopProductCategorySubstateByCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesShopProductCategorySubstates,
  safeObject(matchEntity)
)
export const selectShopProductCategoryEntitiesByCurrentProperty = createSelector(
  selectShopProductCategorySubstateByCurrentProperty,
  selectShopProductCategoriesEntities,
  (substate, entities) => (substate.items ? substate.items.map(id => entities[id]) : [])
)

/**
 * RoomService
 */
export const selectRoomServiceState = createFeatureSelector<fromRoomService.State>('roomService')
export const selectPropertiesRoomServiceSubstates = createSelector(
  selectRoomServiceState,
  fromRoomService.selectSubstates
)
export const selectRoomServiceSubstateByCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesRoomServiceSubstates,
  safeObject(matchEntity)
)
export const selectCurrentRoomServiceIds = createSelector(
  selectRoomServiceSubstateByCurrentProperty,
  safeArray(fromRoomService.selectPropertySubstateRoomServiceIds)
)
export const selectCurrentPropertyRoomServices = createSelector(
  selectCurrentRoomServiceIds,
  selectRoomServicesEntities,
  safeArray(matchEntities)
)
export const selectCurrentPropertyRoomService = createSelector(
  selectCurrentRoomServiceIds,
  selectRoomServicesEntities,
  (ids, entities) => entities[ids[0]] || null
  // safeArray(matchEntities)
)
export const selectCurrentPropertyRoomServiceLoaded = createSelector(
  selectRoomServiceSubstateByCurrentProperty,
  fromRoomService.selectRoomServiceLoaded
)
export const selectCurrentRoomServiceId = createSelector(selectRouterParams, x => [x.roomServiceId])
export const selectSelectedRoomService = createSelector(
  // selectCurrentRoomServiceIds,
  selectCurrentRoomServiceId,
  selectRoomServicesEntities,
  safeObject(matchEntity)
)

/**
 * ProductCategories
 */
// selectPropertySubstateProductCategoryIds
export const selectProductCategoryState = createFeatureSelector<fromProductCategory.State>(
  'productCategory'
)
export const selectPropertiesProductCategorySubstates = createSelector(
  selectProductCategoryState,
  fromProductCategory.selectSubstates
)
export const selectProductCategorySubstateByCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesProductCategorySubstates,
  safeObject(matchEntity)
)
export const selectCurrentProductCategoryIds = createSelector(
  selectProductCategorySubstateByCurrentProperty,
  safeArray(fromProductCategory.selectPropertySubstateProductCategoryIds)
)

export const selectCurrentPropertyProductCategories = createSelector(
  selectCurrentProductCategoryIds,
  selectProductCategoriesEntities,
  safeArray(matchEntities)
)

/**
 * Product
 */
export const selectProductState = createFeatureSelector<fromProduct.State>('product')
export const selectPropertiesProductSubstates = createSelector(
  selectProductState,
  fromProduct.selectSubstates
)
export const selectProductSubstateByCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesProductSubstates,
  safeObject(matchEntity)
)
export const selectProductEntitiesByCurrentProperty = createSelector(
  selectProductSubstateByCurrentProperty,
  selectProductsEntities,
  (substate, entities) => (substate.items ? substate.items.map(id => entities[id]) : [])
)
export const selectUnassignedProducts = createSelector(
  selectProductSubstateByCurrentProperty,
  selectProductsEntities,
  (productSubstate, products) =>
    productSubstate.items
      ? productSubstate.items.map(id => products[id]).filter(R.propEq('category', null))
      : []
)
export const selectProductCategoriesWithNestedProducts = createSelector(
  selectProductCategorySubstateByCurrentProperty,
  selectProductSubstateByCurrentProperty,
  selectProductCategoriesEntities,
  selectProductsEntities,
  (categorySubstate, productSubstate, categories, products) => {
    if (!categorySubstate.items) {
      return []
    }
    return categorySubstate.items.map(categoryId => ({
      ...categories[categoryId],
      products: productSubstate.items
        ? productSubstate.items
            .map(productId => products[productId])
            .filter(R.propEq('category', categoryId))
        : [],
    }))
  }
)

/**
 * Reservations
 */
export const selectReservationState = createFeatureSelector<fromReservation.State>('reservation')
export const selectReservationsSubstates = createSelector(
  selectReservationState,
  fromReservation.selectSubstates
)
export const selectCurrentReservationsSubstate = createSelector(
  selectCurrentPropertyId,
  selectReservationsSubstates,
  safeObject(matchEntity)
)
export const selectCurrentReservationsLoaded = createSelector(
  selectCurrentReservationsSubstate,
  safeObject(fromReservation.selectSubstateLoaded)
)
export const selectCurrentReservationsLoading = createSelector(
  selectCurrentReservationsSubstate,
  safeObject(fromReservation.selectSubstateLoading)
)
export const selectCurrentReservationsIds = createSelector(
  selectCurrentReservationsSubstate,
  safeArray(fromReservation.selectSubstateIds)
)
export const selectCurrentReservationsMeta = createSelector(
  selectCurrentReservationsSubstate,
  safeArray(fromReservation.selectSubstateMeta)
)
export const selectCurrentReservations = createSelector(
  selectCurrentReservationsIds,
  selectReservationEntities,
  safeArray(matchEntities)
)
/**
 * Reviews
 */

export const enrichReview = (
  review,
  chats: Record<string, Chat>,
  users = null,
  staffUsers = null,
  forms = null
): Review => {
  if (!review || !review.events) {
    // Skip if not loaded
    return null
  }
  const chat = chats[review.chat]
  return {
    ...review,
    __chat: chat,
    __form: forms && forms[review.formCode],
    events:
      users && staffUsers
        ? review.events.map(event => enrichTimelineEvent(event, users, staffUsers))
        : review.events,
  }
}
export const selectReviewState = createFeatureSelector<fromReview.State>('review')
export const selectReviewsSubstates = createSelector(selectReviewState, fromReview.selectSubstates)
export const selectCurrentReviewsSubstate = createSelector(
  selectCurrentPropertyId,
  selectReviewsSubstates,
  safeObject(matchEntity)
)
export const selectCurrentReviewsLoaded = createSelector(
  selectCurrentReviewsSubstate,
  fromReview.selectSubstateLoaded
)
export const selectCurrentReviewsLoading = createSelector(
  selectCurrentReviewsSubstate,
  fromReview.selectSubstateLoading
)
export const selectCurrentReviewsIds = createSelector(
  selectCurrentReviewsSubstate,
  safeArray(fromReview.selectSubstateIds)
)
export const selectCurrentReviewId = createSelector(
  selectReviewState,
  fromReview.selectCurrentReviewId
)
export const selectCurrentReviewsFilters = createSelector(
  selectCurrentReviewsSubstate,
  safeObject(fromReview.selectSubstateFilters)
)
export const selectCurrentReviewsTotalRecords = createSelector(
  selectCurrentReviewsSubstate,
  safeObject(fromReview.selectSubstateTotalRecords)
)
export const selectCurrentReviewsOpenedRecords = createSelector(
  selectCurrentReviewsSubstate,
  fromReview.selectSubstateOpenedRecords
)
export const selectCurrentReviews = createSelector(
  selectCurrentReviewsIds,
  selectReviewsEntities,
  safeArray(matchEntities)
)

export const selectCurrentReviewsWithChats = createSelector(
  selectCurrentReviews,
  selectChatsEntities,
  populateReviewsWithChat
)

export const selectCurrentReview = createSelector(
  selectCurrentReviewId,
  selectReviewsEntities,
  safeObject(matchEntity)
)

export const selectCurrentReviewEnriched = createSelector(
  selectCurrentReview,
  selectChatsEntities,
  selectUsersEntities,
  selectStaffUsersEntities,
  selectFormsEntities,
  enrichReview
)

/**
 * Cuisines
 */
export const selectCuisineState = createFeatureSelector<fromCuisines.State>('cuisines')
export const selectCuisinesIds = createSelector(selectCuisineState, fromCuisines.selectIds)
export const selectCuisinesLoaded = createSelector(selectCuisineState, fromCuisines.selectLoaded)
export const selectCuisines = createSelector(
  selectCuisinesIds,
  selectCuisineEntities,
  matchEntities
)
export const selectCuisinesFilters = createSelector(selectCuisineState, fromCuisines.selectFilters)
export const selectCuisinesTotalRecords = createSelector(
  selectCuisineState,
  fromCuisines.selectTotalRecords
)

/**
 * Chats
 */
export const selectChatState = createFeatureSelector<fromChat.State>('chat')
export const selectPropertiesChatsSubstates = createSelector(
  selectChatState,
  fromChat.selectPropertiesSubstates
)
export const selectChatsSubstateByCurrentProperty = createSelector(
  selectCurrentPropertyId,
  selectPropertiesChatsSubstates,
  safeObject(matchEntity)
)
export const selectCurrentChatsLoaded = createSelector(
  selectChatsSubstateByCurrentProperty,
  safeObject(fromChat.selectPropertySubstateLoaded)
)
export const selectCurrentAllChatsIds = createSelector(
  selectChatsSubstateByCurrentProperty,
  safeArray(fromChat.selectPropertySubstateAllChatsIds)
)
export const selectCurrentAllChats = createSelector(
  selectCurrentAllChatsIds,
  selectChatsEntities,
  safeArray(matchEntities)
)

export const selectCurrentInboxChatsIds = createSelector(
  selectChatsSubstateByCurrentProperty,
  safeArray(fromChat.selectPropertySubstateInboxChatsIds)
)
export const selectCurrentInboxChats = createSelector(
  selectCurrentInboxChatsIds,
  selectChatsEntities,
  safeArray(matchEntities)
)

export const selectCurrentChatId = createSelector(selectChatState, fromChat.selectCurrentChatId)

export const selectCurrentChatsTabIndex = createSelector(
  selectChatState,
  fromChat.selectCurrentChatsTabIndex
)

export const selectCurrentChat = createSelector(
  selectCurrentChatId,
  selectChatsEntities,
  matchEntity
)

export const selectChatsInboxCounter = createSelector(
  selectChatsSubstateByCurrentProperty,
  fromChat.selectChatSubstateInboxCounter
)

export const selectChatsAllCounter = createSelector(
  selectChatsSubstateByCurrentProperty,
  fromChat.selectChatSubstateAllCounter
)

export const selectChatsAllPage = createSelector(
  selectChatsSubstateByCurrentProperty,
  fromChat.selectChatSubstatePage
)

export const selectChatsSubstates = createSelector(selectChatState, fromChat.selectChatsSubstate)

export const selectCurrentChatSubstate = createSelector(
  selectCurrentChatId,
  selectChatsSubstates,
  safeObject(matchEntity)
)

export const selectCurrentChatAllPastMessagesLoaded = createSelector(
  selectCurrentChatSubstate,
  fromChat.selectChatSubstateAllPastMessagesLoaded
)

export const selectCurrentChatLoadingPastMessages = createSelector(
  selectCurrentChatSubstate,
  fromChat.selectChatSubstateLoadingPastMessages
)

export const selectCurrentChatFormResponses = createSelector(
  selectCurrentChatSubstate,
  safeArray(fromChat.selectChatSubstateFormResponses)
)

export const selectCurrentChatShopCarts = createSelector(
  selectCurrentChatSubstate,
  safeArray(fromChat.selectChatSubstateShopCarts)
)

export const selectCurrentChatMessages = createSelector(
  selectCurrentChatSubstate,
  safeArray(fromChat.selectChatSubstateMessages)
)

export const selectCurrentChatVisitedPages = createSelector(
  selectCurrentChatSubstate,
  safeArray(fromChat.selectChatSubstateVisitedPages)
)

/**
 * FAQ
 */
export const selectFAQState = createFeatureSelector<fromFAQ.State>('faq')
export const selectFAQSubstates = createSelector(selectFAQState, fromFAQ.selectSubstates)
export const selectFAQCategoriesIds = createSelector(selectFAQState, fromFAQ.selectFAQCategoriesIds)
export const selectCurrentFAQSubstate = createSelector(
  selectCurrentPropertyId,
  selectFAQSubstates,
  matchEntity
)
export const selectCurrentFAQData = createSelector(
  selectCurrentFAQSubstate,
  fromFAQ.selectSubstateData
)
export const selectCurrentFAQLoaded = createSelector(
  selectCurrentFAQSubstate,
  fromFAQ.selectSubstateLoaded
)
export const selectFAQCategories = createSelector(
  selectFAQCategoriesIds,
  selectFAQCategoriesEntities,
  matchEntities
)
export const selectFAQLoaded = createSelector(selectFAQState, fromFAQ.selectIsLoaded)
export const selectFAQFilters = createSelector(selectFAQState, fromFAQ.selectFilters)
export const selectFAQTotalRecords = createSelector(selectFAQState, fromFAQ.selectTotalRecords)

export const selectFAQExistingKeys = createSelector(selectFAQState, fromFAQ.selectExistingKeys)

/**
 * Dashboard
 */
export const selectDashboardState = createFeatureSelector<fromDashboard.State>('dashboard')
export const selectDashboardsSubstates = createSelector(
  selectDashboardState,
  fromDashboard.selectSubstates
)
export const selectCurrentDashbordSubstate = createSelector(
  selectCurrentPropertyId,
  selectDashboardsSubstates,
  matchEntity
)
export const selectCurrentDashboardStats = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateStats
)
export const selectCurrentDashboardStatsLoading = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateStatsLoading
)
export const selectCurrentDashboardStatsLoaded = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateStatsLoaded
)
export const selectCurrentDashboardStatsDays = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateStatsDays
)
export const selectCurrentDashboardStatRange = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateStatRange
)
export const selectCurrentDashboardStatsSegmentsNumber = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateStatsSegmentsNumber
)
export const selectCurrentDashboardFormResponsesIds = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateFormResponsesIds
)
export const selectCurrentDashboardFormResponsesLoaded = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateFormResponsesLoaded
)
export const selectCurrentDashboardFormResponsesLoading = createSelector(
  selectCurrentDashbordSubstate,
  fromDashboard.selectSubstateFormResponsesLoading
)
export const selectCurrentDashboardFormResponses = createSelector(
  selectCurrentDashboardFormResponsesIds,
  selectFormResponsesEntities,
  matchEntities
)

/**
 * Push templates
 */
export const selectPushState = createFeatureSelector<fromPush.State>('push')
export const selectPushesSubstates = createSelector(selectPushState, fromPush.selectSubstates)
export const selectCurrentPushesSubstate = createSelector(
  selectCurrentPropertyId,
  selectPushesSubstates,
  matchEntity
)
export const selectCurrentPushesLoading = createSelector(
  selectCurrentPushesSubstate,
  fromPush.selectSubstateLoading
)
export const selectCurrentPushesCreating = createSelector(
  selectCurrentPushesSubstate,
  fromPush.selectSubstateCreating
)
export const selectCurrentPushesLoaded = createSelector(
  selectCurrentPushesSubstate,
  fromPush.selectSubstateLoaded
)
export const selectCurrentPushesIds = createSelector(
  selectCurrentPushesSubstate,
  fromPush.selectSubstateIds
)
export const selectCurrentPushes = createSelector(
  selectCurrentPushesIds,
  selectPushesEntities,
  matchEntities
)
export const selectCurrentPushesFilters = createSelector(
  selectCurrentPushesSubstate,
  fromPush.selectSubstateFilters
)
export const selectCurrentPushesTotalRecords = createSelector(
  selectCurrentPushesSubstate,
  fromPush.selectSubstateTotalRecords
)

export const enrichPushWithUser = (push, users): Push => {
  if (!push) {
    return null
  }
  return {
    ...push,
    __user: users && users[push.user],
  }
}

export const selectCurrentPushesWithUsers = createSelector(
  selectCurrentPushes,
  selectUsersEntities,
  (pushes, users): Push[] => {
    return pushes.map(push => enrichPushWithUser(push, users))
  }
)
export const selectCurrentPushId = createSelector(selectPushState, fromPush.selectCurrentPushId)
export const selectCurrentPush = createSelector(
  selectCurrentPushId,
  selectPushesEntities,
  matchEntity
)
export const selectCurrentPushEnriched = createSelector(
  selectCurrentPush,
  selectUsersEntities,
  enrichPushWithUser
)

/**
 * Forms (aka RequestForms)
 */

export const selectCurrentFormId = createSelector(selectRouterParams, x => x.formId as string)
export const selectCurrentForm = createSelector(
  selectCurrentFormId,
  selectFormsEntities,
  matchEntity
)
export const selectCurrentFormTemplate = createSelector(
  selectCurrentFormId,
  selectFormTemplatesEntities,
  matchEntity
)
export const selectFormsState = createFeatureSelector<fromForm.State>('form')
export const selectFormsSubstates = createSelector(selectFormsState, fromForm.selectSubstates)
export const selectFormsTemplatesState = createSelector(selectFormsState, fromForm.selectTemplates)
export const selectFormTemplatesIds = createSelector(
  selectFormsTemplatesState,
  fromForm.selectSubstateIds
)
export const selectCurrentFormsSubstate = createSelector(
  selectCurrentPropertyId,
  selectFormsSubstates,
  safeObject(matchEntity)
)
export const selectCurrentFormsIds = createSelector(
  selectCurrentFormsSubstate,
  fromForm.selectSubstateIds
)
export const selectCurrentForms = createSelector(
  selectCurrentFormsIds,
  selectFormsEntities,
  matchEntities
)
export const selectCurrentRequestForms = createSelector(
  selectCurrentForms,
  R.filter(R.propEq('formType', 'request'))
)
export const selectCurrentBookingForms = selectCurrentForms
export const selectFormTemplates = createSelector(
  selectFormTemplatesIds,
  selectFormTemplatesEntities,
  matchEntities
)
export const selectFormsLoaded = createSelector(
  selectCurrentFormsSubstate,
  fromForm.selectFormsLoaded
)
export const selectTemplatesLoaded = createSelector(
  selectFormsTemplatesState,
  fromForm.selectTemplatesLoaded
)
export const selectTemplatesLoading = createSelector(
  selectFormsTemplatesState,
  fromForm.selectTemplatesLoading
)

/**
 * FormResponses (aka GuestRequests)
 */
export const enrichFormResponse = (
  response,
  chats: Record<string, Chat>,
  users = null,
  staffUsers = null,
  forms = null
): FormResponse => {
  if (!response) {
    // Skip if not loaded
    return null
  }
  const chat = chats[response.chat]
  return {
    ...response,
    __chat: chat,
    __form: forms && forms[response.formCode],
    events:
      users && staffUsers
        ? response.events.map(event => enrichTimelineEvent(event, users, staffUsers))
        : response.events,
  }
}
export const selectFormResponseState = createFeatureSelector<fromFormResponse.State>('formResponse')
export const selectFormResponseSubstates = createSelector(
  selectFormResponseState,
  fromFormResponse.selectSubstates
)
export const selectCurrentFormResponseId = createSelector(
  selectFormResponseState,
  fromFormResponse.selectCurrentFormResponsesId
)
export const selectCurrentFormResponse = createSelector(
  selectCurrentFormResponseId,
  selectFormResponsesEntities,
  matchEntity
)
export const selectCurrentFormResponseEnriched = createSelector(
  selectCurrentFormResponse,
  selectChatsEntities,
  selectUsersEntities,
  selectStaffUsersEntities,
  selectFormsEntities,
  enrichFormResponse
)
export const selectCurrentFormResponsesSubstate = createSelector(
  selectCurrentPropertyId,
  selectFormResponseSubstates,
  matchEntity
)
export const selectCurrentFormResponsesLoading = createSelector(
  selectCurrentFormResponsesSubstate,
  fromFormResponse.selectSubstateLoading
)
export const selectCurrentFormResponsesLoaded = createSelector(
  selectCurrentFormResponsesSubstate,
  fromFormResponse.selectSubstateLoaded
)
export const selectCurrentFormResponsesIds = createSelector(
  selectCurrentFormResponsesSubstate,
  fromFormResponse.selectSubstateIds
)
export const selectCurrentFormResponsesFilters = createSelector(
  selectCurrentFormResponsesSubstate,
  fromFormResponse.selectSubstateFilters
)
export const selectCurrentFormResponsesTotalRecords = createSelector(
  selectCurrentFormResponsesSubstate,
  fromFormResponse.selectSubstateTotalRecords
)
export const selectCurrentFormResponsesOpenedRecords = createSelector(
  selectCurrentFormResponsesSubstate,
  fromFormResponse.selectSubstateOpenedRecords
)
export const selectCurrentFormResponses = createSelector(
  selectCurrentFormResponsesIds,
  selectFormResponsesEntities,
  matchEntities
)
export const selectCurrentFormResponsesWithChats = createSelector(
  selectCurrentFormResponses,
  selectChatsEntities,
  enrichFormResponsesWithChats
)

/**
 * Cart
 */
export const selectCartState = createFeatureSelector<fromCart.State>('cart')
export const selectCartSubstates = createSelector(selectCartState, fromCart.selectSubstates)
export const selectCurrentCartsSubstate = createSelector(
  selectCurrentPropertyId,
  selectCartSubstates,
  matchEntity
)
export const selectCurrentCartsLoaded = createSelector(
  selectCurrentCartsSubstate,
  fromCart.selectSubstateLoaded
)
export const selectCurrentCartsLoading = createSelector(
  selectCurrentCartsSubstate,
  fromCart.selectSubstateLoading
)
export const selectCurrentCartsIds = createSelector(
  selectCurrentCartsSubstate,
  fromCart.selectSubstateIds
)
export const selectCurrentCarts = createSelector(
  selectCurrentCartsIds,
  selectCartsEntities,
  matchEntities
)
export const selectCurrentCartsWithChats = createSelector(
  selectCurrentCarts,
  selectChatsEntities,
  (carts, chats): Cart[] =>
    carts.map(x => ({
      ...x,
      __chat: chats[x.chat],
    }))
)
export const selectCurrentCartsFilters = createSelector(
  selectCurrentCartsSubstate,
  fromCart.selectSubstateFilters
)
export const selectCurrentCartsTotalRecords = createSelector(
  selectCurrentCartsSubstate,
  fromCart.selectSubstateTotalRecords
)
export const selectCurrentCartId = createSelector(selectCartState, fromCart.selectCurrentCartsId)
export const selectCurrentCart = createSelector(
  selectCurrentCartId,
  selectCartsEntities,
  matchEntity
)
export const selectCurrentCartEnriched = createSelector(
  selectCurrentCart,
  selectChatsEntities,
  selectUsersEntities,
  selectStaffUsersEntities,
  selectProductCategoriesEntities,
  selectProductsEntities,
  (cart, chats, users, staffUsers, categories, products): Cart => {
    if (!cart || !cart.chat) {
      return null
    }
    const cartChat = R.prop(cart.chat, chats)
    const cartProducts = R.pick(cart.items.map(R.prop('product')), products)
    return {
      ...cart,
      events:
        users && staffUsers
          ? cart.events.map(event => enrichTimelineEvent<CartEventItem>(event, users, staffUsers))
          : cart.events,
      __chat: cartChat,
      __products: cartProducts,
      __productCategories: R.pick(R.values(cartProducts).map(R.prop('category')), categories),
    }
  }
)
export const selectCurrentCartsOpenedRecords = createSelector(
  selectCurrentCartsSubstate,
  fromCart.selectSubstateOpenedRecords
)

/**
 * ShopCart
 */
export const selectShopCartState = createFeatureSelector<fromShopCart.State>('shopCart')
export const selectShopCartSubstates = createSelector(
  selectShopCartState,
  fromShopCart.selectSubstates
)
export const selectCurrentShopCartsSubstate = createSelector(
  selectCurrentPropertyId,
  selectShopCartSubstates,
  matchEntity
)
export const selectCurrentShopCartsLoaded = createSelector(
  selectCurrentShopCartsSubstate,
  fromShopCart.selectSubstateLoaded
)
export const selectCurrentShopCartsStatistics = createSelector(
  selectCurrentShopCartsSubstate,
  fromShopCart.selectSubstateStatistics
)
export const selectCurrentShopCartsLoading = createSelector(
  selectCurrentShopCartsSubstate,
  fromShopCart.selectSubstateLoading
)
export const selectCurrentShopCartsIds = createSelector(
  selectCurrentShopCartsSubstate,
  fromShopCart.selectSubstateIds
)
export const selectCurrentShopCarts = createSelector(
  selectCurrentShopCartsIds,
  selectShopCartsEntities,
  matchEntities
)
export const selectCurrentShopCartsWithChats = createSelector(
  selectCurrentShopCarts,
  selectChatsEntities,
  (carts, chats): ShopCart[] =>
    carts.map(x => ({
      ...x,
      __chat: chats[x.chat],
    }))
)
export const selectCurrentShopCartsFilters = createSelector(
  selectCurrentShopCartsSubstate,
  fromShopCart.selectSubstateFilters
)
export const selectCurrentShopCartsTotalRecords = createSelector(
  selectCurrentShopCartsSubstate,
  fromShopCart.selectSubstateTotalRecords
)
export const selectCurrentShopCartId = createSelector(
  selectShopCartState,
  fromShopCart.selectCurrentShopCartsId
)
export const selectCurrentShopCart = createSelector(
  selectCurrentShopCartId,
  selectShopCartsEntities,
  matchEntity
)
export const selectCurrentShopCartEnriched = createSelector(
  selectCurrentShopCart,
  selectChatsEntities,
  selectUsersEntities,
  selectStaffUsersEntities,
  // selectShopProductCategoriesEntities,
  // selectShopProductsEntities,
  // selectShopEntities,
  (cart, chats, users, staffUsers): ShopCart => {
    if (!cart || !cart.chat) {
      return null
    }
    const cartChat = R.prop(cart.chat, chats)
    return {
      ...cart,
      events:
        users && staffUsers
          ? cart.events.map(event =>
              enrichTimelineEvent<ShopCartEventItem>(event, users, staffUsers)
            )
          : cart.events,
      __chat: cartChat,
      // __shopProducts: cartProducts,
      // __rootShopProductCategory: categories[cart.shopProductCategory],
      // __nestedShopProductCategories: []
      // __shopProductCategories: cartCategories,
    }
  }
)
export const selectCurrentShopCartsOpenedRecords = createSelector(
  selectCurrentShopCartsSubstate,
  fromShopCart.selectSubstateOpenedRecords
)

/**
 * Wifi
 */
export const selectWifiState = createFeatureSelector<fromWifi.State>('wifi')
export const selectCaptivePortalsSubstates = createSelector(
  selectWifiState,
  fromWifi.selectCaptivePortals
)
export const selectCurrentCaptivePortalsSubstate = createSelector(
  selectCurrentPropertyId,
  selectCaptivePortalsSubstates,
  matchEntity
)
export const selectCaptivePortalsIds = createSelector(
  selectCurrentCaptivePortalsSubstate,
  fromWifi.selectCaptivePortalsIds
)
export const selectCaptivePortalsLoading = createSelector(
  selectCurrentCaptivePortalsSubstate,
  fromWifi.selectCaptivePortalsLoading
)
export const selectCaptivePortalsLoaded = createSelector(
  selectCurrentCaptivePortalsSubstate,
  fromWifi.selectCaptivePortalsLoaded
)
export const selectCurrentCaptivePortals = createSelector(
  selectCaptivePortalsIds,
  selectCaptivePortalsEntities,
  matchEntities
)

/**
 * Builder
 */

export const selectBuilderState = createFeatureSelector<fromBuilder.State>('builder')
export const selectBuilderSubstates = createSelector(
  selectBuilderState,
  fromBuilder.selectSubstates
)
export const selectCurrentBuilderSubstate = createSelector(
  selectCurrentPropertyId,
  selectBuilderSubstates,
  matchEntity
)
export const selectBuilderIds = createSelector(
  selectCurrentBuilderSubstate,
  fromBuilder.selectBuildersIds
)
export const selectBuilderLoading = createSelector(
  selectCurrentBuilderSubstate,
  fromBuilder.selectBuildersLoading
)
export const selectBuilderLoaded = createSelector(
  selectCurrentBuilderSubstate,
  fromBuilder.selectBuildersLoaded
)
export const selectBuilderUpdated = createSelector(
  selectCurrentBuilderSubstate,
  fromBuilder.selectBuildersUpdated
)
export const selectBuilderModules = createSelector(
  selectCurrentBuilderSubstate,
  fromBuilder.selectBuildersModules
)
export const selectCurrenBuilderRootId = createSelector(
  selectCurrentBuilderSubstate,
  fromBuilder.selectRootId
)
export const selectCurrentBuilderEntities = createSelector(
  selectBuilderIds,
  selectBuilderEntities,
  matchEntities
)

/**
 * Templates
 */

export const selectMenuPagesTemplatesState = createFeatureSelector<fromMenuPagesTemplates.State>(
  'menuPageTemplates'
)
export const selectMenuPagesTemplatesIds = createSelector(
  selectMenuPagesTemplatesState,
  fromMenuPagesTemplates.selectIds
)
export const selectMenuPagesTemplatesLoaded = createSelector(
  selectMenuPagesTemplatesState,
  fromMenuPagesTemplates.selectIsLoaded
)
export const selectMenuPagesTemplates = createSelector(
  selectMenuPagesTemplatesIds,
  selectMenuPagesTemplatesEntities,
  matchEntities
)

/**
 * Restaurant
 */

const enrichRestaurant = (
  restaurant: Restaurant,
  cuisinesEntities: any,
  restaurantCuisineIds: string[]
): Restaurant => {
  if (!restaurant) {
    return null
  }
  const cuisinesCodes = (restaurant && restaurant.cuisine) || []
  let restaurantCuisines = null
  if (cuisinesCodes && restaurantCuisineIds && restaurantCuisineIds.length) {
    const cuisinses = matchEntities(restaurantCuisineIds, cuisinesEntities) as Cuisine[]
    restaurantCuisines = R.filter(c => R.contains(c.code, cuisinesCodes), cuisinses)
  }
  return {
    ...restaurant,
    __cuisines: restaurantCuisines,
  }
}

export const selectRestaurantState = createFeatureSelector<fromRestaurant.State>('restaurant')
export const selectRestaurantsIds = createSelector(selectRestaurantState, fromRestaurant.selectIds)
export const selectRestaurantsLoaded = createSelector(
  selectRestaurantState,
  fromRestaurant.selectIsLoaded
)
export const selectRestaurants = createSelector(
  selectRestaurantsIds,
  selectRestaurantsEntities,
  matchEntities
)
export const selectRestaurantsCategories = createSelector(
  selectRestaurantState,
  fromRestaurant.selectCategories
)

export const selectRestaurantsCuisinesIds = createSelector(
  selectRestaurantState,
  fromRestaurant.selectRestaurantCuisinesIds
)

export const selectRestaurantsFilters = createSelector(
  selectRestaurantState,
  fromRestaurant.selectFilters
)
export const selectRestaurantsTotalRecords = createSelector(
  selectRestaurantState,
  fromRestaurant.selectTotalRecords
)

export const selectCurrentRestaurantId = createSelector(
  selectRestaurantState,
  fromRestaurant.selectCurrentId
)

export const selectCurrentRestaurant = createSelector(
  selectCurrentRestaurantId,
  selectRestaurantsEntities,
  matchEntity
)

export const selectCurrentRestaurantWithCuisines = createSelector(
  selectCurrentRestaurant,
  selectCuisineEntities,
  selectRestaurantsCuisinesIds,
  enrichRestaurant
)

export const selectCurrentRestaurantLoaded = createSelector(
  selectRestaurantState,
  selectCurrentRestaurantId,
  fromRestaurant.selectIsLoadedById
)

/**
 * Analytics
 */
export const selectAnalyticsState = createFeatureSelector<fromAnalytics.State>('analytics')
export const selectAnalytics = createSelector(selectAnalyticsState, fromAnalytics.selectAnalytics)
export const selectAnalyticsLoaded = createSelector(
  selectAnalyticsState,
  fromAnalytics.selectAnalyticsLoaded
)
export const selectAnalyticsLoading = createSelector(
  selectAnalyticsState,
  fromAnalytics.selectAnalyticsLoading
)
export const selectShopStatistics = createSelector(
  selectAnalyticsState,
  fromAnalytics.selectShopStatistics
)

/**
 * Legals
 */

export const selectLegalEntitiesState = createFeatureSelector<fromLegalEntities.State>(
  'legalEntities'
)
export const selectLegalEntitiesIds = createSelector(
  selectLegalEntitiesState,
  fromLegalEntities.selectIds
)
export const selectLegalEntities = createSelector(
  selectLegalEntitiesIds,
  selectLegalEntityEntities,
  matchEntities
)
export const selectLegalEntitiesLoaded = createSelector(
  selectLegalEntitiesState,
  fromLegalEntities.selectLegalEntitiesLoaded
)
export const selectCurrentLegalEntityId = createSelector(
  selectLegalEntitiesState,
  fromLegalEntities.selectCurrentlySelectedLegalEntityId
)
export const selectCurrentLegalEntityLoaded = createSelector(
  selectLegalEntitiesState,
  selectCurrentLegalEntityId,
  fromLegalEntities.selectIsLoadedById
)
export const selectCurrentLegalEntity = createSelector(
  selectCurrentLegalEntityId,
  selectLegalEntityEntities,
  matchEntity
)

/**
 * SalesService Settings
 */

export const selectSalesServiceSettingsState = createFeatureSelector<fromSalesService.State>(
  'salesService'
)
export const selectSalesServiceSettingsIds = createSelector(
  selectSalesServiceSettingsState,
  fromSalesService.selectIds
)

export const selectSalesServiceSettings = createSelector(
  selectSalesServiceSettingsIds,
  selectSalesServiceSettingsEntities,
  matchEntities
)
export const selectSalesServiceSettingsLoaded = createSelector(
  selectSalesServiceSettingsState,
  fromSalesService.selectSalesServiceSettingsLoaded
)
export const selectCurrentSalesServiceSettingsId = createSelector(
  selectSalesServiceSettingsState,
  fromSalesService.selectCurrentlySelectedSalesServiceSettingsId
)
export const selectCurrentSalesServiceSettingsLoaded = createSelector(
  selectSalesServiceSettingsState,
  selectCurrentSalesServiceSettingsId,
  fromSalesService.selectIsLoadedById
)
export const selectCurrentSalesServiceSettings = createSelector(
  selectCurrentSalesServiceSettingsId,
  selectSalesServiceSettingsEntities,
  matchEntity
)
