import { Injectable } from '@angular/core'

import { L10nTranslationService } from 'angular-l10n'
import { languageList, getLanguageInfoByLocale, CustomTranslationLoader } from '../../app.i18n'
import { take, map } from 'rxjs/operators'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(
    private localeLoader: CustomTranslationLoader,
    private translationService: L10nTranslationService
  ) {
    const lang = this.translationService.getLocale().language
    moment.locale(lang)
  }

  public currentLanguage() {
    return this.translationService.getLocale().language
  }

  public translationChanged() {
    return this.translationService.onChange().pipe(map(x => x.language))
  }

  public get supportedLanguages() {
    return languageList
  }

  public translate(keys: string | string[], args?: any, lang?: string): string | any {
    return this.translationService.translate(keys, args, lang)
  }

  switchLanguage(locale: string) {
    // const { language, country, currency, numberingSystem } = getLanguageInfoByLocale(locale)
    const { language } = getLanguageInfoByLocale(locale)
    this.localeLoader
      .get(language)
      .pipe(take(1))
      .subscribe(() => {
        console.log('[i18n] switching language to:', locale)
        moment.locale(locale)
        this.translationService.setLocale({ language })
        // this.locale.setDefaultLocale(language, country, undefined, numberingSystem)
        // this.locale.setCurrentCurrency(currency)
      })
  }
}
