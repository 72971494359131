import { Pipe, PipeTransform } from '@angular/core'
import { LanguageType } from '@shared/constants'
import { PushSendType } from '@models'

const translations = {
  [LanguageType.RU]: {
    [PushSendType.IMMEDIATELY]: 'Сейчас',
    [PushSendType.DEFERRED]: 'Ко времени',
  },
  [LanguageType.EN]: {
    [PushSendType.IMMEDIATELY]: 'Now',
    [PushSendType.DEFERRED]: 'Deferred',
  },
  [LanguageType.ES]: {
    [PushSendType.IMMEDIATELY]: 'Ahora',
    [PushSendType.DEFERRED]: 'Diferido',
  },
}

@Pipe({
  name: 'pushSendType',
})
export class PushSendTypePipe implements PipeTransform {
  transform(value: any, lang = 'ru'): any {
    const translation = translations[lang]
    return (translation && translation[value]) || null
  }
}
