import { ShopCartFilters, DEFAULT_PAGINATION_SIZE, ShopServiceStatistics } from '@models'
import { Actions as ShopCartActions, ActionTypes as ShopCartActionTypes } from './shop-cart.actions'

export interface Substate {
  ids: string[]
  loaded: boolean
  loading: boolean
  filters: ShopCartFilters
  meta?: ShopCartMeta
  statistics?: ShopServiceStatistics
}

export interface ShopCartMeta {
  totalRecords?: number
  openedRecords?: number
}

export interface State {
  selectedId: string | null
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialSubstate: Substate = {
  ids: [],
  loaded: false,
  loading: false,
  filters: {
    status: null,
    paginationOptions: {
      page: 0,
      limit: DEFAULT_PAGINATION_SIZE,
    },
  },
  meta: {
    totalRecords: 0,
    openedRecords: 0,
  },
}

export const initialState: State = {
  selectedId: null,
  byPropertyId: {},
}

export function substateReducer(state = initialSubstate, action: ShopCartActions): Substate {
  switch (action.type) {
    case ShopCartActionTypes.Load: {
      return {
        ids: [],
        loading: true,
        loaded: false,
        filters: {
          status: action.payload.status,
          paginationOptions: {
            page: action.payload.page,
            limit: action.payload.limit,
          },
        },
        meta: state.meta,
      }
    }

    case ShopCartActionTypes.LoadSuccess: {
      return {
        ...state,
        ids: action.payload.results,
        meta: action.payload.meta,
        loading: false,
        loaded: true,
      }
    }

    case ShopCartActionTypes.LoadStatisticsSuccess: {
      return {
        ...state,
        statistics: action.payload,
      }
    }

    case ShopCartActionTypes.UpdateStatusSuccess: {
      return {
        ...state,
        meta: action.payload.meta,
      }
    }

    case ShopCartActionTypes.UpdateMeta: {
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.meta,
        },
      }
    }

    case ShopCartActionTypes.LoadFail: {
      return {
        ids: [],
        loading: false,
        loaded: true,
        filters: {
          status: action.meta.status,
          paginationOptions: {
            page: 0,
            limit: 0,
          },
        },
      }
    }
  }
  return state
}

export function reducer(state = initialState, action: ShopCartActions): State {
  switch (action.type) {
    case ShopCartActionTypes.Load:
    case ShopCartActionTypes.LoadSuccess:
    case ShopCartActionTypes.UpdateStatusSuccess:
    case ShopCartActionTypes.UpdateMeta:
    case ShopCartActionTypes.LoadFail:
    case ShopCartActionTypes.LoadStatisticsSuccess:
      const query =
        action.type === ShopCartActionTypes.Load || action.type === ShopCartActionTypes.UpdateMeta
          ? action.payload
          : action.meta
      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [query.propertyId]: substateReducer(state.byPropertyId[query.propertyId], action),
        },
      }

    case ShopCartActionTypes.Select:
      return {
        ...state,
        selectedId: action.payload.shopCartId,
      }

    default:
      return state
  }
}

export const selectCurrentShopCartsId = (state: State) => state.selectedId
export const selectSubstates = (state: State) => state.byPropertyId
export const selectSubstateIds = (substate: Substate) => substate && substate.ids
export const selectSubstateLoading = (substate: Substate) => substate && substate.loading
export const selectSubstateLoaded = (substate: Substate) => substate && substate.loaded
export const selectSubstateStatistics = (substate: Substate) => substate && substate.statistics
export const selectSubstateFilters = (substate: Substate) => substate && substate.filters
export const selectSubstateTotalRecords = (substate: Substate) =>
  substate && substate.meta ? substate.meta.totalRecords : 0
export const selectSubstateOpenedRecords = (substate: Substate) =>
  substate && substate.meta ? substate.meta.openedRecords : 0
