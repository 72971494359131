import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import * as shopActions from './shop.actions'
import { ShopService } from './shop.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class ShopEffects {
  // Load
  @Effect()
  public load$ = this.actions$.pipe(
    ofType(shopActions.ShopActionTypes.Load),
    switchMap((action: shopActions.Load) => {
      const meta = action.payload
      return this.shopService.load(action.payload.propertyId).pipe(
        map(x => {
          return new shopActions.LoadSuccess(x, meta)
        }),
        catchError(x => of(new shopActions.LoadFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(shopActions.ShopActionTypes.LoadFail),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.loadShopFail')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  // Update
  @Effect()
  update$ = this.actions$.pipe(
    ofType(shopActions.ShopActionTypes.Update),
    switchMap((action: shopActions.Update) => {
      const meta = action.payload
      return this.shopService
        .update(
          action.payload.propertyId,
          action.payload.shopId,
          action.payload.shop,
          action.payload.sourceLanguage,
          action.payload.targetLanguages,
          action.payload.forceTranslate
        )
        .pipe(
          map(x => new shopActions.UpdateSuccess(x, meta)),
          catchError(x => of(new shopActions.UpdateFail(x, meta)))
        )
    }),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.updateShopSuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(shopActions.ShopActionTypes.UpdateFail),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.updateShopFail')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private shopService: ShopService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
