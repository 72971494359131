import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  Inject,
  OnChanges,
  EventEmitter,
} from '@angular/core'
import * as moment from 'moment'
import { Subject } from 'rxjs'
import { FormBuilder, FormControl, FormGroup, FormArray, AbstractControl } from '@angular/forms'
import { timeSlotValidator } from '@shared/validators'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

type WorkingHours = Array<{
  day: 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su'
  from: string
  to: string
}>

const DEFAULT_WORKING_TIME = ['10:00', '19:00']
const DEFAULT_WORKING_TIME_24HOURS = ['00:00', '23:59']

@Component({
  selector: 'hot-working-hours',
  templateUrl: './working-hours.component.html',
  // encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent implements OnInit, OnDestroy, OnChanges {
  private destroyed = new Subject()
  public form = new FormGroup({})
  public timeSteps
  private initialized = false

  @Input() value: WorkingHours
  @Output() change = new EventEmitter()

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    this.initializeForm()
    this.timeSteps = this.generateTimeSteps()
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed.next()
  }

  ngOnChanges(changes) {
    if (changes.value) {
      this.updateFormWithData(changes.value.currentValue)
    }
  }

  updateFormWithData(data) {
    if (!data) {
      return
    }
    this.initialized = false
    const formData = {}
    formData['WorkingHours'] = [0, 1, 2, 3, 4, 5, 6].map(index => {
      const weekDaySlug = this.getWeekDaySlug(index)
      const intervals = data.filter(d => d.day === weekDaySlug)
      const is24Hours = intervals.length
        ? intervals.some(
            i =>
              i.from === DEFAULT_WORKING_TIME_24HOURS[0] && i.to === DEFAULT_WORKING_TIME_24HOURS[1]
          )
        : false
      if (intervals.length > 1) {
        for (let i = 0; i < intervals.length - 1; i++) {
          this.addDayInterval(index)
        }
      }
      return intervals.length
        ? {
            Include: true,
            Is24Hours: is24Hours,
            Intervals: intervals.map(i => ({ StartHour: i.from, EndHour: i.to })),
          }
        : {
            Include: false,
            Is24Hours: false,
            Intervals: [],
          }
    })

    const workingHours = formData['WorkingHours']
    if (workingHours && workingHours.length) {
      for (let i = 0; i < 7; i++) {
        const dayControl = this.workingHoursControls[i]
        if (!dayControl) {
          continue
        }
        const intervals = dayControl.get('Intervals') as FormArray
        intervals.clear()
        const dayIntervalsCount =
          (workingHours[i] && workingHours[i].Intervals && workingHours[i].Intervals.length) || 0
        for (let j = 0; j < dayIntervalsCount; j++) {
          const interval = this.fb.group({
            StartHour: new FormControl(DEFAULT_WORKING_TIME[0]),
            EndHour: new FormControl(DEFAULT_WORKING_TIME[1]),
          })
          intervals.push(interval)
          this.subscribeIntervalControls(i, interval)
        }
        if (!dayIntervalsCount) {
          const interval = this.fb.group({
            StartHour: new FormControl(DEFAULT_WORKING_TIME[0]),
            EndHour: new FormControl(DEFAULT_WORKING_TIME[1]),
          })
          intervals.push(interval)
          this.subscribeIntervalControls(i, interval)
        }
      }
    }

    this.form.patchValue(formData)
    this.initialized = true
  }

  public generateTimeSteps() {
    const tick = moment().startOf('day')
    const result = []
    for (let i = 0; i < 48; i++) {
      result.push(tick.format('HH:mm'))
      tick.add(30, 'm')
    }
    result.push(DEFAULT_WORKING_TIME_24HOURS[1])
    return result
  }

  public getWeekDaySlug(dayIndex) {
    return moment(dayIndex + 1, 'E')
      .locale('en')
      .format('dd')
      .toLowerCase()
  }

  public getWeekdayName(i) {
    const str = moment(i + 1, 'E')
      .locale(this.locale.language)
      .format('dd')
    return str[0].toUpperCase() + str.slice(1)
  }

  public addDayInterval(dayIndex) {
    const dayControl = this.workingHoursControls[dayIndex]
    if (!dayControl) {
      return
    }
    const intervals = dayControl.get('Intervals') as FormArray
    const interval = this.fb.group({
      StartHour: new FormControl(DEFAULT_WORKING_TIME[0]),
      EndHour: new FormControl(DEFAULT_WORKING_TIME[1]),
    })
    intervals.push(interval)
    this.form.markAsDirty()
    this.subscribeIntervalControls(dayIndex, interval)
  }

  public removeDayInterval(dayIndex, intervalIndex) {
    const dayControl = this.workingHoursControls[dayIndex]
    const intervals = dayControl.get('Intervals') as FormArray
    intervals.removeAt(intervalIndex)
    this.form.markAsDirty()
  }

  initializeForm() {
    this.form = this.fb.group({
      WorkingHours: this.fb.array(this.createDayFormGroup()),
    })
    // this.workingHoursControls.forEach((f, dayIndex) => {
    //   const intervals = f.get('Intervals') as FormArray
    //   intervals.controls.forEach(interval => {
    //     this.subscribeIntervalControls(dayIndex, interval)
    //   })
    // })
    if (this.value) {
      this.updateFormWithData(this.value)
    }
    this.form.valueChanges.subscribe(value => {
      if (this.initialized) {
        const formattedValue = value.WorkingHours.reduce((acc, day, index) => {
          if (!day.Include) {
            return acc
          }
          const weekDaySlug = this.getWeekDaySlug(index)
          const intervals = !day.Is24Hours
            ? day.Intervals.map(interval => ({
                day: weekDaySlug,
                from: interval.StartHour,
                to: interval.EndHour,
              }))
            : [
                {
                  day: weekDaySlug,
                  from: DEFAULT_WORKING_TIME_24HOURS[0],
                  to: DEFAULT_WORKING_TIME_24HOURS[1],
                },
              ]
          return [...acc, ...intervals]
        }, []).filter(Boolean)
        this.change.emit({
          value: formattedValue,
          isValid: this.form.valid,
        })
      }
    })
  }

  public get workingHoursControls(): AbstractControl[] {
    const workingHours = this.form.controls.WorkingHours as FormArray
    return workingHours ? workingHours.controls : []
  }

  createDayFormGroup() {
    const result = []
    for (let i = 1; i <= 7; i++) {
      result.push(
        this.fb.group({
          Include: true,
          Is24Hours: new FormControl(false),
          Intervals: this.fb.array([
            this.fb.group({
              StartHour: new FormControl(DEFAULT_WORKING_TIME[0]),
              EndHour: new FormControl(DEFAULT_WORKING_TIME[1]),
            }),
          ]),
        })
      )
    }
    return result
  }

  private subscribeIntervalControls(dayIndex, interval) {
    const dayForm = this.workingHoursControls[dayIndex]
    if (!dayForm) {
      return
    }
    const is24Hours = dayForm.get('Is24Hours')
    const include = dayForm.get('Include')
    const startHourControl = interval.get('StartHour')
    const endHourControl = interval.get('EndHour')

    startHourControl.valueChanges.subscribe((value: string) => {
      startHourControl.setErrors(null)
      const err = timeSlotValidator(value, true)(endHourControl)
      if (err) {
        startHourControl.setErrors(err)
      } else {
        startHourControl.setErrors(null)
        endHourControl.setErrors(null)
      }
    })
    endHourControl.valueChanges.subscribe((value: string) => {
      endHourControl.setErrors(null)
      const err = timeSlotValidator(value, false)(startHourControl)
      if (err) {
        endHourControl.setErrors(err)
      } else {
        startHourControl.setErrors(null)
        endHourControl.setErrors(null)
      }
    })
    is24Hours.valueChanges.subscribe((value: boolean) => {
      if (value) {
        startHourControl.patchValue(DEFAULT_WORKING_TIME_24HOURS[0])
        endHourControl.patchValue(DEFAULT_WORKING_TIME_24HOURS[1])
      }
    })
    include.valueChanges.subscribe((value: boolean) => {
      if (!value) {
        startHourControl.patchValue(DEFAULT_WORKING_TIME[0])
        endHourControl.patchValue(DEFAULT_WORKING_TIME[1])
      }
    })
  }
}
