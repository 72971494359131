import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResult, ShopProductCategory } from '@models'
import { LanguageType } from '@shared/constants'

@Injectable()
export class ShopProductCategoryService {
  constructor(public readonly apiService: ApiService) {}

  public create(
    propertyId: string,
    shopId: string,
    shopProductCategory: ShopProductCategory,
    parentShopProductCategoryId: string,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[]
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/shop/${shopId}/categories`, {
      parentShopProductCategoryId,
      shopProductCategory,
      sourceLanguage,
      targetLanguages,
    })
  }

  public update(
    propertyId: string,
    shopId: string,
    shopProductCategoryId: string,
    shopProductCategory: ShopProductCategory,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[],
    forceTranslate: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(
      `properties/${propertyId}/shop/${shopId}/categories/${shopProductCategoryId}`,
      {
        shopProductCategory,
        sourceLanguage,
        targetLanguages,
        forceTranslate,
      }
    )
  }

  public delete(
    propertyId: string,
    shopId: string,
    shopProductCategoryId: string
  ): Observable<NormalizedResult> {
    return this.apiService.delete(
      `properties/${propertyId}/shop/${shopId}/categories/${shopProductCategoryId}`
    )
  }

  public toggle(
    propertyId: string,
    shopId: string,
    shopProductCategoryId: string,
    paused: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(
      `properties/${propertyId}/shop/${shopId}/categories/${shopProductCategoryId}/toggle`,
      { paused }
    )
  }
}
