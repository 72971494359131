import * as R from 'ramda'
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Inject,
} from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { FormFieldConfig } from '@models'

@Component({
  selector: 'hot-form-field-date',
  templateUrl: './form-field-date.component.html',
  styleUrls: ['./form-field-date.component.css'],
})
export class FormFieldDateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public defaultLanguage
  @Input() public language
  @Input() public config: FormControl
  @Output() public changed = new EventEmitter()

  public form: FormGroup
  private destroyed = new Subject()

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    this.form = this.fb.group({
      required: this.fb.control(true),
      acceptUserText: false,
      requireTime: false,
      startingDayOffset: 0,
      days: this.fb.control(8, [Validators.max(10), Validators.min(2)]),
      autofillFrom: '',
    })

    // Show error right away on change (before blur event occurs)
    this.form.get('days').markAsTouched()
  }

  prepareConfig(data) {
    return {
      required: typeof data.required === 'boolean' ? data.required : true,
      acceptUserText: data.acceptUserText || false,
      requireTime: data.requireTime || false,
      startingDayOffset: data.startingDayOffset || 0,
      days: R.clamp(2, 10, data.days || 8),
      columns: data.columns || 3,
      autofillFrom: data.autofillFrom || '',
    }
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
      this.changed.next({
        ...this.config.value,
        ...this.prepareConfig(val),
      })
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      const config = this.config.value as FormFieldConfig
      this.form.patchValue(this.prepareConfig(config))
    }
  }
}
