<ng-template #buttonTmpl let-button="button" let-i="i" let-isFirst="isFirst" let-isLast="isLast">
  <div class="pr4 pl4 relative hot-builder-button-container">
    <mat-icon *ngIf="!isEditMode" (click)="handleRemove(i)" class="pointer mr1">delete</mat-icon>

    <button
      mat-stroked-button
      class="w-100 hot-builder-chat-button notranslate"
      [ngClass]="button.visibilityPeriod | visibilityPeriodState"
      [class.bg-washed-blue]="button.wifi"
      (click)="buttonClick(button)"
    >
      <mat-icon *ngIf="button.wifi" class="blue wifi-icon">wifi</mat-icon>
      {{ button.translations[currentLanguage].title }}
      <mat-icon
        *ngIf="isPageType(button, 'page') && button.translations[currentLanguage].title"
        class=""
        >art_track</mat-icon
      >
      <mat-icon
        *ngIf="isPageType(button, 'module') && button.translations[currentLanguage].title"
        class=""
        >assessment</mat-icon
      >
      <mat-icon *ngIf="isPageType(button, 'url') && button.translations[currentLanguage].title"
        >link</mat-icon
      >
      <mat-icon *ngIf="isPageType(button, 'template') && button.translations[currentLanguage].title"
        >view_quilt</mat-icon
      >
      <mat-icon *ngIf="isPageType(button, 'form') && button.translations[currentLanguage].title"
        >assignment</mat-icon
      >
      <mat-icon
        *ngIf="
          button.visibilityPeriod && (button.visibilityPeriod.start || button.visibilityPeriod.end)
        "
        class="visibility-period-icon"
        [ngClass]="button.visibilityPeriod | visibilityPeriodState"
        >timer</mat-icon
      >
      <mat-icon
        *ngIf="isButtonTracked(button) === true"
        svgIcon="hot:ai"
        class="ml2 h1 w1"
        matTooltip="{{ 'MenuBuilder.aiPowered' | translate: locale.language }}"
        [ngStyle]="{ color: '#2ac98f' }"
      ></mat-icon>
      <mat-icon
        *ngIf="isButtonTracked(button) === false"
        svgIcon="hot:ai"
        class="ml2 o-20 h1 w1"
        matTooltip="{{ 'MenuBuilder.noAiPowered' | translate: locale.language }}"
      ></mat-icon>
    </button>

    <mat-icon *ngIf="!isEditMode" (click)="handleButtonEdit(i)" class="pointer mh1"
      >settings</mat-icon
    >
  </div>
  <div class="flex flex-column" [ngClass]="{ disabled: isEditMode }">
    <div
      class="pointer hot-move-button hot-move-button--up"
      *ngIf="!isFirst"
      (click)="handleShift(i, i - 1)"
    >
      <mat-icon>expand_less</mat-icon>
    </div>
    <div
      class="pointer hot-move-button hot-move-button--down"
      *ngIf="!isLast"
      (click)="handleShift(i, i + 1)"
    >
      <mat-icon>expand_more</mat-icon>
    </div>
  </div>
</ng-template>

<section class="hot-builder-section">
  <div
    *ngFor="let button of buttons; let i = index; first as isFirst; last as isLast"
    class="flex items-center mv2"
  >
    <ng-container
      *ngTemplateOutlet="
        buttonTmpl;
        context: { button: button, i: i, isFirst: isFirst, isLast: isLast }
      "
    ></ng-container>
  </div>

  <button
    *ngIf="enableAddButtons"
    mat-button
    color="primary"
    class="w-100 mv2"
    #addNewButton
    [disabled]="disabledAddButton || isEditMode"
    (click)="handleAddButton()"
  >
    <span>{{
      'MenuBuilder.action.addButton' | translate: locale.language:{ count: regularButtons.length }
    }}</span>
  </button>

  <button
    *ngIf="enableAddWifiButtons"
    mat-button
    color="primary"
    class="w-100 mv2"
    #addNewButton
    [disabled]="disabledAddButton || isEditMode"
    (click)="handleAddWifiButton()"
  >
    <span>{{
      'MenuBuilder.action.addWifiButton' | translate: locale.language:{ count: wifiButtons.length }
    }}</span>
  </button>
</section>
