import { Component, OnInit, Input, Inject } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { Router } from '@angular/router'
import { LanguageType } from '@shared/constants'
import * as R from 'ramda'

const ROOT_SEGMENT = '..'

enum RouteName {
  Admin = 'admin',
  Analytics = 'analytics',
  Objects = 'objects',
  ExtarnalObjects = 'external_objects',
  FAQ = 'faq',
  Forms = 'forms',
  Properties = 'properties',
  Restaurants = 'restaurants',
  ChatBot = 'chat_bot',
  Templates = 'templates',
  Cuisines = 'cuisines',
  Facilities = 'facilities',
  LegalEntities = 'legal_entities',
  SalesService = 'sales_service',
  Merchants = 'merchants',
  Reference = 'reference',
}

const ROUTING_MATH = {
  [LanguageType.RU]: {
    [RouteName.Analytics]: 'Статистика',
    [RouteName.Facilities]: 'Facilities',
    [RouteName.Objects]: 'Объекты',
    [RouteName.ExtarnalObjects]: 'Внешние данные',
    [RouteName.ChatBot]: 'Чат-бот',
    [RouteName.Forms]: 'Шаблоны форм',
    [RouteName.FAQ]: 'FAQ',
    [RouteName.Properties]: 'Отели',
    [RouteName.Restaurants]: 'Рестораны',
    [RouteName.Templates]: 'Шаблоны страниц',
    [RouteName.Cuisines]: 'Кухни мира',
    [RouteName.LegalEntities]: 'Юр. лица',
    [RouteName.SalesService]: 'Сервис продаж',
    [RouteName.Merchants]: 'Мерчанты',
    [RouteName.Reference]: 'Справочник',
  },
  [LanguageType.EN]: {
    [RouteName.Analytics]: 'Analytics',
    [RouteName.Facilities]: 'Facilities',
    [RouteName.Objects]: 'Objects',
    [RouteName.ExtarnalObjects]: 'External Objects',
    [RouteName.Forms]: 'Form templates',
    [RouteName.FAQ]: 'FAQ',
    [RouteName.ChatBot]: 'ChatBot',
    [RouteName.Properties]: 'Properties',
    [RouteName.Restaurants]: 'Restaurants',
    [RouteName.Templates]: 'Page templates',
    [RouteName.Cuisines]: 'Cuisines',
    [RouteName.LegalEntities]: 'Legal entities',
    [RouteName.SalesService]: 'Sales Service',
    [RouteName.Merchants]: 'Merchants',
    [RouteName.Reference]: 'Reference',
  },
}

@Component({
  selector: 'hot-routing-display',
  template: `
    <div *ngIf="showUrlSegments" class="flex hot-route-segments">
      <ng-container *ngFor="let segment of urlSegments; let i = index; last as isLast">
        <div>
          <span
            class="pointer"
            [class.hot-activated-route-segment]="isActivatedSegment(segment)"
            (click)="navigateSegment(i)"
          >
            {{ getSegmentDisplayName(segment, i) }}
          </span>
          &nbsp;
          <span *ngIf="!isLast">
            /&nbsp;
          </span>
          <span *ngIf="isLast && currentPageName" class="hot-activated-route-segment">{{
            currentPageName
          }}</span>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .hot-route-segments {
        color: grey;
      }
      .hot-activated-route-segment {
        color: black;
      }
    `,
  ],
})
export class RoutingDisplayComponent implements OnInit {
  @Input() public currentPageName: string
  // @Input() public language = LanguageType.RU
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private router: Router) {}

  private segments: string[] = []

  ngOnInit() {
    this.segments = this.router.url
      .toString()
      .split('/')
      .filter(Boolean)

    if (this.currentPageName) {
      // this.segments.push(this.currentPageName)
    }
  }

  public get showUrlSegments(): boolean {
    return Boolean(this.segments)
  }

  public isActivatedSegment(segment: string): boolean {
    const lastSegment =
      this.segments && this.segments.length > 0 ? this.segments[this.segments.length - 1] : null
    return lastSegment && R.equals(lastSegment, segment)
  }

  public getSegmentDisplayName(segment: string, index: number): string {
    const routes = ROUTING_MATH[this.locale.language]
    return index === 0 ? ROOT_SEGMENT : (routes && routes[segment]) || null
  }

  public get urlSegments(): string[] {
    return this.segments
  }

  public navigateSegment(index: number): void {
    this.router.navigate(this.segments.slice(0, index + 1))
  }
}
