import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import * as fromLegalEntityActions from './legal-entity.actions'
import { LegalEntityService } from './legal-entity.service'

const CREATE_SUCCESS = 'CREATE_SUCCESS'
const DUPLICATE_ERROR = 'DUPLICATE_ERROR'
const CREATE_ERROR = 'CREATE_ERROR'
const LOAD_ERROR = 'LOAD_ERROR'
const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
const UPDATE_FAIL = 'UPDATE_FAIL'
// const DELETE_SUCCESS = 'DELETE_SUCCESS'
// const DELETE_ERROR = 'DELETE_ERROR'

@Injectable()
export class LegalEntityEffects {
  @Effect()
  public create$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.Create),
    switchMap((action: fromLegalEntityActions.Create) => {
      const meta = action.payload
      return this.legalEntityService.create(action.payload.legalEntity).pipe(
        map(x => {
          return new fromLegalEntityActions.CreateSuccess(x, meta)
        }),
        catchError(x => of(new fromLegalEntityActions.CreateFail(x, meta)))
      )
    })
  )

  /**
   * Create effects
   */
  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.CreateSuccess),
    tap(() => {
      this.matSnackBar.open(CREATE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.CreateFail),
    tap((action: fromLegalEntityActions.CreateFail) => {
      const message = action.error
      const error =
        message.description && message.description.errors && message.description.errors.duplicated
          ? DUPLICATE_ERROR
          : CREATE_ERROR
      if (error) {
        this.matSnackBar.open(error, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )

  /**
   * Load effects
   */
  @Effect()
  public load$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.Load),
    switchMap((action: fromLegalEntityActions.Load) => {
      return this.legalEntityService.get().pipe(
        map(x => new fromLegalEntityActions.LoadSuccess(x, action.payload)),
        catchError(x => of(new fromLegalEntityActions.LoadFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.LoadFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  @Effect()
  public loadItem$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.LoadItem),
    switchMap((action: fromLegalEntityActions.LoadItem) => {
      return this.legalEntityService.getItem(action.payload.legalEntityId).pipe(
        map(x => new fromLegalEntityActions.LoadItemSuccess(x, action.payload)),
        catchError(x => of(new fromLegalEntityActions.LoadItemFail(x, action.payload)))
      )
    })
  )

  /**
   * Update effects
   */
  @Effect()
  public update$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.Update),
    switchMap((action: fromLegalEntityActions.Update) => {
      return this.legalEntityService.update(action.payload.legalEntityId, action.payload.legalEntity).pipe(
        map(x => new fromLegalEntityActions.UpdateSuccess(x, action.payload)),
        catchError(x => of(new fromLegalEntityActions.UpdateFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.UpdateSuccess),
    tap(() => {
      this.matSnackBar.open(UPDATE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(fromLegalEntityActions.LegalEntityActionTypes.UpdateFail),
    tap(() => {
      this.matSnackBar.open(UPDATE_FAIL, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private legalEntityService: LegalEntityService,
    private matSnackBar: MatSnackBar
  ) {}
}
