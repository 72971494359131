import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { Self, Component, OnInit, OnDestroy, Inject } from '@angular/core'
import { ControlValueAccessor, NgControl, FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as R from 'ramda'

@Component({
  selector: 'hot-form-field-autofill',
  template: `
    <mat-form-field
      *ngIf="status === 'shown'; else autofillFieldButton"
      appearance="fill"
      floatLabel="always"
      class="w-100"
    >
      <mat-label>{{ 'FormQuestion.field.autofillFrom' | translate: locale.language }}</mat-label>
      <input matInput [formControl]="form" />
    </mat-form-field>

    <ng-template #autofillFieldButton>
      <button mat-button color="primary" class="template-button pa2 lh-solid f7" (click)="toggle()">
        <mat-icon class="small">add</mat-icon>
        <span>{{ 'FormQuestion.field.autofillFrom' | translate: locale.language }}</span>
      </button>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class FormFieldAutofillComponent implements ControlValueAccessor, OnInit, OnDestroy {
  public form: FormControl
  private subscription: Subscription
  public status: 'hidden' | 'shown' = 'hidden'

  private savedValue = null

  private onChange = (_: any) => {}

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Self() public controlDir: NgControl
  ) {
    controlDir.valueAccessor = this
    this.form = new FormControl('', [])
  }

  ngOnInit() {
    this.subscription = this.form.valueChanges.subscribe(val => this.onChange(val))
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  toggle() {
    this.status = this.status === 'hidden' ? 'shown' : 'hidden'
  }

  reset() {
    this.savedValue = R.clone(this.form.value)
    this.form.setValue('', { emitEvent: false })
    this.onChange(null) // remove `fields` object completely
    this.status = 'hidden'
  }

  undoReset() {
    if (this.savedValue) {
      this.form.patchValue(this.savedValue, { emitEvent: true })
      this.status = 'shown'
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.status = 'shown'
      this.form.setValue(value, { emitEvent: false })
    } else {
      this.reset()
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn
  }

  registerOnTouched(_fn: () => void): void {
    // this.onTouched = fn
  }

  setDisabledState(_isDisabled: boolean): void {
    // when the parent updates the
    // state of the form control
    // this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled)
  }

  // registerOnValidatorChange(fn: () => void): void { }
  // validate(control: AbstractControl) {
  //   return null
  //   // return Validators.required(control)
  // }
}
