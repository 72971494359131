import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core'
import { Cuisine } from '@models'
import { ENTER, COMMA } from '@angular/cdk/keycodes'
import { FormControl, AbstractControl } from '@angular/forms'
import { LanguageType } from '@shared/constants'
import * as R from 'ramda'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import { CuisineService } from '@redux/cuisine/cuisine.service'

@Component({
  selector: 'hot-restaurant-cuisine-autocomplete',
  templateUrl: './restaurant-cuisine-autocomplete.component.html',
})
export class RestaurantCuisineAutocompleteComponent implements OnInit, OnChanges {
  @Input() public language = LanguageType.RU
  @Input() public selectedCuisines: Cuisine[] = []
  @Output() public change = new EventEmitter<Cuisine[]>()
  @ViewChild('selectCuisineInput', { static: true }) public selectCuisineInput: ElementRef

  public Cuisine: FormControl
  public selectable = true
  public removable = true
  public addOnBlur = false
  public separatorKeysCodes = [ENTER, COMMA]
  public filteredCuisines: Cuisine[] = []

  public distinctFilteredCuisines: Cuisine[] = []
  public cuisines: Cuisine[] = []
  public resultCuisines: Cuisine[] = []

  constructor(private cuisineService: CuisineService) {}

  public ngOnChanges(changes): void {
    if (changes.selectedCuisines) {
      this.resultCuisines = this.selectedCuisines ? this.selectedCuisines : []
    }
  }

  ngOnInit() {
    this.resultCuisines = this.selectedCuisines ? this.selectedCuisines : []
    this.Cuisine = new FormControl('', this.cuisinesValidator)
    this.subscribeCuisineChange()
    this.cuisineService.getList().subscribe(res => {
      this.cuisines = R.values(res.cuisines)
      this.filteredCuisines = this.cuisines || []
      this.initializeDistinctFilteredCuisines()
    })
  }

  public getCuisineTitle(cuisine: Cuisine): string {
    return cuisine && cuisine.title ? cuisine.title[this.language] || cuisine.title.default : null
  }

  public getCuisineCode(cuisine: Cuisine): string {
    return cuisine && cuisine.code
  }

  public remove(cuisine: Cuisine): void {
    const index = R.findIndex(c => c._id === cuisine._id, this.resultCuisines)
    if (index !== -1) {
      this.resultCuisines.splice(index, 1)
      this.initializeDistinctFilteredCuisines()
      this.change.next(this.resultCuisines)
    }
  }

  private get cuisine(): string {
    return this.Cuisine && this.Cuisine.value
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    const cuisine = R.find(
      c => this.getCuisineTitle(c) === event.option.value,
      this.distinctFilteredCuisines
    )
    const existingCuisine = R.find(
      c => this.getCuisineTitle(c) === event.option.value,
      this.resultCuisines
    )
    if (cuisine && !existingCuisine) {
      this.resultCuisines.push(cuisine)
      this.initializeDistinctFilteredCuisines()
      this.change.next(this.resultCuisines)
    }
    this.updateCuisineControlValue('')
    this.selectCuisineInput.nativeElement.value = ''
    this.selectCuisineInput.nativeElement.blur()
  }

  public add(event: MatChipInputEvent): void {
    const value = (this.cuisine ? this.cuisine.trim() : '') as string
    const cuisine = this.findCuisineByTitle(value)
    const existingCuisine = R.find(c => this.getCuisineTitle(c) === value, this.resultCuisines)
    if (cuisine && !existingCuisine) {
      this.resultCuisines.push(cuisine)
      this.initializeDistinctFilteredCuisines()
      this.change.next(this.resultCuisines)
    }
    if (event.input) {
      event.input.value = ''
    }
    this.updateCuisineControlValue('')
  }

  private updateCuisineControlValue(value: string): void {
    this.Cuisine.patchValue(value)
  }

  private initializeDistinctFilteredCuisines(): void {
    this.distinctFilteredCuisines =
      this.filteredCuisines && this.resultCuisines
        ? R.differenceWith(
            (a: Cuisine, b: Cuisine) => a && b && a._id === b._id,
            this.filteredCuisines,
            this.resultCuisines
          )
        : []
  }

  private findCuisineByTitle(title: string): Cuisine {
    return R.find(c => this.getCuisineTitle(c) === title, this.cuisines)
  }

  private subscribeCuisineChange(): void {
    this.Cuisine.valueChanges.subscribe(value => {
      const cuisine = this.findCuisineByTitle(value)
      if (!cuisine) {
        this.filteredCuisines = R.filter(c => {
          const title = this.getCuisineTitle(c)
          return title && R.toLower(title).includes(R.toLower(value))
        }, this.cuisines)
        this.initializeDistinctFilteredCuisines()
      }
    })
  }

  private cuisinesValidator = (_: AbstractControl) => {
    return this.resultCuisines && this.resultCuisines.length ? null : { empty: true }
  }
}
