<div class="scroll-wrapper flex flex-column w-100 justify-between-l">
  <div class="header-section mb3 mb0-l">
    <div>
      <div class="logo-wrapper ph2 flex justify-center">
        <a routerLink="/properties" class="db color-inherit">
          <mat-icon svgIcon="hot:logo" class="hotbot-small-logo"></mat-icon>
        </a>
      </div>
      <ng-container *ngIf="currentProperty && currentProperty?.config?.botEnabled">
        <a [routerLink]="['/properties', currentProperty._id]">
          <hot-property-logo class="flex mt3" [property]="currentProperty"></hot-property-logo>
        </a>
      </ng-container>
    </div>

    <ng-container *ngIf="currentProperty">
      <ul class="links flex flex-column ma0 pt3 pb2">
        <ng-container *ngIf="currentProperty?.config?.botEnabled">
          <li *ngIf="(showPropertyStats$ | async)">
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: true,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'stats']"
              [matTooltip]="'Stats.title' | translate: locale.language"
              matTooltipPosition="right"
            >
              <mat-icon class="nav-button-icon" svgIcon="hot:stats"></mat-icon>
            </a>
          </li>

          <li>
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'requests']"
              [matTooltip]="'Requests.title' | translate: locale.language"
              matTooltipPosition="right"
            >
              <span *ngIf="(openedRequests$ | async)" class="count-label">{{
                openedRequests$ | async
              }}</span>
              <mat-icon class="nav-button-icon" svgIcon="hot:inbox"></mat-icon>
            </a>
          </li>

          <li>
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'reviews']"
              [matTooltip]="'Reviews.title' | translate: locale.language"
              [matTooltipDisabled]="hasTouch"
              matTooltipPosition="right"
            >
              <span *ngIf="(totalReviews$ | async)" class="count-label">{{
                totalReviews$ | async
              }}</span>
              <mat-icon class="nav-button-icon" svgIcon="hot:reviews"></mat-icon>
            </a>
          </li>

          <li *ngIf="currentProperty.config.ordersEnabled">
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'orders']"
              [matTooltip]="'Carts.title' | translate: locale.language"
              [matTooltipDisabled]="hasTouch"
              matTooltipPosition="right"
            >
              <span *ngIf="(openedCarts$ | async) as openedCarts" class="count-label">{{
                openedCarts
              }}</span>
              <mat-icon class="nav-button-icon" svgIcon="hot:orders"></mat-icon>
            </a>
          </li>

          <li *ngIf="currentProperty.config.shopServiceEnabled">
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'shop-orders']"
              [matTooltip]="'Carts.title' | translate: locale.language"
              [matTooltipDisabled]="hasTouch"
              matTooltipPosition="right"
            >
              <span *ngIf="(openedShopCarts$ | async) as openedShopCarts" class="count-label">{{
                openedShopCarts
              }}</span>
              <mat-icon class="nav-button-icon" svgIcon="hot:orders"></mat-icon>
            </a>
          </li>

          <li *ngIf="(showPropertyMarketing$ | async)">
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'pushes']"
              [matTooltip]="'Marketing.title' | translate: locale.language"
              [matTooltipDisabled]="hasTouch"
              matTooltipPosition="right"
            >
              <mat-icon class="nav-button-icon" svgIcon="hot:marketing"></mat-icon>
            </a>
          </li>

          <li *ngIf="currentProperty.config.pollsEnabled">
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'polls']"
              [matTooltipDisabled]="hasTouch"
              matTooltip="Голосования"
              matTooltipPosition="right"
            >
              <mat-icon class="nav-button-icon" svgIcon="hot:polls"></mat-icon>
            </a>
          </li>

          <li *ngIf="(showPropertyChats$ | async)">
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'chats']"
              [matTooltip]="'Chats.title' | translate: locale.language"
              [matTooltipDisabled]="hasTouch"
              matTooltipPosition="right"
            >
              <span *ngIf="inboxChatsCounter$ | async as inboxChatsCounter" class="count-label">{{
                inboxChatsCounter
              }}</span>
              <mat-icon class="nav-button-icon" svgIcon="hot:messages"></mat-icon>
            </a>
          </li>
        </ng-container>

        <ng-container *ngIf="currentProperty?.config?.wifiEnabled && (showWifi$ | async)">
          <li>
            <a
              class="nav-button"
              routerLinkActive="nav-button--active"
              [routerLinkActiveOptions]="{
                exact: false,
                __change_detection_hack__: currentProperty._id
              }"
              [routerLink]="['properties', currentProperty._id, 'settings', 'wifi']"
              [matTooltip]="'Wifi.title' | translate: locale.language"
              [matTooltipDisabled]="hasTouch"
              matTooltipPosition="right"
            >
              <mat-icon class="nav-button-icon" svgIcon="hot:wifi"></mat-icon>
            </a>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
  <div class="header-section">
    <ul class="links flex flex-column ma0">
      <ng-container *ngIf="currentProperty && (showPropertySettings$ | async)">
        <li>
          <a
            class="nav-button"
            routerLinkActive="nav-button--active"
            [routerLinkActiveOptions]="{
              exact: true,
              __change_detection_hack__: currentProperty._id
            }"
            [routerLink]="['properties', currentProperty._id, 'settings']"
            [matTooltip]="'Settings.title' | translate: locale.language"
            matTooltipPosition="right"
          >
            <mat-icon class="nav-button-icon" svgIcon="hot:settings"></mat-icon>
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="user">
        <li>
          <button
            type="button"
            class="nav-button mb2"
            [matTooltip]="user.email"
            [matMenuTriggerFor]="userMenu"
            matTooltipPosition="right"
          >
            <mat-icon class="nav-button-icon" svgIcon="hot:user"></mat-icon>
          </button>
          <mat-menu #userMenu="matMenu" xPosition="after" yPosition="above" overlapTrigger="false">
            <ng-template matMenuContent>
              <button mat-menu-item (click)="logout()">
                <span>{{ 'User.logout' | translate: locale.language }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </li>
      </ng-container>

      <ng-container *ngIf="(showAdminSection$ | async)">
        <li>
          <a
            class="nav-button mb2"
            routerLinkActive="nav-button--active"
            [routerLink]="['admin']"
            matTooltip="Admin panel"
            matTooltipPosition="right"
          >
            <mat-icon class="nav-button-icon" svgIcon="hot:admin"></mat-icon>
          </a>
        </li>
      </ng-container>

      <ng-container *ngIf="helpIsVisible">
        <li>
          <button mat-icon-button type="button" class="nav-button" (click)="showHelp()">
            <mat-icon class="nav-button-icon" svgIcon="hot:information"></mat-icon>
          </button>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
