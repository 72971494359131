import { L10nLoader, L10nTranslationModule, L10nIntlModule } from 'angular-l10n'
import { SortablejsModule } from 'ngx-sortablejs'

import { registerLocaleData } from '@angular/common'
import localeRuExtra from '@angular/common/locales/extra/ru'
import localeRu from '@angular/common/locales/ru'
import localeEsExtra from '@angular/common/locales/extra/es'
import localeEs from '@angular/common/locales/es'
import { APP_INITIALIZER, ApplicationRef, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations'
import { createInputTransfer, removeNgStyles } from '@angularclass/hmr'

import { EffectsModule } from '@ngrx/effects'
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
  DefaultRouterStateSerializer,
} from '@ngrx/router-store'
import { Store } from '@ngrx/store'

import { take } from 'rxjs/operators'

import { STORE_EFFECTS } from '@redux/effects'
import { RouterStateUrlSerializer } from '@redux/router/router.serializer'
import { SERVICES } from '@redux/services'
import { SHARED_GUARDS } from '@shared/guards'
import { SharedModule } from '@shared/shared.module'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppStoreModule } from './app-store.module'
import { AppContainer } from './app.container'
import { l10nConfig, initL10n, CustomTranslationHandler, CustomTranslationLoader } from './app.i18n'
import { AuthContainer } from './auth/auth.container'
import { ResetPasswordContainer } from './auth/reset/reset.container'
import { RecoverPasswordContainer } from './auth/recover/recover.container'
import { HomePageComponent } from './home/home-page/home-page.component'
import { NoContentComponent } from './no-content/no-content.component'
import { ApiService, ConfigService, WebChatWidgetService } from '@shared/services'
import { Config } from '@models'

registerLocaleData(localeRu, 'ru', localeRuExtra)
registerLocaleData(localeEs, 'es', localeEsExtra)

const AppAnimationModule = environment.noAnimations ? NoopAnimationsModule : BrowserAnimationsModule

export function initConfig(apiService: ApiService, configService: ConfigService): Function {
  return () => {
    return new Promise(resolve => {
      return apiService.get('config').subscribe((val: Config) => {
        configService.languages = val.languages
        configService.countries = val.countries
        resolve(true)
      })
    })
  }
}

@NgModule({
  declarations: [
    AppContainer,
    AuthContainer,
    ResetPasswordContainer,
    RecoverPasswordContainer,
    HomePageComponent,
    NoContentComponent,
  ],
  imports: [
    BrowserModule,
    AppAnimationModule,
    AppRoutingModule,

    SharedModule,
    L10nTranslationModule.forRoot(l10nConfig, {
      translationLoader: CustomTranslationLoader,
      translationHandler: CustomTranslationHandler,
    }),
    L10nIntlModule,

    SortablejsModule.forRoot({ animation: 150 }),
    // NgRx
    AppStoreModule,
    EffectsModule.forRoot([...STORE_EFFECTS]),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      stateKey: 'router', // name of reducer key
    }),
  ],
  providers: [
    ConfigService,
    WebChatWidgetService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ApiService, ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true,
    },
    ...SERVICES,
    // { provide: MAT_DATE_LOCALE, useValue: 'ru' },
    // { provide: LOCALE_ID, useValue: 'ru' },
    // { provide: MAT_DATE_FORMATS, useValue:  },
    { provide: RouterStateSerializer, useClass: RouterStateUrlSerializer },
    ...SHARED_GUARDS,
  ],
  bootstrap: [AppContainer],
  entryComponents: [],
})
export class AppModule {
  constructor(public appRef: ApplicationRef, private _store: Store<any>) {}

  createNewHosts(cmps) {
    const components = Array.prototype.map.call(cmps, function(componentNode) {
      const newNode = document.createElement(componentNode.tagName)
      const currentDisplay = newNode.style.display
      newNode.style.display = 'none'
      if (!!componentNode.parentNode) {
        const parentNode = componentNode.parentNode
        parentNode.insertBefore(newNode, componentNode)
        return function removeOldHost() {
          newNode.style.display = currentDisplay
          try {
            parentNode.removeChild(componentNode)
          } catch (e) {}
        }
      } else {
        return function() {} // make it callable
      }
    })
    return function removeOldHosts() {
      components.forEach(function(removeOldHost) {
        return removeOldHost()
      })
    }
  }

  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement)
    this._store.pipe(take(1)).subscribe(s => (store.rootState = s))
    store.disposeOldHosts = this.createNewHosts(cmpLocation)
    store.restoreInputValues = createInputTransfer()
    removeNgStyles()
  }

  hmrAfterDestroy(store) {
    store.disposeOldHosts()
    delete store.disposeOldHosts
  }

  hmrOnInit(store) {
    if (!store || !store.rootState) {
      return
    }
    // restore state by dispatch a SET_ROOT_STATE action
    if (store.rootState) {
      this._store.dispatch({
        type: 'SET_ROOT_STATE',
        payload: store.rootState,
      })
    }
    if ('restoreInputValues' in store) {
      store.restoreInputValues()
    }
    // this.appRef.tick()
    Object.keys(store).forEach(prop => delete store[prop])
  }
}
