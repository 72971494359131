import { Action } from '@ngrx/store'
import { NormalizedEntities } from '@models/api-normalized-responses'

export enum EntityActionTypes {
  Update = '[Entity] Update',
}

export class Updated implements Action {
  readonly type = EntityActionTypes.Update
  constructor(public payload: NormalizedEntities) {}
}
