import { clone, path, remove, keys } from 'ramda'
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'

import {
  MenuContentType,
  MenuButtonType,
  MenuButton,
  getButtonTitleForLang,
  Builder,
  Form,
  Poll,
  ShopProductCategory,
  ShopProduct,
} from '@models'
import { LanguageType } from '@shared/constants'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

// tslint:disable-next-line:max-line-length
import { BuilderButtonEditDialogComponent } from '@shared/components/builder-buttons/builder-button-edit-dialog/builder-button-edit-dialog.component'

@Component({
  selector: 'hot-create-button',
  template: `
    <ng-container *ngFor="let button of editableButtons; let i = index; let isLast = last">
      <div class="flex w-100 items-center" [class.mb3]="!isLast">
        <button
          e2e-edit-button
          color="primary"
          class="mr3 w-100"
          mat-stroked-button
          (click)="edit(i)"
        >
          <div class="w-100 flex justify-between items-center pv1">
            <span>{{ caption(i) || '&mdash;' }}</span>
            <mat-icon class="hot-manage-button-icon mr1">edit</mat-icon>
          </div>
        </button>
        <mat-icon
          e2e-delete-button
          class="black-50 hover-light-red hot-manage-button-icon"
          (click)="remove(i)"
          >delete</mat-icon
        >
      </div>
      <a
        *ngIf="buttonUrl(i)"
        [href]="buttonUrl(i)"
        target="_blank"
        class="f7 dib v-mid lh-title pv1 mt1 blue underline-hover"
      >
        <mat-icon class="v-mid o-70 mr1">link</mat-icon>
        <span class="dib v-mid truncate mw5" style="text-decoration: inherit">{{
          buttonUrl(i)
        }}</span>
      </a>
    </ng-container>
    <button
      *ngIf="showAddButton"
      e2e-add-button
      class="mr3 w-100 pv1"
      mat-stroked-button
      (click)="create()"
      [class.mt3]="editableButtons.length"
      [class.disabled-button]="editableButtons.length >= buttonsLimit"
    >
      {{ 'components.ButtonFormDialog.action.add' | translate: locale.language }}
    </button>
  `,
  styles: [
    `
      .hot-manage-button-icon {
        cursor: pointer;
      }
      .disabled-button,
      .disabled-button:hover,
      .disabled-button:focus,
      .disabled-button:active {
        background: none;
        opacity: 0.5;
        cursor: not-allowed;
      }
      .disabled-button ::ng-deep .mat-button-focus-overlay {
        background: transparent;
      }
    `,
  ],
})
export class CreateButtonComponent implements OnInit {
  @Input() public language = LanguageType.EN
  @Input() public builders: Builder[] = []
  @Input() public forms: Form[] = []
  @Input() public shopCategories: ShopProductCategory[] = []
  @Input() public shopProducts: ShopProduct[] = []
  @Input() public modules: string[] = []
  @Input() public polls: Poll[] = []
  @Input() public buttonsLimit = 3
  @Input() public showAddButton = true
  @Input() public set buttons(value: MenuButton[]) {
    this.editableButtons = clone(value)
  }
  @Output() update = new EventEmitter<MenuButton[]>()

  public editableButtons: MenuButton[] = []
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private dialog: MatDialog) {}

  ngOnInit() {}

  public create(): void {
    this.subscribeButtonChange()
  }

  public remove(index: number): void {
    this.editableButtons = remove(index, 1, this.editableButtons)
    this.update.next(this.editableButtons)
  }

  public edit(index: number): void {
    this.subscribeButtonChange(index)
  }

  public buttonUrl(index: number): string {
    const button = this.editableButtons.find((b, i) => b && i === index)
    const buttonType = path(['translations', 'en', 'type'], button) as MenuButtonType
    return buttonType && buttonType === MenuButtonType.URL
      ? button.translations.en.config.url
      : null
  }

  public caption(index: number): string {
    return getButtonTitleForLang(
      this.editableButtons.find((b, i) => b && i === index),
      this.language
    )
  }

  private subscribeButtonChange(index?: number): void {
    this.openCreateDialogNew(index)
      .afterClosed()
      .subscribe(data => {
        const button = data && data[0]
        if (button) {
          if (typeof index === 'number') {
            this.editableButtons[index] = button
          } else {
            this.editableButtons.push(button)
          }
          this.update.next(this.editableButtons)
        }
      })
  }

  private openCreateDialogNew(index): MatDialogRef<BuilderButtonEditDialogComponent> {
    // HACK: Ensure button is defined before passing it to BuilderButtonEditDialogComponent
    // TODO: Refactor BuilderButtonsComponent, then remove this
    const button = clone(this.editableButtons.find((b, i) => b && i === index)) || {
      type: MenuContentType.BUTTON,
      translations: {},
    }

    // HACK: Ensure button has currently editing language before passing it to BuilderButtonEditDialogComponent
    // TODO: Refactor BuilderButtonsComponent, then remove this
    if (!button.translations[this.language]) {
      button.translations[this.language] = clone(
        button.translations['en'] ||
          button.translations[keys(button.translations)[0]] || { type: null, title: '', config: {} }
      )
    }

    return this.dialog.open(BuilderButtonEditDialogComponent, {
      panelClass: 'hot-builder-buttons',
      width: '800px',
      maxWidth: '',
      data: {
        currentLanguage: this.language,
        addPageEnabled: false,
        cache: button,
        forms: this.forms,
        shopCategories: this.shopCategories,
        shopProducts: this.shopProducts,
        polls: this.polls,
        builders: this.builders,
        templates: [],
        availableModules: this.modules,
        availableLanguages: [], // use only default key
        buttons: [button],
        buttonIndex: 0,
        buttonAdding: true,
      },
    })
  }
}
