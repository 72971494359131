import {
  L10N_LOCALE,
  L10nLocale,
  L10nConfig,
  L10nTranslationLoader,
  L10nTranslationHandler,
  L10nSchema,
  L10nLoader,
} from 'angular-l10n'
import { Injectable, Inject } from '@angular/core'
import { Observable, from } from 'rxjs'
import memoizeFormatConstructor from 'intl-format-cache'
import IntlMessageFormat from 'intl-messageformat'

export interface LanguageSelectorItem {
  label: string
  /**
   * ISO 639 two-letter or three-letter code.
   */
  language: string
  /**
   * ISO 3166 two-letter, uppercase code.
   */
  country: string
  currency: string
  numberingSystem: 'latn'
  direction: 'ltr' | 'rtl'
}

/**
 * Including pluralization rules for specific languages
 * @example
 *
 * const text = `{count, plural,
 *   =0 {Нет новых заявок}
 *   one {# новая заявка}
 *   few {# новых заявки}
 *   other {# новых заявок}}`
 * const mf = new IntlMessageFormat(messages.ru, 'ru-RU')
 * const res = mf.format({ count: 2 })
 *
 * // Before including locale-data:
 * res === '2 новых заявок'
 *
 * // After including locale-data:
 * res === '2 новых заявки'
 */

window.IntlMessageFormat = IntlMessageFormat
require('intl-messageformat/dist/locale-data/ru')

const MemoizedIntlMessageFormat: typeof IntlMessageFormat = memoizeFormatConstructor(
  IntlMessageFormat
) as any

export const messageFormat = (
  language: string,
  message: string,
  locals: { [key: string]: any }
): string => {
  const mf = new MemoizedIntlMessageFormat(message, language)
  const text = mf.format(locals)
  return text
}

// Codes - https://en.wikipedia.org/wiki/ISO_3166-1
// CIS Members - https://en.wikipedia.org/wiki/Commonwealth_of_Independent_States#Member_states
export const CIS_CODES = ['AM', 'AZ', 'BY', 'KZ', 'KG', 'MD', 'RU', 'TJ', 'UZ', 'TM', 'UA']

export const languageList: LanguageSelectorItem[] = [
  {
    label: 'English',
    language: 'en',
    country: 'US',
    currency: 'USD',
    numberingSystem: 'latn',
    direction: 'ltr',
  },
  {
    label: 'Spanish',
    language: 'es',
    country: 'US',
    currency: 'USD',
    numberingSystem: 'latn',
    direction: 'ltr',
  },
  {
    label: 'Русский',
    language: 'ru',
    country: 'RU',
    currency: 'RUB',
    numberingSystem: 'latn',
    direction: 'ltr',
  },
  // {
  //   label: 'Latviešu valoda',
  //   language: 'lv',
  //   country: 'LV',
  //   currency: 'EUR',
  //   numberingSystem: 'latn',
  //   direction: 'ltr',
  // },
]
export const getFallbackLanguage = (_countryCode: string) => {
  return languageList[0]
}

export const getLanguageInfoByLocale = (locale: string) => {
  const selectedLanguageItem = languageList.find(x => x.language === locale)
  return selectedLanguageItem || getFallbackLanguage(locale)
}

export const languageCodes = languageList.map(x => ({
  code: x.language,
  dir: x.direction,
}))

export const l10nConfig: L10nConfig = {
  defaultLocale: languageList[0],
  format: 'language',
  providers: [{ name: 'app', asset: './assets/i18n/locale-' }],
  keySeparator: '.',
  cache: true,
  schema: languageList.map(
    (x): L10nSchema => {
      return {
        locale: { language: x.language },
        dir: x.direction,
        text: x.label,
      }
    }
  ),
  // providers: [{ type: ProviderType.Static, prefix: './assets/i18n/locale-' }],
  // defaultLocale: {
  //       language: 'en',
  //       countryCode: 'US',
  //       numberingSystem: 'latn',
  //     },

  // locale: {
  //   languages: languageCodes,
  //   defaultLocale: {
  //     languageCode: 'en',
  //     countryCode: 'US',
  //     numberingSystem: 'latn',
  //   },
  //   currency: 'USD',
  //   storage: StorageStrategy.Local,
  // },
  // translation: {
  //   providers: [{ type: ProviderType.Static, prefix: './assets/i18n/locale-' }],
  //   composedKeySeparator: '.',
  //   caching: true,
  //   i18nPlural: false,
  // },
}

@Injectable({ providedIn: 'root' })
export class CustomTranslationLoader implements L10nTranslationLoader {
  /**
   * This method must contain the logic of data access.
   * @param language The current language
   * @param args The object set during the configuration of 'providers'
   * @return An observable of an object of translation data: {key: value}
   */
  public get(language: string, _args?: any): Observable<{ [key: string]: any }> {
    return from(
      import(/* webpackChunkName: "[request]", webpackInclude: /\.ts$/, webpackMode: "lazy" */ `../i18n/locale-${language}`).then(
        x => x.default || x
      )
    )
  }
}

@Injectable()
export class CustomTranslationHandler implements L10nTranslationHandler {
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  /**
   * This method must contain the logic to parse the translated value.
   * @param key The key that has been requested
   * @param params The parameters passed along with the key
   * @param value The translated value
   * @return The parsed value
   */
  public parseValue(_key: string, params: any, value: string | null): string {
    if (params === null || params === undefined) {
      return value
    }
    return messageFormat(this.locale.language, value, params)
  }
}

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return () => l10nLoader.init()
}
