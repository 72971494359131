import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Inject,
} from '@angular/core'
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { FormFieldConfig } from '@models'
import { selectLanguage } from '../../pipes/index'
import { FormTranslations } from '../../../settings/forms/forms.translations'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'hot-form-field-comment',
  templateUrl: './form-field-comment.component.html',
  styleUrls: ['./form-field-comment.component.css'],
})
export class FormFieldCommentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public defaultLanguage
  @Input() public language
  @Input() public config: FormControl
  @Output() public changed = new EventEmitter()

  public form: FormGroup
  private destroyed = new Subject()

  public defaults = FormTranslations.fieldTypes.comment

  public get labelEnterTextPlaceholder() {
    return selectLanguage(this.defaults.labelEnterText, this.language)
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    this.form = this.fb.group({
      labelEnterText: this.fb.control([]),
    })
  }

  prepareConfig(data) {
    return {
      labelEnterText: data.labelEnterText || [],
    }
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
      this.changed.next({
        ...this.config.value,
        ...this.prepareConfig(val),
      })
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      const config = this.config.value as FormFieldConfig
      this.form.patchValue(this.prepareConfig(config))
    }
  }
}
