import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import * as fromFAQActions from './faq.actions'
import { FaqService } from './faq.service'
import { I18nService } from '@shared/services'

@Injectable()
export class FaqEffects {
  /**
   * Create FAQs effects
   */
  @Effect()
  public create$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.Create),
    switchMap((action: fromFAQActions.Create) => {
      return this.faqService.create(action.payload.propertyId, action.meta).pipe(
        map(x => new fromFAQActions.CreateSuccess(x, action.payload)),
        catchError(x => of(new fromFAQActions.CreateFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.CreateSuccess),
    tap(_ => {
      const createCategorySuccess = this.translationService.translate(
        'Faq.notification.createCategorySuccess'
      )
      this.matSnackBar.open(createCategorySuccess, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  /**
   * Load FAQs effects
   */
  @Effect()
  public load$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.Load),
    switchMap((action: fromFAQActions.Load) => {
      return this.faqService.get(action.payload.propertyId).pipe(
        map(x => new fromFAQActions.LoadSuccess(x, action.payload)),
        catchError(x => of(new fromFAQActions.LoadFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.LoadFail),
    tap(() => {
      const loadFAQError = this.translationService.translate('Faq.error.loadFAQError')
      this.matSnackBar.open(loadFAQError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Update FAQs effects
   */
  @Effect()
  public update$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.Update),
    switchMap((action: fromFAQActions.Update) => {
      return this.faqService
        .patch(
          action.payload.propertyId,
          action.payload.faq,
          action.payload.content,
          action.payload.sourceLanguage,
          action.payload.options
        )
        .pipe(
          map(x => new fromFAQActions.UpdateSuccess(x, action.payload)),
          catchError(x => of(new fromFAQActions.UpdateFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.UpdateSuccess),
    tap(_ => {
      const updateSuccess = this.translationService.translate('Faq.notification.updateSuccess')
      this.matSnackBar.open(updateSuccess, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.UpdateFail),
    tap(() => {
      const updateFAQError = this.translationService.translate('Faq.error.updateFAQError')
      this.matSnackBar.open(updateFAQError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Delete FAQs effects
   */
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.Delete),
    switchMap((action: fromFAQActions.Delete) => {
      return this.faqService.delete(action.payload.propertyId, action.payload.faqId).pipe(
        map(x => new fromFAQActions.DeleteSuccess(x, action.payload)),
        catchError(x => of(new fromFAQActions.DeleteFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.DeleteSuccess),
    tap(_ => {
      const deleteSuccess = this.translationService.translate('Faq.notification.deleteSuccess')
      this.matSnackBar.open(deleteSuccess, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.DeleteFail),
    tap(() => {
      const deleteFAQError = this.translationService.translate('Faq.error.deleteFAQError')
      this.matSnackBar.open(deleteFAQError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Create FAQ categories effects
   */

  @Effect()
  public createCategory$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.CreateCategories),
    switchMap((action: fromFAQActions.CreateCategories) => {
      return this.faqService
        .createCategory(action.payload as fromFAQActions.CreateCategoriesPayload)
        .pipe(
          map(x => new fromFAQActions.CreateCategoriesSuccess(x, action.payload)),
          catchError(x => of(new fromFAQActions.CreateCategoriesFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public createCategorySuccess$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.CreateCategoriesSuccess),
    tap(() => {
      const createCategorySuccess = this.translationService.translate(
        'Faq.notification.createCategorySuccess'
      )
      this.matSnackBar.open(createCategorySuccess, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createCategoryFail$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.CreateCategoriesFail),
    tap(() => {
      const loadFAQError = this.translationService.translate('Faq.error.loadFAQError')
      this.matSnackBar.open(loadFAQError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load FAQ categories effects
   */
  @Effect()
  public loadCategories$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.LoadCategories),
    switchMap((action: fromFAQActions.LoadCategories) => {
      const options = {
        limit: action.payload.limit,
        page: action.payload.page,
      }
      return this.faqService.getCategories(options).pipe(
        map(x => new fromFAQActions.LoadCategoriesSuccess(x, action.payload)),
        catchError(x => of(new fromFAQActions.LoadCategoriesFail(x)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadCategoriesFail$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.LoadCategoriesFail),
    tap(() => {
      const loadFAQError = this.translationService.translate('Faq.error.loadFAQError')
      this.matSnackBar.open(loadFAQError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Update FAQ category effects
   */

  @Effect()
  public updateCategory$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.UpdateCategories),
    switchMap((action: fromFAQActions.UpdateCategories) => {
      return this.faqService
        .updateCategory(action.payload as fromFAQActions.CreateCategoriesPayload)
        .pipe(
          map(x => new fromFAQActions.UpdateCategoriesSuccess(x, action.payload)),
          catchError(x => of(new fromFAQActions.UpdateCategoriesFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateCategorySuccess$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.UpdateCategoriesSuccess),
    tap(() => {
      const updateCategorySuccess = this.translationService.translate(
        'Faq.notification.updateCategorySuccess'
      )
      this.matSnackBar.open(updateCategorySuccess, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateCategoryFail$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.UpdateCategoriesFail),
    tap(() => {
      const updateFAQError = this.translationService.translate('Faq.error.updateFAQError')
      this.matSnackBar.open(updateFAQError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Delete FAQ category effects
   */

  @Effect()
  public deleteCategory$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.DeleteCategories),
    switchMap((action: fromFAQActions.DeleteCategories) => {
      return this.faqService.deleteCategory(action.payload).pipe(
        map(x => new fromFAQActions.DeleteCategoriesSuccess(x, action.payload)),
        catchError(x => of(new fromFAQActions.DeleteCategoriesFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public deleteCategorySuccess$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.DeleteCategoriesSuccess),
    tap(() => {
      const deleteCategorySuccess = this.translationService.translate(
        'Faq.notification.deleteCategorySuccess'
      )
      this.matSnackBar.open(deleteCategorySuccess, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteCategoryFail$ = this.actions$.pipe(
    ofType(fromFAQActions.FAQActionTypes.DeleteCategoriesFail),
    tap(() => {
      const deleteFAQError = this.translationService.translate('Faq.error.deleteFAQError')
      this.matSnackBar.open(deleteFAQError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private faqService: FaqService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
