import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

import { Store } from '@ngrx/store'

import { Observable, of } from 'rxjs'

import * as fromRoot from '@redux'
import * as fromShopCart from '@redux/shop-cart'

@Injectable()
export class SelectShopCartGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const id = route.params['orderId']
    this.store$.dispatch(new fromShopCart.Select({ shopCartId: id }))

    return of(true)
  }
}
