import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

const QRCodeURL = 'https://api.qrserver.com/v1/create-qr-code/?data=data&size=400x400&margin=10'

export interface QRCodeDialogConfig {
  channel?: {
    username: string
    type: 'telegram' | 'viber' | 'facebook'
  }
  token?: string
  propertyId?: string
}

@Component({
  selector: 'hot-qrcode-dialog',
  template: `
    <div class="hot-qrcode-container">
      <mat-spinner class="hot-qrcode-spinner" [diameter]="20" color="primary"></mat-spinner>
      <img *ngIf="url" [src]="url" />
    </div>
    <p *ngIf="!isViber()" class="tc o-70">
      {{ 'Requests.messengerNotificationsHint.scan' | translate: locale.language }}
    </p>
    <p
      *ngIf="isViber()"
      class="tc o-70"
      [innerHTML]="'Requests.messengerNotificationsHint.scanViber' | translate: locale.language"
    ></p>
    <div class="tc mt3">
      <a mat-button [href]="buildUrl(true)" target="_blank">
        {{
          'Requests.messengerNotificationsHint.open'
            | translate: locale.language:{ channel: channelLabel }
        }}
      </a>
    </div>
  `,
  styleUrls: ['./qrcode-dialog.component.scss'],
})
export class QRCodeDialogComponent implements OnInit {
  public url: string = null

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public dialogRef: MatDialogRef<QRCodeDialogComponent>,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: QRCodeDialogConfig
  ) {}

  public ngOnInit(): void {
    const payload = this.buildUrl() as string
    const url = new URL(QRCodeURL)
    url.searchParams.set('data', payload)
    this.url = url.toString()
  }

  public buildUrl(safe = false): SafeUrl | string {
    let payload = ''
    const { channel, token, propertyId } = this.data
    const ref = `manager_${token}_${propertyId}`
    switch (channel.type) {
      case 'telegram': {
        payload = `tg://resolve?domain=${channel.username}&start=${ref}`
        break
      }
      case 'viber': {
        payload = `viber://pa?chatURI=${channel.username}&context=${ref}`
        break
      }
      case 'facebook': {
        payload = `https://m.me/${channel.username}?ref=${ref}`
        break
      }
    }
    return safe ? this.sanitizer.bypassSecurityTrustUrl(payload) : payload
  }

  public get channelLabel() {
    const { type } = this.data.channel
    return type[0].toUpperCase() + type.slice(1).toLowerCase()
  }

  public isViber(): boolean {
    return Boolean(this.data.channel && this.data.channel.type === 'viber')
  }

  public onCancel(): void {
    this.dialogRef.close(null)
  }
}
