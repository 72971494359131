import * as R from 'ramda'

import { Actions, ActionTypes } from './wifi.actions'

// States

export interface CaptivePortalSubstate {
  loading: boolean
  loaded: boolean
  ids: string[]
}

export interface State {
  captivePortalsByPropertyId: {
    [key: string]: CaptivePortalSubstate
  }
}

// Initial states

const initialCaptivePortalState: CaptivePortalSubstate = {
  loading: false,
  loaded: false,
  ids: [],
}

const initialState: State = {
  captivePortalsByPropertyId: {},
}

// Reducers

export function captivePortalReducer(
  state = initialCaptivePortalState,
  action: Actions
): CaptivePortalSubstate {
  switch (action.type) {
    case ActionTypes.LoadCaptivePortals: {
      return {
        ...state,
        loading: true,
      }
    }

    case ActionTypes.LoadCaptivePortalsSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        ids: action.payload.results,
      }
    }
  }
}

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LoadCaptivePortals:
    case ActionTypes.LoadCaptivePortalsSuccess:
    case ActionTypes.LoadCaptivePortalsFail: {
      const query = action.type === ActionTypes.LoadCaptivePortals ? action.payload : action.meta
      return R.over(
        R.lensPath(['captivePortalsByPropertyId', query.propertyId]),
        x => captivePortalReducer(x, action),
        state
      )
    }

    default: {
      return state
    }
  }
}

export const selectCaptivePortals = (state: State) => state.captivePortalsByPropertyId
export const selectCaptivePortalsIds = (substate: CaptivePortalSubstate) => substate && substate.ids
export const selectCaptivePortalsLoading = (substate: CaptivePortalSubstate) =>
  substate && substate.loading
export const selectCaptivePortalsLoaded = (substate: CaptivePortalSubstate) =>
  substate && substate.loaded
