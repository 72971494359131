<div class="form" [formGroup]="form">
  <mat-checkbox formControlName="acceptAnyText" class="db">
    {{ 'FormQuestion.text.acceptAnyText' | translate: locale.language }}
  </mat-checkbox>

  <ng-container *ngIf="!form.get('acceptAnyText').value" class="db">
    <mat-checkbox formControlName="required">
      {{ 'forms.label.requiredField' | translate: locale.language }}
    </mat-checkbox>

    <mat-checkbox formControlName="autofill" class="db">
      {{ 'FormQuestion.text.autofill' | translate: locale.language }}
    </mat-checkbox>

    <hot-form-field-autofill class="mt3" formControlName="autofillFrom"></hot-form-field-autofill>

    <div class="mt3">
      <mat-checkbox formControlName="validateEntities">
        Validate entities
      </mat-checkbox>
      <mat-form-field
        *ngIf="form.get('validateEntities').value"
        appearance="fill"
        floatLabel="always"
        class="mt2 w-100"
      >
        <mat-label>Entities</mat-label>
        <input matInput formControlName="entities" />
      </mat-form-field>
    </div>
  </ng-container>
</div>
