<div class="w-100">
  <mat-form-field class="w-100">
      <mat-chip-list #cuisinesList>
        <ng-container *ngFor="let cuisine of resultCuisines">
          <mat-chip
            [selectable]="selectable"
            [removable]="removable"
            [disabled]="isEditMode"
            (removed)="remove(cuisine)">
            <span class="hot-chat-chip-label">{{ getCuisineTitle(cuisine) || '&mdash;' }}</span>
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </ng-container>       
        <input
          placeholder="Кухня"
          #selectCuisineInput
          [formControl]="Cuisine"
          [matAutocomplete]="auto"
          [matChipInputFor]="cuisinesList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
        />        
    </mat-chip-list>    
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let cuisine of distinctFilteredCuisines" [value]="getCuisineTitle(cuisine)">
        <div class="flex items-center">       
          <span class="hot-chat-user-display-name">{{ getCuisineTitle(cuisine) }}</span>
        </div>
      </mat-option>
    </mat-autocomplete>    
  </mat-form-field> 
</div>
