import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { UserProfileFacade } from '@shared/facades/user-profile.facade'

@Injectable()
export class IsAdminGuard implements CanActivate {
  constructor(private userProfileFacade: UserProfileFacade, private router: Router) {}
  canActivate(_: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userProfileFacade.canAccessAdminSection$.pipe(
      map(isAdmin => {
        if (!isAdmin) {
          this.router.navigate(['properties'])
          return false
        }
        return true
      })
    )
  }
}
