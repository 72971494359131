import {
  Directive,
  Input,
  forwardRef,
  OnChanges,
  Renderer2,
  ElementRef,
  HostListener,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

/**
 * @description
 *
 * Allows to edit translatable fields via reactive forms.
 * Use this directive with one `formControl` or `formControlName`
 *
 * @example
 *
 * In class:
 * ```ts
 * title = new FormControl({ default: 'text', en: 'EN text' });
 * ```
 *
 * In template:
 * ```html
 * <input hotTranslatableField
 *   #titleField="hotTranslatable"
 *   [defaultLanguage]="defaultLanguage"
 *   [language]="language"
 *   [formControl]="title">
 * <button
 *   *ngIf="titleField.translated"
 *   (click)="titleField.copyFromDefault()">
 *     <span>Copy from default</span>
 * </button>
 * ```
 */
@Directive({
  selector: '[hotTranslatableField]',
  exportAs: 'hotTranslatable',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TranslatableFieldDirective),
      multi: true,
    },
  ],
})
export class TranslatableFieldDirective implements ControlValueAccessor, OnChanges {
  @Input() defaultLanguage = null
  @Input() language = 'default'
  @Input() placeholderDefaultText = true
  @Input() placeholder

  private _value: any = null

  @HostListener('input', ['$event.target.value'])
  _handleInput(value: any): void {
    const data = {
      ...this._value,
      [this.language]: value,
    }
    const keys = Object.keys(data)
    for (const key of keys) {
      if (key !== 'default' && key === this.defaultLanguage) {
        data['default'] = data[key]
      }
      // if (key !== 'default' && data[key] === data.default) {
      //   delete data[key]
      // }
    }
    this._value = data
    this.onChange(data)
  }

  onChange = (_: any) => {}

  @HostListener('blur', [])
  onTouched = () => {}

  ngOnChanges(changes) {
    if (changes.language) {
      this.writeValue(this._value)
    }
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  get translated() {
    return this.currentValue && this.currentValue !== this.defaultValue
  }

  get defaultValue() {
    return this._value && (this._value[this.defaultLanguage] || this._value.default)
  }

  get currentValue() {
    return this._value && this._value[this.language]
  }

  // Current-language value or default
  get normalizedValue() {
    const value = this._value
      ? typeof this._value[this.language] === 'string'
        ? this._value[this.language]
        : this.defaultValue
      : this.defaultValue
    return value
  }

  writeValue(value: any) {
    this._value = value
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', this.normalizedValue || '')
    // Sets placeholder to default-language value unless placeholder text is provided
    if (this.placeholderDefaultText && !this.placeholder) {
      this._renderer.setProperty(
        this._elementRef.nativeElement,
        'placeholder',
        this.defaultValue || ''
      )
    }
  }

  copyFromDefault() {
    this._handleInput(this.defaultValue)
    this.writeValue(this._value)
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }
}
