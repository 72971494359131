<section class="hot-builder-section mb4">
  <div *ngFor="let text of texts; let i = index" class="hot-bulder-item pr4">
    <div class="overlay-button--delete" *ngIf="showRemoveBtn(i)">
      <mat-icon (click)="handleRemove(i)" class="hot-bulder-item-remove">
        delete
      </mat-icon>
    </div>

    <p class="text-preview pt3 pb3 notranslate" *ngIf="!texts[i].isEdit" (click)="textEdit(i)">
      {{ texts[i].translations[currentLanguage] }}
    </p>

    <div class="w-100 relative" *ngIf="texts[i].isEdit">
      <hot-emoji-picker
        class="hot-builder__emoji-picker"
        (emojiSelect)="addEmoji($event)"
      ></hot-emoji-picker>
      <mat-form-field class="w-100 hot-emoji-input-container">
        <mat-label>{{ 'MenuBuilder.field.text' | translate: locale.language }}</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          ngModel
          #bodyText="ngModel"
          required
          hotAutofocus
          [maxlength]="maxBodyLength"
          [(ngModel)]="cache"
          (input)="cache = $event.target.value"
          [value]="cache"
          (keydown)="handleEnter($event, i)"
        ></textarea>
        <mat-hint align="end">{{ cache.length }} / {{ maxBodyLength }}</mat-hint>
        <mat-error *ngIf="bodyText?.errors && bodyText?.errors.required">
          {{ 'MenuBuilder.error.textRequired' | translate: locale.language }}
        </mat-error>
      </mat-form-field>

      <div class="tr mt3">
        <button
          [disabled]="!cache.length"
          (click)="textSave(i, enableManuallyTranslate && textAdding)"
          mat-raised-button
          color="primary"
        >
          {{ 'forms.action.save' | translate: locale.language }}
        </button>
        <button
          *ngIf="enableManuallyTranslate && !textAdding"
          class="ml3"
          [disabled]="!cache.length"
          (click)="textSave(i, true)"
          mat-raised-button
          color="primary"
        >
          {{ 'forms.action.saveAndTranslate' | translate: locale.language }}
        </button>
      </div>
    </div>

    <div class="overlay-button--edit" *ngIf="!isEditMode">
      <mat-icon
        class="hot-bulder-item-edit"
        (click)="textEdit(i)"
        [matTooltip]="'forms.action.edit' | translate: locale.language"
        matTooltipPosition="right"
        >edit</mat-icon
      >
    </div>

    <mat-icon
      *ngIf="text.isEdit && text.translations[currentLanguage].length && i >= 0"
      class="hot-bulder-item-success"
      (click)="handleCancel(i)"
      [matTooltip]="'forms.action.cancel' | translate: locale.language"
      matTooltipPosition="right"
      >close</mat-icon
    >
  </div>

  <button
    mat-button
    color="primary"
    class="w-100 mt2"
    (click)="handleAddText()"
    [disabled]="isEditMode"
  >
    <span>{{
      'MenuBuilder.action.addText' | translate: locale.language:{ count: texts.length }
    }}</span>
  </button>
</section>
