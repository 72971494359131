import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  filter,
  tap,
  exhaustMap,
  delay,
  mergeMap,
} from 'rxjs/operators'

import * as chatActions from './chat.actions'
import * as fromRoot from '../reducers'
import { ChatService } from './chat.service'
import { Store, select } from '@ngrx/store'
import { FormResponseStatus, ShopCartStatus } from '@models'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class ChatEffects {
  propertyId$ = this.store$.pipe(select(fromRoot.selectCurrentPropertyId))
  selectedChatIdChanges$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.Select),
    map((action: chatActions.Select) => action.payload.chatId),
    filter(Boolean)
  )

  @Effect()
  loadChats$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.Load),
    mergeMap((action: chatActions.Load) => {
      const meta = action.payload
      return this.chatService.getList(action.payload.propertyId, action.payload.params).pipe(
        map(x => new chatActions.LoadSuccess(x, meta)),
        catchError(x => of(new chatActions.LoadFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadChatsFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LoadFail),
    tap(() => {
      const loadChatsError = this.translationService.translate('Chat.error.loadChatsError')
      this.matSnackBar.open(loadChatsError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  @Effect()
  loadMessagesOnChatSelect$ = this.selectedChatIdChanges$.pipe(
    map(chatId => new chatActions.LoadMessages({ chatId }))
  )

  @Effect()
  loadFormResponsesOnChatSelect$ = this.selectedChatIdChanges$.pipe(
    withLatestFrom(this.propertyId$),
    map(([chatId, propertyId]: [string, string]) => {
      return new chatActions.LoadFormResponses({
        chatId: chatId,
        propertyId: propertyId,
        statuses: [FormResponseStatus.Open, FormResponseStatus.InProgress],
      })
    })
  )

  @Effect()
  loadVisitedLinksOnChatSelect$ = this.selectedChatIdChanges$.pipe(
    map(chatId => new chatActions.LoadVisitedPages({ chatId }))
  )

  @Effect()
  loadFormResponses$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LoadFormResponses),
    switchMap((action: chatActions.LoadFormResponses) => {
      const meta = action.payload
      return this.chatService
        .getFormResponses(action.payload.propertyId, action.payload.chatId, {
          statuses: action.payload.statuses,
        })
        .pipe(
          map(x => new chatActions.LoadFormResponsesSuccess(x, meta)),
          catchError(x => of(new chatActions.LoadFormResponsesFail(x, meta)))
        )
    })
  )

  @Effect()
  loadShopCarts$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LoadShopCarts),
    switchMap((action: chatActions.LoadShopCarts) => {
      const meta = action.payload
      return this.chatService
        .getShopCarts(action.payload.propertyId, action.payload.chatId, {
          statuses: action.payload.statuses,
        })
        .pipe(
          map(x => new chatActions.LoadShopCartsSuccess(x, meta)),
          catchError(x => of(new chatActions.LoadShopCartsFail(x, meta)))
        )
    })
  )

  @Effect()
  loadShopCartsOnChatSelect$ = this.selectedChatIdChanges$.pipe(
    withLatestFrom(this.propertyId$),
    map(([chatId, propertyId]: [string, string]) => {
      return new chatActions.LoadShopCarts({
        chatId: chatId,
        propertyId: propertyId,
        statuses: [
          ShopCartStatus.New,
          ShopCartStatus.Open,
          ShopCartStatus.InProgress,
          ShopCartStatus.WaitForPayment,
          ShopCartStatus.Ready,
        ],
      })
    })
  )

  @Effect({ dispatch: false })
  public loadFormResponsesFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LoadFormResponsesFail),
    tap(() => {
      const loadFormResponsesError = this.translationService.translate(
        'Chat.error.loadFormResponsesError'
      )
      this.matSnackBar.open(loadFormResponsesError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect()
  loadVisitedPages$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LoadVisitedPages),
    withLatestFrom(this.propertyId$),
    switchMap(([action, propertyId]: [chatActions.LoadVisitedPages, string]) => {
      const meta = action.payload
      return this.chatService.getVisitedPages(propertyId, action.payload.chatId).pipe(
        map(x => new chatActions.LoadVisitedPagesSuccess(x, meta)),
        catchError(x => of(new chatActions.LoadVisitedPagesFail(x, meta)))
      )
    })
  )

  @Effect()
  loadMessages$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LoadMessages),
    withLatestFrom(this.propertyId$),
    switchMap(([action, propertyId]: [chatActions.LoadMessages, string]) => {
      const meta = action.payload
      return this.chatService.getMessages(propertyId, action.payload.chatId).pipe(
        map(x => new chatActions.LoadMessagesSuccess(x, meta)),
        catchError(x => of(new chatActions.LoadMessagesFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadMessagesFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LoadMessagesFail),
    tap(() => {
      const loadChatMessagesError = this.translationService.translate(
        'Chats.error.loadChatMessagesError'
      )
      this.matSnackBar.open(loadChatMessagesError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect()
  searchMessages$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.SearchMessages),
    withLatestFrom(this.propertyId$),
    exhaustMap(([action, propertyId]: [chatActions.SearchMessages, string]) => {
      const meta = action.payload
      return this.chatService
        .searchMessages(propertyId, action.payload.chatId, action.payload.query)
        .pipe(
          delay(200), // Add delay for "better aesthetics" 😅
          map(x => new chatActions.SearchMessagesSuccess(x, meta)),
          catchError(x => of(new chatActions.SearchMessagesFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  searchMessagesFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.SearchMessagesFail),
    tap(() => {
      const searchChatMessagesError = this.translationService.translate(
        'Chat.error.searchChatMessagesError'
      )
      this.matSnackBar.open(searchChatMessagesError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect()
  joinChat$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.Join),
    withLatestFrom(this.propertyId$),
    switchMap(([action, propertyId]: [chatActions.Join, string]) => {
      const meta = action.payload
      return this.chatService.joinChat(propertyId, action.payload.chatId).pipe(
        mergeMap(x =>
          of(new chatActions.JoinSuccess(x, meta), new chatActions.SelectTab({ tabIndex: 0 }))
        ),
        catchError(x => of(new chatActions.JoinFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  joinChatFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.JoinFail),
    tap(() => {
      const joinChatError = this.translationService.translate('Chat.error.joinChatError')
      this.matSnackBar.open(joinChatError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect()
  leaveChat$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.Leave),
    withLatestFrom(this.propertyId$),
    switchMap(([action, propertyId]: [chatActions.Leave, string]) => {
      const meta = action.payload
      return this.chatService.leaveChat(propertyId, action.payload.chatId).pipe(
        mergeMap(x =>
          of(new chatActions.LeaveSuccess(x, meta), new chatActions.Select({ chatId: null }))
        ),
        catchError(x => of(new chatActions.LeaveFail(x, meta)))
      )
    })
  )

  @Effect()
  assignChat$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.Assign),
    withLatestFrom(this.propertyId$),
    switchMap(([action, propertyId]: [chatActions.Assign, string]) => {
      const meta = action.payload
      return this.chatService
        .assignChat(propertyId, action.payload.chatId, action.payload.formId)
        .pipe(
          map(x => new chatActions.AssignSuccess(x, meta)),
          catchError(x => of(new chatActions.AssignFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public assignFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.AssignFail),
    tap((action: chatActions.AssignFail) => {
      const message = action.error

      const isNoActiveUsersError =
        message.description &&
        message.description.errors &&
        message.description.errors.NoActiveUsers

      const error = isNoActiveUsersError
        ? this.translationService.translate('Chat.error.assignChatFailNoActiveUsers')
        : this.translationService.translate('Chat.error.assignChatError')

      if (error) {
        this.matSnackBar.open(error, 'OK', {
          duration: isNoActiveUsersError ? 15000 : 2000,
        })
      }
    })
  )

  @Effect()
  unsubscribeChat$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.UnsubscribePromo),
    withLatestFrom(this.propertyId$),
    switchMap(([action, propertyId]: [chatActions.UnsubscribePromo, string]) => {
      const meta = action.payload
      return this.chatService.unsubscribeChat(propertyId, action.payload.chatId).pipe(
        map(x => new chatActions.UnsubscribePromoSuccess(x, meta)),
        catchError(x => of(new chatActions.UnsubscribePromoFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  leaveChatFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.LeaveFail),
    tap(() => {
      const leaveChatError = this.translationService.translate('Chat.error.leaveChatError')
      this.matSnackBar.open(leaveChatError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  @Effect()
  sendMessage$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.SendMessage),
    withLatestFrom(this.propertyId$),
    switchMap(([action, propertyId]: [chatActions.SendMessage, string]) => {
      const meta = action.payload
      return this.chatService
        .sendMessage(propertyId, action.payload.chatId, action.payload.message)
        .pipe(
          map(x => new chatActions.SendMessageSuccess(x, meta)),
          catchError(x => of(new chatActions.SendMessageFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  sendMessageFail$ = this.actions$.pipe(
    ofType(chatActions.ActionTypes.SendMessageFail),
    tap(() => {
      const sendChatMessageError = this.translationService.translate(
        'Chat.error.sendChatMessageError'
      )
      this.matSnackBar.open(sendChatMessageError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private store$: Store<fromRoot.State>,
    private chatService: ChatService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
