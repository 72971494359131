<div class="form" [formGroup]="form">
  <div class="mb2">
    <mat-checkbox formControlName="required">
      {{ 'forms.label.requiredField' | translate: locale.language }}
    </mat-checkbox>
  </div>

  <mat-form-field appearance="fill" class="mr3 mb1">
    <mat-label>{{ 'FormQuestion.date.days' | translate: locale.language }}</mat-label>
    <input type="number" matInput formControlName="days" min="2" max="10" />
    <mat-error *ngIf="form.get('days').errors">{{
      'FormQuestion.date.error.invalidDaysCount' | translate: locale.language:{ from: 2, to: 10 }
    }}</mat-error>
  </mat-form-field>

  <!-- Doesn't supported yet on backend :( -->
  <!--
  <mat-form-field appearance="fill">
    <mat-label>Пропустить дней</mat-label>
    <input type="number" matInput formControlName="startingDayOffset">
  </mat-form-field>
  -->

  <mat-checkbox formControlName="acceptUserText">
    {{ 'FormQuestion.date.acceptUserText' | translate: locale.language }}
  </mat-checkbox>

  <mat-checkbox formControlName="requireTime">
    {{ 'FormQuestion.date.requireTime' | translate: locale.language }}
  </mat-checkbox>

  <hot-form-field-autofill class="mt3" formControlName="autofillFrom"></hot-form-field-autofill>
</div>
