import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { of, NEVER } from 'rxjs'
import { switchMap, map, catchError, tap, merge, exhaustMap, take } from 'rxjs/operators'

import * as fromRoot from '../reducers'
import * as fromRouter from '../router'
import * as formResponseActions from './form-response.actions'
import { FormResponseService } from './form-response.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'
import { Store, select } from '@ngrx/store'

@Injectable()
export class FormResponseEffects {
  /**
   * Load form responses effects
   */
  @Effect()
  load$ = this.actions$.pipe(
    ofType(formResponseActions.ActionTypes.Load),
    switchMap((action: formResponseActions.Load) => {
      const meta = action.payload
      return this.formResponseService
        .getList(action.payload.propertyId, {
          status: action.payload.status,
          page: action.payload.page,
          limit: action.payload.limit,
        })
        .pipe(
          map(x => new formResponseActions.LoadSuccess(x, meta)),
          catchError(x => of(new formResponseActions.LoadFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  loadFail$ = this.actions$.pipe(
    ofType(formResponseActions.ActionTypes.LoadFail),
    tap(() => {
      const loadError = this.translationService.translate('Request.error.loadError')
      this.matSnackBar.open(loadError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load item effects
   */
  @Effect()
  loadItem$ = this.actions$.pipe(
    ofType(formResponseActions.ActionTypes.LoadItem),
    merge(
      this.actions$.pipe(
        ofType(formResponseActions.ActionTypes.UpdateStatusFail),
        map(
          (action: formResponseActions.UpdateStatusFail) =>
            new formResponseActions.LoadItem(action.meta)
        )
      )
    ),
    switchMap((action: formResponseActions.LoadItem) => {
      const meta = action.payload
      return this.formResponseService
        .get(action.payload.propertyId, action.payload.formResponseId)
        .pipe(
          map(x => new formResponseActions.LoadItemSuccess(x, meta)),
          catchError(x => of(new formResponseActions.LoadItemFail(x, meta)))
        )
    })
  )

  @Effect()
  loadItemFail$ = this.actions$.pipe(
    ofType(formResponseActions.ActionTypes.LoadItemFail),
    exhaustMap((action: formResponseActions.LoadItemFail) => {
      const loadItemError = this.translationService.translate('Request.error.loadItemError')
      this.matSnackBar.open(loadItemError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })

      return this.store$.pipe(
        select(fromRoot.selectRouterState),
        take(1),
        switchMap(routerState => {
          if (routerState.params.formResponseId === action.meta.formResponseId) {
            return of(new fromRouter.Go({ path: routerState.url.split('/').slice(0, -1) }))
          }
          return NEVER
        })
      )
    })
  )

  /**
   * Update status effects
   */
  @Effect()
  public updateStatus$ = this.actions$.pipe(
    ofType(formResponseActions.ActionTypes.UpdateStatus),
    switchMap((action: formResponseActions.UpdateStatus) => {
      const meta = action.payload
      return this.formResponseService
        .updateStatus(action.payload.propertyId, action.payload.formResponseId, {
          status: action.payload.status,
          comment: action.payload.comment,
        })
        .pipe(
          map(x => new formResponseActions.UpdateStatusSuccess(x, meta)),
          catchError(x => of(new formResponseActions.UpdateStatusFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateStatusFail$ = this.actions$.pipe(
    ofType(formResponseActions.ActionTypes.UpdateStatusFail),
    tap(() => {
      const updateStatusError = this.translationService.translate('Request.error.updateStatusError')
      this.matSnackBar.open(updateStatusError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  constructor(
    private readonly store$: Store<any>,
    private actions$: Actions,
    private formResponseService: FormResponseService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
