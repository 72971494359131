import * as R from 'ramda'
import { I18nService } from '@shared/services'

export const langLabel = (translationService: I18nService, langCode, languages) => {
  const translatePath = 'Languages'
  const translation = translationService.translate(`${translatePath}.${langCode}`)
  const languageInfo = R.find(R.propEq('code', langCode), languages)
  const nameByCode = (languageInfo && languageInfo.name) || langCode || '—'
  return translation.includes(translatePath) ? nameByCode : translation
}
