import { BuilderActions, BuilderActionTypes } from './builder.actions'

// States

export interface Substate {
  loaded: boolean
  loading: boolean
  updated: boolean
  ids: string[]
  rootId: string
  modules: string[]
}

export interface State {
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialSubstate: Substate = {
  loaded: false,
  loading: false,
  updated: false,
  ids: [],
  rootId: '',
  modules: [],
}

export const initialState: State = {
  byPropertyId: {},
}

function substateReducer(state = initialSubstate, action: BuilderActions): Substate {
  switch (action.type) {
    case BuilderActionTypes.LoadAll:
    case BuilderActionTypes.Load: {
      return {
        ...state,
        loading: true,
      }
    }

    case BuilderActionTypes.LoadSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        ids: action.payload.results,
        rootId: action.payload.rootId,
      }
    }

    case BuilderActionTypes.LoadAllSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        ids: action.payload.results,
        rootId: action.payload.rootId,
        modules: action.payload.modules,
      }
    }

    case BuilderActionTypes.LoadAllFail:
    case BuilderActionTypes.LoadFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }

    case BuilderActionTypes.Update: {
      return {
        ...state,
        updated: false,
      }
    }

    case BuilderActionTypes.UpdateSuccess: {
      return {
        ...state,
        updated: true,
      }
    }

    case BuilderActionTypes.CreateSuccess: {
      const createdId = action.payload.result
      return {
        ...state,
        ids: [...state.ids, createdId],
      }
    }

    case BuilderActionTypes.DeleteSuccess: {
      const deletedId = action.payload.result
      return {
        ...state,
        ids: state.ids.filter(x => x !== deletedId),
      }
    }

    default:
      return state
  }
}

export function reducer(state = initialState, action: BuilderActions): State {
  switch (action.type) {
    case BuilderActionTypes.Create:
    case BuilderActionTypes.CreateSuccess:
    case BuilderActionTypes.CreateFail:
    case BuilderActionTypes.Load:
    case BuilderActionTypes.LoadSuccess:
    case BuilderActionTypes.LoadFail:
    case BuilderActionTypes.LoadAll:
    case BuilderActionTypes.LoadAllSuccess:
    case BuilderActionTypes.LoadAllFail:
    case BuilderActionTypes.Update:
    case BuilderActionTypes.DeleteSuccess:
    case BuilderActionTypes.UpdateSuccess:
    case BuilderActionTypes.UpdateFail: {
      const query =
        action.type === BuilderActionTypes.Load ||
        action.type === BuilderActionTypes.LoadAll ||
        action.type === BuilderActionTypes.Update ||
        action.type === BuilderActionTypes.Create
          ? action.payload
          : action.meta
      return {
        byPropertyId: {
          ...state.byPropertyId,
          [query.propertyId]: substateReducer(state.byPropertyId[query.propertyId], action),
        },
      }
    }

    default:
      return state
  }
}

export const selectSubstates = (state: State) => state && state.byPropertyId
export const selectBuildersIds = (substate: Substate) => substate && substate.ids
export const selectBuildersLoading = (substate: Substate) => substate && substate.loading
export const selectBuildersLoaded = (substate: Substate) => substate && substate.loaded
export const selectBuildersUpdated = (substate: Substate) => substate && substate.updated
export const selectBuildersModules = (substate: Substate) => substate && substate.modules
export const selectRootId = (substate: Substate) => substate && substate.rootId
