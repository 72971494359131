<div>
  <div class="form" [formGroup]="form"></div>

  <div class="flex items-center">
    <mat-menu #appMenu="matMenu">
      <button
        type="button"
        mat-menu-item
        *ngFor="let field of otherFormFields"
        (click)="setNextPage(field.id)"
      >
        <span class="mr2 black-50">#{{ field.idx + 1 }} </span>
        <span class="fw5">{{ field.title | selectLanguage: language }}</span>
      </button>
      <mat-divider *ngIf="otherFormFields.length > 0"></mat-divider>
      <button
        type="button"
        mat-menu-item
        class="f6 fw5 black-70"
        (click)="setNextPage(END_FORM_ACTION.id)"
      >
        <mat-icon class="mr2">fast_forward</mat-icon>
        <span>{{ END_FORM_ACTION.title | translate: locale.language }}</span>
      </button>
      <button
        type="button"
        mat-menu-item
        class="f6 fw5 black-70"
        (click)="setNextPage(END_SILENTLY_FORM_ACTION.id)"
      >
        <mat-icon class="mr2">fast_forward</mat-icon>
        <span>{{ END_SILENTLY_FORM_ACTION.title | translate: locale.language }}</span>
      </button>
      <button
        type="button"
        mat-menu-item
        class="f6 fw5 black-70"
        (click)="setNextPage(CANCEL_FORM_ACTION.id)"
      >
        <mat-icon class="mr2">cancel</mat-icon>
        <span>{{ CANCEL_FORM_ACTION.title | translate: locale.language }}</span>
      </button>
    </mat-menu>

    <ng-container *ngIf="getCurrentAction() as nq; else noNextQuestion">
      <span class="f5 skip-question flex items-center">
        <ng-container [ngSwitch]="nq.id">
          <span class="mr2" *ngSwitchCase="END_FORM_ACTION.id">
            <span class="bb bw1 b--gold link pointer" [matMenuTriggerFor]="appMenu">
              <span class="fw5 black">{{
                END_FORM_ACTION.title | translate: locale.language
              }}</span>
            </span>
          </span>
          <span class="mr2" *ngSwitchCase="END_SILENTLY_FORM_ACTION.id">
            <span class="bb bw1 b--gold link pointer" [matMenuTriggerFor]="appMenu">
              <span class="fw5 black">{{
                END_SILENTLY_FORM_ACTION.title | translate: locale.language
              }}</span>
            </span>
          </span>
          <span class="mr2" *ngSwitchCase="CANCEL_FORM_ACTION.id">
            <span class="bb bw1 b--red link pointer" [matMenuTriggerFor]="appMenu">
              <span class="fw5 black">{{
                CANCEL_FORM_ACTION.title | translate: locale.language
              }}</span>
            </span>
          </span>

          <span class="mr2" *ngSwitchDefault>
            <span class="mr2">{{
              (nq.type === 'message'
                ? 'FormQuestion.action.label.gotoMessage'
                : 'FormQuestion.action.label.gotoQuestion') | translate: locale.language
            }}</span>
            <span class="bb bw1 b--green link pointer" [matMenuTriggerFor]="appMenu">
              <span class="black-50">#{{ nq.idx + 1 }} </span>
              <span class="fw5 black dib mw5 truncate">{{
                nq.title | selectLanguage: language
              }}</span>
            </span>
          </span>
        </ng-container>

        <span class="black-40 dib br-100 bg-white pointer" (click)="setNextPage(null)">
          <mat-icon class="delete-icon">cancel</mat-icon>
        </span>
      </span>
    </ng-container>

    <ng-template #noNextQuestion>
      <span class="f5" [matMenuTriggerFor]="appMenu">
        <span class="link pointer skip-question skip-question--link">{{
          'FormQuestion.action.action.selectAction' | translate: locale.language
        }}</span>
      </span>
    </ng-template>
  </div>
</div>
