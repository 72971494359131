import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Inject,
} from '@angular/core'
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { FormFieldConfig } from '@models'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'hot-form-field-address',
  templateUrl: './form-field-address.component.html',
  styleUrls: ['./form-field-address.component.css'],
})
export class FormFieldAddressComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public defaultLanguage
  @Input() public language
  @Input() public config: FormControl
  @Output() public changed = new EventEmitter()

  public form: FormGroup
  private destroyed = new Subject()

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    this.form = this.fb.group({
      required: this.fb.control(true),
      autofillFrom: this.fb.control(''),
    })
  }

  prepareConfig(data: Partial<FormFieldConfig>) {
    return {
      required: typeof data.required === 'boolean' ? data.required : true,
      autofillFrom: data.autofillFrom || '',
    }
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
      this.changed.next({
        ...this.config.value,
        ...this.prepareConfig(val),
      })
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      const config = this.config.value as FormFieldConfig
      this.form.patchValue(this.prepareConfig(config))
    }
  }
}
