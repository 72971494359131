import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { ConfigService, AuthService } from '@shared/services'
import { Component, OnInit, Inject } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

import { Store, select } from '@ngrx/store'

import { Observable } from 'rxjs'
import { filter, take } from 'rxjs/operators'

import * as fromRoot from '@redux/reducers'
import * as fromUser from '@redux/user'
import * as fromRouter from '@redux/router'

@Component({
  selector: 'hot-auth',
  templateUrl: './auth.container.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
        align-items: flex-start;
      }
    `,
  ],
})
export class AuthContainer implements OnInit {
  public userId$: Observable<string>
  public authForm: FormGroup
  public connectWithBookingUrl = `${this.config.API_BASE_URL}/auth/booking-suite`
  public connectWithBookingImageUrl =
    'https://bstatic.com/backend_static/common/dist/bookingsuite/oauth2/connect_with_booking_btn.svg'

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private store$: Store<fromRoot.State>,
    private config: ConfigService,
    public authService: AuthService
  ) {}

  public ngOnInit() {
    this.authForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    })

    this.store$
      .pipe(
        select(fromRoot.selectAuthorizedUser),
        filter(id => Boolean(id)),
        take(1)
      )
      .subscribe(() => {
        this.store$.dispatch(new fromRouter.Go({ path: [''] }))
      })
  }

  public login() {
    const formData = this.authForm.value

    this.store$.dispatch(new fromUser.Login(formData))
  }

  public get isAuthorized() {
    return this.authService.isAuthorized()
  }

  public get isBookingSuiteEnabled() {
    return this.config.BOOKING_SUITE_ENABLED
  }
}
