<div class="w-100 mb2 relative" [formGroup]="titleForm">
  <hot-emoji-picker
    class="hot-builder__emoji-picker"
    (emojiSelect)="addEmoji($event)"
  ></hot-emoji-picker>
  <mat-form-field class="w-100 notranslate title-field" appearance="fill">
    <mat-label>{{ 'MenuBuilder.field.buttonTitle' | translate: locale.language }}</mat-label>
    <input
      class="title-input"
      [maxlength]="maxButtonLength"
      matInput
      formControlName="Title"
      #title
    />
    <mat-hint align="end">{{ title.value.length }} / {{ maxButtonLength }}</mat-hint>
    <mat-error
      *ngIf="titleForm && titleForm.touched && titleForm.get('Title').hasError('required')"
    >
      {{ 'MenuBuilder.error.buttonTitleRequired' | translate: locale.language }}
    </mat-error>
  </mat-form-field>

  <div
    class="mb3 z-5 relative tracking-button"
    *ngIf="(!showTrackingCode && isAdmin) || (canSetVisibilityPeriod && showPeriodOfVisibility)"
  >
    <span
      *ngIf="!showTrackingCode"
      (click)="showTrackingCode = true"
      class="f7 blue pointer dib mr3"
    >
      <mat-icon class="v-mid mr1">timeline</mat-icon>
      <span class="dib v-mid">Add tracking code</span>
    </span>

    <span
      *ngIf="canSetVisibilityPeriod && !showPeriodOfVisibility"
      (click)="showPeriodOfVisibility = true"
      class="f7 blue pointer dib mr3"
    >
      <mat-icon class="v-mid mr1">timer</mat-icon>
      <span class="dib v-mid">{{
        'MenuBuilder.action.addVisibilityPeriod' | translate: locale.language
      }}</span>
    </span>
  </div>

  <mat-form-field class="w-100" appearance="fill" *ngIf="showTrackingCode">
    <mat-label>Tracking Code</mat-label>
    <input
      class="tracking-code-input"
      [maxlength]="maxButtonLength"
      matInput
      formControlName="TrackingCode"
    />
  </mat-form-field>

  <div formGroupName="VisibilityPeriod" *ngIf="showPeriodOfVisibility" class="flex items-center">
    <mat-form-field class="w-100 mr2" appearance="fill">
      <mat-label>{{ 'MenuBuilder.field.visibleSince' | translate: locale.language }}</mat-label>
      <input
        class="visibility-period-input visibility-period-input--since"
        matInput
        formControlName="start"
        [matDatepicker]="pickerSince"
        autocomplete="off"
        autocapitalize="off"
        type="text"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerSince"></mat-datepicker-toggle>
      <mat-datepicker #pickerSince></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="w-100 ml2" appearance="fill">
      <mat-label>{{ 'MenuBuilder.field.visibleUntil' | translate: locale.language }}</mat-label>
      <input
        class="visibility-period-input visibility-period-input--until"
        matInput
        formControlName="end"
        [matDatepicker]="pickerUntil"
        autocomplete="off"
        autocapitalize="off"
        type="text"
      />
      <mat-datepicker-toggle matSuffix [for]="pickerUntil"></mat-datepicker-toggle>
      <mat-datepicker #pickerUntil></mat-datepicker>
    </mat-form-field>
  </div>
</div>

<mat-tab-group
  class="small-tabs"
  *ngIf="
    !debugJson &&
    cache.translations &&
    cache.translations[currentLanguage] &&
    cache.translations[currentLanguage].config as buttonConfig
  "
  [selectedIndex]="selectedButtonIndex()"
  (selectedTabChange)="changeButtonType($event)"
>
  <mat-tab
    *ngIf="canAddTypeButton('PAGE')"
    [label]="'MenuBuilder.buttonType.page' | translate: locale.language"
    aria-label="page"
  >
    <div class="pv4 hot-builder-button-edit-content">
      <div class="mb4" *ngIf="addPageEnabled">
        <button
          mat-flat-button
          color="accent"
          [disabled]="titleForm && !titleForm.valid"
          (click)="handleNewPage(titleForm && titleForm.value.Title, buttonIndex)"
        >
          <mat-icon>add</mat-icon>
          <span>{{ 'MenuBuilder.action.createPage' | translate: locale.language }}</span>
        </button>
      </div>
      <div class="pages-list">
        <button
          *ngFor="let page of builders"
          class="mv2 mr2 notranslate"
          [color]="buttonConfig.pageId === page.pageId ? 'primary' : ''"
          mat-flat-button
          (click)="selectPage(page)"
        >
          {{ page.name | selectLanguage: currentLanguage }}
        </button>
      </div>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="canAddTypeButton('MODULE')"
    [label]="'MenuBuilder.buttonType.module' | translate: locale.language"
    aria-label="module"
  >
    <div class="pv4 hot-builder-button-edit-content">
      <button
        *ngFor="let module of modules"
        class="mv2 mr2 notranslate"
        mat-flat-button
        [color]="isActiveModuleButton(module.module, module.place || '')"
        (click)="selectModule(module)"
      >
        {{ module.i18nKey | translate: locale.language }}
      </button>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="canAddTypeButton('URL')"
    [label]="'MenuBuilder.buttonType.url' | translate: locale.language"
    aria-label="url"
  >
    <div class="pv4 hot-builder-button-edit-content" [formGroup]="urlForm">
      <mat-form-field class="w-100">
        <mat-label>{{ 'MenuBuilder.field.url' | translate: locale.language }}</mat-label>
        <input #urlInput autofocus matInput formControlName="Url" />
        <mat-error *ngIf="urlForm && urlForm.touched && urlForm.get('Url').hasError('url')">
          {{ 'MenuBuilder.error.urlInvalid' | translate: locale.language }}
        </mat-error>
        <mat-error *ngIf="urlForm && urlForm.touched && urlForm.get('Url').hasError('required')">
          {{ 'MenuBuilder.error.urlRequired' | translate: locale.language }}
        </mat-error>
      </mat-form-field>

      <mat-form-fiel class="w-100 flex justify-end">
        <mat-checkbox color="primary" formControlName="TargetSelf">
          {{ 'MenuBuilder.field.targetSelf' | translate: locale.language }}
        </mat-checkbox>
      </mat-form-fiel>

      <hot-upload-file
        class="mt4"
        [previewImageWidth]="200"
        [fileUrl]="fileUrl"
        [extensions]="extensions"
        (change)="onUploadFile($event)"
      >
      </hot-upload-file>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="canAddTypeButton('FORM') && forms?.length"
    [label]="'MenuBuilder.buttonType.form' | translate: locale.language"
    aria-label="form"
  >
    <div class="pv4 hot-builder-button-edit-content">
      <button
        *ngFor="let form of forms"
        class="mv2 mr2 notranslate"
        [color]="buttonConfig.formId === form._id ? 'primary' : ''"
        mat-flat-button
        (click)="selectForm(form)"
      >
        {{ form.title | selectLanguage: currentLanguage }}
      </button>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="canAddTypeButton('SHOP_LINK')"
    [label]="'MenuBuilder.buttonType.shopLink' | translate: locale.language"
    aria-label="shop_link"
  >
    <div class="pv4 hot-builder-button-edit-content">
      <hot-shop-entity-selector
        [lang]="locale.language"
        [defaultLanguage]="locale.language"
        [currentLanguage]="currentLanguage"
        [categories]="shopCategories"
        [products]="shopProducts"
        [link]="buttonConfig || null"
        [allowHomePage]="true"
        (change)="handleShopLinkChange($event)"
      ></hot-shop-entity-selector>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="canAddTypeButton('POLL') && polls?.length"
    [label]="'MenuBuilder.buttonType.poll' | translate: locale.language"
    aria-label="poll"
  >
    <div class="pv4 hot-builder-button-edit-content">
      <button
        *ngFor="let poll of polls"
        class="mv2 mr2 notranslate"
        [color]="buttonConfig.pollId === poll._id ? 'primary' : ''"
        mat-flat-button
        (click)="selectPoll(poll)"
      >
        {{ poll.title | selectLanguage: currentLanguage }}
      </button>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="canAddTypeButton('TEMPLATE')"
    [label]="'MenuBuilder.buttonType.template' | translate: locale.language"
    aria-label="template"
  >
    <div class="pv4 hot-builder-button-edit-content">
      <div class="ph1 pages-list">
        <ng-container *ngIf="templates.length; else noTemplates">
          <button
            *ngFor="let template of templates"
            class="mv2 mr2"
            [color]="buttonConfig.templateId === template._id ? 'primary' : ''"
            mat-flat-button
            (click)="selectTemplate(template)"
          >
            {{ template.name | selectLanguage: currentLanguage }}
          </button>
        </ng-container>
        <ng-template #noTemplates>
          <div class="mt5 o-50 flex justify-center">
            {{ 'MenuBuilder.noTemplates' | translate: locale.language }}
          </div>
        </ng-template>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<div *ngIf="isAdmin && debugJson">
  <textarea
    style="font-family: Menlo, monospace;"
    class="w-100 f7"
    rows="20"
    [value]="cache | json"
    (input)="updateConfig($event)"
  ></textarea>
  <!-- <details>
    <pre>{{ cache | json }}</pre>
  </details> -->
</div>

<div class="flex items-center tr ma2 mt3">
  <ng-container *ngIf="isAdmin">
    <button
      class="o-0 glow mr-auto"
      mat-button
      color="accent"
      (click)="debugJson = !debugJson"
      type="button"
    >
      JSON
    </button>
  </ng-container>
  <button mat-button color="primary" (click)="cancelButtonSave()" class="ml-auto">
    {{ 'forms.action.cancel' | translate: locale.language }}
  </button>
  <button
    mat-flat-button
    class="ml3"
    color="primary"
    [disabled]="!isSaveEnabled"
    (click)="buttonSave(buttonIndex, enableManuallyTranslate && buttonAdding)"
  >
    {{ 'forms.action.save' | translate: locale.language }}
  </button>
  <button
    *ngIf="enableManuallyTranslate && !buttonAdding"
    class="ml3"
    mat-flat-button
    color="primary"
    [disabled]="!isSaveEnabled"
    (click)="buttonSave(buttonIndex, true)"
  >
    {{ 'forms.action.saveAndTranslate' | translate: locale.language }}
  </button>
</div>
