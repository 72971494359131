import * as R from 'ramda'
import { FAQActions, FAQActionTypes } from './faq.actions'
import { DEFAULT_PAGINATION_SIZE, PropertyFAQ } from '@models'

export interface Substate {
  data: PropertyFAQ[]
  loaded: boolean
  loading: boolean
}

export interface State {
  ids: string[]
  loaded: boolean
  loading: boolean
  filters: {
    paginationOptions: {
      page: number
      limit: number
    }
  }
  meta: {
    existingKeys?: string[]
    totalRecords?: number
    totalPages?: number
  }
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialSubstate: Substate = {
  data: [],
  loaded: false,
  loading: false,
}

export const initialState: State = {
  ids: [],
  loaded: false,
  loading: false,
  filters: {
    paginationOptions: {
      page: 0,
      limit: DEFAULT_PAGINATION_SIZE,
    },
  },
  meta: {
    existingKeys: [],
    totalRecords: 0,
    totalPages: 0,
  },
  byPropertyId: {},
}

function substateReducer(state = initialSubstate, action: FAQActions): Substate {
  switch (action.type) {
    case FAQActionTypes.CreateSuccess: {
      const createdPropertyFAQ = action.payload
      return {
        data: [...state.data, createdPropertyFAQ],
        loaded: true,
        loading: false,
      }
    }

    case FAQActionTypes.Load: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }

    case FAQActionTypes.LoadSuccess: {
      return {
        data: action.payload,
        loaded: true,
        loading: false,
      }
    }

    case FAQActionTypes.LoadFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }
    case FAQActionTypes.UpdateSuccess: {
      const faqId = action.payload._id
      const updatedIndex = R.findIndex(R.propEq('_id', faqId))(state.data)
      state.data[updatedIndex] = action.payload
      return {
        ...state,
      }
    }

    case FAQActionTypes.UpdateFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }

    case FAQActionTypes.DeleteSuccess: {
      const deletedId = action.meta.faqId
      return {
        data: R.filter(item => item._id !== deletedId, state.data),
        loaded: true,
        loading: false,
      }
    }

    default:
      return state
  }
}

export function reducer(state = initialState, action: FAQActions): State {
  switch (action.type) {
    case FAQActionTypes.CreateCategoriesSuccess: {
      return {
        ...state,
        ids: [...state.ids, action.payload.result],
        meta: action.payload.meta,
      }
    }

    case FAQActionTypes.LoadCategories: {
      return {
        ...state,
        loaded: false,
        filters: {
          paginationOptions: {
            page: action.payload.page,
            limit: action.payload.limit,
          },
        },
      }
    }

    case FAQActionTypes.LoadCategoriesSuccess: {
      return {
        ...state,
        loaded: true,
        ids: action.payload.results,
        meta: action.payload.meta,
      }
    }

    case FAQActionTypes.LoadCategoriesFail: {
      return {
        ...state,
        ids: [],
        loading: false,
        filters: {
          paginationOptions: {
            page: 0,
            limit: 10,
          },
        },
      }
    }

    case FAQActionTypes.DeleteCategoriesSuccess: {
      const deletedId = action.meta
      return {
        ...state,
        ids: R.without([deletedId], state.ids),
      }
    }

    case FAQActionTypes.Create:
    case FAQActionTypes.CreateSuccess:
    case FAQActionTypes.CreateFail:
    case FAQActionTypes.Load:
    case FAQActionTypes.LoadSuccess:
    case FAQActionTypes.LoadFail:
    case FAQActionTypes.Update:
    case FAQActionTypes.UpdateSuccess:
    case FAQActionTypes.UpdateFail:
    case FAQActionTypes.Delete:
    case FAQActionTypes.DeleteSuccess:
    case FAQActionTypes.DeleteFail: {
      const query =
        action.type === FAQActionTypes.Create ||
        action.type === FAQActionTypes.Load ||
        action.type === FAQActionTypes.Update ||
        action.type === FAQActionTypes.Delete
          ? action.payload
          : action.meta
      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [query.propertyId]: substateReducer(state.byPropertyId[query.propertyId], action),
        },
      }
    }
    default: {
      return state
    }
  }
}

export const selectSubstates = (state: State) => state && state.byPropertyId
export const selectSubstateData = (state: Substate) => state && state.data
export const selectSubstateLoaded = (state: Substate) => state && state.loaded
export const selectFAQCategoriesIds = (state: State) => state && state.ids
export const selectIsLoaded = (state: State) => state && state.loaded
export const selectFilters = (state: State) => state && state.filters
export const selectExistingKeys = (state: State) =>
  state && state.meta ? state.meta.existingKeys : []
export const selectTotalRecords = (state: State) =>
  state && state.meta ? state.meta.totalRecords : 0
