import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { NormalizedResult } from '@models/api-normalized-responses'
import { SalesServiceSettings } from '@models/sales-service-settings.model'
import { Merchant } from '@models/merchant.model'

export interface IMerchantShort {
  _id: string
  name: string
  alias: string
}

@Injectable()
export class SalesServiceService {
  constructor(public readonly apiService: ApiService) {}

  /**
   * Settings
   */

  createSettings(propertyId: string, settings: SalesServiceSettings): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/sales-service/settings`, settings)
  }

  getSettings(propertyId: string, params?: object): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/sales-service/settings`, { params })
  }

  getSettingsItem(propertyId: string, id: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/sales-service/settings/${id}`)
  }

  updateSettings(
    propertyId: string,
    id: string,
    salesServiceSettings: SalesServiceSettings
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/sales-service/settings/${id}`, salesServiceSettings)
  }

  deleteSettings(propertyId: string, id: string): Observable<NormalizedResult> {
    return this.apiService.delete(`properties/${propertyId}/sales-service/settings/${id}`)
  }

  /**
   * Merchants
   */

  createMerchant(merchant: Merchant): Observable<NormalizedResult> {
    return this.apiService.post(`sales-service/merchant`, merchant)
  }

  getMerchants(): Observable<NormalizedResult> {
    return this.apiService.get(`sales-service/merchant`)
  }
  getMerchantsShort(propertyId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/sales-service/merchant-short`)
  }

  getMerchantItem(id: string): Observable<NormalizedResult> {
    return this.apiService.get(`sales-service/merchant/${id}`)
  }

  updateMerchant(id: string, merchant: Merchant): Observable<NormalizedResult> {
    return this.apiService.patch(`sales-service/merchants/${id}`, merchant)
  }

  deleteMerchant(id: string): Observable<NormalizedResult> {
    return this.apiService.delete(`sales-service/merchants/${id}`)
  }
}
