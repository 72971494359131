import { Injectable } from '@angular/core'

import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap } from 'rxjs/operators'

import * as restaurantActions from './restaurant.actions'
import { RestaurantService } from './restaurant.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'

const CREATE_RESTAURANT_SUCCESS = 'Ресторан успешно создан'
const UPDATE_RESTAURANT_SUCCESS = 'Информация была успешно обновлена'
const DELETE_RESTAURANT_SUCCESS = 'Ресторан успешно удален'
const SAME_ALIAS_ERROR = 'Ресторан с заданным пседонимом уже существует'
const CREATE_RESTAURANT_ERROR = 'При создании ресторана возникла ошибка'
const LOAD_ERROR = 'При загрузке информации о ресторанах возникла ошибка'
const UPDATE_RESTAURANT_ERROR = 'При обновлении информации о ресторане возникла ошибка'
const DELETE_RESTAURANT_ERROR = 'При удалении ресторана возникла ошибка'

@Injectable()
export class RestaurantEffects {
  /**
   * Create restaurant effects
   */

  @Effect()
  public create$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.Create),
    switchMap((action: restaurantActions.Create) => {
      const meta = action.payload
      return this.restaurantService
        .create(
          action.payload.restaurant,
          action.payload.sourceLanguage,
          action.payload.targetLanguages
        )
        .pipe(
          map(x => {
            return new restaurantActions.CreateSuccess(x, meta)
          }),
          catchError(x => of(new restaurantActions.CreateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.CreateSuccess),
    tap(() => {
      this.matSnackBar.open(CREATE_RESTAURANT_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.CreateFail),
    tap((action: restaurantActions.UpdateFail) => {
      const message = action.error
      const error =
        message.description && message.description.errors && message.description.errors.alias
          ? SAME_ALIAS_ERROR
          : CREATE_RESTAURANT_ERROR
      if (error) {
        this.matSnackBar.open(error, '', {
          panelClass: ['bg-red'],
          duration: 2000,
        })
      }
    })
  )

  /**
   * Load restaurant effect
   */
  @Effect()
  public loadItem$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.LoadItem),
    switchMap((action: restaurantActions.LoadItem) => {
      const meta = action.payload
      return this.restaurantService
        .getItem(action.payload.restaurantId)
        .pipe(
          map(x => new restaurantActions.LoadItemSuccess(x, meta)),
          catchError(x => of(new restaurantActions.LoadItemFail(x, action.payload)))
        )
    })
  )

  /**
   * Load restaurants effects
   */
  @Effect()
  effect$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.Load),
    switchMap((action: restaurantActions.Load) => {
      const query = {
        limit: action.payload.limit,
        page: action.payload.page,
      }
      return this.restaurantService
        .get(query)
        .pipe(
          map(x => new restaurantActions.LoadSuccess(x, action.payload)),
          catchError(x => of(new restaurantActions.LoadFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.LoadFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Update restaurant effects
   */

  @Effect()
  public update$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.Update),
    switchMap((action: restaurantActions.Update) => {
      const meta = action.payload
      return this.restaurantService
        .update(
          action.payload.restaurantId,
          action.payload.restaurant,
          action.payload.sourceLanguage,
          action.payload.targetLanguages
        )
        .pipe(
          map(x => new restaurantActions.UpdateSuccess(x, meta)),
          catchError(x => of(new restaurantActions.UpdateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.UpdateSuccess),
    tap(() => {
      this.matSnackBar.open(UPDATE_RESTAURANT_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.UpdateFail),
    tap(() => {
      this.matSnackBar.open(UPDATE_RESTAURANT_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Delete restaurant effects
   */

  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.Delete),
    switchMap((action: restaurantActions.Delete) => {
      const restaurantId = action.payload.restaurantId
      return this.restaurantService
        .delete(restaurantId)
        .pipe(
          map(x => new restaurantActions.DeleteSuccess(x, { restaurantId })),
          catchError(x => of(new restaurantActions.DeleteFail(x, { restaurantId })))
        )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.DeleteSuccess),
    tap(() => {
      this.matSnackBar.open(DELETE_RESTAURANT_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(restaurantActions.RestaurantActionTypes.DeleteFail),
    tap(() => {
      this.matSnackBar.open(DELETE_RESTAURANT_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private restaurantService: RestaurantService,
    private matSnackBar: MatSnackBar
  ) {}
}
