import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'

import { AuthService } from '../services/auth.service'
import { Store } from '@ngrx/store'
import * as fromUser from '@redux/user'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store$: Store<any>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const publicRoute = Boolean(route.data['publicRoute'])

    // Set token after successful OAuth
    const accessTokenMatches =
      typeof route.fragment === 'string' && route.fragment.match(/^hotbot-token=(.+)$/)
    if (accessTokenMatches) {
      const accessToken = accessTokenMatches[1]
      this.store$.dispatch(new fromUser.Login({ accessToken }))
      this.router.navigate(['/login'], { fragment: '', replaceUrl: true })
      return false
    }

    if (publicRoute !== this.authService.isAuthorized()) {
      return true
    } else {
      if (this.authService.isAuthorized()) {
        this.router.navigate([''])
      } else {
        this.router.navigate(['/login'])
      }
      return false
    }
  }
}
