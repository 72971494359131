import { Action } from '@ngrx/store'
import { NormalizedResult, Shop } from '@models'
import { LanguageType } from '@shared/constants'

export enum ShopActionTypes {
  Load = '[Shop] Load',
  LoadSuccess = '[Shop] Load Success',
  LoadFail = '[Shop] Load Fail',

  Update = '[Shop] Update',
  UpdateSuccess = '[Shop] Update Success',
  UpdateFail = '[Shop] Update Fail',
}

/**
 * Load Shop actions
 */

export interface LoadPayload {
  propertyId: string
}

export class Load implements Action {
  readonly type = ShopActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = ShopActionTypes.LoadSuccess
  constructor(public payload, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ShopActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Update Shop actions
 */
export interface UpdatePayload {
  propertyId: string
  shopId: string
  shop: Shop
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
  forceTranslate: boolean
}

export class Update implements Action {
  readonly type = ShopActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = ShopActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = ShopActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

export type ShopActions = Load | LoadSuccess | LoadFail | Update | UpdateSuccess | UpdateFail
