import { Component } from '@angular/core'

@Component({
  selector: 'hot-app-footer',
  template: `
    <div class="footer-spacer h3 w-100"></div>
    <div class="bg-white fixed bottom-0 right-0 left--sidebar-width shadow-1 z-1">
      <div class="ph4 pv3">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class AppFooterComponent {}
