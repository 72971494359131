interface Action {
  type: string
  payload?: any
  error?: any
}

export interface LoadListState {
  ids: string[]
  loaded: boolean
  loading: boolean
  meta?: any
}

export const loadListState = {
  ids: [],
  loaded: false,
  loading: false,
}

export function loading<S extends { loading: boolean }, A extends Action>(state: S, _action: A) {
  return {
    ...(state as any),
    loading: true,
  }
}

export function loadSuccess<S extends LoadListState, A extends Action>(state: S, action: A) {
  return {
    ...(state as any),
    ids: action.payload.results,
    loading: false,
    loaded: true,
    meta: action.payload.meta || state.meta,
  }
}

export function loadFail<S extends LoadListState, A extends Action>(state: S, _action: A) {
  return { ...(state as any), loading: false, loaded: true }
}

/**
 * Creates Reducer and Selectors for loading list
 * @param actionTypes Actions Enum
 * @param initialState Initial State
 */
export function createListLoader<S extends LoadListState>(
  actionTypes: {
    Load: string
    LoadSuccess: string
    LoadFail: string
  },
  initialState: S = loadListState as S
) {
  const reducer = (state: S = initialState, action: Action): S => {
    switch (action.type) {
      case actionTypes.Load: {
        return loading(state, action)
      }
      case actionTypes.LoadSuccess: {
        return loadSuccess(state, action)
      }
      case actionTypes.LoadFail: {
        return loadFail(state, action)
      }
    }
    return state
  }

  const selectIds = (substate: S) => substate && substate.ids
  const selectLoading = (substate: S) => substate && substate.loading
  const selectLoaded = (substate: S) => substate && substate.loaded
  const selectMeta = (substate: S) => substate && substate.meta

  return { reducer, selectIds, selectLoading, selectLoaded, selectMeta, initialState }
}

type Reducer<S, A> = (state: S, action: A) => S
export function composeReducers<S, A>(reducers: Array<Reducer<S, A>>) {
  return (state: S, action: A): S => {
    return reducers.reduceRight((acc, fn) => fn(acc, action), state)
  }
}
