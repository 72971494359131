import { omit } from 'ramda'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import * as fromMenuPagesTemplatesActions from './menu-pages-templates.actions'
import { MenuPagesTemplatesService } from './menu-pages-templates.service'

const CREATE_ERROR = 'При создании шаблона возникла ошибка'
const LOAD_ERROR = 'При загрузке информации о шаблонах возникла ошибка'
const UPDATE_SUCCESS = 'Данные были успешно обновлены'
const UPDATE_FAIL = 'При обновлении шаблона возникла ошибка'
const DELETE_SUCCESS = 'Шаблон успешно удален'
const DELETE_ERROR = 'При удалении шаблона возникла ошибка'

@Injectable()
export class MenuPagesTemplatesEffects {
  /**
   * Create Templates effects
   */
  @Effect()
  public create = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.Create),
    switchMap((action: fromMenuPagesTemplatesActions.Create) => {
      return this.templatesService.create(action.payload.languages).pipe(
        map(x => {
          return new fromMenuPagesTemplatesActions.CreateSuccess(x, action.payload)
        }),
        catchError(x => of(new fromMenuPagesTemplatesActions.CreateFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.CreateFail),
    tap(() => {
      this.matSnackBar.open(CREATE_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load Templates effects
   */
  @Effect()
  public load$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.Load),
    switchMap((action: fromMenuPagesTemplatesActions.Load) => {
      return this.templatesService
        .get()
        .pipe(
          map(x => new fromMenuPagesTemplatesActions.LoadSuccess(x, action.payload)),
          catchError(x => of(new fromMenuPagesTemplatesActions.LoadFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.LoadFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Update Templates effects
   */
  @Effect()
  public update$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.Update),
    switchMap((action: fromMenuPagesTemplatesActions.Update) => {
      return this.templatesService
        .update(action.payload.menuPageId, omit(['menuPageId'], action.payload))
        .pipe(
          map(x => new fromMenuPagesTemplatesActions.UpdateSuccess(x, action.payload)),
          catchError(x => of(new fromMenuPagesTemplatesActions.UpdateFail(x, action.payload)))
        )
    })
  )

  /**
   * Update Templates effects
   */
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.Delete),
    switchMap((action: fromMenuPagesTemplatesActions.Delete) => {
      const templateId = action.payload
      return this.templatesService
        .delete(templateId)
        .pipe(
          map(() => new fromMenuPagesTemplatesActions.DeleteSuccess(templateId)),
          catchError(x => of(new fromMenuPagesTemplatesActions.DeleteFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.UpdateSuccess),
    tap(_ => {
      this.matSnackBar.open(UPDATE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.UpdateFail),
    tap(() => {
      this.matSnackBar.open(UPDATE_FAIL, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.DeleteSuccess),
    tap(() => {
      this.matSnackBar.open(DELETE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(fromMenuPagesTemplatesActions.MenuPagesTemplatesActionTypes.DeleteFail),
    tap(() => {
      this.matSnackBar.open(DELETE_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  constructor(
    private actions$: Actions,
    private templatesService: MenuPagesTemplatesService,
    private matSnackBar: MatSnackBar
  ) {}
}
