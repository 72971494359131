import * as R from 'ramda'
import { ShopProductActionTypes } from './shop-product.actions'
import { ShopActionTypes } from '../shop/shop.actions'
import { ShopProduct } from '@models'

export interface Substate {
  items: ShopProduct[]
  loaded: boolean
  loading: boolean
}

export interface SubstateMap {
  [key: string]: Substate
}

export interface State {
  ids: string[]
  loaded: boolean
  loading: boolean
  byPropertyId: SubstateMap
}

export const initialSubstate: Substate = {
  items: [],
  loaded: false,
  loading: false,
}

export const initialState: State = {
  ids: [],
  loaded: false,
  loading: false,
  byPropertyId: {},
}

export function substateReducer(state = initialSubstate, action): Substate {
  switch (action.type) {
    case ShopActionTypes.LoadSuccess: {
      return {
        ...state,
        items: action.payload.shopProducts.map(R.prop('_id')),
      }
    }
    case ShopProductActionTypes.CreateSuccess: {
      return {
        ...state,
        items: R.uniq([...state.items, ...action.payload.shopProducts.map(R.prop('_id'))]),
      }
    }
    case ShopProductActionTypes.DeleteSuccess: {
      return {
        ...state,
        items: R.without([action.meta.shopProductId], state.items),
      }
    }
  }
}

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case ShopActionTypes.LoadSuccess:
    case ShopProductActionTypes.CreateSuccess:
    case ShopProductActionTypes.DeleteSuccess: {
      const propertyId = action.meta.propertyId
      return {
        ...state,
        ids: action.payload.shopProducts
          ? R.uniq([...state.ids, ...action.payload.shopProducts.map(R.prop('_id'))])
          : action.payload.deleted
          ? R.without([action.meta.shopProductId], state.ids)
          : state.ids,
        byPropertyId: {
          ...state.byPropertyId,
          [propertyId]: substateReducer(state.byPropertyId[propertyId], action),
        },
      }
    }
    default: {
      return state
    }
  }
}

export const selectSubstates = (state: State) => state && state.byPropertyId
export const selectPropertySubstateShopProductIds = (state: Substate) => state && state.items
