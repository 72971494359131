import { AbstractControl } from '@angular/forms'

const spaceRegExp = /\s/
export const hasSpaceValidator = (control: AbstractControl) => {
  if (control && control.value && spaceRegExp.test(control.value.trim())) {
    return {
      hasSpace: true,
    }
  }
  return null
}
