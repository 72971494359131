<div class="hot-search" [formGroup]="form">
  <input
    type="search"
    autocomplete="off"
    class="hot-search-input"
    [placeholder]="'common.search' | translate: locale.language"
    [maxlength]="40"
    formControlName="Query"
    spellcheck="false"
    matInput
    [style.background]="background || '#fff'"
    (keydown.esc)="reset()"
  />
  <mat-icon *ngIf="!form.get('Query').value" class="hot-search-input-icon">search</mat-icon>
  <mat-icon
    *ngIf="form.get('Query').value"
    class="hot-search-input-icon hot-search-input-icon-reset"
    (click)="reset()"
    >close</mat-icon
  >
</div>
