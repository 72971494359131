type ObjectId = string

export enum WifiAuthType {
  PhoneSms = 'phone_sms',
  PhoneCall = 'phone_call',
  Epitome = 'epitome',
  Logus = 'logus',
  OneCHotel = 'onechotel',
  Shelter = 'shelter',
  Password = 'password',
  Voucher = 'voucher',
}

export interface CaptivePortalTranslations {
  default: object
  en: object
  ru: object
  zh: object
}

export interface CaptivePortalStyle {
  contentTextColor?: string
  contentBackgroundColor?: string
  backgroundColor?: string
  backgroundUrl?: string
  backgroundPosition?: string
  logoUrl?: string
  logoSvg?: string
}

export interface CaptivePortal {
  _id: string
  property: ObjectId
  name: string
  alias: string
  template: string
  languages: string[]
  css?: string
  style?: CaptivePortalStyle
  translations?: CaptivePortalTranslations
}

export interface Hotspot {
  _id: string
  captivePortal: ObjectId
  name: string
  number: number
  alias: string
  authMethods: any[]
  status: string
}

export interface WifiClient {
  _id: string
  mac: string
  createdAt: string
  updatedAt: string
  phoneNumber: string
  phoneConfirmed: boolean
}

export interface WifiClientAuthCredentials {
  voucher?: any
  name?: any
  bedNumber?: any
  roomNumber?: any
  password?: any
  [key: string]: any
}

export interface WifiClientAuth {
  _id: string
  hotspot: ObjectId
  wificlient: ObjectId
  authType: WifiAuthType
  authCredentials: WifiClientAuthCredentials
}

export interface WifiVoucher {
  readonly createdAt: string
  readonly updatedAt: string
  bookingCode: string
  firstName: string
  lastName: string
  captivePortal: ObjectId
  code: string
  validThrough: Date
}

export interface WifiSession {
  _id: string
  startAt: string
  stopAt: string
  hotspot: ObjectId
  wificlient: ObjectId
  wifiClientAuth: ObjectId
  mac: string
  ip: string
  inputBytes: number
  outputBytes: number
  sessionTime: number
}
