// tslint:disable:max-line-length
import { L10nTranslationModule } from 'angular-l10n'
import { SortablejsModule } from 'ngx-sortablejs'
import { ImageCropperModule } from 'ngx-image-cropper'
import { PickerModule } from '@ctrl/ngx-emoji-mart'
import { ChartsModule } from 'ng2-charts'

import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { RouterModule } from '@angular/router'

import { AppHeaderComponent } from './components/app-header/app-header.component'
import { AppFooterComponent } from './components/app-footer/app-footer.component'
import { AppLanguageSelectorComponent } from './components/app-language-selector/app-language-selector.component'
import { BuilderButtonEditDialogComponent } from './components/builder-buttons/builder-button-edit-dialog/builder-button-edit-dialog.component'
import { BuilderButtonEditorComponent } from './components/builder-buttons/builder-button-editor/builder-button-editor.component'
import { BuilderButtonsComponent } from './components/builder-buttons/builder-buttons.component'
import { BuilderImagesComponent } from './components/builder-page/builder-images/builder-images.component'
import { BuilderFacilitiesGroupsComponent } from './components/builder-page/builder-facilities-groups/builder-facilities-groups.component'
import { BuilderNameComponent } from './components/builder-page/builder-name/builder-name.component'
import { BuilderPageComponent } from './components/builder-page/builder-page.component'
import { BuilderTextsComponent } from './components/builder-page/builder-texts/builder-texts.component'
import { BuilderTrackingComponent } from './components/builder-page/builder-tracking/builder-tracking.component'
import { BuilderTreeComponent } from './components/builder-page/builder-tree/builder-tree.component'
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component'
import { CreateButtonComponent } from './components/create-button/create-button.component'
import { DndAreaComponent } from './components/dnd-area/dnd-area.component'
import { DndListComponent } from './components/dnd-list/dnd-list.component'
import { WorkingHoursComponent } from './components/working-hours/working-hours.component'
import { EmojiPickerComponent } from './components/emoji-picker/emoji-picker.component'
import { FeeInputComponent } from './components/fee-input/fee-input.component'
import { FilterChipsComponent } from './components/filter-chips/filter-chips.component'
import { FormConfirmationDialogComponent } from './components/form-confirmation-dialog/form-confirmation-dialog.component'
import { FormEditorFieldComponent } from './components/form-editor-field/form-editor-field.component'
import { FormEditorComponent } from './components/form-editor/form-editor.component'
import { FormFieldActionComponent } from './components/form-field-action/form-field-action.component'
import { FormFieldCommentComponent } from './components/form-field-comment/form-field-comment.component'
import { FormFieldTextComponent } from './components/form-field-text/form-field-text.component'
import { FormFieldAddressComponent } from './components/form-field-address/form-field-address.component'
import { FormFieldNumberComponent } from './components/form-field-number/form-field-number.component'
import { FormFieldDateComponent } from './components/form-field-date/form-field-date.component'
import { FormFieldOptionsComponent } from './components/form-field-options/form-field-options.component'
import { FormFieldAutofillComponent } from './components/form-field-autofill/form-field-autofill.component'
import { FormFieldPreviewComponent } from './components/form-field-preview/form-field-preview.component'
import { FormFieldsComponent } from './components/form-fields/form-fields.component'
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component'
import { LangSelectorComponent } from './components/lang-selector/lang-selector.component'
import { PageHeadingComponent } from './components/page-heading/page-heading.component'
import { PageContentComponent } from './components/page-content/page-content.component'
import { PageSubheadingComponent } from './components/page-subheading/page-subheading.component'
import { PropertyFormComponent } from './components/property-form/property-form.component'
import { StaffUserFormComponent } from './components/staff-user-form/staff-user-form.component'
import { ShopProductCardComponent } from './components/shop-product-card/shop-product-card.component'
import { ShopStatisticsComponent } from './components/shop-statistics/shop-statistics.component'
import { ShopEntitySelectorComponent } from './components/shop-entity-selector/shop-entity-selector.component'
import { ShopProductCategoryCardComponent } from './components/shop-product-category-card/shop-product-category-card.component'
import { PropertyLogoComponent } from './components/property-logo/property-logo.component'
import { RequestsListComponent } from './components/requests-list/requests-list.component'
import { CartsListComponent } from './components/carts-list/carts-list.component'
import { RestaurantCuisineAutocompleteComponent } from './components/restaurant-cuisine-autocomplete/restaurant-cuisine-autocomplete.component'
import { RestaurantFormComponent } from './components/restaurant-form/restaurant-form.component'
import { RoutingDisplayComponent } from './components/routing-display/routing-display.component'
import { TimelineEventPhotoDialogComponent } from './components/timeline/timeline-event-photo-dialog/timeline-event-photo-dialog.component'
import { TimelineComponent } from './components/timeline/timeline.component'
import { UploadFileComponent } from './components/upload-file/upload-file.component'
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component'
import { StatsChartComponent } from './components/stats-chart/stats-chart.component'
import { ManagerNotificationsComponent } from './components/manager-notifications/manager-notifications.component'
import { MessengerNotificationHintComponent } from './components/messenger-notification-hint/messenger-notification-hint.component'
import { QRCodeDialogComponent } from './components/messenger-notification-hint/qrcode-dialog/qrcode-dialog.component'
import { SearchInputComponent } from './components/search-input/search-input.component'
import { SalesServiceSettingsComponent } from './components/sales-service-settings-form/sales-service-settings.component'

import { AutofocusDirective } from './directives/autofocus.directive'
import { ProxySrcDirective } from './directives/proxy-src.directive'
import { DndBlockDirective } from './directives/dnd-block.directive'
import { MatIconDirective } from './directives/mat-icon.directive'
import { ThemeDirective, ThemeContext } from './directives/theme.directive'
import { TranslatableFieldDirective } from './directives/translatable-field.directive'
import { TranslatableTextDirective } from './directives/translatable-text.directive'
import { HotCurrencyDirective } from './directives/hot-currency.directive'
import { HotPercentsDirective } from './directives/hot-percents.directive'
import { CustomMatPaginatorIntl } from './overrides/custom-paginator-intl'
import { MaterialModule } from './material.module'

import {
  AbbreviationPipe,
  ChatNamePipe,
  ChatTypePipe,
  DeclensionOfNum,
  FullNamePipe,
  LocaleLabelPipe,
  PushMessageStatusPipe,
  PushSendTypePipe,
  RequestStatusPipe,
  CartStatusPipe,
  ReviewStatusPipe,
  ReservationStatusPipe,
  SegmentTypeLabelPipe,
  SelectLanguagePipe,
  TimeAgoPipe,
  TimelineEventStatusPipe,
  VisibilityPeriodStatePipe,
  BaseCurrencyUnit,
  PercentUnit,
} from './pipes'
import {
  ApiService,
  AppTitleService,
  AuthInterceptor,
  AuthService,
  I18nService,
  DialogFlowService,
  FacebookService,
  PubSubService,
  UidService,
  UploadFileService,
  WindowRefService,
  ReportsService,
} from './services'

import { HotDatepickerModule } from './components/date-range-picker/datepicker-module'

const SHARED_DIRECTIVES = [
  AutofocusDirective,
  MatIconDirective,
  TranslatableFieldDirective,
  TranslatableTextDirective,
  HotCurrencyDirective,
  HotPercentsDirective,
  ProxySrcDirective,
  DndBlockDirective,
  ThemeDirective,
]

const SHARED_PIPES = [
  AbbreviationPipe,
  ChatNamePipe,
  FullNamePipe,
  LocaleLabelPipe,
  RequestStatusPipe,
  CartStatusPipe,
  PushMessageStatusPipe,
  ChatTypePipe,
  DeclensionOfNum,
  SegmentTypeLabelPipe,
  SelectLanguagePipe,
  ReviewStatusPipe,
  ReservationStatusPipe,
  TimeAgoPipe,
  TimelineEventStatusPipe,
  PushSendTypePipe,
  VisibilityPeriodStatePipe,
  BaseCurrencyUnit,
  PercentUnit,
]
const SHARED_COMPONENTS = [
  AppHeaderComponent,
  AppFooterComponent,
  AppLanguageSelectorComponent,
  CreateButtonComponent,
  PageHeadingComponent,
  PageContentComponent,
  PageSubheadingComponent,
  UploadFileComponent,
  FilterChipsComponent,
  RequestsListComponent,
  CartsListComponent,
  FormConfirmationDialogComponent,
  FormFieldsComponent,
  FormEditorComponent,
  FormEditorFieldComponent,
  FormFieldActionComponent,
  FormFieldDateComponent,
  FormFieldCommentComponent,
  FormFieldTextComponent,
  FormFieldAddressComponent,
  FormFieldNumberComponent,
  FormFieldPreviewComponent,
  FormFieldOptionsComponent,
  FormFieldAutofillComponent,
  LangSelectorComponent,
  TimelineComponent,
  TimelineEventPhotoDialogComponent,
  BuilderButtonsComponent,
  PropertyFormComponent,
  StaffUserFormComponent,
  ShopProductCardComponent,
  ShopStatisticsComponent,
  ShopEntitySelectorComponent,
  ShopProductCategoryCardComponent,
  RestaurantFormComponent,
  BuilderPageComponent,
  BuilderButtonEditDialogComponent,
  BuilderButtonEditorComponent,
  BuilderImagesComponent,
  BuilderNameComponent,
  BuilderTextsComponent,
  BuilderTrackingComponent,
  BuilderFacilitiesGroupsComponent,
  BuilderTreeComponent,
  ConfirmationDialogComponent,
  RoutingDisplayComponent,
  PropertyLogoComponent,
  RestaurantCuisineAutocompleteComponent,
  ImageCropperComponent,
  DndAreaComponent,
  DndListComponent,
  WorkingHoursComponent,
  EmojiPickerComponent,
  FeeInputComponent,
  UserAvatarComponent,
  StatsChartComponent,
  SalesServiceSettingsComponent,
  ManagerNotificationsComponent,
  MessengerNotificationHintComponent,
  QRCodeDialogComponent,
  SearchInputComponent,
  SalesServiceSettingsComponent,
]

const SHARED_DECLARATIONS = [...SHARED_DIRECTIVES, ...SHARED_COMPONENTS, ...SHARED_PIPES]

const SHARED_MODULES = [
  ChartsModule,
  CommonModule,
  ReactiveFormsModule,
  MaterialModule,
  SortablejsModule,
  ImageCropperModule,
  L10nTranslationModule,
  PickerModule,
  HotDatepickerModule,
]

@NgModule({
  imports: [...SHARED_MODULES, HttpClientModule, RouterModule, FormsModule],
  declarations: [...SHARED_DECLARATIONS],
  providers: [
    I18nService,
    ApiService,
    AppTitleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
      deps: [I18nService],
    },
    AuthService,
    DialogFlowService,
    FacebookService,
    WindowRefService,
    PubSubService,
    UidService,
    UploadFileService,
    ReportsService,
    ThemeContext,
    ...SHARED_PIPES,
  ],
  entryComponents: [],
  exports: [...SHARED_MODULES, ...SHARED_DECLARATIONS],
})
export class SharedModule {}
