import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core'
import { UploadFileService } from '@shared/services/upload-file.service'
import { Store, select } from '@ngrx/store'
import * as fromRoot from '@redux'
import { filter, take } from 'rxjs/operators'
import { UploadFile, UploadFileStatus, UploadFileResult } from '@models'
import * as R from 'ramda'
import { IMAGE_EXTENSIONS } from '@shared/constants'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { I18nService } from '@shared/services'

const BYTES_IN_KB = 1024
const DEFAULT_FILE_SIZE_LIMIT_MB = 10
const DEFAULT_IMAGE_WIDTH = 100
const DEFAULT_PATH_TYPE = 'images'
const FILE_NOT_UPLOADED_CAPTION = 'components.UploadFile.uploadFile'

@Component({
  selector: 'hot-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnChanges {
  @ViewChild('fileInput', { static: true }) public fileInput: ElementRef
  @Input() public type = DEFAULT_PATH_TYPE
  /**
   * Image url for display already uploaded image
   */
  @Input()
  public set fileUrl(value: string) {
    if (value) {
      this.uploadStatus = UploadFileStatus.COMPLETED
      this.uploadedFilePath = value
      this.uploadedFileName = value.replace(/^.*[\\\/]/, '')
    }
  }
  @Input() public forceUseProxy = null
  @Input() public extensions: { [key: string]: string } = IMAGE_EXTENSIONS
  @Input() public previewImageWidth: number | string = DEFAULT_IMAGE_WIDTH
  @Input() public fileSizeLimit = DEFAULT_FILE_SIZE_LIMIT_MB
  @Input()
  public set placeholder(placeholder: string) {
    this._placeholder = placeholder
  }
  @Input() public showIcon = true
  @Input() public showClose = true
  // This variable need for using upload component with other loader component and prevent default behaviour (Image Cropper etc.)
  @Input() public preventClick = false
  @Output() public change = new EventEmitter<UploadFileResult>()

  public uploadedFilePath: string
  public uploadedFileName: string

  private uploadStatus = null
  private propertyId: string
  private errorMessage: string
  private _placeholder: string

  public constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private uploadFileService: UploadFileService,
    private store$: Store<fromRoot.State>,
    private translationService: I18nService
  ) {}

  public ngOnInit(): void {
    this.store$
      .pipe(
        select(fromRoot.selectCurrentPropertyId),
        filter(x => x !== undefined),
        take(1)
      )
      .subscribe(x => (this.propertyId = x))
  }

  public ngOnChanges(changes: any): void {
    if (changes.forceUseProxy) {
      this.forceUseProxy = changes.forceUseProxy.currentValue
    }
  }

  public get acceptedExtensions(): string {
    return R.reduce(
      (acc, value) => {
        return acc.concat(`,${value}`)
      },
      this.transformedExtension[0] || '',
      R.remove(0, 1, this.transformedExtension)
    )
  }

  public get transformedExtension(): string[] {
    return R.values(this.extensions).map(e => `.${e}`)
  }

  public get imageWidth(): string {
    return typeof this.previewImageWidth === 'number'
      ? this.previewImageWidth + 'px'
      : this.previewImageWidth
  }

  public get placeholder(): string {
    return this._placeholder || FILE_NOT_UPLOADED_CAPTION
  }

  public get error(): string {
    return this.errorMessage
  }

  public get imageUploaded(): boolean {
    return (
      this.uploadedFilePath && IMAGE_EXTENSIONS[R.toUpper(this.uploadedFilePath.split('.').pop())]
    )
  }

  public get pending(): boolean {
    return this.uploadStatus && this.uploadStatus === UploadFileStatus.PENDING
  }

  public get uploaded(): boolean {
    return this.uploadStatus && this.uploadStatus === UploadFileStatus.COMPLETED
  }

  public get failed(): boolean {
    return this.uploadStatus && this.uploadStatus === UploadFileStatus.FAILED
  }

  public uploadFile(file) {
    const fileExtension = file.name.split('.').pop()
    const sizeInMB = file.size / (BYTES_IN_KB * BYTES_IN_KB)
    if (sizeInMB > this.fileSizeLimit) {
      this.errorUploadFile(this.getFileSizeLimitErrorMessage(this.fileSizeLimit))
    } else if (fileExtension && !this.extensions[R.toUpper(fileExtension)]) {
      this.errorUploadFile(this.invalidExtensionErrorMessage)
    } else {
      this.uploadStatus = UploadFileStatus.PENDING
      this.change.next({ status: UploadFileStatus.PENDING })
      this.uploadFileService.upload(file, `${this.propertyId}/${this.type}`).subscribe(
        (x: UploadFile) => {
          this.uploadStatus = UploadFileStatus.COMPLETED
          this.uploadedFileName = file.name
          this.uploadedFilePath = x.url
          this.change.next({ status: UploadFileStatus.COMPLETED, data: x })
        },
        _ => {
          const errText = this.translationService.translate('components.UploadFile.uploadingError')
          this.errorUploadFile(errText)
        }
      )
    }
  }

  public onFileUpload($event): void {
    if ($event && $event.target.files) {
      const fileToUpload = $event.target.files[0]
      if (fileToUpload) {
        return this.uploadFile(fileToUpload)
      }
    }
    return this.cancelUploadFile()
  }

  public openFileDialog(): void {
    if (!this.preventClick) {
      this.fileInput.nativeElement.click()
    }
  }

  public cancelUploadFile(event?: Event): void {
    if (event) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
    this.fileInput.nativeElement.value = ''
    this.uploadedFileName = null
    this.uploadedFilePath = null
    this.uploadStatus = UploadFileStatus.CANCELLED_BY_USER
    this.change.next({ status: UploadFileStatus.CANCELLED_BY_USER })
  }

  private getFileSizeLimitErrorMessage(size: number): string {
    return `Максимальный размер загружаемого файла: ${size} MB`
  }

  private get invalidExtensionErrorMessage(): string {
    const extensions = R.keys(this.extensions).join(', ')
    return this.translationService.translate('components.UploadFile.extensions', { extensions })
  }

  private errorUploadFile(errorMessage: string): void {
    this.errorMessage = errorMessage
    this.uploadStatus = UploadFileStatus.FAILED
    this.change.next({ status: UploadFileStatus.FAILED })
  }
}
