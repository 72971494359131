import { PushActions, PushActionType } from './push.actions'
import { PushListFilters, DEFAULT_PAGINATION_SIZE } from '@models'

export interface Substate {
  ids: string[]
  loading: boolean
  loaded: boolean
  creating?: boolean
  filters: PushListFilters
  meta?: {
    totalRecords?: number
  }
}

export interface State {
  selectedId: string | null
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialSubstate: Substate = {
  ids: [],
  loading: false,
  loaded: false,
  creating: false,
  filters: {
    status: null,
    paginationOptions: {
      page: 0,
      limit: DEFAULT_PAGINATION_SIZE,
    },
  },
  meta: {
    totalRecords: 0,
  },
}

export const initialState: State = {
  selectedId: null,
  byPropertyId: {},
}

export const addUniqueId = (id: string, ids: string[]): string[] => {
  return ids.indexOf(id) === -1 ? [id, ...ids] : ids
}

export function substateReducer(state = initialSubstate, action: PushActions): Substate {
  switch (action.type) {
    case PushActionType.Load: {
      return {
        ids: [],
        loaded: false,
        loading: true,
        filters: {
          status: action.payload.status,
          paginationOptions: {
            page: action.payload.page,
            limit: action.payload.limit,
          },
        },
      }
    }
    case PushActionType.LoadFail: {
      return {
        ids: [],
        loaded: false,
        loading: false,
        filters: {
          status: action.meta.status,
          paginationOptions: {
            page: 0,
            limit: 0,
          },
        },
      }
    }
    case PushActionType.LoadSuccess: {
      return {
        ...state,
        ids: action.payload.results,
        meta: action.payload.meta,
        loaded: true,
        loading: false,
      }
    }

    case PushActionType.Create:
    case PushActionType.Update: {
      return {
        ...state,
        creating: true,
      }
    }

    case PushActionType.CreateFail:
    case PushActionType.UpdateFail:
    case PushActionType.UpdateSuccess: {
      return {
        ...state,
        creating: false,
      }
    }

    case PushActionType.CreateSuccess: {
      return {
        ...state,
        creating: false,
        ids: addUniqueId(action.payload.result, state.ids),
      }
    }
    default:
      return state
  }
}

export function reducer(state = initialState, action: PushActions): State {
  switch (action.type) {
    case PushActionType.Load:
    case PushActionType.LoadSuccess:
    case PushActionType.LoadFail:
    case PushActionType.Create:
    case PushActionType.CreateFail:
    case PushActionType.CreateSuccess:
    case PushActionType.Update:
    case PushActionType.UpdateFail:
    case PushActionType.UpdateSuccess: {
      const propertyId =
        action.type === PushActionType.Create ||
        action.type === PushActionType.Load ||
        action.type === PushActionType.Update
          ? action.payload.propertyId
          : action.meta.propertyId
      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [propertyId]: substateReducer(state.byPropertyId[propertyId], action),
        },
      }
    }
    case PushActionType.Select: {
      return {
        ...state,
        selectedId: action.payload.pushMessageId,
      }
    }
    default:
      return state
  }
}

export const selectCurrentPushId = (state: State) => state.selectedId
export const selectSubstates = (state: State) => state.byPropertyId
export const selectSubstateIds = (state: Substate) => state && state.ids
export const selectSubstateLoading = (state: Substate) => state && state.loading
export const selectSubstateLoaded = (state: Substate) => state && state.loaded
export const selectSubstateCreating = (state: Substate) => state && state.creating
export const selectSubstateFilters = (state: Substate) => state && state.filters
export const selectSubstateTotalRecords = (substate: Substate) =>
  substate && substate.meta ? substate.meta.totalRecords : 0
