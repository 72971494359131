import { AbstractControl } from '@angular/forms'

const urlRegexp = /^(https?:\/\/)?[\da-zа-я\.-]{1,256}\.[a-zа-я\.]{2,6}([\da-zа-я\.@:%_\+.~#?&//=]*)/i

export const urlValidator = (control: AbstractControl) => {
  if (control && control.value && !control.value.match(urlRegexp)) {
    return {
      url: true,
    }
  }
  return null
}
