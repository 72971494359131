import * as R from 'ramda'
import { ShopActionTypes } from './shop.actions'
import { ActionTypes as ShopCartActionTypes } from '../shop-cart/shop-cart.actions'
import { Shop } from '@models'

export interface Substate {
  items: Shop[]
  loaded: boolean
  loading: boolean
}

export interface SubstateMap {
  [key: string]: Substate
}

export interface State {
  ids: string[]
  loaded: boolean
  loading: boolean
  byPropertyId: SubstateMap
}

export const initialSubstate: Substate = {
  items: [],
  loaded: false,
  loading: false,
}

export const initialState: State = {
  ids: [],
  loaded: false,
  loading: false,
  byPropertyId: {},
}

export function substateReducer(state = initialSubstate, action): Substate {
  switch (action.type) {
    case ShopCartActionTypes.LoadItemSuccess:
    case ShopCartActionTypes.LoadSuccess:
    case ShopActionTypes.LoadSuccess: {
      return {
        ...state,
        items: R.uniq([...state.items, ...action.payload.shops.map(R.prop('_id'))]),
      }
    }
  }
}

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case ShopCartActionTypes.LoadItemSuccess:
    case ShopCartActionTypes.LoadSuccess:
    case ShopActionTypes.LoadSuccess: {
      const propertyId = action.meta.propertyId
      return {
        ...state,
        ids: R.uniq([...state.ids, ...action.payload.shops.map(R.prop('_id'))]),
        byPropertyId: {
          ...state.byPropertyId,
          [propertyId]: substateReducer(state.byPropertyId[propertyId], action),
        },
      }
    }
    default: {
      return state
    }
  }
}

export const selectSubstates = (state: State) => state && state.byPropertyId
export const selectPropertySubstateShopIds = (state: Substate) => state && state.items
