import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Inject,
} from '@angular/core'
import { Builder, MenuButtonType, MenuContentType } from '@models'
import * as R from 'ramda'
import { ActivatedRoute, Router } from '@angular/router'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'hot-builder-tree',
  templateUrl: './builder-tree.component.html',
  styleUrls: ['./builder-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuilderTreeComponent implements OnInit, OnChanges {
  @Input() showOrphans = false
  @Input() builders: Builder[]
  @Input() rootId: string
  @Input() pageId: string
  @Input() currentLanguage: string
  @Output() deletePage = new EventEmitter<Builder>()

  public tree: {} = {}
  public ids: {} = {}
  public alreadyBuilt: Record<string, boolean>
  public orphans: any[] = []

  public builderRootUrl = './'

  public ngOnInit() {
    this.scrollToFirstActive()
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) {}

  ngOnChanges(changes) {
    this.alreadyBuilt = {}
    this.initTree()
    const { params } = this.route.snapshot
    const path = this.router.url.split('?')[0]
    const urlArray = path.split('/')
    this.builderRootUrl = params.pageId ? urlArray.slice(0, -1).join('/') : urlArray.join('/')
    if (changes.pageId) {
      this.scrollToFirstActive()
    }
  }

  handleDeletePage(pageId) {
    const page = this.builders.find(x => x.pageId === pageId)
    this.deletePage.next(page)
  }

  markAsBuilt(pageId) {
    this.alreadyBuilt[pageId] = true
  }

  isBuilt(pageId) {
    return Boolean(this.alreadyBuilt[pageId])
  }

  public initTree() {
    const rootPage = R.find(R.propEq('pageId', this.rootId))(this.builders)
    const rootName = rootPage.name[this.currentLanguage] || rootPage.name.default
    const rootPages = this.getPageChildrens(rootPage.content)
    this.markAsBuilt(this.rootId)
    this.tree[this.rootId] = {
      id: this.rootId,
      title: rootName,
      children: rootPages,
    }

    this.generateChildrens(rootPages)
    this.makeSubpages(rootPages)

    this.orphans = this.builders
      .filter(x => !this.isBuilt(x.pageId))
      .map(x => {
        return {
          id: x.pageId,
          type: 'page',
          config: {
            pageId: x.pageId,
          },
          orphan: true,
          title: (x.name[this.currentLanguage] || x.name.default) as string,
          children: [],
          isTracking: x.tracking.length > 0,
        }
      })
      .sort((a, b) => a.title.localeCompare(b.title))
  }

  public makeSubpages(pages) {
    pages.forEach(page => {
      if (page.children && page.children.length) {
        this.generateChildrens(page.children)
      }
    })

    pages.forEach(newPage => {
      if (newPage.children && newPage.children.length) {
        this.makeSubpages(newPage.children)
      }
    })
  }

  public generateChildrens(array) {
    array.forEach(page => {
      const id = page.id
      if (id && !this.isBuilt(id)) {
        const currentPage = R.find(x => x && x.pageId === id, this.builders)
        if (currentPage) {
          const childrens = this.getPageChildrens(currentPage.content)
          this.markAsBuilt(id)
          if (childrens.length) {
            page['children'] = childrens
          }
        }
      }
    })
  }

  public getPageChildrens(pageContent) {
    const translated = x => x.translations[this.currentLanguage] || x.translations.default
    return pageContent
      .filter(v => v.type === MenuContentType.BUTTON)
      .map(v => {
        const type = translated(v).type
        const title = translated(v).title
        const config = translated(v).config
        const res = {
          type,
          title,
          config,
        }
        if (type === MenuButtonType.PAGE) {
          res['id'] = config.pageId
          // @ts-ignore
          res.isTracking = this.builders.some(
            ({ pageId, tracking }) => tracking.length && pageId === config.pageId
          )
        }
        return res
      })
  }

  private scrollToFirstActive() {
    setTimeout(() => this.scrollToFirstActiveUnsafe(), 0)
  }
  private scrollToFirstActiveUnsafe() {
    const container = this.elementRef.nativeElement
    const parent = this.elementRef.nativeElement.parentElement
    const activeLink = container.querySelector('.page-link.active')
    if (activeLink) {
      const paddingTop = parseInt(container.offsetTop, 10)
      const paddingLeft = parseInt(container.offsetLeft, 10)
      const activeLinkTopOffset = activeLink.offsetTop - (paddingTop || 0)
      const activeLinkLeftOffset = activeLink.offsetLeft - (paddingLeft || 0)
      parent.scrollTo(activeLinkLeftOffset, activeLinkTopOffset)
    }
  }

  public navigateToLink(id) {
    const snapshot = this.route.snapshot
    const params = snapshot.params
    const urlArray = this.router.url.split('/')
    if (params.pageId) {
      urlArray.splice(-1, 1)
    }
    const preparedUrl = urlArray.join('/')
    this.router.navigateByUrl(`${preparedUrl}/${id}`)
  }
}
