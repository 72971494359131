import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of, NEVER } from 'rxjs'
import { catchError, map, switchMap, tap, take, exhaustMap } from 'rxjs/operators'

import * as fromRoot from '../reducers'
import * as fromRouter from '../router'
import * as fromBuilderActions from './builder.actions'
import { BuilderService } from './builder.service'
import { Store, select } from '@ngrx/store'
import { I18nService } from '@shared/services'

@Injectable()
export class BuilderEffects {
  /**
   * Create Builders effects
   */
  @Effect()
  public create = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.Create),
    switchMap((action: fromBuilderActions.Create) => {
      return this.builderService
        .create(
          action.payload.propertyId,
          action.payload.menuPage,
          action.payload.translateName,
          action.payload.meta
        )
        .pipe(
          map(x => {
            return new fromBuilderActions.CreateSuccess(x, action.payload)
          }),
          catchError(x => of(new fromBuilderActions.CreateFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.CreateFail),
    tap(() => {
      const createError = this.translationService.translate('MenuBuilder.error.createMenuPageError')
      this.matSnackBar.open(createError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * Load Builders effects
   */
  @Effect()
  public load$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.Load),
    switchMap((action: fromBuilderActions.Load) => {
      return this.builderService.get(action.payload.propertyId, action.payload.pageId).pipe(
        map(x => new fromBuilderActions.LoadSuccess(x, action.payload)),
        catchError(x => of(new fromBuilderActions.LoadFail(x, action.payload)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.LoadFail),
    tap(() => {
      const loadError = this.translationService.translate('MenuBuilder.error.loadMenuPageError')
      this.matSnackBar.open(loadError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  /**
   * LoadAll Builders effects
   */
  @Effect()
  public loadAll$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.LoadAll),
    exhaustMap((action: fromBuilderActions.LoadAll) => {
      return this.builderService.getAll(action.payload.propertyId).pipe(
        map(x => new fromBuilderActions.LoadAllSuccess(x, action.payload)),
        catchError(x => of(new fromBuilderActions.LoadAllFail(x, action.payload)))
      )
    })
  )

  /**
   * Update Builders effects
   */
  @Effect()
  public update$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.Update),
    switchMap((action: fromBuilderActions.Update) => {
      return this.builderService
        .update(action.payload.propertyId, action.payload.pageId, {
          lang: action.payload.lang,
          menuPage: action.payload.menuPage,
          translateName: action.payload.translateName,
        })
        .pipe(
          map(x => new fromBuilderActions.UpdateSuccess(x, action.payload)),
          catchError(x => of(new fromBuilderActions.UpdateFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.UpdateSuccess),
    tap(_ => {
      const updateSuccess = this.translationService.translate(
        'MenuBuilder.notification.updateSuccess'
      )
      this.matSnackBar.open(updateSuccess, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.UpdateFail),
    tap(() => {
      const updateError = this.translationService.translate('MenuBuilder.error.updateMenuPageError')
      this.matSnackBar.open(updateError, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.Delete),
    switchMap((action: fromBuilderActions.Delete) => {
      return this.builderService.delete(action.payload.propertyId, action.payload.pageId).pipe(
        map(x => new fromBuilderActions.DeleteSuccess(x, action.payload)),
        catchError(x => of(new fromBuilderActions.DeleteFail(x, action.payload)))
      )
    })
  )

  @Effect()
  public deleteSuccess$ = this.actions$.pipe(
    ofType(fromBuilderActions.BuilderActionTypes.DeleteSuccess),
    switchMap((action: fromBuilderActions.DeleteSuccess) => {
      return this.store$.pipe(
        select(fromRoot.selectRouterState),
        take(1),
        switchMap(routerState => {
          if (routerState.params.pageId === action.meta.pageId) {
            return of(new fromRouter.Go({ path: routerState.url.split('/').slice(0, -1) }))
          }
          return NEVER
        })
      )
    })
  )

  constructor(
    private readonly store$: Store<any>,
    private actions$: Actions,
    private builderService: BuilderService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
