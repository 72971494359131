import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResults, NormalizedResult } from '@models'

@Injectable()
export class CartService {
  constructor(public readonly apiService: ApiService) {}

  public getList(propertyId: string, params?: any): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/carts`, {
      params: {
        status: params.status || '',
        page: params.page,
        limit: params.limit,
      },
    })
  }

  public get(propertyId: string, cartId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/carts/${cartId}`)
  }

  public updateStatus(
    propertyId: string,
    cartId: string,
    { status, comment }
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/carts/${cartId}`, {
      status: status,
      comment: comment,
    })
  }
}
