import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

import { LanguageType } from '@shared/constants'

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(date: any, lang: LanguageType = LanguageType.RU): any {
    const momentDate = moment(date).locale(lang)
    return momentDate.isValid() ? momentDate.fromNow() : ''
  }
}
