import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, Inject } from '@angular/core'
import { MenuButton, MenuButtonType, MenuContentType } from '@models/builder.model'
import * as R from 'ramda'
import {
  Builder,
  MenuPagesTemplates,
  Form,
  Property,
  FacilityGroup,
  Poll,
  ShopProductCategory,
  ShopProduct,
} from '@models'
import { BuilderButtonEditDialogComponent } from './builder-button-edit-dialog/builder-button-edit-dialog.component'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

const emptyButtonContent = {
  type: null,
  title: '',
  config: {},
}

const emptyButton: MenuButton = {
  type: MenuContentType.BUTTON,
  translations: {},
  isEdit: false,
}
const DEFAULT_MAX_BUTTONS_NUMBER = 0

@Component({
  selector: 'hot-builder-buttons',
  templateUrl: './builder-buttons.component.html',
  styleUrls: ['./builder-buttons.component.scss'],
})
export class BuilderButtonsComponent implements OnChanges {
  @Input() property: Property
  @Input() buttons: MenuButton[]
  @Input() builders: Builder[]
  @Input() forms: Form[]
  @Input() shopCategories: ShopProductCategory[]
  @Input() shopProducts: ShopProduct[]
  @Input() polls: Poll[]
  @Input() templates: MenuPagesTemplates[]
  @Input() modules: string[]
  @Input() public currentLanguage: string
  @Input() public facilitiesGroups: FacilityGroup[] = []
  @Input() public availableLanguages: string[]
  @Input() public maxButtonsAmount = DEFAULT_MAX_BUTTONS_NUMBER
  @Input() public addPageEnabled = true
  @Input() public canSetVisibilityPeriod = false
  @Input() public addButtonForceDisabled = false
  @Input() public enableWifiButtons = false
  @Input() public enableManuallyTranslate = true
  @Input() public isEditMode: boolean
  @Output() public update = new EventEmitter()
  @Output() public remove = new EventEmitter()
  @Output()
  public shift = new EventEmitter<{ firstIndex: number; secondIndex: number; type: string }>()
  @Output() public navigate = new EventEmitter()
  @Output() public createNewPage = new EventEmitter()

  @ViewChild('buttonUrl') public buttonUrl
  @ViewChild('buttonTitle') public buttonTitle
  @ViewChild('addNewButton') public addNewButton

  public cache: MenuButton
  public lastEditIndex: number

  private lastEditDialog: MatDialogRef<BuilderButtonEditDialogComponent>

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private dialog: MatDialog) {}

  public get regularButtons() {
    return this.buttons.filter(x => !x.wifi)
  }

  public get wifiButtons() {
    return this.buttons.filter(x => x.wifi)
  }

  ngOnChanges(changes) {
    if (changes.currentLanguage) {
      this.removeLastEditedInvalidButton()
    }
  }

  handleRemove(index, type = 'buttons', noUpdate = false) {
    if (this.buttons[index] && this.buttons[index].isEdit) {
      this.lastEditIndex = null
    }
    this.remove.emit({ index, type, noUpdate })
  }

  public buttonClick(button) {
    const config = button.translations[this.currentLanguage].config
    const type = button.translations[this.currentLanguage].type
    if (type === MenuButtonType.PAGE) {
      this.navigate.emit(config)
    }

    if (type === MenuButtonType.FORM) {
      this.navigate.emit(config)
    }

    if (type === MenuButtonType.URL) {
      window.open(config.url)
    }
  }

  public handleShift(firstBtnIndex: number, secondBtnIndex: number): void {
    if (this.isEditMode) {
      return
    }
    this.shift.next({ firstIndex: firstBtnIndex, secondIndex: secondBtnIndex, type: 'buttons' })
  }

  public get enableAddButtons(): boolean {
    return this.maxButtonsAmount === 0 ? true : this.regularButtons.length < this.maxButtonsAmount
  }

  public get enableAddWifiButtons(): boolean {
    if (!this.enableWifiButtons) {
      return false
    }
    return this.maxButtonsAmount === 0 ? true : this.wifiButtons.length < this.maxButtonsAmount
  }

  addButtonAtIndex(index, button: MenuButton) {
    this.removeLastEditedInvalidButton()
    this.buttons.splice(index, 0, button)
    this.handleButtonEdit(index, true)
  }

  handleAddButton() {
    const newButton = R.clone(emptyButton)
    this.availableLanguages.map(lang => {
      newButton.translations[lang] = R.clone(emptyButtonContent)
    })

    const firstWifiButtonIndex = R.findIndex(x => x.wifi, this.buttons)
    const indexToInsert = firstWifiButtonIndex >= 0 ? firstWifiButtonIndex : this.buttons.length
    this.addButtonAtIndex(indexToInsert, newButton)
  }

  handleAddWifiButton() {
    const newButton = R.clone(emptyButton)
    newButton.wifi = true
    this.availableLanguages.map(lang => {
      newButton.translations[lang] = R.clone(emptyButtonContent)
    })

    const indexToInsert = this.buttons.length
    this.addButtonAtIndex(indexToInsert, newButton)
  }

  handleButtonEdit(index, newButton = false) {
    const button = this.buttons[index]
    this.removeLastEditedInvalidButton()

    this.buttons.map(b => {
      b.isEdit = false
    })
    button.isEdit = true
    this.lastEditIndex = index
    this.cache = R.clone(button)
    this.cache.isEdit = true

    if (this.lastEditDialog) {
      this.lastEditDialog.close()
    }

    this.lastEditDialog = this.openEditButtonDialog(index, newButton)
    this.lastEditDialog.afterClosed().subscribe(data => {
      if (!data) {
        if (newButton) {
          this.handleRemove(index, 'buttons', true)
        } else {
          button.isEdit = false
        }
      }
      this.lastEditIndex = null
      // const addNewButtonEl = this.addNewButton._elementRef.nativeElement
      //  addNewButtonEl.blur()
    })
  }

  private openEditButtonDialog(
    buttonIndex: number,
    newButton: boolean
  ): MatDialogRef<BuilderButtonEditDialogComponent> {
    return this.dialog.open(BuilderButtonEditDialogComponent, {
      panelClass: 'hot-builder-buttons',
      width: '800px',
      data: {
        currentLanguage: this.currentLanguage,
        addPageEnabled: this.addPageEnabled,
        canSetVisibilityPeriod: this.canSetVisibilityPeriod,
        cache: this.cache,
        polls: this.polls,
        forms: this.forms,
        shopCategories: this.shopCategories,
        shopProducts: this.shopProducts,
        builders: this.builders,
        templates: this.templates,
        availableModules: this.modules,
        availableLanguages: this.availableLanguages,
        buttons: this.buttons,
        buttonIndex: buttonIndex,
        update: this.update,
        navigate: this.navigate,
        createNewPage: this.createNewPage,
        enableManuallyTranslate: this.enableManuallyTranslate,
        buttonAdding: newButton,
        property: this.property,
      },
    })
  }

  isLastEditValid(index) {
    return (
      this.buttons[index].translations[this.currentLanguage].title &&
      !R.isEmpty(this.buttons[index].translations[this.currentLanguage].config)
    )
  }

  removeLastEditedInvalidButton() {
    if (this.lastEditIndex !== null && this.buttons[this.lastEditIndex]) {
      if (this.lastEditIndex !== null && !this.isLastEditValid(this.lastEditIndex)) {
        this.buttons.splice(this.lastEditIndex, 1)
        this.handleRemove(this.lastEditIndex, undefined, true)
      }
    }
    this.lastEditIndex = null
  }

  public isPageType(button, type) {
    return (
      button.translations[this.currentLanguage] &&
      button.translations[this.currentLanguage].type === type
    )
  }

  public isEditValid(index) {
    return (
      this.buttons[index].translations[this.currentLanguage].type &&
      this.buttons[index].translations[this.currentLanguage].title &&
      this.buttons[index].translations[this.currentLanguage].config
    )
  }

  public get newButton() {
    return R.clone(emptyButton) as MenuButton
  }

  public get disabledAddButton(): boolean {
    return this.isButtonsEdit || this.addButtonForceDisabled
  }

  public get isButtonsEdit(): boolean {
    return this.buttons.some(b => b.isEdit)
  }

  public isButtonTracked(button): boolean {
    const translated = button.translations[this.currentLanguage] || button.translations.en
    if (!translated.config || !translated.config.pageId) {
      return null
    }
    return this.builders.some(
      ({ pageId, tracking }) => tracking.length && pageId === translated.config.pageId
    )
  }
}
