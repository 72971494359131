import * as R from 'ramda'
import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { FAQItem, NormalizedResult, NormalizedResults, PropertyFAQ } from '@models'
import { CreateCategoriesPayload } from '@redux/faq/faq.actions'

@Injectable()
export class FaqService {
  public create(propertyId: string, data: PropertyFAQ): Observable<PropertyFAQ> {
    return this.apiService.post(`properties/${propertyId}/faq`, data)
  }

  public get(propertyId: string): Observable<PropertyFAQ[]> {
    return this.apiService.get(`properties/${propertyId}/faq`)
  }

  public patch(
    propertyId: string,
    faq: string,
    faqItem: Partial<FAQItem>,
    sourceLanguage: string,
    { needTranslate = false } = {}
  ): Observable<PropertyFAQ> {
    const data = {
      content: faqItem,
      sourceLanguage,
      needTranslate,
    }
    return this.apiService.patch(`properties/${propertyId}/faq/${faq}`, data)
  }

  public delete(propertyId: string, faqId: string): Observable<PropertyFAQ> {
    return this.apiService.delete(`properties/${propertyId}/faq/${faqId}`)
  }

  public createCategory(data: CreateCategoriesPayload): Observable<NormalizedResult> {
    return this.apiService.post(`faq/`, data)
  }

  public getCategories(options): Observable<NormalizedResults> {
    const params = {
      page: options.page,
      limit: options.limit,
    }
    return this.apiService.get(`faq`, {
      params: R.pickBy(value => Boolean(value), params),
    })
  }

  public updateCategory(data: CreateCategoriesPayload): Observable<NormalizedResult> {
    return this.apiService.patch(`faq/${data.category._id}`, data)
  }

  public deleteCategory(FAQCategoryId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`faq/${FAQCategoryId}`)
  }

  constructor(private apiService: ApiService) {}
}
