import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  Inject,
} from '@angular/core'
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms'
import { Subject, Subscription } from 'rxjs'
import { takeUntil, take, delay } from 'rxjs/operators'
import { UidService } from '../../services/index'
import { FormField } from '@models'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { selectLanguage } from '@shared/pipes/select-language.pipe'

type FormFieldWithIdx = Partial<FormField> & { idx: number }

const END_FORM_ACTION = {
  id: '~finish~',
  title: 'FormQuestion.action.label.finish',
}

const END_SILENTLY_FORM_ACTION = {
  id: '~finish_silently~',
  title: 'FormQuestion.action.label.finishSilently',
}

const CANCEL_FORM_ACTION = {
  id: '~cancel~',
  title: 'FormQuestion.action.label.cancel',
}

@Component({
  selector: 'hot-form-field-options',
  templateUrl: './form-field-options.component.html',
  styleUrls: ['./form-field-options.component.scss'],
})
export class FormFieldOptionsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() public defaultLanguage
  @Input() public language
  @Input() public showIdInputs = false
  @Input() public config: FormControl
  @Output() public changed = new EventEmitter()

  @ViewChildren('answerInput') answerInputs: QueryList<ElementRef>

  public END_FORM_ACTION = END_FORM_ACTION
  public END_SILENTLY_FORM_ACTION = END_SILENTLY_FORM_ACTION
  public CANCEL_FORM_ACTION = CANCEL_FORM_ACTION
  public form: FormGroup
  public otherFormFields: FormFieldWithIdx[]
  public showEntities = false
  public isLastQuestion = true
  private formFieldsSubscription: Subscription = null
  private destroyed = new Subject()

  public get answers(): FormArray {
    return this.form.get('options') as FormArray
  }

  public nextQuestionFor(option) {
    const nqId = option.get('nextQuestion').value
    if (nqId === END_FORM_ACTION.id) {
      return END_FORM_ACTION
    }

    if (nqId === END_SILENTLY_FORM_ACTION.id) {
      return END_SILENTLY_FORM_ACTION
    }

    if (nqId === CANCEL_FORM_ACTION.id) {
      return CANCEL_FORM_ACTION
    }

    if (nqId) {
      return this.otherFormFields.find(x => x.id === nqId)
    }

    return null
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private fb: FormBuilder,
    private uid: UidService
  ) {
    this.form = this.fb.group({
      acceptUserText: this.fb.control(false),
      options: this.fb.array([]),
    })
  }

  public addAnswer() {
    this.answers.push(this.createAnswer())
    this.answerInputs.changes.pipe(take(1), delay(10)).subscribe(() => {
      if (this.answerInputs.length > 1) {
        this.answerInputs.last.nativeElement.focus()
      }
    })
  }

  public addOtherAnswer() {
    this.form.get('acceptUserText').patchValue(true)
  }

  public deleteOtherAnswer() {
    this.form.get('acceptUserText').patchValue(false)
  }

  ngAfterViewInit() {
    // this.answerInputs.changes.pipe(takeUntil(this.destroyed)).subscribe(() => {
    //   if (this.answerInputs.length > 1 && this.focusLastField) {
    //     this.answerInputs.last.nativeElement.focus()
    //   }
    // })
  }

  public deleteAnswer(idx) {
    this.answers.removeAt(idx)
    if (this.answers.length === 0) {
      // We do this to create new ID for option
      this.addAnswer()
    }
  }

  public deleteAnswerIfEmpty(idx, input) {
    const answer = this.answers.at(idx).value.label
    const value = selectLanguage(answer, this.language, this.defaultLanguage) || answer.default
    console.log(value, input.value)
  }

  updateFormFieldsDropdown() {
    const currentFormField = this.config.parent.value as FormField
    const formFields = this.config.parent.parent.value as FormField[]
    const currentFieldId = currentFormField.id
    const currentFieldIdx = formFields.findIndex(x => x.id === currentFieldId)
    this.isLastQuestion = currentFieldIdx + 1 === formFields.length
    this.otherFormFields = formFields
      .map((x, idx) => {
        return { idx, id: x.id, title: x.title.length ? x.title : x.label, type: x.type }
      })
      .slice(currentFieldIdx + 2)
  }

  updateLocalAnswers() {
    const config = this.config.value
    const acceptUserText = (config && config.acceptUserText) || false
    const options = (config && config.options) || []
    this.form.get('acceptUserText').patchValue(acceptUserText)
    this.deleteAllAnswers()
    const answers = this.answers
    this.showEntities = false
    if (options.length) {
      options.forEach(x => {
        this.showEntities = this.showEntities || Boolean(x.entities)
        answers.push(this.createAnswer(x))
      })
    } else {
      answers.push(this.createAnswer())
    }
  }

  setNextPage(optionIdx, pageId) {
    const options = this.form.get('options') as FormArray
    options.at(optionIdx).patchValue({
      nextQuestion: pageId,
    })
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(val => {
      this.changed.next({ ...this.config.value, ...val })
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.updateFormFieldsDropdown()

      this.updateLocalAnswers()

      const formFields = this.config.parent.parent as FormArray
      if (this.formFieldsSubscription) {
        this.formFieldsSubscription.unsubscribe()
        this.formFieldsSubscription = null
      }
      this.formFieldsSubscription = formFields.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.updateFormFieldsDropdown()
        })
    }
  }

  private createAnswer(data: any = {}) {
    const options = this.config && this.config.value && this.config.value.options
    return this.fb.group({
      id: this.fb.control(data.id || this.uid.nanoId(options, 'id')),
      value: this.fb.control(data.value || ''),
      label: this.fb.control(data.label || []),
      nextQuestion: this.fb.control(data.nextQuestion || null),
      entities: this.fb.control(data.entities || ''),
    })
  }

  private deleteAllAnswers() {
    const answers = this.answers
    while (answers.controls.length > 0) {
      answers.removeAt(0)
    }
  }
}
