import { RoomServiceActionTypes } from './room-service.actions'
import { ActionTypes as CartActionTypes } from '../cart/cart.actions'
import { RoomService } from '@models'

export interface Substate {
  items: RoomService[]
  loaded: boolean
  loading: boolean
}

export interface State {
  ids: string[]
  loaded: boolean
  loading: boolean
  byPropertyId: {
    [key: string]: Substate
  }
}

export const initialSubstate: Substate = {
  items: [],
  loaded: false,
  loading: false,
}

export const initialState: State = {
  ids: [],
  loaded: false,
  loading: false,
  byPropertyId: {},
}

function substateReducer(state = initialSubstate, action): Substate {
  switch (action.type) {
    case RoomServiceActionTypes.CreateSuccess: {
      return state
    }

    case RoomServiceActionTypes.Load: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }

    case RoomServiceActionTypes.LoadSuccess: {
      return {
        items: action.payload.results,
        loaded: true,
        loading: false,
      }
    }

    case CartActionTypes.LoadItemSuccess: {
      return {
        items: action.payload.roomServices.map(x => x._id),
        loaded: true,
        loading: false,
      }
    }

    case RoomServiceActionTypes.LoadFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }

    case RoomServiceActionTypes.DeleteSuccess: {
      return {
        ...state,
        // @ts-ignore
        items: state.items.filter(id => id !== action.meta.roomServiceId),
      }
    }

    default:
      return state
  }
}

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case CartActionTypes.LoadItemSuccess:
    case RoomServiceActionTypes.Create:
    case RoomServiceActionTypes.CreateSuccess:
    case RoomServiceActionTypes.CreateFail:
    case RoomServiceActionTypes.Load:
    case RoomServiceActionTypes.LoadSuccess:
    case RoomServiceActionTypes.LoadFail:
    case RoomServiceActionTypes.DeleteSuccess:
    case RoomServiceActionTypes.Update:
    case RoomServiceActionTypes.UpdateSuccess:
    case RoomServiceActionTypes.UpdateFail: {
      const query =
        action.type === RoomServiceActionTypes.Create ||
        action.type === RoomServiceActionTypes.Load ||
        action.type === RoomServiceActionTypes.Update
          ? action.payload
          : action.meta
      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [query.propertyId]: substateReducer(state.byPropertyId[query.propertyId], action),
        },
      }
    }
    default: {
      return state
    }
  }
}

export const selectSubstates = (state: State) => state && state.byPropertyId
export const selectPropertySubstateRoomServiceIds = (state: Substate) => state && state.items
export const selectRoomServiceLoaded = (state: Substate) => state && state.loaded
