import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { TimelineEvent, TimelineEventPhoto, TimelineType } from '@models'
// tslint:disable-next-line:max-line-length
import { TimelineEventPhotoDialogComponent } from '@shared/components/timeline/timeline-event-photo-dialog/timeline-event-photo-dialog.component'
import { TimelineEventStatusPipe } from '@shared/pipes'

@Component({
  selector: 'hot-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnDestroy {
  @Input() public type = TimelineType.FormResponse
  @Input() public events: TimelineEvent[] = []

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private dialog: MatDialog,
    public timelineEventStatusPipe: TimelineEventStatusPipe
  ) {}

  public ngOnInit() {}
  public ngOnDestroy() {}

  public getStatusContext(event: TimelineEvent) {
    return {
      userType: Boolean(event.appName) ? 'app' : 'user',
      user: this.getUserName(event),
      previousStatus: this.getStatusLabel(event.oldValue),
      currentStatus: this.getStatusLabel(event.newValue),
    }
  }

  public showComment(event: TimelineEvent): boolean {
    return Boolean(event.body)
  }

  public openPhotoDialog(photo: TimelineEventPhoto): void {
    this.dialog.open(TimelineEventPhotoDialogComponent, {
      data: {
        url: photo.url,
      },
    })
  }

  private getUserName(event: TimelineEvent) {
    return (
      event.appName ||
      // (event.__chat && chatName(event.__chat)) ||
      (event.__user && event.__user.email) ||
      (event.__staffUser && event.__staffUser.name) ||
      (event.__staffUser && event.__staffUser.email)
    )
  }

  private getStatusLabel(status) {
    return this.timelineEventStatusPipe
      .transform(status, this.locale.language, this.type)
      .replace(/\s/g, '&nbsp;')
  }
}
