import { Address } from '@models/address.model'
import { TranslatableField } from '@models/translatable-field'
import Dinero from 'dinero.js'

export enum InvoiceStatus {
  NEW = 'new',
  PENDING = 'pending',
  PREAUTH = 'pre_auth',
  CANCELLED = 'cancelled',
  PROCESSED = 'processed',
  COMPLETED = 'completed',
}
export enum InvoiceType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}
export interface IMOPBankCard {
  pan: string
  expiration: string
  cardholderName: string
  paymentSystem: string
  issuer?: string
  country?: string
}

export interface IInvoiceItem {
  id: string
  quantity: number
  name: TranslatableField
  basePrice: Dinero.DineroObject
  discount?: Dinero.DineroObject
  price?: Dinero.DineroObject
  total?: Dinero.DineroObject
}

export interface IInvoiceOptions {
  paymentSettings: {
    provider: string
    providerConfig: {
      account?: string
      merchant: string
    }
  }
}

export interface IInvoiceProps {
  _id?: any

  status: InvoiceStatus
  batch: string
  number: string
  customer: {}
  property: string
  chat: string
  type: InvoiceType
  processedAt: Date
  refId: string
  refModelName: string
  items: IInvoiceItem[]
  total: Dinero.DineroObject
  seller?: string
  invoiceOptions?: IInvoiceOptions
  paymentInfo?: {
    paymentId: string
    meansOfPayment: IMOPBankCard
    paymentWay: string
  }
  createdAt?: Date
  updatedAt?: Date
}

export interface InvoiceReceipt {
  number: number
  shiftNumber: number
  inShiftNumber: number
  cryptoSign: string
  device: {
    vendorId: string
    registrationId: string
    cryptoStorageId: string
  }
  taxId: string
  direction: InvoiceType
  dataProviderName: string
  externalUrl: string
  qrCode?: {
    type: 'url' | 'data' | 'binary'
    value: any
  }
  processedAt: Date
  positions: any
  cashierName: string
  taxationSystem: string
  amounts: {
    electronic?: number
    prepaid?: number
    cash?: number
  }
  url: string
}
export interface InvoiceModel {
  _id?: string
  name: string
  shortName: string
  email: string
  phone?: string
  registrationInfo: {
    registrationNumber?: string
    kpp?: string
    physicalAddress?: Address
    legalAddress: Address
    tin: string
    lei?: string
    giin?: string
  }
  paymentInfo: {
    accountHolderName: string
    accountNumber: string
    iban?: string
    bankName: string
    bankAddress: string
    sortCode?: number
    abaRoutingNumber?: number
    swiftBicCode: string
    ifscCode?: number
    routingCode?: number
  }
  receipts?: InvoiceReceipt[]
}
