<div class="flex items-center">
  <input
    e2e-input
    #fileInput
    name="file"
    type="file"
    [accept]="acceptedExtensions"
    hidden
    (change)="onFileUpload($event)"
  />
  <div
    *ngIf="uploaded; else notUploadedTmpl"
    class="uploaded"
    [class.uploaded--is-image]="imageUploaded"
    [class.uploaded--is-file]="!imageUploaded"
  >
    <div class="flex items-center relative">
      <ng-container *ngIf="imageUploaded; else attachmentTmpl">
        <img
          e2e-image
          [style.max-width]="imageWidth"
          class="w-100 hot-upload-image-preview"
          [hotProxySrc]="uploadedFilePath"
          [forceUseProxy]="forceUseProxy"
        />
      </ng-container>
      <ng-template #attachmentTmpl>
        <a
          e2e-file-link
          [href]="uploadedFilePath"
          class="uploaded-link flex items-center mr2"
          target="_blank"
          [download]="uploadedFileName"
        >
          <mat-icon class="mr2">attachment</mat-icon>
          <span>{{ uploadedFileName }}</span>
        </a>
      </ng-template>

      <button
        e2e-remove
        (click)="cancelUploadFile($event)"
        [class.hot-upload-file-remove--image]="imageUploaded"
        class="hot-upload-file-remove br-pill pa1 m0 bw0 shadow-1"
      >
        <mat-icon class="db hot-upload-file-icon hot-upload-file-icon--remove">close</mat-icon>
      </button>
    </div>
  </div>
  <ng-template #notUploadedTmpl>
    <div e2e-upload (click)="openFileDialog()" class="uploader uploader--upload">
      <div *ngIf="showIcon" class="dib w2">
        <mat-spinner *ngIf="pending" [diameter]="20" color="accent"></mat-spinner>
        <mat-icon *ngIf="!pending" class="v-mid hot-upload-file-icon hot-upload-file-icon--upload"
          >file_upload</mat-icon
        >
      </div>
      <div class="dib pr2">
        <div e2e-upload-label class="v-mid hot-upload-file-placeholder">
          {{ placeholder | translate: locale.language }}
        </div>
        <div e2e-error *ngIf="failed" class="f7 v-mid hot-upload-file-error">{{ error }}</div>
      </div>
    </div>
  </ng-template>
</div>
