import * as moment from 'moment'
import { AbstractControl } from '@angular/forms'

const toMomentObj = (time) => {
  const [hours, minutes] = time.split(':')
  return moment().startOf('day').set('hours', hours).set('minutes', minutes)
}

export const timeSlotValidator = (time: string, moreThan?: boolean) => (control: AbstractControl) => {
  if (time && control && control.value) {
    const edgeTime = toMomentObj(time)
    const validatedTime = toMomentObj(control.value)
    if (moreThan && !(validatedTime.isAfter(edgeTime, 'minutes') || validatedTime.isSame(edgeTime, 'minutes'))) {
      return { timeSlot: true }
    } else if (!moreThan && !(validatedTime.isBefore(edgeTime, 'minutes')  || validatedTime.isSame(edgeTime, 'minutes'))) {
      return { timeSlot: true }
    }
  }
  return null
}
