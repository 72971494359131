import { Component, Input, OnChanges, Inject } from '@angular/core'
import { FormFieldType, Form } from '@models'
import { selectLanguage } from '@shared/pipes/select-language.pipe'
import * as moment from 'moment'
import 'moment/locale/ru'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

interface FormattedAnswer {
  label: string
  value: string
  date?: Date
}

@Component({
  selector: 'hot-form-fields',
  template: `
    <div *ngIf="formattedAnswers" class="mt1">
      <div *ngFor="let answer of formattedAnswers" class="mb1">
        {{ answer.label || '&mdash;' }}: <span class="fw5">{{ answer.value || '&mdash;' }}</span>
        <span *ngIf="answer.date" class="dib bg-washed-blue blue br3 pa1">
          {{ answer.date | date: 'mediumDate':'':locale.language }}
          {{ answer.date | date: 'shortTime':'':locale.language }}
        </span>
      </div>
    </div>
  `,
  styles: [],
})
export class FormFieldsComponent implements OnChanges {
  @Input() lang = 'en'
  @Input() public form: Form
  @Input() public answers: { [key: string]: string }

  public formattedAnswers: FormattedAnswer[] = []
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  public ngOnChanges(changes): void {
    if ((changes.form && changes.answers) || changes.lang) {
      this.formattedAnswers = this.getFormatAnswers(this.form, this.answers)
    }
  }

  public getFormatAnswers(form: Form, answers: { [key: string]: string }): FormattedAnswer[] {
    const dateRegex = /\((\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)\)/
    return form.fields
      .filter(field => field.type !== FormFieldType.Action && field.type !== FormFieldType.Message)
      .map(f => {
        const label = selectLanguage(f.title, this.lang)
        const value = this.getFormatAnswer(f.type, f.config, answers[f.id])

        if (!value) {
          return { label, value: null, date: null }
        }

        const date = String(value).match(dateRegex)
        return {
          label,
          value: String(value).replace(dateRegex, ''),
          date: date ? new Date(date[1]) : null,
        }
      })
  }

  private getOptionLabel(options: any[], answerId: string) {
    const option = options && options.find(x => x.id === answerId || Number(answerId) === x.id)
    // choose lang here, default is hardcode now
    return option
      ? Array.isArray(option.label)
        ? selectLanguage(option.label, this.lang)
        : option.label[this.lang] || option.label.default
      : answerId
  }

  public getFormatAnswer(fieldType: FormFieldType, fieldConfig: any, answerId: string) {
    switch (fieldType) {
      case FormFieldType.Promocode:
      case FormFieldType.Options:
      case FormFieldType.Comment: {
        return this.getOptionLabel(fieldConfig.options, answerId)
      }
      case FormFieldType.Review: {
        return this.getOptionLabel(fieldConfig.scoreOptions, answerId)
      }
      case FormFieldType.Date: {
        const date = moment(answerId, 'YYYY-MM-DD', true)
        // remove hard code for lang
        return date.isValid() ? date.locale(this.lang).format('LL') : answerId
      }
      default: {
        return answerId
      }
    }
  }
}
