// import * as R from 'ramda'

// import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
// import { createSelector } from '@ngrx/store'

// import { User } from '@models'

import { UserActions, UserActionTypes } from './user.actions'
import { UserPermission } from '@models'

export enum ValidateTokenStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

// export interface State extends EntityState<User> {
export interface State {
  // additional entities state properties
  authorizedUserId: string
  selectedUserId: string
  tokenValidationStatus: ValidateTokenStatus
  pending: boolean
  permissions: UserPermission[]
}

// export const adapter: EntityAdapter<User> = createEntityAdapter<User>({ selectId: x => x._id })

// export const initialState: State = adapter.getInitialState({
export const initialState: State = {
  authorizedUserId: null,
  selectedUserId: null,
  tokenValidationStatus: null,
  pending: false,
  permissions: [],
}

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case UserActionTypes.LoginSuccess: {
      console.log('LOGGED IN', { user: action.payload.user })
      return {
        ...state,
        permissions: action.payload.user.permissions,
        authorizedUserId: action.payload.user._id,
      }
    }
    case UserActionTypes.Select: {
      return {
        ...state,
        selectedUserId: action.payload.userId,
      }
    }
    case UserActionTypes.ValidateResetPasswordToken: {
      return {
        ...state,
        tokenValidationStatus: ValidateTokenStatus.PENDING,
      }
    }
    case UserActionTypes.ValidateResetPasswordTokenSuccess: {
      return {
        ...state,
        tokenValidationStatus: ValidateTokenStatus.SUCCESS,
      }
    }
    case UserActionTypes.ValidateResetPasswordTokenFail: {
      return {
        ...state,
        tokenValidationStatus: ValidateTokenStatus.FAILED,
      }
    }
    case UserActionTypes.Update:
    case UserActionTypes.Create: {
      return {
        ...state,
        pending: true,
      }
    }
    case UserActionTypes.UpdateFail:
    case UserActionTypes.UpdateSuccess:
    case UserActionTypes.CreateFail:
    case UserActionTypes.CreateSuccess: {
      return {
        ...state,
        pending: false,
      }
    }

    // case UserActionTypes.AddUser: {
    //   return adapter.addOne(action.payload.user, state)
    // }

    // case UserActionTypes.AddUsers: {
    //   return adapter.addMany(action.payload.users, state)
    // }

    // case UserActionTypes.UpdateUser: {
    //   return adapter.updateOne(action.payload.user, state)
    // }

    // case UserActionTypes.UpdateUsers: {
    //   return adapter.updateMany(action.payload.users, state)
    // }

    // case UserActionTypes.DeleteUser: {
    //   return adapter.removeOne(action.payload.id, state)
    // }

    // case UserActionTypes.DeleteUsers: {
    //   return adapter.removeMany(action.payload.ids, state)
    // }

    // case UserActionTypes.LoadUsers: {
    //   return adapter.addAll(action.payload.users, state)
    // }

    // case UserActionTypes.ClearUsers: {
    //   return adapter.removeAll(state)
    // }

    default: {
      return state
    }
  }
}

// export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()

export const selectAuthorizedUserId = (state: State) => state.authorizedUserId
export const selectSelectedUserId = (state: State) => state.selectedUserId
export const selectTokenValidationStatus = (state: State) => state.tokenValidationStatus
export const selectUserPending = (state: State) => state.pending
export const selectUserPermissions = (state: State) => state.permissions

// export const selectAuthorizedUser = createSelector(selectAuthorizedUserId, selectEntities, R.prop)
