import { Action } from '@ngrx/store'
import { NormalizedResults, Cuisine, NormalizedResult } from '@models'

export enum ActionTypes {
  Load = '[Cuisine] Load',
  LoadSuccess = '[Cuisine] Load success',
  LoadFail = '[Cuisine] Load fail',
  Create = '[Cuisine] Create',
  CreateSuccess = '[Cuisine] Create success',
  CreateFail = '[Cuisine] Create fail',
  Update = '[Cuisine] Update',
  UpdateSuccess = '[Cuisine] Update success',
  UpdateFail = '[Cuisine] Update fail',
  Delete = '[Cuisine] Delete',
  DeleteSuccess = '[Cuisine] Delete success',
  DeleteFail = '[Cuisine] Delete fail',
}

// for future: some filters
export interface LoadPayload {
  page?: number
  limit?: number
}

// Load Actions
export class Load implements Action {
  public readonly type = ActionTypes.Load
  // maybe some payoad with filters for search
  public constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  public readonly type = ActionTypes.LoadSuccess
  public constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  public readonly type = ActionTypes.LoadFail
  public constructor(public error: any, public meta: LoadPayload) {}
}

// Create actions
export interface CreatePayload {
  cuisine: Cuisine
}
export class Create implements Action {
  public readonly type = ActionTypes.Create
  public constructor(public payload: CreatePayload) {}
}
export class CreateSuccess implements Action {
  public readonly type = ActionTypes.CreateSuccess
  public constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}
export class CreateFail implements Action {
  public readonly type = ActionTypes.CreateFail
  public constructor(public error: any, public meta: CreatePayload) {}
}

// Update actions
export interface UpdatePayload {
  cuisineId: string
  cuisine: Partial<Cuisine>
}
export class Update implements Action {
  public readonly type = ActionTypes.Update
  public constructor(public payload: UpdatePayload) {}
}
export class UpdateSuccess implements Action {
  public readonly type = ActionTypes.UpdateSuccess
  public constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}
export class UpdateFail implements Action {
  public readonly type = ActionTypes.UpdateFail
  public constructor(public error: any, public meta: UpdatePayload) {}
}

// Delete actions
export interface DeletePayload {
  cuisineId: string
}
export class Delete implements Action {
  public readonly type = ActionTypes.Delete
  public constructor(public payload: DeletePayload) {}
}
export class DeleteSuccess implements Action {
  public readonly type = ActionTypes.DeleteSuccess
  public constructor(public payload: boolean, public meta: DeletePayload) {}
}
export class DeleteFail implements Action {
  public readonly type = ActionTypes.DeleteFail
  public constructor(public error: any, public meta: DeletePayload) {}
}

export type Actions =
  | Load
  | LoadSuccess
  | LoadFail
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdateFail
  | UpdateSuccess
  | Delete
  | DeleteSuccess
  | DeleteFail
