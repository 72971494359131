import { Action } from '@ngrx/store'
import { Analytics, AnalyticsFilters, ShopServiceStatistics, PropertyKind } from '@models'

export enum ActionTypes {
  Load = '[Analytics] Load',
  LoadSuccess = '[Analytics] Load Success',
  LoadFail = '[Analytics] Load Fail',
  LoadShop = '[Analytics] LoadShop',
  LoadShopSuccess = '[Analytics] LoadShop Success',
  LoadShopFail = '[Analytics] LoadShop Fail',
}
/**
 * Load chat users actions
 */
export interface LoadPayload {
  filters: AnalyticsFilters
}

export class Load implements Action {
  public readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  public readonly type = ActionTypes.LoadSuccess
  constructor(public payload: Analytics, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  public readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load shop statistics
 */
export interface LoadShopPayload {
  propertyId?: string
  from: string
  to: string
  isAdmin?: boolean
  propertyKind?: PropertyKind
}
export class LoadShop implements Action {
  public readonly type = ActionTypes.LoadShop
  constructor(public payload: LoadShopPayload) {}
}

export class LoadShopSuccess implements Action {
  public readonly type = ActionTypes.LoadShopSuccess
  constructor(public payload: ShopServiceStatistics, public meta: LoadShopPayload) {}
}

export class LoadShopFail implements Action {
  public readonly type = ActionTypes.LoadShopFail
  constructor(public error: any, public meta: LoadShopPayload) {}
}

export type Actions = Load | LoadSuccess | LoadFail | LoadShop | LoadShopSuccess | LoadShopFail
