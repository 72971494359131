import { AbstractControl } from '@angular/forms'
import * as R from 'ramda'

const urlRegexp = /^(https?:\/\/)?[\da-zа-я\.-]{1,256}\.[a-zа-я\.]{2,6}([\da-zа-я\.@:%_\+.~#?&//=]*)/i

export const translatableUrlValidator = (control: AbstractControl) => {
  let result = null
  if (control && control.value) {
    const hasErrors = R.keys(control.value).map(lang => {
      const value = (control.value[lang] && control.value[lang].trim()) || ''
      return Boolean(value) && !value.match(urlRegexp)
    })
    if (R.any(v => v, hasErrors)) {
      result = {
        url: true,
      }
    }
  }
  return result
}
