import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { Component, OnInit, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Store, select } from '@ngrx/store'
import { filter, take } from 'rxjs/operators'
import * as fromRoot from '@redux/reducers'
import * as fromUser from '@redux/user'
import * as fromRouter from '@redux/router'
import { blankValidator } from '@shared/validators'

@Component({
  selector: 'hot-auth-reset',
  templateUrl: './recover.container.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
        align-items: flex-start;
      }
    `,
  ],
})
export class RecoverPasswordContainer implements OnInit {
  public recoverForm: FormGroup

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private store$: Store<fromRoot.State>
  ) {}

  public ngOnInit() {
    this.recoverForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, blankValidator])),
    })

    this.store$
      .pipe(
        select(fromRoot.selectAuthorizedUser),
        filter(id => Boolean(id)),
        take(1)
      )
      .subscribe(() => {
        this.store$.dispatch(new fromRouter.Go({ path: [''] }))
      })
  }

  public submit() {
    const formData = this.recoverForm.value
    this.store$.dispatch(new fromUser.ResetPassword(formData))
  }

  public get enableSubmit(): boolean {
    return this.recoverForm && this.recoverForm.dirty && this.recoverForm.valid
  }
}
