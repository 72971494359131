import { Chat } from './chat.model'
import { Form } from './form.model'
import { TranslatableField } from './translatable-field'
import { PaginationOptions } from './pagination-options'
import { TimelineEvent } from './timeline-event.model'

export enum ReviewStatus {
  Open = 'open',
  Closed = 'closed',
}

export interface ReviewFilters {
  status?: ReviewStatus
  paginationOptions?: PaginationOptions
}

export interface Review {
  _id: string
  createdAt: string
  closedAt: string
  reviewNumber: number
  status: ReviewStatus
  chat: string
  formCode: string
  events: TimelineEvent[]
  title: TranslatableField
  answers: {
    [key: string]: string
  }

  // Custom fields to populate
  __chat?: Chat
  __events?: TimelineEvent
  __form?: Form
}
