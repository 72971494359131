import { Pipe, PipeTransform } from '@angular/core'
import { TimelineType } from '@models'
import { I18nService } from '@shared/services'

@Pipe({
  name: 'timelineEventStatus',
})
export class TimelineEventStatusPipe implements PipeTransform {
  constructor(private translation: I18nService) {}
  transform = (value: any, _lang = 'ru', type = TimelineType.FormResponse): any => {
    const key = `components.Timeline.status.${type}.${value}`
    const translated = this.translation.translate(key)
    const unknownStatus = translated === key
    return unknownStatus ? '❓' : translated
  }
}
