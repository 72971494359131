import { Actions as FormActions, ActionTypes as FormActionTypes } from './form.actions'
import { BuilderActions, BuilderActionTypes } from '../builder/builder.actions'
import { createListLoader } from '../helpers'
import * as R from 'ramda'

export interface Substate {
  ids: string[]
  loaded: boolean
  loading: boolean
}

export interface State {
  byPropertyId: {
    [key: string]: Substate
  }
  templates: Substate
}

export const initialSubstate: Substate = {
  ids: [],
  loaded: false,
  loading: false,
}

export const initialState: State = {
  byPropertyId: {},
  templates: initialSubstate,
}

const TemplateListLoader = createListLoader(
  {
    Load: FormActionTypes.LoadTemplates,
    LoadSuccess: FormActionTypes.LoadTemplatesSuccess,
    LoadFail: FormActionTypes.LoadTemplatesFail,
  },
  initialSubstate
)

const ListLoader = createListLoader(FormActionTypes, initialSubstate)

export function reducer(state = initialState, action: FormActions | BuilderActions): State {
  switch (action.type) {
    case FormActionTypes.DeleteTemplateSuccess: {
      const templateId = action.payload
      const updatedTemplatesIds = R.without(templateId, state.templates.ids)
      return {
        ...state,
        templates: {
          ...state.templates,
          ids: updatedTemplatesIds,
        },
      }
    }

    case FormActionTypes.DeleteSuccess: {
      const { formId, propertyId } = action.payload
      const propertyState = R.clone(state.byPropertyId[propertyId])
      const updatedIds = propertyState.ids.filter(id => id !== formId)
      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [propertyId]: {
            ...propertyState,
            ids: updatedIds,
          },
        },
      }
    }

    case FormActionTypes.LoadTemplates:
    case FormActionTypes.LoadTemplatesSuccess:
    case FormActionTypes.LoadTemplatesFail: {
      return {
        ...state,
        templates: TemplateListLoader.reducer(state.templates, action),
      }
    }

    case BuilderActionTypes.LoadAllSuccess: {
      const propertyId = action.meta.propertyId
      return R.set(
        R.lensPath(['byPropertyId', propertyId]),
        {
          ids: R.pluck<string, any>('_id', action.payload.forms),
          loading: false,
          loaded: true,
        },
        state
      )
    }

    case FormActionTypes.Load:
    case FormActionTypes.LoadSuccess:
    case FormActionTypes.LoadFail: {
      const propertyId =
        action.type === FormActionTypes.Load ? action.payload.propertyId : action.meta.propertyId

      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [propertyId]: ListLoader.reducer(state.byPropertyId[propertyId], action),
        },
      }
    }
  }
  return state
}

export const selectTemplates = (state: State) => state.templates
export const selectSubstates = (state: State) => state.byPropertyId
export const selectSubstateIds = (substate: Substate) => substate && substate.ids
export const selectSubstateLoading = (substate: Substate) => substate && substate.loading
export const selectSubstateLoaded = (substate: Substate) => substate && substate.loaded
export const selectTemplatesLoading = TemplateListLoader.selectLoading
export const selectTemplatesLoaded = TemplateListLoader.selectLoaded
export const selectFormsLoaded = ListLoader.selectLoaded
