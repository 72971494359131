import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { NormalizedResult } from '@models'

@Injectable()
export class PushSubscriptionService {
  constructor(private apiService: ApiService) {}

  public saveSubscription(subscription: PushSubscription): Observable<NormalizedResult> {
    return this.apiService.post('user/push/subscribe', subscription)
  }
}
