import * as R from 'ramda'
import { Component, OnInit, Input, ChangeDetectionStrategy, Inject } from '@angular/core'
import { FormField, FormFieldType } from '@models'
import { LanguageType } from '../../constants'

import * as moment from 'moment'
import { selectLanguage } from '../../pipes/index'
import { FormTranslations } from '../../../settings/forms/forms.translations'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'hot-form-field-preview',
  templateUrl: './form-field-preview.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldPreviewComponent implements OnInit {
  @Input() field: FormField
  @Input() language: LanguageType | 'en' = 'en'

  public commentButtons = R.pluck('label', FormTranslations.fieldTypes.comment.options)

  getTranslation(field, defaultValue = null) {
    return selectLanguage(field, this.language) || defaultValue
  }

  public get translatedLabel() {
    const label = this.field.label
    return label && this.getTranslation(label)
  }

  getConfigLabel(key) {
    if (!this.field || !this.field.type) {
      return ''
    }
    const i18n = FormTranslations.fieldTypes[this.field.type][key]
    const label = this.field.config && this.field.config[key]
    return (label && this.getTranslation(label)) || i18n[this.locale.language]
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit() {}

  get otherButtonLabel() {
    return selectLanguage(FormTranslations.fieldTypes.options.labelOther, this.language)
  }

  getDateButtons() {
    if (this.field.type !== FormFieldType.Date) {
      return []
    }
    const dateLabels = FormTranslations.fieldTypes.date
    const config = this.field.config
    const getDateForDay = daysOffset =>
      moment()
        .add(daysOffset, 'days')
        .locale(this.language)
        .format('L')
    const buttons = [
      selectLanguage(dateLabels.labelToday, this.language),
      selectLanguage(dateLabels.labelTomorrow, this.language),
    ]
    for (let i = 2; i < config.days; i++) {
      buttons.push(getDateForDay(i))
    }
    if (config.acceptUserText) {
      buttons.push(selectLanguage(dateLabels.labelOther, this.language))
    }
    return buttons
  }
}
