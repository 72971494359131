import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root',
})
export class AppTitleService {
  constructor(public titleService: Title) {}

  getTitle() {
    return this.titleService.getTitle()
  }

  setTitle(pageTitle = '') {
    const title = pageTitle ? pageTitle + ' | Hotbot.ai' : 'Hotbot.ai'
    this.titleService.setTitle(title)
  }
}
