import { Action } from '@ngrx/store'
import { NormalizedResult, NormalizedResults, Restaurant } from '@models'
import { LanguageType } from '@shared/constants'

export enum RestaurantActionTypes {
  Create = '[Restaurant] Create',
  CreateSuccess = '[Restaurant] Create Success',
  CreateFail = '[Restaurant] Create Fail',
  Load = '[Restaurant] Load',
  LoadSuccess = '[Restaurant] Load Success',
  LoadFail = '[Restaurant] Load Fail',
  LoadItem = '[Restaurant] Load item',
  LoadItemSuccess = '[Restaurant] Load item Success',
  LoadItemFail = '[Restaurant] Load item Fail',
  Update = '[Restaurant] Update',
  UpdateSuccess = '[Restaurant] Update Success',
  UpdateFail = '[Restaurant] Update Fail',
  Delete = '[Restaurant] Delete',
  DeleteSuccess = '[Restaurant] Delete Success',
  DeleteFail = '[Restaurant] Delete Fail',
  Select = '[Restaurant] Select',
}

/**
 * Select restaurant action
 */
export interface SelectPayload {
  restaurantId: string
}
export class Select implements Action {
  public readonly type = RestaurantActionTypes.Select
  constructor(public payload: SelectPayload) {}
}

/**
 * Create restaurant actions
 */

export interface CreatePayload {
  restaurant: Restaurant
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
}

export class Create implements Action {
  readonly type = RestaurantActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = RestaurantActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = RestaurantActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load restaurant actions
 */

export interface LoadPayload {
  page?: number
  limit?: number
  meta?: {}
}

export class Load implements Action {
  readonly type = RestaurantActionTypes.Load
  constructor(public payload: LoadPayload = {}) {}
}

export class LoadSuccess implements Action {
  readonly type = RestaurantActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = RestaurantActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load restaurant actions
 */

export interface LoadItemPayload {
  restaurantId: string
}

export class LoadItem implements Action {
  readonly type = RestaurantActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}

export class LoadItemSuccess implements Action {
  readonly type = RestaurantActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}

export class LoadItemFail implements Action {
  readonly type = RestaurantActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Update restaurant actions
 */
export interface UpdatePayload {
  restaurantId: string
  restaurant: Restaurant
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
}

export class Update implements Action {
  readonly type = RestaurantActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = RestaurantActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = RestaurantActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete restaurant actions
 */

export interface DeletePayload {
  restaurantId: string
}

export class Delete implements Action {
  readonly type = RestaurantActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = RestaurantActionTypes.DeleteSuccess
  constructor(public payload: any, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = RestaurantActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type RestaurantActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Select
