import { AuthGuard } from './auth.guard'
import { LoadPropertiesGuard } from './load-properties.guard'
import { LoadMenuPagesTemplatesGuard } from './load-menu-pages-templates.guard'
import { OnboardingGuard } from './onboarding.guard'
import { PropertyGuard } from './property.guard'
import { SelectFormResponseGuard } from './select-form-response.guard'
import { SelectCartGuard } from './select-cart.guard'
import { SelectShopCartGuard } from './select-shop-cart.guard'
import { SelectPushMessageGuard } from './select-push-message.guard'
import { SelectStaffUserGuard } from './select-staff-user.guard'
import { SelectReviewGuard } from '@shared/guards/select-review.guard'
import { SelectChatGuard } from '@shared/guards/select-chat.guard'
import { IsAdminGuard } from '@shared/guards/is-admin.guard'
import { SelectRestaurantGuard } from '@shared/guards/select-restaurant.guard'

export * from './auth.guard'
export * from './onboarding.guard'
export * from './property.guard'
export * from './load-properties.guard'
export * from './select-form-response.guard'
export * from './select-cart.guard'
export * from './select-shop-cart.guard'
export * from './select-push-message.guard'
export * from './select-staff-user.guard'

export const SHARED_GUARDS = [
  AuthGuard,
  LoadPropertiesGuard,
  LoadMenuPagesTemplatesGuard,
  OnboardingGuard,
  PropertyGuard,
  SelectFormResponseGuard,
  SelectCartGuard,
  SelectShopCartGuard,
  SelectPushMessageGuard,
  SelectStaffUserGuard,
  SelectReviewGuard,
  SelectChatGuard,
  IsAdminGuard,
  SelectRestaurantGuard,
]
