import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'abbreviation',
})
export class AbbreviationPipe implements PipeTransform {
  transform(value: string, size = 2): string {
    const preparedValue = value.replace(/[|]/g, '').replace(/\s\s+/g, ' ')
    const nameArray = preparedValue.split(/[\s\-_]+/)
    let result = ''

    if (nameArray.length > 1) {
      for (let i = 0; i < size; i++) {
        result += nameArray[i].charAt(0).toUpperCase()
      }
    } else {
      result += value.charAt(0).toUpperCase()
      for (let i = 1; i < size; i++) {
        result += value.charAt(i).toLowerCase()
      }
    }

    return result
  }
}
