import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { Property, User } from '@models'
import * as fromUser from '@redux/user'
import { Store } from '@ngrx/store'
import * as fromRoot from '@redux'
import { I18nService } from '@shared/services/i18n.service'
import { CIS_CODES } from '../../../app.i18n'

@Component({
  selector: 'hot-app-language-selector',
  template: `
    <button
      mat-icon-button
      [matMenuTriggerFor]="countryMenu"
      aria-label="Change language"
      class="T-button--flat bg-animate"
    >
      <mat-icon class="T-icon">language</mat-icon>
    </button>
    <mat-menu xPosition="before" #countryMenu="matMenu" class="before">
      <a
        *ngFor="let item of languages"
        class="flex items-center"
        (click)="updateUserLocale(item.language)"
        mat-menu-item
      >
        <span class="ib">{{ item.label }}</span>
        <mat-icon class="blue ml2" *ngIf="locale.language == item.language">done</mat-icon>
      </a>
    </mat-menu>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `,
  ],
})
export class AppLanguageSelectorComponent implements OnInit, OnDestroy {
  @Input() property: Property
  @Input() user: User

  get languages() {
    return this.i18n.supportedLanguages.filter(lang => {
      return lang.language !== 'ru' || this.isCISProperty
    })
  }

  get userLocale() {
    return this.user.locale ? this.user.locale : 'en'
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public i18n: I18nService,
    private store$: Store<fromRoot.State>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public updateUserLocale(locale) {
    if (this.property && this.user) {
      this.store$.dispatch(
        new fromUser.UpdatePreferences({
          propertyId: this.property._id,
          userId: this.user._id,
          user: { locale },
        })
      )
    } else {
      this.i18n.switchLanguage(locale)
    }
  }

  public get isCISProperty() {
    return (
      this.property &&
      this.property.address &&
      CIS_CODES.includes(String(this.property.address.countryCode).toUpperCase())
    )
  }
}
