import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import * as fromAnalytics from './analytics.actions'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import { AnalyticsService } from '@redux/analytics'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'

const LOAD_ANALYTICS_ERROR = 'При загрузке информации возникла ошибка'

@Injectable()
export class AnalyticsEffects {
  @Effect()
  loadAnalytics$ = this.actions$.pipe(
    ofType(fromAnalytics.ActionTypes.Load),
    switchMap((action: fromAnalytics.Load) => {
      const meta = action.payload
      return this.analyticsService.getAnalytics(action.payload.filters).pipe(
        map(x => new fromAnalytics.LoadSuccess(x, meta)),
        catchError(x => of(new fromAnalytics.LoadFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  loadAnalyticsFail$ = this.actions$.pipe(
    ofType(fromAnalytics.ActionTypes.LoadFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ANALYTICS_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  @Effect()
  loadShopAnalytics$ = this.actions$.pipe(
    ofType(fromAnalytics.ActionTypes.LoadShop),
    switchMap((action: fromAnalytics.LoadShop) => {
      const meta = action.payload
      return this.analyticsService.getShopStatistics(action.payload).pipe(
        map(x => new fromAnalytics.LoadShopSuccess(x, meta)),
        catchError(x => of(new fromAnalytics.LoadShopFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  loadShopAnalyticsFail$ = this.actions$.pipe(
    ofType(fromAnalytics.ActionTypes.LoadShopFail),
    tap(() => {
      this.matSnackBar.open(LOAD_ANALYTICS_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  public constructor(
    public actions$: Actions,
    public analyticsService: AnalyticsService,
    public matSnackBar: MatSnackBar
  ) {}
}
