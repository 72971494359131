import { Action } from '@ngrx/store'
import { NormalizedResult, LegalEntity } from '@models'

export enum LegalEntityActionTypes {
  Create = '[Legal-Entity] Create',
  CreateSuccess = '[Legal-Entity] Create Success',
  CreateFail = '[Legal-Entity] Create Fail',

  Load = '[Legal-Entity] Load',
  LoadSuccess = '[Legal-Entity] Load Success',
  LoadFail = '[Legal-Entity] Load Fail',

  LoadItem = '[Legal-Entity Item] Load',
  LoadItemSuccess = '[Legal-Entity Item] Load Succes',
  LoadItemFail = '[Legal-Entity Item] Load Fail',

  Update = '[Legal-Entity] Update',
  UpdateSuccess = '[Legal-Entity] Update Success',
  UpdateFail = '[Legal-Entity] Update Fail',

  Delete = '[Legal-Entity] Delete',
  DeleteSuccess = '[Legal-Entity] Delete Success',
  DeleteFail = '[Legal-Entity] Delete Fail',

  Select = '[Legal-Entity] Select',
}

/**
 * Create actions
 */

export interface CreatePayload {
  result?: string
  legalEntity?: LegalEntity
}

export class Create implements Action {
  readonly type = LegalEntityActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = LegalEntityActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload = {}) {}
}

export class CreateFail implements Action {
  readonly type = LegalEntityActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload = {}) {}
}

/**
 * Load  actions
 */

export interface LoadPayload {
  legalEntityId?: string
}

export class Load implements Action {
  readonly type = LegalEntityActionTypes.Load
  constructor(public payload: LoadPayload = {}) {}
}

export class LoadSuccess implements Action {
  readonly type = LegalEntityActionTypes.LoadSuccess
  constructor(public payload, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = LegalEntityActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load Item actions
 */

export interface LoadItemPayload {
  legalEntityId?: string
}
export class LoadItem implements Action {
  readonly type = LegalEntityActionTypes.LoadItem
  constructor(public payload: LoadItemPayload = {}) {}
}
export class LoadItemSuccess implements Action {
  readonly type = LegalEntityActionTypes.LoadItemSuccess
  constructor(public payload, public meta: LoadItemPayload) {}
}
export class LoadItemFail implements Action {
  readonly type = LegalEntityActionTypes.LoadItemFail
  constructor(public payload, public meta: LoadItemPayload) {}
}


/**
 * Update actions
 */
export interface UpdatePayload {
  legalEntityId: string
  legalEntity: LegalEntity
}

export class Update implements Action {
  readonly type = LegalEntityActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = LegalEntityActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = LegalEntityActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete actions
 */
export interface DeletePayload {
  legalEntityId: string
}

export class Delete implements Action {
  readonly type = LegalEntityActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = LegalEntityActionTypes.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = LegalEntityActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

/*
 * Select action
 */

export interface SelectPayload {
  legalEntityId: string
}
export class Select implements Action {
  readonly type = LegalEntityActionTypes.Select
  constructor(public payload: SelectPayload) {}
}

export type LegalEntityActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Select
