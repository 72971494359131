import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { UploadFile, UploadFilePreset } from '@models'

import { ApiService } from './api.service'

@Injectable()
export class UploadFileService {
  public constructor(private apiService: ApiService) {}

  public upload(
    fileToUpload: any,
    path: string,
    preset?: UploadFilePreset
  ): Observable<UploadFile> {
    const input = new FormData()
    input.append('files', fileToUpload)
    input.append('path', path)
    return this.apiService.post(`upload${preset ? '?preset=' + preset : ''}`, input)
  }
}
