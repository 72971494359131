import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'

import { Store, select } from '@ngrx/store'

import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

import * as fromRoot from '@redux'
import * as fromProperty from '@redux/property'
import { AuthService } from '@shared/services'

@Injectable({
  providedIn: 'root',
})
export class LoadPropertiesGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>, private authService: AuthService) {}

  public canActivate(): Observable<boolean> | boolean {
    if (!this.authService.isAuthorized()) {
      return false
    }
    this.store$.dispatch(new fromProperty.Load())
    return this.store$.pipe(select(fromRoot.selectPropertiesLoaded), filter(Boolean))
  }
}
