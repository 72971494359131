export const FormTranslations = {
  fieldTypes: {
    text: {
      label: {
        default: 'Enter text',
        ru: 'Введите текст',
      },
    },

    action: {
      label: {
        default: 'Enter text',
        ru: 'Введите текст',
      },
    },

    message: {
      label: {
        default: 'Enter message text',
        ru: 'Введите текст сообщения',
      },
    },

    options: {
      label: {
        default: 'Select an option',
        ru: 'Выберите один из вариантов',
      },
      labelOther: {
        default: 'Other',
        ru: 'Другое',
      },
      labelEnterText: {
        default: 'Enter your option',
        ru: 'Введите ваш вариант',
      },
    },

    date: {
      label: {
        default: 'Select desired date',
        ru: 'Выберите желаемую дату',
      },
      labelEnterText: {
        default: 'Enter your desired date',
        ru: 'Введите желаемую дату',
      },
      labelToday: { default: 'Today', ru: 'Сегодня' },
      labelTomorrow: { default: 'Tomorrow', ru: 'Завтра' },
      labelOther: { default: 'Other', ru: 'Другое' },
    },

    comment: {
      label: {
        default: 'Add a special wish?',
        ru: 'Добавить особое пожелание?',
      },
      labelEnterText: {
        default: 'Please enter your comment:',
        ru: 'Пожалуйста, введите ваше пожелание:',
      },
      options: [
        { id: 'yes', label: { default: 'Да', en: 'Yes' } },
        { id: 'no', label: { default: 'Нет', en: 'No' } },
      ],
    },
  },
}
