import { ReviewFilters, DEFAULT_PAGINATION_SIZE, ReviewStatus } from '@models'
import { Actions as ReviewActions, ActionTypes as ReviewActionTypes } from './review.actions'

export interface State {
  selectedId: string
  byPropertyId: {
    [key: string]: Substate
  }
}
export interface ReviewMeta {
  totalRecords?: number
  openedRecords?: number
}

export interface Substate {
  ids: string[]
  loaded: boolean
  loading: boolean
  filters?: ReviewFilters
  meta?: ReviewMeta
}

export const initialState: State = {
  selectedId: null,
  byPropertyId: {},
}

export const initialSubstate: Substate = {
  ids: [],
  loaded: false,
  loading: false,
  filters: {
    status: ReviewStatus.Open,
    paginationOptions: {
      limit: DEFAULT_PAGINATION_SIZE,
      page: 0,
    },
  },
  meta: {
    totalRecords: 0,
    openedRecords: 0,
  },
}

function substateReducer(state = initialSubstate, action: ReviewActions): Substate {
  switch (action.type) {
    case ReviewActionTypes.Load: {
      return {
        ids: [],
        loading: true,
        loaded: false,
        filters: {
          status: action.payload.status,
          paginationOptions: {
            page: action.payload.page,
            limit: action.payload.limit,
          },
        },
        meta: state.meta,
      }
    }

    case ReviewActionTypes.LoadSuccess: {
      return {
        ...state,
        ids: action.payload.results,
        meta: action.payload.meta,
        loading: false,
        loaded: true,
      }
    }

    case ReviewActionTypes.LoadFail: {
      return {
        ids: [],
        loaded: false,
        loading: false,
        filters: {
          status: action.meta.status,
          paginationOptions: {
            page: 0,
            limit: 0,
          },
        },
      }
    }

    case ReviewActionTypes.UpdateMeta: {
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.meta,
        },
      }
    }

    case ReviewActionTypes.UpdateStatusSuccess: {
      return {
        ...state,
        meta: action.payload.meta,
      }
    }

    default:
      return state
  }
}

export function reducer(state = initialState, action: ReviewActions): State {
  switch (action.type) {
    case ReviewActionTypes.Load:
    case ReviewActionTypes.LoadSuccess:
    case ReviewActionTypes.UpdateStatusSuccess:
    case ReviewActionTypes.UpdateMeta:
    case ReviewActionTypes.LoadFail: {
      const payload =
        action.type === ReviewActionTypes.Load || action.type === ReviewActionTypes.UpdateMeta
          ? action.payload
          : action.meta
      return {
        ...state,
        byPropertyId: {
          ...state.byPropertyId,
          [payload.propertyId]: substateReducer(state.byPropertyId[payload.propertyId], action),
        },
      }
    }

    case ReviewActionTypes.Select: {
      return {
        ...state,
        selectedId: action.payload.reviewId,
      }
    }

    default:
      return state
  }
}

export const selectSubstates = (state: State) => state && state.byPropertyId
export const selectCurrentReviewId = (state: State) => state && state.selectedId
export const selectSubstateLoaded = (state: Substate) => state && state.loaded
export const selectSubstateLoading = (state: Substate) => state && state.loading
export const selectSubstateIds = (state: Substate) => state && state.ids
export const selectSubstateFilters = (state: Substate) => state && state.filters
export const selectSubstateTotalRecords = (state: Substate) =>
  state && state.meta && state.meta.totalRecords
export const selectSubstateOpenedRecords = (state: Substate) =>
  state && state.meta ? state.meta.openedRecords : 0
