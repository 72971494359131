import { Integration, IntegrationProvider } from '@models'
import { PropertyActions, PropertyActionTypes } from './property.actions'
import * as R from 'ramda'

export interface State {
  ids: string[]
  loaded: boolean
  loadedById: { [key: string]: boolean }
  loadingById: { [key: string]: boolean }
  selectedProperty: string | null
  integrationsById: {
    [key: string]: {
      integrations: Integration[]
      available: IntegrationProvider[]
    }
  }
  searchCity: {
    pending: boolean
    items: string[]
  }
}

export const initialState: State = {
  ids: [],
  loaded: false,
  selectedProperty: null,
  loadedById: {},
  loadingById: {},
  integrationsById: {},
  searchCity: {
    pending: false,
    items: [],
  },
}

export function reducer(state = initialState, action: PropertyActions): State {
  switch (action.type) {
    case PropertyActionTypes.SelectProperty: {
      return {
        ...state,
        selectedProperty: action.payload.propertyId,
      }
    }

    case PropertyActionTypes.CreateSuccess: {
      const createdId = action.payload.result
      return {
        ...state,
        ids: [...state.ids, createdId],
      }
    }
    case PropertyActionTypes.DeleteSuccess: {
      const deletedId = action.propertyId
      return {
        ...state,
        ids: R.without([deletedId], state.ids),
      }
    }

    case PropertyActionTypes.LoadSuccess: {
      return {
        ...state,
        loaded: true,
        ids: action.payload.results,
        selectedProperty: state.selectedProperty || null,
      }
    }

    case PropertyActionTypes.LoadItem: {
      const payload = action.payload
      return {
        ...state,
        loadedById: {
          [payload.propertyId]: false,
        },
        loadingById: {
          [payload.propertyId]: true,
        },
      }
    }

    case PropertyActionTypes.LoadItemSuccess: {
      const meta = action.meta
      return {
        ...state,
        loadedById: {
          [meta.propertyId]: true,
        },
        loadingById: {
          [meta.propertyId]: false,
        },
      }
    }

    case PropertyActionTypes.UpdateIntegrationsSuccess:
    case PropertyActionTypes.LoadIntegrationsSuccess: {
      const meta = action.meta
      return {
        ...state,
        integrationsById: {
          [meta.propertyId]: {
            integrations: action.payload.integrations,
            available: action.payload.availableIntegrations,
          },
        },
      }
    }

    case PropertyActionTypes.LoadItemFail: {
      const meta = action.meta
      return {
        ...state,
        loadedById: {
          [meta.propertyId]: false,
        },
        loadingById: {
          [meta.propertyId]: false,
        },
      }
    }

    case PropertyActionTypes.SearchCity: {
      const meta = action.payload
      const isPending = Boolean(meta.query && (meta.query.length > 2 || meta.countryCode))
      return {
        ...state,
        searchCity: {
          pending: isPending,
          items: [],
        },
      }
    }

    case PropertyActionTypes.SearchCitySuccess: {
      return {
        ...state,
        searchCity: {
          pending: false,
          items: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}

export const selectCurrentPropertyId = (state: State) => state.selectedProperty
export const selectIds = (state: State) => state.ids
export const selectIsLoaded = (state: State) => state.loaded
export const selectIsLoadedById = (state: State, id: string) => state.loadedById[id] || false
export const selectIntegrationsById = (state: State, id: string) =>
  (state.integrationsById[id] && state.integrationsById[id].integrations) || null
export const selectAvailableIntegrationsById = (state: State, id: string) =>
  (state.integrationsById[id] && state.integrationsById[id].available) || null
export const selectSearchCityPending = (state: State) =>
  (state.searchCity && state.searchCity.pending) || false
export const selectSearchCityResults = (state: State) =>
  (state.searchCity && state.searchCity.items) || []
