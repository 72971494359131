<tr *ngIf="_firstRowOffset < labelMinRequiredCells" aria-hidden="true">
  <td class="mat-calendar-body-label"
      [attr.colspan]="numCols"
      [style.paddingTop]="_cellPadding"
      [style.paddingBottom]="_cellPadding">
    {{label}}
  </td>
</tr>

<tr *ngFor="let row of rows; let rowIndex = index" role="row">
  <td *ngIf="rowIndex === 0 && _firstRowOffset"
      aria-hidden="true"
      class="mat-calendar-body-label"
      [attr.colspan]="_firstRowOffset"
      [style.paddingTop]="_cellPadding"
      [style.paddingBottom]="_cellPadding">
    {{_firstRowOffset >= labelMinRequiredCells ? label : ''}}
  </td>
  <td *ngFor="let item of row; let colIndex = index"
      role="gridcell"
      class="mat-calendar-body-cell"
      [ngClass]="item.cssClasses"
      [tabindex]="_isActiveCell(rowIndex, colIndex) ? 0 : -1"
      [class.mat-calendar-body-disabled]="!item.enabled"
      [class.mat-calendar-body-active]="_isActiveCell(rowIndex, colIndex)"
      [class.mat-calendar-body-begin-range]="_isBegin(item.value)"
      [class.mat-calendar-body-end-range]="_isEnd(item.value)"
      [class.mat-calendar-cell-range-selected]="_isRangeSelected(item.value)"
      [class.mat-calendar-cell-range-in-progress]="_isRangeSelectionInProgress(item.value)"
      [class.mat-calendar-cell-hover]="_isRangeSelectionCellHovered(item.value)"
      [attr.aria-label]="item.ariaLabel"
      [attr.aria-disabled]="!item.enabled || null"
      [attr.aria-selected]="selectedValue === item.value"
      (click)="_cellClicked(item)"
      (mouseover)="_mouseOverCell(item)"
      [style.width]="_cellWidth"
      [style.paddingTop]="_cellPadding"
      [style.paddingBottom]="_cellPadding">
    <div class="mat-calendar-body-cell-content"
         [class.mat-calendar-body-selected]="begin === item.value || end === item.value || selectedValue === item.value"
         [class.mat-calendar-body-range-selected]="_isRangeSelected(item.value)"
         [class.mat-calendar-body-today]="todayValue === item.value">
      {{item.displayValue}}
    </div>
  </td>
</tr>
