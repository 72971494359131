import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { InvoiceModel } from '@models'

@Injectable()
export class InvoiceService {
  constructor(public readonly apiService: ApiService) {}

  public getRelatedList(opts: {
    propertyId: string
    refModelName?: string
    refId?: string
  }): Observable<{ invoices: InvoiceModel[] }> {
    return this.apiService.get(`properties/${opts.propertyId}/invoices/related`, {
      params: {
        refModelName: opts.refModelName,
        refId: opts.refId,
      },
    })
  }
}
