import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResult, Shop } from '@models'
import { LanguageType } from '@shared/constants'

@Injectable()
export class ShopService {
  constructor(public readonly apiService: ApiService) {}

  public load(propertyId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/shop`)
  }

  public update(
    propertyId: string,
    shopId: string,
    shop: Shop,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[],
    forceTranslate: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/shop/${shopId}`, {
      shop,
      sourceLanguage,
      targetLanguages,
      forceTranslate,
    })
  }
}
