import { Action } from '@ngrx/store'

import { CartStatus, NormalizedResults, NormalizedResult } from '@models'
import { CartMeta } from '@redux/cart/cart.reducer'

export enum ActionTypes {
  Select = '[Cart] Select cart',
  Load = '[Cart] Load',
  LoadSuccess = '[Cart] Load Success',
  LoadFail = '[Cart] Load Fail',
  LoadItem = '[Cart] Load Item',
  LoadItemSuccess = '[Cart] Load Item Success',
  LoadItemFail = '[Cart] Load Item Fail',
  UpdateStatus = '[Cart] Update Status Status',
  UpdateStatusSuccess = '[Cart] Update Status Success',
  UpdateStatusFail = '[Cart] Update Status Fail',
  UpdateMeta = '[Cart] Update meta',
}

/**
 * Select action
 */
export class Select implements Action {
  readonly type = ActionTypes.Select
  constructor(public payload: { cartId: string }) {}
}

/**
 * Load actions
 */
export interface LoadPayload {
  propertyId: string
  // status?: string
  status?: CartStatus
  page?: number
  limit?: number
}

export class Load implements Action {
  readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load item actions
 */
export interface LoadItemPayload {
  propertyId?: string
  cartId?: string
}

export class LoadItem implements Action {
  readonly type = ActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}

export class LoadItemSuccess implements Action {
  readonly type = ActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}

export class LoadItemFail implements Action {
  readonly type = ActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Update status actions
 */
export interface UpdateStatusPayload {
  propertyId: string
  cartId: string
  status: CartStatus
  comment: string
}

export class UpdateStatus implements Action {
  readonly type = ActionTypes.UpdateStatus
  constructor(public payload: UpdateStatusPayload) {}
}

export class UpdateStatusSuccess implements Action {
  readonly type = ActionTypes.UpdateStatusSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateStatusPayload) {}
}

export class UpdateStatusFail implements Action {
  readonly type = ActionTypes.UpdateStatusFail
  constructor(public error: any, public meta: UpdateStatusPayload) {}
}

export interface UpdateMetaPayload {
  propertyId: string
}
export class UpdateMeta implements Action {
  public readonly type = ActionTypes.UpdateMeta
  constructor(public payload: UpdateMetaPayload, public meta: CartMeta) {}
}

export type Actions =
  | Select
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | UpdateStatus
  | UpdateStatusSuccess
  | UpdateStatusFail
  | UpdateMeta
