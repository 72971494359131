import { Form } from './form.model'

export enum MenuStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export enum MenuContentType {
  IMAGE = 'image',
  TEXT = 'text',
  BUTTON = 'button',
}

export enum MenuButtonType {
  URL = 'url',
  FORM = 'form',
  PAGE = 'page',
  POLL = 'poll',
  MODULE = 'module',
  TEMPLATE = 'template',
  SHOP_LINK = 'shop_link',
}

export enum MenuModules {
  WEATHER = 'weather',
  TRAVELLINE = 'travelline',
  BOOKING = 'booking',
  ROOMS = 'rooms',
  MAP = 'map',
  POLL = 'poll',
  RESTAURANT = 'restaurant',
  ROOM_SERVICE = 'roomService',
  MY_ORDERS = 'myOrders',
  MY_RESERVATION = 'myReservation',
  CONTACT_ADMINISTRATOR = 'contactAdministrator',
}

export interface BuilderTracking {
  value: string
  viewValue: string
  manuallyAdded: boolean
}

export interface Builder {
  pageId?: string
  facilitiesGroups?: string[]
  tracking?: BuilderTracking[]
  name?: {
    default: string
  }
  content?: (MenuContent | MenuButton)[]
  rootId?: string
  // custom fields
  _sortableLabel?: string // uses for sorting pages
}

export interface BuilderResponse {
  menuPagesTemplates: ReadonlyArray<Builder>
  menuPages: ReadonlyArray<Builder>
  results: string[]
  rootId: string
  modules: string[]
  forms: Form[]
}

export interface MenuContent {
  type: MenuContentType.TEXT | MenuContentType.IMAGE
  translations?: {
    default?: string
    [language: string]: string
  }
  // Custom fields
  isEdit?: boolean
  needTranslate?: boolean
}

export interface MenuButtonItem {
  type: MenuButtonType | null
  title: string
  config: {
    pageId?: string
    formId?: string
    pollId?: string
    templateId?: string

    module?: string
    place?: string

    url?: string
    targetSelf?: boolean

    rootCategory?: string
    category?: string
    product?: string
  }
}

export interface MenuButton {
  type: MenuContentType.BUTTON
  wifi?: boolean
  trackingCode?: string
  visibilityPeriod?: {
    start?: Date | null
    end?: Date | null
  }
  translations: {
    [language: string]: MenuButtonItem
  }
  // Custom fields
  isEdit?: boolean
  needTranslate?: boolean
}

export interface MenuName {
  default: string
  [language: string]: string
}

export interface Breadcrumb {
  name: string
  id: string
}
