import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'percentUnit',
})
export class PercentUnit implements PipeTransform {
  transform = (value: number): string => {
    // 0.042 -> 4.2
    const str = (value * 100).toFixed(2)
    return str.endsWith('.00') ? String(Number(str)) : str
  }
}
