import { Action } from '@ngrx/store'
import { NormalizedResult } from '@models'

export enum PushSubscriptionActionType {
  Save = '[PushSubscription] Save',
  SaveSuccess = '[PushSubscription] Save success',
  SaveFail = '[PushSubscription] Save fail',
}

/**
 * Save push subscription
 */
export class Save implements Action {
  public readonly type = PushSubscriptionActionType.Save
  constructor(public payload: PushSubscription) {}
}

export class SaveSuccess implements Action {
  public readonly type = PushSubscriptionActionType.SaveSuccess
  constructor(public payload: NormalizedResult, public meta: PushSubscription) {}
}

export class SaveFail implements Action {
  public readonly type = PushSubscriptionActionType.SaveFail
  constructor(public error: any, public meta: PushSubscription) {}
}
