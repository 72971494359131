import { Injectable } from '@angular/core'
import { CuisineService } from './cuisine.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, Effect, ofType } from '@ngrx/effects'
import * as fromCuisineActions from './cuisine.actions'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import { of } from 'rxjs'

const LOAD_CUISINES_ERROR = 'При загрузке информации о кухнях мира произошла ошибка'
const CREAT_CUISINE_SUCCESS = 'Новый тип кухни был успешно создан'
const CREAT_CUISINE_ERROR = 'При создании нового типа кухни произошла ошибка'
const UPDATE_CUISINE_SUCCESS = 'Информация была успешно обновлена'
const UPDATE_CUISINE_ERROR = 'При обновлении информации о кухне возникла ошибка'
const DELETE_CUISINE_SUCCESS = 'Тип кухни был успешно удален'
const DELETE_CUISINE_ERROR = 'При удалении типа кухни произошла ошибка'

@Injectable()
export class CuisineEffects {
  public constructor(
    public actions$: Actions,
    public cuisineService: CuisineService,
    public matSnackBar: MatSnackBar
  ) {}

  // Load effects
  @Effect()
  public load$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.Load),
    switchMap((action: fromCuisineActions.Load) => {
      const meta = action.payload
      return this.cuisineService
        .getList(action.payload)
        .pipe(
          map(x => new fromCuisineActions.LoadSuccess(x, meta)),
          catchError(x => of(new fromCuisineActions.LoadFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public loadFail$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.LoadFail),
    tap(() => {
      this.matSnackBar.open(LOAD_CUISINES_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  // Create effects
  @Effect()
  public create$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.Create),
    switchMap((action: fromCuisineActions.Create) => {
      const meta = action.payload
      return this.cuisineService
        .create(action.payload.cuisine)
        .pipe(
          map(x => new fromCuisineActions.CreateSuccess(x, meta)),
          catchError(x => of(new fromCuisineActions.CreateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.CreateSuccess),
    tap(() => {
      this.matSnackBar.open(CREAT_CUISINE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.CreateFail),
    tap(() => {
      this.matSnackBar.open(CREAT_CUISINE_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  // Update effects
  @Effect()
  public update$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.Update),
    switchMap((action: fromCuisineActions.Update) => {
      const meta = action.payload
      return this.cuisineService
        .update(action.payload.cuisineId, action.payload.cuisine)
        .pipe(
          map(x => new fromCuisineActions.UpdateSuccess(x, meta)),
          catchError(x => of(new fromCuisineActions.UpdateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.UpdateSuccess),
    tap(() => {
      this.matSnackBar.open(UPDATE_CUISINE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.UpdateFail),
    tap(() => {
      this.matSnackBar.open(UPDATE_CUISINE_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  // Delete effects
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.Delete),
    switchMap((action: fromCuisineActions.Delete) => {
      const meta = action.payload
      return this.cuisineService
        .delete(action.payload.cuisineId)
        .pipe(
          map(x => new fromCuisineActions.DeleteSuccess(x, meta)),
          catchError(x => of(new fromCuisineActions.DeleteFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.DeleteSuccess),
    tap(() => {
      this.matSnackBar.open(DELETE_CUISINE_SUCCESS, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(fromCuisineActions.ActionTypes.DeleteFail),
    tap(() => {
      this.matSnackBar.open(DELETE_CUISINE_ERROR, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )
}
