import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Actions, Effect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import * as fromRouter from '@redux/router'

import * as fromPushActions from './push.actions'
import { PushService } from './push.service'
import { I18nService } from '@shared/services'
import { PushSendType } from '@models'

@Injectable()
export class PushEffects {
  public constructor(
    private actions$: Actions,
    private pushService: PushService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}

  /**
   * Load pushes effects
   */

  @Effect()
  public loadPushes$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.Load),
    switchMap((action: fromPushActions.Load) => {
      return this.pushService
        .getList(action.payload.propertyId, {
          status: action.payload.status,
          limit: action.payload.limit,
          page: action.payload.page,
        })
        .pipe(
          map(x => new fromPushActions.LoadSuccess(x, action.payload)),
          catchError(x => of(new fromPushActions.LoadFail(x, action.payload)))
        )
    })
  )

  @Effect({ dispatch: false })
  public loadPushesFail$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.LoadFail),
    tap(() => {
      const loadError = this.translationService.translate('Push.error.loadError')
      this.matSnackBar.open(loadError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Create push effects
   */
  @Effect()
  public createPush$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.Create),
    switchMap((action: fromPushActions.Create) => {
      const { propertyId, push } = action.payload
      return this.pushService.createPush(propertyId, push).pipe(
        map(x => new fromPushActions.CreateSuccess(x, action.payload)),
        catchError(x => of(new fromPushActions.CreateFail(x, action.payload)))
      )
    })
  )

  @Effect()
  public afterCreateSuccess$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.CreateSuccess),
    map(
      (action: fromPushActions.CreateSuccess) =>
        new fromRouter.Go({
          path:
            action.meta.push.sendType === PushSendType.IMMEDIATELY
              ? ['properties', action.meta.propertyId, 'pushes', 'create', 'success']
              : ['properties', action.meta.propertyId, 'pushes'],
        })
    )
  )

  @Effect({ dispatch: false })
  public createPushFail$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.CreateFail),
    tap(() => {
      const createError = this.translationService.translate('Push.error.createError')
      this.matSnackBar.open(createError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Update push effects
   */
  @Effect()
  public updatePush$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.Update),
    switchMap((action: fromPushActions.Update) => {
      const { propertyId, pushId, push } = action.payload
      return this.pushService.updatePush(propertyId, pushId, push).pipe(
        map(x => new fromPushActions.UpdateSuccess(x, action.payload)),
        catchError(x => of(new fromPushActions.UpdateFail(x, action.payload)))
      )
    })
  )

  @Effect()
  public afterUpdateSuccess$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.UpdateSuccess),
    tap((action: fromPushActions.UpdateSuccess) => {
      if (action.meta.push.sendType === PushSendType.DEFERRED) {
        const message = this.translationService.translate('Push.notification.updateSuccess')
        this.matSnackBar.open(message, '', {
          panelClass: ['bg-green'],
          duration: 2000,
        })
      }
    }),
    map(
      (action: fromPushActions.UpdateSuccess) =>
        new fromRouter.Go({
          path:
            action.meta.push.sendType === PushSendType.IMMEDIATELY
              ? ['properties', action.meta.propertyId, 'pushes', 'create', 'success']
              : ['properties', action.meta.propertyId, 'pushes'],
        })
    )
  )

  @Effect({ dispatch: false })
  public updatePushFail$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.UpdateFail),
    tap(() => {
      const message = this.translationService.translate('Push.error.updateError')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Delete push effects
   */
  @Effect()
  public deletePush$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.Delete),
    switchMap((action: fromPushActions.Delete) => {
      const { propertyId, pushId } = action.payload
      return this.pushService.deletePush(propertyId, pushId).pipe(
        map(x => new fromPushActions.DeleteSuccess(x, action.payload)),
        catchError(x => of(new fromPushActions.DeleteFail(x, action.payload)))
      )
    })
  )

  @Effect()
  public afterDeleteSuccess$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.DeleteSuccess),
    tap(() => {
      const message = this.translationService.translate('Push.notification.deleteSuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 2000,
      })
    }),
    map(
      (action: fromPushActions.DeleteSuccess) =>
        new fromRouter.Go({ path: ['properties', action.meta.propertyId, 'pushes'] })
    )
  )

  @Effect({ dispatch: false })
  public deletePushFail$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.DeleteFail),
    tap(() => {
      const message = this.translationService.translate('Push.error.deleteError')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )

  /**
   * Load push item effects
   */
  @Effect()
  public loadItem$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.LoadItem),
    switchMap((action: fromPushActions.LoadItem) => {
      const meta = action.payload
      return this.pushService.get(action.payload.propertyId, action.payload.pushMessageId).pipe(
        map(x => new fromPushActions.LoadItemSuccess(x, meta)),
        catchError(x => of(new fromPushActions.LoadItemFail(x, meta)))
      )
    })
  )

  @Effect({ dispatch: false })
  public loadItemFail$ = this.actions$.pipe(
    ofType(fromPushActions.PushActionType.LoadItemFail),
    tap(() => {
      const loadItemError = this.translationService.translate('Push.error.loadItemError')
      this.matSnackBar.open(loadItemError, '', {
        panelClass: ['bg-red'],
        duration: 2000,
      })
    })
  )
}
