<div class="w-100 mw6 center mv5 flex flex-column" *ngIf="!isAuthorized; else loading">
  <div class="bg-white shadow-2 pa4 pa5-l pb4 br2">
    <!-- <div class="f2 fw3 mb3">Hotb</div> -->
    <mat-icon svgIcon="hot:hotbot" class="mb4 hotbot-logo"></mat-icon>

    <form
      novalidate
      [formGroup]="authForm"
      (ngSubmit)="login()"
      class="auth-form-container"
      [class.mb5]="!isBookingSuiteEnabled"
    >
      <mat-form-field class="w-100" id="form-auth--login" appearance="outline">
        <mat-label>{{ 'Auth.field.email' | translate: locale.language }}</mat-label>
        <input
          matInput
          type="email"
          tabindex="1"
          autocomplete="off"
          autocapitalize="off"
          formControlName="email"
        />
      </mat-form-field>

      <mat-form-field class="w-100" id="form-mailout--password" appearance="outline">
        <mat-label>{{ 'Auth.field.password' | translate: locale.language }}</mat-label>
        <input
          matInput
          type="password"
          tabindex="2"
          autocomplete="off"
          autocapitalize="off"
          formControlName="password"
        />
      </mat-form-field>

      <div class="w-100">
        <button mat-flat-button color="accent" type="submit" class="f5 w-100 pv2" tabindex="3">
          {{ 'Auth.action.signIn' | translate: locale.language }}
        </button>
      </div>
    </form>

    <div class="w-100 mt3 tc">
      <a mat-button [routerLink]="'/recover'" class="gray">
        {{ 'Auth.action.forgotPassword' | translate: locale.language }}
      </a>
    </div>

    <ng-container *ngIf="isBookingSuiteEnabled">
      <div class="mv5 tc relative black-50">
        <div class="bb b--black-10"></div>
        <span class="bg-white ph3 absolute absolute--center"
          >{{ 'Auth.orLabel' | translate: locale.language }}</span
        >
      </div>

      <div class="tc">
        <a [href]="connectWithBookingUrl" tabindex="4" class="link"
          ><img class="w5" [src]="connectWithBookingImageUrl" alt="Connect with Booking Suite"
        /></a>
      </div>
    </ng-container>
    <div style="text-align: right;">
      <hot-app-language-selector class="self-end"></hot-app-language-selector>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="flex w-100 h-100 items-center justify-center">
    <mat-spinner [diameter]="64"></mat-spinner>
  </div>
</ng-template>
