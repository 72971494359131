<section class="hot-builder-section mb4">
  <div *ngFor="let image of images; let i = index" class="hot-bulder-item mv2">
    <div class="overlay-button--delete" *ngIf="!isEditMode">
      <mat-icon class="hot-bulder-item-remove" (click)="handleRemove(i, 'images')">delete</mat-icon>
    </div>
    <img [hotProxySrc]="image?.translations?.default" alt="" />
  </div>

  <hot-image-cropper
    class="hot-builder-image-loader"
    [class.hot-builder-image-loader-crop]="imageLoader.loaded"
    [class.hot-builder-image-loader-disabled]="isEditMode"
    [class.hot-builder-image-loader-slim]="images.length"
    [placeholder]="
      'MenuBuilder.action.addImage' | translate: locale.language:{ count: images.length }
    "
    #imageLoader
    (change)="onImageChange($event)"
    (click)="handleAddImage()"
  >
  </hot-image-cropper>
</section>
