import * as R from 'ramda'

export const updateSettings = (key, value, settingsKey) => {
  let settings

  try {
    settings = JSON.parse(window.localStorage[settingsKey] || '{}')
  } catch (e) {
    settings = {}
  }

  const newSettings = R.merge(settings, {
    [key]: value,
  })

  window.localStorage[settingsKey] = JSON.stringify(newSettings)
  return newSettings
}

export const restoreSettings = settingsKey => {
  try {
    return JSON.parse(window.localStorage[settingsKey])
  } catch (e) {
    return {}
  }
}
