import { Action } from '@ngrx/store'
import { NormalizedResult, ProductCategory } from '@models'
import { LanguageType } from '@shared/constants'

export enum ProductCategoryActionTypes {
  Create = '[ProductCategory] Create',
  CreateSuccess = '[ProductCategory] Create Success',
  CreateFail = '[ProductCategory] Create Fail',

  Load = '[ProductCategory] Load',
  LoadSuccess = '[ProductCategory] Load Success',
  LoadFail = '[ProductCategory] Load Fail',

  Update = '[ProductCategory] Update',
  UpdateSuccess = '[ProductCategory] Update Success',
  UpdateFail = '[ProductCategory] Update Fail',

  Delete = '[ProductCategory] Delete',
  DeleteSuccess = '[ProductCategory] Delete Success',
  DeleteFail = '[ProductCategory] Delete Fail',
}

/**
 * Create ProductCategory actions
 */

export interface CreatePayload {
  propertyId: string
  roomServiceId: string
  productCategory: ProductCategory
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
}

export class Create implements Action {
  readonly type = ProductCategoryActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = ProductCategoryActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = ProductCategoryActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load ProductCategory actions
 */

export interface LoadPayload {
  propertyId?: string
  roomServiceId?: string
}

export class Load implements Action {
  readonly type = ProductCategoryActionTypes.Load
  constructor(public payload: LoadPayload = {}) {}
}

export class LoadSuccess implements Action {
  readonly type = ProductCategoryActionTypes.LoadSuccess
  // constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
  constructor(public payload, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ProductCategoryActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Update ProductCategory actions
 */
export interface UpdatePayload {
  propertyId: string
  roomServiceId: string
  productCategoryId: string
  productCategory: ProductCategory
  sourceLanguage: LanguageType
  targetLanguages: LanguageType[]
  forceTranslate: boolean
}

export class Update implements Action {
  readonly type = ProductCategoryActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = ProductCategoryActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = ProductCategoryActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete ProductCategory actions
 */
export interface DeletePayload {
  propertyId: string
  roomServiceId: string
  productCategoryId: string
}

export class Delete implements Action {
  readonly type = ProductCategoryActionTypes.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = ProductCategoryActionTypes.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = ProductCategoryActionTypes.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type ProductCategoryActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
