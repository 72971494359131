import {
  Component,
  OnInit,
  HostBinding,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ViewChild,
  AfterContentChecked,
  Inject,
} from '@angular/core'
import { PropertyFacade } from '@redux/property/property.facade'
import { OnboardingFacade } from '@redux/property/onboarding.facade'
import { map, startWith, withLatestFrom, take } from 'rxjs/operators'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { WebChatWidgetService, AppTitleService } from '@shared/services'

import { select, Store } from '@ngrx/store'
import * as fromRoot from '@redux'

@Component({
  selector: 'hot-page-heading',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="wrap T-bg">
      <div class="page-name mb2 mb0-ns w-auto-ns order-1">
        <div class="f5 fw6 fw4-l f3-l page-title T-heading" #pageTitleElement>
          <ng-content select="[page-title]"></ng-content>
        </div>
        <div class="f7 page-subtitle T-text-muted ttu-l tracked-l fw5-l">
          <ng-content select="[page-subtitle]"></ng-content>
        </div>
      </div>

      <div class="flex-auto order-2"></div>

      <div class="extra-buttons w-100 w-auto-ns order-5 order-3-ns mr3-ns">
        <ng-content></ng-content>
      </div>

      <!--
      <button
        *ngIf="isWidgetLoaded"
        mat-flat-button
        class="T-button--flat bg-animate page-heading__support-btn"
        (click)="openChat()"
      >
        {{ 'components.Common.supportButton' | translate: locale.language }}
      </button>
      -->
      <div class="flex-none nr2 mr0-ns order-4">
        <button
          *ngIf="helpIsVisible"
          type="button"
          mat-icon-button
          class="T-button--flat bg-animate mr1-ns"
          (click)="showHelp()"
        >
          <mat-icon class="T-icon">help_outline</mat-icon>
        </button>

        <hot-app-language-selector
          [property]="property$ | async"
          [user]="user$ | async"
          class="mr1-ns"
        ></hot-app-language-selector>

        <a
          mat-icon-button
          *ngIf="(goalsLink$ | async) as goalsLink"
          [routerLink]="goalsLink"
          [routerLinkActive]="'dn'"
          [matTooltip]="'Onboarding.linkTooltip' | translate: locale.language"
          [matTooltipPosition]="'below'"
          class="T-button--flat bg-animate red mr1-ns"
        >
          <mat-icon style="transform:rotate(180deg)">info</mat-icon>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./page-heading.component.scss'],
})
export class PageHeadingComponent implements OnInit, OnChanges, AfterContentChecked {
  @HostBinding('class.withoutOffset')
  @Input()
  withoutOffset = false

  @Input()
  public pageTitle = ''

  @ViewChild('pageTitleElement', { static: true })
  public pageTitleElement
  public helpIsVisible = false

  public notCompletedCards$ = this.onboardingFacade.notCompletedCards$
  public property$ = this.propertyFacade.property$
  public user$ = this.store$.pipe(select(fromRoot.selectAuthorizedUser))

  public goalsLink$ = this.notCompletedCards$.pipe(
    withLatestFrom(this.property$),
    map(([goals, property]) => {
      if (goals.length && property) {
        return ['/properties', property._id, 'hi']
      }
      return null
    }),
    startWith(null)
  )

  prevContentTitle = null

  ngOnInit() {
    // const hasHelp = typeof window.showSupportChat === 'function'
    if (typeof window.prefillSupportChat === 'function') {
      this.user$.pipe(take(1)).subscribe(user => {
        const opts = {
          prefill: {
            name: {
              value: user.name,
              readOnly: false,
            },
            email: {
              value: user.email,
              readOnly: false,
            },
          },
        }
        window.prefillSupportChat(opts)
      })
    }
    // this.helpIsVisible = typeof window.showSupportChat === 'function'
    this.updateTitle()
  }

  ngOnChanges(changes) {
    if (changes.pageTitle) {
      this.updateTitle()
    }
  }
  ngAfterContentChecked(): void {
    if (this.getPageTitleFromContent() !== this.prevContentTitle) {
      this.prevContentTitle = this.getPageTitleFromContent()
      this.updateTitle()
    }
  }

  showHelp() {
    window.showSupportChat()
  }

  updateTitle() {
    const pageTitle = this.pageTitle || this.getPageTitleFromContent()
    this.titleService.setTitle(pageTitle)
  }

  private getPageTitleFromContent() {
    return (
      this.pageTitleElement &&
      this.pageTitleElement.nativeElement &&
      this.pageTitleElement.nativeElement.textContent
    )
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private titleService: AppTitleService,
    private store$: Store<fromRoot.State>,
    private propertyFacade: PropertyFacade,
    private onboardingFacade: OnboardingFacade,
    private webChatWidgetService: WebChatWidgetService
  ) {}

  public openChat() {
    this.webChatWidgetService.openChat()
  }

  public get isWidgetLoaded() {
    return this.webChatWidgetService.isWidgetReady()
  }
}
