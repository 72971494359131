import { Component, EventEmitter, Output } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'hot-emoji-picker',

  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
  template: `
    <span class="emoji-picker" (mouseenter)="showPicker()" (mouseleave)="hidePicker()">
      <span class="emoji-picker__button">
        <mat-icon>insert_emoticon</mat-icon>
      </span>
      <emoji-mart
        class="emoji-picker__picker"
        *ngIf="isVisible"
        (emojiClick)="addEmoji($event)"
        [darkMode]="false"
        [showPreview]="false"
        [@fadeAnimation]
      ></emoji-mart>
    </span>
  `,
  styles: [
    `
      .emoji-picker {
        position: relative;
      }

      .emoji-picker__button {
        background: none;
        border: none;
        padding: 0;
        opacity: 0.7;
        transition: opacity 0.5s ease;
      }

      .emoji-picker:hover .emoji-picker__button {
        opacity: 1;
      }

      .emoji-picker__picker {
        position: absolute;
        top: 0;
        padding-top: 20px;
        right: 50%;
        transform: translateX(50%);
        z-index: 10;
      }
    `,
  ],
})
export class EmojiPickerComponent {
  @Output() public emojiSelect = new EventEmitter<any>()

  public isVisible = false

  constructor() {}

  public showPicker() {
    this.isVisible = true
  }

  public hidePicker() {
    this.isVisible = false
  }

  public addEmoji($event) {
    const emoji = $event.emoji.native
    this.emojiSelect.emit({ event: $event, emoji })
  }
}
