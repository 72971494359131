import { Injectable } from '@angular/core'
import * as R from 'ramda'

import { select, Store } from '@ngrx/store'

import { combineLatest } from 'rxjs'
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators'

import { UserPermission, UserPropertyPermission } from '@models'
import * as fromRoot from '@redux'
import { PropertyFacade } from '@redux/property/property.facade'

const equals = R.equals as <T>(a: T, b: T) => boolean

@Injectable({ providedIn: 'root' })
export class UserProfileFacade {
  public user$ = this.store$.pipe(select(fromRoot.selectAuthorizedUser))
  public permissions$ = combineLatest([
    this.store$.pipe(select(fromRoot.selectUserPermissions)),
    this.propertyFacade.property$.pipe(
      map((property): UserPropertyPermission[] => (property && property.permissions) || [])
    ),
  ]).pipe(
    map(([userPermissions, propertyPermissions]) => {
      return R.uniq([...userPermissions, ...propertyPermissions])
    }),
    distinctUntilChanged(equals),
    shareReplay(1)
  )

  public canAccessAdminSection$ = this.can('properties.create')

  constructor(private store$: Store<fromRoot.State>, private propertyFacade: PropertyFacade) {}

  can(permission: UserPermission) {
    return this.permissions$.pipe(map(permissions => permissions.includes(permission)))
  }

  canAny(permissions: UserPermission[]) {
    return this.permissions$.pipe(
      map(userPermissions => R.intersection(userPermissions, permissions).length > 0)
    )
  }
}
