import * as R from 'ramda'

import { ChatMessage, FormResponse, ChatPageVisit, ShopCart } from '@models'

import { Actions, ActionTypes } from './chat.actions'

const MESSAGES_LIMIT = 20

export interface ChatMeta {
  total?: number
  page?: number
  limit?: number
}
export interface PropertyChatsState {
  loaded: boolean
  chats: {
    inbox: string[]
    all: string[]
  }
  meta: {
    inbox: ChatMeta
    all: ChatMeta
  }
}

export interface ChatState {
  unreadCounter: number
  loadingPastMessages: boolean
  allPastMessagesLoaded: boolean
  messages: ChatMessage[]
  visitedPages: ChatPageVisit[]
  formResponses: FormResponse[]
  shopCarts: ShopCart[]
}

export interface State {
  byPropertyId: R.Dictionary<PropertyChatsState>
  byChatId: R.Dictionary<ChatState>
  selectedChat: string | null
  selectedChatsTabIndex: number
}

export const propertyChatsInitialState: PropertyChatsState = {
  loaded: false,
  chats: {
    inbox: [],
    all: [],
  },
  meta: {
    inbox: {
      total: 0,
      page: 0,
      limit: 10,
    },
    all: {
      total: 0,
      page: 0,
      limit: 10,
    },
  },
}

export const chatInitialState: ChatState = {
  loadingPastMessages: false,
  allPastMessagesLoaded: false,
  unreadCounter: 0,
  messages: [],
  visitedPages: [],
  formResponses: [],
  shopCarts: [],
}

export const initialState: State = {
  selectedChatsTabIndex: 0,
  selectedChat: null,
  byPropertyId: {},
  byChatId: {},
}

function byPropertyReducer(state = propertyChatsInitialState, action: Actions): PropertyChatsState {
  switch (action.type) {
    case ActionTypes.LoadSuccess: {
      const isFirstPage = action.payload.meta.page === 0
      return {
        ...state,
        loaded: true,
        chats: {
          ...state.chats,
          [action.meta.params.tab]: isFirstPage
            ? action.payload.results
            : Array.from(
                new Set(state.chats[action.meta.params.tab].concat(action.payload.results))
              ),
        },
        meta: {
          ...state.meta,
          [action.meta.params.tab]: {
            ...state.meta[action.meta.params.tab],
            ...action.payload.meta,
          },
        },
      }
    }

    case ActionTypes.UpdateChat: {
      // update chat in "Inbox" tab
      if (state.chats.inbox.includes(action.payload.chat._id)) {
        if (action.payload.chat.pausedAt || action.payload.chat.pendingAt) {
          return state
        } else {
          return {
            ...state,
            chats: {
              inbox: state.chats.inbox.filter(chatId => chatId !== action.payload.chat._id),
              all: [action.payload.chat._id, ...state.chats.all],
            },
            meta: {
              inbox: {
                ...state.meta.inbox,
                total: state.meta.inbox.total - 1,
              },
              all: {
                ...state.meta.all,
                total: state.meta.all.total + 1,
              },
            },
          }
        }
      }

      // update chat in "All" tab
      if (state.chats.all.includes(action.payload.chat._id)) {
        if (!action.payload.chat.pausedAt && !action.payload.chat.pendingAt) {
          return state
        } else {
          return {
            ...state,
            chats: {
              inbox: [action.payload.chat._id, ...state.chats.inbox],
              all: state.chats.all.filter(chatId => chatId !== action.payload.chat._id),
            },
            meta: {
              inbox: {
                ...state.meta.inbox,
                total: state.meta.inbox.total + 1,
              },
              all: {
                ...state.meta.all,
                total: state.meta.all.total - 1,
              },
            },
          }
        }
      }

      // add new chat in "Inbox" tab
      if (action.payload.chat.pausedAt || action.payload.chat.pendingAt) {
        return {
          ...state,
          chats: {
            ...state.chats,
            inbox: [action.payload.chat._id, ...state.chats.inbox],
          },
          meta: {
            ...state.meta,
            inbox: {
              ...state.meta.inbox,
              total: state.meta.inbox.total + 1,
            },
          },
        }
      }
      break
    }
  }
  return state
}

function byChatReducer(state = chatInitialState, action: Actions): ChatState {
  switch (action.type) {
    case ActionTypes.ReceivedMessage: {
      let found = false
      const message = action.payload.message
      const messages = state.messages.map(x => {
        if (x._id === message._id) {
          found = true
          return message
        }
        return x
      })
      return {
        ...state,
        messages: found ? messages : [message].concat(state.messages),
      }
    }

    case ActionTypes.EditedMessages: {
      let found = false
      const updatedMessages = action.payload.messages
      const messages = state.messages.map(x => {
        const updatedMessage = updatedMessages.find(xx => x._id === xx._id)
        if (updatedMessage) {
          found = true
          return updatedMessage
        }
        return x
      })
      return {
        ...state,
        messages: found ? messages : state.messages,
      }
    }

    case ActionTypes.LoadMessagesSuccess: {
      return {
        ...state,
        allPastMessagesLoaded: false,
        messages: action.payload.messages,
      }
    }

    case ActionTypes.LoadVisitedPagesSuccess: {
      return {
        ...state,
        visitedPages: action.payload.visitedPages,
      }
    }

    case ActionTypes.SearchMessages: {
      return {
        ...state,
        loadingPastMessages: true,
      }
    }

    case ActionTypes.SearchMessagesFail: {
      return {
        ...state,
        loadingPastMessages: false,
      }
    }

    case ActionTypes.SearchMessagesSuccess: {
      const loadedPastMessages = action.payload.messages
      return {
        ...state,
        loadingPastMessages: false,
        allPastMessagesLoaded: loadedPastMessages.length < MESSAGES_LIMIT,
        messages: state.messages.concat(loadedPastMessages),
      }
    }

    case ActionTypes.LoadFormResponsesSuccess: {
      return {
        ...state,
        formResponses: R.values(action.payload.formResponses),
      }
    }

    case ActionTypes.LoadShopCartsSuccess: {
      return {
        ...state,
        shopCarts: R.values(action.payload.shopCarts),
      }
    }
  }
  return state
}

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.Select: {
      return R.assoc('selectedChat', action.payload.chatId, state)
    }

    case ActionTypes.SelectTab: {
      return R.assoc('selectedChatsTabIndex', action.payload.tabIndex, state)
    }

    case ActionTypes.LoadSuccess: {
      const path = ['byPropertyId', action.meta.propertyId]
      const newSubState = byPropertyReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }

    case ActionTypes.ReceivedMessage: {
      const path = ['byChatId', action.payload.message.chat]
      const newSubState = byChatReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }

    case ActionTypes.EditedMessages: {
      const path = ['byChatId', action.payload.messages[0].chat]
      const newSubState = byChatReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }

    case ActionTypes.SearchMessages: {
      const path = ['byChatId', action.payload.chatId]
      const newSubState = byChatReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }

    case ActionTypes.LoadMessagesSuccess:
    case ActionTypes.LoadVisitedPagesSuccess:
    case ActionTypes.SearchMessagesSuccess:
    case ActionTypes.SearchMessagesFail: {
      const path = ['byChatId', action.meta.chatId]
      const newSubState = byChatReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }

    case ActionTypes.LoadShopCartsSuccess: {
      const path = ['byChatId', action.meta.chatId]
      const newSubState = byChatReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }

    case ActionTypes.LoadFormResponsesSuccess: {
      const path = ['byChatId', action.meta.chatId]
      const newSubState = byChatReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }

    case ActionTypes.UpdateChat: {
      const path = ['byPropertyId', action.payload.chat.property]
      const newSubState = byPropertyReducer(R.path(path, state), action)
      return R.assocPath(path, newSubState, state)
    }
  }
  return state
}

export const selectPropertiesSubstates = (state: State) => state.byPropertyId
export const selectPropertySubstateInboxChatsIds = (state: PropertyChatsState) =>
  state && state.chats && state.chats.inbox
export const selectPropertySubstateAllChatsIds = (state: PropertyChatsState) =>
  state && state.chats && state.chats.all
export const selectPropertySubstateLoaded = (state: PropertyChatsState) => state && state.loaded
export const selectCurrentChatId = (state: State) => state && state.selectedChat
export const selectCurrentChatsTabIndex = (state: State) => state && state.selectedChatsTabIndex

export const selectChatsSubstate = (state: State) => state.byChatId
export const selectChatSubstateMessages = (state: ChatState) => state && state.messages
export const selectChatSubstateVisitedPages = (state: ChatState) => state && state.visitedPages
export const selectChatSubstateFormResponses = (state: ChatState) => state && state.formResponses
export const selectChatSubstateShopCarts = (state: ChatState) => state && state.shopCarts
export const selectChatSubstateLoadingPastMessages = (state: ChatState) =>
  state && state.loadingPastMessages
export const selectChatSubstateAllPastMessagesLoaded = (state: ChatState) =>
  state && state.allPastMessagesLoaded
export const selectChatSubstateUnreadCounter = (state: ChatState) => state && state.unreadCounter
export const selectChatSubstateInboxCounter = (state: PropertyChatsState) =>
  state && state.meta && state.meta.inbox && state.meta.inbox.total ? state.meta.inbox.total : 0
export const selectChatSubstateAllCounter = (state: PropertyChatsState) =>
  state && state.meta && state.meta.all && state.meta.all.total ? state.meta.all.total : 0
export const selectChatSubstatePage = (state: PropertyChatsState) =>
  state && state.meta && state.meta.all && state.meta.all.page ? state.meta.all.page : 0
