import { Injectable, OnDestroy } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { BehaviorSubject, Observable, of, Subscription } from 'rxjs'
import { catchError, switchMap, tap, take, map } from 'rxjs/operators'

import { ShopDeliveryOption, ShopPickupPoint } from '@models'
import { ApiService, I18nService } from '@shared/services'

interface TranslationParams {
  sourceLanguage: string
  forceTranslate?: boolean
}

export interface PickupPointsState {
  pickupPoints: ShopPickupPoint[]
}

interface DeliveryResponse {
  deliveryOptions: ShopDeliveryOption[]
  pickupPoints: ShopPickupPoint[]
}

@Injectable({ providedIn: 'root' })
export class ShopDeliveryFacade implements OnDestroy {
  public state$ = new BehaviorSubject<DeliveryResponse>({
    deliveryOptions: [],
    pickupPoints: [],
  })

  public pickupPoints$ = this.state$.pipe(map(state => state.pickupPoints))

  private filters$ = new BehaviorSubject<{ property: string; shop: string }>({
    property: null,
    shop: null,
  })

  private filtersSubscription: Subscription = null

  constructor(
    private apiService: ApiService,
    private matSnackBar: MatSnackBar,
    private translationService: I18nService
  ) {
    this.filtersSubscription = this.filters$
      .pipe(
        switchMap(
          (filters): Observable<DeliveryResponse> => {
            if (!filters.property || !filters.shop) {
              return of(null)
            }
            return this.apiLoadDeliveryInfo(filters).pipe(catchError(() => of(null)))
          }
        )
      )
      .subscribe(data => {
        if (data) {
          this.state$.next(data)
        } else {
          this.state$.next({ deliveryOptions: [], pickupPoints: [] })
        }
      })
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe()
  }

  public loadDeliveryInfo(filters: {
    property: string
    shop: string
  }): Observable<DeliveryResponse> {
    this.filters$.next(filters)
    return this.state$
  }

  public updateDeliveryOptions({
    shop,
    property,
    deliveryOptions,
  }: {
    property: string
    shop: string
    deliveryOptions: ShopDeliveryOption[]
  }) {
    return this.updateState(
      this.apiService.patch(`properties/${property}/shop/${shop}/delivery`, {
        deliveryOptions,
      }),
      'Shop.deliveryOptions.messages.deliveryOptionsUpdated',
      'Shop.deliveryOptions.messages.deliveryOptionsError'
    )
  }

  public addPickupPoint({
    property,
    shop,
    pickupPoint,
    sourceLanguage,
  }: {
    property: string
    shop: string
    pickupPoint: Partial<ShopPickupPoint>
  } & TranslationParams): Observable<DeliveryResponse> {
    return this.updateState(
      this.apiService.post(`properties/${property}/shop/${shop}/delivery/pickup-points`, {
        pickupPoint,
        sourceLanguage,
      }),
      'Shop.deliveryOptions.messages.pickupPointAdded',
      'Shop.deliveryOptions.messages.pickupPointError'
    )
  }

  public updatePickupPoint({
    property,
    shop,
    pickupPoint,
    sourceLanguage,
  }: {
    property: string
    shop: string
    pickupPoint: Partial<ShopPickupPoint>
  } & TranslationParams): Observable<DeliveryResponse> {
    return this.updateState(
      this.apiService.patch(
        `properties/${property}/shop/${shop}/delivery/pickup-points/${pickupPoint._id}`,
        {
          pickupPoint,
          sourceLanguage,
        }
      ),
      'Shop.deliveryOptions.messages.pickupPointUpdated',
      'Shop.deliveryOptions.messages.pickupPointError'
    )
  }

  public deletePickupPoint({
    property,
    shop,
    pickupPoint,
  }: {
    property: string
    shop: string
    pickupPoint: string
  }): Observable<DeliveryResponse> {
    return this.updateState(
      this.apiService.delete(
        `properties/${property}/shop/${shop}/delivery/pickup-points/${pickupPoint}`
      ),
      'Shop.deliveryOptions.messages.pickupPointDeleted',
      'Shop.deliveryOptions.messages.pickupPointError'
    )
  }

  private apiLoadDeliveryInfo({
    property,
    shop,
  }: {
    property: string
    shop: string
  }): Observable<DeliveryResponse> {
    return this.apiService.get(`properties/${property}/shop/${shop}/delivery`, {})
  }

  private updateState(
    response$: Observable<DeliveryResponse>,
    successKey: string,
    errorKey: string
  ) {
    return response$.pipe(
      take(1),
      tap({
        next: value => {
          this.showSuccessMessage(successKey)
          this.state$.next(value)
        },
        error: () => {
          this.showErrorMessage(errorKey)
        },
      }),
      catchError(() => of(null))
    )
  }

  private showSuccessMessage(key: string) {
    this.matSnackBar.open(this.translationService.translate(key), '', {
      panelClass: ['bg-green'],
      duration: 3000,
    })
  }

  private showErrorMessage(key: string) {
    this.matSnackBar.open(this.translationService.translate(key), '', {
      panelClass: ['bg-red'],
      duration: 3000,
    })
  }
}
