import { Pipe, PipeTransform } from '@angular/core'

const shortFormat = {
  en: '🇺🇸 EN',
  ru: '🇷🇺 RU',
  es: '🇪🇸 ES',
}

@Pipe({
  name: 'localeLabel',
})
export class LocaleLabelPipe implements PipeTransform {
  transform(value: any, format?: string): any {
    switch (value) {
      case 'en':
        return format === 'shortFormat' ? shortFormat['en'] : '🇺🇸 English'
      case 'ru':
        return format === 'shortFormat' ? shortFormat['ru'] : '🇷🇺 Russian'
      case 'es':
        return format === 'shortFormat' ? shortFormat['es'] : '🇪🇸 Spanish'
      default:
        return '❓'
    }
  }
}
