import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { I18nService } from '@shared/services'

@Component({
  selector: 'hot-confirmation-dialog',
  template: `
    <div class="flex flex-column">
      <h1 mat-dialog-title>{{ getTitle }}</h1>
      <div mat-dialog-content>
        <p class="mb4">{{ getText }}</p>
      </div>
      <div class="flex justify-end ma2">
        <button class="mr3" color="primary" mat-button (click)="onCancel()">
          {{ 'common.cancel' | translate: locale.language }}
        </button>
        <button mat-raised-button color="primary" (click)="onConfirm()">
          {{ confirmationButtonText }}
        </button>
      </div>
    </div>
  `,
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public translation: I18nService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string
      text: string
      confirmButtonText: string
    }
  ) {}

  public onConfirm(): void {
    this.dialogRef.close(true)
  }

  public onCancel(): void {
    this.dialogRef.close(false)
  }

  public get getTitle() {
    return this.data.title || this.translation.translate('common.youSure')
  }

  public get getText() {
    return this.data.text || this.translation.translate('common.actionCannotUndone')
  }

  public get confirmationButtonText() {
    return this.data.confirmButtonText || this.translation.translate('common.ok')
  }
}
