import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnChanges,
  Inject,
} from '@angular/core'
import { UploadFileResult, UploadFileStatus, UploadFile, Restaurant, Cuisine } from '@models'
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn } from '@angular/forms'
import * as R from 'ramda'
import { urlValidator, numberValidator, rangeValidator } from '@shared/validators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/internal/operators'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import * as translatableFieldUtils from '@models/translatable-field'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

const MAX_COMMON_LENGTH = 100
const MAX_LAT_LNG_LENGTH = 100
const MAX_ADDRESS_LENGTH = 200
const MAX_DESCRIPTION_LENGTH = 3000

const MIN_LAT_VALUE = -90
const MAX_LAT_VALUE = 90
const MIN_LNG_VALUE = -180
const MAX_LNG_VALUE = 180

@Component({
  selector: 'hot-restaurant-form',
  templateUrl: './restaurant-form.component.html',
  styleUrls: ['./restaurant-form.component.scss'],
})
export class RestaurantFormComponent implements OnInit, OnChanges {
  @Input() public restaurant: Restaurant
  @Input() public categoriesList: string[] = []
  @Input() public currentLanguage: string
  @Input() public defaultLanguage
  @Input() public availableLanguages
  @Output()
  public submit = new EventEmitter<{
    restaurant: Restaurant
    sourceLanguage: string
    targetLanguages: string[]
  }>()
  @Output() public cancel = new EventEmitter()

  @ViewChild('categoryInput', { static: true }) categoryInput: ElementRef

  public form = new FormGroup({})
  public uploadLogoStatus = null

  public photoUploaded = false
  public photoUploadChanged = false

  public separatorKeysCodes = [ENTER, COMMA]

  public categories: string[] = []
  public filteredCategories: Observable<string[]>
  public categoryCtrl = new FormControl([], Validators.required)

  private cuisines: Cuisine[] = []
  private cuisinesChanged = false

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      map((category: string | null) => {
        return category
          ? this._filter(category)
          : (this.categoriesList && this.categoriesList.slice()) || []
      })
    )
  }

  ngOnInit() {
    this.initializeForm()
    if (this.restaurant) {
      this.categories = this.restaurant.category
      this.categoriesList =
        this.categoriesList && this.categoriesList.filter(el => !this.categories.includes(el))
      this.photoUploaded = Boolean(this.restaurant && this.restaurant.imageUrl)
      this.cuisines = this.restaurant.__cuisines || []
      this.updateForm()
    }
  }

  ngOnChanges(changes) {
    if (changes.categoriesList) {
      this.categoriesList =
        this.categoriesList && this.categoriesList.filter(el => !this.categories.includes(el))
    }
    if (changes.restaurant) {
      this.updateForm()
    }
  }

  public get enableTranslate(): boolean {
    return this.availableLanguages.length > 1
  }

  public get maxAddressLength(): number {
    return MAX_ADDRESS_LENGTH
  }

  public get maxCommonLength(): number {
    return MAX_COMMON_LENGTH
  }

  public get maxNameLength(): number {
    return MAX_COMMON_LENGTH
  }

  public get maxDescriptionLength(): number {
    return MAX_DESCRIPTION_LENGTH
  }

  public get maxWebsiteLength(): number {
    return MAX_COMMON_LENGTH
  }

  public get maxLatLngLength(): number {
    return MAX_LAT_LNG_LENGTH
  }

  public get minLatValue(): number {
    return MIN_LAT_VALUE
  }

  public get maxLatValue(): number {
    return MAX_LAT_VALUE
  }

  public get minLngValue(): number {
    return MIN_LNG_VALUE
  }

  public get maxLngValue(): number {
    return MAX_LNG_VALUE
  }

  public onSubmit(translate = false): void {
    if (this.enableSubmit) {
      this.updateData(translate)
    }
  }

  public onCancel(): void {
    this.cancel.next()
  }

  public get enableSubmit(): boolean {
    return (
      this.form &&
      this.form.valid &&
      (this.form.dirty || this.photoUploadChanged || this.cuisinesChanged) &&
      this.photoUploaded &&
      Boolean(this.categories.length)
    )
  }

  private updateLogoControlValue(value): void {
    this.form.patchValue({
      ImageUrl: value,
    })
  }

  public onRestaurantCuisinesChange(cuisines: Cuisine[]): void {
    if (cuisines) {
      this.cuisines = cuisines
      this.cuisinesChanged = true
    }
  }

  public onUploadLogo(result: UploadFileResult): void {
    if (result.status) {
      this.uploadLogoStatus = result.status
      this.photoUploadChanged = true
    }
    if (result.status === UploadFileStatus.COMPLETED) {
      this.photoUploaded = true
      this.updateLogoControlValue((result.data as UploadFile).url)
    } else if (
      result.status === UploadFileStatus.CANCELLED_BY_USER ||
      result.status === UploadFileStatus.FAILED
    ) {
      this.photoUploaded = false
      this.updateLogoControlValue('')
    }
  }

  private updateData(translate: boolean): void {
    const data = this.form.value
    const restaurant: Restaurant = {
      name: translatableFieldUtils.trimValue(data.Name),
      description: translatableFieldUtils.trimValue(data.Description),

      category: this.categories,
      cuisine: this.cuisines && this.cuisines.length ? this.cuisines.map(c => c.code) : [],

      schedule: translatableFieldUtils.trimValue(data.Schedule),
      address: translatableFieldUtils.trimValue(data.Address),
      city: translatableFieldUtils.trimValue(data.City),

      coordinates: [Number(data.Lng), Number(data.Lat)],
      location: {
        lat: data.Lat,
        lng: data.Lng,
      },

      averageBill: translatableFieldUtils.trimValue(data.AverageBill),
      rating: data.Rating,

      imageUrl: data.ImageUrl,
      link: data.Link && data.Link.trim(),
    }
    const sourceLanguage = translate ? this.currentLanguage : null
    const targetLanguages = this.availableLanguages
    this.submit.next({ restaurant, sourceLanguage, targetLanguages })
    this.form.markAsPristine()
    this.form.markAsTouched()
    this.photoUploadChanged = false
    this.cuisinesChanged = false
  }

  private getCommonValidators(maxLengthConstraint: number): ValidatorFn {
    return Validators.compose([Validators.maxLength(maxLengthConstraint)])
  }

  private getLocationValidators(minValue, maxValue): ValidatorFn {
    return Validators.compose([
      Validators.required,
      numberValidator,
      rangeValidator([minValue, maxValue]),
      Validators.maxLength(MAX_LAT_LNG_LENGTH),
    ])
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      Name: new FormControl(
        {},
        Validators.compose([Validators.required, this.getCommonValidators(MAX_COMMON_LENGTH)])
      ),
      Description: new FormControl({}, [
        Validators.required,
        this.getCommonValidators(MAX_DESCRIPTION_LENGTH),
      ]),
      Schedule: new FormControl({}, [
        Validators.required,
        this.getCommonValidators(MAX_COMMON_LENGTH),
      ]),
      Address: new FormControl({}, [
        Validators.required,
        this.getCommonValidators(MAX_ADDRESS_LENGTH),
      ]),
      City: new FormControl({}, [Validators.required, this.getCommonValidators(MAX_COMMON_LENGTH)]),
      Lat: new FormControl('', [
        Validators.required,
        this.getLocationValidators(MIN_LAT_VALUE, MAX_LAT_VALUE),
      ]),
      Lng: new FormControl('', [
        Validators.required,
        this.getLocationValidators(MIN_LNG_VALUE, MAX_LNG_VALUE),
      ]),
      AverageBill: new FormControl({}, Validators.compose([Validators.required])),
      ImageUrl: new FormControl(''),
      Rating: new FormControl(
        '',
        Validators.compose([Validators.pattern(/^\d+(\.\d)?$/), rangeValidator([0, 10])])
      ),
      Link: new FormControl(
        '',
        Validators.compose([urlValidator, Validators.maxLength(MAX_COMMON_LENGTH)])
      ),
    })
  }

  private updateForm(): void {
    const formData = {
      Name: this.restaurant.name,
      Description: this.restaurant.description,
      Schedule: this.restaurant.schedule,
      Address: this.restaurant.address,
      City: this.restaurant.city,
      Lat: this.restaurant.coordinates[1],
      Lng: this.restaurant.coordinates[0],
      AverageBill: this.restaurant.averageBill,
      Rating: this.restaurant.rating,
      ImageUrl: this.restaurant.imageUrl,
      Link: this.restaurant.link,
    }
    this.form.patchValue(formData)
  }

  public removeCategory(category: string): void {
    const index = this.categories.indexOf(category)

    if (index >= 0) {
      this.categories.splice(index, 1)
    }

    this.categoriesList.push(category)
    this.categoriesList.sort()
    this.categoryCtrl.setValue(null)
    this.form.markAsDirty()
  }

  public addCategory(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value.trim()

    if (value.length) {
      this.filterSelectedCategories(value)
    }

    if (input) {
      input.value = ''
    }

    this.categoryCtrl.setValue(null)
    this.form.markAsDirty()
    this.categoryInput.nativeElement.blur()
  }

  public selectedCategory(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue
    this.categories.push(value)
    this.categoryInput.nativeElement.value = ''

    if (value.length) {
      this.filterSelectedCategories(value)
    }

    this.categoryCtrl.setValue(null)
    this.form.markAsDirty()
    this.categoryInput.nativeElement.blur()
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    return (
      (this.categoriesList &&
        this.categoriesList.filter(
          category => category.toLowerCase().indexOf(filterValue) === 0
        )) ||
      []
    )
  }

  private filterSelectedCategories(value) {
    const addedIndex = this.categoriesList.indexOf(value)
    this.categoriesList.splice(addedIndex, 1)
    this.categoriesList = R.uniq(this.categoriesList)
    this.categories.push(value.trim())
    this.categories = R.uniq(this.categories)
  }
}
