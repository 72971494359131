<div class="form" [formGroup]="form">
  <div class="mb2">
    <mat-checkbox formControlName="required">
      {{ 'forms.label.requiredField' | translate: locale.language }}
    </mat-checkbox>
  </div>

  <mat-form-field appearance="fill" class="mr3 mb1">
    <mat-label>Min</mat-label>
    <input type="text" matInput formControlName="min" />
    <mat-error *ngIf="form.get('min').errors">Invalid number</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="mr3 mb1">
    <mat-label>Max</mat-label>
    <input type="text" matInput formControlName="max" />
    <mat-error *ngIf="form.get('max').errors">Invalid number</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" floatLabel="always" class="mt2 w-100">
    <mat-label>Extract count from these entities</mat-label>
    <input matInput formControlName="entities" />
  </mat-form-field>

  <hot-form-field-autofill formControlName="autofillFrom"></hot-form-field-autofill>
</div>
