<form [formGroup]="formForm">
  <div class="flex-l">
    <div class="settings mr4-l flex-auto flex-none-l mb3">
      <div class="sticky" style="top: 152px">
        <div class="bg-white br3 pt3 ph3 mb3">
          <mat-form-field class="w-100 f4" floatLabel="always">
            <mat-label class="f5">{{ 'Form.field.title' | translate: locale.language }}</mat-label>
            <input
              matInput
              #titleField="hotTranslatableText"
              formControlName="title"
              hotTranslatableText
              [language]="language"
            />
            <!-- <button
              type="button"
              mat-icon-button
              color="primary"
              matSuffix
              matTooltip="Скопировать с языка по-умолчанию"
              *ngIf="titleField.translated"
              (click)="titleField.copyFromDefault()"
            >
              <mat-icon>history</mat-icon>
            </button> -->
          </mat-form-field>

          <mat-form-field class="w-100" floatLabel="always">
            <mat-label class="f5">{{
              'Form.field.cancelConfirmation' | translate: locale.language
            }}</mat-label>
            <textarea
              matInput
              formControlName="cancelConfirmation"
              hotTranslatableText
              [language]="language"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="bg-white br3 pa3 mb3">
          <h3 class="mb2">{{ 'Form.sectionTitle.settings' | translate: locale.language }}</h3>

          <div class="mb2">
            <mat-checkbox formControlName="requiresRoomNumber">{{
              'Form.field.requiresRoomNumber' | translate: locale.language
            }}</mat-checkbox>
            <mat-checkbox class="mt2" formControlName="requiresEmailNotification">{{
              'Form.field.requiresEmailNotification' | translate: locale.language
            }}</mat-checkbox>
            <mat-checkbox class="mt2" formControlName="requiresSmsNotification">{{
              'Form.field.requiresSmsNotification' | translate: locale.language
            }}</mat-checkbox>
            <ng-container *ngIf="form.formType === 'request'">
              <mat-checkbox class="mt2" formControlName="instantCancellation">
                {{ 'Form.field.instantCancellation' | translate: locale.language }}
              </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="backofficeIntegration">
              <mat-checkbox class="mt2" formControlName="ignoreBackofficeIntegration">
                {{
                  'Form.field.ignoreBackofficeIntegration'
                    | translate: locale.language:{ provider: backofficeIntegration }
                }}
              </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="!form.isServiceForm">
              <div class="mt3 mb2 fw5 f6">
                {{ 'Form.field.formType' | translate: locale.language }}
              </div>
              <mat-radio-group formControlName="formType" class="flex flex-column">
                <mat-radio-button class="mb1" *ngFor="let formType of formTypes" [value]="formType">
                  {{ 'Form.typeLabel.' + formType | translate: locale.language }}
                </mat-radio-button>
              </mat-radio-group>
            </ng-container>
          </div>

          <hot-manager-notifications
            [withKpi]="showKpiSettings"
            [smallTabs]="true"
            [notifications]="notificationSettings"
            [types]="notificationTypes"
            (update)="onNotificationChange($event)"
          ></hot-manager-notifications>
        </div>

        <div class="bg-white mt3 br3 pa3 link-editor" *ngIf="isLinkForm" formGroupName="formLink">
          <mat-form-field appearance="fill" floatLabel="always" class="w-100 mb3">
            <mat-label>{{ 'Form.field.description' | translate: locale.language }}</mat-label>
            <textarea
              #btnDescription
              matInput
              formControlName="description"
              maxlength="600"
              hotTranslatableText
              [language]="language"
            ></textarea>
            <mat-hint align="end">{{ btnDescription.value.length }} / 600</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="fill" floatLabel="always" class="w-100 mb3">
            <mat-label>{{ 'Form.field.buttonLabel' | translate: locale.language }}</mat-label>
            <input
              #btnLabel
              type="text"
              maxlength="20"
              matInput
              formControlName="label"
              hotTranslatableText
              [language]="language"
            />
            <mat-hint align="end">{{ btnLabel.value.length }} / 20</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="fill" floatLabel="always" class="w-100">
            <mat-label>URL</mat-label>
            <input type="text" matInput formControlName="url" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="questions flex-auto">
      <div style="margin-left: 44px; margin-top: -24px">
        <button
          type="button"
          class="w-100 add-field-button--divider"
          mat-button
          color="primary"
          (click)="addField(0)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="br3 form-fields" formArrayName="fields">
        <div
          class="form-field"
          *ngFor="let item of formFields.controls; let i = index; first as isFirst; last as isLast"
          [attr.data-form-field]="item.value.id"
          [formGroupName]="i"
        >
          <div class="flex mb3">
            <div class="controls mr2">
              <div class="sticky" style="top: 160px">
                <div class="flex flex-column items-center justify-center bg-white br-pill mv2">
                  <button
                    type="button"
                    [disabled]="isFirst"
                    color="primary"
                    mat-icon-button
                    class="control-button"
                    (click)="moveField(i, -1)"
                  >
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </button>
                  <div class="fw5">#{{ i + 1 }}</div>
                  <button
                    type="button"
                    [disabled]="isLast"
                    color="primary"
                    mat-icon-button
                    class="control-button"
                    (click)="moveField(i, 1)"
                  >
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                </div>
                <button
                  type="button"
                  tabindex="-1"
                  matSuffix
                  mat-icon-button
                  (click)="deleteField(i)"
                  class="control-button glow bg-white gray hover-white hover-bg-accent"
                >
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </div>

            <hot-form-editor-field
              class="flex-auto"
              [formField]="item"
              [language]="language"
              [defaultLanguage]="defaultLanguage"
              [showIdInputs]="showIdInputs"
              (changed)="updateField(i, $event)"
              (deleted)="deleteField(i)"
            >
            </hot-form-editor-field>
          </div>

          <div style="margin-left: 44px" class="nt3" *ngIf="!isLast">
            <button
              type="button"
              class="w-100 add-field-button--divider"
              mat-button
              color="primary"
              (click)="addField(i + 1)"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <div style="margin-left: 42px">
          <button
            type="button"
            class="template-button w-100 pv2"
            mat-button
            color="primary"
            (click)="addField()"
          >
            <mat-icon>add</mat-icon>
            <span>{{ 'Form.action.addQuestion' | translate: locale.language }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
