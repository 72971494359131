import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'hot-builder-button-edit-dialog',
  templateUrl: './builder-button-edit-dialog.html',
  styles: [],
})
export class BuilderButtonEditDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BuilderButtonEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  public onSave(): void {
    this.dialogRef.close(this.data.buttons)
  }

  public onCancel(): void {
    this.dialogRef.close(null)
  }

  public onUpdate(payload) {
    if (this.data.update) {
      this.data.update.emit(payload)
    }
  }

  public onCreateNewPage(payload) {
    if (this.data.createNewPage) {
      this.data.createNewPage.emit(payload)
    }
  }
}
