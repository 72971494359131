import * as moment from 'moment'
import { Component, Input, Inject, OnInit, OnChanges } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { Store, select } from '@ngrx/store'
import * as fromRoot from '@redux'
import * as fromShopCart from '@redux/shop-cart'
import * as fromAnalytics from '@redux/analytics'
import { PropertyKind } from '@models'

@Component({
  selector: 'hot-shop-statistics',
  templateUrl: './shop-statistics.component.html',
  styleUrls: ['./shop-statistics.component.scss'],
})
export class ShopStatisticsComponent implements OnInit, OnChanges {
  @Input() propertyId: string = null
  @Input() isAdmin = false

  public form = new FormGroup({})
  public maxDate: moment.Moment = moment()
  public minDate: moment.Moment = moment('2020-05-18', 'YYYY-MM-DD') // FIXME: Needs migration: add currency to the legacy shopcarts
  public statisticsForProperty$ = this.store.pipe(select(fromRoot.selectCurrentShopCartsStatistics))
  public statisticsForAdmin$ = this.store.pipe(select(fromRoot.selectShopStatistics))
  public loading$ = this.store.pipe(select(fromRoot.selectAnalyticsLoading))
  public loading = false

  public statisticsColumns: string[] = [
    'source',
    'visitors',
    'shopVisitors',
    'orders',
    'missedIncome',
    'incomeOnline',
    'incomeOffline',
    'income',
  ]

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private fb: FormBuilder,
    private readonly store: Store<fromRoot.State>
  ) {}

  ngOnInit() {
    this.initializeForm()
    this.loadStatistics()
    this.loading$.subscribe(x => {
      this.loading = x
    })
  }

  ngOnChanges(changes) {
    if (changes && changes.propertyId) {
      this.loadStatistics()
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      Daterange: new FormControl({
        begin: moment().subtract(7, 'd'),
        end: moment(),
      }),
      PropertyKind: new FormControl(PropertyKind.Hotel),
    })
    this.form.valueChanges.subscribe(() => {
      this.loadStatistics()
    })
  }

  public get statistics$() {
    return this.isAdmin ? this.statisticsForAdmin$ : this.statisticsForProperty$
  }

  public onDatesPresetClick(rangeCode: 'today' | '30d' | '7d') {
    let begin = null
    let end = null
    switch (rangeCode) {
      case 'today': {
        begin = moment().startOf('day')
        end = moment().endOf('day')
        break
      }
      case '30d': {
        begin = moment()
          .startOf('day')
          .subtract(30, 'd')
        end = moment().endOf('day')
        break
      }
      case '7d': {
        begin = moment()
          .startOf('day')
          .subtract(7, 'd')
        end = moment().endOf('day')
        break
      }
    }
    this.form.patchValue({ Daterange: { begin, end } })
  }

  public loadStatistics() {
    const dateRangeControl = this.form.get('Daterange')
    if (!dateRangeControl || !dateRangeControl.value) {
      return
    }
    const from = dateRangeControl.value.begin.format('YYYY-MM-DD')
    const to = dateRangeControl.value.end.format('YYYY-MM-DD')
    const propertyKind = this.form.get('PropertyKind').value
    this.store.dispatch(
      this.isAdmin
        ? new fromAnalytics.LoadShop({
            propertyId: this.propertyId || null,
            propertyKind: this.propertyId ? null : propertyKind,
            from,
            to,
          })
        : new fromShopCart.LoadStatistics({
            propertyId: this.propertyId,
            from,
            to,
          })
    )
  }
}
