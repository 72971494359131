import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResults, NormalizedResult, RoomService } from '@models'
import { LanguageType } from '@shared/constants'

@Injectable()
export class RoomServiceService {
  constructor(public readonly apiService: ApiService) {}

  public create(
    propertyId: string,
    roomService: RoomService,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[]
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/roomService`, {
      roomService,
      sourceLanguage,
      targetLanguages,
    })
  }

  public get(payload): Observable<NormalizedResults> {
    const propertyId = payload.propertyId
    return this.apiService.get(`properties/${propertyId}/roomService`)
  }

  public update(
    propertyId: string,
    roomServiceId: string,
    roomService: RoomService,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[],
    forceTranslate: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/roomService/${roomServiceId}`, {
      roomService,
      sourceLanguage,
      targetLanguages,
      forceTranslate,
    })
  }

  public toggle(
    propertyId: string,
    roomServiceId: string,
    pause: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/roomService/${roomServiceId}/toggle`, {
      pause,
    })
  }

  public delete(propertyId: string, roomServiceId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`properties/${propertyId}/roomService/${roomServiceId}`)
  }
}
