<div
  class="requests hot-flex-table"
  [class.requests--list]="theme !== 'table'"
  [class.requests--user-list]="theme === 'user-list'"
  [class.requests--table]="theme === 'table'"
>
  <div class="row row--header" *ngIf="theme === 'table'">
    <div *ngIf="showColumn('number')" class="column column--number">
      {{ 'Requests.tableColumn.number' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('date')" class="column column--date">
      {{ 'Requests.tableColumn.date' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('guest')" class="column">
      {{ 'Requests.tableColumn.guest' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('language')" class="column">
      {{ 'Requests.tableColumn.language' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('roomNumber')" class="column">
      {{ 'Requests.tableColumn.roomNumber' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('type')" class="column">
      {{ 'Requests.tableColumn.type' | translate: locale.language }}
    </div>
    <div *ngIf="showColumn('status')" class="column">
      {{ 'Requests.tableColumn.status' | translate: locale.language }}
    </div>
  </div>
  <a
    *ngFor="let request of requests"
    [routerLink]="['/properties', propertyId, 'requests', request._id]"
    [class]="'row row--link bg-animate request request--' + request.status"
  >
    <div *ngIf="showColumn('number')" class="column column--number">
      {{ request.responseNumber }}
    </div>
    <div *ngIf="showColumn('date')" class="column column--date">
      {{ request.createdAt | date: 'medium':'':locale.language }}
    </div>
    <div *ngIf="showColumn('guest')" class="column column--name">
      <span *ngIf="theme === 'list'">👤</span>
      <span>{{ (request.__chat | chatName) || '&mdash;' }}</span>
    </div>
    <div *ngIf="showColumn('language')" class="column column--locale">
      <span *ngIf="theme === 'list'">🌐</span>
      <span>{{ getLanguageLabel(request.__chat?.locale) || '&mdash;' }}</span>
    </div>
    <div *ngIf="showColumn('roomNumber')" class="column column--room">
      <span *ngIf="theme === 'list'">🚪</span>
      <span>{{ getRoomNumber(request) || '&mdash;' }}</span>
    </div>
    <div *ngIf="showColumn('type')" class="column column--title">
      {{ (request?.title | selectLanguage: locale.language) || '&mdash;' }}
    </div>
    <div *ngIf="showColumn('status')" class="column column--status">
      {{ request.status | requestStatus: locale.language }}
    </div>
  </a>
</div>
