import { Action } from '@ngrx/store'
import { Push, NormalizedResult, NormalizedResults, PushStatus } from '@models'

export enum PushActionType {
  Load = '[Push template] Load',
  LoadSuccess = '[Push template] Load success',
  LoadFail = '[Push template] Load fail',
  LoadItem = '[Push template] Load item',
  Select = '[Push template] Select',
  LoadItemSuccess = '[Push template] Load item success',
  LoadItemFail = '[Push template] Load item fail',
  Create = '[Push template] Create template',
  CreateSuccess = '[Push template] Create success',
  CreateFail = '[Push template] Create fail',
  Update = '[Push template] Update template',
  UpdateSuccess = '[Push template] Update success',
  UpdateFail = '[Push template] Update fail',
  Delete = '[Push template] Delete template',
  DeleteSuccess = '[Push template] Delete success',
  DeleteFail = '[Push template] Delete fail',
}

/**
 * Select push action
 */

export interface SelectPayload {
  pushMessageId: string
}
export class Select implements Action {
  readonly type = PushActionType.Select
  constructor(public payload: SelectPayload) {}
}

/**
 * Load push template actions
 */
export interface LoadPayload {
  propertyId: string
  status?: PushStatus
  page?: number
  limit?: number
}

export class Load implements Action {
  readonly type = PushActionType.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = PushActionType.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = PushActionType.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load push template item actions
 */

export interface LoadItemPayload {
  propertyId?: string
  pushMessageId?: string
}

export class LoadItem implements Action {
  readonly type = PushActionType.LoadItem
  constructor(public payload: LoadItemPayload) {}
}

export class LoadItemSuccess implements Action {
  readonly type = PushActionType.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}

export class LoadItemFail implements Action {
  readonly type = PushActionType.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Create push template actions
 */
export interface CreatePayload {
  propertyId: string
  push?: Push
}

export class Create implements Action {
  readonly type = PushActionType.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = PushActionType.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = PushActionType.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Update push template actions
 */
export interface UpdatePayload {
  propertyId: string
  pushId: string
  push?: Push
}

export class Update implements Action {
  readonly type = PushActionType.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = PushActionType.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = PushActionType.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

/**
 * Delete push template actions
 */
export interface DeletePayload {
  propertyId: string
  pushId: string
}

export class Delete implements Action {
  readonly type = PushActionType.Delete
  constructor(public payload: DeletePayload) {}
}

export class DeleteSuccess implements Action {
  readonly type = PushActionType.DeleteSuccess
  constructor(public payload: NormalizedResult, public meta: DeletePayload) {}
}

export class DeleteFail implements Action {
  readonly type = PushActionType.DeleteFail
  constructor(public error: any, public meta: DeletePayload) {}
}

export type PushActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | Select
