import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { AuthService } from '@shared/services/auth.service'
import * as fromPushSubscription from '@redux/push-subscription'
import * as fromRoot from '@redux'
import { ConfigService, PubSubService, WebChatWidgetService } from '@shared/services'
import { PostMessageType } from '@shared/constants'
import { take, distinctUntilChanged, filter, takeUntil, withLatestFrom } from 'rxjs/operators'
import { UserProfileFacade } from '@shared/facades/user-profile.facade'
import { PropertyFacade } from '@redux/property/property.facade'
import { Property } from '@models'

@Component({
  selector: 'hot-root',
  template: `
    <ng-container *ngIf="authService.isAuthorized(); else guest">
      <hot-app-header
        *ngIf="!authService.ssoMode"
        [user]="user$ | async"
        [properties]="properties$ | async"
        [currentProperty]="currentProperty$ | async"
      ></hot-app-header>
      <router-outlet></router-outlet>
    </ng-container>
    <ng-template #guest>
      <router-outlet></router-outlet>
    </ng-template>
  `,
})
export class AppContainer implements OnInit, OnDestroy {
  public user$ = this.userProfileFacade.user$
  public properties$ = this.propertyFacade.properties$
  public currentProperty$ = this.propertyFacade.property$

  private destroyed = new Subject()

  @HostListener('window:message', ['$event'])
  onWindowMessage($event) {
    const message = $event.data
    if (message.type === PostMessageType.SUBSCRIPTION_RECEIVED) {
      try {
        const subscription = JSON.parse(message.data)
        this.store$.dispatch(new fromPushSubscription.Save(subscription))
      } catch (error) {
        console.log(error)
      }
    }
  }

  public ngOnInit() {
    // const routeChanged$ = this.router.events.pipe(
    //   switchMap((event: RouterEvent) => {
    //     return event instanceof NavigationEnd ? of(event.url) : NEVER
    //   })
    // )
    // this.authService.accessToken$
    //   .pipe(switchMap(token => (token ? routeChanged$ : NEVER)))
    //   .subscribe(url => {
    //     if (url.includes('builder')) {
    //       this.webChatWidgetService.destroyWebChatWidget()
    //     } else {
    //       this.initSupportChat()
    //     }
    //   })
    if (this.authService.ssoMode) {
      document.querySelector('body').setAttribute('style', '--sidebar-width: 0px')
    }
    this.currentProperty$
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        takeUntil(this.destroyed),
        withLatestFrom(this.user$)
      )
      .subscribe(([property, user]) => {
        // HACK: Show Intercom widget in BotBit properties
        if (property && user && isAdminInProperty('5e8b6d29276c420027da4a70', property)) {
          if (typeof window.hideSupportChat === 'function') {
            window.hideSupportChat()
          }

          /*loadIntercom('z0yssql1').then(appId => {
            window.Intercom('boot', {
              app_id: appId,
              email: user.email,
              name: user.name,
              user_id: user._id,
              vertical_padding: 64,
              horizontal_padding: 64,
            })
          })*/
        }
      })
  }

  ngOnDestroy() {
    this.destroyed.next()
  }

  public initSupportChat() {
    this.authService.accessToken$.pipe(take(1)).subscribe(token => {
      // Pass the user ID, so we can infer name in chats
      this.webChatWidgetService.initWebChatWidget(this.configService.CHANNEL_ID, { hbuser: token })
      this.webChatWidgetService.hideWidget()
      this.webChatWidgetService.passOnboarding()
    })
  }

  constructor(
    private readonly store$: Store<fromRoot.State>,
    public readonly authService: AuthService,
    public readonly userProfileFacade: UserProfileFacade,
    public readonly propertyFacade: PropertyFacade,
    public pubsub: PubSubService,
    private webChatWidgetService: WebChatWidgetService,
    private configService: ConfigService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    const addIcon = (name, path) =>
      iconRegistry.addSvgIconInNamespace(
        'hot',
        name,
        sanitizer.bypassSecurityTrustResourceUrl(path)
      )
    addIcon('hotbot', '/assets/logo.svg')
    addIcon('logo', '/assets/logo-small.svg')
    addIcon('booking', '/assets/booking-logo.svg')
    addIcon('facebook', '/assets/facebook.svg')
    addIcon('inbox', '/assets/menu/inbox.svg')
    addIcon('marketing', '/assets/menu/marketing.svg')
    addIcon('messages', '/assets/menu/messages.svg')
    addIcon('polls', '/assets/menu/polls.svg')
    addIcon('orders', '/assets/menu/orders.svg')
    addIcon('reviews', '/assets/menu/reviews.svg')
    addIcon('settings', '/assets/menu/settings.svg')
    addIcon('user', '/assets/menu/user.svg')
    addIcon('admin', '/assets/menu/admin.svg')
    addIcon('stats', '/assets/menu/stats.svg')
    addIcon('wifi', '/assets/menu/wifi.svg')
    addIcon('ai', '/assets/menu/ai.svg')
    addIcon('information', '/assets/menu/information.svg')

    addIcon('facebook-messenger-sq', '/assets/messengers/square/fb-messenger.svg')
    addIcon('telegram-messenger-sq', '/assets/messengers/square/telegram.svg')
    addIcon('viber-messenger-sq', '/assets/messengers/square/viber.svg')
    addIcon('webchat-messenger-sq', '/assets/messengers/square/web.svg')
    addIcon('wechat-messenger-sq', '/assets/messengers/square/wechat.svg')
    addIcon('whatsapp-messenger-sq', '/assets/messengers/square/whatsapp.svg')

    addIcon('file-excel', '/assets/files/file-excel-solid.svg')
    addIcon('payments-applepay', '/assets/payments/applepay.svg')
    addIcon('payments-gpay', '/assets/payments/gpay.svg')
    pubsub.init()
  }
}

function isAdminInProperty(userId: string, property: Property): boolean {
  const members = property.members || []
  if (members.some(m => m.role === 'owner' && m.user === userId)) {
    return true
  }
  return false
}
/*
function loadIntercom(appId: string) {
  console.log('Load Intercom')
  return new Promise(resolve => {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i: any = function() {
        i.c(arguments)
      }
      i.q = []
      i.c = function(args) {
        i.q.push(args)
      }
      w.Intercom = i

      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + appId
      const x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
      s.addEventListener(
        'load',
        () => {
          console.log('Intercom loaded')
          resolve(appId)
        },
        false
      )
    }
  })
}*/
