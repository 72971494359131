import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import * as shopProductCategoryActions from './shop-product-category.actions'
import { ShopProductCategoryService } from './shop-product-category.service'
import { of } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { I18nService } from '@shared/services'

@Injectable()
export class ShopProductCategoryEffects {
  // Create

  @Effect()
  public create$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.Create),
    switchMap((action: shopProductCategoryActions.Create) => {
      const meta = action.payload
      return this.shopProductCategoryService
        .create(
          action.payload.propertyId,
          action.payload.shopId,
          action.payload.shopProductCategory,
          action.payload.parentShopProductCategoryId,
          action.payload.sourceLanguage,
          action.payload.targetLanguages
        )
        .pipe(
          map(x => {
            return new shopProductCategoryActions.CreateSuccess(x, meta)
          }),
          catchError(x => of(new shopProductCategoryActions.CreateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public createSuccess$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.CreateSuccess),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.createCategorySuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public createFail$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.CreateFail),
    tap((action: shopProductCategoryActions.CreateFail) => {
      const messageKey =
        action.error.statusCode === 400
          ? 'Shop.notification.updateCategoryWithExternalProductsFail'
          : 'Shop.notification.createCategoryFail'
      const message = this.translationService.translate(messageKey)
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 4000,
      })
    })
  )

  // Update
  @Effect()
  public update$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.Update),
    switchMap((action: shopProductCategoryActions.Update) => {
      const meta = action.payload
      return this.shopProductCategoryService
        .update(
          action.payload.propertyId,
          action.payload.shopId,
          action.payload.shopProductCategoryId,
          action.payload.shopProductCategory,
          action.payload.sourceLanguage,
          action.payload.targetLanguages,
          action.payload.forceTranslate
        )
        .pipe(
          map(x => new shopProductCategoryActions.UpdateSuccess(x, meta)),
          catchError(x => of(new shopProductCategoryActions.UpdateFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public updateSuccess$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.UpdateSuccess),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.updateCategorySuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public updateFail$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.UpdateFail),
    tap((action: shopProductCategoryActions.UpdateFail) => {
      const messageKey =
        action.error.statusCode === 400
          ? 'Shop.notification.updateCategoryWithExternalProductsFail'
          : 'Shop.notification.updateCategoryFail'
      const message = this.translationService.translate(messageKey)
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 4000,
      })
    })
  )

  // Delete
  @Effect()
  public delete$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.Delete),
    switchMap((action: shopProductCategoryActions.Delete) => {
      const meta = action.payload
      return this.shopProductCategoryService
        .delete(
          action.payload.propertyId,
          action.payload.shopId,
          action.payload.shopProductCategoryId
        )
        .pipe(
          map(x => {
            return new shopProductCategoryActions.DeleteSuccess(x, meta)
          }),
          catchError(x => of(new shopProductCategoryActions.DeleteFail(x, meta)))
        )
    })
  )

  @Effect({ dispatch: false })
  public deleteSuccess$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.DeleteSuccess),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.deleteCategorySuccess')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-green'],
        duration: 3000,
      })
    })
  )

  @Effect({ dispatch: false })
  public deleteFail$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.DeleteFail),
    tap(() => {
      const message = this.translationService.translate('Shop.notification.deleteCategoryFail')
      this.matSnackBar.open(message, '', {
        panelClass: ['bg-red'],
        duration: 3000,
      })
    })
  )

  // Toggle
  @Effect()
  public toggle$ = this.actions$.pipe(
    ofType(shopProductCategoryActions.ShopProductCategoryActionTypes.Toggle),
    switchMap((action: shopProductCategoryActions.Toggle) => {
      const meta = action.payload
      return this.shopProductCategoryService
        .toggle(
          action.payload.propertyId,
          action.payload.shopId,
          action.payload.shopProductCategoryId,
          action.payload.paused
        )
        .pipe(
          map(x => {
            return new shopProductCategoryActions.ToggleSuccess(x, meta)
          }),
          catchError(x => of(new shopProductCategoryActions.ToggleFail(x, meta)))
        )
    })
  )

  constructor(
    private actions$: Actions,
    private shopProductCategoryService: ShopProductCategoryService,
    public translationService: I18nService,
    private matSnackBar: MatSnackBar
  ) {}
}
