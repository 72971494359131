import { Action } from '@ngrx/store'
import { NormalizedResult } from '@models/api-normalized-responses'
import { SalesServiceSettings } from '@models/sales-service-settings.model'

export enum SalesServiceActionTypes {
  CreateSettings = '[Sales-Service] CreateSettings',
  CreateSettingsSuccess = '[Sales-Service] CreateSettings Success',
  CreateSettingsFail = '[Sales-Service] CreateSettings Fail',

  LoadSettings = '[Sales-Service] LoadSettings',
  LoadSettingsSuccess = '[Sales-Service] LoadSettings Success',
  LoadSettingsFail = '[Sales-Service] Load SettingsFail',

  LoadSettingsItem = '[Sales-Service] LoadSettings Item',
  LoadSettingsItemForProperty = '[Sales-Service] LoadSettings Item For Property',
  LoadSettingsItemSuccess = '[Sales-Service Item] LoadSettingsItem Success',
  LoadSettingsItemFail = '[Sales-Service Item] LoadSettingsItem Fail',

  UpdateSettings = '[Sales-Service] UpdateSettings',
  UpdateSettingsSuccess = '[Sales-Service] UpdateSettings Success',
  UpdateSettingsFail = '[Sales-Service] UpdateSettings Fail',

  DeleteSettings = '[Sales-Service] DeleteSettings',
  DeleteSettingsSuccess = '[Sales-Service] DeleteSettings Success',
  DeleteSettingsFail = '[Sales-Service] DeleteSettings Fail',

  SelectSettings = '[Sales-Service]  SelectSettings',
}

/**
 * Create Settings actions
 */

export interface CreateSettingsPayload {
  result?: string
  propertyId: string
  salesServiceSettings?: SalesServiceSettings
}

export class CreateSettings implements Action {
  readonly type = SalesServiceActionTypes.CreateSettings
  constructor(public payload: CreateSettingsPayload) {}
}

export class CreateSettingsSuccess implements Action {
  readonly type = SalesServiceActionTypes.CreateSettingsSuccess
  constructor(public payload: NormalizedResult, public meta: CreateSettingsPayload) {}
}

export class CreateSettingsFail implements Action {
  readonly type = SalesServiceActionTypes.CreateSettingsFail
  constructor(public error: any, public meta: CreateSettingsPayload) {}
}

/**
 * Load settings actions
 */

export interface LoadSettingsPayload {
  propertyId?: string
  salesServiceSettingsId?: string
}

export class LoadSettings implements Action {
  readonly type = SalesServiceActionTypes.LoadSettings
  constructor(public payload: LoadSettingsPayload = {}) {}
}

export class LoadSettingsSuccess implements Action {
  readonly type = SalesServiceActionTypes.LoadSettingsSuccess
  constructor(public payload, public meta: LoadSettingsPayload) {}
}

export class LoadSettingsFail implements Action {
  readonly type = SalesServiceActionTypes.LoadSettingsFail
  constructor(public error: any, public meta: LoadSettingsPayload) {}
}

/**
 * Load Settings Item actions
 */

export interface LoadSettingsItemPayload {
  propertyId?: string
  salesServiceSettingsId?: string
}
export class LoadSettingsItem implements Action {
  readonly type = SalesServiceActionTypes.LoadSettingsItem
  constructor(public payload: LoadSettingsItemPayload = {}) {}
}

export class LoadSettingsItemForProperty implements Action {
  readonly type = SalesServiceActionTypes.LoadSettingsItemForProperty
  constructor(public payload: LoadSettingsItemPayload = {}) {}
}

export class LoadSettingsItemSuccess implements Action {
  readonly type = SalesServiceActionTypes.LoadSettingsItemSuccess
  constructor(public payload, public meta: LoadSettingsItemPayload) {}
}
export class LoadSettingsItemFail implements Action {
  readonly type = SalesServiceActionTypes.LoadSettingsItemFail
  constructor(public payload, public meta: LoadSettingsItemPayload) {}
}

/**
 * Update settings actions
 */
export interface UpdateSettingsPayload {
  propertyId?: string
  salesServiceSettingsId: string
  saleServiceSettings: SalesServiceSettings
}

export class UpdateSettings implements Action {
  readonly type = SalesServiceActionTypes.UpdateSettings
  constructor(public payload: UpdateSettingsPayload) {}
}

export class UpdateSettingsSuccess implements Action {
  readonly type = SalesServiceActionTypes.UpdateSettingsSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateSettingsPayload) {}
}

export class UpdateSettingsFail implements Action {
  readonly type = SalesServiceActionTypes.UpdateSettingsFail
  constructor(public error: any, public meta: UpdateSettingsPayload) {}
}

/**
 * Delete actions
 */
export interface DeleteSettingsPayload {
  propertyId?: string
  salesServiceSettingsId: string
}

export class DeleteSettings implements Action {
  readonly type = SalesServiceActionTypes.DeleteSettings
  constructor(public payload: DeleteSettingsPayload) {}
}

export class DeleteSettingsSuccess implements Action {
  readonly type = SalesServiceActionTypes.DeleteSettingsSuccess
  constructor(public payload: NormalizedResult, public meta: DeleteSettingsPayload) {}
}

export class DeleteSettingsFail implements Action {
  readonly type = SalesServiceActionTypes.DeleteSettingsFail
  constructor(public error: any, public meta: DeleteSettingsPayload) {}
}

/*
 * Select Settings action
 */

export interface SelectSettingsPayload {
  propertyId?: string
  salesServiceSettingsId: string
}
export class SelectSettings implements Action {
  readonly type = SalesServiceActionTypes.SelectSettings
  constructor(public payload: SelectSettingsPayload) {}
}

export type SalesServiceActions =
  | CreateSettings
  | CreateSettingsSuccess
  | CreateSettingsFail
  | LoadSettings
  | LoadSettingsSuccess
  | LoadSettingsFail
  | LoadSettingsItem
  | LoadSettingsItemForProperty
  | LoadSettingsItemSuccess
  | LoadSettingsItemFail
  | UpdateSettings
  | UpdateSettingsSuccess
  | UpdateSettingsFail
  | DeleteSettings
  | DeleteSettingsSuccess
  | DeleteSettingsFail
  | SelectSettings
