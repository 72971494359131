<div
  class="category"
  [class.selected]="selected"
  [class.clickable]="clickable"
  (click)="onClick($event)"
>
  <div class="title flex items-center">
    <div class="flex-auto">
      {{ category.title | selectLanguage: lang }}
      <span>({{ category.products.length }})</span>
    </div>
    <div class="flex items-center" [class.withDraggerSpace]="draggable">
      <ng-content></ng-content>
    </div>
  </div>
</div>
