<div class="w-100 mw6 center mv5 flex flex-column">
  <div class="bg-white shadow-2 pa4 pa5-l pb4 br2">
    <mat-icon svgIcon="hot:hotbot" class="mb4 hotbot-logo"></mat-icon>

    <form
      novalidate
      [formGroup]="recoverForm"
      (ngSubmit)="submit()"
      class="mb5 auth-form-container"
    >
      <mat-form-field class="w-100" id="form-auth--login" appearance="outline">
        <mat-label>{{ 'Auth.field.email' | translate: locale.language }}</mat-label>
        <input
          matInput
          type="email"
          tabindex="1"
          autocomplete="off"
          autocapitalize="off"
          formControlName="email"
        />
        <mat-error
          *ngIf="
            recoverForm && recoverForm.touched && recoverForm.get('email').hasError('required')
          "
        >
          {{ 'forms.error.fieldRequired' | translate: locale.language }}
        </mat-error>
        <mat-error
          *ngIf="recoverForm && recoverForm.touched && recoverForm.get('email').hasError('blank')"
        >
          {{ 'forms.error.answerCannotContainSpacesOnly' | translate: locale.language }}
        </mat-error>
      </mat-form-field>
      <div class="w-100">
        <button
          mat-flat-button
          [disabled]="!enableSubmit"
          color="accent"
          type="submit"
          class="f5 w-100 pv2"
          tabindex="3"
        >
          {{ 'Auth.action.recoverPassword' | translate: locale.language }}
        </button>
      </div>
    </form>

    <div class="w-100 mt3 tc">
      <a mat-button [routerLink]="'/login'" class="gray">
        {{ 'Auth.action.signIn' | translate: locale.language }}
      </a>
    </div>
  </div>

  <hot-app-language-selector class="self-end"></hot-app-language-selector>
</div>
