import * as R from 'ramda'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Inject,
} from '@angular/core'
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

import { FormField, FormFieldType } from '@models'
import { LanguageType } from '../../constants'
import { Subject } from 'rxjs'
import { FormTranslations } from '../../../settings/forms/forms.translations'
import { selectLanguage } from '../../pipes/index'

const fieldTypes = [
  {
    type: FormFieldType.Action,
    icon: 'work_outline',
    interactive: false,
  },
  {
    type: FormFieldType.Message,
    icon: 'chat_bubble_outline',
    interactive: false,
    showDivider: true,
  },
  {
    type: FormFieldType.Text,
    icon: 'text_fields',
    interactive: true,
  },
  {
    type: FormFieldType.Options,
    icon: 'format_list_numbered',
    interactive: true,
  },
  {
    type: FormFieldType.Number,
    icon: 'money',
    interactive: true,
  },
  {
    type: FormFieldType.Date,
    icon: 'date_range',
    interactive: true,
  },
  {
    type: FormFieldType.Address,
    icon: 'place',
    interactive: true,
  },
  {
    type: FormFieldType.Comment,
    icon: 'add_comment',
    interactive: true,
  },
]

const fieldTypesByType = R.indexBy<typeof fieldTypes[0]>(R.prop('type'), fieldTypes)

@Component({
  selector: 'hot-form-editor-field',
  templateUrl: './form-editor-field.component.html',
  styleUrls: ['./form-editor-field.component.scss'],
})
export class FormEditorFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public defaultLanguage: LanguageType = null
  @Input() public language: LanguageType | 'en' = 'en'
  @Input() public formField: FormGroup = null
  @Input() public showIdInputs = false
  @Output() public changed = new EventEmitter<FormField>()
  @Output() public statusChanged = new EventEmitter<boolean>()
  @Output() public deleted = new EventEmitter()

  public fieldForm: FormGroup = null
  private destroyed = new Subject()

  public fieldTypes = fieldTypes

  public get labelPlaceholder() {
    const field = this.fieldForm.value
    if (!field || !field.type || !FormTranslations.fieldTypes[field.type]) {
      return ''
    }
    const i18n = FormTranslations.fieldTypes[field.type].label
    return selectLanguage(i18n, this.language)
  }

  public get formFieldConfig(): FormControl {
    return this.fieldForm.get('config') as FormControl
  }

  public get currentType() {
    return this.fieldForm.get('type').value
  }

  public get currentTypeConfig() {
    return fieldTypesByType[this.currentType]
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    this.fieldForm = this.fb.group(
      {
        id: null,
        type: this.fb.control('text'),
        title: this.fb.control([]),
        label: this.fb.control([]),
        config: this.fb.control({}),
      }
      // { validator: [this.validateField] }
    )
  }

  patchConfig(configValue) {
    const config = this.fieldForm.get('config') as FormGroup
    config.markAsTouched()
    config.markAsDirty()
    config.setValue(configValue)
    // R.forEachObjIndexed((val: any, key: string) => {
    //   config.addControl(key, this.fb.control(val))
    // }, configValue)
  }

  ngOnInit() {
    // this.fieldForm.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(this.changed)
    // this.fieldForm.statusChanges.pipe(takeUntil(this.destroyed)).subscribe(this.statusChanged)
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formField) {
      // const data = changes.formField.currentValue.value
      // const fields = changes.form.currentValue.fields || []
      // this.configForm.patchValue(data.config || {})

      this.fieldForm = changes.formField.currentValue
      this.fieldForm.get('config').markAsPristine()
      this.fieldForm.get('config').markAsUntouched()

      // const valueChanges = this.fieldForm.get('config').valueChanges
      // valueChanges.pipe(takeUntil(valueChanges), takeUntil(this.destroyed)).subscribe(val => {
      //   console.log('---> CONFIG CHANGED', val)
      // })

      // this.fieldForm.patchValue({
      //   id: data.id || null,
      //   title: data.title || { default: '' },
      //   label: data.label || { default: '' },
      //   type: data.type || 'text',
      //   // config: data.config || {},
      // })

      // console.log(changes)
      // this.updateConfig(data.config || {})

      // this.deleteAllFields()
      // const formFields = this.formFields
      // fields.forEach(x => {
      //   formFields.push(this.createField(x))
      // })
    }
  }
}
