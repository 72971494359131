import { Directionality } from '@angular/cdk/bidi'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { ESCAPE, UP_ARROW } from '@angular/cdk/keycodes'
import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollStrategy,
} from '@angular/cdk/overlay'
import { ComponentPortal, ComponentType } from '@angular/cdk/portal'
import { DOCUMENT } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core'
import {
  CanColor,
  CanColorCtor,
  DateAdapter,
  mixinColor,
  ThemePalette,
} from '@angular/material/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { merge, Subject, Subscription } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { HotCalendarComponent } from './calendar'
import {
  matDatepickerAnimations,
  MAT_DATEPICKER_SCROLL_STRATEGY,
} from '@angular/material/datepicker'
import { createMissingDateImplError } from './datepicker-errors'
import { HotCalendarCellCssClasses } from './calendar-body'
import { HotDatepickerInputDirective, HotDatepickerRangeValue } from './datepicker-input'
import * as R from 'ramda'

let datepickerUid = 0

/** @docs-private */
class HotDatepickerContentBase {
  constructor(public _elementRef: ElementRef) {}
}
const _HotDatepickerContentMixinBase: CanColorCtor & typeof HotDatepickerContentBase = mixinColor(
  HotDatepickerContentBase
)

@Component({
  moduleId: module.id,
  selector: 'hot-datepicker-content',
  templateUrl: 'datepicker-content.html',
  styleUrls: ['datepicker-content.css', '_datepicker-theme.scss'],
  animations: [matDatepickerAnimations.transformPanel, matDatepickerAnimations.fadeInCalendar],
  exportAs: 'matDatepickerContent',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotDatepickerContentDialog<D> extends _HotDatepickerContentMixinBase
  implements AfterViewInit, CanColor {
  /** Reference to the internal calendar component. */
  @ViewChild(HotCalendarComponent) _calendar: HotCalendarComponent<D>
  datepicker: HotDatepickerComponent<D>
  @HostBinding('attr.class') cssClass = 'mat-datepicker-content'
  @HostBinding('@transformPanel') 'enter'
  @HostBinding('class.mat-datepicker-content-touch')
  get touchUi() {
    return this.datepicker.touchUi
  }
  @Input() color: ThemePalette

  constructor(elementRef: ElementRef) {
    super(elementRef)
  }

  ngAfterViewInit() {
    this._calendar.focusActiveCell()
  }

  close() {
    if (this.datepicker.closeAfterSelection) {
      this.datepicker.close()
    }
  }
}

@Component({
  moduleId: module.id,
  selector: 'hot-datepicker',
  template: '',
  exportAs: 'hotDatepicker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HotDatepickerComponent<D> implements OnDestroy, CanColor {
  public id = `hot-datepicker-${datepickerUid++}`
  private _rangeMode: boolean
  private readonly _scrollStrategy: () => ScrollStrategy
  private _disabled: boolean
  private _locale: string

  private _popupRef: OverlayRef
  private _dialogRef: MatDialogRef<HotDatepickerContentDialog<D>> | null

  private _calendarPortal: ComponentPortal<HotDatepickerContentDialog<D>>
  private _popupComponentRef: ComponentRef<HotDatepickerContentDialog<D>> | null

  private _focusedElementBeforeOpen: HTMLElement | null = null
  private _inputSubscription = Subscription.EMPTY
  private _datepickerInput: HotDatepickerInputDirective<D>
  readonly _disabledChange = new Subject<boolean>()
  readonly _selectedChanged = new Subject<HotDatepickerRangeValue<D> | D>()
  private _beginDateSelected: D | null

  @Input() calendarHeaderComponent: ComponentType<any>
  @Output() readonly yearSelected: EventEmitter<D> = new EventEmitter<D>()
  @Output() readonly monthSelected: EventEmitter<D> = new EventEmitter<D>()
  @Input() panelClass: string | string[]
  @Input() dateClass: (date: D) => HotCalendarCellCssClasses
  // tslint:disable-next-line:no-output-rename
  @Output('opened') openedStream: EventEmitter<void> = new EventEmitter<void>()
  // tslint:disable-next-line:no-output-rename
  @Output('closed') closedStream: EventEmitter<void> = new EventEmitter<void>()

  @Input()
  set locale(locale: string) {
    this._locale = locale
    this._dateAdapter.setLocale(this._locale)
  }

  @Input()
  get rangeMode(): boolean {
    return this._rangeMode
  }
  set rangeMode(mode: boolean) {
    this._rangeMode = mode
    if (this.rangeMode) {
      this._validSelected = null
    } else {
      this._beginDate = this._endDate = null
    }
  }

  @Input()
  get beginDate(): D | null {
    return this._beginDate
  }
  set beginDate(value: D | null) {
    this._validSelected = null
    this._beginDate = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  _beginDate: D | null

  @Input()
  get endDate(): D | null {
    return this._endDate
  }
  set endDate(value: D | null) {
    this._validSelected = null
    this._endDate = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  _endDate: D | null

  @Input()
  get startAt(): D | null {
    if (this.rangeMode) {
      return (
        this._startAt ||
        (this._datepickerInput && this._datepickerInput.value
          ? (<HotDatepickerRangeValue<D>>this._datepickerInput.value).begin
          : null)
      )
    }
    return this._startAt || (this._datepickerInput ? <D | null>this._datepickerInput.value : null)
  }
  set startAt(value: D | null) {
    this._startAt = this._getValidDateOrNull(this._dateAdapter.deserialize(value))
  }
  private _startAt: D | null

  /** The view that the calendar should start in. */
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month'

  /** Color palette to use on the datepicker's calendar. */
  @Input()
  get color(): ThemePalette {
    return (
      this._color || (this._datepickerInput ? this._datepickerInput._getThemePalette() : undefined)
    )
  }
  set color(value: ThemePalette) {
    this._color = value
  }
  _color: ThemePalette

  @Input()
  get touchUi(): boolean {
    return this._touchUi
  }
  set touchUi(value: boolean) {
    this._touchUi = coerceBooleanProperty(value)
  }
  private _touchUi = false

  @Input()
  get disabled(): boolean {
    return this._disabled === undefined && this._datepickerInput
      ? this._datepickerInput.disabled
      : !!this._disabled
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value)

    if (newValue !== this._disabled) {
      this._disabled = newValue
      this._disabledChange.next(newValue)
    }
  }

  @Input() closeAfterSelection = true

  /** In range mod, enable datepicker to select the first date selected as a one-day-range,
   * if the user closes the picker before selecting another date
   */
  @Input() selectFirstDateOnClose = false

  /** Order the views when clicking on period label button */
  @Input() orderPeriodLabel: 'month' | 'multi-year' = 'multi-year'

  /** Whether the calendar is open. */
  @Input()
  get opened(): boolean {
    return this._opened
  }
  set opened(value: boolean) {
    value ? this.open() : this.close()
  }
  private _opened = false

  @Input()
  get maxIntervalInDays() {
    return this._maxIntervalInDays
  }
  set maxIntervalInDays(value: number | null) {
    this._maxIntervalInDays = value
  }
  private _maxIntervalInDays: number | null

  /** The currently selected date. */
  get _selected(): D | null {
    return this._validSelected
  }
  set _selected(value: D | null) {
    this._validSelected = value
  }
  private _validSelected: D | null = null

  /** The minimum selectable date. */
  get _minDate(): D | null {
    return this._datepickerInput && this._datepickerInput.min
  }

  /** The maximum selectable date. */
  get _maxDate(): D | null {
    return this._datepickerInput && this._datepickerInput.max
  }

  get _dateFilter(): (date: D | null) => boolean {
    return this._datepickerInput && this._datepickerInput._dateFilter
  }

  constructor(
    private _dialog: MatDialog,
    private _overlay: Overlay,
    private _ngZone: NgZone,
    private _viewContainerRef: ViewContainerRef,
    @Inject(MAT_DATEPICKER_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() private _dateAdapter: DateAdapter<D>,
    @Optional() private _dir: Directionality,
    @Optional() @Inject(DOCUMENT) private _document: any
  ) {
    if (!this._dateAdapter) {
      throw createMissingDateImplError('DateAdapter')
    }

    this._scrollStrategy = scrollStrategy
  }

  ngOnDestroy() {
    this.close()
    this._inputSubscription.unsubscribe()
    this._disabledChange.complete()

    if (this._popupRef) {
      this._popupRef.dispose()
      this._popupComponentRef = null
    }
  }

  select(date: D): void {
    const oldValue = this._selected
    this._selected = date
    if (!this._dateAdapter.sameDate(oldValue, this._selected)) {
      this._selectedChanged.next(date)
    }
  }

  _selectRange(dates: HotDatepickerRangeValue<D>): void {
    this._beginDateSelected = null
    if (
      !this._dateAdapter.sameDate(dates.begin, this.beginDate) ||
      !this._dateAdapter.sameDate(dates.end, this.endDate)
    ) {
      this._selectedChanged.next(dates)
    }
    this._beginDate = dates.begin
    this._endDate = dates.end
  }
  _selectYear(normalizedYear: D): void {
    this.yearSelected.emit(normalizedYear)
  }

  _selectMonth(normalizedMonth: D): void {
    this.monthSelected.emit(normalizedMonth)
  }

  /**
   * Register an input with this datepicker.
   * @param input The datepicker input to register with this datepicker.
   */
  _registerInput(input: HotDatepickerInputDirective<D>): void {
    if (this._datepickerInput) {
      throw Error('A HotDatepicker can only be associated with a single input.')
    }
    this._datepickerInput = input
    this._inputSubscription = this._datepickerInput._valueChange.subscribe(
      (value: HotDatepickerRangeValue<D> | D | null) => {
        if (R.isNil(value)) {
          this.beginDate = this.endDate = this._selected = null
          return
        }
        if (value && value.hasOwnProperty('begin') && value.hasOwnProperty('end')) {
          value = <HotDatepickerRangeValue<D>>value
          if (
            value.begin &&
            value.end &&
            this._dateAdapter.compareDate(value.begin, value.end) <= 0
          ) {
            this.beginDate = value.begin
            this.endDate = value.end
          } else {
            this.beginDate = this.endDate = null
          }
        } else {
          this._selected = <D>value
        }
      }
    )
  }

  open(): void {
    if (this._opened || this.disabled) {
      return
    }
    if (!this._datepickerInput) {
      throw Error('Attempted to open an HotDatepicker with no associated input.')
    }
    if (this._document) {
      this._focusedElementBeforeOpen = this._document.activeElement
    }

    this.touchUi ? this._openAsDialog() : this._openAsPopup()
    this._opened = true
    this.openedStream.emit()
  }

  close(): void {
    if (!this._opened) {
      return
    }
    if (this._popupRef && this._popupRef.hasAttached()) {
      this._popupRef.detach()
    }
    if (this._dialogRef) {
      this._dialogRef.close()
      this._dialogRef = null
    }
    if (this._calendarPortal && this._calendarPortal.isAttached) {
      this._calendarPortal.detach()
    }
    if (this._beginDateSelected && this.selectFirstDateOnClose) {
      this._selectRange({ begin: this._beginDateSelected, end: this._beginDateSelected })
    }

    const completeClose = () => {
      // The `_opened` could've been reset already if
      // we got two events in quick succession.
      if (this._opened) {
        this._opened = false
        this.closedStream.emit()
        this._focusedElementBeforeOpen = null
      }
    }

    if (
      this._focusedElementBeforeOpen &&
      typeof this._focusedElementBeforeOpen.focus === 'function'
    ) {
      // Because IE moves focus asynchronously, we can't count on it being restored before we've
      // marked the datepicker as closed. If the event fires out of sequence and the element that
      // we're refocusing opens the datepicker on focus, the user could be stuck with not being
      // able to close the calendar at all. We work around it by making the logic, that marks
      // the datepicker as closed, async as well.
      this._focusedElementBeforeOpen.focus()
      setTimeout(completeClose)
    } else {
      completeClose()
    }
  }

  setBeginDateSelected(date: D): void {
    this._beginDateSelected = date
  }

  /** Open the calendar as a dialog. */
  private _openAsDialog(): void {
    if (this._dialogRef) {
      this._dialogRef.close()
    }

    this._dialogRef = this._dialog.open<HotDatepickerContentDialog<D>>(HotDatepickerContentDialog, {
      direction: this._dir ? this._dir.value : 'ltr',
      viewContainerRef: this._viewContainerRef,
      panelClass: 'mat-datepicker-dialog',
    })

    this._dialogRef.afterClosed().subscribe(() => this.close())
    this._dialogRef.componentInstance.datepicker = this
    this._setColor()
  }

  /** Open the calendar as a popup. */
  private _openAsPopup(): void {
    if (!this._calendarPortal) {
      this._calendarPortal = new ComponentPortal<HotDatepickerContentDialog<D>>(
        HotDatepickerContentDialog,
        this._viewContainerRef
      )
    }

    if (!this._popupRef) {
      this._createPopup()
    }

    if (!this._popupRef.hasAttached()) {
      this._popupComponentRef = this._popupRef.attach(this._calendarPortal)
      this._popupComponentRef.instance.datepicker = this
      this._setColor()

      // Update the position once the calendar has rendered.
      this._ngZone.onStable
        .asObservable()
        .pipe(take(1))
        .subscribe(() => {
          this._popupRef.updatePosition()
        })
    }
  }

  /** Create the popup. */
  private _createPopup(): void {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this._createPopupPositionStrategy(),
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
      direction: this._dir,
      scrollStrategy: this._scrollStrategy(),
      panelClass: 'mat-datepicker-popup',
    })

    this._popupRef = this._overlay.create(overlayConfig)
    this._popupRef.overlayElement.setAttribute('role', 'dialog')

    merge(
      this._popupRef.backdropClick(),
      this._popupRef.detachments(),
      this._popupRef.keydownEvents().pipe(
        filter(event => {
          // Closing on alt + up is only valid when there's an input associated with the datepicker.
          return (
            event.keyCode === ESCAPE ||
            (this._datepickerInput && event.altKey && event.keyCode === UP_ARROW)
          )
        })
      )
    ).subscribe(event => {
      if (event) {
        event.preventDefault()
      }

      this.close()
    })
  }

  /** Create the popup PositionStrategy. */
  private _createPopupPositionStrategy(): PositionStrategy {
    return this._overlay
      .position()
      .flexibleConnectedTo(this._datepickerInput.getConnectedOverlayOrigin())
      .withTransformOriginOn('.mat-datepicker-content')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withLockedPosition()
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ])
  }

  /**
   * @param obj The object to check.
   * @returns The given object if it is both a date instance and valid, otherwise null.
   */
  private _getValidDateOrNull(obj: any): D | null {
    return this._dateAdapter.isDateInstance(obj) && this._dateAdapter.isValid(obj) ? obj : null
  }

  /** Passes the current theme color along to the calendar overlay. */
  private _setColor(): void {
    const color = this.color
    if (this._popupComponentRef) {
      this._popupComponentRef.instance.color = color
    }
    if (this._dialogRef) {
      this._dialogRef.componentInstance.color = color
    }
  }
}
