import { path } from 'ramda'
import { Address, getLat, getLng, getCountryCode, getCity } from './address.model'
import { LanguageType } from '@shared/constants'
import { UserPropertyPermission } from './user.model'
import { Url } from 'url'

export interface PropertyInfo {
  photoUrl?: string
  address?: string
  label?: string
}

export interface PropertyMember {
  user: string
  role: string
  pickupPoint?: string
  workingHours?: Array<{
    day: string
    from: string
    to: string
  }>
}

export interface PropertySharingSettings {
  vkSharingText?: string
  socialNetworkUrls: {
    vk?: string
    fb?: string
    tripAdvisor?: string
  }
}

export interface PropertyPhone {
  _id?: string
  value: string
  label: {
    default: string
    en?: string
    ru?: string
  }
}

export enum BookingEngineType {
  None = 'none',
  AYS = 'ays',
  FRONTDESK = 'frontdesk',
  TRAVELLINE = 'travelline',
  BOOKING = 'booking',
  CUSTOM_FORM = 'custom:form',
}

export const BookingEngineLabels = {
  [BookingEngineType.None]: '—',
  [BookingEngineType.AYS]: 'AYS',
  [BookingEngineType.FRONTDESK]: 'Frontdesk24',
  [BookingEngineType.TRAVELLINE]: 'TravelLine',
  [BookingEngineType.BOOKING]: 'Booking.com',
}

export interface BookingEngine {
  url: string
  engineType: BookingEngineType
  config?: {
    children?: boolean
    childrenAge?: boolean
    hourlyBooking?: boolean
    departureOnDayOfArrival?: boolean
    form?: string
  }
  externalId?: string
}

export enum BackofficeIntegraion {
  HKEEPER = 'hkeeper',
  TEAMJET = 'teamjet',
}

export enum DevicesIntegration {
  SBERDEVICES = 'sberdevices',
}

export const DevicesIntegrationLabels = {
  [DevicesIntegration.SBERDEVICES]: 'SberDevices',
}

export enum MapServices {
  GOOGLE = 'google',
  YANDEX = 'yandex',
}

export const MapServicesLabels = {
  [MapServices.GOOGLE]: 'Google Maps',
  [MapServices.YANDEX]: 'Yandex Maps',
}

export const BackofficeIntegraionLabels = {
  [BackofficeIntegraion.HKEEPER]: 'HKeeper',
  [BackofficeIntegraion.TEAMJET]: 'TeamJet',
}

export type ExternalSource = 'bookingsuite' | 'bookingsuite'

export enum ExternalSources {
  BookingSuite = 'bookingsuite',
  TravelLine = 'bookingsuite',
}

export type PropertyGoalType =
  | 'connect_messenger'
  | 'add_members'
  | 'verify_faq'
  | 'verify_services'

export interface PropertyGoal {
  name: PropertyGoalType
  completedAt: Date | null
  skippedAt: Date | null
}

export enum IntegrationProvider {
  Emailreports = 'emailreports',
  Fidelio = 'fidelio',
  FtpReports = 'ftpreports',
  Logus = 'logus',
  Servio = 'servio',
  GoogleAnalytics = 'ga',
  Yametrika = 'yametrika',
  OneCHotel = '1chotel',
  AYS = 'ays',
}

export interface Integration {
  provider: IntegrationProvider
  config?: any

  error?: string
  lastSuccessDate?: string
  lastErrorDate?: string
}

export enum PropertyKind {
  Hotel = 'hotel',
  Restaurant = 'restaurant',
}

interface HTTPSignature {
  url: Url
  key: string
  keyId: string
  algorithm: string
}

export interface Property {
  _id?: string
  name: string
  alias?: string
  logoUrl: string
  phone: PropertyPhone[]
  website?: string
  shopWebsite?: string
  bookingEngine: BookingEngine
  email?: string
  address?: Address
  externalId?: {
    id: string
    source: ExternalSource
  }
  info?: {
    en?: PropertyInfo
    ru?: PropertyInfo
  }
  sharingSettings: {
    [key: string]: PropertySharingSettings
  }
  defaultLanguage?: string
  members?: PropertyMember[]
  description: {}
  legalAgreement: {}
  languages: string[]
  config?: {
    bookingDuringOnboarding?: boolean
    authorizationAfterOnboarding?: boolean
    sendInvitationEmails?: boolean
    chatModeEnabled?: boolean
    adminConnectionEnabled?: boolean
    botEnabled?: boolean
    wifiEnabled?: boolean
    formsEnabled?: boolean
    pollsEnabled?: boolean
    ordersEnabled?: boolean
    showMyRequestsButton?: boolean
    filterRestaurantsByLocation?: boolean
    filterRestaurantsByCity?: boolean
    allowBookRestaurantsRequest?: boolean
    loadPlacesFromKudaGo?: boolean
    isHapiEnabled?: boolean
    inviteBySms?: boolean
    menuBuilderEnabled?: boolean
    authFlow?: string
    aiEnabled?: boolean
    reservationsEnabled?: boolean
    splitCottagesFromRooms?: boolean
    salesServiceEnabled?: boolean
    shopServiceEnabled?: boolean
    hideSwitchPropertyButton?: boolean
    isPMSIntegrationMonitoringEnabled?: boolean
    isDevicesEnabled?: boolean
    demoProperty?: boolean
  }
  goals?: PropertyGoal[]
  badges?: {
    openedRequests?: number
    openedReviews?: number
    openedChats?: number
  }
  timeZone: string
  useProxy: boolean
  backofficeIntegration?: {
    provider: string
    url: string
    token: string
  }
  source?: 'bookingsuite' | null

  permissions?: UserPropertyPermission[]

  wallet?: {
    enabled?: boolean
    options?: Partial<{
      labelColor: string
      foregroundColor: string
      backgroundColor: string
    }>
  }

  integrations?: Integration[]
  bookingRefundPolicy?: {
    text: { [key: string]: string }
    schema: any[]
  }
  kind?: PropertyKind | null

  mapService?: MapServices

  checkInTime: string
  checkOutTime: string

  devicesIntegration?: {
    provider?: DevicesIntegration | null
    config?: Partial<HTTPSignature>
  }
}

const parseVkSharingText = link => {
  const startText = '&comment='
  const endText = '%23'
  const startIndex = link.indexOf(startText) + startText.length
  const endIndex = link.indexOf(endText)
  return link.slice(startIndex, endIndex).trim()
}

export const getPropertyInfo = (property: Property, language?: string): PropertyInfo => {
  return property && property.info && (property.info[language] || '')
}

export const getPropertyAddress = (property: Property): Address => {
  return property && property.address
}

export const getPropertyLat = (property: Property): number => {
  const address = getPropertyAddress(property)
  return getLat(address)
}

export const getPropertyLng = (property: Property): number => {
  const address = getPropertyAddress(property)
  return getLng(address)
}

export const getPropertyCountryCode = (property: Property): string => {
  const address = getPropertyAddress(property)
  return getCountryCode(address)
}

export const getPropertyCity = (property: Property): string => {
  const address = getPropertyAddress(property)
  return getCity(address)
}

export const getPropertyInfoPhotoUrl = (property: Property, lang: string): string => {
  const info = getPropertyInfo(property, lang)
  return info && info.photoUrl
}

export const getPropertyInfoAddress = (property: Property, lang: string): string => {
  const info = getPropertyInfo(property, lang)
  return info && info.address
}

export const getPropertyName = (property: Property): string => {
  return property && property.name
}

export const getPropertyAlias = (property: Property): string => {
  return property && property.alias
}

export const getPropertyEmail = (property: Property): string => {
  return property && property.email
}

export const getPropertyWebsite = (property: Property): string => {
  return property && property.website
}

export const getPropertyTimeZone = (property: Property): string => {
  return property && property.timeZone
}

export const getPropertyTripAdvisorLink = (property: Property, lang = LanguageType.RU): string => {
  return (
    (path(['sharingSettings', lang, 'socialNetworkUrls', 'tripAdvisor'], property) as string) || ''
  ).trim()
}

export const getPropertyVkSharingText = (property: Property, lang: string): string => {
  const vkSharingText = path(['sharingSettings', lang, 'vkSharingText'], property)
  if (vkSharingText !== undefined) {
    return String(vkSharingText).trim()
  }

  const vkUrl = path(['sharingSettings', lang, 'socialNetworkUrls', 'vk'], property)
  if (vkUrl !== undefined) {
    return parseVkSharingText(vkUrl).trim()
  }

  return ''
}

export const generatePropertySharingSettings = (
  propertyFormData,
  language: string
): PropertySharingSettings => {
  const tripAdvisor = propertyFormData.TripAdvisor || ''
  const vkSharingText =
    propertyFormData.VkSharingText &&
    propertyFormData.VkSharingText[language] &&
    propertyFormData.VkSharingText[language].trim()
      ? `${propertyFormData.VkSharingText[language].trim()} `
      : ''

  return {
    vkSharingText,
    socialNetworkUrls: { tripAdvisor },
  }
}

export const filterPropertyByQuery = (query: string) => (property: Property) =>
  query ? property.name && property.name.toLowerCase().includes(query) : true
