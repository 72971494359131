import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Push, NormalizedResult, NormalizedResults } from '@models'
import { Observable } from 'rxjs'

@Injectable()
export class PushService {
  constructor(private apiService: ApiService) {}

  public createPush(propertyId: string, push: Push): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/pushes`, push)
  }

  public updatePush(propertyId: string, pushId: string, push: Push): Observable<NormalizedResult> {
    return this.apiService.patch(`properties/${propertyId}/pushes/${pushId}`, push)
  }

  public deletePush(propertyId: string, pushId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`properties/${propertyId}/pushes/${pushId}`)
  }

  public getList(propertyId: string, params?: any): Observable<NormalizedResults> {
    return this.apiService.get(`properties/${propertyId}/pushes`, {
      params: {
        status: params.status || '',
        page: params.page,
        limit: params.limit,
      },
    })
  }

  public get(propertyId: string, pushMessageId: string): Observable<NormalizedResult> {
    return this.apiService.get(`properties/${propertyId}/pushes/${pushMessageId}`)
  }
}
