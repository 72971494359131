import { HttpClient } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'

import { BehaviorSubject, Observable, Subscriber, throwError } from 'rxjs'
import { first, map, switchMap } from 'rxjs/operators'

import { ApiService } from '@shared/services/api.service'

import { ConfigService } from './config.service'
import { I18nService } from './i18n.service'

const fbLocales = {
  en: 'en_US',
  es: 'es_LA',
  ru: 'ru_RU',
}

declare var FB: any

export interface FacebookLoginStatus {
  status: 'connected' | 'authorization_expired' | 'not_authorized'
  authResponse?: {
    userID: string
    accessToken: string
  }
}

export interface FacebookPage {
  id: string
  name: string
  access_token: string
  username: string
  picture: {
    data: { url: string }
  }
  [key: string]: any
}

const LOGIN_SCOPE =
  'public_profile,manage_pages,pages_show_list,pages_messaging,pages_messaging_subscriptions'

@Injectable()
export class FacebookService {
  private readonly facebookAppId: string
  private loaded = new BehaviorSubject(false)

  constructor(
    public http: HttpClient,
    private i18nService: I18nService,
    private apiService: ApiService,
    private configService: ConfigService,
    private zone: NgZone
  ) {
    this.facebookAppId = this.configService.FACEBOOK_APP_ID
    if (!this.facebookAppId) {
      throw new Error('FACEBOOK_APP_ID is not provided')
    }
    this.ensureSDKLoaded()
    this.loaded.pipe(first(Boolean)).subscribe(() => {
      this.initFB()
    })
  }

  private wrapToObservable<T>(fn: (s: Subscriber<T>) => any) {
    // Also we're waiting for FB loaded
    return this.loaded.pipe(
      first(Boolean),
      switchMap(() => {
        return new Observable<T>(fn)
      })
    )
  }

  getFacebookPages(): Observable<FacebookPage> {
    return this.getLoginStatus().pipe(
      switchMap(response => {
        if (response.status === 'connected') {
          const token = response.authResponse.accessToken
          return this.apiService.get('fb/pages?access_token=' + token)
        }
        return throwError(response.status)
      }),
      map(res => res.data)
    )
  }

  getLoginStatus() {
    return this.wrapToObservable<FacebookLoginStatus>(observer => {
      FB.getLoginStatus((response: FacebookLoginStatus) => {
        this.zone.run(() => {
          observer.next(response)
          observer.complete()
        })
      })
    })
  }

  logout() {
    return this.wrapToObservable<FacebookLoginStatus>(observer => {
      try {
        FB.logout((response: FacebookLoginStatus) => {
          this.zone.run(() => {
            observer.next(response)
            observer.complete()
          })
        })
      } catch (e) {
        observer.error(e)
      }
    })
  }

  login() {
    return this.wrapToObservable<FacebookLoginStatus>(observer => {
      FB.login(
        (response: FacebookLoginStatus) => {
          if (response.status === 'connected') {
            observer.next(response)
            observer.complete()
          } else {
            observer.error(response)
          }
        },
        { scope: LOGIN_SCOPE }
      )
    })
  }

  initFB() {
    this.zone.run(() => {
      FB.init({
        appId: this.facebookAppId,
        autoLogAppEvents: false, // true,
        cookie: false,
        xfbml: false,
        version: 'v3.10',
      })
    })
  }

  ensureSDKLoaded() {
    const d = document
    const s = 'script'
    const id = 'facebook-jssdk'

    if (this.loaded.value) {
      return
    }

    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      this.loaded.next(true)
      return
    }

    window['fbAsyncInit'] = () => {
      this.loaded.next(true)
      delete window['fbAsyncInit']
    }

    const lang = this.i18nService.currentLanguage()
    const fbLocale = fbLocales[lang] || fbLocales.en
    const js: any = d.createElement(s)
    js.id = id
    js.src = `//connect.facebook.net/${fbLocale}/sdk.js`
    fjs.parentNode.insertBefore(js, fjs)
  }
}
