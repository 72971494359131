import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ThemeKind, ThemeContext } from '@shared/directives/theme.directive'
import * as R from 'ramda'
import * as datalabelsPlugin from 'chartjs-plugin-datalabels'
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, first, map, skip } from 'rxjs/operators'

import { ChartType, defaultChartOptions, getOverridesForType } from './chart-types'
import { Size } from './types'
import * as chartColors from './chart-colors'

@Component({
  selector: 'hot-stats-chart',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <canvas
      *ngIf="(chartData$ | async) as chartData"
      baseChart
      [chartType]="chartData.chartType"
      [datasets]="chartData.datasets"
      [labels]="chartData.labels"
      [options]="chartData.options"
      [plugins]="chartData.plugins"
      [colors]="chartData.colors"
    >
    </canvas>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class StatsChartComponent implements OnInit, OnDestroy {
  @Input() public colorFn: chartColors.GetColorsFn = null
  @Input() set colors(value: any[]) {
    this.userColors$.next(value)
  }

  @Input() set datasets(value: any[]) {
    this.userDatasets$.next(value)
  }
  @Input() set labels(value: string[]) {
    this.userLabels$.next(value)
  }

  @Input() set chartType(value: ChartType) {
    this.chartType$.next(value)
  }

  @Input() set options(value) {
    this.userOptions$.next(value)
  }

  private title$ = new BehaviorSubject('')
  private chartType$ = new BehaviorSubject(ChartType.Line)
  private userColors$ = new BehaviorSubject<any>([])
  private userLabels$ = new BehaviorSubject<string[]>([])
  private userDatasets$ = new BehaviorSubject<any[]>([])
  private userOptions$ = new BehaviorSubject<any>(null)
  private canvasSize$ = new BehaviorSubject<Size>(null)
  private initialCanvasSize$ = this.canvasSize$.pipe(
    filter(x => x !== null),
    first()
  )
  private debouncedCanvasResize$ = this.canvasSize$.pipe(
    filter(x => x !== null),
    skip(1),
    debounceTime(100)
  )

  private canvasSizeChanges$ = merge(this.initialCanvasSize$, this.debouncedCanvasResize$).pipe(
    distinctUntilChanged(this.distinctSizeChanges)
  )

  private colors$: Observable<any>
  private options$: Observable<any>
  public chartData$: Observable<any>

  constructor(private themeContext: ThemeContext, private el: ElementRef) {
    const onChartResize = (_chart, size) => {
      this.canvasSize$.next(size)
    }

    this.options$ = this.initOptions(onChartResize)
    this.colors$ = this.initColors()

    // Wait until all data is ready
    this.chartData$ = combineLatest([
      this.chartType$,
      this.options$,
      this.userDatasets$,
      this.userLabels$,
      this.colors$,
    ]).pipe(
      map(([chartType, options, datasets, labels, colors]) => {
        return {
          chartType,
          options,
          datasets,
          labels,
          colors,
          plugins: [datalabelsPlugin],
        }
      })
    )
  }

  private initOptions(onResize: (chart: any, size: Size) => void) {
    return combineLatest([
      this.userOptions$,
      this.title$,
      this.chartType$,
      this.themeContext.theme$,
    ]).pipe(
      map(([userOpts, title, chartType, theme]) => {
        const combinedOpts = {
          ...defaultChartOptions,
          ...getOverridesForType(chartType),
          ...userOpts,
          onResize,
        }
        return this.adjustOptions(combinedOpts, { title, chartType, theme })
      })
    )
  }

  private initColors() {
    return combineLatest([
      this.themeContext.theme$,
      this.chartType$,
      this.userLabels$,
      this.canvasSizeChanges$,
      this.userColors$,
    ]).pipe(
      map(([theme, chartType, labels, canvasSize, userColors]) => {
        const params: chartColors.GetColorsFnParams = {
          theme,
          chartType,
          canvasSize,
          // TODO: For multiple line charts this should be datasets count
          colorsCount: labels.length,
          userColors,
        }
        if (this.colorFn) {
          return this.colorFn(params)
        }
        return this.getColors(params)
      })
    )
  }

  private adjustOptions(options, { title, chartType, theme }) {
    let data = options
    if (chartType === ChartType.Bar || chartType === ChartType.HorizontalBar) {
      const color = theme === ThemeKind.Dark ? 'hsla(0, 100%, 100%, 0.8)' : 'hsla(0, 0%, 0%, 0.8)'
      data = R.assocPath(['scales', 'yAxes', 0, 'ticks', 'fontColor'], color, data)
    }
    if (title) {
      data = R.assocPath(['title', 'text'], title || '', data)
    }
    return data
  }

  private distinctSizeChanges(x, y) {
    // return x.height === y.height && x.width === y.width
    return x.height === y.height
  }

  protected updateCanvasSize() {
    this.canvasSize$.next({
      width: this.el.nativeElement.clientWidth,
      height: this.el.nativeElement.clientHeight,
    })
  }

  public ngOnInit() {
    this.updateCanvasSize()
  }
  public ngOnDestroy() {}

  private getColors(params: chartColors.GetColorsFnParams) {
    const { chartType } = params
    if (chartType === ChartType.Line) {
      return chartColors.getColorsForLineChart(params)
    } else if (chartType === ChartType.Bar || chartType === ChartType.HorizontalBar) {
      return chartColors.getColorsForBarChart(params)
    } else if (chartType === ChartType.Donut || chartType === ChartType.Pie) {
      return chartColors.getColorsForPieChart(params)
    }
    return []
  }
}
