import { Action } from '@ngrx/store'
import { MenuPagesTemplates } from '@models'

export enum MenuPagesTemplatesActionTypes {
  Create = '[Menu Pages Templates] Create',
  CreateSuccess = '[Menu Pages Templates] Create Success',
  CreateFail = '[Menu Pages Templates] Create Fail',
  Load = '[Menu Pages Templates] Load',
  LoadSuccess = '[Menu Pages Templates] Load Success',
  LoadFail = '[Menu Pages Templates] Load Fail',
  Update = '[Menu Pages Templates] Update',
  UpdateSuccess = '[Menu Pages Templates] Update Success',
  UpdateFail = '[Menu Pages Templates] Update Fail',
  Delete = '[Menu Pages Templates] Delete',
  DeleteSuccess = '[Menu Pages Templates] Delete Success',
  DeleteFail = '[Menu Pages Templates] Delete Fail',
}

/**
 * Create Menu Pages Templates actions
 */

export interface CreatePayload {
  languages: string[]
}

export class Create implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.Create
  constructor(public payload: CreatePayload) {}
}
export class CreateSuccess implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.CreateSuccess
  constructor(public payload, public meta: any) {}
}
export class CreateFail implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.CreateFail

  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Load Menu Pages Templates actions
 */

export class Load implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.Load
  constructor(public payload?: any) {}
}
export class LoadSuccess implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.LoadSuccess
  constructor(public payload, public meta: any) {}
}
export class LoadFail implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.LoadFail
  constructor(public error: any, public meta: any) {}
}

/**
 * Update Menu Pages Templates actions
 */

export class Update implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.Update
  constructor(public payload: any) {}
}
export class UpdateSuccess implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.UpdateSuccess
  constructor(public payload: MenuPagesTemplates, public meta: any) {}
}
export class UpdateFail implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.UpdateFail
  constructor(public error: any, public meta: any) {}
}

/**
 * Delete Menu Pages Templates actions
 */

export class Delete implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.Delete
  constructor(public payload: any) {}
}
export class DeleteSuccess implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.DeleteSuccess
  constructor(public payload: any) {}
}
export class DeleteFail implements Action {
  public readonly type = MenuPagesTemplatesActionTypes.DeleteFail
  constructor(public error: any, public meta: any) {}
}

export type MenuPagesTemplatesActions =
  | Create
  | CreateSuccess
  | CreateFail
  | Load
  | LoadSuccess
  | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
