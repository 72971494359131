<div
  class="mw7 center mv5 flex flex-column"
  *ngIf="tokenValidationStatus !== statuses.PENDING; else loading"
>
  <div class="bg-white shadow-2 pa5 pb4 br2">
    <mat-icon svgIcon="hot:hotbot" class="mb4 hotbot-logo"></mat-icon>

    <form
      *ngIf="tokenValidationStatus === statuses.SUCCESS"
      novalidate
      [formGroup]="setPasswordForm"
      (ngSubmit)="submit()"
      class="auth-form-container"
      [class.mb5]="!isBookingSuiteEnabled"
    >
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{ 'Auth.field.password' | translate: locale.language }}</mat-label>
        <input
          matInput
          type="password"
          tabindex="2"
          autocomplete="off"
          autocapitalize="off"
          formControlName="password"
        />
        <mat-error
          *ngIf="
            setPasswordForm &&
            setPasswordForm.touched &&
            setPasswordForm.get('password').hasError('required')
          "
        >
          {{ 'forms.error.fieldRequired' | translate: locale.language }}
        </mat-error>
        <mat-error
          *ngIf="
            setPasswordForm &&
            setPasswordForm.touched &&
            setPasswordForm.get('password').hasError('blank')
          "
        >
          {{ 'forms.error.answerCannotContainSpacesOnly' | translate: locale.language }}
        </mat-error>
        <mat-error
          *ngIf="
            setPasswordForm &&
            setPasswordForm.touched &&
            setPasswordForm.get('password').hasError('maxlength')
          "
        >
          {{ 'Auth.error.longPassword' | translate: locale.language:{ maxLength: passwordMaxLength }
          }}
        </mat-error>
        <mat-error
          *ngIf="
            setPasswordForm &&
            setPasswordForm.touched &&
            setPasswordForm.get('password').hasError('minlength')
          "
        >
          {{ 'Auth.error.weakPassword' | translate: locale.language:{ minLength: passwordMinLength }
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{ 'Auth.field.passwordConfirmation' | translate: locale.language }}</mat-label>
        <input
          matInput
          type="password"
          tabindex="2"
          autocomplete="off"
          autocapitalize="off"
          formControlName="passwordConfirmation"
        />
        <mat-error
          *ngIf="
            setPasswordForm &&
            setPasswordForm.touched &&
            setPasswordForm.get('passwordConfirmation').hasError('required')
          "
        >
          {{ 'forms.error.fieldRequired' | translate: locale.language }}
        </mat-error>
        <mat-error
          *ngIf="
            setPasswordForm &&
            setPasswordForm.touched &&
            setPasswordForm.get('passwordConfirmation').hasError('blank')
          "
        >
          {{ 'forms.error.answerCannotContainSpacesOnly' | translate: locale.language }}
        </mat-error>
        <mat-error
          *ngIf="
            setPasswordForm &&
            setPasswordForm.touched &&
            setPasswordForm.get('passwordConfirmation').hasError('passwordsMismatch')
          "
        >
          {{ 'Auth.error.passwordsMismatch' | translate: locale.language }}
        </mat-error>
      </mat-form-field>

      <div class="w-100">
        <button
          mat-flat-button
          [disabled]="!enableSubmit"
          color="accent"
          type="submit"
          class="f5 w-100 pv2"
          tabindex="3"
        >
          {{ 'Auth.action.setPassword' | translate: locale.language }}
        </button>
      </div>
    </form>

    <div class="w-100 mt3 tc">
      <a mat-button [routerLink]="'/login'" class="gray">
        {{ 'Auth.action.signIn' | translate: locale.language }}
      </a>
    </div>
  </div>

  <hot-app-language-selector class="self-end"></hot-app-language-selector>
</div>

<ng-template #loading>
  <div class="flex w-100 h-100 items-center justify-center">
    <mat-spinner [diameter]="64"></mat-spinner>
  </div>
</ng-template>
