import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@shared/services'
import { NormalizedResult, ShopProduct } from '@models'
import { LanguageType } from '@shared/constants'

@Injectable()
export class ShopProductService {
  constructor(public readonly apiService: ApiService) {}

  public create(
    propertyId: string,
    shopId: string,
    shopProduct: ShopProduct,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[]
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/shop/${shopId}/products`, {
      shopProduct,
      sourceLanguage,
      targetLanguages,
    })
  }

  public update(
    propertyId: string,
    shopId: string,
    shopProductId: string,
    shopProduct: ShopProduct,
    sourceLanguage: LanguageType,
    targetLanguages: LanguageType[],
    forceTranslate: boolean
  ): Observable<NormalizedResult> {
    return this.apiService.patch(
      `properties/${propertyId}/shop/${shopId}/products/${shopProductId}`,
      {
        shopProduct,
        sourceLanguage,
        targetLanguages,
        forceTranslate,
      }
    )
  }

  public delete(
    propertyId: string,
    shopId: string,
    shopProductId: string
  ): Observable<NormalizedResult> {
    return this.apiService.delete(
      `properties/${propertyId}/shop/${shopId}/products/${shopProductId}`
    )
  }
}
