export interface UploadFile {
  url: string
  contentType: string
  size: number
  meta?: any
}

export interface UploadFileResult {
  status: string
  data?: any
}

export enum UploadFileStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  CANCELLED_BY_USER = 'Cancelled_by_user',
}

export enum UploadFilePreset {
  ShopProduct = 'ShopProduct',
}
