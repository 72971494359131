import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { throwError as _throw } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { AuthService } from './auth.service'
import { ConfigService } from './config.service'

interface HttpOptions {
  headers?: object
  params?: object
  observe?: any
  responseType?: any
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly API_BASE_URL: string

  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public configService: ConfigService
  ) {
    this.API_BASE_URL = this.configService.API_BASE_URL
    if (!this.API_BASE_URL) {
      throw new Error('API_BASE_URL is not provided')
    }
  }

  public get(requestUrl: string, options?: HttpOptions) {
    return this.http
      .get(`${this.API_BASE_URL}/${requestUrl}`, this.prepareHttpOptions(options))
      .pipe(map(this.mapResultsIfNeeded), catchError(this.throwNormalizedError))
  }

  public put(requestUrl: string, payload: any, options?: HttpOptions) {
    return this.http
      .put(`${this.API_BASE_URL}/${requestUrl}`, payload, this.prepareHttpOptions(options))
      .pipe(map(this.mapResultsIfNeeded), catchError(this.throwNormalizedError))
  }

  public patch(requestUrl: string, payload: any, options?: HttpOptions) {
    return this.http
      .patch(`${this.API_BASE_URL}/${requestUrl}`, payload, this.prepareHttpOptions(options))
      .pipe(map(this.mapResultsIfNeeded), catchError(this.throwNormalizedError))
  }

  public post(requestUrl: string, payload: any, options?: HttpOptions) {
    return this.http
      .post(`${this.API_BASE_URL}/${requestUrl}`, payload, this.prepareHttpOptions(options))
      .pipe(map(this.mapResultsIfNeeded), catchError(this.throwNormalizedError))
  }

  public delete(requestUrl: string, options?: HttpOptions) {
    return this.http
      .delete(`${this.API_BASE_URL}/${requestUrl}`, this.prepareHttpOptions(options))
      .pipe(map(this.mapResultsIfNeeded), catchError(this.throwNormalizedError))
  }

  public prepareHttpOptions(
    options: HttpOptions = {}
  ): {
    headers?: HttpHeaders
    params?: HttpParams
    observe?: any
    responseType?: any
  } {
    const httpOptions: {
      headers?: HttpHeaders
      params?: HttpParams
      observe?: any
      responseType?: any
    } = {}

    if (options.params) {
      httpOptions.params = Object.keys(options.params).reduce((params, pKey) => {
        return params.set(pKey, options.params[pKey])
      }, new HttpParams())
    }

    if (options.headers) {
      httpOptions.headers = Object.keys(options.headers).reduce((headers, hKey) => {
        return headers.set(hKey, options.headers[hKey])
      }, new HttpHeaders())
    }

    if (options.observe) {
      httpOptions.observe = options.observe
    }

    if (options.responseType) {
      httpOptions.responseType = options.responseType
    }

    return httpOptions
  }

  public mapResultsIfNeeded = result => {
    if (result.resultType && !result.results && !result.result) {
      return {
        ...result,
        results: result[result.resultType].map(x => x.pageId || x._id),
      }
    }
    return result
  }

  public normalizeError = (error: any) => {
    return {
      statusCode: error.status !== undefined ? error.status : 500,
      message: error.message ? error.message : error.status ? error.statusText : 'Server error',
      description: error.error ? error.error : '',
    }
  }

  public throwNormalizedError = (response: HttpErrorResponse) => {
    return _throw(this.normalizeError(response))
  }
}
