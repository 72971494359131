import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ApiService } from '@shared/services'

import { User, NormalizedResult, UserProfile } from '@models'

@Injectable()
export class UserService {
  constructor(public readonly apiService: ApiService) {}

  public login(email: string, password: string): Observable<string> {
    return this.apiService
      .post('auth/login', { email, password })
      .pipe(map(response => response['token']))
  }

  public reset(email: string): Observable<string> {
    return this.apiService.post('auth/reset', { email })
  }

  public validateResetPasswordToken(token: string): Observable<string> {
    return this.apiService.post('auth/check', { token })
  }

  public setPassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ): Observable<string> {
    return this.apiService.put('auth/reset', { token, password, passwordConfirmation })
  }

  public getUser(): Observable<UserProfile> {
    return this.apiService.get('profile')
  }

  public createUser(
    propertyId: string,
    user: User,
    notifications,
    roomServiceNotifications
  ): Observable<NormalizedResult> {
    return this.apiService.post(`properties/${propertyId}/members`, {
      ...user,
      notifications,
      roomServiceNotifications,
    })
  }

  public updateUser(
    propertyId: string,
    userId: string,
    user: Partial<User>,
    notifications,
    roomServiceNotifications
  ): Observable<NormalizedResult> {
    return this.apiService.put(`properties/${propertyId}/members/${userId}`, {
      ...user,
      notifications,
      roomServiceNotifications,
    })
  }

  public deleteUser(propertyId: string, userId: string): Observable<NormalizedResult> {
    return this.apiService.delete(`properties/${propertyId}/members/${userId}`)
  }
}
