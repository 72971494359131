import { Actions, ActionTypes } from './cuisine.actions'
import * as R from 'ramda'
import { CuisineFilers, DEFAULT_PAGINATION_SIZE } from '@models'

export interface State {
  ids: string[]
  loaded: boolean
  loading: boolean
  filters: CuisineFilers
  meta?: {
    totalRecords?: number
  }
}

export const initialState: State = {
  ids: [],
  loaded: false,
  loading: false,
  filters: {
    paginationOptions: {
      limit: DEFAULT_PAGINATION_SIZE,
      page: 0,
    },
  },
  meta: {
    totalRecords: 0,
  },
}

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.Load: {
      return {
        ...state,
        loaded: false,
        loading: true,
        filters: {
          paginationOptions: {
            page: action.payload.page,
            limit: action.payload.limit,
          },
        },
      }
    }
    case ActionTypes.LoadSuccess: {
      return {
        ...state,
        ids: action.payload.results,
        loaded: true,
        loading: false,
        meta: action.payload.meta,
      }
    }
    case ActionTypes.LoadFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
        filters: {
          paginationOptions: {
            page: 0,
            limit: 0,
          },
        },
      }
    }
    case ActionTypes.DeleteSuccess: {
      return {
        ...state,
        ids: R.without([action.meta.cuisineId], state.ids),
      }
    }
    default:
      return state
  }
}

export const selectIds = state => state && state.ids
export const selectLoading = state => state && state.loading
export const selectLoaded = state => state && state.loaded
export const selectFilters = state => state && state.filters
export const selectTotalRecords = state => state && state.meta && state.meta.totalRecords
