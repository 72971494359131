import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  Inject,
} from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { MenuName } from '@models/builder.model'
import { ESCAPE } from '@angular/cdk/keycodes'

const MAX_NAME_LENGTH = 50

@Component({
  selector: 'hot-builder-name',
  templateUrl: './builder-name.component.html',
  styleUrls: ['./builder-name.component.scss'],
})
export class BuilderNameComponent implements OnChanges {
  @Input() public currentLanguage: string
  @Input() public name: MenuName
  @Input() public enableManuallyTranslate = true
  @Input() public isEditMode: boolean
  @Output() public update = new EventEmitter()
  @Output() public editModeChange = new EventEmitter<boolean>()
  public cache: string
  public isNameEdit: boolean

  @HostListener('keydown', ['$event'])
  handleEnter($event) {
    if ($event.keyCode === ESCAPE) {
      this.cancelEdit()
    }
  }

  public get maxNameLength() {
    return MAX_NAME_LENGTH
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnChanges(changes) {
    if (changes.currentLanguage) {
      this.isNameEdit = false
    }
  }

  nameEdit() {
    if (this.isEditMode) {
      return false
    }
    this.editModeChange.emit(true)
    this.cache = this.name[this.currentLanguage]
    this.isNameEdit = true
  }

  nameSave(translate = false) {
    if (!this.isNameValid) {
      return false
    }
    this.name[this.currentLanguage] = this.cache

    this.isNameEdit = false
    this.cache = ''
    this.editModeChange.emit(false)
    this.update.emit(translate)
  }

  cancelEdit() {
    this.isNameEdit = false
    this.editModeChange.emit(false)
  }

  get isNameValid() {
    return this.cache && this.cache.length
  }

  public addEmoji({ emoji }) {
    this.cache += emoji
  }
}
