import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

import { Store } from '@ngrx/store'

import { Observable, of } from 'rxjs'

import * as fromRoot from '@redux'
import * as fromUser from '@redux/user'

@Injectable()
export class SelectStaffUserGuard implements CanActivate {
  constructor(private store$: Store<fromRoot.State>) {}

  canActivate(router: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userId = router.params.userId

    this.store$.dispatch(new fromUser.Select({ userId }))
    return of(true)
  }
}
