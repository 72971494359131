<div>
  <div [formGroup]="form" class="flex justify-end">
    <div class="flex justify-end items-center relative w-100 mb2">
      <div class="flex-auto flex items-center">
        <mat-button-toggle-group
          *ngIf="isAdmin && !propertyId"
          formControlName="PropertyKind"
          class="mr3"
        >
          <mat-button-toggle value="hotel">Hotel</mat-button-toggle>
          <mat-button-toggle value="restaurant">Restaurant</mat-button-toggle>
        </mat-button-toggle-group>
        <div *ngIf="loading">
          <mat-spinner [diameter]="32"></mat-spinner>
        </div>
      </div>
      <div class="mr4">
        <button mat-button (click)="onDatesPresetClick('today')">Today</button>
        <button mat-button (click)="onDatesPresetClick('30d')">
          Last 30 days
        </button>
        <button mat-button (click)="onDatesPresetClick('7d')">Last 7 days</button>
      </div>
      <div
        *ngIf="form.value.Daterange.begin && form.value.Daterange.end"
        class="b absolute"
        style="right: 42px; top: 12px;"
      >
        {{ form.value.Daterange.begin.format('D MMMM YYYY') }} –
        {{ form.value.Daterange.end.format('D MMMM YYYY') }}
      </div>
      <input
        style="opacity: 0; pointer-events: none; width: 290px;"
        [hotDatepicker]="rangePicker"
        formControlName="Daterange"
        [min]="minDate"
        [max]="maxDate"
      />
      <hot-datepicker #rangePicker [rangeMode]="true" [locale]="locale.language"></hot-datepicker>
      <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    </div>
  </div>

  <div *ngIf="(statistics$ | async) as statistics; else noData">
    <div *ngIf="statistics.guests" class="bg-white shadow-1 br2 pa3 mb3">
      <div class="b mb3">📩 CJM conversion</div>
      <div class="flex items-center justify-around mb3">
        <div class="flex items-center">
          <div class="tc mr4">
            <div class="f3 b">{{ statistics.guests.contacts.guestsCount }}</div>
            <div>Guests</div>
          </div>
        </div>

        <div class="mr4 o-50 f3">→</div>

        <div class="tc mr4">
          <div class="f3">
            <span class="b">{{ statistics.guests.emailConversion.recievedEmailPerGuest }}</span>
            <span class="o-50 ml1"
              >({{ statistics.guests.emailConversion.recievedEmailPerGuestRatio }}%)</span
            >
          </div>
          <div>Recieved emails</div>
          <div class="o-70">
            Total {{ statistics.guests.emailConversion.recievedEmailTotal }} emails
          </div>
        </div>

        <div class="mr4 o-50 f3">→</div>

        <div class="tc mr4">
          <div class="f3">
            <span class="b">{{ statistics.guests.emailConversion.openedEmailPerGuest }}</span>
            <span class="o-50 ml1"
              >({{ statistics.guests.emailConversion.openedEmailPerGuestRatio }}%)</span
            >
          </div>
          <div>Opened email</div>
          <div class="o-70">
            Total {{ statistics.guests.emailConversion.openedEmailTotal }} opened emails
          </div>
        </div>

        <div class="mr4 o-50 f3">→</div>

        <div class="tc mr4">
          <div class="f3">
            <span class="b">{{ statistics.guests.emailConversion.clickedEmailPerGuest }}</span>
            <span class="o-50 ml1"
              >({{ statistics.guests.emailConversion.clickedEmailPerGuestRatio }}%)</span
            >
          </div>
          <div>Clicked email</div>
          <div class="o-70">
            Total {{ statistics.guests.emailConversion.clickedEmailTotal }} clicked emails
          </div>
        </div>

        <div class="mr4 o-50 f3">→</div>

        <div class="tc mr4">
          <div class="f3">
            <span class="b">{{ statistics.ordersFromCjm.count }}</span>
            <span class="o-50 ml1">({{ statistics.ordersFromCjm.ratio }}%)</span>
          </div>
          <div>Orders</div>
          <div class="o-70">
            From CJM emails
          </div>
        </div>
      </div>
      <div class="flex items-center gray">
        <div>
          <span>{{ statistics.guests.contacts.withEmail }}</span>
          guests with email ({{ statistics.guests.contacts.withEmailRatio }}%)
        </div>
        <div class="mh2">•</div>
        <div>
          <span>{{ statistics.guests.contacts.withPhone }}</span>
          guests with phone ({{ statistics.guests.contacts.withPhoneRatio }}%)
        </div>
        <div class="mh2">•</div>
        <div>
          <span>{{ statistics.guests.contacts.noContacts }}</span>
          guests without contacts ({{ statistics.guests.contacts.noContactsRatio }}%)
        </div>
      </div>
    </div>

    <div *ngIf="statistics.payment" class="flex mb3">
      <div class="w-third bg-white shadow-1 br2 mr3 pa3">
        <div class="b mb3">💰 Income</div>
        <div class="flex items-center justify-around">
          <div class="tc">
            <div
              *ngFor="let paymentData of statistics.payment"
              class="mb3 flex items-center nowrap"
            >
              <span class="currency">{{ paymentData.currency }}</span>
              <span class="f4 b"
                >{{ paymentData.totalIncome | baseCurrencyUnit: paymentData.currency }}
                <span class="o-50 normal">({{ paymentData.totalCount }})</span></span
              >
            </div>
            <div *ngIf="!statistics.payment || !statistics.payment.length" class="f4 b">0</div>
            <div>
              Total income
            </div>
          </div>
          <div class="tc">
            <div
              *ngFor="let paymentData of statistics.payment"
              class="mb3 flex items-center nowrap"
            >
              <span class="currency">{{ paymentData.currency }}</span>
              <span class="f4 b">{{
                paymentData.averageBill | baseCurrencyUnit: paymentData.currency
              }}</span>
            </div>
            <div *ngIf="!statistics.payment || !statistics.payment.length" class="f4 b">0</div>
            <div>Average bill</div>
          </div>
        </div>
      </div>

      <div class="w-third bg-white shadow-1 br2 mr3 pa3">
        <div class="b mb3">💳 Online payments</div>
        <div *ngFor="let paymentData of statistics.payment" class="mb3">
          <div class="flex items-center justify-around mb2">
            <div class="tc">
              <div class="flex items-center nowrap">
                <span class="currency">{{ paymentData.currency }}</span>
                <span class="f4 b">
                  {{ paymentData.online.total | baseCurrencyUnit: paymentData.currency }}
                  <span class="o-50 normal">({{ paymentData.online.count }})</span>
                </span>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-around">
            <div>
              <div
                *ngFor="let orders of paymentData.online.byStatus"
                class="flex items-center nowrap"
              >
                <span class="b"> {{ orders.total | baseCurrencyUnit: paymentData.currency }}</span>
                <span class="o-70">({{ orders.count }})</span>
                <span class="badge">{{ orders.status }}</span>
              </div>
            </div>
            <div>
              <div
                *ngFor="let orders of paymentData.online.byPaymentMethod"
                class="flex items-center nowrap"
              >
                <span class="b">{{ orders.total | baseCurrencyUnit: paymentData.currency }}</span>
                <span class="o-70">({{ orders.count }})</span>
                <span class="badge">{{ orders.method }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-third bg-white shadow-1 br2 pa3">
        <div class="b mb3">💵 Offline payments</div>
        <div *ngFor="let paymentData of statistics.payment" class="mb3">
          <div class="flex items-center justify-around mb2">
            <div class="tc">
              <div class="flex items-center nowrap">
                <span class="currency">{{ paymentData.currency }}</span>
                <span class="f4 b">
                  {{ paymentData.offline.total | baseCurrencyUnit: paymentData.currency }}
                  <span class="o-50 normal">({{ paymentData.offline.count }})</span>
                </span>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-around">
            <div>
              <div
                *ngFor="let orders of paymentData.offline.byStatus"
                class="flex items-center nowrap"
              >
                <span class="b"> {{ orders.total | baseCurrencyUnit: paymentData.currency }}</span>
                <span class="o-70">({{ orders.count }})</span>
                <span class="badge">{{ orders.status }}</span>
              </div>
            </div>
            <div>
              <div
                *ngFor="let orders of paymentData.offline.byPaymentMethod"
                class="flex items-center nowrap"
              >
                <span class="b">{{ orders.total | baseCurrencyUnit: paymentData.currency }}</span>
                <span class="o-70">({{ orders.count }})</span>
                <span class="badge">{{ orders.method }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex mb3">
      <div *ngIf="statistics.retentionRate" class="w-third bg-white shadow-1 br2 mr3 pa3">
        <div class="b mb3">🔄 Retention rate</div>
        <div class="flex items-center justify-around">
          <div class="tc">
            <div class="f4 b">
              {{ statistics.retentionRate.count }}
            </div>
            <div>Guests who ordered</div>
          </div>
          <div class="mh2 o-50 f4">→</div>
          <div class="tc">
            <div class="f4">
              <span class="b">{{ statistics.retentionRate.twoOrMore }}</span>
              <span class="o-50 ml1">({{ statistics.retentionRate.twoOrMoreRatio }}%)</span>
            </div>
            <div>Made 2+ orders</div>
          </div>
          <div class="mh2 o-50 f4">→</div>
          <div class="tc">
            <div class="f4">
              <span class="b">{{ statistics.retentionRate.threeOrMore }}</span>
              <span class="o-50 ml1">({{ statistics.retentionRate.threeOrMoreRatio }}%)</span>
            </div>
            <div>Made 3+ orders</div>
          </div>
        </div>
      </div>
      <div
        *ngIf="statistics.popularProducts && statistics.popularProducts.product"
        class="w-third bg-white shadow-1 br2 mr3 pa3"
      >
        <div class="b mb3">🔝 Popular products</div>
        <div *ngFor="let good of statistics.popularProducts.product">
          {{ good[0] }} ✕ {{ good[1].title | selectLanguage: locale.language }}
        </div>
      </div>
      <div
        *ngIf="statistics.popularProducts && statistics.popularProducts.service"
        class="w-third bg-white shadow-1 br2 pa3"
      >
        <div class="b mb2">🔝 Popular services</div>
        <div *ngFor="let good of statistics.popularProducts.service">
          {{ good[0] }} ✕ {{ good[1].title | selectLanguage: locale.language }}
        </div>
      </div>
    </div>

    <div *ngIf="statistics.orderProcessing" class="flex mb3">
      <div class="w-two-thirds bg-white shadow-1 br2 pa3">
        <div class="b mb3">⏱ Taking in progress time</div>
        <div class="flex">
          <div class="w-50 mr2 tc">
            <div class="b f3">
              {{ statistics.orderProcessing.avgDelayTotal }} min
              <!-- {{ 'Shop.label.min' | translate: locale.language }} -->
            </div>
            <div>Average delay</div>
          </div>
          <div class="w-50">
            <div class="b mb2">Average delay per manager</div>
            <div *ngFor="let manager of statistics.orderProcessing.byUsers; let i = index">
              {{ i + 1 }}. {{ manager.user.name }} – {{ manager.avgDelay }} min
              <span class="o-50">({{ manager.count }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DELETE: -->
    <!-- <div *ngIf="statistics && statistics.emailsOpenRate" class="bg-white shadow-1 br2 pa3 mb3">
      ✉️ <b>{{ statistics.emailsOpenRate.total }}</b> Sent emails
      <span class="o-60 ml2 mr2">→</span> <b>{{ statistics.emailsOpenRate.opened }}</b> Opened
      <span class="o-60">({{ statistics.emailsOpenRate.openedRatio }}%)</span>
      <span class="o-60 ml2 mr2">→</span> <b>{{ statistics.emailsOpenRate.clicked }}</b> Clicked
      <span class="o-60">({{ statistics.emailsOpenRate.clickedRatio }}%)</span>
    </div> -->

    <table
      *ngIf="statistics.shopSource"
      mat-table
      [dataSource]="statistics.shopSource"
      class="w-100 mat-elevation-z8 br2"
    >
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef>Source</th>
        <td mat-cell *matCellDef="let element">{{ element.source }}</td>
      </ng-container>
      <ng-container matColumnDef="visitors">
        <th mat-header-cell *matHeaderCellDef>Visitors</th>
        <td mat-cell *matCellDef="let element">{{ element.visitors }}</td>
      </ng-container>
      <ng-container matColumnDef="shopVisitors">
        <th mat-header-cell *matHeaderCellDef>Shop visitors</th>
        <td mat-cell *matCellDef="let element">
          {{ element.shopVisitors }}
        </td>
      </ng-container>
      <ng-container matColumnDef="orders">
        <th mat-header-cell *matHeaderCellDef>Orders</th>
        <td mat-cell *matCellDef="let element">
          {{ element.orders }}
          <span class="o-50 normal">({{ element.ordersRatio }}%)</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="missedIncome">
        <th mat-header-cell *matHeaderCellDef>Missed income</th>
        <td mat-cell *matCellDef="let element" class="pt2 pb1">
          <ng-container *ngFor="let currencyData of element.missedIncome">
            <div *ngIf="currencyData.value" class="flex items-center nowrap mb1">
              <span class="currency">{{ currencyData.currency }}</span>
              <span>{{ currencyData.value | baseCurrencyUnit: currencyData.currency }}</span>
            </div>
            <div *ngIf="!currencyData.value" class="flex items-center nowrap mb1">0</div>
          </ng-container>
          <ng-container *ngIf="!element.missedIncome.length">0</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="incomeOnline">
        <th mat-header-cell *matHeaderCellDef>Income (Online)</th>
        <td mat-cell *matCellDef="let element" class="pt2 pb1">
          <ng-container *ngFor="let currencyData of element.incomeOnline">
            <div *ngIf="currencyData.value" class="flex items-center nowrap mb1">
              <span class="currency">{{ currencyData.currency }}</span>
              <span>{{ currencyData.value | baseCurrencyUnit: currencyData.currency }}</span>
            </div>
            <div *ngIf="!currencyData.value" class="flex items-center nowrap mb1">0</div>
          </ng-container>
          <ng-container *ngIf="!element.income.length">0</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="incomeOffline">
        <th mat-header-cell *matHeaderCellDef>Income (Offline)</th>
        <td mat-cell *matCellDef="let element" class="pt2 pb1">
          <ng-container *ngFor="let currencyData of element.incomeOffline">
            <div *ngIf="currencyData.value" class="flex items-center nowrap mb1">
              <span class="currency">{{ currencyData.currency }}</span>
              <span>{{ currencyData.value | baseCurrencyUnit: currencyData.currency }}</span>
            </div>
            <div *ngIf="!currencyData.value" class="flex items-center nowrap mb1">0</div>
          </ng-container>
          <ng-container *ngIf="!element.income.length">0</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="income">
        <th mat-header-cell *matHeaderCellDef>Income</th>
        <td mat-cell *matCellDef="let element" class="pt2 pb1">
          <ng-container *ngFor="let currencyData of element.income">
            <div *ngIf="currencyData.value" class="flex items-center nowrap mb1">
              <span class="currency">{{ currencyData.currency }}</span>
              <span>{{ currencyData.value | baseCurrencyUnit: currencyData.currency }}</span>
            </div>
            <div *ngIf="!currencyData.value" class="flex items-center nowrap mb1">0</div>
          </ng-container>
          <ng-container *ngIf="!element.income.length">0</ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="statisticsColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: statisticsColumns; let last = last"
        [class.b]="last"
      ></tr>
    </table>
  </div>
</div>

<ng-template #noData>
  <div class="flex o-60 justify-center mv3">No data</div>
</ng-template>
