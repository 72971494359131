import {
  SalesServiceActions,
  SalesServiceActionTypes,
} from '@redux/sales-service/sales-service.actions'
import * as R from 'ramda'

export interface State {
  ids: string[]
  selectedId: string | null
  loadedById: { [key: string]: boolean }
  loaded: boolean
  loading: boolean
}

export const initialState: State = {
  ids: [],
  selectedId: null,
  loadedById: {},
  loaded: false,
  loading: false,
}

export function reducer(state = initialState, action: SalesServiceActions): State {
  switch (action.type) {
    case SalesServiceActionTypes.LoadSettings:
    case SalesServiceActionTypes.LoadSettingsItemForProperty:
    case SalesServiceActionTypes.LoadSettingsItem: {
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    }
    case SalesServiceActionTypes.LoadSettingsSuccess:
    case SalesServiceActionTypes.LoadSettingsItemSuccess: {
      const ids = action.payload.results || [action.payload.result]
      const r = {
        ...state,
        loaded: true,
        loading: false,
        ids,
        loadedById: ids.reduce((a, e) => {
          a[e] = true
          return a
        }, state.loadedById),
      }
      if (action.type === SalesServiceActionTypes.LoadSettingsItemSuccess) {
        r.selectedId = action.payload.result
      }
      return r
    }
    case SalesServiceActionTypes.LoadSettingsFail:
    case SalesServiceActionTypes.LoadSettingsItemFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
      }
    }
    case SalesServiceActionTypes.CreateSettingsSuccess: {
      return {
        ...state,
        ids: [...state.ids, action.payload.result],
      }
    }
    case SalesServiceActionTypes.DeleteSettingsSuccess: {
      const itemId = action.meta.salesServiceSettingsId
      return {
        ...state,
        ids: R.without([itemId], state.ids),
        loadedById: R.omit([itemId], state.loadedById),
      }
    }
    case SalesServiceActionTypes.SelectSettings: {
      return {
        ...state,
        selectedId: action.payload.salesServiceSettingsId,
      }
    }
    case SalesServiceActionTypes.UpdateSettingsSuccess: {
      return { ...state }
    }

    default: {
      return state
    }
  }
}

export const selectIds = (state: State) => state.ids
export const selectSalesServiceSettingsLoaded = (state: State) => state.loaded
export const selectCurrentlySelectedSalesServiceSettingsId = (state: State) => state.selectedId
export const selectIsLoadedById = (state: State, id: string) => state.loadedById[id] || false
