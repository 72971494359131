import { Injectable } from '@angular/core'
import { ApiService } from '@shared/services'
import { Observable } from 'rxjs'
import { NormalizedResults, NormalizedResult, Cuisine } from '@models'

@Injectable()
export class CuisineService {
  constructor(private apiService: ApiService) {}

  public getList(params?: any): Observable<NormalizedResults> {
    return this.apiService.get('cuisines', {
      params: {
        page: (params && params.page) || 0,
        limit: (params && params.limit) || 0,
      },
    })
  }

  public getByCode(code: string): Observable<NormalizedResult> {
    return this.apiService.get('cuisines', { params: { code } })
  }

  public create(data: Cuisine): Observable<NormalizedResult> {
    return this.apiService.post('cuisines', data)
  }

  public update(cuisineId: string, data: Partial<Cuisine>): Observable<NormalizedResult> {
    return this.apiService.patch(`cuisines/${cuisineId}`, data)
  }

  public delete(cuisineId: string): Observable<boolean> {
    return this.apiService.delete(`cuisines/${cuisineId}`)
  }
}
