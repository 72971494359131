import * as R from 'ramda'
import { Observable, combineLatest, ObservedValueOf } from 'rxjs'
import { map } from 'rxjs/operators'

export const combineLatestObject = <T extends { [key in keyof T]: Observable<unknown> }>(
  obj: T
): Observable<{ [K in keyof T]: ObservedValueOf<T[K]> }> => {
  const recordKeys = R.keys(obj)
  const observables = R.values(obj)
  return combineLatest(observables).pipe(
    map(xs => {
      return R.fromPairs(R.zip(recordKeys, xs) as any) as any
    })
  )
}
