import { RestaurantActions, RestaurantActionTypes } from './restaurant.actions'
import * as R from 'ramda'
import { DEFAULT_PAGINATION_SIZE, RestaurantFilters } from '@models'

export interface State {
  ids: string[]
  loaded: boolean
  filters: RestaurantFilters
  selectedId: string | null
  loadedById: { [key: string]: boolean }
  meta?: {
    totalRecords?: number
    categories?: string[]
    cuisinesIds?: string[]
  }
}

export const initialState: State = {
  ids: [],
  loaded: false,
  selectedId: null,
  loadedById: {},
  filters: {
    paginationOptions: {
      page: 0,
      limit: DEFAULT_PAGINATION_SIZE,
    },
  },
  meta: {
    totalRecords: 0,
    categories: [],
    cuisinesIds: [],
  },
}

export function reducer(state = initialState, action: RestaurantActions): State {
  switch (action.type) {
    case RestaurantActionTypes.Select: {
      return {
        ...state,
        selectedId: action.payload.restaurantId,
      }
    }
    case RestaurantActionTypes.CreateSuccess: {
      const createdId = action.payload.result
      return {
        ...state,
        ids: [...state.ids, createdId],
      }
    }
    case RestaurantActionTypes.DeleteSuccess: {
      const deletedId = action.meta.restaurantId
      return {
        ...state,
        ids: R.without([deletedId], state.ids),
      }
    }

    case RestaurantActionTypes.Load: {
      return {
        ...state,
        loaded: false,
        filters: {
          paginationOptions: {
            page: action.payload.page,
            limit: action.payload.limit,
          },
        },
      }
    }

    case RestaurantActionTypes.LoadSuccess: {
      return {
        ...state,
        loaded: true,
        ids: action.payload.results,
        meta: action.payload.meta,
      }
    }

    case RestaurantActionTypes.LoadFail: {
      return {
        ...state,
        ids: [],
        loaded: false,
        filters: {
          paginationOptions: {
            page: 0,
            limit: 10,
          },
        },
      }
    }

    case RestaurantActionTypes.LoadItem: {
      return {
        ...state,
        loadedById: {
          [action.payload.restaurantId]: false,
        },
      }
    }

    case RestaurantActionTypes.UpdateSuccess: {
      return {
        ...state,
        meta: action.payload.meta,
      }
    }

    case RestaurantActionTypes.LoadItemSuccess: {
      return {
        ...state,
        loadedById: {
          [action.meta.restaurantId]: true,
        },
        ids: [action.payload.result],
        meta: action.payload.meta,
      }
    }

    case RestaurantActionTypes.LoadItemFail: {
      return {
        ...state,
        loadedById: {
          [action.meta.restaurantId]: false,
        },
      }
    }

    default: {
      return state
    }
  }
}

export const selectIds = (state: State) => state.ids
export const selectIsLoaded = (state: State) => state.loaded
export const selectCategories = (state: State) => (state && state.meta ? state.meta.categories : [])
export const selectRestaurantCuisinesIds = (state: State) =>
  state && state.meta ? state.meta.cuisinesIds : []
export const selectFilters = (state: State) => state && state.filters
export const selectTotalRecords = (state: State) =>
  state && state.meta ? state.meta.totalRecords : 0
export const selectIsLoadedById = (state: State, id: string) => state.loadedById[id] || false
export const selectCurrentId = (state: State) => state && state.selectedId
