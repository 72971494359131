import { Action } from '@ngrx/store'

import { FormResponseStatus, NormalizedResults, NormalizedResult } from '@models'
import { FormResponseMeta } from '@redux/form-response/form-response.reducer'

export enum ActionTypes {
  Select = '[FormResponse] Select form response',
  Load = '[FormResponse] Load',
  LoadSuccess = '[FormResponse] Load Success',
  LoadFail = '[FormResponse] Load Fail',
  LoadItem = '[FormResponse] Load Item',
  LoadItemSuccess = '[FormResponse] Load Item Success',
  LoadItemFail = '[FormResponse] Load Item Fail',
  UpdateStatus = '[FormResponse] Update Status',
  UpdateStatusSuccess = '[FormResponse] Update Status Success',
  UpdateStatusFail = '[FormResponse] Update Status Fail',
  UpdateMeta = '[FormResponse] Update meta',
}

/**
 * Select action
 */
export class Select implements Action {
  readonly type = ActionTypes.Select
  constructor(public payload: { formResponseId: string }) {}
}

/**
 * Load actions
 */
export interface LoadPayload {
  propertyId: string
  status?: FormResponseStatus
  page?: number
  limit?: number
}

export class Load implements Action {
  readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load item actions
 */
export interface LoadItemPayload {
  propertyId?: string
  formResponseId?: string
}

export class LoadItem implements Action {
  readonly type = ActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}

export class LoadItemSuccess implements Action {
  readonly type = ActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}

export class LoadItemFail implements Action {
  readonly type = ActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Update status actions
 */
export interface UpdateStatusPayload {
  propertyId: string
  formResponseId: string
  status: FormResponseStatus
  comment: string
}

export class UpdateStatus implements Action {
  readonly type = ActionTypes.UpdateStatus
  constructor(public payload: UpdateStatusPayload) {}
}

export class UpdateStatusSuccess implements Action {
  readonly type = ActionTypes.UpdateStatusSuccess
  constructor(public payload: NormalizedResult, public meta: UpdateStatusPayload) {}
}

export class UpdateStatusFail implements Action {
  readonly type = ActionTypes.UpdateStatusFail
  constructor(public error: any, public meta: UpdateStatusPayload) {}
}

export interface UpdateMetaPayload {
  propertyId: string
}
export class UpdateMeta implements Action {
  public readonly type = ActionTypes.UpdateMeta
  constructor(public payload: UpdateMetaPayload, public meta: FormResponseMeta) {}
}

export type Actions =
  | Select
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | UpdateStatus
  | UpdateStatusSuccess
  | UpdateStatusFail
  | UpdateMeta
