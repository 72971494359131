import { AbstractControl } from '@angular/forms'

export const latinSymbolsValidator = (control: AbstractControl) => {
  const preparedValue = String(control.value).trim()
  const notEngRegExp = /[^a-zA-Z_ :.]/g
  if (control && control.value && notEngRegExp.test(preparedValue)) {
    return {
      latinSymbols: true,
    }
  }
  return null
}
