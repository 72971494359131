import { Action } from '@ngrx/store'
import { NormalizedResults, NormalizedResult, Reservation } from '@models'
import { ReservationsQueryParams } from '@redux/reservation/reservation.service'

export enum ActionTypes {
  Select = '[Reservation] Select',

  Load = '[Reservation] Load',
  LoadSuccess = '[Reservation] Load Success',
  LoadFail = '[Reservation] Load Fail',

  LoadItem = '[Reservation] Load Item',
  LoadItemSuccess = '[Reservation] Load Item Success',
  LoadItemFail = '[Reservation] Load Item Fail',

  Create = '[Reservation] Create',
  CreateSuccess = '[Reservation] Create Success',
  CreateFail = '[Reservation] Create Fail',

  Update = '[Reservation] Update',
  UpdateSuccess = '[Reservation] Update Success',
  UpdateFail = '[Reservation] Update Fail',
}

/**
 * Select action
 */
export class Select implements Action {
  readonly type = ActionTypes.Select
  constructor(public payload: { reservationId: string }) {}
}

/**
 * Load Reservations actions
 */
export interface LoadPayload {
  propertyId: string
  query?: ReservationsQueryParams
}

export class Load implements Action {
  readonly type = ActionTypes.Load
  constructor(public payload: LoadPayload) {}
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LoadSuccess
  constructor(public payload: NormalizedResults, public meta: LoadPayload) {}
}

export class LoadFail implements Action {
  readonly type = ActionTypes.LoadFail
  constructor(public error: any, public meta: LoadPayload) {}
}

/**
 * Load Reservation actions
 */
export interface LoadItemPayload {
  propertyId: string
  reservationId: string
}

export class LoadItem implements Action {
  readonly type = ActionTypes.LoadItem
  constructor(public payload: LoadItemPayload) {}
}

export class LoadItemSuccess implements Action {
  readonly type = ActionTypes.LoadItemSuccess
  constructor(public payload: NormalizedResult, public meta: LoadItemPayload) {}
}

export class LoadItemFail implements Action {
  readonly type = ActionTypes.LoadItemFail
  constructor(public error: any, public meta: LoadItemPayload) {}
}

/**
 * Create Reservation actions
 */
export interface CreatePayload {
  propertyId: string
  reservation: Partial<Reservation>
}

export class Create implements Action {
  readonly type = ActionTypes.Create
  constructor(public payload: CreatePayload) {}
}

export class CreateSuccess implements Action {
  readonly type = ActionTypes.CreateSuccess
  constructor(public payload: NormalizedResult, public meta: CreatePayload) {}
}

export class CreateFail implements Action {
  readonly type = ActionTypes.CreateFail
  constructor(public error: any, public meta: CreatePayload) {}
}

/**
 * Update Reservation actions
 */
export interface UpdatePayload {
  propertyId: string
  reservationId: string
  reservation: Partial<Reservation>
}

export class Update implements Action {
  readonly type = ActionTypes.Update
  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccess implements Action {
  readonly type = ActionTypes.UpdateSuccess
  constructor(public payload: NormalizedResult, public meta: UpdatePayload) {}
}

export class UpdateFail implements Action {
  readonly type = ActionTypes.UpdateFail
  constructor(public error: any, public meta: UpdatePayload) {}
}

export type Actions =
  | Select
  | Load
  | LoadSuccess
  | LoadFail
  | LoadItem
  | LoadItemSuccess
  | LoadItemFail
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdateSuccess
  | UpdateFail
