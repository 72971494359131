import { Environment } from './model'
import { processEnv } from './process.env'

export const environment: Environment = {
  production: true,
  hmr: false,
  noAnimations: true,

  API_BASE_URL: processEnv.API_BASE_URL || 'http://localhost:5000',
  FILE_PROXY_URL: processEnv.FILE_PROXY_URL,
  FACEBOOK_APP_ID: processEnv.FACEBOOK_APP_ID,
  PUSH_NOTIF_PUBLIC_KEY: processEnv.PUSH_NOTIF_PUBLIC_KEY,
  BOOKING_OAUTH_ENABLED: processEnv.BOOKING_OAUTH_ENABLED,
  APP_ID: processEnv.APP_ID,
}
