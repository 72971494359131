import { ChatAttachment } from './chat-attachment.model'

export enum ChatMessageServiceEventKind {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Assigned = 'assigned',
  Notified = 'notified',
}

interface IChatMessageEventProps {
  kind: ChatMessageServiceEventKind
  users?: string[]
  form?: string
}

interface IChatMessageServiceContentPart {
  type: 'channel_list'
  payload: any[]
}

export interface ChatMessage {
  _id: string
  chat: string
  property: string
  propertyUser: string
  createdAt: string
  type: 'outgoing' | 'incoming' | 'notification' | 'service'
  status?: 'error' | 'sent' | 'delivered' | 'read'
  content?: any
  photos?: any[]
  images?: {
    image: string
    caption?: string
  }[]
  attachments?: ChatAttachment[]
  event?: IChatMessageEventProps
  serviceMessage?: IChatMessageServiceContentPart[]
  // for incoming messages
  text?: string
  originalText?: string
}
