import { Injectable } from '@angular/core'
import { PropertyFacade } from './property.facade'
import { map, startWith, shareReplay, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'
import { PropertyGoal, Property } from '@models'
import { AuthService } from '@shared/services'

interface GoalCardContent {
  name: string
  title: string
  text: string
  actionLabel: string
  imageUrl: string
  linkTemplate: string
  emoji: string
}

interface GoalCard {
  content: GoalCardContent
  link: string
  completed: boolean
  skipped: boolean
}

const goalWithContent = (
  goalStatus: PropertyGoal,
  content: GoalCardContent,
  property: Property
): GoalCard => {
  return {
    content,
    link: content.linkTemplate.replace(':propertyId', property._id),
    completed: Boolean(goalStatus.completedAt),
    skipped: Boolean(!goalStatus.completedAt && goalStatus.skippedAt),
  }
}

const cardsContent: GoalCardContent[] = [
  {
    name: 'connect_messenger',
    title: 'Onboarding.card.connectMessenger.title',
    text: 'Onboarding.card.connectMessenger.text',
    actionLabel: 'Onboarding.card.connectMessenger.actionLabel',
    imageUrl: 'assets/onboarding/connect_messenger.png',
    linkTemplate: '/properties/:propertyId/settings/channels',
    emoji: '🔌',
  },
  {
    name: 'add_members',
    title: 'Onboarding.card.addMembers.title',
    text: 'Onboarding.card.addMembers.text',
    actionLabel: 'Onboarding.card.addMembers.actionLabel',
    imageUrl: 'assets/onboarding/add_members.png',
    linkTemplate: '/properties/:propertyId/settings/staff',
    emoji: '🙋‍',
  },
  {
    name: 'verify_faq',
    title: 'Onboarding.card.verifyFAQ.title',
    text: 'Onboarding.card.verifyFAQ.text',
    actionLabel: 'Onboarding.card.verifyFAQ.actionLabel',
    imageUrl: 'assets/onboarding/verify_faq.png',
    linkTemplate: '/properties/:propertyId/settings/faq',
    emoji: '🛎',
  },
  {
    name: 'verify_services',
    title: 'Onboarding.card.verifyServices.title',
    text: 'Onboarding.card.verifyServices.text',
    actionLabel: 'Onboarding.card.verifyServices.actionLabel',
    imageUrl: 'assets/onboarding/verify_services.png',
    linkTemplate: '/properties/:propertyId/settings/builder',
    emoji: '📋',
  },
]

@Injectable({ providedIn: 'root' })
export class OnboardingFacade {
  public goals$ = this.propertyFacade.getOnboardingGoals()

  public goalsCards$ = this.goals$.pipe(
    withLatestFrom(this.propertyFacade.property$),
    map(([goals, property]) => {
      const indexedGoals: Record<string, typeof goals[0]> = R.indexBy(R.prop('name'), goals)
      return this.authService.ssoMode
        ? []
        : cardsContent
            .map(card =>
              indexedGoals[card.name]
                ? goalWithContent(indexedGoals[card.name], card, property)
                : null
            )
            .filter(Boolean)
    }),
    startWith([] as GoalCard[]),
    shareReplay(1)
  )

  public skippedGoalsCards$ = this.goalsCards$.pipe(
    map(cards => cards.filter(x => !x.completed && x.skipped))
  )

  public notCompletedCards$ = this.goalsCards$.pipe(map(cards => cards.filter(x => !x.completed)))

  constructor(private propertyFacade: PropertyFacade, private authService: AuthService) {}
}
